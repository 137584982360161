/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from '@ngrx/store';

export enum EventMonitorActionTypes {
  INIT = '[Event Monitor] Init',

  GET_EVENT_CATEGORIES = '[Event Monitor] Get Event Categories',
  GET_EVENT_CATEGORIES_SUCCESS = '[Event Monitor] Get Event Categories Success',
  GET_EVENT_CATEGORIES_FAILURE = '[Event Monitor] Get Event Categories Failure',
  CANCEL_GET_EVENT_CATEGORIES_REQUEST = '[Event Monitor] Cancel Get Event Categories Request',

  GET_EVENT_ORGANIZATIONS = '[Event Monitor] Get Event Organizations',
  GET_EVENT_ORGANIZATIONS_SUCCESS = '[Event Monitor] Get Event Organizations Success',
  GET_EVENT_ORGANIZATIONS_FAILURE = '[Event Monitor] Get Event Organizations Failure',
  CANCEL_GET_EVENT_ORGANIZATIONS_REQUEST = '[Event Monitor] Cancel Get Event Organizations Request',

  GET_EVENT_USERS = '[Event Monitor] Get Event Users',
  GET_EVENT_USERS_SUCCESS = '[Event Monitor] Get Event Users Success',
  GET_EVENT_USERS_FAILURE = '[Event Monitor] Get Event Users Failure',
  CANCEL_GET_EVENT_USERS_REQUEST = '[Event Monitor] Cancel Get Event Users Request',

  GET_EVENT_DEVICES = '[Event Monitor] Get Event Devices',
  GET_EVENT_DEVICES_SUCCESS = '[Event Monitor] Get Event Devices Success',
  GET_EVENT_DEVICES_FAILURE = '[Event Monitor] Get Event Devices Failure',
  CANCEL_GET_EVENT_DEVICES_REQUEST = '[Event Monitor] Cancel Get Event Devices Request',

  GET_EVENTS = '[Event Monitor] Get Events',
  GET_EVENTS_SUCCESS = '[Event Monitor] Get Events Success',
  GET_EVENTS_FAILURE = '[Event Monitor] Get Events Failure',
  CANCEL_GET_EVENTS_REQUEST = '[Event Monitor] Cancel Get Events Request',

  REFRESH_EVENTS = '[Event Monitor] Refresh Events',

  SELECT_EVENT = '[Event Monitor] Select Event',

  EXPORT_EVENTS = '[Event Monitor] Export Events',
  EXPORT_EVENTS_SUCCESS = '[Event Monitor] Export Events Success',
  EXPORT_EVENTS_FAILURE = '[Event Monitor] Export Events Failure',
  CANCEL_EXPORT_EVENTS_REQUEST = '[Event Monitor] Cancel Export Events Request',
}

// Init
class Init implements Action {
  readonly type = EventMonitorActionTypes.INIT;
}

// Get event categories
class GetEventCategories implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_CATEGORIES;
}

// On get event categories successfully
class OnGetEventCategoriesSuccess implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_CATEGORIES_SUCCESS;

  constructor(public result: any) {}
}

// On get event categories failed
class OnGetEventCategoriesFailed implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_CATEGORIES_FAILURE;

  constructor(public error: any) {}
}

// Cancel get event categories request
class CancelGetEventCategoriesRequest implements Action {
  readonly type = EventMonitorActionTypes.CANCEL_GET_EVENT_CATEGORIES_REQUEST;
}

// Get event organizations
class GetEventOrganizations implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS;
}

// On get event organizations successfully
class OnGetEventOrganizationsSuccess implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS_SUCCESS;

  constructor(public result: any) {}
}

// On get event organizations failed
class OnGetEventOrganizationsFailed implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS_FAILURE;

  constructor(public error: any) {}
}

// Cancel get event organizations request
class CancelGetEventOrganizationsRequest implements Action {
  readonly type = EventMonitorActionTypes.CANCEL_GET_EVENT_ORGANIZATIONS_REQUEST;
}

// Get event users
class GetEventUsers implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_USERS;
}

// On get event users successfully
class OnGetEventUsersSuccess implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_USERS_SUCCESS;

  constructor(public result: any) {}
}

// On get event users failed
class OnGetEventUsersFailed implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_USERS_FAILURE;

  constructor(public error: any) {}
}

// Cancel get event users request
class CancelGetEventUsersRequest implements Action {
  readonly type = EventMonitorActionTypes.CANCEL_GET_EVENT_USERS_REQUEST;
}

// Get event devices
class GetEventDevices implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_DEVICES;

  constructor(public searchText: any) {}
}

// On get event devices successfully
class OnGetEventDevicesSuccess implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_DEVICES_SUCCESS;

  constructor(public result: any) {}
}

// On get event devices failed
class OnGetEventDevicesFailed implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENT_DEVICES_FAILURE;

  constructor(public error: any) {}
}

// Cancel get event devices request
class CancelGetEventDevicesRequest implements Action {
  readonly type = EventMonitorActionTypes.CANCEL_GET_EVENT_DEVICES_REQUEST;
}

// Get events
class GetEvents implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENTS;
  constructor(
    public searchText: any,
    public page: any,
    public perPage: any,
  ) {}
}

// On get events successfully
class OnGetEventsSuccess implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENTS_SUCCESS;

  constructor(
    public result: any,
    public payload: any,
  ) {}
}

// On get events failed
class OnGetEventsFailed implements Action {
  readonly type = EventMonitorActionTypes.GET_EVENTS_FAILURE;

  constructor(public error: any) {}
}

// Cancel get events request
class CancelGetEventsRequest implements Action {
  readonly type = EventMonitorActionTypes.CANCEL_GET_EVENTS_REQUEST;
}

// Refresh the list of devices
class RefreshEvents implements Action {
  readonly type = EventMonitorActionTypes.REFRESH_EVENTS;
}

// Select event
class SelectEvent implements Action {
  readonly type = EventMonitorActionTypes.SELECT_EVENT;

  constructor(public selectedEvent: any) {}
}

// Export events
class ExportEvents implements Action {
  readonly type = EventMonitorActionTypes.EXPORT_EVENTS;

  constructor(public searchText: any) {}
}

// On export events successfully
class OnExportEventsSuccess implements Action {
  readonly type = EventMonitorActionTypes.EXPORT_EVENTS_SUCCESS;

  constructor(public results: any[]) {}
}

// On export events failed
class OnExportEventsFailed implements Action {
  readonly type = EventMonitorActionTypes.EXPORT_EVENTS_FAILURE;

  constructor(public error: any) {}
}

// Cancel export events request
class CancelExportEventsRequest implements Action {
  readonly type = EventMonitorActionTypes.CANCEL_EXPORT_EVENTS_REQUEST;
}

export type EventMonitorAction =
  | Init
  // Categories
  | GetEventCategories
  | OnGetEventCategoriesSuccess
  | OnGetEventCategoriesFailed
  | CancelGetEventCategoriesRequest
  // Organizations
  | GetEventOrganizations
  | OnGetEventOrganizationsSuccess
  | OnGetEventOrganizationsFailed
  | CancelGetEventOrganizationsRequest
  // Users
  | GetEventUsers
  | OnGetEventUsersSuccess
  | OnGetEventUsersFailed
  | CancelGetEventUsersRequest
  // Devices
  | GetEventDevices
  | OnGetEventDevicesSuccess
  | OnGetEventDevicesFailed
  | CancelGetEventDevicesRequest
  // Events
  | GetEvents
  | OnGetEventsSuccess
  | OnGetEventsFailed
  | CancelGetEventsRequest
  | RefreshEvents
  | SelectEvent
  | ExportEvents
  | OnExportEventsSuccess
  | OnExportEventsFailed
  | CancelExportEventsRequest;

export const fromEventMonitorActions = {
  Init,
  // Categories
  GetEventCategories,
  OnGetEventCategoriesSuccess,
  OnGetEventCategoriesFailed,
  CancelGetEventCategoriesRequest,
  // Organizations
  GetEventOrganizations,
  OnGetEventOrganizationsSuccess,
  OnGetEventOrganizationsFailed,
  CancelGetEventOrganizationsRequest,
  // Users
  GetEventUsers,
  OnGetEventUsersSuccess,
  OnGetEventUsersFailed,
  CancelGetEventUsersRequest,
  // Devices
  GetEventDevices,
  OnGetEventDevicesSuccess,
  OnGetEventDevicesFailed,
  CancelGetEventDevicesRequest,
  // Events
  GetEvents,
  OnGetEventsSuccess,
  OnGetEventsFailed,
  CancelGetEventsRequest,

  RefreshEvents,
  SelectEvent,
  ExportEvents,
  OnExportEventsSuccess,
  OnExportEventsFailed,
  CancelExportEventsRequest,
};
