<div class="px-5">
  <p-tabMenu
    styleClass="microsec-dashboard-tabmenu"
    [model]="tabsMenu"
    [activeItem]="activeTabMenu"
    (activeItemChange)="onTabChange($event)"></p-tabMenu>
</div>

<microsec-form-builder
  #fb
  [fields]="fields"
  [submitButtonLabel]="question ? SAVE_CHANGES_LABEL : CREATE_LABEL"
  [shouldShowActionButtons]="true"
  (submitEvent)="onSubmit($event)"
  [shouldSubmitOnEnter]="false">
</microsec-form-builder>

<ng-template #titleField>
  @if (!!form && !!form.form) {
    <div [formGroup]="form.form">
      <div pTooltip="Click to edit" tooltipPosition="left" appendTo="body">
        <textarea
          class="no-border w-full text-xl"
          type="text"
          [spellcheck]="false"
          pInputTextarea
          inputTrimRef
          [autoResize]="true"
          [rows]="1"
          [formControlName]="FORM_PARAMS.TITLE"
          placeholder="Enter your question..."
          (keydown.enter)="$event.preventDefault()"
          [autofocus]="true"></textarea>
      </div>
    </div>
  }
</ng-template>

<ng-template #descriptionField>
  @if (!!form && !!form.form) {
    <div class="mb-3">
      <label class="font-bold">Description</label>
    </div>
    <microsec-custom-markdown-editor
      [textareaFormGroup]="form.form"
      [textareaFormControlName]="FORM_PARAMS.DESCRIPTION"></microsec-custom-markdown-editor>
  }
</ng-template>

<ng-template #prefillingLogicsField>
  @if (!!form && !!form.form && !!prefillingLogicsFormArray) {
    <ng-container [formGroup]="form.form">
      <div class="mb-3">
        <label class="font-bold">Autofill Logic(s)</label>
      </div>
      <div class="flex flex-column gap-3">
        <ng-container [formArrayName]="FORM_PARAMS.PREFILLING_LOGICS">
          <ng-container *ngFor="let item of prefillingLogicsFormArray?.controls; let i = index">
            <ng-container [formGroupName]="i">
              <div class="flex align-items-center gap-2">
                <div class="autofill-field-input">
                  <div class="microsec-codemirror-container console-content">
                    <div class="card">
                      <ngx-codemirror #codeMirrorComponent formControlName="value" [options]="CODEMIRROR_OPTIONS"> </ngx-codemirror>
                    </div>
                    <label
                      class="text-error"
                      *ngIf="
                        !!prefillingLogicsFormArray?.get([i, 'value'])?.touched &&
                        !!prefillingLogicsFormArray?.get([i, 'value'])?.dirty &&
                        !!prefillingLogicsFormArray?.get([i, 'value'])?.errors
                      ">
                      Autofill logic is required
                    </label>
                  </div>
                </div>
                <div class="autofill-field-delete-button">
                  <p-button
                    severity="danger"
                    size="small"
                    icon="fas fa-trash"
                    pTooltip="Delete"
                    tooltipPosition="bottom"
                    appendTo="body"
                    (onClick)="removePrefillingLogic(i)">
                  </p-button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <div>
          <p-button
            severity="secondary"
            [rounded]="true"
            styleClass="text-transform-none p-button-sm"
            type="button"
            icon="fas fa-add"
            label="Add Logic"
            (onClick)="addPrefillingLogic()"></p-button>
        </div>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #mitigationsField>
  <app-assessment-mitigations [(mitigations)]="mitigations"></app-assessment-mitigations>
</ng-template>
