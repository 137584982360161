<div class="loader flex w-full absolute z-5 align-items-center justify-content-center select-none">
  <div class="loader-wrapper flex py-8 w-30rem border-round-lg bg-black-alpha-70 justify-content-center">
    <div class="flex flex-column align-items-center justify-content-center relative">
      <!-- Loading image -->
      <div class="loader-shadow flex h-25rem w-25rem align-items-center justify-content-center">
        @if (!editor?.subLoadings?.length || (!!editor?.subLoadings?.length && !shouldShowCloseButton)) {
          <img class="loader-stroke h-15rem w-15rem absolute" [src]="EDITOR_DIAGRAM_BASE_URL + '/nme-loader.png'" alt="microsec-loader" />
        }
        <div class="flex h-7rem">
          <img src="/assets/images/microsec-logo-icon.png" alt="microsec-logo-icon" style="max-height: 100%" />
        </div>
      </div>
      <!-- Main message -->
      <div
        class="text-data font-bold text-center px-4 mt-2"
        [innerHTML]="!!editor?.loadingMessage ? editor?.loadingMessage : EDITOR_DEFAULT_LOADING_MESSAGE"></div>
      <!-- Sub-messages -->
      @if (!!editor?.subLoadings?.length) {
        <div class="flex flex-column gap-3 px-4">
          @for (subItem of editor?.subLoadings; track $index) {
            <div class="flex flex-row align-items-center">
              <div class="flex w-2rem align-items-center justify-content-center relative">
                @if (!subItem?.error) {
                  @if (subItem?.isLoading === true) {
                    <i class="pi pi-spin pi-spinner text-highlight absolute"></i>
                  } @else if (subItem?.isLoading === false) {
                    <i class="pi pi-check text-green absolute"></i>
                  }
                } @else {
                  <i class="pi pi-times text-red absolute"></i>
                }
              </div>
              <div
                class="flex"
                [ngClass]="{
                  'text-green': subItem?.isLoading === false,
                  'text-gray-600': subItem?.isLoading === null,
                  'text-white': !!subItem?.error || subItem?.isLoading === true
                }">
                {{ subItem?.label }}
              </div>
            </div>
          }
        </div>
      }
      <!-- Close Button -->
      @if (!!shouldShowCloseButton) {
        <div class="flex mt-4 align-items-center justify-content-center">
          <p-button severity="primary" label="Close" (onClick)="close()" />
        </div>
      }
    </div>
  </div>
</div>
