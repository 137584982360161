import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { ILicenseService } from './ILicenseService';

const API_LICENSE = `${API.FEATURE_MANAGER}/license`;

@Injectable({
  providedIn: 'root',
})
export class LicenseService implements ILicenseService {
  constructor(private http: HttpClient) {}

  getAllLicenses(): Observable<any> {
    return this.http.get<any>(`${API_LICENSE}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getLicense(id: any): Observable<any> {
    return this.http.get<any>(`${API_LICENSE}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  uploadLicense(payload: any): Observable<any> {
    return this.http.post<any>(`${API_LICENSE}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteLicense(id: any): Observable<any> {
    return this.http.delete<any>(`${API_LICENSE}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAllLicenses(): Observable<any> {
    return this.http.delete<any>(`${API_LICENSE}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getMachineID(): Observable<any> {
    return this.http.get<any>(`${API.FEATURE_MANAGER}/machine-id`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
