import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { ILayoutItem } from '@microsec/interfaces';
import { BaseComponent } from '@ids-components';

import { NETWORK_SETTINGS_CHILDREN } from './network-settings.config';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { CONNECTION_FEATURES } from '@ids-constants';

@Component({
  selector: 'app-network-settings',
  templateUrl: './network-settings.component.html',
  styleUrls: ['./network-settings.component.scss'],
})
export class NetworkSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkConnectionFeatureEnabled(CONNECTION_FEATURES.ENABLED)) {
      if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
        const tabItems: ILayoutItem[] = this.util.cloneObjectArray(NETWORK_SETTINGS_CHILDREN).filter((p) => !!p.label);
        this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      }
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
