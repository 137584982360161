import { CommonToolbarConfiguration } from '@microsec/models';

export const DEVICE_STATUSES = {
  ANOMALY: 'Anomaly',
  NORMAL: 'Normal',
};

export const INTERFACE_TYPES = {
  ETH: 'eth',
  IP: 'ip',
  TCP: 'tcp',
  UDP: 'udp',
  LORAWAN: 'lorawan',
  BLE: 'ble',
  CAN: 'can',
  TCP6: 'tcp6',
  UDP6: 'udp6',
  MODBUS: 'modbus',
  MODBUS_RTU: 'modbus_rtu',
};

export const INTERFACE_TYPE_LABELS = {
  [INTERFACE_TYPES.ETH]: 'ETH',
  [INTERFACE_TYPES.IP]: 'IP',
  [INTERFACE_TYPES.TCP]: 'TCP',
  [INTERFACE_TYPES.UDP]: 'UDP',
  [INTERFACE_TYPES.LORAWAN]: 'LoRaWAN',
  [INTERFACE_TYPES.BLE]: 'BLE',
  [INTERFACE_TYPES.CAN]: 'CAN',
  [INTERFACE_TYPES.TCP6]: 'TCP6',
  [INTERFACE_TYPES.UDP6]: 'UDP6',
  [INTERFACE_TYPES.MODBUS]: 'Modbus',
  [INTERFACE_TYPES.MODBUS_RTU]: 'Modbus RTU',
};

export const INTERFACE_TYPE_OPTIONS = [
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.ETH], value: INTERFACE_TYPES.ETH, color: '#234b7b' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.IP], value: INTERFACE_TYPES.IP, color: '#6a60ab' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.TCP], value: INTERFACE_TYPES.TCP, color: '#744700' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.UDP], value: INTERFACE_TYPES.UDP, color: '#c90076' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.LORAWAN], value: INTERFACE_TYPES.LORAWAN, color: '#c39873' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.BLE], value: INTERFACE_TYPES.BLE, color: '#7347ad' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.CAN], value: INTERFACE_TYPES.CAN, color: '#5ca985' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.MODBUS], value: INTERFACE_TYPES.MODBUS, color: '#73d938' },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.MODBUS_RTU], value: INTERFACE_TYPES.MODBUS_RTU, color: '#73d938' },
];

export const LEVEL_OPTIONS = [
  { label: '0', value: 0, subLabel: 'Process Control' },
  { label: '1', value: 1, subLabel: 'Basic Control' },
  { label: '2', value: 2, subLabel: 'Supervisory Control' },
  { label: '3', value: 3, subLabel: 'Production Control' },
  { label: '4', value: 4, subLabel: 'Enterprise Control' },
  { label: 'Not Defined', value: -1, subLabel: '' },
];

export const APPLICATION_PROTOCOL_OPTIONS = [
  { label: 'HTTP', value: 'http' },
  { label: 'FTP', value: 'ftp' },
  { label: 'SSH', value: 'ssh' },
  { label: 'Modbus', value: 'modbus' },
  { label: 'LoRaWAN', value: 'lorawan' },
  { label: 'BLE', value: 'ble' },
  { label: 'Zigbee', value: 'zigbee' },
  { label: 'SigFox', value: 'sigfox' },
  { label: 'BACnet', value: 'bacnet' },
  { label: 'GOOSE', value: 'goose' },
  { label: 'MQTT', value: 'mqtt' },
  { label: 'STP', value: 'stp' },
  { label: 'RTSP', value: 'rtsp' },
  { label: 'LLC', value: 'llc' },
  { label: 'Data', value: 'data' },
];

function getDeviceTypeOptions() {
  const deviceTypeOptions = [
    { label: 'PLC', value: 'plc' },
    { label: 'RTU', value: 'rtu' },
    { label: 'HMI', value: 'hmi' },
    { label: 'Sensor', value: 'sensor' },
    { label: 'Actuator', value: 'actuator' },
    { label: 'Robot', value: 'robot' },
    { label: 'Gateway', value: 'gateway' },
    { label: 'Router', value: 'router' },
    { label: 'Switch', value: 'switch' },
    { label: 'Firewall', value: 'firewall' },
    { label: 'Camera', value: 'camera' },
    { label: 'CCTV', value: 'cctv' },
    { label: 'Barcode Scanner', value: 'barcode_scanner' },
    { label: 'IED', value: 'ied' },
    { label: 'Application Server', value: 'application_server' },
    { label: 'Workstation', value: 'workstation' },
    { label: 'Desktop', value: 'desktop' },
    { label: 'Laptop', value: 'laptop' },
    { label: 'Server', value: 'server' },
    { label: 'NAS', value: 'nas' },
    { label: 'VPN Gateway', value: 'vpn_gateway' },
    { label: 'Printer', value: 'printer' },
    { label: 'Phone', value: 'phone' },
    { label: 'NTP Server', value: 'ntp_server' },
    { label: 'SCADA', value: 'scada' },
    { label: 'Protocol Converter', value: 'protocol_converter' },
    { label: 'Access Point', value: 'access_point', otherValues: ['WAP'] },
  ];
  deviceTypeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  return deviceTypeOptions;
}

export const DEVICE_TYPE_OPTIONS = [...getDeviceTypeOptions(), { label: 'Others', value: 'others' }];

export const TAGS_PRESET_COLORS = ['#0277FF', '#00CDE8', '#00AD74', '#FB4D58', '#FF9534', '#6E5AE8', '#AAAAAA'];

export const DETECTED_DEVICE_FILTER_CONFIGURATION: CommonToolbarConfiguration = {
  types: ['search', 'filter'],
  searchPlaceholder: 'Search Device Name, Source IP Address (CIDR supported) or MAC Address...',
  filters: {
    1: {
      key: 'usesAgent',
      label: 'Agent/Agentless',
      type: 'dropdown',
      options: [
        { label: 'Agent', value: true },
        { label: 'Agentless', value: false },
      ],
    },
    2: {
      key: 'connections',
      label: 'Connections',
      type: 'multiselect',
      options: [],
    },
    3: {
      key: 'createdDates',
      label: 'Created Date',
      type: 'date-range',
    },
    4: {
      key: 'criticality',
      label: 'Criticality',
      type: 'slider',
    },
    5: {
      key: 'isImported',
      label: 'Import Status',
      type: 'dropdown',
      options: [
        { label: 'Imported', value: true },
        { label: 'Not Imported', value: false },
      ],
    },
    6: {
      key: 'interfaces',
      label: 'Interface Types',
      type: 'multiselect',
      options: INTERFACE_TYPE_OPTIONS,
    },
    7: {
      key: 'lastSeenDates',
      label: 'Last Seen',
      type: 'date-range',
    },
    8: {
      key: 'tags',
      label: 'Tags',
      type: 'multiselect',
      options: [],
    },
    9: {
      key: 'zones',
      label: 'Zones',
      type: 'multiselect',
      options: [],
    },
  },
};

export const TLS_CONFIG_FIELDS = [
  { key: 'tls_version', label: 'TLS Version', type: 'string' },
  { key: 'tls_cipher_suite', label: 'TLS Cipher Suite', type: 'string' },
  { key: 'tls_signature_algorithm', label: 'TLS Signature Algorithm', type: 'string' },
  { key: 'tls_key_type', label: 'TLS Key Type', type: 'string' },
  { key: 'tls_key_size', label: 'TLS Key Size', type: 'number' },
  { key: 'tls_certificate_has_ocsp_and_crl', label: 'OCSP and CRL Enabled', type: 'boolean' },
  { key: 'tls_is_trusted', label: 'TLS Trusted', type: 'boolean' },
  { key: 'last_seen', label: 'Last Seen', type: 'datetime' },
];
