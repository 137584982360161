import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService, TargetDeviceService } from '@ids-services';
import { CommonToolbarResult, CommonToolbarConfiguration } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';
import { ReportSpecification } from '@microsec/models';
import { BehaviorSubject, finalize } from 'rxjs';

import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

const FIELDS = {
  id: 'ID',
  label: 'Name',
  src_mac_addr: 'MAC Address',
  src_ip_addr: 'IP Addresses',
};

@Component({
  selector: 'app-fl-analyzer-devices',
  templateUrl: './fl-analyzer-devices.component.html',
  styleUrls: ['./fl-analyzer-devices.component.scss'],
})
export class FlAnalyzerDevicesComponent extends BaseComponent implements OnInit {
  isLoading = true;

  _analyzer: any = null;

  get analyzer() {
    return this._analyzer;
  }

  @Input() set analyzer(value: any) {
    this._analyzer = value;
    this.setValues();
  }

  devices: any[] = [];

  values: any[] = [];

  cols = [
    { field: 'label', header: FIELDS.label, width: 20 },
    { field: 'src_mac_addr', header: FIELDS.src_mac_addr, width: 15 },
    { field: 'src_ip_addr', header: FIELDS.src_ip_addr, width: 15 },
  ];

  globalFilterFields: string[] = ['id', 'label'];

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.handleFilterObjUpdate();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.getDevices();
  }

  getDevices() {
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        detailed: false,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.setValues();
        }),
      )
      .subscribe({
        next: (res) => {
          const devices = ((res?.devices as any[]) || []).map((device) => ({
            ...device,
            src_mac_addr: this.getAddress(device.eth),
            src_ip_addr: this.getAddress(device.ip, true),
          }));
          this.devices = devices;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  setValues() {
    const values = this.devices.filter((device) => ((this.analyzer?.device_ids as any[]) || []).includes(device.id));
    this.values = values;
  }

  getAddress(value: any, isMultiple = false): any {
    if (!isMultiple) {
      return !!value ? Object.keys(value)?.[0] || '-' : '-';
    }
    const addresses = !!value ? Object.keys(value) : [];
    return {
      label: !!addresses.length ? Object.keys(value)?.[0] + (addresses.length > 1 ? '...' : '') : '-',
      tooltip: !!addresses.length ? addresses.join(', ') : '',
    };
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result.search || '';
      }
    });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        this.dt.generateReportDialog.exportReport(specification.data || [], `FL_Analyzer_${this.analyzer.id}_devices`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }
}
