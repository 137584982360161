import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NETWORK_MAP_MODE_VALUES } from '@ids-constants';

import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-network-map-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent {
  @Input() mode = NETWORK_MAP_MODE_VALUES.PURDUE_MODEL;

  @Input() settings: any[] = [];

  @Input() itemList$ = new BehaviorSubject<any>(null);

  @Input() itemDetails$ = new BehaviorSubject<any>(null);

  @Input() diagramData$ = new BehaviorSubject<any>(null);

  @Input() changedItems$ = new BehaviorSubject<any>(null);

  @Input() changedItemsObs = new Observable<any>();

  @Input() selectedDevice = null;

  @Output() selectedDeviceChange = new EventEmitter<number>();

  @Input() settingOptionsObs = new Observable<any>();

  @Input() refreshObs = new Observable<any>();

  @Input() isItemDetailsDisplayed = false;

  activeIndex = 0;

  hideItemList() {
    this.itemList$.next(false);
  }

  changeSelectedDevice(value: any) {
    this.selectedDevice = value;
    this.selectedDeviceChange.emit(value);
  }

  get listName() {
    return this.activeIndex === 0 ? 'Device List' : 'Threat List';
  }
}
