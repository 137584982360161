import { BASE_API } from '@microsec/constants';
import { API as SHARED_API, API_VERSION } from '@microsec/services';

export const API = {
  ...SHARED_API,
  EVENT_MONITOR: `${BASE_API}/evm/${API_VERSION}`,
  ACCESS_POLICY: `${BASE_API}/access-policy/api/${API_VERSION}`,
  ANOMALY_ANALYZER: `${BASE_API}/anom/${API_VERSION}`,
  ASSESSMENT_MANAGER: `${BASE_API}/am/${API_VERSION}`,
  CONNECTION_MANAGER: `${BASE_API}/cm/${API_VERSION}`,
  FOTA_MANAGER: `${BASE_API}/fota-manager/${API_VERSION}`,
  HYBRID_MONITOR: `${BASE_API}/hyb/${API_VERSION}`,
  IDS: `${BASE_API}/ids-api/${API_VERSION}`,
  NETWORK_DEVICE_MANAGER: `${BASE_API}/ndm/${API_VERSION}`,
  REPORT_MANAGER: `${BASE_API}/rm/${API_VERSION}`,
  TARGET_DEVICE_MANAGER: `${BASE_API}/target-device-manager/${API_VERSION}`,
  THREAT_MANAGER: `${BASE_API}/tm/${API_VERSION}`,
  VULNERABILITY_ANALYZER: `${BASE_API}/vuln/${API_VERSION}`,
};
