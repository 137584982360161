<div class="time-range-input" (click)="timeRangePanel.toggle($event)">
  <div *ngIf="dateRange?.length === 2">{{ dateRange?.[0] | momentPipe }} - {{ dateRange?.[1] | momentPipe }}</div>
  <div *ngIf="dateRange?.length !== 2 && selectedTimeRangeItem !== TIME_RANGE_ITEMS.ALL_TIME.ID">Select time range</div>
  <div *ngIf="dateRange?.length !== 2 && selectedTimeRangeItem === TIME_RANGE_ITEMS.ALL_TIME.ID">All Time</div>
  <div class="flex align-items-center gap-2">
    <span *ngIf="dateRange?.length === 2 || selectedTimeRangeItem === TIME_RANGE_ITEMS.ALL_TIME.ID" (click)="onClearTime()">
      <i class="pi pi-times cursor-pointer"></i>
    </span>
    <i class="pi pi-chevron-down"></i>
  </div>
</div>
<p-overlayPanel #timeRangePanel [dismissable]="false" styleClass="time-range-overlay">
  <div class="flex gap-2">
    <div>
      <p-menu [model]="timeRangeItems" styleClass="time-range-menu"></p-menu>
    </div>
    <ng-container *ngIf="selectedTimeRangeItem === TIME_RANGE_ITEMS.CUSTOM_RANGE.ID">
      <p-divider layout="vertical" styleClass="m-0"></p-divider>
      <div class="w-25rem relative">
        <div>
          <p-calendar
            styleClass="time-range-calendar"
            selectionMode="range"
            [(ngModel)]="dateRange"
            [inline]="true"
            [hideOnDateTimeSelect]="false"
            (onSelect)="checkDateValidity()"
            [minDate]="config.minDate"
            [maxDate]="config.maxDate"></p-calendar>
        </div>
      </div>
    </ng-container>
  </div>
</p-overlayPanel>
