import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import {
  ANALYZER_TYPES,
  DISPLAY,
  IPPROTOCOLS,
  NON_IP_PROTOCOLS,
  STEP_TEMPLATE_LABEL,
  ANALYZER_DATA_TYPES,
  CONN_TYPE,
  ANALYZER_RULE_DOWNLOAD_FREQUENCY_OPTIONS,
  PROTOCOL_TYPE,
  ANALYZER_RULE_TYPE_OPTIONS,
  IP_PROTOCOLS_PAYLOAD,
  CONNECTION_TYPES,
  PROJECT_MANAGEMENT_CONSTANTS,
} from '@ids-constants';
import {
  CREATE_LABEL,
  SAVE_CHANGES_LABEL,
  DOCUMENTATION_URL,
  DOMAIN_TOKEN,
  VALIDATOR_TYPE,
  MODULE_CONSTANTS,
  ORGANIZATION_ID_PARAM_ROUTE,
  PROJECT_ID_PARAM_ROUTE,
  SCOPE,
} from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { ActionMenuItem, FormItem } from '@microsec/models';
import { AnomalyAnalyzerService, ConnectionService } from '@ids-services';
import { validatorTrimRequired } from '@microsec/validators';

import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, finalize, forkJoin } from 'rxjs';

import { AnalyzerNonipRulesetFieldComponent } from './analyzer-nonip-ruleset-field/analyzer-nonip-ruleset-field.component';
import { PROJECT_SETTINGS_CONSTANTS } from '@ids-products';

export const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  PROJECT_ID: 'project_id',
  CONNECTION_IDS: 'connection_ids',
  ANALYZER_TYPE: 'analyzer_type',
  RULE_TYPES: 'rule_types',
  RULE_SETS: 'rule_sets',
  RULE_DOWNLOAD_FREQUENCY: 'rule_download_frequency',
  PROTOCOL: 'protocol',
  ENABLED: 'enabled',
  DATA_TYPE: 'data_type',
  DATA_STRUCTURE_ID: 'data_structure_id',

  NONIP_RULESET: 'nonip_rule_types',
  RULE_PROTOCOLS: 'rule_protocols',
  RULE_SYNTAX: 'rule_syntax',

  // ------ MQTT ----------------
  TIMER_TIMEOUT: 'timeout',
  TIMER_GRACE: 'grace',
  TIMER_TIMEOUT_UNIT: 'timeoutUnit',
  TIMER_GRACE_UNIT: 'graceUnit',
  TIMER: 'timer',
  TIMER_ENABLED: 'timer_enabled',
  RULES: 'rules',
  MQTT_RULE_TYPE: 'type',
  MQTT_RULE_FORMULA: 'formula',
};

export const RULE_BASED_FIELDS = {
  GENERAL: [{ step: 0, fields: [FORM_PARAMS.NAME, FORM_PARAMS.DESCRIPTION, FORM_PARAMS.ENABLED, FORM_PARAMS.CONNECTION_IDS] }],
  [PROTOCOL_TYPE.IP]: [
    {
      step: 0,
      fields: [FORM_PARAMS.NAME, FORM_PARAMS.DESCRIPTION, FORM_PARAMS.ENABLED, FORM_PARAMS.CONNECTION_IDS, FORM_PARAMS.PROTOCOL],
    },
    {
      step: 1,
      fields: [FORM_PARAMS.RULE_DOWNLOAD_FREQUENCY, FORM_PARAMS.RULE_TYPES],
    },
  ],
  [PROTOCOL_TYPE.NONIP]: [
    {
      step: 0,
      fields: [FORM_PARAMS.NAME, FORM_PARAMS.DESCRIPTION, FORM_PARAMS.ENABLED, FORM_PARAMS.CONNECTION_IDS, FORM_PARAMS.PROTOCOL],
    },
    { step: 1, fields: [FORM_PARAMS.NONIP_RULESET] },
  ],
  [PROTOCOL_TYPE.MQTT]: [
    {
      step: 0,
      fields: [FORM_PARAMS.NAME, FORM_PARAMS.DESCRIPTION, FORM_PARAMS.ENABLED, FORM_PARAMS.CONNECTION_IDS, FORM_PARAMS.DATA_STRUCTURE_ID],
    },
    { step: 1, fields: [FORM_PARAMS.TIMER] },
    { step: 2, fields: [FORM_PARAMS.RULES] },
  ],
};

export const CUSTOM_FIELDS = [FORM_PARAMS.TIMER, FORM_PARAMS.TIMER_ENABLED, FORM_PARAMS.RULES, FORM_PARAMS.RULE_TYPES, FORM_PARAMS.NONIP_RULESET];

export const REQUIRED_FIELDS = [FORM_PARAMS.RULE_DOWNLOAD_FREQUENCY];

const CONSTANTS = {
  PROTOCOL_TYPE: 'type',
  CONN_TYPE: 'connection_type',
};

const UNIT_OPTIONS: any[] = [
  { label: 'Seconds', value: 's' },
  { label: 'Minutes', value: 'm' },
  { label: 'Hours', value: 'h' },
];

@Component({
  selector: 'app-rule-based-analyzer-form',
  templateUrl: './rule-based-analyzer-form.component.html',
  styleUrls: ['./rule-based-analyzer-form.component.scss'],
})
export class RuleBasedAnalyzerFormComponent extends BaseComponent implements AfterViewInit {
  analyzer: any = null;
  connections: any[] = [];
  connectionOptions: any[] = [];
  protocols: any = null;
  fields: FormItem[] = [];
  activeStep = 0;
  steps: MenuItem[] = [];

  hasOinkcodes = false;

  // Object containing all types information of this analyzer
  // Type of analyzer that are being created: NONIP, IP (packet), MQTT (payload), null (Not yet defined by connection)
  typeObj: any = {
    [FORM_PARAMS.ANALYZER_TYPE]: null,
    [FORM_PARAMS.DATA_TYPE]: null,
    [CONSTANTS.CONN_TYPE]: null,
    [CONSTANTS.PROTOCOL_TYPE]: null,
  };

  //--------------- For MQTT Connection ------------------
  mqttSelectedRules: any[] = [];

  mqttRulesCols: any[] = [{ field: 'formula', header: 'Formula', width: 38 }];

  codeMirrorOptions: CodeMirror.EditorConfiguration = {
    theme: 'base16-dark',
    mode: 'spreadsheet',
    showCursorWhenSelecting: false,
  };

  mqttRuleActionItems: ActionMenuItem[] = [];

  // ---------------------------------------------

  @ViewChild('fb') form!: FormBuilderComponent;
  @ViewChild('stepsTemplate') stepsTemplate!: TemplateRef<any>;
  @ViewChild('timerField') timerField!: TemplateRef<any>;
  @ViewChild('rulesField') rulesField!: TemplateRef<any>;
  @ViewChild('ruleTypesField') ruleTypesField!: TemplateRef<any>;
  @ViewChild('nonipRulesField') nonipRulesField!: TemplateRef<any>;
  @ViewChild('nonipRulesetFieldComponent') nonipRulesetFieldComponent!: AnalyzerNonipRulesetFieldComponent;

  @ViewChildren('codeMirrorComponent')
  codeMirrorComponents!: CodemirrorComponent[];

  FORM_PARAMS = FORM_PARAMS;

  ANALYZER_RULE_TYPE_OPTIONS = ANALYZER_RULE_TYPE_OPTIONS;

  ANALYZER_TYPES = ANALYZER_TYPES;

  CONSTANTS = CONSTANTS;

  PROTOCOL_TYPE = PROTOCOL_TYPE;

  UNIT_OPTIONS = UNIT_OPTIONS;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private connectionSrv: ConnectionService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.analyzer = this.dialogConfig?.data?.analyzer;
    this.activeStep = 0;
    this.steps = [...STEP_TEMPLATE_LABEL.DEFAULT];
    this.mqttRuleActionItems = [
      {
        label: 'Delete',
        icon: 'fa fa-trash',
        command: ({ index }) => this.removeRule(index),
      },
    ];
    this.getData();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        hasNoLabel: true,
        field: 'custom',
        customField: this.stepsTemplate,
        hidden: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: !this.analyzer || this.activeStep === 0,
        fieldInfo: 'Name of the analyzer',
        defaultValue: '',
        focused: true,
        hidden: this.activeStep !== 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the analyzer',
        defaultValue: '',
        hidden: this.activeStep !== 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONNECTION_IDS,
        label: 'Connection',
        required: !this.analyzer || this.activeStep === 0,
        field: 'dropdown',
        options: this.connectionOptions || [],
        placeholder: 'Select connection',
        fieldInfo: `Analyzer's connection ID`,
        defaultValue: null,
        hidden: this.activeStep !== 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DATA_STRUCTURE_ID,
        label: 'Data Structure ID',
        fieldInfo: 'Data structure ID of the analyzer',
        defaultValue: '',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROTOCOL,
        label: 'Protocol(s)',
        field: 'radio',
        defaultValue: null,
        fieldInfo: 'Analyzer protocols',
        options: [
          { label: 'IP', value: ANALYZER_TYPES.RULE_BASED },
          { label: 'Non-IP', value: ANALYZER_TYPES.RULE_BASED_NONIP },
        ],
        disabled: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        defaultValue: true,
        fieldInfo: 'Enable analyzer',
        hidden: this.activeStep !== 0,
      } as FormItem),

      // Step 2: IP Connection (Packet)
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_TYPES,
        label: 'Rule Type',
        field: 'custom',
        customField: this.ruleTypesField,
        fieldInfo: 'Rule type of the analyzer',
        defaultValue: ANALYZER_RULE_TYPE_OPTIONS.map((opt) => opt.value),
        hidden: this.activeStep !== 1 || this.typeObj[CONSTANTS.PROTOCOL_TYPE] !== PROTOCOL_TYPE.IP,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_DOWNLOAD_FREQUENCY,
        label: 'Rule Download Frequency',
        field: 'dropdown',
        options: this.util.cloneObjectArray(ANALYZER_RULE_DOWNLOAD_FREQUENCY_OPTIONS),
        placeholder: 'Select frequency',
        fieldInfo: 'Rule download frequency',
        defaultValue: null,
        required: this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.IP && (!this.analyzer || this.activeStep === 1),
        hidden: this.typeObj[CONSTANTS.PROTOCOL_TYPE] !== PROTOCOL_TYPE.IP || this.activeStep !== 1,
      } as FormItem),

      // NON-IP Form Step 2
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NONIP_RULESET,
        hasNoLabel: true,
        field: 'custom',
        customField: this.nonipRulesField,
        defaultValue: [],
        hidden: this.activeStep !== 1,
      } as FormItem),

      // MQTT Form Fields
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TIMER_ENABLED,
        defaultValue: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TIMER,
        hasNoLabel: true,
        field: 'custom',
        customField: this.timerField,
        defaultValue: {},
        hidden: this.activeStep !== 1,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULES,
        hasNoLabel: true,
        field: 'custom',
        customField: this.rulesField,
        defaultValue: [],
        hidden: this.activeStep !== 2,
      } as FormItem),
    ];

    this.fields = fields;
    setTimeout(() => {
      // --------------- Event listeners -----------------------
      this.form?.setChangeEvent(FORM_PARAMS.CONNECTION_IDS, (value: any) => {
        this.onConnectionChange(value);
      });

      this.form?.setChangeEvent(FORM_PARAMS.PROTOCOL, (value: any) => {
        this.onProtocolChange(value);
      });

      // ------------- MQTT connection ----------------------
      this.form?.form?.setControl(
        FORM_PARAMS.TIMER,
        this.fb.group({
          [FORM_PARAMS.TIMER_TIMEOUT]: [null, Validators.min(1)],
          [FORM_PARAMS.TIMER_GRACE]: [null, Validators.min(0)],
          [FORM_PARAMS.TIMER_TIMEOUT_UNIT]: [!!this.analyzer ? 's' : 'm'],
          [FORM_PARAMS.TIMER_GRACE_UNIT]: [!!this.analyzer ? 's' : 'm'],
        }),
      );
      this.form?.form?.setControl(FORM_PARAMS.RULES, this.fb.array([]));
      this.form?.setChangeEvent(FORM_PARAMS.TIMER_ENABLED, (value: boolean) => {
        this.form?.form
          ?.get([FORM_PARAMS.TIMER, FORM_PARAMS.TIMER_TIMEOUT])
          ?.setValidators(Validators.compose([Validators.min(1), ...(!!value ? [Validators.required] : [])]));
        this.form?.form
          ?.get([FORM_PARAMS.TIMER, FORM_PARAMS.TIMER_GRACE])
          ?.setValidators(Validators.compose([Validators.min(0), ...(!!value ? [Validators.required] : [])]));
        this.form?.form?.get([FORM_PARAMS.TIMER, FORM_PARAMS.TIMER_TIMEOUT])?.updateValueAndValidity();
        this.form?.form?.get([FORM_PARAMS.TIMER, FORM_PARAMS.TIMER_GRACE])?.updateValueAndValidity();
        this.form?.setControlValidators(FORM_PARAMS.RULES, !value ? [VALIDATOR_TYPE.REQUIRED] : []);
      });

      // Edit mode populate form
      if (!!this.analyzer) {
        this.editFormFields();
      }

      this.cd.detectChanges();
    });
  }

  getData() {
    this.form.isLoading = true;
    forkJoin({
      connections: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      protocols: this.connectionSrv.getProtocols(),
      oinkcodes: this.anomalyAnalyzerSrv.getOinkcodes(),
    })
      .pipe(
        finalize(() => {
          this.initForm();

          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          // For MQTT Agent/MicroAgent connections or Microids connections should not be able to be analyzed
          this.connections =
            (res.connections.data as any[]).filter(
              (connection) =>
                connection.interface?.type === CONNECTION_TYPES.MQTT ||
                connection.interface?.type === CONNECTION_TYPES.PCAP ||
                connection.interface?.type === CONNECTION_TYPES.PHYSICAL,
            ) || [];
          this.connectionOptions = ((this.connections as any[]) || []).map((con) => ({
            label: con.name || con.id,
            value: con.id,
          }));
          this.protocols = res.protocols || {};

          const oinkcodes = ((res.oinkcodes?.oinkcodes as any[]) || []).filter(
            (oinkcode) =>
              oinkcode.scope === SCOPE.GLOBAL ||
              (oinkcode.scope === SCOPE.ORGANIZATION && oinkcode.organization_id === this.breadcrumbConfig?.organizationId) ||
              (oinkcode.scope === SCOPE.PROJECT &&
                oinkcode.organization_id === this.breadcrumbConfig?.organizationId &&
                oinkcode.project_id === this.breadcrumbConfig?.projectId),
          );
          this.hasOinkcodes = !!oinkcodes.length;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  // Populate form when in edit mode
  editFormFields() {
    // Disable the connection selection
    this.form?.getControl(FORM_PARAMS.CONNECTION_IDS).disable();
    this.form?.getControl(FORM_PARAMS.PROTOCOL).disable();

    // Populate the typeObj and step template
    switch (this.analyzer.type) {
      case DISPLAY.IP:
        this.setTypeObj(ANALYZER_DATA_TYPES.PACKET, CONN_TYPE.IP, PROTOCOL_TYPE.IP, ANALYZER_TYPES.RULE_BASED);
        // Populate form data
        this.populateFormFields();
        this.form?.setControlValue(FORM_PARAMS.PROTOCOL, ANALYZER_TYPES.RULE_BASED);
        this.steps = [...STEP_TEMPLATE_LABEL.IP];
        break;
      case DISPLAY.NONIP:
        this.setTypeObj(ANALYZER_DATA_TYPES.PACKET, CONN_TYPE.NONIP, PROTOCOL_TYPE.NONIP, ANALYZER_TYPES.RULE_BASED_NONIP);
        // Populate form data
        this.populateFormFields();
        this.form?.setControlValue(FORM_PARAMS.PROTOCOL, ANALYZER_TYPES.RULE_BASED_NONIP);
        this.steps = [...STEP_TEMPLATE_LABEL.NONIP];
        break;
      case DISPLAY.MQTT:
        this.setTypeObj(ANALYZER_DATA_TYPES.PAYLOAD, CONN_TYPE.MQTT, PROTOCOL_TYPE.MQTT, ANALYZER_TYPES.RULE_BASED);
        this.steps = [...STEP_TEMPLATE_LABEL.MQTT];
        // Populate form data
        this.populateFormFields();
        break;
      default:
        this.showErrorMessage('Undefined analyzer type');
        break;
    }
  }

  onConnectionChange(connectionId: any) {
    const connection = this.connections.find((conn) => conn.id === connectionId) || null;
    // Hide the MQTT specific form fields and reset Protocol field
    this.form.setControlValidatorsAndVisibility(FORM_PARAMS.DATA_STRUCTURE_ID, []);
    this.form.setControlValueAndValidators(FORM_PARAMS.PROTOCOL, null, []);

    if (!!connection && !this.analyzer) {
      if (!connection.interface?.type.toLowerCase().includes(CONN_TYPE.MQTT)) {
        // PACKET Analyzer i.e. non-MQTT connection
        this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.PROTOCOL, [VALIDATOR_TYPE.REQUIRED]);

        const hasNonip = connection.protocols.some((protocol: any) => NON_IP_PROTOCOLS.includes(protocol));
        const hasIp = connection.protocols.some((protocol: any) => IPPROTOCOLS.includes(protocol));
        if (hasNonip && hasIp) {
          // Connection is Mixed: IP and NONIP
          // Set the Step Template
          this.steps = [...STEP_TEMPLATE_LABEL.DEFAULT];

          //Set default value protocol to IP
          this.form.setControlValue(FORM_PARAMS.PROTOCOL, ANALYZER_TYPES.RULE_BASED);

          // Set the type of Analyzer
          this.setTypeObj(ANALYZER_DATA_TYPES.PACKET, CONN_TYPE.MIX, undefined, undefined);

          this.form.getControl(FORM_PARAMS.PROTOCOL).enable();
        } else if (hasNonip) {
          // Set the Step Template
          this.steps = [...STEP_TEMPLATE_LABEL.NONIP];

          // Connection is ONLY NON-IP (will trigger onProtocolChange as well)
          this.form.setControlValue(FORM_PARAMS.PROTOCOL, ANALYZER_TYPES.RULE_BASED_NONIP);

          // Set the type of Analyzer
          this.setTypeObj(ANALYZER_DATA_TYPES.PACKET, CONN_TYPE.NONIP, PROTOCOL_TYPE.NONIP, ANALYZER_TYPES.RULE_BASED_NONIP);
        } else if (hasIp) {
          // Set the Step Template
          this.steps = [...STEP_TEMPLATE_LABEL.IP];

          // Connection is ONLY IP (will trigger onProtocolChange as well)
          this.form.setControlValue(FORM_PARAMS.PROTOCOL, ANALYZER_TYPES.RULE_BASED);

          // Set the type of Analyzer
          this.setTypeObj(ANALYZER_DATA_TYPES.PACKET, CONN_TYPE.IP, PROTOCOL_TYPE.IP, ANALYZER_TYPES.RULE_BASED);
        } else {
          // New Type of Protocols/ Undefined protocols
          this.showErrorMessage('Undefined protocol type (IP/NONIP). Please check with admins');
        }
      } else {
        // PAYLOAD Analyzer i.e. MQTT connection
        this.form.setControlVisibility(FORM_PARAMS.PROTOCOL, false);
        this.form.setControlValidatorsAndVisibility(FORM_PARAMS.DATA_STRUCTURE_ID, [VALIDATOR_TYPE.REQUIRED]);
        this.form.setControlValidators(FORM_PARAMS.RULES, [VALIDATOR_TYPE.REQUIRED]);

        // Set the type of Analyzer
        this.setTypeObj(ANALYZER_DATA_TYPES.PAYLOAD, CONN_TYPE.MQTT, PROTOCOL_TYPE.MQTT, ANALYZER_TYPES.RULE_BASED);

        // Set the Step Template
        this.steps = [...STEP_TEMPLATE_LABEL.MQTT];
      }
    }
  }

  // Callback when there is protocol changed IP or NON-IP
  onProtocolChange(value: any) {
    if (!this.analyzer) {
      switch (value) {
        case ANALYZER_TYPES.RULE_BASED:
          // Setup Step Template
          this.steps = [...STEP_TEMPLATE_LABEL.IP];
          // Set the type of Analyzer
          this.setTypeObj(ANALYZER_DATA_TYPES.PACKET, CONN_TYPE.MIX, PROTOCOL_TYPE.IP, ANALYZER_TYPES.RULE_BASED);
          break;
        case ANALYZER_TYPES.RULE_BASED_NONIP:
          this.steps = [...STEP_TEMPLATE_LABEL.NONIP];
          // Set the type of Analyzer
          this.setTypeObj(ANALYZER_DATA_TYPES.PACKET, CONN_TYPE.MIX, PROTOCOL_TYPE.NONIP, ANALYZER_TYPES.RULE_BASED_NONIP);
          break;
        default:
          break;
      }
    }
  }

  get isCurrentStepValid() {
    if (!!this.typeObj) {
      switch (this.activeStep) {
        case 0: {
          return (
            this.form?.getControl(FORM_PARAMS.NAME)?.valid &&
            this.form?.getControl(FORM_PARAMS.DESCRIPTION)?.valid &&
            (!this.analyzer ? this.form?.getControl(FORM_PARAMS.CONNECTION_IDS)?.valid : true) &&
            (this.typeObj[CONSTANTS.CONN_TYPE] === CONN_TYPE.MQTT ? this.form?.getControl(FORM_PARAMS.DATA_STRUCTURE_ID)?.valid : true) &&
            (!this.analyzer ? this.form?.getControl(FORM_PARAMS.PROTOCOL)?.valid : true) &&
            this.form?.getControl(FORM_PARAMS.ENABLED)?.valid
          );
        }
        case 1: {
          return this.form?.getControl(FORM_PARAMS.TIMER)?.valid;
        }
        case 2: {
          return this.form?.getControl(FORM_PARAMS.RULES)?.valid;
        }
        default: {
          return false;
        }
      }
    }
    return false;
  }

  onActiveStepChange(step: number) {
    this.activeStep += step;
    // Find the next/previous field template from constant file
    const fieldsMetadata = RULE_BASED_FIELDS[this.typeObj[CONSTANTS.PROTOCOL_TYPE]] || null;
    const fieldsToDisplay: any = fieldsMetadata?.find((field) => field.step === this.activeStep)?.fields;
    const fieldsToHide = fieldsMetadata?.find((field) => field.step === this.activeStep - step)?.fields;

    if (fieldsToDisplay.length > 0 && !!fieldsToDisplay && !!fieldsMetadata) {
      switch (step) {
        case 1: // Next Step
          // Show the fields
          fieldsToDisplay.forEach((fieldName: any) => {
            this.form?.setControlVisibility(fieldName, true);
            if (this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.IP && !!REQUIRED_FIELDS.includes(fieldName)) {
              this.form?.setControlValidators(fieldName, [VALIDATOR_TYPE.REQUIRED]);
            }
            if (this.activeStep === 2 && fieldName === FORM_PARAMS.RULES && !this.form.getControlValue(FORM_PARAMS.TIMER_ENABLED)) {
              this.form?.setControlValidators(fieldName, [VALIDATOR_TYPE.REQUIRED]);
            }
          });
          // Hide the fields
          fieldsToHide?.forEach((fieldName) => {
            this.form?.setControlVisibility(fieldName, false);
          });

          // Refresh the code mirror component to show values
          if (!!this.analyzer && this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.MQTT) {
            setTimeout(() => {
              this.codeMirrorComponents.forEach((codeMirrorComponent) => {
                codeMirrorComponent?.codeMirror?.refresh();
              });
            }, 1);
          }

          break;
        case -1: // Previous Step
          // Show the fields
          fieldsToDisplay.forEach((fieldName: any) => {
            this.form?.setControlVisibility(fieldName, true);
          });
          // Hide the fields and removed required field to progress
          fieldsToHide?.forEach((fieldName) => {
            this.form?.setControlValidatorsAndVisibility(fieldName, []);
          });
          break;
        default:
          break;
      }
    }
  }

  onSubmit(closeDialog: (analyzer: any) => void) {
    this.form.isLoading = true;
    const fieldsMetadata = RULE_BASED_FIELDS[this.typeObj[CONSTANTS.PROTOCOL_TYPE]] || null;
    const fieldParams = fieldsMetadata.reduce((result: any[], current: any) => {
      const currentFields = current.fields as any;
      result.push(...currentFields);
      return result;
    }, []);
    const formValue = this.form.getRawValue();
    let payload = {
      ...formValue,
      [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
      [FORM_PARAMS.DATA_TYPE]: this.typeObj[FORM_PARAMS.DATA_TYPE],
      [FORM_PARAMS.ANALYZER_TYPE]: this.typeObj[FORM_PARAMS.ANALYZER_TYPE],
      [FORM_PARAMS.CONNECTION_IDS]: [formValue[FORM_PARAMS.CONNECTION_IDS]],
    };
    // Remove the unnecessary fields from the payload
    this.fields.forEach((field) => {
      if (!fieldParams.includes(field.name) && payload.hasOwnProperty(field.name)) {
        delete payload[field.name];
      }
    });
    // Set the appropriate payload content depends on protocol type
    switch (this.typeObj[CONSTANTS.PROTOCOL_TYPE]) {
      case PROTOCOL_TYPE.IP:
        payload = { ...this.setIPPacketPayload(payload) };
        break;
      case PROTOCOL_TYPE.NONIP:
        payload = {
          ...payload,
          ...this.setNonIPPayload(
            this.nonipRulesetFieldComponent?.rulesets,
            this.nonipRulesetFieldComponent?.customRules,
            this.nonipRulesetFieldComponent?.enabledRuleset,
            this.nonipRulesetFieldComponent?.enabledCustomRuleset,
          ),
        };
        if (payload.hasOwnProperty(FORM_PARAMS.PROTOCOL)) {
          delete payload[FORM_PARAMS.PROTOCOL];
        }
        break;
      case PROTOCOL_TYPE.MQTT:
        payload = { ...payload, ...this.setMQTTPayload(formValue) };
        if (payload.hasOwnProperty(FORM_PARAMS.PROTOCOL)) {
          delete payload[FORM_PARAMS.PROTOCOL];
        }
        if (payload.hasOwnProperty(FORM_PARAMS.NONIP_RULESET)) {
          delete payload[FORM_PARAMS.NONIP_RULESET];
        }
        break;
      default:
        break;
    }

    let request: Observable<any> | null = null;

    // Modify paylaod when doing patching
    if (!!this.analyzer) {
      delete payload[FORM_PARAMS.PROJECT_ID];
      delete payload[FORM_PARAMS.ANALYZER_TYPE];
      delete payload[FORM_PARAMS.CONNECTION_IDS];
      if (this.analyzer[FORM_PARAMS.ANALYZER_TYPE] === ANALYZER_TYPES.RULE_BASED_NONIP) {
        // Modify payload to suite BE api end point
        if (payload.hasOwnProperty(FORM_PARAMS.NONIP_RULESET)) {
          delete payload[FORM_PARAMS.NONIP_RULESET];
        }
        delete payload[FORM_PARAMS.DATA_TYPE];
        request = this.anomalyAnalyzerSrv.updateAnalyzer(this.analyzer.id, payload, true);
      } else {
        request = this.anomalyAnalyzerSrv.updateAnalyzer(this.analyzer.id, payload, false);
      }
    } else {
      request = this.anomalyAnalyzerSrv.createAnalyzer(payload);
    }

    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.showSuccessMessage(`Rule-Based Analyzer ${!!this.analyzer ? 'Updated' : 'Created'} Successfully`);
          if (this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.IP) {
            closeDialog({ ...rs, next: true });
          } else {
            closeDialog({ ...rs, next: false });
          }
        },
        error: (error) => {
          this.setMQTTRulesServerError(error);
          this.showErrorMessage(error);
        },
      });
  }

  private populateFormFields() {
    const fieldsMetadata = RULE_BASED_FIELDS[this.typeObj[CONSTANTS.PROTOCOL_TYPE]] || null;
    const fieldsToFill = fieldsMetadata?.reduce((result, obj) => result.concat(obj.fields as any), []);

    fieldsToFill.forEach((fieldName) => {
      if (!!this.fields.find((field) => field.name === fieldName)) {
        if (!!this.analyzer.hasOwnProperty(fieldName)) {
          if (fieldName === FORM_PARAMS.CONNECTION_IDS) {
            this.form?.setControlValue(fieldName, this.analyzer[fieldName][0] || null);
          } else {
            if (!CUSTOM_FIELDS.includes(fieldName)) {
              this.form?.setControlValue(fieldName, this.analyzer[fieldName]);
            }
          }
        }
      }
    });

    if (this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.MQTT) {
      this.form?.setControlValue(FORM_PARAMS.TIMER_ENABLED, !!this.analyzer[FORM_PARAMS.TIMER]);
      this.form?.setControlValidators(FORM_PARAMS.RULES, !this.analyzer[FORM_PARAMS.TIMER] ? [VALIDATOR_TYPE.REQUIRED] : []);

      this.form?.patchValue({
        [FORM_PARAMS.TIMER]: this.analyzer[FORM_PARAMS.TIMER],
      });
      this.analyzer.rules?.forEach((rule: any) => {
        this.mqttRulesFormArray?.push(
          this.fb.group({
            [FORM_PARAMS.MQTT_RULE_TYPE]: [rule[FORM_PARAMS.MQTT_RULE_TYPE] || 'excel'],
            [FORM_PARAMS.MQTT_RULE_FORMULA]: [rule[FORM_PARAMS.MQTT_RULE_FORMULA] || '', validatorTrimRequired],
          }),
        );
      });
      setTimeout(() => {
        this.codeMirrorComponents.forEach((codeMirrorComponent) => {
          setTimeout(() => {
            codeMirrorComponent?.codeMirror?.refresh();
          }, 1);
        });
      }, 500);
      this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.DATA_STRUCTURE_ID, [VALIDATOR_TYPE.REQUIRED]);
      this.form?.setControlValidators(FORM_PARAMS.RULES, !!this.analyzer[FORM_PARAMS.TIMER] ? [] : [VALIDATOR_TYPE.REQUIRED]);
    }
    if (this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.IP) {
      if (!!this.analyzer.hasOwnProperty(FORM_PARAMS.RULE_TYPES)) {
        const ruleTypes: any[] = this.analyzer[FORM_PARAMS.RULE_TYPES] || [];

        const ruleTypesValues = ruleTypes.map((rule) => {
          if (!!rule.enabled) {
            return rule.type;
          }
        });
        this.form?.setControlValue(FORM_PARAMS.RULE_TYPES, ruleTypesValues);
      }
      this.form?.setControlVisibility(FORM_PARAMS.PROTOCOL, true);
    }
    if (this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.NONIP) {
      this.form?.setControlVisibility(FORM_PARAMS.PROTOCOL, true);
    }
  }

  //----------------- NON-IP connection-----------------------
  setNonIPPayload(builtins: any[], customs: any[], enabledBuiltin: boolean, enabledCustom: boolean): any {
    const customRuleArray = this.modifyCustomRules(customs) || [];
    const payload = {
      [FORM_PARAMS.RULE_TYPES]: [
        {
          id: 1,
          enabled: !!enabledBuiltin,
          [FORM_PARAMS.RULE_SETS]: [...((builtins as any[]) || []).map((rule) => ({ id: rule.id, enabled: rule.enabled }))],
        },
        {
          id: 2,
          enabled: !!enabledCustom,
          [FORM_PARAMS.RULE_SETS]: [
            {
              id: 5,
              enabled: !!enabledCustom,
              [FORM_PARAMS.RULES]: [...customRuleArray],
            },
          ],
        },
      ],
    };
    return payload;
  }

  modifyCustomRules(customs: any[]): any[] {
    const result: any[] = ((customs as any[]) || []).map((rule) => {
      let rulePayload = {};
      if (rule.id === null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...childElement } = rule;
        rulePayload = {
          ...childElement,
          [FORM_PARAMS.RULE_PROTOCOLS]: [...this.findKeywords(rule.rule)],
        };
      } else {
        rulePayload = {
          ...rule,
          [FORM_PARAMS.RULE_PROTOCOLS]: [...this.findKeywords(rule.rule)],
        };
      }

      return rulePayload;
    });
    return result;
  }

  findKeywords(input: string): string[] {
    const matches = input.match(/[a-zA-Z0-9_-]+(?=\.)/);

    if (matches && matches.length > 0) {
      return [matches[0]];
    } else {
      return [];
    }
  }

  //----------------- IP (packet) connection------------------
  setIPPacketPayload(payload: any): any {
    const ruleTypes: any[] = payload[FORM_PARAMS.RULE_TYPES] || [];

    const updatedRuleTypes = ANALYZER_RULE_TYPE_OPTIONS.map((option) => ({
      type: option.value,
      enabled: ruleTypes.includes(option.value),
    }));

    return {
      ...payload,
      [FORM_PARAMS.RULE_TYPES]: updatedRuleTypes,
      [FORM_PARAMS.PROTOCOL]: [...IP_PROTOCOLS_PAYLOAD],
    };
  }

  //----------------- MQTT connection ---------------------
  setMQTTPayload(formValue: any): any {
    let payload = {};
    const timeoutUnit = formValue[FORM_PARAMS.TIMER]?.[FORM_PARAMS.TIMER_TIMEOUT_UNIT] || 's';
    const graceUnit = formValue[FORM_PARAMS.TIMER]?.[FORM_PARAMS.TIMER_GRACE_UNIT] || 's';
    const timer = !!formValue[FORM_PARAMS.TIMER_ENABLED]
      ? {
          [FORM_PARAMS.TIMER_TIMEOUT]:
            formValue[FORM_PARAMS.TIMER]?.[FORM_PARAMS.TIMER_TIMEOUT] * (timeoutUnit === 'h' ? 3600 : timeoutUnit === 'm' ? 60 : 1),
          [FORM_PARAMS.TIMER_GRACE]:
            formValue[FORM_PARAMS.TIMER]?.[FORM_PARAMS.TIMER_GRACE] * (graceUnit === 'h' ? 3600 : graceUnit === 'm' ? 60 : 1),
        }
      : null;
    const rules = !!formValue[FORM_PARAMS.RULES]?.length
      ? formValue[FORM_PARAMS.RULES].map((rule: any) => ({
          ...rule,
          formula: Array.from(rule.formula)[0] !== '=' ? `=${rule.formula}` : rule.formula,
        }))
      : [];
    payload = { [FORM_PARAMS.TIMER]: timer || undefined, [FORM_PARAMS.RULES]: rules };
    return payload;
  }

  get mqttRulesFormArray() {
    return this.form?.form?.get(FORM_PARAMS.RULES) as FormArray;
  }

  addRules() {
    this.mqttRulesFormArray?.push(
      this.fb.group({
        [FORM_PARAMS.MQTT_RULE_TYPE]: ['excel'],
        [FORM_PARAMS.MQTT_RULE_FORMULA]: ['', validatorTrimRequired],
      }),
    );
  }

  removeRules() {
    this.mqttSelectedRules?.forEach((rule) => {
      this.mqttRulesFormArray?.removeAt(rule);
    });
  }

  removeRule(index: number) {
    this.mqttRulesFormArray?.removeAt(index);
  }

  goToDocumentation() {
    const customDomain: any = localStorage.getItem('domain');
    window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
  }

  // ----------------------------------- Helper ----------------------------------------------
  private setTypeObj(dataType: string, connectionType: string, protocolType?: string, analyzerType?: string) {
    this.typeObj[FORM_PARAMS.DATA_TYPE] = dataType;
    this.typeObj[CONSTANTS.CONN_TYPE] = connectionType;
    this.typeObj[CONSTANTS.PROTOCOL_TYPE] = protocolType;
    this.typeObj[FORM_PARAMS.ANALYZER_TYPE] = analyzerType;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form?.form?.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  openOinkcodeRequiredConfirmation() {
    this.confirm({
      action: 'Oinkcode Required',
      customContent: 'An oinkcode is required to create rule based analyzers.<br><br>Do you want to set up an oinkcode now?',
      acceptLabel: 'Set Up Oinkcode',
      next: () => {
        this.changeRoute(
          `${MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(
            ORGANIZATION_ID_PARAM_ROUTE,
            this.breadcrumbConfig?.organizationId?.toString(),
          ).replace(PROJECT_ID_PARAM_ROUTE, this.breadcrumbConfig?.projectId?.toString())}/${PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE}/${
            PROJECT_SETTINGS_CONSTANTS.SNORT_SETTINGS.ROUTE
          }`,
        );
      },
      rejectCallback: () => {
        this.dialogRef.close(false);
      },
    });
  }

  setMQTTRulesServerError(error: any) {
    if (!!error?.error?.errors) {
      const errors = error.error.errors;
      Object.keys(errors?.[FORM_PARAMS.RULES] || {}).forEach((key) => {
        const value = errors[FORM_PARAMS.RULES][key]?.[FORM_PARAMS.MQTT_RULE_FORMULA];
        if (!!value) {
          this.mqttRulesFormArray?.get([key, FORM_PARAMS.MQTT_RULE_FORMULA])?.markAsDirty();
          this.mqttRulesFormArray?.get([key, FORM_PARAMS.MQTT_RULE_FORMULA])?.setErrors({
            incorrect: true,
            message: Array.isArray(value) ? value.join('<br/>') : value,
          });
          this.mqttRulesFormArray?.updateValueAndValidity();
        }
        this.mqttRulesFormArray?.updateValueAndValidity();
      });
    }
  }
}
