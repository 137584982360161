<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!license) {
    <app-license-details [license]="license" (uploadLicenseEvent)="openLicenseForm()" (deleteLicenseEvent)="openDeleteConfirmation(license)" />
  } @else {
    <div class="flex align-items-center justify-content-center" style="height: 75vh">
      <div class="relative w-full" style="max-width: 50rem">
        <microsec-card [isBordered]="true">
          <div class="px-2 py-4 flex flex-column align-items-center justify-content-center gap-2">
            <label class="font-bold">Start by uploading a license for MicroIDS</label>
            <label class="text-grey text-sm"
              >Please upload a license or contact MicroSec Sales to obtain a license.
              <span class="link-blue" (click)="goToDocumentation()">Learn More</span>.</label
            >
            <p-button
              type="button"
              [rounded]="true"
              icon="fa fa-plus"
              severity="primary"
              styleClass="text-transform-none mt-1"
              label="Upload New License"
              (onClick)="openLicenseForm(true)">
            </p-button>
          </div>
          <div class="absolute bottom-0 right-0 flex align-items-center gap-1 p-1">
            <label class="text-grey text-sm">Machine ID: {{ machineId }}</label>
            <span class="icon-button text-sm" pTooltip="Copy" tooltipPosition="bottom" appendTo="body" (click)="util.copyToClipboard(machineId)">
              <i class="fas fa-copy"></i>
            </span>
          </div>
        </microsec-card>
      </div>
    </div>
  }
</microsec-loading-overlay>

<!-- License Uploaded Dialog -->
<p-dialog
  styleClass="common-dialog"
  [(visible)]="uploadedDialogVisible"
  [modal]="true"
  appendTo="body"
  [closable]="false"
  [style]="{ width: '800px' }"
  [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">License Uploaded</span>
  </ng-template>
  <div class="form">
    <div class="py-2 px-3">
      <div class="mb-5">License has been uploaded successfully.</div>
      <div class="flex align-items-center justify-content-end gap-2">
        <p-button type="button" label="View License" severity="secondary" (onClick)="onViewLicense()"> </p-button>
        <p-button type="button" label="Continue Setup" severity="primary" (onClick)="goToSetup()"> </p-button>
      </div>
    </div>
  </div>
</p-dialog>
