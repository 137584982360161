@if (!!analyzer) {
  <microsec-card [isNestedCard]="true">
    <div class="grid align-items-center">
      <label class="col-12 font-bold"> Analyzer Progress Timeline </label>
      <div class="col-12">
        <microsec-custom-steps
          [steps]="items"
          [activeStep]="
            analyzer.mode === MODES.TRAINING && !analyzer.training_progress && !analyzer.packets_trained
              ? -1
              : analyzer.training_progress < 100
                ? 0
                : 1
          "
          [loading]="
            analyzer.training_progress < 100 ||
            (analyzer.training_progress >= 100 && (analyzer.packets_predicted < 1 || analyzer.packets_predicted < analyzer.prediction_threshold))
          "></microsec-custom-steps>
      </div>
      @if (
        (analyzer.mode === MODES.TRAINING && (!!analyzer.training_progress || !!analyzer.packets_trained)) ||
        analyzer.mode === MODES.PREDICTION ||
        analyzer.training_progress === 100
      ) {
        <div class="col-12">
          <div class="flex align-items-center gap-5 mx-auto" style="max-width: 32rem">
            <!--Training Queue -->
            @if (analyzer.mode === MODES.TRAINING && analyzer.training_progress < 99.9) {
              <span><i class="fas fa-cubes-stacked progress-icon"></i></span>
              <div class="grid">
                <label class="col-12 font-bold"> Queuing Training Packet (1/2) </label>
                <label class="col-12"> As part of the training preparation, packets are being gathered and transmitted to the ML analyzer. </label>
                <div class="col-12">
                  <div class="progress-detail">
                    Packet Queued:
                    <span> {{ analyzer.packets_trained || 0 }}/{{ analyzer.prediction_packet_accumulation_threshold || 0 }} </span>
                    | Progress:
                    <span>
                      {{ analyzer.training_progress ? (analyzer.training_progress | number: '1.0-2') + '%' : '0%' }}
                    </span>
                  </div>
                </div>
              </div>
            }

            <!--Training Begin -->
            @if (analyzer.mode === MODES.TRAINING && analyzer.training_progress >= 99.9 && analyzer.training_progress < 100) {
              <span><i class="fas fa-chart-bar progress-icon"></i></span>
              <div class="grid">
                <label class="col-12 font-bold"> Training ML Model (2/2) </label>
                <label class="col-12">
                  The ML model is currently being trained based on queued packets from ID
                  {{ this.analyzer.connection_ids?.[0] }}: {{ this.connectionName }}.
                </label>
                <div class="col-12">
                  <div class="progress-detail">Training in Progress</div>
                </div>
              </div>
            }

            <!--Predicition -->
            @if (analyzer.mode === MODES.PREDICTION || analyzer.training_progress >= 100) {
              <span><i class="fas fa-infinity progress-icon"></i></span>
              <div class="grid">
                <label class="col-12 font-bold"> Packet Queuing and Prediction </label>
                <label class="col-12">
                  ML Model is now in prediction mode. Streaming packets will be queued and analyzed in real-time for threat detection.
                </label>
                <div class="col-12">
                  <div class="progress-detail">
                    Packet Queued: <span>{{ analyzer.packets_predicted || 0 }}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </microsec-card>
}
