<microsec-loading-overlay [isLoading]="!!isLoading">
  <div>
    @switch (step) {
      @case ('form') {
        @if (!!form) {
          <form class="no-wrapper" [formGroup]="form" (ngSubmit)="onSubmit()">
            <p-tabView styleClass="microsec-dashboard-tab active-scan-tab">
              @if (scanType === SCAN_TYPES.IDS) {
                <p-tabPanel header="Target">
                  <div class="scroll-panel">
                    <div class="my-2 mx-6 flex flex-column gap-4">
                      <div class="flex flex-column gap-3">
                        <label class="font-bold">Select an Active Scan scope</label>
                        <div *ngFor="let s of scopeOptions" class="ml-3 flex align-items-baseline gap-3">
                          <p-radioButton [inputId]="s.value" name="scope" [value]="s.value" [formControlName]="IDS_FORM_PARAMS.SCOPE"></p-radioButton>
                          <div class="flex flex-column gap-1">
                            <label [for]="s.value">{{ s.label }}</label>
                            <label [for]="s.value" class="text-grey">{{ s.description }}</label>
                          </div>
                        </div>
                      </div>
                      @switch (form.get(IDS_FORM_PARAMS.SCOPE)?.value) {
                        @case ('network') {
                          <div class="flex flex-column gap-3">
                            <label class="font-bold">Select Network</label>
                            <div class="ml-3">
                              <div class="flex flex-column">
                                <p-dropdown
                                  styleClass="w-20rem"
                                  [options]="idsConnectionOptions"
                                  [formControlName]="IDS_FORM_PARAMS.CONNECTION_ID"
                                  placeholder="Unit"
                                  optionLabel="label"
                                  optionValue="value"
                                  placeholder="Select Network"
                                  appendTo="body"
                                  (onHide)="markFieldAsTouchedAndDirty(IDS_FORM_PARAMS.CONNECTION_ID)"
                                  [ngClass]="{
                                    'ng-invalid ng-dirty':
                                      !!form.get(IDS_FORM_PARAMS.CONNECTION_ID)?.touched &&
                                      !!form.get(IDS_FORM_PARAMS.CONNECTION_ID)?.dirty &&
                                      !!form.get(IDS_FORM_PARAMS.CONNECTION_ID)?.errors
                                  }">
                                </p-dropdown>
                                <label
                                  class="text-error"
                                  *ngIf="
                                    !!form.get(IDS_FORM_PARAMS.CONNECTION_ID)?.touched &&
                                    !!form.get(IDS_FORM_PARAMS.CONNECTION_ID)?.dirty &&
                                    !!form.get(IDS_FORM_PARAMS.CONNECTION_ID)?.errors
                                  ">
                                  Network is required
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                        @case ('devices') {
                          <div class="flex flex-column gap-3">
                            <label class="font-bold">Select Devices</label>
                            <div class="ml-3">
                              <microsec-card [isTableCard]="true">
                                <microsec-common-table
                                  [cols]="deviceCols"
                                  [values]="idsDevices"
                                  selectionMode="multiple"
                                  [(selection)]="selectedIdsDevices"
                                  [loading]="isLoading"
                                  [cellTemplate]="cellTemplateDevice"
                                  [dataKey]="'id'"
                                  itemName="Device">
                                </microsec-common-table>
                                <ng-template let-col="col" let-rowData="rowData" #cellTemplateDevice>
                                  <ng-container [ngSwitch]="col.field">
                                    <!-- Connection -->
                                    <ng-container *ngSwitchCase="'connection'">
                                      <p-dropdown
                                        appendTo="body"
                                        [options]="rowData['connectionOptions']"
                                        optionLabel="label"
                                        optionValue="value"
                                        [(ngModel)]="rowData.defaultConnectionId"
                                        [ngModelOptions]="{ standalone: true }"
                                        placeholder="Select Connection"
                                        (onChange)="onDeviceConnectionChange(rowData)">
                                      </p-dropdown>
                                    </ng-container>
                                    <!-- Tags -->
                                    <ng-container *ngSwitchCase="'tags'">
                                      <div
                                        class="flex align-items-center w-max"
                                        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
                                        tooltipPosition="top"
                                        appendTo="body">
                                        <ng-container
                                          *ngFor="let tag of rowData[col.field] || []; let i = index; let firstTag = first; let lastTag = last">
                                          <div
                                            *ngIf="!!firstTag"
                                            class="custom-chip"
                                            [style]="{ background: util.hexToRgba(tag.color), color: tag.color }">
                                            {{ tag.label }}
                                          </div>
                                          <div *ngIf="!firstTag && !!lastTag" class="ml-1">+{{ i }}</div>
                                        </ng-container>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                        {{ rowData[col.field] || '-' }}
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </ng-template>
                              </microsec-card>
                            </div>
                          </div>
                        }
                        @case ('ipRange') {
                          <div class="flex flex-column gap-3">
                            <label class="font-bold">Select Devices</label>
                            <div class="ml-3 flex align-items-baseline gap-2">
                              <div class="flex flex-column">
                                <input
                                  type="text"
                                  pInputText
                                  placeholder="IP Address"
                                  class="w-20rem"
                                  [formControlName]="IDS_FORM_PARAMS.IP_ADDR"
                                  [ngClass]="{
                                    'ng-invalid ng-dirty':
                                      !!form.get(IDS_FORM_PARAMS.IP_ADDR)?.touched &&
                                      !!form.get(IDS_FORM_PARAMS.IP_ADDR)?.dirty &&
                                      !!form.get(IDS_FORM_PARAMS.IP_ADDR)?.errors
                                  }" />
                                <label
                                  class="text-error"
                                  *ngIf="
                                    !!form.get(IDS_FORM_PARAMS.IP_ADDR)?.touched &&
                                    !!form.get(IDS_FORM_PARAMS.IP_ADDR)?.dirty &&
                                    !!form.get(IDS_FORM_PARAMS.IP_ADDR)?.errors
                                  ">
                                  IP Address is not valid
                                </label>
                              </div>
                              <p-dropdown
                                appendTo="body"
                                [options]="netmaskOptions"
                                optionLabel="label"
                                optionValue="value"
                                [formControlName]="IDS_FORM_PARAMS.NETMASK"
                                placeholder="CIDR">
                              </p-dropdown>
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </div>
                </p-tabPanel>
              }
              <p-tabPanel header="Scan">
                <div class="scroll-panel">
                  <div class="my-2 mx-6 flex flex-column gap-5">
                    <label>
                      Active Scan allows you to adjust the intensity of the scan based on completeness and intrusiveness. Select the scan type based
                      on your specific needs.
                    </label>
                    <div class="relative h-3rem">
                      <span class="absolute" style="left: 1.5rem; width: calc(100% - 3.5rem)">
                        <p-slider
                          [(ngModel)]="selectedAggressionLevel"
                          [ngModelOptions]="{ standalone: true }"
                          [step]="1"
                          [min]="1"
                          [max]="3"
                          styleClass="aggresion-level-slider"></p-slider>
                      </span>
                      @if (selectedAggressionLevel !== 1) {
                        <label class="absolute cursor-pointer" style="top: -0.4rem; left: 1.25rem" (click)="selectedAggressionLevel = 1"> • </label>
                      }
                      @if (selectedAggressionLevel !== 2) {
                        <label class="absolute cursor-pointer" style="top: -0.4rem; left: calc(50% - 0.4rem)" (click)="selectedAggressionLevel = 2">
                          •
                        </label>
                      }
                      @if (selectedAggressionLevel !== 3) {
                        <label class="absolute cursor-pointer" style="top: -0.4rem; right: 2.25rem" (click)="selectedAggressionLevel = 3"> • </label>
                      }
                      <label
                        class="absolute cursor-pointer text-center"
                        style="top: 1rem; left: 0"
                        [ngClass]="{ 'text-highlight font-bold': selectedAggressionLevel === 1 }"
                        (click)="selectedAggressionLevel = 1">
                        Safe<br />(Default)
                      </label>
                      <label
                        class="absolute cursor-pointer text-center"
                        [style]="{ top: '1rem' }"
                        [ngStyle]="{ left: scanType === SCAN_TYPES.EDGE ? 'calc(50% - 3.5rem)' : 'calc(50% - 2.25rem)' }"
                        [ngClass]="{ 'text-highlight font-bold': selectedAggressionLevel === 2 }"
                        (click)="selectedAggressionLevel = 2">
                        Advanced
                        @if (scanType === SCAN_TYPES.EDGE) {
                          <span [ngClass]="{ 'text-highlight': selectedAggressionLevel === 2 }"><br />(Coming Soon)</span>
                        }
                      </label>
                      <label
                        class="absolute cursor-pointer"
                        style="top: 1rem; right: 0"
                        [ngClass]="{ 'text-highlight font-bold': selectedAggressionLevel === 3, 'text-center': scanType === SCAN_TYPES.EDGE }"
                        (click)="selectedAggressionLevel = 3">
                        Intensive
                        @if (scanType === SCAN_TYPES.EDGE) {
                          <span [ngClass]="{ 'text-highlight': selectedAggressionLevel === 3 }"><br />(Coming Soon)</span>
                        }
                      </label>
                    </div>
                    <microsec-card styleClass="aggresion-level-card">
                      @switch (selectedAggressionLevel) {
                        @case (1) {
                          <div class="p-2 flex flex-column gap-3">
                            <label class="font-bold">Safe Scan</label>
                            <label class="text-grey">The default and recommended setting for Active Scan.</label>
                            <div>
                              <label class="text-grey">Safe Scan attempts to provide insights on the following information:</label>
                              <ul class="my-0">
                                <li class="text-grey">IP Address</li>
                                <li class="text-grey">MAC Address</li>
                                <li class="text-grey">Hostname</li>
                                <li class="text-grey">Open Ports</li>
                                <li class="text-grey">Manufacturer</li>
                                <li class="text-grey">Device Type</li>
                                <li class="text-grey">Operating System</li>
                              </ul>
                            </div>
                            <label class="text-grey"
                              >Choose this setting for a quick and secure scan that won't disrupt services or consume excessive resources.</label
                            >
                          </div>
                        }
                        @case (2) {
                          <div class="p-2 flex flex-column gap-3">
                            <label class="font-bold">Advanced Scan</label>
                            <div>
                              <label class="text-grey"
                                >Advanced Scan attempts to provide insights on the following information in addition to Safe Scan:</label
                              >
                              <ul class="my-0">
                                <li class="text-grey">Model</li>
                                <li class="text-grey">Firmware Version</li>
                                <li class="text-grey">Serial Number</li>
                                <li class="text-grey">Vulnerabilities Configuration</li>
                              </ul>
                            </div>
                            <label class="text-grey">
                              Choose this setting if you want a more detailed scan, understanding that it may have a slightly higher impact on the
                              target system.
                            </label>
                          </div>
                        }
                        @case (3) {
                          <div class="p-2 flex flex-column gap-3">
                            <label class="font-bold">Intensive Scan</label>
                            <div>
                              <label class="text-grey"
                                >Intensive Scan attempts to provide insights on the following information in addition to Advanced Scan:</label
                              >
                              <ul class="my-0">
                                <li class="text-grey">Default Password Vulnerabilities</li>
                                <li class="text-grey">Weak Password Vulnerabilities</li>
                              </ul>
                            </div>
                            <label class="text-grey">
                              Choose this setting when a deep, comprehensive scan is crucial. This level can be more intrusive and resource-intensive.
                            </label>
                          </div>
                        }
                        @default {
                          <div></div>
                        }
                      }
                    </microsec-card>
                  </div>
                </div>
              </p-tabPanel>
              @if (scanType === SCAN_TYPES.IDS) {
                <p-tabPanel header="Services">
                  <div class="scroll-panel">
                    <div class="mx-6 flex">
                      <label class="col-3 font-bold">Services to Scan</label>
                      <div class="col-6 grid">
                        @for (s of services; track $index) {
                          <div class="col-6">
                            <p-checkbox
                              [label]="s.label"
                              [name]="IDS_FORM_PARAMS.SERVICES_TO_SCAN"
                              [value]="s.value"
                              [inputId]="s.value"
                              [formControl]="$any(form.controls[IDS_FORM_PARAMS.SERVICES_TO_SCAN])"></p-checkbox>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </p-tabPanel>
              }
            </p-tabView>
            <div class="mb-3 mx-5">
              <div class="action-buttons flex flex-row align-items-center justify-content-end gap-2">
                <p-button severity="secondary" type="button" (onClick)="onCancel()" label="Cancel"> </p-button>
                <p-button type="button" icon="fa fa-angle-left" label="Back" severity="secondary" (onClick)="step = 'confirmation'"></p-button>
                <p-button type="submit" label="Start Scan" severity="primary" [disabled]="!isValid"></p-button>
              </div>
            </div>
          </form>
        }
      }
      @default {
        <app-active-scan-confirmation
          (cancelEvent)="dialogRef.close(false)"
          (confirmEvent)="onConfirm()"
          [(scanType)]="scanType"
          [edgeDevices]="edgeDevices"
          [(selectedEdgeDevices)]="selectedEdgeDevices" />
      }
    }
  </div>
</microsec-loading-overlay>
