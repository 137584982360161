/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, Observable, scheduled } from 'rxjs';

import { IFirmwareService } from './IFirmwareService';
import firmwares from './mock-data/firmwares.json';
import supportedDevices from './mock-data/supported-devices.json';

@Injectable({
  providedIn: 'root',
})
export class MockFirmwareService implements IFirmwareService {
  getFirmwares(project_id?: any, device_id?: any) {
    return scheduled([firmwares], asyncScheduler);
  }

  getFirmware(id: any) {
    return scheduled([{}], asyncScheduler);
  }

  getDeviceFirmwareStatus(device_id: any) {
    return scheduled([{}], asyncScheduler);
  }

  uploadFirmware(payload: any) {
    return scheduled([{}], asyncScheduler);
  }

  updateFirmware(id: any, payload: any) {
    return scheduled([{}], asyncScheduler);
  }

  deleteFirmware(id: any, organizationId: any) {
    return scheduled([{}], asyncScheduler);
  }

  downloadFirmware(id: any) {
    return scheduled([null], asyncScheduler);
  }

  pushHikVisionCCTVDevice(id: any, payload: any) {
    return scheduled([{}], asyncScheduler);
  }

  getSupportedDevices(): Observable<any> {
    return scheduled([supportedDevices], asyncScheduler);
  }
}
