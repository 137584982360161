export const ASSESSMENT_STATUS = {
  COMPLETED: 'completed',
  ACTIVE: 'active',
  OPEN: 'open',
};

export const ASSESSMENT_REPORT_STATUS = {
  COMPLETED: 'completed',
  REPORT_CALCULATING: 'report_calculating',
  OPEN: 'open',
};

export const FOUNDATIONAL_REQUIREMENTS = {
  IAC: 'IAC',
  UC: 'UC',
  SI: 'SI',
  DC: 'DC',
  RDF: 'RDF',
  TRE: 'TRE',
  RA: 'RA',
};

export const FOUNDATIONAL_REQUIREMENT_LABELS = {
  IAC: 'Identification and Authentication Control',
  UC: 'Use Control',
  SI: 'System Integrity',
  DC: 'Data Confidentiality',
  RDF: 'Restricted Data Flow',
  TRE: 'Timely Response to Events',
  RA: 'Resource Availability',
};

export const FOUNDATIONAL_REQUIREMENT_OPTIONS = [
  { label: FOUNDATIONAL_REQUIREMENT_LABELS.IAC, value: FOUNDATIONAL_REQUIREMENTS.IAC },
  { label: FOUNDATIONAL_REQUIREMENT_LABELS.UC, value: FOUNDATIONAL_REQUIREMENTS.UC },
  { label: FOUNDATIONAL_REQUIREMENT_LABELS.SI, value: FOUNDATIONAL_REQUIREMENTS.SI },
  { label: FOUNDATIONAL_REQUIREMENT_LABELS.DC, value: FOUNDATIONAL_REQUIREMENTS.DC },
  { label: FOUNDATIONAL_REQUIREMENT_LABELS.RDF, value: FOUNDATIONAL_REQUIREMENTS.RDF },
  { label: FOUNDATIONAL_REQUIREMENT_LABELS.TRE, value: FOUNDATIONAL_REQUIREMENTS.TRE },
  { label: FOUNDATIONAL_REQUIREMENT_LABELS.RA, value: FOUNDATIONAL_REQUIREMENTS.RA },
];

export const COMPLIANCE_STANDARD_IEC_62443_NAME = 'IEC 62443 3-3';

export const COMPLIANCE_STANDARD_IEC_62443_KEY = 'iec62443_3-3';

export const COMPLIANCE_STANDARDS = {
  IEC_62443: 'iec_62443',
};

export const COMPLIANCE_STANDARD_OPTIONS = [{ label: 'IEC 62443', value: 'iec_62443' }];

export const ASSESSMENT_ANSWERS = {
  TRUE: true,
  FALSE: false,
  NOT_APPLICABLE: 'not_applicable',
};

export const ASSESSMENT_RESULTS = {
  COMPLIANT: 'compliant',
  PARTIALLY_COMPLIANT: 'partially compliant',
  NOT_COMPLIANT: 'not compliant',
  UNANSWERED: 'unanswered',
  NOT_APPLICABLE: 'not_applicable',
};

export const ASSESSMENT_RESULT_LABELS = {
  [ASSESSMENT_RESULTS.COMPLIANT]: 'Compliant',
  [ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT]: 'Partially Compliant',
  [ASSESSMENT_RESULTS.NOT_COMPLIANT]: 'Not Compliant',
  [ASSESSMENT_RESULTS.UNANSWERED]: 'Unanswered',
  [ASSESSMENT_RESULTS.NOT_APPLICABLE]: 'Not Applicable',
};

export const ASSESSMENT_RESULT_COLORS = {
  [ASSESSMENT_RESULTS.COMPLIANT]: '#00ad74',
  [ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT]: '#ff9534',
  [ASSESSMENT_RESULTS.NOT_COMPLIANT]: '#fb4d58',
  [ASSESSMENT_RESULTS.UNANSWERED]: '#444444',
  [ASSESSMENT_RESULTS.NOT_APPLICABLE]: '#333333',
};

export const DEFAULT_SECURITY_LEVELS = [null, null, null, null, null, null, null];

export const SECURITY_LEVEL_OPTIONS = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
];

export const MAX_ATTACHMENT_FILE_SIZE = 1024 * 1024;

export const ATTACHMENT_FILE_ICONS: { [key: string]: string } = {
  ['.jpg']: 'fas fa-file-image',
  ['.jpeg']: 'fas fa-file-image',
  ['.png']: 'fas fa-file-image',
  ['.doc']: 'fas fa-file-word',
  ['.docs']: 'fas fa-file-word',
  ['.xls']: 'fas fa-file-excel',
  ['.xlsx']: 'fas fa-file-excel',
  ['.csv']: 'fas fa-file-csv',
  ['.pdf']: 'fas fa-file-pdf',
  ['.txt']: 'fas fa-file-lines',
};

export const ATTACHMENT_ALLOWED_EXTENSIONS = ['.jpg', '.jpeg', '.pcap', '.png', '.doc', '.xlsx', '.csv', '.pdf', '.txt'];

export const ATTACHMENT_TYPE_FILES = Object.keys(ATTACHMENT_FILE_ICONS);
