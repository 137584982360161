import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { EDITOR_CREATION_TYPES, EDITOR_CREATION_TYPE_VALUES } from '@ids-constants';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';

@Component({
  selector: 'app-nme-creation-form',
  templateUrl: './nme-creation-form.component.html',
  styleUrls: ['./nme-creation-form.component.scss'],
})
export class NmeCreationFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  ngAfterViewInit() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: 'option',
        hasNoLabel: true,
        field: 'radio',
        radioType: 'block',
        options: this.util.cloneObjectArray(EDITOR_CREATION_TYPES),
        defaultValue: EDITOR_CREATION_TYPE_VALUES.SCRATCH,
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Submit
   * @param closeDialog
   */
  submit(closeDialog: (result: any) => void) {
    this.form.isLoading = false;
    closeDialog(this.form?.getRawValue()?.option);
  }
}
