import { ILayoutItem } from '@microsec/interfaces';
import { ConfigurationComponent } from './configuration/configuration.component';
import { VulnerabilityComponent } from './vulnerability/vulnerability.component';
import { MalwareComponent } from './malware/malware.component';
import { AnomalyComponent } from './anomaly/anomaly.component';
import { NonComplianceComponent } from './non-compliance/non-compliance.component';

export const THREATS_CONSTANTS = {
  ANOMALY: {
    ROUTE: 'anomaly',
    LABEL: 'Anomaly',
  },
  MALWARE: {
    ROUTE: 'malware',
    LABEL: 'Malware',
  },
  VULNERABILITY: {
    ROUTE: 'vulnerability',
    LABEL: 'Vulnerability',
  },
  NON_COMPLIANCE: {
    ROUTE: 'non-compliance',
    LABEL: 'Non-Compliance',
  },
  CONFIGURATION: {
    ROUTE: 'configuration',
    LABEL: 'Configuration',
  },
};

export const THREATS_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: THREATS_CONSTANTS.ANOMALY.ROUTE,
  },
  {
    route: THREATS_CONSTANTS.ANOMALY.ROUTE,
    label: THREATS_CONSTANTS.ANOMALY.LABEL,
    component: AnomalyComponent,
  },
  {
    route: THREATS_CONSTANTS.MALWARE.ROUTE,
    label: THREATS_CONSTANTS.MALWARE.LABEL,
    component: MalwareComponent,
  },
  {
    route: THREATS_CONSTANTS.VULNERABILITY.ROUTE,
    label: THREATS_CONSTANTS.VULNERABILITY.LABEL,
    component: VulnerabilityComponent,
  },
  {
    route: THREATS_CONSTANTS.NON_COMPLIANCE.ROUTE,
    label: THREATS_CONSTANTS.NON_COMPLIANCE.LABEL,
    component: NonComplianceComponent,
  },
  {
    route: THREATS_CONSTANTS.CONFIGURATION.ROUTE,
    label: THREATS_CONSTANTS.CONFIGURATION.LABEL,
    component: ConfigurationComponent,
  },
];
