import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { TreeSelect } from 'primeng/treeselect';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseComponent } from '@ids-components';
import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import {
  ATTACK_OPTIONS,
  CONNECTION_TYPES,
  DETECTED_DEVICE_FILTER_CONFIGURATION,
  FL_AGGREGATION_METHOD_OPTIONS,
  FL_MODEL_TYPES,
  FL_MODEL_TYPE_OPTIONS,
  PROTOCOL_OPTIONS,
} from '@ids-constants';
import { CommonToolbarConfiguration, FormItem } from '@microsec/models';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { AnomalyAnalyzerService, ConnectionService } from '@ids-services';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  NAME: 'name',
  DESCRIPTION: 'description',
  CONNECTION_IDS: 'connection_ids',
  PROTOCOLS: 'protocols',
  PROTOCOL: 'protocol',
  ATTACKS: 'attacks',
  ATTACK_TYPE: 'attack_type',
  MODEL_TYPE: 'model_type',
  AGGREGATION_METHODS: 'aggregation_methods',
  ROUNDS_TOTAL: 'rounds_total',
  MODEL_HYPERPARAMS: 'model_hyperparams',
  ANALYZER_TYPE: 'analyzer_type',
  ENABLED: 'enabled',
  DATA_TYPE: 'data_type',
  DEVICE_IDS: 'device_ids',
  DEVICE_COUNT: 'device_count',
};

@Component({
  selector: 'app-fl-analyzer-form',
  templateUrl: './fl-analyzer-form.component.html',
  styleUrls: ['./fl-analyzer-form.component.scss'],
})
export class FlAnalyzerFormComponent extends BaseComponent implements AfterViewInit {
  analyzer: any = null;

  steps: MenuItem[] = [];

  activeStep = 0;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('stepsTemplate') stepsTemplate!: TemplateRef<any>;

  @ViewChild('protocolsTreeSelect') protocolsTreeSelect!: TreeSelect;

  @ViewChild('protocolsField') protocolsField!: TemplateRef<any>;

  @ViewChild('devicesField') devicesField!: TemplateRef<any>;

  @ViewChild('attacksField') attacksField!: TemplateRef<any>;

  protocols: any = null;

  protocolOptions: any = null;

  connections: any[] = [];

  connectionOptions: any[] = [];

  selectedDeviceIds: any[] = [];

  FORM_PARAMS = FORM_PARAMS;

  CREATE_BUTTON_LABEL = CREATE_LABEL;

  SAVE_CHANGES_BUTTON_LABEL = SAVE_CHANGES_LABEL;

  FL_MODEL_TYPE_OPTIONS = FL_MODEL_TYPE_OPTIONS;

  FL_AGGREGATION_METHOD_OPTIONS = FL_AGGREGATION_METHOD_OPTIONS;

  deviceFieldConfiguration: CommonToolbarConfiguration | null = null;

  deviceFieldCols: any[] = [
    { field: 'label', header: 'Name', width: 20 },
    { field: 'src_mac_addr', header: 'MAC Address', width: 15 },
    { field: 'src_ip_addr', header: 'IP Addresses', width: 15 },
  ];

  deviceFieldFilters: {
    [key: string]: any;
  } = { connections: [] };

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private connectionSrv: ConnectionService,
    public dialogConfig: DynamicDialogConfig,
    private fb: FormBuilder,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.analyzer = this.dialogConfig?.data?.analyzer;
    this.connections = ((this.dialogConfig?.data?.connections as any[]) || []).filter((con) => con?.interface?.type === CONNECTION_TYPES.MQTT_AGENT);
    this.connectionOptions = this.connections.map((con) => ({
      label: con.name || con.id,
      value: con.id,
    }));
    this.deviceFieldConfiguration = this.deviceFilterConfiguration;
    this.selectedDeviceIds = this.analyzer?.[FORM_PARAMS.DEVICE_IDS] || [];
    this.steps = [
      {
        label: 'Set Up<br/>Configuration',
      },
      {
        label: 'Select<br/>Devices',
      },
      {
        label: 'Set Up<br/>FL Parameters',
      },
    ];
    this.getProtocols();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        hasNoLabel: true,
        field: 'custom',
        customField: this.stepsTemplate,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: true,
        fieldInfo: 'Name of the analyzer',
        defaultValue: this.analyzer?.[FORM_PARAMS.NAME] || '',
        maxLength: 50,
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the analyzer',
        defaultValue: this.analyzer?.[FORM_PARAMS.DESCRIPTION] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONNECTION_IDS,
        label: 'Connection(s)',
        required: true,
        field: 'multiselect',
        options: this.connectionOptions || [],
        placeholder: 'Select connection(s)',
        fieldInfo: `Analyzer's connection ID`,
        defaultValue: this.analyzer?.[FORM_PARAMS.CONNECTION_IDS] || [],
        disabled: !!this.analyzer,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROTOCOLS,
        label: 'Protocol',
        required: true,
        field: 'custom',
        customField: this.protocolsField,
        fieldInfo: 'Protocol of the analyzer',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        defaultValue: this.analyzer ? this.analyzer[FORM_PARAMS.ENABLED] : true,
        fieldInfo: 'Enable analyzer',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DEVICE_IDS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.devicesField,
        defaultValue: this.analyzer?.[FORM_PARAMS.DEVICE_IDS] || '',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ATTACKS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.attacksField,
        defaultValue: [],
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.form?.setControl(FORM_PARAMS.ATTACKS, this.fb.array([]));
      ATTACK_OPTIONS.forEach((attack) => {
        const analyzerAttack = ((this.analyzer?.[FORM_PARAMS.PROTOCOLS]?.[0]?.attacks as any[]) || []).find((a) => a.attack_type === attack.value);
        this.attacksFormArray?.push(
          this.fb.group({
            [FORM_PARAMS.ATTACK_TYPE]: [
              {
                value: attack.value,
                disabled: attack.value !== 'tampering',
              },
            ],
            [FORM_PARAMS.ENABLED]: [
              {
                value: attack.value === 'tampering' ? (!this.analyzer ? true : !!analyzerAttack?.[FORM_PARAMS.ENABLED]) : false,
                disabled: attack.value !== 'tampering',
              },
            ],
            [FORM_PARAMS.MODEL_TYPE]: [
              {
                value: !!analyzerAttack ? analyzerAttack[FORM_PARAMS.MODEL_TYPE] : FL_MODEL_TYPE_OPTIONS[0].value,
                disabled: attack.value !== 'tampering',
              },
              Validators.required,
            ],
            [FORM_PARAMS.AGGREGATION_METHODS]: [
              {
                value: !!analyzerAttack ? analyzerAttack[FORM_PARAMS.AGGREGATION_METHODS] : FL_AGGREGATION_METHOD_OPTIONS[0].value,
                disabled: attack.value !== 'tampering',
              },
              Validators.required,
            ],
            [FORM_PARAMS.ROUNDS_TOTAL]: [
              {
                value: !!analyzerAttack ? analyzerAttack[FORM_PARAMS.ROUNDS_TOTAL] : 10,
                disabled: attack.value !== 'tampering',
              },
              Validators.compose([Validators.required, Validators.min(1)]),
            ],
          }),
        );
      });
      this.form?.setChangeEvent(FORM_PARAMS.CONNECTION_IDS, (value: any[]) => {
        this.deviceFieldFilters['connections'] = value || [];
        this.selectedDeviceIds = [];
      });

      if (!!this.analyzer) {
        this.deviceFieldFilters['connections'] = this.analyzer[FORM_PARAMS.CONNECTION_IDS] || [];

        const nodes = this.util.flattenObjectArray(this.protocolOptions || [], 'children');
        const protocols = ((this.analyzer[FORM_PARAMS.PROTOCOLS] as any[]) || []).map((p) => p.protocol || p.key);
        const value = nodes.filter((node) => protocols.includes(node.key))?.[0] || null;
        this.form?.setControlValue(FORM_PARAMS.PROTOCOLS, value);
      }
    });
  }

  getProtocols() {
    this.form.isLoading = true;
    this.connectionSrv
      .getProtocols()
      .pipe(
        finalize(() => {
          this.initForm();
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.protocols = res || {};
          this.setProtocolOptions();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onSubmit(closeDialog: (analyzer: any) => void) {
    this.form.isLoading = true;
    const formValue = this.form.getRawValue();
    const payload = {
      ...formValue,
      [FORM_PARAMS.ORGANIZATION_ID]: this.breadcrumbConfig?.organizationId,
      [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
      [FORM_PARAMS.ANALYZER_TYPE]: 'fl',
      [FORM_PARAMS.DATA_TYPE]: 'packet',
      [FORM_PARAMS.PROTOCOLS]: [
        {
          [FORM_PARAMS.PROTOCOL]: formValue[FORM_PARAMS.PROTOCOLS].key,
          [FORM_PARAMS.ATTACKS]: ((formValue[FORM_PARAMS.ATTACKS] as any[]) || [])
            .filter((attack) => !!attack[FORM_PARAMS.ENABLED])
            .map((attack) => ({
              ...attack,
              [FORM_PARAMS.MODEL_HYPERPARAMS]:
                attack[FORM_PARAMS.MODEL_TYPE] === FL_MODEL_TYPES.GMM
                  ? {
                      class: 1,
                      subclass: 2,
                      sample_count: 30,
                    }
                  : {
                      C: 1,
                      kernel: 'rbf',
                      nu: 0.5,
                      cache_size: 200,
                      gamma: 0.001,
                      tol: 0.001,
                      coef0: 0,
                      degree: 3,
                      sample_count: 1000,
                    },
            })),
        },
      ],
      [FORM_PARAMS.DEVICE_IDS]: this.selectedDeviceIds || [],
      [FORM_PARAMS.DEVICE_COUNT]: this.selectedDeviceIds?.length || 0,
    };
    delete payload[FORM_PARAMS.ATTACKS];
    const request: Observable<any> = !!this.analyzer
      ? this.anomalyAnalyzerSrv.updateFlAnalyzer(this.analyzer.id, payload)
      : this.anomalyAnalyzerSrv.createFlAnalyzer(payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.showSuccessMessage(`Analyzer ${!!this.analyzer ? 'Updated' : 'Created'} Successfully`);
          closeDialog(rs);
        },
        error: (error) => {
          this.form.showServerErrorMessage(error);
          this.showErrorMessage(error);
        },
      });
  }

  setProtocolOptions(connectionProtocols?: string[]) {
    const analyzerProtocols = PROTOCOL_OPTIONS.map((options) => options.value);
    const hierarchyProtocols = this.mappedHierarchyProtocols(this.protocols, connectionProtocols || analyzerProtocols);
    const protocolOptions = this.mappedProtocolOptions(hierarchyProtocols);
    protocolOptions.unshift({ key: 'metrics', label: 'Metrics' });
    this.protocolOptions = protocolOptions;
  }

  mappedHierarchyProtocols(protocols: any, connectionProtocols: string[]) {
    const newProtocols = this.util.cloneDeepObject(protocols);
    Object.entries(newProtocols || {}).forEach(([key, value]: [string, any]) => {
      if (!connectionProtocols.includes(key)) {
        delete newProtocols[key];
      } else if (!!value.sub && !!Object.keys(value.sub).length) {
        newProtocols[key].sub = this.mappedHierarchyProtocols(value.sub, connectionProtocols);
      }
    });
    return Object.keys(newProtocols).length === 0 ? undefined : newProtocols;
  }

  mappedProtocolOptions(hierarchyProtocols: any): any[] {
    return Object.entries(hierarchyProtocols || {}).map(([key, value]: [string, any]) => ({
      key: key,
      label: value.name,
      expanded: true,
      selectable: false,
      children: !!value.sub && !!Object.keys(value.sub).length ? this.mappedProtocolOptions(value.sub) : undefined,
    }));
  }

  onActiveStepChange(isNext = true) {
    this.activeStep = this.activeStep + (isNext ? 1 : -1);
    // STEP 1 FL
    this.form.setControlVisibility(FORM_PARAMS.NAME, this.activeStep === 0);
    this.form.setControlVisibility(FORM_PARAMS.DESCRIPTION, this.activeStep === 0);
    this.form.setControlVisibility(FORM_PARAMS.CONNECTION_IDS, this.activeStep === 0);
    this.form.setControlVisibility(FORM_PARAMS.PROTOCOLS, this.activeStep === 0);
    this.form.setControlVisibility(FORM_PARAMS.ENABLED, this.activeStep === 0);
    // STEP 2 FL
    this.form.setControlVisibility(FORM_PARAMS.DEVICE_IDS, this.activeStep === 1);
    // STEP 3 FL
    this.form.setControlVisibility(FORM_PARAMS.ATTACKS, this.activeStep === 2);
  }

  get isCurrentStepValid() {
    switch (this.activeStep) {
      case 0: {
        return (
          !this.form?.getControl(FORM_PARAMS.NAME)?.invalid &&
          !this.form?.getControl(FORM_PARAMS.DESCRIPTION)?.invalid &&
          !this.form?.getControl(FORM_PARAMS.CONNECTION_IDS)?.invalid &&
          !this.form?.getControl(FORM_PARAMS.PROTOCOLS)?.invalid &&
          !this.form?.getControl(FORM_PARAMS.ENABLED)?.invalid
        );
      }
      case 1: {
        return !!this.selectedDeviceIds?.length;
      }
      default: {
        return false;
      }
    }
  }

  get attacksFormArray() {
    return this.form?.form?.get(FORM_PARAMS.ATTACKS) as FormArray;
  }

  get deviceFilterConfiguration() {
    const config = this.util.cloneDeepObject(DETECTED_DEVICE_FILTER_CONFIGURATION);
    delete config['filters']['2'];
    return config as CommonToolbarConfiguration;
  }
}
