<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="!analyzer && activeStep === 2 ? CREATE_BUTTON_LABEL : !!analyzer && activeStep === 2 ? SAVE_CHANGES_BUTTON_LABEL : ''"
  cancelButtonLabel=""
  (submitEvent)="onSubmit($event)"
  [shouldSubmitOnEnter]="false"
  [additionalConditions]="!!selectedDeviceIds && !!selectedDeviceIds.length"
  [leftActionArea]="leftActionArea">
</microsec-form-builder>

<ng-template #leftActionArea>
  <div class="flex gap-2" [ngClass]="{ 'mr-2': !!analyzer || activeStep >= 2 }">
    <p-button severity="secondary" type="button" (onClick)="fb.dialogRef.close(false)" label="Cancel"> </p-button>
    @if (activeStep > 0) {
      <p-button type="button" icon="fa fa-angle-left" label="Back" severity="secondary" (onClick)="onActiveStepChange(false)"></p-button>
    }
    @if (activeStep < 2) {
      <p-button
        type="button"
        icon="fa fa-angle-right"
        iconPos="right"
        label="Next"
        severity="primary"
        [disabled]="!isCurrentStepValid"
        (onClick)="onActiveStepChange()"></p-button>
    }
  </div>
</ng-template>

<ng-template #stepsTemplate>
  <div class="my-3 mx-8">
    <microsec-custom-steps [steps]="steps" [activeStep]="activeStep"></microsec-custom-steps>
  </div>
</ng-template>

<ng-template #protocolsField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div>
        <p-treeSelect
          #protocolsTreeSelect
          panelStyleClass="fl-treeselect"
          appendTo="body"
          [formControlName]="FORM_PARAMS.PROTOCOLS"
          [options]="protocolOptions"
          [metaKeySelection]="false"
          [propagateSelectionUp]="false"
          [propagateSelectionDown]="false"
          selectionMode="single"
          placeholder="Select a protocol">
          <ng-template pTemplate="header">
            <div class="p-treeselect-header">
              <div class="p-treeselect-filter-container">
                <input type="text" class="p-treeselect-filter p-inputtext" (input)="protocolsTreeSelect.onFilterInput($event)" />
                <span class="p-treeselect-filter-icon pi pi-search"></span>
              </div>
              <button class="p-treeselect-close p-link" (click)="protocolsTreeSelect.hide($event)">
                <span class="p-treeselect-filter-icon pi pi-times"></span>
              </button>
            </div>
          </ng-template>
        </p-treeSelect>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #devicesField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <label>Select devices to participate in federated learning.</label>
      <div class="mt-3">
        <app-shared-device-table
          [filterConfiguration]="deviceFieldConfiguration"
          [cols]="deviceFieldCols"
          [filters]="deviceFieldFilters"
          [(selectedDeviceIds)]="selectedDeviceIds" />
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #attacksField>
  @if (!!form && !!form.form && !!attacksFormArray && !!attacksFormArray.controls && !!attacksFormArray.controls.length) {
    <ng-container [formGroup]="form.form">
      <ng-container [formArrayName]="FORM_PARAMS.ATTACKS">
        <label>Select attack types to train federated learning model on.</label>
        <div class="mt-3">
          <p-accordion>
            @for (attackControl of attacksFormArray.controls; track i; let i = $index) {
              <ng-container [formGroupName]="i">
                <p-accordionTab>
                  <ng-template pTemplate="header">
                    <span class="flex align-items-center justify-content-between gap-2 w-full">
                      <label>{{ attacksFormArray.get([i, FORM_PARAMS.ATTACK_TYPE])?.value | constant: 'anomaly-analyzer-attack' }}</label>
                      <p-inputSwitch (click)="$event.stopPropagation()" [formControlName]="FORM_PARAMS.ENABLED"></p-inputSwitch>
                    </span>
                  </ng-template>
                  <ng-template pTemplate="content">
                    <div class="grid field">
                      <label class="col-4 text-data">Model Type <microsec-asterisk /></label>
                      <div class="col-8">
                        <p-dropdown
                          [options]="FL_MODEL_TYPE_OPTIONS"
                          [formControlName]="FORM_PARAMS.MODEL_TYPE"
                          placeholder="Select a model type"
                          optionLabel="label"
                          optionValue="value"
                          appendTo="body"></p-dropdown>
                      </div>
                      <label
                        class="col-8 col-offset-4 text-error"
                        *ngIf="
                          !!attacksFormArray.get([i, FORM_PARAMS.MODEL_TYPE])?.touched && !!attacksFormArray.get([i, FORM_PARAMS.MODEL_TYPE])?.errors
                        ">
                        Model type is required
                      </label>
                    </div>
                    <div class="grid field">
                      <label class="col-4 text-data">Aggregation Methods <microsec-asterisk /></label>
                      <div class="col-8">
                        <p-dropdown
                          [options]="FL_AGGREGATION_METHOD_OPTIONS"
                          [formControlName]="FORM_PARAMS.AGGREGATION_METHODS"
                          placeholder="Select an aggregation methods"
                          optionLabel="label"
                          optionValue="value"
                          appendTo="body"></p-dropdown>
                      </div>
                      <label
                        class="col-8 col-offset-4 text-error"
                        *ngIf="
                          !!attacksFormArray.get([i, FORM_PARAMS.AGGREGATION_METHODS])?.touched &&
                          !!attacksFormArray.get([i, FORM_PARAMS.AGGREGATION_METHODS])?.errors
                        ">
                        Aggregation methods type is required
                      </label>
                    </div>
                    <div class="grid field">
                      <label class="col-4 text-data">Number of Training Rounds <microsec-asterisk /></label>
                      <div class="col-8">
                        <p-inputNumber
                          mode="decimal"
                          [maxlength]="5"
                          [useGrouping]="false"
                          [formControlName]="FORM_PARAMS.ROUNDS_TOTAL"
                          [minFractionDigits]="0"
                          [maxFractionDigits]="0"
                          [inputStyleClass]="
                            !!attacksFormArray.get([i, FORM_PARAMS.ROUNDS_TOTAL])?.touched &&
                            !!attacksFormArray.get([i, FORM_PARAMS.ROUNDS_TOTAL])?.dirty &&
                            !!attacksFormArray.get([i, FORM_PARAMS.ROUNDS_TOTAL])?.errors
                              ? 'ng-invalid ng-dirty'
                              : ''
                          " />
                      </div>
                      <label
                        class="col-8 col-offset-4 text-error"
                        *ngIf="
                          !!attacksFormArray.get([i, FORM_PARAMS.ROUNDS_TOTAL])?.touched &&
                          !!attacksFormArray.get([i, FORM_PARAMS.ROUNDS_TOTAL])?.dirty &&
                          !!attacksFormArray.get([i, FORM_PARAMS.ROUNDS_TOTAL])?.errors
                        ">
                        Number of training rounds must be greater than 1
                      </label>
                    </div>
                  </ng-template>
                </p-accordionTab>
              </ng-container>
            }
          </p-accordion>
        </div>
      </ng-container>
    </ng-container>
  }
</ng-template>
