<div class="cursor-pointer" [ngSwitch]="type">
  <!-- DEVICE -->
  <ng-container *ngSwitchCase="'device'">
    <microsec-card
      (mousedown)="onMouseDown($event)"
      (mouseup)="onMouseUp($event, 'device', item)"
      (mousemove)="onMouseMove($event)"
      (click)="openDetailsPanel($event, 'device', item)"
      [isNestedCard]="true">
      <div class="grid align-items-center">
        <div class="col text-grey">Last seen:&nbsp;{{ !!item.last_seen ? (item.last_seen | momentPipe) : '-' }}</div>
        <div class="col-fixed">
          <div class="flex flex-row align-items-center">
            <p-splitButton
              [label]="item.levelLabel"
              icon="pi pi-sitemap"
              [model]="item.levelOptions"
              (onClick)="openLevelOptions($event)"
              [id]="'device-' + item.id + '-network-map-level-selection'"
              styleClass="network-map-level-selection mr-2 h-2rem border-round-xl surface-800 p-1"
              menuStyleClass="microsec-button-context-menu"
              appendTo="body"
              *ngIf="mode === NETWORK_MAP_MODE_VALUES.PURDUE_MODEL"></p-splitButton>
            <div
              class="flex align-items-center align-content-center justify-content-center w-2rem h-2rem border-round-xl surface-800 p-1"
              [ngClass]="{
                'border-none': item.status !== 'anomalous',
                'border-2 border-red-500': item.status === 'anomalous'
              }">
              <div class="flex align-items-center align-content-center justify-content-center w-1rem h-1rem">
                <i class="fa fa-laptop"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div
          class="col-12 text-3xl mb-2 overflow-hidden white-space-nowrap text-overflow-ellipsis"
          [pTooltip]="item.label"
          tooltipPosition="left"
          appendTo="body">
          <span class="title"> {{ item.label }}</span>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 xl:col-4">
          <div class="underline text-grey mb-2">MAC Address</div>
          <div>{{ !!item.eth ? getMacAddress(item.eth) : '-' }}</div>
        </div>
        <div class="col-12 xl:col-4">
          <div class="w-max" [pTooltip]="!!item.ip ? getIpAddress(item.ip).tooltip : ''" tooltipPosition="top" appendTo="body">
            <div class="underline text-grey mb-2">IP Address</div>
            <div>{{ !!item.ip ? getIpAddress(item.ip).label : '-' }}</div>
          </div>
        </div>
        <div class="col-12 xl:col-4">
          <div class="underline text-grey mb-2">Type</div>
          <div>{{ !!item.type ? item.type : '-' }}</div>
        </div>
      </div>
    </microsec-card>
  </ng-container>
  <!-- THREAT -->
  <ng-container *ngSwitchCase="'threat'">
    <microsec-card (click)="openDetailsPanel($event, 'threat', item)" [isNestedCard]="true">
      <div class="grid align-items-center">
        <div class="col text-grey">Created Date:&nbsp;{{ !!item.created ? (item.created | momentPipe) : '-' }}</div>
        <div class="col-fixed">
          <div class="flex align-items-center align-content-center justify-content-center w-2rem h-2rem border-none border-round-xl surface-800 p-1">
            <div class="flex align-items-center align-content-center justify-content-center w-1rem h-1rem">
              <i class="fa fa-skull-crossbones"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 text-3xl mb-2" [pTooltip]="item.attackTypeLabel" tooltipPosition="left" appendTo="body">
          <span class="title">{{ item.attackTypeLabel || item.attack_type }}</span>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 lg:col-6 xl:col-3">
          <div class="underline text-grey mb-2">Threat Type</div>
          <div>{{ !!item.threat_type ? item.threat_type : '-' }}</div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
          <div class="underline text-grey mb-2">Threat Score</div>
          <div>{{ !!item.threat_score ? (item.threat_score | threatScorePipe) + '/10' : '0/10' }}</div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
          <div class="underline text-grey mb-2">Status</div>
          <div>{{ !!item.status ? item.status : '-' }}</div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
          <div class="underline text-grey mb-2">Impacted Devices</div>
          <div [innerHTML]="!!item.device_ids ? getDeviceNames(item.device_ids) : '-'"></div>
        </div>
      </div>
    </microsec-card>
  </ng-container>
  <!-- DEFAULT - Show nothing -->
  <ng-container *ngSwitchDefault> </ng-container>
</div>
