/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, BehaviorSubject, Observable, scheduled } from 'rxjs';

import { INetworkDeviceService } from './INetworkDeviceService';
import * as devicesData from './mock-data/devices.json';
import * as deviceData from './mock-data/device.json';

@Injectable({
  providedIn: 'root',
})
export class MockNetworkDeviceService implements INetworkDeviceService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  createDevice(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDevices(organization_id?: any, proj_id?: any, page?: number, per_page?: number, sort?: string, supported_features?: string): Observable<any> {
    return scheduled([devicesData as any], asyncScheduler);
  }
  getDevice(id: any): Observable<any> {
    return scheduled([deviceData as any], asyncScheduler);
  }
  syncDevices(organization_id?: any, proj_id?: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  syncDevice(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateDevice(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteDevice(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  createDeviceConfig(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDeviceConfig(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateDeviceConfig(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteDeviceConfig(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  testDeviceConfig(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  createDeviceVlan(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDeviceVlan(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateDeviceVlan(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteDeviceVlan(devicid: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  createDeviceFirewall(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDeviceFirewalls(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDeviceFirewall(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateDeviceFirewall(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteDeviceFirewall(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
