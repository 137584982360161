import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@ids-shared';

import { SharedEventsComponent } from './shared-events.component';
import { EventOverviewComponent } from './event-overview/event-overview.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedEventsComponent, EventOverviewComponent],
  exports: [SharedEventsComponent, EventOverviewComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedEventsModule {
  static forRoot(): ModuleWithProviders<SharedEventsModule> {
    return {
      ngModule: SharedEventsModule,
      providers: [],
    };
  }
}
