import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FIRMWARE_DEVICE_STATUS_VALUES } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { FirmwarePushFormComponent } from '../../../../../../shared-components/shared-firmwares/firmware-push-form/firmware-push-form.component';
import { FirmwareService, TargetDeviceService } from '@ids-services';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';
import { ActionMenuItem } from '@microsec/models';

import { BehaviorSubject, finalize } from 'rxjs';

import { FirmwareAssignedDevicesFormComponent } from './firmware-assigned-devices-form/firmware-assigned-devices-form.component';
import { DELETE_LABEL } from '@microsec/constants';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

@Component({
  selector: 'app-firmware-assigned-devices',
  templateUrl: './firmware-assigned-devices.component.html',
  styleUrls: ['./firmware-assigned-devices.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class FirmwareAssignedDevicesComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _firmware: any = null;

  get firmware() {
    return this._firmware;
  }

  @Input() set firmware(value: any) {
    this._firmware = value;
    this.selectedDevices = [];
    this.devices = this.util.sortObjectArray(value?.assigned_devices, 'name');
  }

  @Output() refreshFirmwareEvent: EventEmitter<any> = new EventEmitter<any>();

  cols: any[] = [
    { field: 'name', header: 'Device', width: 12 },
    { field: 'current_version', header: 'Current Version', width: 12 },
    { field: 'current_status', header: 'Status', width: 8 },
  ];

  globalFilterFields: string[] = ['name', 'current_version'];

  devices: any[] = [];

  selectedDevices: any[] = [];

  FIRMWARE_DEVICE_STATUS_VALUES = FIRMWARE_DEVICE_STATUS_VALUES;

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private firmwareSrv: FirmwareService,
    private targetDeviceSrv: TargetDeviceService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Push to Device',
        icon: 'fa fa-upload',
        visible: () => this.firmware?.target_device === 'hikvision_cctv',
        disabled: ({ rowData }) => rowData.current_status !== FIRMWARE_DEVICE_STATUS_VALUES.SCHEDULED,
        command: ({ rowData }) => this.openFirmwarePushForm([rowData]),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.handleFilterObjUpdate();
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.devices = this.util.sortObjectArray(this.util.cloneObjectArray(this.devices || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result?.search || '';
      }
    });
  }

  openDeviceAssignmentForm() {
    const dialog = this.dialogSrv.open(FirmwareAssignedDevicesFormComponent, {
      data: {
        firmware: this.firmware,
      },
      header: 'Assign Firmware to Device(s)',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.refreshFirmwareEvent.emit();
      }
    });
  }

  openFirmwarePushForm(devices: any[] = []) {
    const dialog = this.dialogSrv.open(FirmwarePushFormComponent, {
      data: {
        firmware: this.firmware,
        devices: !!devices?.length ? devices : this.devices,
      },
      header: 'Push Firmware',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.refreshFirmwareEvent.emit();
      }
    });
  }

  openDeleteConfirmation(devices: any[] = []) {
    const deviceList = devices.map((device) => `<li>${`Device ID ${device.id} ${device.label ? `: ${device.label}` : ''}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Assigned Device(s)',
      customContent: `Are you sure you want to delete ${
        !devices?.length
          ? 'all assigned devices from this firmware?<br/><br/>'
          : `the following assigned device(s) from this firmware?<ul>${deviceList.join('')}</ul>`
      }`,
      next: () => {
        this.isLoading = true;
        const selectedAssignedDeviceIds = (!!devices.length ? devices : this.devices).map((p) => p.device_id);
        const assignedDeviceIds = (this.firmware.assigned_devices as any[])
          .map((p) => p.device_id)
          .filter((id) => !selectedAssignedDeviceIds.includes(id));
        this.firmwareSrv
          .updateFirmware(this.firmware.id, {
            assigned_devices: assignedDeviceIds,
            org_id: this.breadcrumbConfig?.organizationId,
          })
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe({
            next: () => {
              this.showSuccessMessage(`Deleted assigned device(s) successfully`);
              this.refreshFirmwareEvent.emit();
            },
            error: (err: any) => {
              this.showErrorMessage(err);
            },
          });
      },
    });
  }
}
