import { ANALYZER_DATA_TYPES, ANALYZER_FEATURES, ANALYZER_TYPES } from '@ids-constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';

import { ANOMALY_ANALYZER_CHILDREN, ANOMALY_ANALYZER_CONSTANTS } from './anomaly-analyzer.config';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { ILayoutItem } from '@microsec/interfaces';

export const MESSAGE = {
  [ANALYZER_TYPES.ML]: [
    {
      severity: 'warn',
      detail: `Machine learning analyzer learns from historical data to recognize deviations and unusual behaviour that may
  indicate an anomaly in your network. It can detect anomalies in real-time and alert users to take action
  before they become serious issues.`,
      icon: 'fas fa-lightbulb',
    },
  ],
  [ANALYZER_TYPES.RULE_BASED]: [
    {
      severity: 'warn',
      detail: `Rule-Based analyzer allows users to create custom rules that trigger real-time alerts based on specific user-defined conditions or payload transmission inactivity, facilitating timely decision-making. It can detect threats in real-time and alert users to take action before they become serious issues.`,
      icon: 'fas fa-lightbulb',
    },
  ],
  ['vulnerability']: [
    {
      severity: 'warn',
      detail: `Vulnerability analyzer scans packages, configurations, firmwares and protocols for security weaknesses such as software vulnerabilities, misconfigurations, and insecure user practices. Edit the configuration settings below to set up a background vulnerability scan and schedule regular scans to maintain system security.`,
      icon: 'fas fa-lightbulb',
    },
  ],
};

export function setMessage(type: string, dataType?: string) {
  const hideMessagesAnalyzer = JSON.parse(localStorage.getItem('hideMessagesAnalyzer') || '{}');
  const showMessage = !!dataType ? hideMessagesAnalyzer[`${type}_${dataType}`] : hideMessagesAnalyzer[type];
  if (!showMessage) {
    return !!dataType ? (MESSAGE[type] as any)?.[dataType] : MESSAGE[type];
  }
  return null;
}

export function closeMessage(type: string, dataType?: string) {
  const hideMessagesAnalyzer = {
    ...JSON.parse(localStorage.getItem('hideMessagesAnalyzer') || '{}'),
  };
  if (!!dataType) {
    hideMessagesAnalyzer[`${type}_${dataType}`] = true;
  } else {
    hideMessagesAnalyzer[type] = true;
  }
  localStorage.setItem('hideMessagesAnalyzer', JSON.stringify(hideMessagesAnalyzer));
}

@Component({
  selector: 'app-anomaly-analyzer',
  templateUrl: './anomaly-analyzer.component.html',
  styleUrls: ['./anomaly-analyzer.component.scss'],
})
export class AnomalyAnalyzerComponent extends BaseComponent implements OnInit, OnDestroy {
  tabItems = this.util.cloneObjectArray(ANOMALY_ANALYZER_CHILDREN).filter((p) => !!p.label);

  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ENABLED)) {
      if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN)) {
        const tabItems: ILayoutItem[] = this.util.cloneObjectArray(ANOMALY_ANALYZER_CHILDREN).filter((p) => !!p.label);
        tabItems.forEach((item) => {
          if (!!item.featureName && !!item.featureCheckField) {
            item.visible = this.features?.[item.featureName]?.[item.featureCheckField];
          }
        });
        this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      }
      if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
        const tabItems: ILayoutItem[] = this.util.cloneObjectArray(ANOMALY_ANALYZER_CHILDREN).filter((p) => !!p.label);
        tabItems.forEach((item) => {
          if (!!item.featureName && !!item.featureCheckField) {
            item.visible =
              this.features?.[item.featureName]?.[item.featureCheckField] && item.route !== ANOMALY_ANALYZER_CONSTANTS.VULNERABILITY.ROUTE;
          }
        });
        this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      }

      if (!localStorage.getItem('hideMessagesAnalyzer')) {
        const hideMessagesAnalyzer = {
          [`${ANALYZER_TYPES.ML}_${ANALYZER_DATA_TYPES.PACKET}`]: false,
          [`${ANALYZER_TYPES.ML}_${ANALYZER_DATA_TYPES.PAYLOAD}`]: false,
          [`${ANALYZER_TYPES.RULE_BASED}`]: false,
          ['vulnerability']: false,
        };
        localStorage.setItem('hideMessagesAnalyzer', JSON.stringify(hideMessagesAnalyzer));
      }
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
