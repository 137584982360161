import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// #region Third parties
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// #endregion

// #region PrimeNG modules, services
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
// #endregion

// #region Services
import { getServiceReplacements } from '@ids-services';
// #endregion

// #region Customs
import { CustomDomainInterceptor, DEFAULT_TIMEOUT, JwtInterceptor, TimeoutInterceptor } from '@microsec/interceptors';
// #endregion

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from '@ids-shared';
import { ProductsModule } from '@ids-products';

// NGRX
import { NgrxLayoutModule } from '@microsec/ngrx-layout';
import { NgrxAuthenticationModule } from '@microsec/ngrx-authentication';
import { NgrxEventMonitorModule } from '@ngrx-event-monitor';

// Service
import { ContextMenuService } from '@microsec/services';

export function createServiceReplacement(realSrv: any, mockSrv: any, shouldUseMockData = false) {
  return {
    provide: realSrv,
    useClass: !!environment.useMockData || !!shouldUseMockData ? mockSrv : realSrv,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ProductsModule.forRoot(environment),
    SharedModule.forRoot(environment),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    ContextMenuModule,
    MenuModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    TooltipModule,
    BadgeModule,
    NgrxLayoutModule,
    NgrxAuthenticationModule,
    NgrxEventMonitorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomDomainInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    // global default of 30 seconds
    { provide: DEFAULT_TIMEOUT, useValue: 30000 },
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    MessageService,
    ConfirmationService,
    ContextMenuService,
    // Service replacements
    [...getServiceReplacements(environment)],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
