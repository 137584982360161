@if (!!isNotLicensePage && !hasLicense) {
  <div class="license-warning-wrapper">
    <div class="box flex flex-row align-items-center justify-content-between text-data">
      <div class="content flex align-items-center">
        <i class="icon text-6xl fas fa-key px-6"></i>
        <div class="message text-base flex flex-column">
          <div class="title text-4xl pb-1">Product is not activated</div>
          <div class="message text-base">Please upload a license key or contact MicroSec Sales to obtain a license key.</div>
        </div>
      </div>
      @if (!!isSuperAdmin) {
        <div class="flex justify-content-end">
          <p-button severity="secondary" styleClass="mr-6" label="Upload License" (onClick)="routeToLicensePage()" />
        </div>
      }
    </div>
  </div>
}
