import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@ids-services';

import { catchError, Observable, throwError } from 'rxjs';

import { IVulnerabilityAnalyzerService } from './IVulnerabilityAnalyzerService';

const PROJECT_VULNERABILITY_ANALYZER = `${API.VULNERABILITY_ANALYZER}/project`;

@Injectable({
  providedIn: 'root',
})
export class VulnerabilityAnalyzerService implements IVulnerabilityAnalyzerService {
  constructor(private httpClient: HttpClient) {}

  getConfiguration(project_id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${PROJECT_VULNERABILITY_ANALYZER}/${project_id}/configurations`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateConfiguration(project_id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${PROJECT_VULNERABILITY_ANALYZER}/${project_id}/configurations`, payload, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
