export const TYPE_OPTIONS = [
  { label: 'Router', value: 'router' },
  { label: 'Switch', value: 'switch' },
];

export const FEATURE_OPTIONS = [
  { label: 'Firewall', value: 'firewall' },
  { label: 'VLAN', value: 'vlan' },
];

export const CONFIG_TYPES = {
  NETCONF: 'netconf',
  RESTFUL_API: 'restful_api',
  SNMP: 'snmp',
  SSH: 'ssh',
};

export const CONFIG_TYPE_OPTIONS = [
  { label: 'NETCONF', value: CONFIG_TYPES.NETCONF },
  { label: 'RESTful API', value: CONFIG_TYPES.RESTFUL_API },
  { label: 'SNMP', value: CONFIG_TYPES.SNMP },
  { label: 'SSH', value: CONFIG_TYPES.SSH },
];

export const MODEL_OPTIONS = [
  {
    label: 'Aruba Instant On 1930',
    value: 'aruba_instant_on_1930',
    deviceType: 'switch',
    supportedConfigTypeOptions: [{ label: 'SNMP', value: CONFIG_TYPES.SNMP }],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Juniper EX2300',
    value: 'juniper_ex2300',
    deviceType: 'switch',
    supportedConfigTypeOptions: [
      { label: 'NETCONF', value: CONFIG_TYPES.NETCONF },
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 1100',
    value: 'netgate_1100',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 1537',
    value: 'netgate_1537',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 1541',
    value: 'netgate_1541',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 2100',
    value: 'netgate_2100',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 3100',
    value: 'netgate_3100',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 5100',
    value: 'netgate_5100',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 6100',
    value: 'netgate_6100',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgate 7100 1U',
    value: 'netgate_7100_1U',
    deviceType: 'router',
    supportedConfigTypeOptions: [
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgear M4300',
    value: 'netgear_m4300',
    deviceType: 'switch',
    supportedConfigTypeOptions: [
      { label: 'RESTful API', value: CONFIG_TYPES.RESTFUL_API },
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
  {
    label: 'Netgear M4500',
    value: 'netgear_m4500',
    deviceType: 'switch',
    supportedConfigTypeOptions: [
      { label: 'RESTful API', value: CONFIG_TYPES.RESTFUL_API },
      { label: 'SNMP', value: CONFIG_TYPES.SNMP },
      { label: 'SSH', value: CONFIG_TYPES.SSH },
    ],
    snmpVersionOptions: [{ label: '2c', value: '2c' }],
  },
];

export const SNMP_PRIV_PROTOCOL_OPTIONS = [
  { label: 'AES128', value: 'aes128' },
  { label: 'None', value: 'none' },
];

export const SNMP_AUTH_PROTOCOL_OPTIONS = [
  { label: 'MD5', value: 'md5' },
  { label: 'SHA1', value: 'sha1' },
  { label: 'None', value: 'none' },
];

export const SNMP_VERSION_OPTIONS = [
  // { label: '1', value: '1' },
  { label: '2c', value: '2c' },
  // { label: '3', value: '3' },
];
