import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DELETE_LABEL, MODULE_CONSTANTS, ORGANIZATION_ID_PARAM_ROUTE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { ORGANIZATION_MANAGEMENT_CONSTANTS, QUOTAS_FEATURES } from '@ids-constants';
import { OrganizationService } from '@microsec/services';
import { SharedOrganizationFormComponent } from '../../shared-components/shared-organization-form/shared-organization-form.component';
import { ReportSpecification, CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';

import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ConfirmationDialogConfig } from '@microsec/models';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

const FIELDS = {
  ID: 'ID',
  NAME: 'Organizations',
  TOTAL_PROJECTS: 'No. of Projects',
  ADDRESS: 'Address',
  TELEPHONE_NUMBER: 'Phone Number',
};

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class OrganizationsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols: any[] = [
    { field: 'name', header: FIELDS.NAME, width: '20' },
    { field: 'total_projects', header: FIELDS.TOTAL_PROJECTS, width: '20' },
  ];

  globalFilterFields: string[] = [...this.cols.map((col) => col.field)];

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private organizationSrv: OrganizationService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN],
        command: ({ rowData }: any) => this.openOrganizationForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN],
        command: ({ rowData }: any) => this.openDeleteConfirmation(rowData),
      },
      {
        label: 'Config',
        icon: 'fa fa-cogs',
        command: ({ event, rowData }: any) => this.goToOrganizationSettings(event, rowData.id),
      },
    ];
    this.getOrganizations();
    this.handleFilterObjUpdate();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.organizationSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getOrganizations();
      }
    });
    this.subscriptions.push(subscription);
  }

  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  getOrganizations() {
    this.isLoading = true;
    this.organizationSrv
      .getOrganizations()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this.values = rs;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        this.dt.generateReportDialog.exportReport(specification?.data as any, 'system_organizations');
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  openOrganizationForm(organization?: any) {
    const dialog = this.dialogSrv.open(SharedOrganizationFormComponent, {
      data: { organization: !organization ? null : organization },
      header: `${!organization ? 'Create' : 'Edit'} Organization`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      this.organizationSrv.refresh$.next(rs);
    });
  }

  openDeleteConfirmation(organization: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Organization',
      object: organization,
      objectFieldNameFunction: (o) => `Organization ID ${o.id} ${!!o.name ? `: ${o.name}` : ''}`,
      acceptRequest: this.organizationSrv.deleteOrganization(organization.id),
      next: () => {
        this.showSuccessMessage(`Deleted organization ${organization.name} successfully`);
        this.getOrganizations();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  goToOrganization(e: MouseEvent, org_id: any) {
    e.preventDefault();
    this.changeRoute(MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, org_id));
  }

  goToOrganizationSettings(e: MouseEvent, org_id: any) {
    e.preventDefault();
    this.changeRoute(
      `${MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE}/${ORGANIZATION_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE}`.replace(
        ORGANIZATION_ID_PARAM_ROUTE,
        org_id,
      ),
    );
  }

  get checkOrganizationQuota() {
    return this.values.length < this.getQuotaLimitation(QUOTAS_FEATURES.ORGANIZATIONS);
  }

  override ngOnDestroy() {
    this.cleanup();
    this.organizationSrv.refresh$.next(null);
  }
}
