export enum USER_SETTINGS_GROUPS {
  PREFERENCES = 'Preferences',
  CERTIFICATES = 'Certificates',
  DEVELOPER = 'Developer',
  NOTIFICATIONS = 'Notifications',
}

export const USER_SETTINGS_CONSTANTS = {
  ORGANIZATIONS: {
    ROUTE: 'organizations',
    LABEL: 'Organizations',
  },
  CONFIG_2FA: {
    ROUTE: '2fa',
    LABEL: '2FA',
  },
  PASSWORD: {
    ROUTE: 'password',
    LABEL: 'Password',
  },
  ACCESS_TOKENS: {
    ROUTE: 'access-tokens',
    LABEL: 'Access Tokens',
  },
  INBOX: {
    ROUTE: 'inbox',
    LABEL: 'Inbox',
  },
};
