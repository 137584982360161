import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { NetworkDeviceService } from '@ids-services';

import { finalize } from 'rxjs';

@Component({
  selector: 'app-network-device-details',
  templateUrl: './network-device-details.component.html',
  styleUrls: ['./network-device-details.component.scss'],
})
export class NetworkDeviceDetailsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _deviceId: any = null;

  get deviceId() {
    return this._deviceId;
  }

  @Input() set deviceId(value: any) {
    this._deviceId = value;
    if (!!this._deviceId) {
      this.getDevice();
    }
  }

  device: any = {};

  constructor(private networkDeviceSrv: NetworkDeviceService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.networkDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !!this.deviceId) {
        this.getDevice();
      }
    });
    this.subscriptions.push(subscription);
  }

  getDevice() {
    this.isLoading = true;
    this.networkDeviceSrv
      .getDevice(this.deviceId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.device = res;
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  testConfiguration(config: any) {
    this.isLoading = true;
    this.networkDeviceSrv
      .testDeviceConfig(config.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Tested configuration succesfully`);
          this.networkDeviceSrv.refresh$.next(true);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }
}
