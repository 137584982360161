import { MODULE_CONSTANTS } from '@microsec/constants';
import { ORGANIZATION_MANAGEMENT_CHILDREN } from './organization-management/organization-management.config';
import { PROJECT_MANAGEMENT_CHILDREN } from './project-management/project-management.config';
import { SYSTEM_SETTINGS_CHILDREN } from './system-settings/system-settings.config';
import { USER_SETTINGS_CHILDREN } from './user-settings/user-settings.config';
import { PROJECT_GETTING_STARTED_CHILDREN } from './project-getting-started/project-getting-started.config';

export const MODULES = {
  SYSTEM_SETTINGS: {
    ROUTE: MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE,
    LABEL: MODULE_CONSTANTS.GLOBAL_SETTINGS.LABEL,
    CHILDREN: SYSTEM_SETTINGS_CHILDREN,
  },
  ORGANIZATION_MANAGEMENT: {
    ROUTE: MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE,
    LABEL: MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.LABEL,
    CHILDREN: ORGANIZATION_MANAGEMENT_CHILDREN,
  },
  PROJECT_MANAGEMENT: {
    ROUTE: MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE,
    LABEL: MODULE_CONSTANTS.PROJECT_MANAGEMENT.LABEL,
    CHILDREN: PROJECT_MANAGEMENT_CHILDREN,
  },
  USER_SETTINGS: {
    ROUTE: MODULE_CONSTANTS.USER_SETTINGS.ROUTE,
    LABEL: MODULE_CONSTANTS.USER_SETTINGS.LABEL,
    CHILDREN: USER_SETTINGS_CHILDREN,
  },
  SYSTEM_GETTING_STARTED: {
    ROUTE: MODULE_CONSTANTS.SYSTEM_GETTING_STARTED.ROUTE,
    LABEL: MODULE_CONSTANTS.SYSTEM_GETTING_STARTED.LABEL,
    CHILDREN: [],
  },
  PROJECT_GETTING_STARTED: {
    ROUTE: MODULE_CONSTANTS.PROJECT_GETTING_STARTED.ROUTE,
    LABEL: MODULE_CONSTANTS.PROJECT_GETTING_STARTED.LABEL,
    CHILDREN: PROJECT_GETTING_STARTED_CHILDREN,
  },
  LOGIN: {
    ROUTE: MODULE_CONSTANTS.LOGIN.ROUTE,
    LABEL: MODULE_CONSTANTS.LOGIN.LABEL,
    CHILDREN: [],
  },
  REDIRECTION: {
    ROUTE: MODULE_CONSTANTS.REDIRECTION.ROUTE,
    LABEL: MODULE_CONSTANTS.REDIRECTION.LABEL,
  },
};
