<div class="grid form">
  <div class="col-12">
    <microsec-card [isNestedCard]="true">
      <div class="mb-3">
        <label class="font-bold">Network Device Details</label>
      </div>
      <div class="flex flex-column mx-3 gap-2 list-fields" *ngIf="!!device">
        <div class="grid align-items-center">
          <label class="col-4">ID</label>
          <label class="col-8 text-data overflow-hidden white-space-nowrap text-overflow-ellipsis">{{ device.id || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Name</label>
          <label class="col-8 text-data">{{ device.label || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Description</label>
          <label class="col-8 text-data">{{ device.description || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Type</label>
          <label class="col-8 text-data">{{ (device?.device_type | titlecase) || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Model</label>
          <label class="col-8 text-data">{{ (device?.model | constant: 'network-device-model') || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Firmware</label>
          <label class="col-8 text-data">{{ device?.firmware || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Configuration Type</label>
          <label class="col-8 text-data">{{ (config?.config_type | constant: 'network-device-config-type') || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">IP Address</label>
          <label class="col-8 text-data">{{ config?.ip_address || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Port</label>
          <label class="col-8 text-data">{{ config?.port || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Status</label>
          <div class="col-8">
            <div class="flex align-items-center gap-2">
              <label *ngIf="config?.connection_status === 'connected'" class="capitalize text-green">
                {{ config.connection_status }}
              </label>
              <p-button
                *ngIf="config?.connection_status !== 'connected'"
                label="Test Connection"
                size="small"
                (onClick)="testConfigurationDialog.open(device, false)"></p-button>
            </div>
          </div>
        </div>
      </div>
    </microsec-card>
  </div>
</div>

<app-shared-test-configuration-dialog #testConfigurationDialog> </app-shared-test-configuration-dialog>
