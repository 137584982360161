import { ILayoutItem } from '@microsec/interfaces';

import { DetectedDevicesComponent } from './detected-devices/detected-devices.component';
import { TagsComponent } from './tags/tags.component';
import { ZonesComponent } from './zones/zones.component';
import { NetworkDevicesComponent } from './network-devices/network-devices.component';
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { VlansComponent } from './vlans/vlans.component';
import { FirewallsComponent } from './firewalls/firewalls.component';

export const DEVICES_CONSTANTS = {
  DETECTED: {
    ROUTE: 'detected',
    LABEL: 'Detected Devices',
  },
  TAGS: {
    ROUTE: 'tags',
    LABEL: 'Tags',
  },
  ZONES: {
    ROUTE: 'zones',
    LABEL: 'Zones',
  },
  NETWORK: {
    ROUTE: 'network',
    LABEL: 'Network Devices',
  },
  FIRMWARES: {
    ROUTE: 'firmwares',
    LABEL: 'Firmwares',
  },
  FIREWALLS: {
    ROUTE: 'firewalls',
    LABEL: 'Firewalls',
  },
  VLANS: {
    ROUTE: 'vlans',
    LABEL: 'VLANs',
  },
};

export const DEVICES_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: DEVICES_CONSTANTS.DETECTED.ROUTE,
  },
  {
    route: DEVICES_CONSTANTS.DETECTED.ROUTE,
    label: DEVICES_CONSTANTS.DETECTED.LABEL,
    component: DetectedDevicesComponent,
  },
  {
    route: DEVICES_CONSTANTS.TAGS.ROUTE,
    label: DEVICES_CONSTANTS.TAGS.LABEL,
    component: TagsComponent,
  },
  {
    route: DEVICES_CONSTANTS.ZONES.ROUTE,
    label: DEVICES_CONSTANTS.ZONES.LABEL,
    component: ZonesComponent,
  },
  {
    route: DEVICES_CONSTANTS.NETWORK.ROUTE,
    label: DEVICES_CONSTANTS.NETWORK.LABEL,
    component: NetworkDevicesComponent,
  },
  {
    route: DEVICES_CONSTANTS.FIRMWARES.ROUTE,
    label: DEVICES_CONSTANTS.FIRMWARES.LABEL,
    component: FirmwaresComponent,
  },
  {
    route: DEVICES_CONSTANTS.FIREWALLS.ROUTE,
    label: DEVICES_CONSTANTS.FIREWALLS.LABEL,
    component: FirewallsComponent,
  },
  {
    route: DEVICES_CONSTANTS.VLANS.ROUTE,
    label: DEVICES_CONSTANTS.VLANS.LABEL,
    component: VlansComponent,
  },
];
