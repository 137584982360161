import { DEVICE_TYPE_OPTIONS, EDITOR_DEVICE_TYPE_SIZE, EDITOR_DIAGRAM_BASE_URL, EDITOR_OBJECT_TYPES } from '@ids-constants';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NmeImportDevicesFormComponent } from '../nme-top-navigation/nme-import-devices-form/nme-import-devices-form.component';
import { NetworkMapEditor } from '@ids-models';
import { NetworkMapHelper } from '@ids-utilities';
import { TargetDeviceService } from '@ids-services';

@Component({
  selector: 'app-nme-left-navigation',
  templateUrl: './nme-left-navigation.component.html',
  styleUrls: ['./nme-left-navigation.component.scss'],
})
export class NmeLeftNavigationComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() editor: NetworkMapEditor | null = null;

  tabs: any[] = [
    {
      label: 'Device Types',
      items: this.util.cloneObjectArray(DEVICE_TYPE_OPTIONS).map((p) => ({
        ...p,
        type: EDITOR_OBJECT_TYPES.DEVICE,
        editorIcon: NetworkMapHelper.getNetworkMapEditorIconUrl(p.value),
      })),
    },
    {
      label: 'Zones',
      items: [
        {
          type: EDITOR_OBJECT_TYPES.ZONE,
          label: 'Zone',
          value: 'zone',
          editorIcon: `${EDITOR_DIAGRAM_BASE_URL}/zones/nme-zone.svg`,
        },
      ],
    },
  ];

  searchText = '';

  totalDeviceCountInterval: any = null;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.loadSVGs();
    this.getTotalDevices();
    if (!!this.editor) {
      this.editor.totalDeviceCountSubject.asObservable().subscribe(() => {
        this.getTotalDevices();
      });
    }
    this.totalDeviceCountInterval = setInterval(() => {
      this.getTotalDevices();
    }, 5000);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (!!this.totalDeviceCountInterval) {
      clearInterval(this.totalDeviceCountInterval);
    }
  }

  getTotalDevices() {
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        page: 1,
        perPage: 1,
        isMulticast: 'false',
      })
      .subscribe({
        next: (rs) => {
          if (!!this.editor) {
            this.editor.totalDeviceCount.set(rs?.total_record || 0);
          }
        },
        error: (err) => {
          window.microsec.error(err);
        },
      });
  }

  /**
   * Load SVG images
   */
  loadSVGs() {
    this.tabs.forEach((tab: any) => {
      ((tab?.items as any[]) || []).forEach((item: any) => {
        if (!!item?.editorIcon) {
          this.http.get(item.editorIcon, { responseType: 'text' }).subscribe({
            next: (data: any) => {
              data = data.replace('<svg ', `<svg width="${EDITOR_DEVICE_TYPE_SIZE}" height="${EDITOR_DEVICE_TYPE_SIZE}" `);
              item.svgContent = this.sanitizer.bypassSecurityTrustHtml(data);
            },
            error: () => {
              item.svgContent = null;
            },
          });
        }
      });
    });
  }

  /**
   * Import devices
   */
  importDevices() {
    const importDeviceDialog = this.dialogSrv.open(NmeImportDevicesFormComponent, {
      header: 'Import Devices',
      data: {
        importedDevices: this.editor?.importedDevices(),
      },
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    importDeviceDialog.onClose.subscribe((result) => {
      if (!!this.editor && !!result) {
        this.editor.showLoader(() => {
          this.editor?.graph?.batchUpdate(() => {
            this.editor?.importDevices(result);
            this.editor!.zoom('fit');
          });
        });
      }
    });
  }

  /**
   * Check if the item is searched
   * @param item
   * @returns
   */
  isInSearch(item: any) {
    const searchText = this.searchText.trim()?.toLowerCase() || '';
    return (item?.value as string)?.toLowerCase().indexOf(searchText) > -1 || (item?.label as string)?.toLowerCase().indexOf(searchText) > -1;
  }
}
