import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MODULE_CONSTANTS } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { Setting2FAService } from '@ids-services';
import { BaseComponent } from '@ids-components';
import { USER_SETTINGS_CONSTANTS } from '@ids-constants';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    private injector: Injector,
    private setting2FASrv: Setting2FAService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(true));
    const USER_SETTINGS_CHILDREN = this.injector.get('USER_SETTINGS_CHILDREN');
    this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.USER_SETTINGS.ROUTE));
    // Check 2FA enabled
    this.setting2FASrv.getOpenGlobalSetting().subscribe({
      next: (response: any) => {
        if (response.is_enabled && response.auth_provider === 'google_auth') {
          const config2faItem = this.util.cloneObjectArray(USER_SETTINGS_CHILDREN).find((p) => p.route === USER_SETTINGS_CONSTANTS.CONFIG_2FA.ROUTE);
          if (!!config2faItem) {
            this.store.dispatch(new fromLayoutActions.UpdateReplacedItems([{ ...config2faItem, visible: true }]));
          }
        }
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }
}
