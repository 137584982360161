import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL, CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AnomalyAnalyzerService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize, Observable } from 'rxjs';

export const FORM_PARAMS = {
  RULE_GID: 'rule_gid',
  RULE_SID: 'rule_sid',
  ENABLED: 'enabled',
  COMMENT: 'comment',
};

@Component({
  selector: 'app-ip-suppressed-rule-form',
  templateUrl: './ip-suppressed-rule-form.component.html',
  styleUrls: ['./ip-suppressed-rule-form.component.scss'],
})
export class IpSuppressedRuleFormComponent extends BaseComponent implements AfterViewInit {
  analyzerId: any = null;

  rule: any = null;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('criticalityField') criticalityField!: TemplateRef<any>;

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.analyzerId = this.dialogConfig?.data?.analyzerId;
    this.rule = this.dialogConfig?.data?.data;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_GID,
        label: 'Rule GID',
        field: 'number',
        required: true,
        focused: true,
        fieldInfo: 'Rule GID',
        defaultValue: this.rule?.[FORM_PARAMS.RULE_GID] || null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_SID,
        label: 'Rule SID',
        field: 'number',
        required: true,
        fieldInfo: 'Rule SID',
        defaultValue: this.rule?.[FORM_PARAMS.RULE_SID] || null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMMENT,
        label: 'Comment',
        field: 'textarea',
        fieldInfo: 'Comment of the rule',
        defaultValue: this.rule?.[FORM_PARAMS.COMMENT] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        defaultValue: this.rule?.[FORM_PARAMS.ENABLED] || true,
      } as FormItem),
    ];
    this.fields = fields;
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const payload = this.form.getRawValue();
    const request: Observable<any> = !this.rule
      ? this.anomalyAnalyzerSrv.createAnalyzerSuppressedRule(this.analyzerId, payload)
      : this.anomalyAnalyzerSrv.updateAnalyzerSuppressedRule(this.analyzerId, this.rule.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.rule ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'suppressed rule'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
