import { Component, Input, ViewChild } from '@angular/core';
import { NetworkMapEditor } from '@ids-models';
import { FormItem } from '@microsec/models';
import { EDITOR_OBJECT_TYPES, EDITOR_ZONE_DARK_LEVEL } from '@ids-constants';
import { FormBuilderComponent } from '@microsec/components';
import { NetworkMapHelper } from '@ids-utilities';

const FORM_PARAMS = {
  BACKGROUND: 'background',
};

@Component({
  selector: 'app-nme-style',
  templateUrl: './nme-style.component.html',
  styleUrls: ['./nme-style.component.scss'],
})
export class NmeStyleComponent {
  @Input() editor: NetworkMapEditor | null = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  initForm() {
    const zoneCells = this.editor?.graph?.getSelectionCells().filter((p) => !!p.id?.startsWith(`${EDITOR_OBJECT_TYPES.ZONE}-`));
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.BACKGROUND,
        label: 'Background',
        field: 'color',
        placeholder: 'Select a color',
        required: true,
        defaultValue: zoneCells?.[0]?.style.fillColor,
      } as FormItem),
    ];
    fields.forEach((field) => {
      field.setMediumSize();
    });
    this.fields = fields;
    this.form.setChangeEvent(FORM_PARAMS.BACKGROUND, () => {
      const zoneCell = this.editor?.selection?.zone;
      if (!!zoneCell) {
        const color = this.form.getControlValue(FORM_PARAMS.BACKGROUND);
        zoneCell.style.fillColor = color;
        zoneCell.style.strokeColor = color;
        zoneCell.style.swimlaneFillColor = NetworkMapHelper.getDarkerColor(color, EDITOR_ZONE_DARK_LEVEL);
        zoneCell.value.data.color = color;
        if (!!this.editor) {
          this.editor.isSaved = false;
          this.editor.graph?.refresh();
        }
      }
    });
  }

  patchData() {
    const zoneCell = this.editor?.graph?.getSelectionCell() || null;
    if (!!zoneCell) {
      const isSaved: any = this.editor?.isSaved;
      this.form.patchValue({ [FORM_PARAMS.BACKGROUND]: zoneCell.style.fillColor }, () => {
        this.editor!.isSaved = isSaved;
      });
    }
  }
}
