<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="UPLOAD_LABEL"
  (submitEvent)="onSubmit($event)"
  [additionalConditions]="!!form && !!form.getControlValue(FORM_PARAMS.IS_AGREE)">
</microsec-form-builder>

<ng-template #aggreementTemplate>
  @if (!!form && !!form.form) {
    <div class="flex align-items-center gap-2" [formGroup]="form.form">
      <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.IS_AGREE" />
      <label>I have read and agree to the <span class="link-blue" (click)="goToDocumentation()">Terms and Conditions</span> of MicroSec.</label>
    </div>
  }
</ng-template>
