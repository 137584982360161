<div class="grid">
  <!-- Buttons -->
  <div class="col-12">
    <div class="flex justify-content-between">
      <div></div>
      <div class="flex align-items-center">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            [autofocus]="true"
            pInputText
            type="text"
            placeholder="Search..."
            (input)="dt?.datatable?.filterGlobal(util.getTextValue($event), 'contains')"
            placeholder="Search..." />
        </span>
        <microsec-refresh-button (refreshEvent)="getOrganizations()" />
      </div>
    </div>
  </div>

  <!-- Table -->
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table #dt [cols]="cols" [values]="values" [loading]="isLoading" [cellTemplate]="cellTemplate" [selectable]="false" />
    </microsec-card>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <ng-container *ngSwitchCase="'name'">
      <microsec-truncate-text class="link" [tooltip]="rowData[col.field]" (click)="goToOrganization($event, rowData.id)">
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    </ng-container>
    <ng-container *ngSwitchCase="'total_projects'">
      {{ rowData[col.field] }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    </ng-container>
  </ng-container>
</ng-template>
