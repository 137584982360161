import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MOMENT_DATETIME, SHORT_TEXT_MAX_LENGTH } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { PIPELINE_TYPE } from '@microsec/constants';

const FORM_PARAMS = {
  NAME: 'name',
  TARGET_DEVICE: 'target_device',
  VERSION: 'version',
  UPLOADED: 'uploaded',
  SIGNING_ACTIVE: 'signing_active',
  SIGNING_KEY_PAIR: 'signing_key_pair',
  SIGNING_PUBLIC_KEY: 'signing_public_key',
  SIGNING_ENVELOPE_TEMPLATE: 'signing_envelope_template',
  SIGNING_CA: 'signing_ca',
};

@Component({
  selector: 'app-firmware-overview',
  templateUrl: './firmware-overview.component.html',
  styleUrls: ['./firmware-overview.component.scss'],
})
export class FirmwareOverviewComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  _firmware: any = null;

  get firmware() {
    return this._firmware;
  }

  @Input() set firmware(value: any) {
    this._firmware = value;
    this.initForm();
  }

  MOMENT_DATETIME = MOMENT_DATETIME;

  SHORT_TEXT_MAX_LENGTH = SHORT_TEXT_MAX_LENGTH;

  ngAfterViewInit(): void {
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    this.fields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'label',
        defaultValue: this.firmware.name,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TARGET_DEVICE,
        label: 'Target Device',
        field: 'label',
        defaultValue: this.firmware.target_device,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VERSION,
        label: 'Version',
        field: 'label',
        defaultValue: this.firmware.version,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.UPLOADED,
        label: 'Uploaded',
        field: 'label',
        defaultValue: this.firmware.created_date,
        pipeline: PIPELINE_TYPE.DATETIME,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_ACTIVE,
        label: 'Signing Active',
        field: 'label',
        defaultValue: !!this.firmware?.signed ? 'Yes' : 'No',
        hidden: false,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_KEY_PAIR,
        label: 'Signing Key Pair',
        field: 'label',
        defaultValue: this.firmware.signing_key_id,
        hidden: !this.firmware?.signed || this.firmware?.sign_mode !== 'keypair',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_PUBLIC_KEY,
        label: 'Signing Public Key',
        field: 'copy',
        defaultValue: this.firmware.checksum,
        hidden: !this.firmware?.signed || this.firmware?.sign_mode !== 'keypair',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_ENVELOPE_TEMPLATE,
        label: 'Signing Envelope Template',
        field: 'label',
        defaultValue: this.firmware.signing_envelope_template_displayname,
        hidden: !this.firmware?.signed || this.firmware?.sign_mode !== 'ca',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING_CA,
        label: 'Signing CA',
        field: 'label',
        defaultValue: this.firmware?.signing_envelope_ca_displayname || this.firmware.signing_envelope_ca_id,
        hidden: !this.firmware?.signed || this.firmware?.sign_mode !== 'ca',
      } as FormItem),
    ];
  }
}
