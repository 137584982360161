import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ORGANIZATION_MANAGEMENT_CONSTANTS, QUOTAS_FEATURES } from '@ids-constants';
import { LicenseService } from '@ids-services';
import { BaseComponent } from '@ids-components';
import {
  PIPE_DATETIME,
  MODULE_CONSTANTS,
  PER_PAGE,
  PER_PAGE_OPTIONS,
  DELETE_LABEL,
  DOCUMENTATION_URL,
  DOMAIN_TOKEN,
  ORGANIZATION_ID_PARAM_ROUTE,
} from '@microsec/constants';
import { finalize } from 'rxjs/operators';
import { LicenseFormComponent } from './license-form/license-form.component';
import { FeatureService, OrganizationService } from '@microsec/services';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
})
export class LicensesComponent extends BaseComponent implements OnInit {
  isLoading = true;

  orgId: any = null;

  machineId: any;

  license: any;

  licenses: any[] = [];

  uploadedDialogVisible = false;

  faCheck = faCheck;

  faQuestionCircle = faQuestionCircle;

  PIPE_DATETIME = PIPE_DATETIME;

  QUOTAS_FEATURES = QUOTAS_FEATURES;

  PER_PAGE = PER_PAGE;

  PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;

  @ViewChild('uploadedConfirmationTemplate') uploadedConfirmationTemplate!: ElementRef;

  constructor(
    private licenseSrv: LicenseService,
    private organizationSrv: OrganizationService,
    private featureSrv: FeatureService,
  ) {
    super();
  }

  ngOnInit() {
    this.getMachineID();
    this.getAllLicenses();
    this.getOrganizations();
  }

  /**
   * Get machine ID
   */
  getMachineID() {
    this.isLoading = true;
    this.licenseSrv
      .getMachineID()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.machineId = response.data;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get all licenses
   */
  getAllLicenses(onFinalize?: () => void) {
    this.isLoading = true;
    this.licenseSrv
      .getAllLicenses()
      .pipe(
        finalize(() => {
          if (onFinalize) {
            onFinalize();
          } else {
            this.isLoading = false;
          }
        }),
      )
      .subscribe({
        next: (response: any) => {
          const licenses = this.util.sortObjectArray((response?.data as any[]) || [], 'modified', false, true);
          this.license = licenses[0] || null;
        },
        error: (err: any) => {
          this.license = null;
          this.showErrorMessage(err);
        },
      });
  }

  getOrganizations() {
    this.isLoading = true;
    this.organizationSrv
      .getOrganizations()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          const organizations = this.util.sortObjectArray((rs as any[]) || [], 'created', false, true);
          this.orgId = organizations[0]?.id || null;
        },
      });
  }

  /**
   * Open License Form
   */
  openLicenseForm(isInit = false) {
    const dialog = this.dialogSrv.open(LicenseFormComponent, {
      header: 'Upload License',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((succeed) => {
      if (!!succeed) {
        if (!!isInit) {
          this.getFeatures(() => {
            this.uploadedDialogVisible = true;
            this.isLoading = false;
          });
        } else {
          this.getFeatures();
        }
      }
    });
  }

  /**
   * Open Delete License Confirmation
   */
  openDeleteConfirmation(license: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'License',
      object: license,
      objectFieldName: 'name',
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.licenseSrv.deleteLicense(license.id).pipe(),
      next: () => {
        this.showSuccessMessage(`Deleted license ${license.name} successfully`);
        this.getFeatures();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
        this.isLoading = false;
      },
    });
  }

  getFeatures(onFinalize?: () => void) {
    this.isLoading = true;
    this.featureSrv
      .getFeatures()
      .pipe(
        finalize(() => {
          if (onFinalize) {
            onFinalize();
          } else {
            this.getAllLicenses();
          }
        }),
      )
      .subscribe();
  }

  onViewLicense() {
    this.getFeatures(() => {
      this.getAllLicenses(() => {
        this.uploadedDialogVisible = false;
        this.isLoading = false;
      });
    });
  }

  goToSetup() {
    if (!!this.orgId) {
      this.changeRoute(
        `${MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, this.orgId)}/${
          ORGANIZATION_MANAGEMENT_CONSTANTS.PROJECTS.ROUTE
        }`,
      );
    } else {
      this.changeRoute(`${MODULE_CONSTANTS.REDIRECTION.ROUTE}`);
    }
  }

  goToDocumentation() {
    const customDomain: any = localStorage.getItem('domain');
    window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
  }
}
