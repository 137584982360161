<div class="flex flex-column w-full">
  <div class="text-highlight text-center py-3 border-bottom-2 border-cyan-500">Diagram</div>
</div>
<div class="flex flex-column w-full text-data p-3 gap-3">
  <div class="font-bold">View</div>
  @for (cf of editor?.config; track $index) {
    <p-checkbox
      [label]="cf?.label"
      [binary]="true"
      [(ngModel)]="cf.value"
      (onChange)="changeDiagramConfig(cf)"
      [disabled]="cf.key === EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND || cf.key === EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND_COLOR" />
  }
</div>
