<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="!analyzer && activeStep === 1 ? CREATE_BUTTON_LABEL : !!analyzer && activeStep === 1 ? SAVE_CHANGES_BUTTON_LABEL : ''"
  cancelButtonLabel=""
  (submitEvent)="onSubmit($event)"
  [leftActionArea]="leftActionArea">
</microsec-form-builder>

<ng-template #leftActionArea>
  <div class="flex gap-2" [ngClass]="{ 'mr-2': !!analyzer || activeStep >= 1 }">
    <p-button severity="secondary" type="button" (onClick)="fb.dialogRef.close(false)" [label]="!!analyzer && activeStep === 2 ? 'Done' : 'Cancel'">
    </p-button>
    @if (activeStep === 1) {
      <p-button type="button" icon="fa fa-angle-left" label="Back" severity="secondary" (onClick)="onActiveStepChange(false)"></p-button>
    }
    @if (activeStep === 0) {
      <p-button
        type="button"
        icon="fa fa-angle-right"
        iconPos="right"
        label="Next"
        severity="primary"
        [disabled]="!isCurrentStepValid"
        (onClick)="onActiveStepChange()"></p-button>
    }
  </div>
</ng-template>

<ng-template #stepsTemplate>
  <div class="my-3 mx-8">
    <microsec-custom-steps [steps]="steps" [activeStep]="activeStep"></microsec-custom-steps>
  </div>
</ng-template>

<ng-template #protocolsField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div>
        <p-treeSelect
          #protocolsTreeSelect
          appendTo="body"
          [formControlName]="FORM_PARAMS.PROTOCOL"
          [options]="protocolOptions"
          [metaKeySelection]="false"
          [propagateSelectionUp]="false"
          [propagateSelectionDown]="false"
          selectionMode="single"
          placeholder="Select a protocol">
          <ng-template pTemplate="header">
            <div class="p-treeselect-header">
              <div class="p-treeselect-filter-container">
                <input type="text" class="p-treeselect-filter p-inputtext" (input)="protocolsTreeSelect.onFilterInput($event)" />
                <span class="p-treeselect-filter-icon pi pi-search"></span>
              </div>
              <button class="p-treeselect-close p-link" (click)="protocolsTreeSelect.hide($event)">
                <span class="p-treeselect-filter-icon pi pi-times"></span>
              </button>
            </div>
          </ng-template>
        </p-treeSelect>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #anomalySensitivityThresholdField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="grid align-items-center mt-2">
        <div class="col">
          <p-slider [formControlName]="FORM_PARAMS.ANOMALY_SENSITIVITY_THRESHOLD" [step]="0.1" [min]="0.1" [max]="0.9"></p-slider>
        </div>
        <div class="col-fixed text-center" style="width: 50px">
          <label class="font-bold">
            {{ form.getControlValue(FORM_PARAMS.ANOMALY_SENSITIVITY_THRESHOLD) }}
          </label>
        </div>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #trainingTimeTemplate>
  @if (!!form && !!form.form && !!form.getControlValue(FORM_PARAMS.TRAINING_TIME)) {
    <ng-container [formGroup]="form.form">
      <div class="-mt-2">
        <p-divider></p-divider>
      </div>
      <div class="flex justify-content-center align-items-center gap-2" style="margin-left: 35px">
        <label>Training Time:</label>
        <label>{{ form.getControlValue(FORM_PARAMS.TRAINING_TIME) }}</label>
        <span
          class="mt-1"
          pTooltip="The training time is influenced by the number of training packets specified.<br><br>A higher number of training packets increases the training time, but also enhances the model's accuracy."
          [escape]="false"
          tooltipPosition="right"
          appendTo="body">
          <i class="pi pi-info-circle pr-1"></i>
        </span>
      </div>
    </ng-container>
  }
</ng-template>
