/* eslint-disable prefer-rest-params */
import { EDITOR_DIAGRAM_CONFIGS, EDITOR_DIAGRAM_CONFIG_KEYS, EDITOR_OBJECT_TYPES } from '@ids-constants';
import { CommonMaxgraph } from '../common-maxgraph/common-maxgraph';
import { Cell, ConnectionHandler, KeyHandler, Point, PopupMenuHandler, SelectionHandler, SwimlaneManager, UndoManager } from '@maxgraph/core';
import { Util } from '@microsec/utilities';
import { Observable, Subject } from 'rxjs';
import { WritableSignal, computed, signal } from '@angular/core';
import { NMEConfig } from './nme-config';
import { NMEInit } from './nme-init';
import { NMEGrid } from './nme-grid';
import { NMEXML } from './nme-xml';
import { NMEImport } from './nme-import';
import { NMEDraggableItem } from './nme-draggable-item';
import { NMEZoom } from './nme-zoom';
import { NMELayer } from './nme-layer';
import { NMECell } from './nme-cell';
import { NMEUndo } from './nme-undo';

export class NetworkMapEditor extends CommonMaxgraph {
  /**
   * Check if the network map editor is changed
   */
  isChanged: boolean | null = null;

  /**
   * Check if the network map editor is saved
   */
  isSaved: boolean | null = null;

  /**
   * Last saved datetime
   */
  lastSaved: any = null;

  /**
   * All device count
   */
  totalDeviceCount: WritableSignal<number> = signal<number>(0);

  /**
   * Imported devices
   */
  importedDevices: WritableSignal<any[]> = signal<any[]>([]);

  /**
   * Total device count subject to trigger event
   */
  totalDeviceCountSubject: Subject<any> = new Subject<any>();

  /**
   * ================================= SELECTION =================================
   */

  selection: { device: Cell | null; zone: Cell | null } = {
    device: null,
    zone: null,
  };

  /**
   * ================================= LAYERS =================================
   */
  _layers: { device: Cell | null; zone: Cell | null } = {
    device: null,
    zone: null,
  };

  get layers() {
    return this._layers;
  }

  set layers(value: { device: Cell | null; zone: Cell | null }) {
    this._layers = value;
  }

  /**
   * ================================= CONFIG =================================
   */
  _config: any[] = Util.cloneObjectArray(EDITOR_DIAGRAM_CONFIGS).map((p) => ({
    ...p,
    value:
      p.key !== EDITOR_DIAGRAM_CONFIG_KEYS.PAGE_VIEW &&
      p.key !== EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND &&
      p.key !== EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND_COLOR,
  }));

  get config() {
    return this._config;
  }

  set config(value: any[]) {
    this._config = value;
  }

  gridConfig: {
    translate: Point;
    gridSize: number;
    scale: number;
    width: number;
    height: number;
  } = {
    translate: new Point(),
    gridSize: 0,
    scale: 0,
    width: 0,
    height: 0,
  };

  /**
   * ================================= MANAGERS =================================
   */

  keyHandler: KeyHandler | null = null;

  connectionHandler: ConnectionHandler | null = null;

  selectionHandler: SelectionHandler | null = null;

  swimlaneManager: SwimlaneManager | null = null;

  popupMenuHandler: PopupMenuHandler | null = null;

  undoManager: UndoManager | null = null;

  constructor() {
    super();
    // Init & Config
    this.initGraph = NMEInit.initGraph.bind(this);
    this.initOverrideFunctions = NMEInit.initOverrideFunctions.bind(this);
    this.initHandlers = NMEInit.initHandlers.bind(this);
    this.configGraph = NMEConfig.configGraph.bind(this);
    // Grid
    this.createResizableGrids = NMEGrid.createResizableGrids.bind(this);
    this.repaintGrids = NMEGrid.repaintGrids.bind(this);
    // Undo/Redo
    this.undo = NMEUndo.undo.bind(this);
    this.redo = NMEUndo.redo.bind(this);
    // XML
    this.importXML = NMEXML.importXML.bind(this);
    this.exportXML = NMEXML.exportXML.bind(this);
    // Import Devices
    this.importDevices = NMEImport.importDevices.bind(this);
    this.resetGraphLayout = NMEImport.resetGraphLayout.bind(this);
    // Draggable Items
    this.createDraggableItems = NMEDraggableItem.createDraggableItems.bind(this);
    // Zoom
    this.zoom = NMEZoom.zoom.bind(this);
    // Layer
    this.orderLayers = NMELayer.orderLayers.bind(this);
    // Cell
    this.removeSelectedCells = NMECell.removeSelectedCells.bind(this);
  }

  /**
   * ================================= INIT & CONFIG =================================
   */
  initGraph: () => void;
  initOverrideFunctions: () => void;
  initHandlers: () => void;
  configGraph: () => void;

  /**
   * ================================= GRID =================================
   */
  createResizableGrids: () => void;
  repaintGrids: (isForced: boolean) => void;

  /**
   * ================================= UNDO/REDO =================================
   */

  undo: () => void;
  redo: () => void;

  /**
   * ================================= XML =================================
   */
  importXML: (importedXML: string, isFirstLoad?: boolean) => void;
  exportXML: () => void;

  /**
   * ================================= IMPORT DEVICES =================================
   */
  importDevices: (data: any) => void;
  resetGraphLayout: (requests: Observable<any>) => void;

  /**
   * ================================= DRAGGABLE ITEMS =================================
   */

  createDraggableItems: () => void;

  /**
   * ================================= IMPORTED CALCULATION =================================
   */

  /**
   * All not imported devices count
   */
  totalNotImportedDeviceCount = computed(() => {
    return this.totalDeviceCount() - this.importedDevices().length;
  });

  /**
   * Add imported Device ID
   * @param deviceId
   */
  addImportedDeviceId(deviceId: any) {
    this.importedDevices.update((importedDevices) => {
      if (!Array.isArray(deviceId)) {
        importedDevices.push(deviceId);
      } else {
        importedDevices.push(...(deviceId as any[]));
      }
      importedDevices = importedDevices.filter((p, index, self) => self.indexOf(p) === index && !!p);
      return importedDevices;
    });
  }

  /**
   * Remove imported Device ID
   * @param deviceId
   */
  removeImportedDeviceId(deviceId: any) {
    this.importedDevices.update((importedDevices) => {
      importedDevices = importedDevices.filter((p) => (!Array.isArray(deviceId) ? p !== deviceId : !(deviceId as any[]).includes(p)));
      return importedDevices;
    });
  }

  /**
   * ================================= CELL =================================
   */

  removeSelectedCells: () => void;

  /**
   * ================================= LAYERS =================================
   */

  orderLayers: (mode: any) => void;

  /**
   * ================================= ZOOM =================================
   */

  zoom: (mode: any) => void;

  /**
   * ================================= HELPER =================================
   */

  /**
   * Clear all objects on the graph
   */
  override clearGraph() {
    this.importedDevices.set([]);
    this.graph?.removeCells(this.layers.zone?.children || []);
    this.graph?.removeCells(this.layers.device?.children || []);
  }

  /**
   * Update imported devices
   */
  updateImportedDevices() {
    const existingDeviceIds = Object.entries(this.graph?.model.cells || {})
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_key, cell]) => cell.value?.type === EDITOR_OBJECT_TYPES.DEVICE)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_key, cell]) => cell.value?.data?.device_id)
      .filter((p) => !!p);
    this.importedDevices.set(existingDeviceIds);
  }
}
