import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { NetworkDeviceService } from '@ids-services';
import { verticalSlideAnimation } from '@microsec/animations';
import { CommonTableComponent } from '@microsec/components';
import { ReportSpecification, CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem } from '@microsec/models';

import { BehaviorSubject, finalize, forkJoin, Observable } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';

import { VlanFormComponent } from './vlan-form/vlan-form.component';
import { SharedTestConfigurationDialogComponent } from '../shared-test-configuration-dialog/shared-test-configuration-dialog.component';
import { DELETE_LABEL } from '@microsec/constants';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

const FIELDS = {
  id: 'ID',
  vlan_id: 'VLAN ID',
  name: 'Name',
  description: 'Description',
  interface: 'Interface',
  priority: 'Priority',
  portMembers: 'Port Members',
};

@Component({
  selector: 'app-shared-vlans',
  templateUrl: './shared-vlans.component.html',
  styleUrls: ['./shared-vlans.component.scss'],
  animations: [verticalSlideAnimation],
  providers: [ArrayToPlaceholderStringPipe],
})
export class SharedVlansComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  currentPage = 1;

  totalRecords = 0;

  lazyLoadEvent: LazyLoadEvent | null = null;

  values: any[] = [];

  cols: any[] = [
    { field: 'id', header: 'ID', width: 6 },
    { field: 'name', header: 'Name', width: 12 },
    { field: 'description', header: 'Description', width: 15 },
    { field: 'interface', header: 'Interface', width: 10 },
    { field: 'priority', header: 'Priority', width: 10 },
  ];

  globalFilterFields: string[] = [...this.cols.map((col) => col.field)];

  selectedVlans: any[] = [];

  _device: any = null;

  get device() {
    return this._device;
  }

  @Input() set device(value: any) {
    this._device = value;
    if (!!this._device) {
      this.isLoading = false;
      this.setData([this._device]);
    }
  }

  devices: any[] = [];

  @ViewChild('dt') dt!: CommonTableComponent;

  @ViewChild('testConfigurationDialog') testConfigurationDialog!: SharedTestConfigurationDialogComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private networkDeviceSrv: NetworkDeviceService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.handleFilterObjUpdate();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openVlanForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    if (!this.device) {
      this.getDeviceVlans();
    }
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.networkDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !this.device) {
        this.getDeviceVlans();
      }
    });
    this.subscriptions.push(subscription);
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result?.search || '';
      }
    });
  }

  getDeviceVlans() {
    this.isLoading = true;
    const request: Observable<any> = !!this.device
      ? this.networkDeviceSrv.getDevice(this.device.id)
      : this.networkDeviceSrv.getDevices(
          this.breadcrumbConfig?.organizationId,
          this.breadcrumbConfig?.projectId,
          undefined,
          undefined,
          undefined,
          'vlan',
        );
    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.setData(!!this.device ? [res] : (res.devices as any[]) || []);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  setData(devices: any[]) {
    this.devices = devices;
    const values: any[] = [];
    this.devices.forEach((device) => {
      //populate device_id and config_id into the table row values
      //important for edit VLAN and firewall record to identify the associated device
      const vlans = ((device.vlans as any[]) || []).map((vlan) => ({
        device_id: device.id || null,
        config_id: device.configs[0]?.id || null,
        ...vlan,
      }));
      values.push(...vlans);
    });
    this.values = values;
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        this.dt.generateReportDialog.exportReport(
          specification?.data as any[] | [],
          `${!!this.device ? `device_${this.device.id}` : `project_${this.breadcrumbConfig?.projectId}`}_vlans`,
        );
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  syncVlans() {
    if (this.device?.configs?.[0]?.connection_status === 'connected') {
      this.isLoading = true;
      const request: Observable<any> = !!this.device
        ? this.networkDeviceSrv.syncDevice(this.device.id)
        : this.networkDeviceSrv.syncDevices(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
      request
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage('Syncronization completed successfully');
            this.getDeviceVlans();
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    } else {
      this.testConfigurationDialog.open(this.device);
    }
  }

  openVlanForm(vlan?: any) {
    if (!!vlan || this.device?.configs?.[0]?.connection_status === 'connected') {
      const dialog = this.dialogSrv.open(VlanFormComponent, {
        header: `${!vlan ? 'Create' : 'Update'} VLAN`,
        data: {
          deviceId: this.device?.id || vlan.device_id || null,
          configId: this.device?.configs?.[0]?.id || vlan.config_id || null,
          devices: this.device ? [this.device] : this.devices,
          vlan: vlan,
        },
        width: '800px',
        height: 'min-content',
        closable: false,
      });
      dialog.onClose.subscribe((rs) => {
        if (!!rs) {
          this.getDeviceVlans();
        }
      });
    } else {
      this.testConfigurationDialog.open(this.device);
    }
  }

  openDeleteConfirmation(vlans: any[] = []) {
    const vlanList = vlans.map((vlan) => `<li>VLAN ID ${vlan.id} ${vlan.name ? `: ${vlan.name}` : ''}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'VLAN(s)',
      customContent: `Are you sure you want to delete ${
        !vlans?.length ? 'all VLANs?<br/><br/>' : `the following VLAN(s)?<ul>${vlanList.join('')}</ul>`
      }`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: forkJoin((((vlans.length ? vlans : this.values) as any[]) || []).map((vlan) => this.networkDeviceSrv.deleteDeviceVlan(vlan.id))),
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`Deleted VLAN(s) successfully`);
        if (!!this.selectedVlans?.length) {
          this.selectedVlans = [];
        }
        this.getDeviceVlans();
      },
      error: (err) => {
        this.isLoading = false;
        this.showErrorMessage(err);
      },
    });
  }

  override ngOnDestroy() {
    this.cleanup();
    this.networkDeviceSrv.refresh$.next(null);
  }
}
