<microsec-card>
  <div class="grid field">
    <label class="col-12">
      Use a one-time password authenticator on your mobile device to enable two-factor authentication (2FA) for login and device enrolment.
    </label>
  </div>
  <div class="grid field">
    <label class="col-fixed underline">2FA Status:</label>
    <label class="col-8">{{ setting.status ? 'Enabled' : 'Disabled' }}</label>
  </div>

  <!-- 2FA Enabled -->
  @if (setting.status) {
    <div class="grid field">
      <label class="col-12">
        You've already enabled two-factor authentication using one time password authenticators.
        <br />
        In order to register a different device, you must first disable two-factor authentication.
      </label>
    </div>
    <div class="grid field">
      <div class="col-12">
        <p-button [autofocus]="true" severity="danger" label="Disable 2FA" (onClick)="setUserSetting(false)" [disabled]="isDisabled" />
      </div>
      <!-- <div class="col-fixed">
        <p-button severity="secondary" label="Regenerate recovery codes" />
      </div> -->
    </div>
  } @else {
    <div class="grid field">
      <label class="col-12"> You've haven't setup 2FA for your account yet. </label>
    </div>
    <div class="grid field">
      <div class="col-12">
        <p-button [autofocus]="true" severity="success" label="Enable 2FA" (onClick)="setUserSetting(true)" [disabled]="isDisabled" />
      </div>
    </div>
  }
</microsec-card>
