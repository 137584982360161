import { IPV4_PATTERN, IPV6_PATTERN, PORT_PATTERN, VALIDATOR_TYPE } from '@microsec/constants';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL, CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { CHAIN_OPTIONS, IP_PROTOCOL_OPTIONS, IP_VERSION_OPTIONS, TARGET_OPTIONS } from '@ids-constants';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { NetworkDeviceService } from '@ids-services';

import { Observable, finalize } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export const FORM_PARAMS = {
  DEVICE_ID: 'device_id',
  CONFIG_ID: 'config_id',
  RULE_ID: 'rule_id',
  TARGET: 'target',
  IP_PROTOCOL: 'ip_protocol',
  IP_VERSION: 'ip_version',
  POSITION: 'position',
  DESCRIPTION: 'description',
  CHAIN: 'chain',
  INTERFACE: 'interface',
  SOURCE_ADDRESS: 'source_address',
  SOURCE_PORT: 'source_port',
  DESTINATION_ADDRESS: 'destination_address',
  DESTINATION_PORT: 'destination_port',
};
@Component({
  selector: 'app-firewall-form',
  templateUrl: './firewall-form.component.html',
  styleUrls: ['./firewall-form.component.scss'],
})
export class FirewallFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  devices: any[] = [];

  deviceId: any = null;

  configId: any = null;

  firewall: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  FORM_PARAMS = FORM_PARAMS;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private networkDeviceSrv: NetworkDeviceService,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.devices = this.dialogConfig?.data?.devices;
    this.deviceId = this.dialogConfig?.data?.deviceId;
    this.configId = this.dialogConfig?.data?.configId;
    this.firewall = this.dialogConfig?.data?.firewall;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DEVICE_ID,
        label: 'Device',
        field: 'dropdown',
        options: ((this.devices as any[]) || [])?.map((device) => ({
          value: device.id,
          label: device.label,
        })),
        placeholder: 'Select a device',
        required: true,
        fieldInfo: 'Device of the VLAN',
        defaultValue: this.deviceId || null,
        focused: !this.deviceId,
        hidden: !!this.deviceId || !!this.firewall,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONFIG_ID,
        defaultValue: this.configId || null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_ID,
        label: 'Rule ID',
        field: !!this.firewall ? 'label' : 'input',
        required: true,
        inputBlockSpace: true,
        pattern: /^\S*$/,
        patternErrorText: 'Invalid Rule ID. Ensure that there are no whitespaces entered.',
        fieldInfo: 'Custom rule id',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        inputBlockSpace: true,
        pattern: /^\S*$/,
        patternErrorText: 'Invalid Description. Ensure that there are no whitespaces entered.',
        fieldInfo: 'Description of the firewall',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TARGET,
        label: 'Target',
        field: 'dropdown',
        options: TARGET_OPTIONS,
        placeholder: 'Select a target',
        fieldInfo: 'Target of the firewall',
        defaultValue: null,
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP_PROTOCOL,
        label: 'IP Protocol',
        field: 'dropdown',
        options: IP_PROTOCOL_OPTIONS,
        placeholder: 'Select an IP Protocol',
        fieldInfo: 'IP Protocol of the firewall',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP_VERSION,
        label: 'IP Version',
        field: 'dropdown',
        options: IP_VERSION_OPTIONS,
        placeholder: 'Select an IP Version',
        fieldInfo: 'IP Version of the firewall',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.POSITION,
        label: 'Position',
        field: 'number',
        fieldInfo: 'Position of the firewall',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CHAIN,
        label: 'Chain',
        field: 'dropdown',
        options: CHAIN_OPTIONS,
        placeholder: 'Select a chain',
        fieldInfo: 'Chain of the firewall',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.INTERFACE,
        label: 'Interface',
        fieldInfo: 'Interface of the firewall',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SOURCE_ADDRESS,
        label: 'Source IP Address',
        fieldInfo: 'Source IP address of traffic rule applies to, e.g. 192.0.2.0/29, 192.168.1.4',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SOURCE_PORT,
        label: 'Source Port',
        pattern: PORT_PATTERN,
        fieldInfo: 'Source port of traffic rule applies to',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESTINATION_ADDRESS,
        label: 'Destination IP Address',
        fieldInfo: 'Destination IP address of traffic rule applies to, e.g. 192.0.2.0/29, 192.168.1.4',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESTINATION_PORT,
        label: 'Destination Port',
        pattern: PORT_PATTERN,
        fieldInfo: 'Destination port of traffic rule applies to',
        defaultValue: null,
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.DEVICE_ID, (value) => {
        const configId = this.devices.find((device) => device.id === value)?.configs?.[0]?.id || null;
        this.form.setControlValue(FORM_PARAMS.CONFIG_ID, configId);
      });
      this.form?.setChangeEvent(FORM_PARAMS.IP_VERSION, (value: string) => {
        const ipPattern = value === 'ipv4' ? IPV4_PATTERN : IPV6_PATTERN;
        this.form.setControlValidators(FORM_PARAMS.SOURCE_ADDRESS, [VALIDATOR_TYPE.PATTERN], [ipPattern]);
        this.form.setControlValidators(FORM_PARAMS.DESTINATION_ADDRESS, [VALIDATOR_TYPE.PATTERN], [ipPattern]);
      });
      if (!!this.firewall) {
        this.form.patchValue(this.firewall);
      }
    });
  }

  /**
   * Create/Edit the project and its profile
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const payload = { ...this.form.getRawValue() };
    if (!!this.firewall) {
      delete payload[FORM_PARAMS.DEVICE_ID];
      delete payload[FORM_PARAMS.CONFIG_ID];
      delete payload[FORM_PARAMS.RULE_ID];
    }
    Object.keys(payload).forEach((key) => {
      payload[key] = payload[key] || undefined;
    });
    const request: Observable<any> = !this.firewall
      ? this.networkDeviceSrv.createDeviceFirewall(payload)
      : this.networkDeviceSrv.updateDeviceFirewall(this.firewall.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.firewall ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'Firewall'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
