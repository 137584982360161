@if (form.visible) {
  <div class="h-full overflow-y-auto overflow-x-hidden">
    <app-assessment-form [assessment]="form.assessment" (closeEvent)="closeAssessmentForm()" />
  </div>
} @else {
  <microsec-common-layout [preContentTemplate]="licenseWarningTemplate">
    <app-assessment-list (openAssessmentFormEvent)="openAssessmentForm($event)" />
  </microsec-common-layout>
  <ng-template #licenseWarningTemplate>
    <app-shared-license-warning />
  </ng-template>
}
