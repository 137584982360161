import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NETWORK_MAP_MODES, NETWORK_MAP_MODE_VALUES, NETWORK_MAP_SETTINGS, NETWORK_MAP_SETTING_VALUES } from '@ids-constants';
import { BaseComponent } from '@ids-components';

import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-network-map',
  templateUrl: './network-map.component.html',
  styleUrls: ['./network-map.component.scss'],
})
export class NetworkMapComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = true;

  NETWORK_MAP_MODE_VALUES = NETWORK_MAP_MODE_VALUES;

  _mode = NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED;

  set mode(value: any) {
    this._mode = value;
    if (!!value) {
      this.refresh$.next(null);
    }
  }

  get mode() {
    return this._mode;
  }

  // Selected Device Persistence for Force-Directed and Purdue-Model Mainly
  _selectedDevice = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = value;
  }

  // Setting options
  settings = this.util.cloneObjectArray(NETWORK_MAP_SETTINGS).map((setting: any) => {
    const settingOptions: any[] = (setting.items as any[]).map((p) => ({
      ...p,
      checked: this.checkSettingOption(p.value),
    }));
    setting.items = settingOptions;
    return setting;
  });

  settingOptions$ = new Subject<any>();

  settingOptionsObs = this.settingOptions$.asObservable();

  // Item list
  isItemListDisplayed?: boolean | null = false;

  itemList$ = new BehaviorSubject<any>(true);

  itemListObs = this.itemList$.asObservable();

  // Item details
  isItemDetailsDisplayed?: boolean | null = false;

  itemDetails$ = new BehaviorSubject<any>(null);

  itemDetailsObs = this.itemDetails$.asObservable();

  itemDetailsFirstLoaded = false;

  // Diagram data
  diagramData$ = new BehaviorSubject<any>(null);

  diagramDataObs = this.diagramData$.asObservable();

  // Changed items
  changedItems$ = new BehaviorSubject<any>(null);

  changedItemsObs = this.changedItems$.asObservable();

  // Zoom
  zoom$ = new BehaviorSubject<any>(null);

  // Refresh
  refresh$ = new BehaviorSubject<any>(null);

  refreshObs = this.refresh$.asObservable();

  queryParams: Params | null = null;

  constructor(private route: ActivatedRoute) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
      if (!!this.queryParams?.['mode']) {
        const modeString = this.queryParams?.['mode'];
        const mode = NETWORK_MAP_MODES.find((p) => p.value === modeString)?.value;
        if (!!mode) {
          this.mode = mode;
        }
        this.router.navigate([], { queryParams: { mode: null }, queryParamsHandling: 'merge' });
      }
    });
    this.itemListObs.subscribe((itemList) => {
      if (!!itemList) {
        this.isItemListDisplayed = true;
      } else {
        this.isItemListDisplayed = null;
        setTimeout(() => {
          this.isItemListDisplayed = false;
        }, 450);
      }
    });
    this.itemDetailsObs.subscribe((itemDetail) => {
      if (!!itemDetail?.data) {
        this.isItemDetailsDisplayed = !!itemDetail?.type;
      } else {
        if (!this.itemDetailsFirstLoaded) {
          this.isItemDetailsDisplayed = false;
          this.itemDetailsFirstLoaded = true;
        } else {
          this.isItemDetailsDisplayed = null;
          setTimeout(() => {
            this.isItemDetailsDisplayed = false;
          }, 450);
        }
      }
    });
  }

  showItemList() {
    this.itemList$.next(true);
  }

  refresh() {
    const mode = this.mode;
    this.mode = null;
    setTimeout(() => {
      this.mode = mode;
    });
  }

  updateZoom(option: number | null = null) {
    this.zoom$.next(option);
  }

  checkSettingOption(optionValue: any) {
    if (optionValue === NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_NAME) {
      localStorage.setItem(`network_map_${optionValue}`, 'true');
    }
    const localstorageValue = localStorage.getItem(`network_map_${optionValue}`) === 'true';
    return localstorageValue;
  }

  changeSelectedDevice(value: any) {
    this.selectedDevice = value;
  }

  override ngOnDestroy(): void {
    // Navigate away from Network-Map (-): Remove Selected Device from Network-map parent component
    this.selectedDevice = null;
  }
}
