import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ILayoutItem } from '@microsec/interfaces';

import { ASSESSMENT_TOOL_CHILDREN } from './assessment-tool.config';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { ASSESSMENT_FEATURES } from '@ids-constants';

@Component({
  selector: 'app-assessment-tool',
  templateUrl: './assessment-tool.component.html',
  styleUrls: ['./assessment-tool.component.scss'],
})
export class AssessmentToolComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkAssessmentFeatureEnabled(ASSESSMENT_FEATURES.ENABLED)) {
      const tabItems: ILayoutItem[] = this.util.cloneObjectArray(ASSESSMENT_TOOL_CHILDREN).filter((p) => !!p.label && p.visible !== false);
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
