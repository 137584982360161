import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { UserService } from '@microsec/services';
import { CommonTableComponent } from '@microsec/components';
import { ReportSpecification, CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem, ConfirmationDialogConfig } from '@microsec/models';

import { BehaviorSubject, finalize } from 'rxjs';

import { UserFormComponent } from './user-form/user-form.component';
import { DELETE_LABEL } from '@microsec/constants';
import { QUOTAS_FEATURES } from '@ids-constants';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

const FIELDS = {
  id: 'S/N',
  username: 'Username',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  is_superuser: 'System Admin',
  timestampLabel: 'Timestamp',
};

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class UsersComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  cols: any[] = [
    { field: 'id', header: 'S/N', width: 8, frozen: true },
    { field: 'username', header: 'Username', width: 12 },
    { field: 'first_name', header: 'First Name', width: 12 },
    { field: 'last_name', header: 'Last Name', width: 12 },
    { field: 'email', header: 'Email', width: 15 },
    { field: 'is_superuser', header: 'System Admin', width: 15 },
  ];

  globalFilterFields: string[] = ['username', 'first_name', 'last_name', 'email'];

  values: any[] = [];

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private userSrv: UserService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.getUsers();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        command: ({ rowData }) => this.openUserForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        command: ({ rowData }) => this.openDeleteConfirmation(rowData),
      },
    ];
    this.handleFilterObjUpdate();
  }

  getUsers() {
    this.isLoading = true;
    this.userSrv
      .getUsers('', true)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this.values = this.util.sortObjectArray(rs, 'id');
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result.search || '';
      }
    });

    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.userSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getUsers();
      }
    });
    this.subscriptions.push(subscription);
  }

  openUserForm(user: any = null) {
    const dialog = this.dialogSrv.open(UserFormComponent, {
      header: `${!user ? 'Create' : 'Edit'} User`,
      data: { user },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getUsers();
      }
    });
  }

  openDeleteConfirmation(user: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'User',
      object: user,
      objectFieldName: 'username',
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.userSrv.deleteUser(user.id).pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      ),
      next: () => {
        this.showSuccessMessage(`Deleted user ${user.username} successfully`);
        this.getUsers();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((user) => ({
          ...user,
          is_superuser: user.is_superuser?.toString()?.toUpperCase(),
        }));
        this.dt.generateReportDialog.exportReport(data, `system_users`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  get checkUserQuota() {
    return this.values.length < this.getQuotaLimitation(QUOTAS_FEATURES.USERS);
  }

  //clean and remove subscriptions
  override ngOnDestroy() {
    this.cleanup();
    this.userSrv.refresh$.next(null);
  }
}
