<div class="py-3 px-5" style="min-height: 30rem" *ngIf="activeStep === 1">
  <div class="flex flex-column gap-4">
    <div *ngFor="let group of reportTypes | keyvalue">
      <div class="mb-3 font-bold text-data">{{ group.key | titlecase }} Report</div>
      <div class="flex flex-wrap gap-3" *ngIf="!!group.value?.length">
        <div class="report-type" *ngFor="let type of group.value" (click)="onUpdateReportType(type)">
          <div class="report-type-icon">
            <img class="block w-full" src="/assets/images/report-icons/file-icon.svg" alt="report-file-icon" />
          </div>
          <div class="report-type-name">
            <div class="font-bold text-data">{{ type.name }}</div>
          </div>
          <div class="report-type-description">
            <div class="font-bold text-sm text-data">{{ type.name }}</div>
            <div class="text-xs text-grey">{{ type.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="{ hidden: activeStep !== 2 }">
  <microsec-form-builder
    #fb
    [fields]="fields"
    (submitEvent)="onSubmit($event)"
    [submitButtonLabel]="''"
    [shouldShowActionButtons]="true"
    [rightActionArea]="actionButtonsArea"
    [additionalConditions]="isAssessmentsTimeRangeValid" />

  <ng-template #actionButtonsArea>
    <ng-container *ngIf="!!form && !!form.form">
      <ng-container [formGroup]="form.form">
        <div class="flex align-items-center gap-2">
          <p-button severity="primary" *ngIf="!report" type="button" icon="fa fa-angle-left" (onClick)="onUpdateReportType()" label="Back" />
          <p-button severity="success" type="submit" [disabled]="!form.valid || !isAssessmentsTimeRangeValid" [label]="CREATE_LABEL" />
        </div>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #reportFilterTimeField>
    <ng-container *ngIf="!!form && !!form.form" [formGroup]="form.form">
      <div class="grid">
        <div class="col-12">
          <app-report-form-time-range-input [(dateRange)]="dateRange"></app-report-form-time-range-input>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
