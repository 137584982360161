import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { ISetting2FAService } from './ISetting2FAService';

const API_2FA = `${API.ACCESS_POLICY}/ext-2fa`;
const API_RSA_SETTING = `${API_2FA}/setting`;
const API_GLOBAL_SETTING = `${API_2FA}/global-setting`;
const API_USER_2FA_SETTING = `${API_2FA}/user-2fa-setting`;

@Injectable({
  providedIn: 'root',
})
export class Setting2FAService implements ISetting2FAService {
  constructor(private http: HttpClient) {}

  getRSASetting(): Observable<any> {
    return this.http.get<any>(`${API_RSA_SETTING}/`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateRSASetting(payload: any): Observable<any> {
    return this.http.post<any>(`${API_RSA_SETTING}/`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  testRSAConnection(payload: any): Observable<any> {
    return this.http.post<any>(`${API_2FA}/rsa-test-connection/`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getOpenGlobalSetting(): Observable<any> {
    return this.http.get<any>(`${API_2FA}/open-global-setting/`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getGlobalSetting(): Observable<any> {
    return this.http.get<any>(`${API_GLOBAL_SETTING}/`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateGlobalSetting(payload: any): Observable<any> {
    return this.http.post<any>(`${API_GLOBAL_SETTING}/`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getOpenUserSetting(username: any, password: any): Observable<any> {
    return this.http
      .post<any>(`${API_2FA}/open-user-2fa-setting/`, { username, password })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getUserSetting(): Observable<any> {
    return this.http.get<any>(`${API_USER_2FA_SETTING}/`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateUserSetting(payload: any): Observable<any> {
    return this.http.post<any>(`${API_USER_2FA_SETTING}/`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
