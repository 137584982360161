/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { ILicenseService } from './ILicenseService';
import licensesData from './mock-data/licenses.json';
import machineIDData from './mock-data/machine-id.json';

@Injectable({
  providedIn: 'root',
})
export class MockLicenseService implements ILicenseService {
  constructor(private http: HttpClient) {}

  getAllLicenses(): Observable<any> {
    return scheduled([licensesData], asyncScheduler);
  }

  getLicense(id: any): Observable<any> {
    return scheduled([licensesData.data[0]], asyncScheduler);
  }

  uploadLicense(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteLicense(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteAllLicenses(): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getMachineID(): Observable<any> {
    return scheduled([machineIDData], asyncScheduler);
  }
}
