<div class="grid form">
  <div class="col-12">
    <microsec-card [isNestedCard]="true">
      <div class="mb-3">
        <label class="font-bold">Analyzer Details</label>
      </div>
      <div class="list-fields mx-3">
        <div class="grid align-items-center">
          <label class="col-5">Enabled</label>
          <div class="col-7">
            <p-inputSwitch [(ngModel)]="analyzer.enabled" [disabled]="true"></p-inputSwitch>
          </div>
        </div>
        @if (analyzer?.analyzer_type === ANALYZER_TYPES.RULE_BASED && analyzer?.data_type === ANALYZER_DATA_TYPES.PACKET) {
          <div class="grid align-items-center">
            <label class="col-5">Last Downloaded</label>
            <div class="col-7">
              <div class="flex align-items-center gap-3">
                <label class="text-data">{{ analyzer.ruleDownloadLastTime || '-' }}</label>
                @if (!!analyzer?.is_sync_scheduled) {
                  <label class="text-grey">Downloading</label>
                } @else {
                  <label class="link-blue" (click)="downloadRule()"> Download Now </label>
                }
              </div>
            </div>
          </div>
        }
        @if (!!analyzer?.name) {
          <div class="grid align-items-center">
            <label class="col-5">Name</label>
            <label class="col-7 text-data overflow-hidden white-space-nowrap text-overflow-ellipsis">{{ analyzer?.name }}</label>
          </div>
        }
        @if (!!analyzer?.description) {
          <div class="grid align-items-center">
            <label class="col-5">Description</label>
            <label class="col-7 text-data">{{ analyzer.description }}</label>
          </div>
        }
        @if (!!analyzer?.type) {
          <div class="grid align-items-center">
            <label class="col-5">Type</label>
            <label class="col-7 text-data">{{ analyzer.type || '-' }}</label>
          </div>
        }
        @if (!!analyzer?.connection_ids?.length) {
          <div class="grid align-items-center">
            <label class="col-5">Connection ID</label>
            <label class="col-7 text-data">{{ analyzer.connection_ids.join(', ') }}</label>
          </div>
        }
        @if (!!connection?.name) {
          <div class="grid align-items-center">
            <label class="col-5">Connection Name</label>
            <label class="col-7 text-data">{{ connection?.name }}</label>
          </div>
        }
        @if (analyzer?.analyzer_type === ANALYZER_TYPES.RULE_BASED && analyzer?.data_type === ANALYZER_DATA_TYPES.PACKET) {
          @if (!!analyzer?.rule_download_frequency) {
            <div class="grid align-items-center">
              <label class="col-5">Rule Download Frequency</label>
              <label class="col-7 text-data">{{ analyzer.rule_download_frequency | titlecase }}</label>
            </div>
          }
          @if (!!analyzer?.rule_types?.length) {
            <div class="grid align-items-center">
              <label class="col-5">Rule Types</label>
              <label class="col-7 text-data">{{ analyzer.rule_types | analyzerRuleTypesPipe }}</label>
            </div>
          }
        }
        @if (analyzer.analyzer_type === ANALYZER_TYPES.RULE_BASED && analyzer.data_type === ANALYZER_DATA_TYPES.PAYLOAD) {
          @if (!!analyzer?.data_structure_id) {
            <div class="grid align-items-center">
              <label class="col-5">Data Structure ID</label>
              <label class="col-7 text-data">{{ analyzer.data_structure_id }}</label>
            </div>
          }
          @if (!!analyzer?.timer) {
            <div class="grid py-1">
              <label class="col-12">Interruption Monitoring</label>
              <div class="col-12">
                <microsec-card [isTableCard]="true">
                  <microsec-common-table
                    #dt
                    [cols]="timerCols"
                    [values]="timer || []"
                    [loading]="isLoading"
                    [showContainerHeader]="false"
                    [paginator]="false"
                    [noSortingCols]="['timeout', 'grace']"
                    [cellTemplate]="cellTemplate"></microsec-common-table>
                </microsec-card>
              </div>
            </div>
          }
          @if (!!analyzer?.rules?.length) {
            <div class="grid py-1">
              <label class="col-12">Conditional Rules</label>
              <div class="col-12">
                <microsec-card [isTableCard]="true">
                  <microsec-common-table
                    #dt
                    [cols]="rulesCols"
                    [values]="analyzer.rules"
                    [loading]="isLoading"
                    itemName="Rule"
                    [noSortingCols]="['formula']"></microsec-common-table>
                </microsec-card>
              </div>
            </div>
          }
        }
        @if (analyzer.analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP) {
          @if (!!analyzer.rule_types[0]?.enabled && nonipBuiltinRules.length > 0) {
            <div class="grid align-items-center">
              <label class="col-5">Enabled Built-In Rules</label>
              <label class="col-7 text-data">{{ nonipBuiltinRules }}</label>
            </div>
          }
          @if (customRuleValues.length > 0) {
            <div class="grid py-1">
              <label class="col-12">Custom Rules</label>
              <div class="col-12">
                <microsec-card [isTableCard]="true">
                  <microsec-common-table
                    #dt
                    [cols]="nonIpCustomRules"
                    [values]="customRuleValues || []"
                    [loading]="isLoading"
                    [showContainerHeader]="false"
                    [paginator]="true"
                    [noSortingCols]="['enabled', 'name', 'rule']"
                    [cellTemplate]="nonIPCellTemplate"></microsec-common-table>
                </microsec-card>
              </div>
            </div>
          }
        }
      </div>
    </microsec-card>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate> {{ rowData[col.field] }} seconds </ng-template>
<ng-template let-col="col" let-rowData="rowData" #nonIPCellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Enabled -->
    <ng-container *ngSwitchCase="'enabled'">
      <p-inputSwitch
        (click)="$event.stopPropagation()"
        [disabled]="true"
        [(ngModel)]="rowData[col.field]"
        (onChange)="updateNonipAnalyzerRuleStatus(rowData)"></p-inputSwitch>
    </ng-container>
    <!-- Default -->
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
