import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@ids-shared';
import { AccessTokenDetailComponent } from './developer/access-tokens/access-token-detail/access-token-detail.component';
import { AccessTokenFormComponent } from './developer/access-tokens/access-token-form/access-token-form.component';
import { AccessTokensComponent } from './developer/access-tokens/access-tokens.component';
import { Config2faComponent } from './preferences/config2fa/config2fa.component';
import { AuthenticatorVerificationComponent } from './preferences/config2fa/setup2fa/authenticator-verification/authenticator-verification.component';
import { QrCodeScanComponent } from './preferences/config2fa/setup2fa/qr-code-scan/qr-code-scan.component';
import { RecommendationComponent } from './preferences/config2fa/setup2fa/recommendation/recommendation.component';
import { Setup2FAComponent } from './preferences/config2fa/setup2fa/setup2fa.component';
import { VerificationCodesComponent } from './preferences/config2fa/setup2fa/verification-codes/verification-codes.component';
import { OrganizationsComponent } from './preferences/organizations/organizations.component';
import { PasswordComponent } from './preferences/password/password.component';
import { UserSettingsComponent } from './user-settings.component';
import { SharedLicenseWarningModule } from '../shared-components/shared-license-warning/shared-license-warning.module';

@NgModule({
  declarations: [
    UserSettingsComponent,
    OrganizationsComponent,
    Config2faComponent,
    Setup2FAComponent,
    RecommendationComponent,
    QrCodeScanComponent,
    AuthenticatorVerificationComponent,
    VerificationCodesComponent,
    AccessTokensComponent,
    AccessTokenFormComponent,
    AccessTokenDetailComponent,
    PasswordComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SharedModule, SharedLicenseWarningModule],
})
export class UserSettingsModule {}
