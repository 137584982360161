<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="form" *ngIf="!!question?.compliance">
    <div class="grid">
      <div class="col-12">
        <microsec-card [isNestedCard]="true">
          <div class="grid">
            <label class="col-12 font-bold"> Question Compliance Details </label>
            <div class="col-12 mt-2">
              <div class="mx-3">
                <div class="field grid">
                  <label class="col-4">Enter Compliance Details</label>
                  <div class="col-8">
                    <p-checkbox [binary]="true" [ngModel]="compliance[FORM_PARAMS.IS_COMPLIANCE]" [disabled]="true"> </p-checkbox>
                  </div>
                </div>
                <div class="field grid">
                  <label class="col-4">Compliance Standard</label>
                  <div class="col-8">
                    <p-dropdown
                      [options]="COMPLIANCE_STANDARD_OPTIONS"
                      [disabled]="true"
                      [ngModel]="compliance[FORM_PARAMS.COMPLIANCE_STANDARD]"
                      optionLabel="label"
                      optionValue="value"></p-dropdown>
                  </div>
                </div>
                <div class="field grid">
                  <label class="col-4">Foundation Requirement</label>
                  <div class="col-8">
                    <p-dropdown
                      [options]="FOUNDATIONAL_REQUIREMENT_OPTIONS"
                      [disabled]="true"
                      [ngModel]="compliance[FORM_PARAMS.FOUNDATIONAL_REQUIREMENT]"
                      optionLabel="label"
                      optionValue="value"></p-dropdown>
                  </div>
                </div>
                <div class="field grid">
                  <label class="col-4">Capability Security Level (SL-C)</label>
                  <div class="col-8">
                    <p-multiSelect
                      [options]="SECURITY_LEVEL_OPTIONS"
                      [disabled]="true"
                      [ngModel]="compliance[FORM_PARAMS.SECURITY_LEVEL_CAPABLE]"
                      optionLabel="label"
                      optionValue="value"></p-multiSelect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </microsec-card>
      </div>
    </div>
  </div>
</microsec-loading-overlay>
