import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MODULE_CONSTANTS, ORGANIZATION_ID_PARAM_ROUTE, PROJECT_ID_PARAM_ROUTE, PROJECT_ONBOARDING_UNFINISHED, USER_ROLE } from '@microsec/constants';
import { fromLayoutActions, layoutSelectors } from '@microsec/ngrx-layout';
import { BaseComponent } from '@ids-components';
import { ThreatService } from '@ids-services';
import { ANALYZER_FEATURES, PROJECT_MANAGEMENT_CONSTANTS, THREAT_STATUSES } from '@ids-constants';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
})
export class ProjectManagementComponent extends BaseComponent implements OnInit, OnDestroy {
  checkInterval: any;

  hiddenItemRoutes: any[] = [PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP_EDITOR.ROUTE];

  excludeLayoutItemRoutes: any[] = [
    PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP_EDITOR.ROUTE,
    `${PROJECT_MANAGEMENT_CONSTANTS.NETWORK_SETTINGS.ROUTE}/networks`,
    `${PROJECT_MANAGEMENT_CONSTANTS.ASSESSMENT_TOOL.ROUTE}/assessments`,
  ];

  currentRoute: any = '';

  PROJECT_MANAGEMENT_CONSTANTS = PROJECT_MANAGEMENT_CONSTANTS;

  constructor(
    private injector: Injector,
    private threatSrv: ThreatService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const isOnboardingUnfinished = JSON.parse(
      localStorage.getItem(`${PROJECT_ONBOARDING_UNFINISHED}_${this.breadcrumbConfig?.organizationId}_${this.breadcrumbConfig?.projectId}`) ||
        'false',
    );
    if (!isOnboardingUnfinished) {
      const projectManagementRoutePrefix = `${MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(
        ORGANIZATION_ID_PARAM_ROUTE,
        this.breadcrumbConfig?.organizationId?.toString(),
      ).replace(PROJECT_ID_PARAM_ROUTE, this.breadcrumbConfig?.projectId?.toString())}/`;
      this.store.select(layoutSelectors.selectUrl).subscribe((selectUrl) => {
        this.currentRoute = selectUrl.split(projectManagementRoutePrefix)[1];
      });
      this.store.dispatch(new fromLayoutActions.SetLeftNavbar(true));
      const PROJECT_MANAGEMENT_CHILDREN = this.injector.get('PROJECT_MANAGEMENT_CHILDREN');
      this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE));
      const checkedItems: any[] = this.util.cloneObjectArray(PROJECT_MANAGEMENT_CHILDREN).map((item) => ({
        ...item,
        visible: this.checkItemVisibility(item.route),
      }));
      this.store.dispatch(new fromLayoutActions.UpdateReplacedItems(checkedItems));

      // Check threats
      this.getActiveThreats();
      this.checkInterval = setInterval(() => {
        this.getActiveThreats();
      }, 60000);
    } else {
      this.changeRoute(
        MODULE_CONSTANTS.PROJECT_GETTING_STARTED.ROUTE.replace(
          ORGANIZATION_ID_PARAM_ROUTE,
          this.breadcrumbConfig?.organizationId?.toString(),
        ).replace(PROJECT_ID_PARAM_ROUTE, this.breadcrumbConfig?.projectId?.toString()),
      );
    }
  }

  getActiveThreats() {
    if (this.breadcrumbConfig?.projectId) {
      this.threatSrv
        .getThreats(this.breadcrumbConfig?.projectId, undefined, undefined, undefined, undefined, undefined, false, [
          THREAT_STATUSES.OPEN,
          THREAT_STATUSES.FIXING,
        ])
        .pipe()
        .subscribe({
          next: (res) => {
            this.store.dispatch(new fromLayoutActions.AddLeftNavbarBadge(PROJECT_MANAGEMENT_CONSTANTS.THREATS.ROUTE, res?.total_record));
          },
          error: () => {
            /** Do nothing */
          },
        });
    }
  }

  checkItemVisibility(route: string) {
    if (route === PROJECT_MANAGEMENT_CONSTANTS.ANOMALY_ANALYZER.ROUTE) {
      return (
        !!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ENABLED) &&
        (!!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ANOMALY_ANALYZER_ML) ||
          !!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ANOMALY_ANALYZER_RULE_BASED) ||
          !!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.VULNERABILITY_ANALYZER))
      );
    } else {
      return this.checkPermissionsByScope(USER_ROLE.READ_ONLY) && !this.hiddenItemRoutes.includes(route);
    }
  }

  override ngOnDestroy() {
    clearInterval(this.checkInterval);
    this.store.dispatch(new fromLayoutActions.AddLeftNavbarBadge(PROJECT_MANAGEMENT_CONSTANTS.THREATS.ROUTE, ''));
  }
}
