<div class="detected-devices">
  <div class="grid">
    <div class="col-12">
      <microsec-common-toolbar
        #ct
        [configuration]="filterConfiguration"
        [filterObject$]="$any(filterObject$)"
        [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null"
        [rightTemplate]="rightTemplate"></microsec-common-toolbar>
      <ng-template #leftTemplate>
        <span [pTooltip]="!!checkPhysicalDeviceQuota ? '' : 'Maximum number of physical devices reached'" tooltipPosition="bottom" appendTo="body">
          <p-button
            severity="primary"
            label="Add Device"
            size="small"
            [rounded]="true"
            icon="pi pi-plus"
            (onClick)="openDeviceForm()"
            [disabled]="!!isLoading || !checkPhysicalDeviceQuota" />
        </span>
      </ng-template>
      <ng-template #rightTemplate>
        <div class="flex align-items-center gap-2">
          <span #activeScanMessageTarget>
            @if (!!runningIdsActiveScan || !!runningEdgeActiveScan) {
              <p-button
                icon="fas fa-circle-notch fa-spin"
                label="Scanning"
                severity="success"
                [rounded]="true"
                styleClass="w-9rem text-transform-none custom-button"
                (onClick)="activeScanMessagePanel.show($event)">
              </p-button>
            } @else {
              <p-button
                type="button"
                icon="fa fa-tower-broadcast"
                label="Active Scan"
                severity="secondary"
                [rounded]="true"
                styleClass="text-transform-none"
                (onClick)="openActiveScanForm($event)">
              </p-button>
            }
          </span>
          <p-button
            type="button"
            icon="fa fa-code-branch"
            label="Merge Devices"
            severity="secondary"
            [rounded]="true"
            styleClass="text-transform-none"
            (onClick)="openMergeDevicesForm()">
          </p-button>
        </div>
      </ng-template>
    </div>

    <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedDevice }" id="tb" (click)="hideDetailsPanel($event)">
      <microsec-card [isTableCard]="true">
        <microsec-common-table
          #dt
          [lazy]="true"
          (lazyLoadEvent)="getDevices(false, $any($event))"
          [totalRecords]="totalRecords"
          [cols]="selectedCols"
          [values]="values"
          selectionMode="multiple"
          [(selection)]="selectedDevices"
          [(rowSingleSelection)]="selectedDevice"
          [loading]="isLoading"
          [cellTemplate]="cellTemplate"
          [actionsMenuItems]="actionsMenuItems"
          [dataKey]="'id'"
          itemName="Device"
          [containerHeaderRightTemplate]="containerHeaderRightTemplate">
        </microsec-common-table>
      </microsec-card>
    </div>
    <div class="col-12 lg:col-6" *ngIf="!!selectedDevice">
      <div class="container-border">
        <app-detected-device-details
          [deviceId]="selectedDevice.id"
          (deviceChangeEvent)="onDeviceChangeEvent($event)"
          (hidePanelEvent)="hideDetailsPanel()"></app-detected-device-details>
      </div>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedDevices)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedDevices.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getDevices(false)"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="COLS"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()"
      [settingTemplate]="deviceSettingTemplate">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Status -->
    <ng-container *ngSwitchCase="'status'">
      <span
        class="capitalize"
        [ngClass]="{
          'text-green': rowData.status === 'normal',
          'text-red': rowData.status !== 'normal'
        }"
        >{{ rowData['status'] }}</span
      >
    </ng-container>
    <!-- Interfaces -->
    <ng-container *ngSwitchCase="'interfaces'">
      {{ rowData | detectedDeviceInterfacesPipe }}
    </ng-container>
    <!-- IP Address -->
    <ng-container *ngSwitchCase="'src_ip_addr'">
      <span [pTooltip]="!!rowData[col.field] ? rowData[col.field].tooltip : ''" tooltipPosition="top" appendTo="body">
        {{ !!rowData[col.field] ? rowData[col.field].label : '-' }}
      </span>
    </ng-container>
    <!-- Tags -->
    <ng-container *ngSwitchCase="'tags'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let tag of rowData[col.field] || []; let i = index; let firstTag = first; let lastTag = last">
          <div *ngIf="!!firstTag" class="custom-chip" [style]="{ background: util.hexToRgba(tag.color), color: tag.color }">
            <!-- (click)="onClickSelectedTag($event, tag)"> -->
            {{ tag.label }}
          </div>
          <div *ngIf="!firstTag && !!lastTag" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Zones -->
    <ng-container *ngSwitchCase="'zones'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let zone of rowData[col.field] || []; let i = index; let firstZone = first; let lastZone = last">
          <div *ngIf="!!firstZone" class="custom-chip" [style]="{ background: util.hexToRgba(zone.color), color: zone.color }">
            <!-- (click)="onClickSelectedTag($event, tag)"> -->
            {{ zone.label }}
          </div>
          <div *ngIf="!firstZone && !!lastZone" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Threat Score -->
    <ng-container *ngSwitchCase="'threat_score'">
      <span [ngClass]="{ 'text-red': !!rowData[col.field] }">
        {{ (rowData[col.field] | number: '1.0-0') || '-' }}
      </span>
    </ng-container>
    <!-- date -->
    <ng-container *ngSwitchCase="col.field === 'created' || col.field === 'last_seen' ? col.field : ''">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <!-- boolean -->
    <ng-container *ngSwitchCase="col.field === 'is_imported' || col.field === 'uses_agent' ? col.field : ''">
      <span class="capitalize">{{ rowData[col.field] }}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #deviceSettingTemplate>
  <div class="px-3 mb-3 mt-3">
    <label class="font-bold">Device Visibility</label>
  </div>
  <div class="px-3 overflow-y-auto" style="max-height: 20rem">
    <div [class]="i < deviceVisibilityOptions.length - 1 ? 'mb-3' : ''" *ngFor="let device of deviceVisibilityOptions; let i = index">
      <p-checkbox [label]="device.header" [value]="device.value" [binary]="true" [(ngModel)]="device.value" (onChange)="onMulticastChange($event)">
      </p-checkbox>
    </div>
  </div>
  <p-divider></p-divider>
</ng-template>

<p-overlayPanel styleClass="custom-message-panel" #activeScanMessagePanel [dismissable]="false" (onHide)="isPostActiveScan = false">
  <ng-template pTemplate="content">
    <div class="flex flex-column gap-3">
      <label class="font-bold text-sm">Active Scan Ongoing</label>
      <label class="text-sm">
        Active Scan is currently in progress. Scan outcome can be viewed under Project Settings > Events upon completion.
      </label>
      <div class="flex align-items-center justify-content-between">
        <div>
          @if (!!isPostActiveScan) {
            <label class="text-grey text-sm underline cursor-pointer" (click)="alwaysHideActiveScanMessage()">Don't show again</label>
          }
        </div>
        <p-button type="button" size="small" label="Got It" severity="secondary" (onClick)="activeScanMessagePanel.hide()"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
