<div class="grid">
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <span [pTooltip]="!!checkNetworkDeviceQuota ? '' : 'Maximum number of network devices reached'" tooltipPosition="bottom" appendTo="body">
        <p-button
          [label]="'Connect'"
          [rounded]="true"
          icon="pi pi-plus"
          [disabled]="!!isLoading || !checkNetworkDeviceQuota"
          (onClick)="openDeviceForm()" />
      </span>
    </ng-template>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedDevice }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [lazy]="true"
        (lazyLoadEvent)="getDevices(filterSearch, false, $any($event))"
        [totalRecords]="totalRecords"
        [cols]="selectedCols || []"
        [values]="values"
        selectionMode="multiple"
        [(selection)]="selectedDevices"
        [(rowSingleSelection)]="selectedDevice"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [noSortingCols]="noSortCols"
        [dataKey]="'id'"
        itemName="Device"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate"></microsec-common-table>
    </microsec-card>
  </div>
  <div class="col-12 lg:col-6" *ngIf="!!selectedDevice">
    <div class="container-border">
      <microsec-selected-panel-header
        [id]="selectedDevice.id"
        [label]="selectedDevice.label"
        (closeSelectedPanelEvent)="hideDetailsPanel()"
        [rightTemplate]="rightTemplate"></microsec-selected-panel-header>
      <ng-template #rightTemplate>
        <p-button
          icon="fa fa-edit"
          severity="info"
          styleClass="p-button-sm mr-2"
          pTooltip="Edit"
          tooltipPosition="bottom"
          appendTo="body"
          (onClick)="openDeviceForm(selectedDevice)"
          *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
        </p-button>
      </ng-template>
      <app-network-device-details [deviceId]="selectedDevice.id"></app-network-device-details>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedDevices)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedDevices.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getDevices(filterSearch)"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Type -->
    <ng-container *ngSwitchCase="'device_type'">
      <label>
        {{ (rowData[col.field] | titlecase) || '-' }}
      </label>
    </ng-container>
    <!-- Model -->
    <ng-container *ngSwitchCase="'model'">
      <label>
        {{ (rowData[col.field] | constant: 'network-device-model') || '-' }}
      </label>
    </ng-container>
    <!-- IP Address -->
    <ng-container *ngSwitchCase="'configIPAddress'">
      <label>
        {{ rowData.config?.ip_address || '-' }}
      </label>
    </ng-container>
    <!-- Port -->
    <ng-container *ngSwitchCase="'configPort'">
      <label>
        {{ rowData.config?.port || '-' }}
      </label>
    </ng-container>
    <!-- IP Address -->
    <ng-container *ngSwitchCase="'configStatus'">
      <label
        *ngIf="!!rowData.config?.connection_status"
        class="capitalize"
        [ngClass]="{
          'text-green': rowData.config.connection_status === 'connected',
          'text-red': rowData.config.connection_status !== 'connected'
        }">
        {{ rowData.config.connection_status }}
      </label>
      <label *ngIf="!rowData.config?.connection_status">-</label>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #elseTemplate> - </ng-template>

<app-shared-test-configuration-dialog #testConfigurationDialog> </app-shared-test-configuration-dialog>
