<div class="form">
  <div class="px-3 scroll-panel">
    <div class="grid">
      <div class="col-12">
        <app-rule-based-configuration-field [analyzer]="analyzer"></app-rule-based-configuration-field>
      </div>
    </div>
  </div>
  <div class="action-buttons flex justify-content-end">
    <p-button severity="success" (onClick)="dialogRef.close()" label="Complete"> </p-button>
  </div>
</div>
