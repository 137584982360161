<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!assessmentType) {
    <microsec-card [isNestedCard]="true">
      <div class="grid">
        <label class="col-12 font-bold"> Assessment Type Details </label>
        <div class="col-12">
          <div class="list-fields mx-3">
            <div class="grid align-items-center">
              <label class="col-4">Name</label>
              <label class="col-8">{{ assessmentType.name }}</label>
            </div>
            <div class="grid align-items-center" *ngIf="!!assessmentType.descriptionPreview">
              <label class="col-4">Description</label>
              <div class="col-8 markdown-preview max-h-30rem overflow-auto" [innerHTML]="assessmentType.descriptionPreview"></div>
            </div>
            <div class="grid align-items-center">
              <label class="col-4">Number of Sections</label>
              <label class="col-8">{{ assessmentType.sections?.length || 0 }}</label>
            </div>
            <div class="grid align-items-center">
              <label class="col-4">Number of Questions</label>
              <label class="col-8">{{ assessmentType.numberOfQuestions || 0 }}</label>
            </div>
            <div class="grid align-items-center">
              <label class="col-4">Created Date</label>
              <label class="col-8">{{ (assessmentType.created | momentPipe) || '-' }}</label>
            </div>
            <div class="grid align-items-center">
              <label class="col-4">Updated Date</label>
              <label class="col-8">{{ (assessmentType.updated | momentPipe) || '-' }}</label>
            </div>
          </div>
        </div>
      </div>
    </microsec-card>
  }
</microsec-loading-overlay>
