import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@microsec/components';

@Component({
  selector: 'app-zone-details',
  templateUrl: './zone-details.component.html',
  styleUrls: ['./zone-details.component.scss'],
})
export class ZoneDetailsComponent extends BaseComponent implements AfterViewInit {
  @Input() isLoading = false;

  _zone: any = null;

  get zone() {
    return this._zone;
  }

  @Input() set zone(value: any) {
    this._zone = value;
  }

  @Output() zoneChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() refreshListEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() openFormEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() hideDetailsEvent: EventEmitter<any> = new EventEmitter<any>();

  async ngAfterViewInit() {
    await this.prepareConfigs();
  }
}
