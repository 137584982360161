import { Component, Input } from '@angular/core';
import { COMPLIANCE_STANDARDS, COMPLIANCE_STANDARD_OPTIONS, FOUNDATIONAL_REQUIREMENT_OPTIONS, SECURITY_LEVEL_OPTIONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';

import { FORM_PARAMS } from '../assessment-question-form/assessment-question-form.component';

@Component({
  selector: 'app-assessment-question-compliance',
  templateUrl: './assessment-question-compliance.component.html',
  styleUrls: ['./assessment-question-compliance.component.scss'],
})
export class AssessmentQuestionComplianceComponent extends BaseComponent {
  isLoading = false;

  _question: any = null;

  get question() {
    return this._question;
  }

  @Input() set question(value: any) {
    this._question = value || null;
    this.compliance = {
      [FORM_PARAMS.IS_COMPLIANCE]: true,
      [FORM_PARAMS.COMPLIANCE_STANDARD]: COMPLIANCE_STANDARDS.IEC_62443,
      [FORM_PARAMS.FOUNDATIONAL_REQUIREMENT]:
        this.question[FORM_PARAMS.COMPLIANCE]?.[COMPLIANCE_STANDARDS.IEC_62443]?.[FORM_PARAMS.FOUNDATIONAL_REQUIREMENT] || null,
      [FORM_PARAMS.SECURITY_LEVEL_CAPABLE]:
        this.question[FORM_PARAMS.COMPLIANCE]?.[COMPLIANCE_STANDARDS.IEC_62443]?.[FORM_PARAMS.SECURITY_LEVEL_CAPABLE] || [],
    };
  }

  compliance: any = {
    [FORM_PARAMS.IS_COMPLIANCE]: true,
    [FORM_PARAMS.COMPLIANCE_STANDARD]: COMPLIANCE_STANDARDS.IEC_62443,
    [FORM_PARAMS.FOUNDATIONAL_REQUIREMENT]: null,
    [FORM_PARAMS.SECURITY_LEVEL_CAPABLE]: null,
  };

  FORM_PARAMS = FORM_PARAMS;

  COMPLIANCE_STANDARD_OPTIONS = COMPLIANCE_STANDARD_OPTIONS;

  FOUNDATIONAL_REQUIREMENT_OPTIONS = FOUNDATIONAL_REQUIREMENT_OPTIONS;

  SECURITY_LEVEL_OPTIONS = SECURITY_LEVEL_OPTIONS;

  constructor() {
    super();
  }
}
