<div class="grid">
  <label class="col-12 text-data text-center mt-5"> Open the authenticator app and scan the QR code </label>

  <label class="col-12 text-center my-2">
    <!-- QR Code -->
    @if (!!qrData) {
      <qrcode [qrdata]="qrData?.otp_link" [errorCorrectionLevel]="'M'" />
    }
  </label>

  <label class="col-12 text-data text-center"> Once registered, you will see 6-digits verification codes in the app </label>

  <div class="col-4 text-left">
    <p-button severity="secondary" (onClick)="changeStep(-1)" label="Back" />
  </div>
  <label class="col-4 text-data text-center" style="line-height: 2.5rem"> Step 1/3 </label>
  <div class="col-4 text-right">
    <p-button [autofocus]="true" severity="success" (onClick)="changeStep(1)" label="Next" />
  </div>
</div>
