<div class="flex w-full px-5 my-2">
  <p-tabMenu
    [model]="tabItems"
    [(activeItem)]="activeTabItem"
    class="w-full"
    styleClass="microsec-dashboard-tabmenu w-full"
    (activeItemChange)="changeTab($event)" />
</div>

<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="zone ? SAVE_CHANGES_LABEL : CREATE_LABEL"
  [additionalConditions]="!!form && !!form.getControlValue(FORM_PARAMS.IS_COMPLIANCE) ? !!complianceDetailsValid : true"
  (submitEvent)="submit($event)" />

<ng-template #colorField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <microsec-custom-color-picker [(color)]="color" [presetColors]="TAGS_PRESET_COLORS"></microsec-custom-color-picker>
    </ng-container>
  }
</ng-template>

<ng-template #complianceDetailsField>
  @if (!!securityLevel?.target?.length && !!securityLevel?.capable?.length) {
    <app-compliance-details-field [readOnly]="false" [(securityLevel)]="securityLevel" [(isValid)]="complianceDetailsValid" />
  }
</ng-template>
