import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';

import * as d3 from 'd3';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { NetworkMapCommon } from '../network-map-common';
import { NetworkMapHelper } from '@ids-utilities';

@Component({
  selector: 'app-force-directed',
  templateUrl: './force-directed.component.html',
  styleUrls: ['./force-directed.component.scss'],
})
export class ForceDirectedComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Input() itemDetails$ = new BehaviorSubject<any>(null);

  @Input() diagramDataObs = new Observable<any>();

  @Input() zoom$ = new BehaviorSubject<any>(null);

  @Input() refresh$ = new BehaviorSubject<any>(null);

  @Input() filterObject$ = new BehaviorSubject<any>(null);

  windowHeight = window.innerHeight;

  windowWidth = window.innerWidth;

  @ViewChild('svgContainer') private svgElement!: ElementRef;
  svgContainer: any = {};
  svg: any = {};
  rect: any = {};

  zoom: any = {};

  maxScaleLimit = 3.0;
  minScaleLimit = 0.1;
  currentScale = 1;
  currentZoomShiftX = 0;
  currentZoomShiftY = 0;
  zoomScaleDiff = 0.75;
  zoomDeviceLimit = 15;

  @Input() selectedDevice: any = null;

  @Output() selectedDeviceChange = new EventEmitter<any>();

  devices: any[] = [];

  threatDeviceIds: any[] = [];
  threatIntervals: any[] = [];

  // How fast nodes will settle down. Default is 0.0228 (slower)
  private readonly forceDecayRate = 0.1;

  // Track if simulation has stopped
  private readonly stopSimulation$ = new Subject<any>();
  private readonly stopSimulationObs = this.stopSimulation$.asObservable();

  ngAfterViewInit() {
    this.subscriptions.push(
      ...[
        // Update selected device/threat
        this.itemDetails$.asObservable().subscribe((itemDetails) => {
          // Selected Device is updated by Network-Map parent component
          switch (itemDetails?.type) {
            case 'device': {
              if (!this.selectedDevice) {
                this.clearDeviceSelections();
                this.zoomToTarget();
              } else {
                d3.selectAll('g.device-node').each((data: any) => {
                  const groupId = `${this.selectedDevice?.status}-node-group-${this.selectedDevice?.id}`;
                  if (data.groupId === groupId) {
                    data.isClicked = true;
                    this.toggleDeviceNode(data);
                    this.zoomToTarget({ target: data.circle.node() } as MouseEvent);
                  }
                });
              }
              this.threatDeviceIds = [];
              this.clearThreatIntervals();
              break;
            }
            case 'threat': {
              this.clearDeviceSelections();
              this.clearThreatIntervals();
              this.threatDeviceIds = ((this.selectedDevice?.device_ids as any[]) || [])
                .map((p) => p?.device_id)
                .filter((p) => !!this.devices.map((d) => d.id).includes(p));
              d3.selectAll('g.device-node').each((data: any) => {
                if (!!this.threatDeviceIds.includes(data?.id)) {
                  this.toggleDeviceNode(data, true);
                  this.setupThreatInterval(data);
                }
              });
              this.zoomToTarget(!!this.threatDeviceIds?.length ? ({ target: null } as MouseEvent) : undefined, this.threatDeviceIds);
              break;
            }
            default: {
              this.threatDeviceIds = [];
              this.clearDeviceSelections();
              this.clearThreatIntervals();
              if (!itemDetails?.isEmptySpaceClicked) {
                this.zoomToTarget();
              }
              break;
            }
          }
        }),
        // Update zoom
        this.zoom$.asObservable().subscribe((option) => {
          this.updateZoom(option);
        }),
        // Wait for simulation for itemDetails or generic zoom update from DrawDiagram()
        this.stopSimulationObs.subscribe(() => {
          if (!!this.selectedDevice) {
            //this.updateZoom(0);
            this.itemDetails$.next({ type: this.itemDetails$.value?.type || 'device', data: this.selectedDevice });
          } else {
            // Timeout waiting for all nodes to settle before zooming
            setTimeout(() => {
              this.updateZoom(0);
            }, 500);
          }
        }),
        // Update diagram's nodes and links
        this.diagramDataObs.subscribe((data) => {
          //Selected Device is updated by Network-Map parent component
          if (!!data && !!data?.shouldResetDiagram) {
            const links: any[] = data?.links;
            const devices = ((data?.devices as any[]) || []).map((device: any) => {
              device.children = links.filter((p) => p.src_device_id === device.id).map((p) => p.dest_device_id);
              device.relative = links.filter((p) => p.src_device_id === device.id || p.dest_device_id === device.id).length || 0;
              return device;
            });
            this.devices = devices;
            this.drawDiagram();
          }

          // Apply search if any (highlight as well)
          if (!!data) {
            const searchedDeviceIds = ((data?.searchedDevices as any[]) || []).map((p) => p?.id);
            this.svg?.selectAll('g.device-node')?.each((deviceNode: any) => {
              const isSearchDevice = !!searchedDeviceIds.find((p) => p === deviceNode.id);
              deviceNode?.searchedCircle?.attr('stroke', isSearchDevice ? NetworkMapCommon.searchedStrokeColor : 'transparent');
              deviceNode?.circle?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
              deviceNode?.selectedCircle?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
              deviceNode?.icon?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
            });
            if (!!searchedDeviceIds.length) {
              // Zoom
              this.zoomToTarget({ target: null } as MouseEvent, searchedDeviceIds);
              // Search related links
              const links = this.svg.selectAll('line.link');
              links.each((data: any) => {
                const deviceIdsFromLink = (data.linkId as string)
                  .replace('line-s-', '')
                  .replace('-line', '')
                  .split('-e-')
                  .map((p) => +p);
                data?.link?.attr('opacity', searchedDeviceIds.filter((p) => !!deviceIdsFromLink.includes(p)).length === 2 ? 0.9 : 0.5);
              });
            }
          }
        }),
      ],
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.clearTooltip();
  }

  /**
   * ========================================================================================================================================================
   * DRAW DIAGRAM
   * ========================================================================================================================================================
   */

  /**
   * Draw diagram
   */
  drawDiagram() {
    d3.select(this.svgElement.nativeElement).selectAll('*').remove();
    this.setupZoomOptions();
    this.svgContainer = d3
      .select(this.svgElement.nativeElement)
      .attr('width', this.maxWidth)
      .attr('height', this.maxHeight)
      .append('g')
      .attr('class', 'svg-container')
      .attr('transform', `translate(${-this.viewX},${-this.viewY})`)
      .call(this.zoom);

    this.svg = this.svgContainer
      .append('g')
      .attr('class', 'svg')
      .attr('width', this.maxWidth)
      .attr('height', this.maxHeight)
      .attr('transform', 'translate(0, 0)');

    this.rect = this.svg.append('rect').attr('x', 0).attr('width', this.maxWidth).attr('height', this.maxHeight).attr('fill', 'transparent');

    this.rect.on('click', (event: MouseEvent) => {
      if (event.target === event.currentTarget) {
        if (!!this.selectedDevice) {
          this.showDeviceDetails(null, true);
        }
        if (!!this.threatDeviceIds.length) {
          this.threatDeviceIds = [];
          this.showDeviceDetails(null, true);
        }
        this.clearDeviceSelections();
        this.clearThreatIntervals();
        // Click on background (-): Remove Selected Device from Network-map parent component
        this.selectedDevice = null;
        this.selectedDeviceChange.emit(null);
      }
    });

    const bodyStrength = (this.devices.length < 20 ? 200 : 100) * this.devices.length;
    const simulation = d3
      .forceSimulation()
      .force(
        'link',
        d3.forceLink().id((d: any) => d.id),
      )
      .force('charge', d3.forceManyBody().strength(-bodyStrength))
      .force('center', d3.forceCenter(this.windowWidth / 3, this.windowHeight / 3))
      .force('collision', d3.forceCollide().radius(20))
      .force('x', d3.forceX(this.windowWidth / 3))
      .force('y', d3.forceY(this.windowHeight / 3))
      .alphaDecay(this.forceDecayRate);

    // Define styles
    NetworkMapCommon.defineStyles(this.svg);

    // Get links and its style
    let links: any[] = [];

    this.devices.forEach((d: any) => {
      d.children.forEach((childId: number) => {
        if (
          !links.length ||
          (!!links.length &&
            !links.find((p) => p.source === d.id && p.target === childId) &&
            !links.find((p) => p.source === childId && p.target === d.id))
        ) {
          links.push({ source: d.id, target: childId });
        }
      });
    });
    links = links
      .filter((p) => p.source !== p.target)
      .filter((p) => !!this.devices.find((d) => d.id === p.source) && !!this.devices.find((d) => d.id === p.target));
    links = this.util.sortObjectArray(links, 'target');
    links = this.util.sortObjectArray(links, 'source');

    const linksSelection = this.getLinksSelection(links);

    // Get nodes and its style
    const nodesGroup = this.svg
      .append('g')
      .attr('class', 'nodes-group')
      .attr('transform', 'translate(' + this.viewX + ',' + this.viewY + ')');

    const nodesSelection = nodesGroup.selectAll('g.device-node').data(this.devices).enter().append('g').attr('class', 'device-node');
    this.getNormalNodeSelection(nodesSelection.filter((p: any) => p.status === 'normal'));
    this.getAnomalousNodeSelection(nodesSelection.filter((p: any) => p.status === 'anomalous'));

    simulation.nodes(this.devices as any[]).on('tick', () => {
      nodesSelection.attr('transform', (d: any) => `translate(${d.x}, ${d.y})`);
      nodesSelection.attr('cx', (d: any) => d.x).attr('cy', (d: any) => d.y);

      linksSelection
        .attr('x1', (d: any) => d.source.x)
        .attr('y1', (d: any) => d.source.y)
        .attr('x2', (d: any) => d.target.x)
        .attr('y2', (d: any) => d.target.y);
    });

    if (!!this.selectedDevice) {
      simulation.on('end', () => {
        //simulation has stopped
        this.stopSimulation$.next(null);
      });
    } else {
      this.stopSimulation$.next(null);
    }

    simulation.force<d3.ForceLink<any, any>>('link')?.links(links);
  }

  /**
   * Draw device node
   * @param nodesSelection
   */
  getNormalNodeSelection(nodesSelection: any) {
    const growRadius = (data: any) => NetworkMapCommon.circleGrowRadius * (data?.relative || 0);
    // Draw selected circle
    nodesSelection
      .append('circle')
      .attr('class', 'selected')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data) + 6)
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');

    // Draw circle
    nodesSelection
      .append('circle')
      .attr('class', 'main')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data))
      .attr('cursor', 'pointer')
      .attr('fill', NetworkMapCommon.circleFillColor)
      .attr('stroke', NetworkMapCommon.normalStrokeColor)
      .attr('stroke-width', '3px');

    // Draw search circle
    nodesSelection
      .append('circle')
      .attr('class', 'searched')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data))
      .attr('cursor', 'pointer')
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');

    // Add the icon
    nodesSelection
      .append('foreignObject')
      .attr('class', 'text-white')
      .attr('x', (data: any) => {
        return nodesSelection.attr('x') - (NetworkMapCommon.circleRadius + growRadius(data));
      })
      .attr('y', (data: any) => {
        return nodesSelection.attr('y') - (NetworkMapCommon.circleRadius + growRadius(data));
      })
      .attr('cursor', 'pointer')
      .attr('width', (data: any) => {
        return 2 * (NetworkMapCommon.circleRadius + growRadius(data));
      })
      .attr('height', (data: any) => {
        return 2 * (NetworkMapCommon.circleRadius + growRadius(data));
      });

    const nodeElements = nodesSelection.nodes();

    for (let i = 0; i < nodeElements.length; i++) {
      const nodeElement = nodeElements[i];
      const data: any = d3.select(nodeElement).datum();
      const groupId = `normal-node-group-${data?.id}`;
      data.groupId = groupId;
      data.group = d3.select(nodeElement);
      data.isClicked = false;
      const selectedCircleObject = d3.select(nodeElement).select('circle.selected');
      data.selectedCircle = selectedCircleObject;
      const searchedCircleObject = d3.select(nodeElement).select('circle.searched');
      data.searchedCircle = searchedCircleObject;
      const circleObject = d3.select(nodeElement).select('circle.main');
      data.circle = circleObject;
      const iconObject = d3.select(nodeElement).select('foreignObject');
      const iconSize = NetworkMapCommon.circleRadius + growRadius(data);
      const iconPadding = (NetworkMapCommon.circleRadius + growRadius(data)) / 2;
      const iconObj: any = NetworkMapHelper.getNetworkMapIcon(data?.type, iconSize, iconPadding);
      this.setNetworkMapIcon(iconObject, iconObj, iconSize, iconPadding, growRadius(data));
      data.icon = iconObject;
    }
    nodesSelection.each((data: any, i: any, nodesArray: any) => {
      const groupId = data.groupId;
      const circleObject = data.circle;
      const nodeItem = d3.select(nodesArray[i]);
      nodeItem.on('mouseover', (event) => {
        circleObject.attr('stroke', !data.isClicked ? NetworkMapCommon.normalHoverStrokeColor : NetworkMapCommon.normalClickedStrokeColor);
        circleObject.attr('fill', !data.isClicked ? NetworkMapCommon.circleHoverFillColor : NetworkMapCommon.circleNormalClickedFillColor);
        this.highlightRelatedLinks(data, true, data.isClicked);
        this.drawTooltip(data.group, event);
      });
      nodeItem.on('mouseout', () => {
        if (!data?.isClicked && !this.threatDeviceIds.includes(data?.id)) {
          circleObject.attr('stroke', !data.isClicked ? NetworkMapCommon.normalStrokeColor : NetworkMapCommon.normalClickedStrokeColor);
          circleObject.attr('fill', !data.isClicked ? NetworkMapCommon.circleFillColor : NetworkMapCommon.circleNormalClickedFillColor);
          this.highlightRelatedLinks(data, false, data.isClicked);
        }
        this.clearTooltip();
      });
      nodeItem.on('click', () => {
        data.isClicked = !data.isClicked;
        if (!!data.isClicked) {
          this.clearDeviceSelections(groupId);
          // Click on Node (+): Add Selected Device to Network-map parent component
          this.selectedDevice = data;
          this.selectedDeviceChange.emit(this.selectedDevice);
        } else if (!data.isClicked) {
          // Double Click on Node (-): Remove Selected Device from Network-map parent component
          this.selectedDevice = null;
          this.selectedDeviceChange.emit(null);
        }
        setTimeout(() => {
          this.showDeviceDetails(!!data.isClicked ? data : null);
          this.toggleDeviceNode(data);
        });
      });
    });
  }

  /**
   * Draw anomalous device node
   * @param nodesSelection
   */
  getAnomalousNodeSelection(nodesSelection: any) {
    const growRadius = (data: any) => NetworkMapCommon.circleGrowRadius * (data?.relative || 0);
    // Draw selected circle
    nodesSelection
      .append('circle')
      .attr('class', 'selected')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data) + 6)
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');

    // Draw 1
    nodesSelection
      .append('circle')
      .attr('class', 'layer-1-circle')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data) + (NetworkMapCommon.circleRadius / 3) * 2)
      .attr('fill', '#FB4D58')
      .attr('opacity', '0.1');

    // Draw 2
    nodesSelection
      .append('circle')
      .attr('class', 'layer-2-circle')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data) + NetworkMapCommon.circleRadius / 3)
      .attr('fill', '#FB4D58')
      .attr('opacity', '0.1');

    // Draw circle
    nodesSelection
      .append('circle')
      .attr('class', 'main')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data))
      .attr('cursor', 'pointer')
      .attr('fill', NetworkMapCommon.circleFillColor)
      .attr('stroke', NetworkMapCommon.anomalousStrokeColor)
      .attr('stroke-width', '3px');

    // Draw search circle
    nodesSelection
      .append('circle')
      .attr('class', 'searched')
      .attr('r', (data: any) => NetworkMapCommon.circleRadius + growRadius(data))
      .attr('cursor', 'pointer')
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');

    // Add the icon
    nodesSelection
      .append('foreignObject')
      .attr('class', 'network-map-anomalous-icon')
      .attr('x', (data: any) => {
        return nodesSelection.attr('x') - (NetworkMapCommon.circleRadius + growRadius(data));
      })
      .attr('y', (data: any) => {
        return nodesSelection.attr('y') - (NetworkMapCommon.circleRadius + growRadius(data));
      })
      .attr('cursor', 'pointer')
      .attr('width', (data: any) => {
        return 2 * (NetworkMapCommon.circleRadius + growRadius(data));
      })
      .attr('height', (data: any) => {
        return 2 * (NetworkMapCommon.circleRadius + growRadius(data));
      });

    const nodeElements = nodesSelection.nodes();

    for (let i = 0; i < nodeElements.length; i++) {
      const nodeElement = nodeElements[i];
      const data: any = d3.select(nodeElement).datum();
      const groupId = `anomalous-node-group-${data?.id}`;
      data.groupId = groupId;
      data.group = d3.select(nodeElement);
      data.isClicked = false;
      const selectedCircleObject = d3.select(nodeElement).select('circle.selected');
      data.selectedCircle = selectedCircleObject;
      const searchedCircleObject = d3.select(nodeElement).select('circle.searched');
      data.searchedCircle = searchedCircleObject;
      const circleObject = d3.select(nodeElement).select('circle.main');
      data.circle = circleObject;
      data.circle1 = d3.select(nodeElement).select('circle.layer-1-circle');
      data.circle2 = d3.select(nodeElement).select('circle.layer-2-circle');
      const iconObject = d3.select(nodeElement).select('foreignObject');
      const iconSize = NetworkMapCommon.circleRadius + growRadius(data);
      const iconPadding = (NetworkMapCommon.circleRadius + growRadius(data)) / 2;
      const iconObj = NetworkMapHelper.getNetworkMapIcon(data?.type, iconSize, iconPadding);
      this.setNetworkMapIcon(iconObject, iconObj, iconSize, iconPadding, growRadius(data));
      data.icon = iconObject;
    }
    nodesSelection.each((data: any, i: any, nodesArray: any) => {
      const groupId = data.groupId;
      const circleObject = data.circle;
      const nodeItem = d3.select(nodesArray[i]);
      nodeItem.on('mouseover', (event) => {
        circleObject.attr('stroke', !data.isClicked ? NetworkMapCommon.anomalousHoverStrokeColor : NetworkMapCommon.anomalousClickedStrokeColor);
        circleObject.attr('fill', !data.isClicked ? NetworkMapCommon.circleHoverFillColor : NetworkMapCommon.circleAnomalousClickedFillColor);
        this.highlightRelatedLinks(data, true, data.isClicked);
        this.drawTooltip(data.group, event);
      });
      nodeItem.on('mouseout', () => {
        if (!data?.isClicked && !this.threatDeviceIds.includes(data?.id)) {
          circleObject.attr('stroke', !data.isClicked ? NetworkMapCommon.anomalousStrokeColor : NetworkMapCommon.anomalousClickedStrokeColor);
          circleObject.attr('fill', !data.isClicked ? NetworkMapCommon.circleFillColor : NetworkMapCommon.circleAnomalousClickedFillColor);
          this.highlightRelatedLinks(data, false, data.isClicked);
        }
        this.clearTooltip();
      });
      nodeItem.on('click', () => {
        data.isClicked = !data.isClicked;
        if (!!data.isClicked) {
          this.clearDeviceSelections(groupId);
          // Click on Node (+): Add Selected Device to Network-map parent component
          this.selectedDevice = data;
          this.selectedDeviceChange.emit(this.selectedDevice);
        } else if (!data.isClicked) {
          // Double Click on Node (-): Remove Selected Device from Network-map parent component
          this.selectedDevice = null;
          this.selectedDeviceChange.emit(null);
        }
        this.showDeviceDetails(!!data.isClicked ? data : null);
        this.toggleDeviceNode(data);
      });
    });
  }

  /**
   * Draw a line
   */
  getLinksSelection(links: any[]) {
    const linksSelection = this.svg
      .append('g')
      .attr('class', 'links-group')
      .attr('transform', 'translate(' + this.viewX + ',' + this.viewY + ')')
      .selectAll('line')
      .data(links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .attr('opacity', '0.9')
      .attr('stroke', NetworkMapCommon.linkColor)
      .attr('stroke-width', 3)
      .attr('fill', 'none');

    const linkElements = linksSelection.nodes();

    for (let i = 0; i < linkElements.length; i++) {
      const linkElement = linkElements[i];
      const data: any = d3.select(linkElement).datum();
      const linkId = `line-s-${data?.source}-e-${data?.target}-line`;
      data.linkId = linkId;
      data.link = d3.select(linkElement);
    }
    return linksSelection;
  }

  /**
   * Clear all selection except the checking selection
   * @param exceptionNode
   */
  clearDeviceSelections(exceptionNode = '') {
    if (!this.svg?.selectAll) {
      return;
    }
    // Clear all highlighted links
    this.svg?.selectAll('line.link').each((linkItem: any) => {
      linkItem.link.attr('stroke', NetworkMapCommon.linkColor);
    });
    // Clear all nodes
    this.svg?.selectAll('g.device-node').each((data: any) => {
      const node = data.group;
      if (data.groupId !== exceptionNode) {
        const data: any = node.datum();
        data.isClicked = false;
        data.selectedCircle.attr('fill', 'transparent');
        data.selectedCircle.attr('stroke', 'transparent');
        switch (data.status) {
          case 'normal': {
            data.circle.attr('stroke', NetworkMapCommon.normalStrokeColor);
            data.circle.attr('fill', NetworkMapCommon.circleFillColor);
            data.icon.attr('class', 'text-white');
            break;
          }
          case 'anomalous': {
            data.circle.attr('stroke', NetworkMapCommon.anomalousStrokeColor);
            data.circle.attr('fill', NetworkMapCommon.circleFillColor);
            data.icon.attr('class', 'network-map-anomalous-icon');
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  /**
   * Draw tooltip
   * @param node
   * @param event
   */
  drawTooltip(node: any, event: any) {
    const data = node.datum();
    // create tooltip element
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'fixed')
      .style('z-index', 3)
      .style('pointer-events', 'none')
      .style('opacity', 0);

    // set tooltip content
    const status = data?.status || '';
    let statusColorClass = '';
    switch (status) {
      case 'normal': {
        statusColorClass = 'text-teal-500';
        break;
      }
      case 'anomalous': {
        statusColorClass = 'text-red-500';
        break;
      }

      default: {
        break;
      }
    }
    const hiddenMACClass = !!data?.mac_address ? '' : 'hidden';
    const hiddenIPClass = !!data?.ip_address ? '' : 'hidden';
    tooltip.html(`
    <div class="flex w-25rem relative">
      <div class="flex w-25rem absolute justify-content-center z-1" style="top: -1rem;">
        <div class="w-4rem h-4rem align-self-center border-round-xl rotate-45" style="background: #222222;"></div>
      </div>
      <div class="flex w-25rem absolute flex-column text-white master-font p-4 border-round-xl z-2" style="background: #222222;">
        <div class="grid field">
          <div class="col-12 font-bold">${data?.label}</div>
        </div>
        <div class="grid field">
          <div class="col-6">Status</div>
          <div class="col-6">
            <span class="${statusColorClass}">
              ${!!status ? status[0].toUpperCase() + status.slice(1) : ''}
            </span>
          </div>
        </div>
        <div class="grid field ${hiddenMACClass}">
          <div class="col-6">MAC Address</div>
          <div class="col-6">${data?.mac_address}</div>
        </div>
        <div class="grid field ${hiddenIPClass}">
          <div class="col-6">IP Address</div>
          <div class="col-6">${data?.ip_address}</div>
        </div>
      </div>
    </div>
    `);
    const tooltipWidth = tooltip.node()?.clientWidth || 0;

    // set tooltip position
    tooltip
      .style('left', event.pageX - tooltipWidth / 2 + 'px')
      .style('top', `calc(${event.pageY}px + 4rem)`)
      .style('opacity', 1);
  }

  /**
   * Clear tooltip
   */
  clearTooltip() {
    const tooltip = d3.select('.tooltip');
    tooltip.remove();
  }

  /**
   * ========================================================================================================================================================
   * TOGGLE / SHOW DETAILS
   * ========================================================================================================================================================
   */

  /**
   * Enable/Disable device's node
   * @param data
   * @param isThreatSelected
   */
  toggleDeviceNode(data: any, isThreatSelected = false) {
    data.selectedCircle.attr('fill', !!data.isClicked ? NetworkMapCommon.circleSelectedColor : 'transparent');
    switch (data.status) {
      case 'normal': {
        data.circle.attr('stroke', !!data.isClicked ? NetworkMapCommon.normalClickedStrokeColor : NetworkMapCommon.normalStrokeColor);
        data.circle.attr('fill', !!data.isClicked ? NetworkMapCommon.circleNormalClickedFillColor : NetworkMapCommon.circleFillColor);
        data.selectedCircle.attr('stroke', !!data.isClicked ? NetworkMapCommon.normalClickedStrokeColor : 'transparent');
        break;
      }
      case 'anomalous': {
        data.circle.attr('stroke', !!data.isClicked ? NetworkMapCommon.anomalousClickedStrokeColor : NetworkMapCommon.anomalousStrokeColor);
        data.circle.attr('fill', !!data.isClicked ? NetworkMapCommon.circleAnomalousClickedFillColor : NetworkMapCommon.circleFillColor);
        data.selectedCircle.attr('stroke', !!data.isClicked ? NetworkMapCommon.anomalousClickedStrokeColor : 'transparent');
        data.icon.attr('class', !!data.isClicked ? 'text-white' : 'network-map-anomalous-icon');
        break;
      }
      default: {
        break;
      }
    }
    if (!isThreatSelected) {
      this.highlightRelatedLinks(data, false, data.isClicked || !!isThreatSelected);
    }
  }

  /**
   * Show device details
   * @param data
   */
  showDeviceDetails(data: any, isEmptySpaceClicked = false) {
    this.itemDetails$.next(
      !!data
        ? {
            type: 'device',
            data,
          }
        : { data: null, isEmptySpaceClicked },
    );
  }

  /**
   * Highlight related links
   */
  highlightRelatedLinks(data: any, isHovered = true, isClicked = false) {
    const links = this.svg.selectAll('line.link');
    links.each((linkItem: any) => {
      if (
        ((linkItem.linkId as string) || '').indexOf(`line-s-${data.id}-`) > -1 ||
        ((linkItem.linkId as string) || '').indexOf(`-e-${data.id}-line`) > -1
      ) {
        let clickedStrokeColor = NetworkMapCommon.linkClickedColor;
        switch (data?.status) {
          case 'normal': {
            clickedStrokeColor = NetworkMapCommon.linkNormalClickedColor;
            break;
          }
          case 'anomalous': {
            clickedStrokeColor = NetworkMapCommon.linkAnomalousClickedColor;
            break;
          }
          default: {
            break;
          }
        }
        if (linkItem.link.attr('stroke') !== clickedStrokeColor) {
          linkItem.link.attr('stroke', !!isClicked ? clickedStrokeColor : !!isHovered ? NetworkMapCommon.linkHoverColor : NetworkMapCommon.linkColor);
        }
      }
    });
  }

  /**
   * ========================================================================================================================================================
   * THREAT INTERVALS
   * ========================================================================================================================================================
   */

  /**
   * Setup threat interval
   * @param data
   */
  setupThreatInterval(data: any) {
    let intervalStep = 0;
    let direction = 1;
    const interval = setInterval(() => {
      if (direction === 1) {
        // increase
        if (intervalStep + 0.05 <= 0.3) {
          intervalStep = intervalStep + 0.05;
        } else {
          direction = -1;
        }
      } else {
        // decrease
        if (intervalStep - 0.05 >= 0) {
          intervalStep = intervalStep - 0.05;
        } else {
          direction = 1;
        }
      }
      data?.circle1?.attr('opacity', intervalStep);
      data?.circle2?.attr('opacity', intervalStep);
    }, 100);
    const threatInterval = {
      interval,
      circle1: data?.circle1,
      circle2: data?.circle2,
    };
    this.threatIntervals.push(threatInterval);
  }

  /**
   * Clear threat intervals
   */
  clearThreatIntervals() {
    this.threatIntervals.forEach((threatInterval) => {
      clearInterval(threatInterval.interval);
      threatInterval?.circle1?.attr('opacity', 0.1);
      threatInterval?.circle2?.attr('opacity', 0.1);
    });
    this.threatIntervals = [];
  }

  /**
   * ========================================================================================================================================================
   * ZOOM
   * ========================================================================================================================================================
   */

  /**
   * Setup zoom options
   */
  setupZoomOptions() {
    this.zoom = d3
      .zoom()
      .on('zoom', (event) => {
        this.currentZoomShiftX = event.transform.x;
        this.currentZoomShiftY = event.transform.y;
        this.currentScale = event.transform.k;
        this.svg.attr('transform', event.transform);
      })
      .scaleExtent([0.1, 5]);
  }

  /**
   * Update zoom in/out
   * @param option
   */
  updateZoom(option: number | null = null) {
    const transform = () => {
      const transform = d3.zoomIdentity.translate(this.currentZoomShiftX, this.currentZoomShiftY).scale(this.currentScale);
      if (!!this.svgContainer?.call) {
        this.svgContainer.call(this.zoom.transform, transform);
      }
    };
    if (option !== null) {
      if (option !== 0) {
        // Update zoom in/out
        const updatedZoomLevel = this.currentScale + 0.1 * option;
        if (updatedZoomLevel >= 0.1 && updatedZoomLevel <= 5) {
          this.currentScale = updatedZoomLevel;
          this.currentZoomShiftX += this.viewX * 0.1 * option * -1;
          this.currentZoomShiftY += this.viewY * 0.1 * option * -1;
          transform();
        }
      } else {
        if (!!this.selectedDevice) {
          // Zoom to target device
          d3.selectAll('g.device-node').each((data: any) => {
            const groupId = `${this.selectedDevice?.status}-node-group-${this.selectedDevice?.id}`;
            if (data.groupId === groupId) {
              this.zoomToTarget({ target: data.circle.node() } as MouseEvent);
            }
          });
        } else {
          // Zoom to full diagram
          this.zoomToTarget();
        }
      }
    } else {
      transform();
    }
  }

  /**
   * Zoom to device's node(s)
   * @param event
   */
  zoomToTarget(event?: MouseEvent, zoomedDeviceIds: any[] = []) {
    let elementX = 0;
    let elementY = 0;
    if (!!event?.target) {
      // Clicked element
      const item = event.target as SVGCircleElement | SVGForeignObjectElement;
      const element = item.closest('g') as SVGGElement;
      elementX = +(element?.getAttribute('cx') || 0);
      elementY = +(element?.getAttribute('cy') || 0);
      // New scale
      this.currentScale = 2;
    } else {
      // Zoom all devices
      if (event?.target === undefined) {
        // Normal zoom
        if (this.devices.length > this.zoomDeviceLimit) {
          // Zoom all devices
          const nodesGroupElement = d3.select('g.nodes-group').node() as SVGGElement;
          const nodesGroup = nodesGroupElement?.getBBox();
          if (!!nodesGroup) {
            // New scale
            this.currentScale = Math.min(this.windowWidth / nodesGroup.width, this.windowHeight / nodesGroup.height) * this.zoomScaleDiff;
            elementX = nodesGroup.x * this.zoomScaleDiff + nodesGroup.width / 2;
            elementY = nodesGroup.y * this.zoomScaleDiff + nodesGroup.height / 2;
          }
        }
        // Less devices zoom
        else {
          this.currentScale = 1;
          this.currentZoomShiftX = 0;
          this.currentZoomShiftY = 0;
          const transform = d3.zoomIdentity.translate(this.currentZoomShiftX, this.currentZoomShiftY).scale(this.currentScale);
          if (!!this.svgContainer.transition) {
            this.svgContainer?.transition()?.duration(500)?.call(this.zoom.transform, transform);
          }
          return;
        }
      }
      // Zoom to a group of devices
      else if (event?.target === null) {
        const boundaryRect = {
          x: Infinity,
          y: Infinity,
          maxX: -Infinity,
          maxY: -Infinity,
          width: -Infinity,
          height: -Infinity,
        };
        d3.selectAll('g.device-node')
          .nodes()
          .forEach((node: any) => {
            const data: any = d3.select(node).data()?.[0];
            if (!!zoomedDeviceIds.includes(data?.id)) {
              const element = node.closest('g') as SVGGElement;
              const x = +(element.getAttribute('cx') || 0);
              const y = +(element.getAttribute('cy') || 0);
              boundaryRect.x = Math.min(boundaryRect.x, x);
              boundaryRect.y = Math.min(boundaryRect.y, y);
              boundaryRect.maxX = Math.max(boundaryRect.maxX, x);
              boundaryRect.maxY = Math.max(boundaryRect.maxY, y);
              boundaryRect.width = Math.abs(boundaryRect.maxX - boundaryRect.x);
              boundaryRect.height = Math.abs(boundaryRect.maxY - boundaryRect.y);
            }
          });
        if (zoomedDeviceIds.length > 1) {
          const widthRadius = !!boundaryRect.width ? this.windowWidth / boundaryRect.width : this.windowWidth;
          const heightRadius = !!boundaryRect.height ? this.windowHeight / boundaryRect.height : this.windowHeight;
          const scale = Math.min(widthRadius, heightRadius) * (this.zoomScaleDiff - 0.4);
          this.currentScale = Math.max(Math.min(scale, this.maxScaleLimit), this.minScaleLimit);
          elementX = boundaryRect.x * (this.zoomScaleDiff + 0.25) + boundaryRect.width / 2;
          elementY = boundaryRect.y * (this.zoomScaleDiff + 0.2) + boundaryRect.height / 2;
        } else {
          elementX = boundaryRect.x;
          elementY = boundaryRect.y;
          this.currentScale = 2;
        }
      }
    }
    /** CALCULATION X
     * -elementX * this.currentScale            => The correct position of the node, this will make sure the node will be always at left edge of the screen
     * + this.windowWidth * 0.25                => 0.25 to make sure the x will be located in position 1/4 of screen width
     * - this.viewX * (this.currentScale - 1)   => Additional value to make it locate to the position (0,y) of the svg
     */
    this.currentZoomShiftX = -elementX * this.currentScale + this.windowWidth * 0.25 - this.viewX * (this.currentScale - 1);
    /** CALCULATION Y
     * -elementY * this.currentScale            => The correct position of the node, this will make sure the node will be always at top edge corner of the screen
     * + this.windowHeight * 0.5                => 0.5 to make sure the x will be located in middle of screen height
     * - this.viewY * (this.currentScale - 1)   => Additional value to make it locate to the position (x,0) of the svg
     */
    this.currentZoomShiftY = -elementY * this.currentScale + this.windowHeight * 0.5 - this.viewY * (this.currentScale - 1);

    const transform = d3.zoomIdentity.translate(this.currentZoomShiftX, this.currentZoomShiftY).scale(this.currentScale);
    if (!!this.svgContainer.transition) {
      this.svgContainer?.transition()?.duration(500)?.call(this.zoom.transform, transform);
    }
  }

  /**
   * ========================================================================================================================================================
   * HELPER FUNCTIONS
   * ========================================================================================================================================================
   */

  /**
   * Refresh the device data
   */
  refresh() {
    this.refresh$.next(null);
  }

  setNetworkMapIcon(iconObject: any, iconObj: any, iconSize: any, iconPadding: any, growRadius: any) {
    const svgIconObject = iconObject
      .append('svg')
      .attr('width', (NetworkMapCommon.circleRadius + growRadius) * 2)
      .attr('height', (NetworkMapCommon.circleRadius + growRadius) * 2);
    if (iconObj?.type === 'svg') {
      d3.text(iconObj?.url).then((svgString: string) => {
        // Append the SVG content to the container element
        svgIconObject.html(
          svgString.replace(`width="48" height="48"`, `width="${iconSize}" height="${iconSize}"` + ` x="${iconPadding}" y="${iconPadding}"`),
        );
      });
    } else {
      svgIconObject.html(iconObj?.template);
    }
  }

  get maxWidth() {
    return this.windowWidth * 100;
  }

  get maxHeight() {
    return this.windowHeight * 100;
  }

  get viewX() {
    return this.maxWidth / 2;
  }

  get viewY() {
    return this.maxHeight / 2;
  }
}
