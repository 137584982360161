@if (!!tag) {
  <microsec-card [isNestedCard]="true">
    <div class="grid">
      <label class="col-12 font-bold"> Tag Details </label>
      <div class="col-12">
        <div class="list-fields mx-3">
          <div class="grid align-items-center">
            <label class="col-4">Name</label>
            <label class="col-8">{{ tag.label }}</label>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">Description</label>
            <label class="col-8">{{ tag.description || '-' }}</label>
          </div>
          <div class="grid align-items-center">
            <label class="col-4">Color</label>
            <div class="col-8">
              <div class="h-2rem w-2rem border-round-xs" [style.background]="tag.color"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </microsec-card>
}
