import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AnomalyAnalyzerService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';

export const FORM_PARAMS = {
  ID: 'id',
  LABEL: 'label',
  CREATED: 'created',
  UPDATED: 'updated',
};
@Component({
  selector: 'app-ml-model-form',
  templateUrl: './ml-model-form.component.html',
  styleUrls: ['./ml-model-form.component.scss'],
})
export class MlModelFormComponent extends BaseComponent implements AfterViewInit {
  model: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.model = this.dialogConfig?.data?.model;
    this.initForm();
    if (!!this.model) {
      this.form.patchValue(this.model);
    }
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ID,
        label: 'Model ID',
        required: true,
        fieldInfo: 'ID of the model analyzer',
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        required: true,
        fieldInfo: 'Label of the model analyzer',
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CREATED,
        label: 'Created',
        required: true,
        fieldInfo: 'Created date of the model analyzer',
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.UPDATED,
        label: 'Updated',
        required: true,
        fieldInfo: 'Updated date of the model analyzer',
        disabled: true,
      } as FormItem),
    ];
    this.fields = fields;
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const payload = this.form.getRawValue();
    this.anomalyAnalyzerSrv
      .updateModel(this.model.id, {
        label: payload.label,
      })
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Model Updated Successfully`);
          closeDialog();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }
}
