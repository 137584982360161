import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API } from '@ids-services';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { INetworkDeviceService } from './INetworkDeviceService';

const API_DEVICES = `${API.NETWORK_DEVICE_MANAGER}/devices`;

@Injectable({
  providedIn: 'root',
})
export class NetworkDeviceService implements INetworkDeviceService {
  constructor(private httpClient: HttpClient) {}

  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  createDevice(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_DEVICES}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDevices(
    organization_id?: any,
    proj_id?: any,
    page?: number,
    per_page?: number,
    label?: string,
    sort?: string,
    supported_features?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (proj_id) {
      params.push(`proj_id=${proj_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    if (label) {
      params.push(`label=${label}`);
    }
    if (supported_features) {
      params.push(`supported_features=${supported_features}`);
    }
    return this.httpClient
      .get(`${API_DEVICES}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDevice(id: any): Observable<any> {
    return this.httpClient.get<any>(`${API_DEVICES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  syncDevices(organization_id?: any, proj_id?: any): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (proj_id) {
      params.push(`proj_id=${proj_id}`);
    }
    return this.httpClient
      .post<any>(`${API_DEVICES}/sync${!!params.length ? `?${params.join('&')}` : ''}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  syncDevice(id: any): Observable<any> {
    return this.httpClient.post<any>(`${API_DEVICES}/${id}/sync`, {}).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateDevice(id: any, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_DEVICES}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteDevice(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${API_DEVICES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createDeviceConfig(payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API.NETWORK_DEVICE_MANAGER}/configs`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDeviceConfig(id: any): Observable<any> {
    return this.httpClient.get(`${API.NETWORK_DEVICE_MANAGER}/configs/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateDeviceConfig(id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${API.NETWORK_DEVICE_MANAGER}/configs/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteDeviceConfig(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${API.NETWORK_DEVICE_MANAGER}/configs/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  testDeviceConfig(id: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API.NETWORK_DEVICE_MANAGER}/configs/${id}/test`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createDeviceVlan(payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API.NETWORK_DEVICE_MANAGER}/vlans`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDeviceVlan(id: any): Observable<any> {
    return this.httpClient.get(`${API.NETWORK_DEVICE_MANAGER}/vlans/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateDeviceVlan(id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${API.NETWORK_DEVICE_MANAGER}/vlans/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteDeviceVlan(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${API.NETWORK_DEVICE_MANAGER}/vlans/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createDeviceFirewall(payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API.NETWORK_DEVICE_MANAGER}/firewall_rules`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDeviceFirewall(id: any): Observable<any> {
    return this.httpClient
      .get(`${API.NETWORK_DEVICE_MANAGER}/firewall_rules/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateDeviceFirewall(id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${API.NETWORK_DEVICE_MANAGER}/firewall_rules/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteDeviceFirewall(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${API.NETWORK_DEVICE_MANAGER}/firewall_rules/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
