/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, BehaviorSubject, Observable, scheduled } from 'rxjs';

import { IReportService } from './IReportService';
import * as reportsData from './mock-data/reports.json';
import * as reportTypesData from './mock-data/report-types.json';
import * as reportFiltersData from './mock-data/report-filters.json';

@Injectable({
  providedIn: 'root',
})
export class MockReportService implements IReportService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  createReport(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getReports(
    organization_id?: any,
    project_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    report_type_id?: any,
    status?: string,
  ): Observable<any> {
    return scheduled([reportsData], asyncScheduler);
  }
  getReport(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  downloadReport(id: any, format = 'pdf'): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteReport(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  createReportType(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getReportTypes(scope?: string, org_id?: any, proj_id?: any, page?: number, per_page?: number, sort?: string, reverse?: boolean): Observable<any> {
    return scheduled([reportTypesData], asyncScheduler);
  }
  getReportFilters(): Observable<any> {
    return scheduled([reportFiltersData], asyncScheduler);
  }
}
