export enum SYSTEM_SETTINGS_GROUPS {
  GLOBAL_SETTINGS = 'Global Settings',
}

export const SYSTEM_SETTINGS_CONSTANTS = {
  ORGANIZATIONS: {
    ROUTE: 'organizations',
    LABEL: 'Organizations',
  },
  USERS: {
    ROUTE: 'users',
    LABEL: 'Users',
  },
  EVENTS: {
    ROUTE: 'events',
    LABEL: 'Events',
  },
  LICENSES: {
    ROUTE: 'licenses',
    LABEL: 'Licenses',
  },
  SNORT_SETTINGS: {
    ROUTE: 'snort-settings',
    LABEL: 'Snort Settings',
  },
};
