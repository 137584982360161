import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MODULE_CONSTANTS } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss'],
})
export class SystemSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(private injector: Injector) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const SYSTEM_SETTINGS_CHILDREN = this.injector.get('SYSTEM_SETTINGS_CHILDREN');
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(true));
    this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE));
    const checkedItems = this.util.cloneObjectArray(SYSTEM_SETTINGS_CHILDREN);
    this.store.dispatch(new fromLayoutActions.UpdateReplacedItems(checkedItems.map((item) => ({ ...item, visible: true }))));
  }

  override ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.store.dispatch(new fromLayoutActions.UpdateReplacedItems([]));
  }
}
