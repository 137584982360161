<div class="grid">
  <!-- Search, Filter, Clear -->
  <div class="col-12">
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)"></microsec-common-toolbar>
  </div>
  <!-- Table -->
  <div class="col-12" [ngClass]="{ 'lg:col-6 ': !!selectedEvent && !!isMainPage }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isNestedCard]="!!deviceId" [isTableCard]="true">
      <microsec-common-table
        #dt
        [lazy]="true"
        (lazyLoadEvent)="getEvents(false, $any($event))"
        [totalRecords]="totalRecords"
        [cols]="selectedCols"
        [values]="values"
        selectionMode="multiple"
        [(selection)]="selectedEvents"
        [(rowSingleSelection)]="selectedEvent"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [dataKey]="'id'"
        itemName="Event"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  @if (!!selectedEvent && !!isMainPage) {
    <div class="col-12 lg:col-6">
      <div class="container-border">
        <microsec-selected-panel-header
          [label]="selectedEvent.eventLabel"
          (closeSelectedPanelEvent)="hideDetailsPanel()"></microsec-selected-panel-header>
        <microsec-card [isDetailCard]="true">
          <microsec-scroller [maxHeight]="'63.5vh'">
            <div style="padding-top: 1 rem">
              <app-event-overview [event]="selectedEvent"></app-event-overview>
            </div>
          </microsec-scroller>
        </microsec-card>
      </div>
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    @if (permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] || currentScope === SCOPE.GLOBAL) {
      <microsec-operation-button
        operation="Delete"
        buttonClass="p-button-danger"
        icon="fa fa-trash"
        (selectedOperationEvent)="openDeleteConfirmation(selectedEvents)"
        (allOperationEvent)="openDeleteConfirmation()"
        [disabled]="!selectedEvents.length"></microsec-operation-button>
    }
    @if (currentScope === SCOPE.PROJECT && !deviceId && permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
      @if (!isCleared) {
        <microsec-operation-button
          operation="Archive"
          buttonClass="p-button-secondary"
          icon="fa fa-archive"
          (selectedOperationEvent)="openArchiveConfirmation(selectedEvents)"
          (allOperationEvent)="openArchiveConfirmation()"
          [disabled]="!selectedEvents.length"></microsec-operation-button>
      } @else {
        <microsec-operation-button
          operation="Unarchive"
          buttonClass="p-button-secondary"
          icon="fa fa-boxes-packing"
          (selectedOperationEvent)="openUnarchiveConfirmation(selectedEvents)"
          (allOperationEvent)="openUnarchiveConfirmation()"
          [disabled]="!selectedEvents.length"></microsec-operation-button>
      }
    }
    <microsec-refresh-button (refreshEvent)="getEvents(false)"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Events -->
    <ng-container *ngSwitchCase="'details'">
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData.payload?.details || '-' }}
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<p-sidebar
  [(visible)]="displayEventDetails"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="selectedEvent = null">
  @if (!!selectedEvent && !!displayEventDetails) {
    <microsec-selected-panel-header [label]="selectedEvent.eventLabel" (closeSelectedPanelEvent)="hideDetailsPanel()" />
    <app-event-overview [event]="selectedEvent" (hidePanelEvent)="selectedEvent = null"></app-event-overview>
  }
</p-sidebar>
