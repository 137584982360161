import { AfterViewInit, Component, Input } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { COMPLIANCE_STANDARDS, COMPLIANCE_STANDARD_OPTIONS, FOUNDATIONAL_REQUIREMENT_LABELS } from '@ids-constants';

@Component({
  selector: 'app-zone-compliance',
  templateUrl: './zone-compliance.component.html',
  styleUrls: ['./zone-compliance.component.scss'],
})
export class ZoneComplianceComponent extends BaseComponent implements AfterViewInit {
  _zone: any = null;

  get zone() {
    return this._zone;
  }

  @Input() set zone(value: any) {
    this._zone = value;
    this.setComplianceResults();
  }

  complianceResults: any[] = [];

  complianceStandard = COMPLIANCE_STANDARDS.IEC_62443;

  COMPLIANCE_STANDARD_OPTIONS = COMPLIANCE_STANDARD_OPTIONS;

  async ngAfterViewInit() {
    await this.prepareConfigs();
  }

  setComplianceResults() {
    if (!!this.zone?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]) {
      const complianceResults: any[] = Object.values(FOUNDATIONAL_REQUIREMENT_LABELS).map((label, i) => ({
        label: label,
        target: this.zone.compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_target?.[i],
        capability: this.zone.compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_capable?.[i],
        achieved: this.zone.compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_achieved?.[i],
      }));
      this.complianceResults = complianceResults;
    } else {
      this.complianceResults = [];
    }
  }
}
