import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';
import {
  EDITOR_CREATION_TYPE_VALUES,
  EDITOR_DEFAULT_LOADING_MESSAGE,
  NETWORK_MAP_MODES,
  NETWORK_MAP_MODE_VALUES,
  PROJECT_MANAGEMENT_CONSTANTS,
} from '@ids-constants';
import { ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { NetworkMapEditor } from '@ids-models';
import { ConnectionService, TargetDeviceService } from '@ids-services';
import { NmeCreationFormComponent } from '../nme-top-navigation/nme-creation-form/nme-creation-form.component';
import { NmeImportDevicesFormComponent } from '../nme-top-navigation/nme-import-devices-form/nme-import-devices-form.component';
import { Observable, asyncScheduler, catchError, delay, forkJoin, map, scheduled, switchMap } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-nme-diagram',
  templateUrl: './nme-diagram.component.html',
  styleUrls: ['./nme-diagram.component.scss'],
})
export class NmeDiagramComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isFirstLoaded = true;

  _editor: NetworkMapEditor | null = null;

  get editor() {
    return this._editor;
  }

  @Input() set editor(value: NetworkMapEditor | null) {
    this._editor = value;
    if (!!value) {
      value.isLoading = true;
      value.loadingMessage = EDITOR_DEFAULT_LOADING_MESSAGE;
      this.subscriptions.push(
        scheduled([null], asyncScheduler)
          .pipe(delay(1000))
          .subscribe(() => {
            this.isFirstLoaded = false;
            this.initGraph();
          }),
      );
    }
  }

  @Output() editorChange: EventEmitter<NetworkMapEditor | null> = new EventEmitter<NetworkMapEditor | null>();

  modes = this.util.cloneObjectArray(NETWORK_MAP_MODES).map((p) => ({ ...p, disabled: p.value === NETWORK_MAP_MODE_VALUES.EDITOR }));

  mode = NETWORK_MAP_MODE_VALUES.EDITOR;

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.editorChange.emit(new NetworkMapEditor());
  }

  /**
   * Init graph
   */
  initGraph() {
    if (!!this.editor) {
      this.editor.graphCallback = () => {
        this.getNetworkMap();
      };
      this.editor!.isLoading = false;
      setTimeout(() => {
        this.editor?.initGraph();
        this.editor?.createDraggableItems();
        this.editor?.configGraph();
        this.editor?.initOverrideFunctions();
        this.editor?.initHandlers();
        this.editor?.graphCallback?.();
      });
    }
  }

  async changeMode(mode: any) {
    await this.prepareConfigs();
    if (mode === NETWORK_MAP_MODE_VALUES.EDITOR) {
      this.mode = NETWORK_MAP_MODE_VALUES.EDITOR;
    } else {
      this.changeRoute(
        `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
          `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
          `${PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP.ROUTE}` +
          `?mode=${mode}`,
      );
    }
  }

  getNetworkMap() {
    this.targetDeviceSrv.getNetworkMap(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId).subscribe({
      next: (rs: any) => {
        // If no data, show dialog
        if (!Object.keys(rs)?.length) {
          const creationDialog = this.dialogSrv.open(NmeCreationFormComponent, {
            header: 'Create Network Map',
            width: '800px',
            height: 'min-content',
            closable: false,
          });
          creationDialog.onClose.subscribe((result) => {
            if (result === EDITOR_CREATION_TYPE_VALUES.DISCOVERED_NETWORK_ASSETS) {
              const importDeviceDialog = this.dialogSrv.open(NmeImportDevicesFormComponent, {
                header: 'Import Devices',
                width: '800px',
                height: 'min-content',
                closable: false,
              });
              importDeviceDialog.onClose.subscribe((result) => {
                if (!!result) {
                  this.editor?.graph?.batchUpdate(() => {
                    this.editor?.importDevices(result);
                    this.editor!.zoom('fit');
                  });
                }
              });
            }
          });
        }
        // If existed data, show it
        else if (!!this.editor) {
          this.editor.lastSaved = moment.utc(rs?.modified).local() || null;
          this.editor.importXML((rs.xml_object as string) || '', true);
        }
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * ================================= ZOOM =================================
   */

  /**
   * Refresh layout
   */
  resetLayout() {
    this.confirm({
      action: 'Reset Network Layout',
      acceptLabel: 'Reset',
      customContent: `
      <p>Are you sure you want to reset to the original network map layout?</p>
      <p>This action will revert devices, along with their associated links and zones, to their default configurations before any edits were made.</p>
      `,
      next: () => {
        const importedDeviceRequests: Observable<any>[] = [];
        (this.editor?.importedDevices() || []).forEach((deviceId) => {
          importedDeviceRequests.push(this.targetDeviceSrv.getDevice(deviceId).pipe(catchError(() => scheduled([null], asyncScheduler))));
        });
        this.editor?.resetGraphLayout(
          forkJoin(importedDeviceRequests).pipe(
            switchMap((devices) => {
              return forkJoin([
                this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
                this.targetDeviceSrv.getLinks(),
              ]).pipe(
                map((results) => ({
                  devices: devices
                    .filter((p) => !!p && !!Object.keys(p).length)
                    .map((device) => {
                      ((device.zones as any[]) || []).forEach((zone) => {
                        zone.zone_id = zone.id;
                        delete zone.id;
                      });
                      return device;
                    }),
                  connections: results?.[0],
                  links: results?.[1],
                })),
              );
            }),
          ),
        );
      },
    });
  }

  /**
   * Zoom
   * @param mode
   */
  zoom(mode: any) {
    this.editor?.zoom(mode);
  }
}
