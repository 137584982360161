import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AssessmentService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  SCOPE: 'scope',
  NAME: 'name',
  DESCRIPTION: 'description',
  ANSWER_TYPE: 'answer_type',
};
@Component({
  selector: 'app-assessment-type-form',
  templateUrl: './assessment-type-form.component.html',
  styleUrls: ['./assessment-type-form.component.scss'],
})
export class AssessmentTypeFormComponent extends BaseComponent implements AfterViewInit {
  _assessmentType: any = null;

  get assessmentType() {
    return this._assessmentType;
  }

  @Input() set assessmentType(value: any) {
    this._assessmentType = value;
  }

  @Input() isPage = false;

  @Output() submitSucceedEvent: EventEmitter<any> = new EventEmitter<any>();

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('descriptionField') descriptionField!: TemplateRef<any>;

  constructor(
    private assessmentSrv: AssessmentService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.prepareConfigs();
    if (!this.isPage) {
      this.assessmentType = this.dialogConfig?.data?.assessmentType;
    }
    this.initForm(this.assessmentType || null);
  }

  initForm(assessmentType?: any) {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Assessment Type Name',
        labelStyleClass: 'font-bold',
        field: 'input',
        focused: true,
        required: true,
        defaultValue: assessmentType?.[FORM_PARAMS.NAME] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Assessment Type Description',
        labelStyleClass: 'font-bold',
        field: 'custom',
        customField: this.descriptionField,
        defaultValue: assessmentType?.[FORM_PARAMS.DESCRIPTION] || '',
      } as FormItem),
    ];
    fields.forEach((field) => {
      field.setFullSize();
    });
    this.fields = fields;
  }

  onSubmit(closeDialog?: (rs: any) => void) {
    this.form.isLoading = true;
    const payload = {
      ...this.form.getRawValue(),
      ...(!this.assessmentType && {
        [FORM_PARAMS.ORGANIZATION_ID]: this.breadcrumbConfig?.organizationId,
        [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
        [FORM_PARAMS.SCOPE]: this.currentScope,
      }),
    };
    const request: Observable<any> = !this.assessmentType
      ? this.assessmentSrv.createAssessmentType(payload)
      : this.assessmentSrv.updateAssessmentType(this.assessmentType.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.showSuccessMessage(`Assessment Type ${!!this.assessmentType ? 'Updated' : 'Created'} Successfully`);
          this.submitSucceedEvent?.emit(rs);
          this.initForm(rs);
          closeDialog?.(rs);
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
