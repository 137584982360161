<div class="form">
  <div class="px-3 scroll-panel">
    <div class="grid">
      <div class="col-12">
        <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)"></microsec-common-toolbar>
      </div>
      <div class="col-12">
        <microsec-card [isTableCard]="true">
          <microsec-common-table
            #dt
            [totalRecords]="totalRecords"
            [cols]="cols"
            [values]="values"
            selectionMode="multiple"
            [showMultiselectOptions]="true"
            [(selection)]="selectedQuestions"
            [loading]="isLoading"
            [dataKey]="'id'"
            itemName="Question"
            [containerHeaderRightTemplate]="containerHeaderRightTemplate">
          </microsec-common-table>
        </microsec-card>
      </div>
    </div>
  </div>

  <div class="action-buttons flex flex-row align-items-center justify-content-end gap-2">
    <p-button severity="secondary" (onClick)="dialogRef.close(false)" label="Cancel"> </p-button>
    <p-button icon="fas fa-add" severity="primary" label="Add Selected Questions" [disabled]="!selectedQuestions.length" (onClick)="onSubmit()">
    </p-button>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <p-button
    label="Show Selected"
    [severity]="!!isFilterSelected ? 'primary' : 'secondary'"
    [rounded]="true"
    styleClass="text-transform-none"
    (onClick)="filterSelected()"></p-button>
</ng-template>
