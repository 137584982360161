import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENTATION_URL, DOMAIN_TOKEN, BASE_API } from '@microsec/constants';
import { MenuItem } from 'primeng/api';

@Component({
  template: '',
  styleUrls: ['./base-app-component.component.scss'],
})
export class BaseAppComponent {
  constructor(protected titleSrv: Title) {}

  customTopNavButtons: MenuItem[] = [
    {
      label: 'Info',
      icon: 'info-circle',
      styleClass: 'tour-documentation-step',
      visible: true,
      items: [
        // {
        //   label: 'MicroIDS',
        //   disabled: true,
        //   styleClass: 'text-white font-bold',
        // },
        // {
        //   separator: true,
        // },
        {
          label: 'Documentation',
          command: () => {
            const customDomain = localStorage.getItem('domain');
            if (!!customDomain) {
              window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
            }
          },
        },
        {
          label: 'CVE Search',
          command: () => {
            const customDomain = localStorage.getItem('domain');
            if (!!customDomain) {
              window.open(`${BASE_API}/cves/`.replace(DOMAIN_TOKEN, customDomain), '_blank');
            }
          },
        },
      ],
    },
  ];
}
