<!-- <microsec-form-builder [isPage]="true" #fb [fields]="fields" [shouldShowActionButtons]="false" /> -->
<div class="grid form">
  <div class="col-12">
    <microsec-card [isNestedCard]="true">
      <div class="mb-3">
        <label class="font-bold">Firmware Details</label>
      </div>
      <div class="flex flex-column mx-3 gap-2 list-fields" *ngIf="!!firmware">
        <div class="grid align-items-center">
          <label class="col-4">Name</label>
          <label class="col-8 text-data overflow-hidden white-space-nowrap text-overflow-ellipsis">{{ firmware.name || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Target Device</label>
          <label class="col-8 text-data">{{ firmware.target_device || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Version</label>
          <label class="col-8 text-data">{{ firmware.version || '-' }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Uploaded</label>
          <label class="col-8 text-data">{{ firmware.created_date | momentPipe: MOMENT_DATETIME }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Signing Active</label>
          <label class="col-8 text-data">{{ !!firmware.signed ? 'Yes' : 'No' }}</label>
        </div>
      </div>
    </microsec-card>
  </div>
</div>
