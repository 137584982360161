<div class="grid">
  <div class="col-12">
    <div class="m-2">
      <microsec-common-toolbar
        #ct
        [configuration]="filterConfiguration"
        [filterObject$]="$any(filterObject$)"
        [leftTemplate]="leftTemplate"></microsec-common-toolbar>
    </div>
    <ng-template #leftTemplate>
      <p-button label="Assign Firmware" [rounded]="true" icon="pi pi-plus" (onClick)="openDeviceAssignmentForm()"> </p-button>
    </ng-template>

    <div class="col-12">
      <microsec-card [isTableCard]="true" [isNoPadding]="true">
        <microsec-common-table
          #dt
          [globalFilterFields]="globalFilterFields"
          [cols]="selectedCols || []"
          [values]="devices"
          [loading]="isLoading"
          [(selection)]="selectedDevices"
          selectionMode="multiple"
          [cellTemplate]="cellTemplate"
          [actionsMenuItems]="actionsMenuItems"
          [scrollHeight]="'40vh'"
          [containerHeaderRightTemplate]="containerHeaderRightTemplate">
        </microsec-common-table>
      </microsec-card>
    </div>
  </div>

  <ng-template #containerHeaderRightTemplate>
    <div class="flex flex-wrap align-items-center gap-2">
      <microsec-operation-button
        *ngIf="firmware?.target_device === 'hikvision_cctv'"
        operation="Push to Device"
        selectedOperationLabel="Push to Selected Device"
        allOperationLabel="Push to All Device"
        buttonClass="p-button-primary"
        icon="fa fa-upload"
        (selectedOperationEvent)="openFirmwarePushForm(selectedDevices)"
        (allOperationEvent)="openFirmwarePushForm()"
        [disabled]="!selectedDevices.length"></microsec-operation-button>
      <microsec-operation-button
        operation="Delete"
        buttonClass="p-button-danger"
        icon="fa fa-trash"
        (selectedOperationEvent)="openDeleteConfirmation(selectedDevices)"
        (allOperationEvent)="openDeleteConfirmation()"
        [disabled]="!selectedDevices.length"></microsec-operation-button>
      <microsec-table-column-filter [key]="constructor.name" [cols]="cols || []" [(selectedColFields)]="selectedColFields">
      </microsec-table-column-filter>
    </div>
  </ng-template>

  <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
    <ng-container [ngSwitch]="col.field">
      <ng-container *ngSwitchCase="'current_status'">
        <microsec-truncate-text>
          {{ rowData[col.field] | constant: 'firmware-device-status' }}
        </microsec-truncate-text>
      </ng-container>
      <ng-container *ngSwitchCase="'uploaded'">
        <microsec-truncate-text>
          {{ rowData[col.field] | momentPipe }}
        </microsec-truncate-text>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <microsec-truncate-text>
          {{ rowData[col.field] }}
        </microsec-truncate-text>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
