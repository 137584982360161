/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, Observable, scheduled } from 'rxjs';

import { IAccessTokenService } from './IAccessTokenService';
import * as tokensData from './mock-data/tokens.json';

@Injectable({
  providedIn: 'root',
})
export class MockAccessTokenService implements IAccessTokenService {
  getTokens(user_id: number): Observable<any[]> {
    return scheduled([(tokensData as any).default], asyncScheduler);
  }
  createToken(user_id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteToken(user_id: number, id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
