<div class="grid">
  <!-- Create + Search -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button severity="primary" label="Create Tag" [rounded]="true" icon="pi pi-plus" (onClick)="openTagForm()"> </p-button>
    </ng-template>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedTag }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [(firstIndex)]="firstIndex"
        [cols]="selectedCols"
        [values]="values"
        [loading]="isLoading"
        selectionMode="multiple"
        [(selection)]="selectedTags"
        [(rowSingleSelection)]="selectedTag"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [dataKey]="'id'"
        itemName="Tag"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-6" *ngIf="!!selectedTag">
    <div class="container-border">
      <microsec-loading-overlay [isLoading]="!!isLoading">
        <microsec-selected-panel-header
          [id]="selectedTag.id"
          [label]="selectedTag.label"
          (closeSelectedPanelEvent)="hideDetailsPanel()"
          [rightTemplate]="rightTemplate"></microsec-selected-panel-header>
        <ng-template #rightTemplate>
          <p-button
            severity="primary"
            icon="fas fa-edit"
            pTooltip="Edit"
            tooltipPosition="bottom"
            appendTo="body"
            (onClick)="openTagForm(selectedTag)"
            *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
          </p-button>
        </ng-template>
        <microsec-card [isDetailCard]="true">
          <p-tabView styleClass="microsec-dashboard-tab">
            <p-tabPanel header="Overview">
              <app-tag-overview [tag]="selectedTag"> </app-tag-overview>
            </p-tabPanel>
          </p-tabView>
        </microsec-card>
      </microsec-loading-overlay>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedTags)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedTags.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getTags()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Name -->
    <ng-container *ngSwitchCase="'label'">
      <div class="flex align-items-center w-max" [pTooltip]="rowData[col.field]" tooltipPosition="top" appendTo="body">
        <div class="custom-chip" [style]="{ background: util.hexToRgba(rowData.color), color: rowData.color }">
          {{ rowData[col.field] }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
