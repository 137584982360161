<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="grid">
    <div class="col-12">
      <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)"></microsec-common-toolbar>
    </div>
    <div class="col-12">
      <p-dataView
        [rows]="PER_PAGE"
        styleClass="microsec-dashboard-dataView"
        [value]="values"
        [paginator]="true"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [loading]="isLoading">
        <ng-template let-threats pTemplate="list">
          @for (threat of threats; track $index) {
            <div class="mb-2 border-none w-full">
              <app-network-map-item
                type="threat"
                [item]="threat"
                [itemDetails$]="itemDetails$"
                (changeSelectedDevice)="changeSelectedItemDevice($event)">
              </app-network-map-item>
            </div>
          }
        </ng-template>
      </p-dataView>
    </div>
  </div>
</microsec-loading-overlay>
