<microsec-card [isBordered]="true">
  <div style="max-width: 75rem">
    <microsec-form-builder
      #fb
      [isPage]="true"
      [fields]="fields"
      [shouldShowActionButtons]="true"
      [submitButtonLabel]="SAVE_CHANGES_LABEL"
      cancelButtonLabel=""
      (submitEvent)="validateOinkcode()"
      [leftActionArea]="leftActionArea">
    </microsec-form-builder>
  </div>
</microsec-card>

<ng-template #headerTemplate>
  <div class="flex flex-column gap-3">
    <label class="text-xl font-bold">Snort Oinkcode</label>
    <label class="text-grey"
      >Set up Snort Oinkcode to allow creation of rule-based analyzers.
      <span class="link-blue" (click)="openSnortOinkcodeDocumentation()">Learn more about Snort Oinkcode.</span></label
    >
  </div>
</ng-template>

<ng-template #oinkcodeTypeTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="$any(form.form)">
      <div class="grid">
        <div class="col-12 md:col-6 flex flex-column gap-3">
          <label class="font-bold">Select Settings to Use</label>
          <label class="text-sm text-grey"
            >Set oinkcode to use inherited settings (from {{ scope === SCOPE.PROJECT ? 'organization / ' : '' }}global level) or custom
            settings</label
          >
        </div>
        <div class="col-12 md:col-6 mt-3">
          <p-dropdown
            [options]="OINKCODE_TYPE_OPTIONS"
            [formControlName]="FORM_PARAMS.OINKCODE_TYPE"
            placeholder="Select settings to use"
            optionLabel="label"
            optionValue="value"
            appendTo="body"></p-dropdown>
        </div>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #oinkcodeTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="grid">
        <div
          class="col-12 md:col-6 flex flex-column gap-3"
          [ngClass]="{ 'opacity-50': scope !== SCOPE.GLOBAL && form.getControlValue(FORM_PARAMS.OINKCODE_TYPE) === OINKCODE_TYPES.INHERITED }">
          <label class="font-bold">Oinkcode</label>
          <label class="text-sm text-grey">{{ oinkcodeFieldInfo }}</label>
        </div>
        @if (scope !== SCOPE.GLOBAL && form.getControlValue(FORM_PARAMS.OINKCODE_TYPE) === OINKCODE_TYPES.INHERITED && !existedParentScope) {
          <div class="col-12 md:col-6 flex flex-column gap-3">
            <label>Not Configured</label>
            <label class="font-bold link-blue" (click)="goToSnortSetting()">
              Manage {{ scope === SCOPE.PROJECT ? 'Organization' : 'Global' }} Oinkcode
            </label>
          </div>
        } @else {
          <div class="col-12 md:col-6 mt-3">
            <input type="text" pInputText [formControlName]="FORM_PARAMS.OINKCODE" [maxlength]="40" />
          </div>
        }
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #leftActionArea>
  <p-button [label]="CANCEL_LABEL" severity="secondary" styleClass="mr-2" (onClick)="getOinkcodes()"> </p-button>
</ng-template>
