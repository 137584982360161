import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ThreatTitlePipe } from '@ids-pipes';

import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-network-map-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
  providers: [ThreatTitlePipe],
})
export class ItemDetailsComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  @Input() itemDetails$ = new BehaviorSubject<any>(null);

  @Input() itemDetailsObs = new Observable<any>();

  @Input() changedItems$ = new BehaviorSubject<any>(null);

  @Input() refresh$ = new BehaviorSubject<any>(null);

  @Output() changeSelectedDevice = new EventEmitter<any>();

  type: any = null;

  item: any = null;

  constructor(private threatTitlePipe: ThreatTitlePipe) {
    super();
  }

  ngAfterViewInit(): void {
    this.itemDetails$.subscribe((itemDetails) => {
      this.isLoading = true;
      setTimeout(() => {
        this.type = itemDetails?.type;
        this.item = itemDetails?.data;
        this.isLoading = false;
      });
    });
  }

  hideItemDetails() {
    // Device Detail Panel Closed (-): Remove Selected Device from Network-map parent component
    this.changeSelectedDevice.emit(null);
    setTimeout(() => {
      this.itemDetails$.next(null);
    });
  }

  refreshNetworkPanelAndMap() {
    this.refresh$.next(null);
  }

  get itemTitle() {
    switch (this.type) {
      case 'device': {
        return `ID ${this.item?.id}: ${this.item?.label}`;
      }
      case 'threat': {
        return `ID ${this.item?.threat_id}: ${this.threatTitlePipe.transform(this.item)}`;
      }
      default:
        return '';
    }
  }
}
