import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ANALYZER_DATA_TYPES, ANALYZER_TYPES } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService } from '@ids-services';

import { finalize } from 'rxjs';

@Component({
  selector: 'app-ml-analyzer-details',
  templateUrl: './ml-analyzer-details.component.html',
  styleUrls: ['./ml-analyzer-details.component.scss'],
})
export class MlAnalyzerDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  activeIndex = 0;

  _analyzerId: any = null;

  get analyzerId() {
    return this._analyzerId;
  }

  @Input() set analyzerId(value: any) {
    this._analyzerId = value;
    if (!!this._analyzerId) {
      this.getAnalyzer();
    }
  }

  analyzer: any = {};

  @Output() analyzerChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() hidePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() panelHeaderRightTemplate: TemplateRef<any> | null = null;

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_DATA_TYPES = ANALYZER_DATA_TYPES;

  checkInterval: any;

  constructor(public anomalyAnalyzerSrv: AnomalyAnalyzerService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkInterval = setInterval(() => {
      if (!!this.analyzerId && this.analyzer?.analyzer_type === ANALYZER_TYPES.ML) {
        this.getAnalyzer(false);
      }
    }, 5000);
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.anomalyAnalyzerSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !!this.analyzerId) {
        this.getAnalyzer();
      }
    });
    this.subscriptions.push(subscription);
  }

  getAnalyzer(showLoading = true) {
    if (!!showLoading) {
      this.isLoading = true;
    }
    this.anomalyAnalyzerSrv
      .getAnalyzer(this.analyzerId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.analyzer = rs || {};
          this.analyzerChangeEvent.emit(this.analyzer);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  override ngOnDestroy(): void {
    this.cleanup();
    clearInterval(this.checkInterval);
  }
}
