<!-- TABS -->
@if (!this.isCompliance) {
  <div class="flex w-full px-5 my-2">
    <p-tabMenu
      [model]="tabItems"
      [(activeItem)]="activeTabItem"
      class="w-full"
      styleClass="microsec-dashboard-tabmenu w-full"
      (activeItemChange)="onActiveStepChange($event)" />
  </div>
}
<!-- FORM CONTENT -->
<microsec-form-builder
  #fb
  [fields]="fields"
  [submitButtonLabel]="!device ? CREATE_LABEL : SAVE_CHANGES_LABEL"
  (submitEvent)="onSubmit($event)"
  [additionalConditions]="
    !!form && (!form.getControlValue(FORM_PARAMS.IS_COMPLIANCE) || (!!form.getControlValue(FORM_PARAMS.IS_COMPLIANCE) && !!complianceDetailsValid))
  "
  [shouldShowActionButtons]="true"
  [leftActionArea]="leftActionArea" />

<ng-template #leftActionArea>
  <div class="flex flex-grow-1 justify-content-between align-items-center">
    <div class="flex">
      <p-button
        severity="primary"
        type="button"
        label="Autofill"
        pTooltip="Automatically fill compliance values based on MicroIDS knowledge base."
        tooltipPosition="bottom"
        appendTo="body"
        *ngIf="!!form?.getControlValue(FORM_PARAMS.IS_COMPLIANCE) && (this.activeTabItem?.id === TAB_VALUES.COMPLIANCE || !!isCompliance)"
        [disabled]="!deviceInfo"
        (onClick)="getDeviceInfo(true)" />
    </div>
  </div>
</ng-template>

<ng-template #rightActionArea> </ng-template>

<ng-template #criticalityField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="grid align-items-center -mt-1">
        <div class="col">
          <p-slider [formControlName]="FORM_PARAMS.CRITICALITY" [min]="0" [max]="10"></p-slider>
        </div>
        <div class="col-fixed text-center" style="width: 50px">
          <label class="font-bold">
            {{ form.getControlValue(FORM_PARAMS.CRITICALITY) }}
          </label>
        </div>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #tagsField>
  <app-tags-field [(selectedTags)]="selectedTags" fieldName="tag"></app-tags-field>
</ng-template>

<ng-template #zonesField>
  <app-tags-field [(selectedTags)]="selectedZones" fieldName="zone"></app-tags-field>
</ng-template>

<ng-template #complianceDetailsField>
  @if (!!securityLevel?.target?.length && !!securityLevel?.capable?.length) {
    <app-compliance-details-field [readOnly]="false" [(securityLevel)]="securityLevel" [(isValid)]="complianceDetailsValid" />
  }
</ng-template>
