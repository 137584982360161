import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConnectionService } from '@ids-services';
import { BaseComponent } from '@ids-components';
import { CONNECTION_TYPES, CONNECTION_TYPE_LABELS, QUOTAS_FEATURES, STATUSES } from '@ids-constants';
import { verticalSlideAnimation } from '@microsec/animations';
import { ReportSpecification } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ActionMenuItem } from '@microsec/models';

import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';

import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

const FIELDS = {
  id: 'ID',
  name: 'Name',
  status: 'Status',
  enabled: 'Enabled',
  description: 'Description',
  interfaceType: 'Interface Type',
  protocols: 'Protocols',
  reload_pcap: 'Reload PCAP',
};

@Component({
  selector: 'app-network-list',
  templateUrl: './network-list.component.html',
  styleUrls: ['./network-list.component.scss'],
  animations: [verticalSlideAnimation],
  providers: [ArrayToPlaceholderStringPipe],
})
export class NetworkListComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  firstIndex = 0;

  @ViewChild('dt') dt!: CommonTableComponent;

  @Output() openConnectionFormEvent: EventEmitter<any> = new EventEmitter<any>();

  values: any[] = [];

  cols = [
    { field: 'enabled', header: FIELDS.enabled, width: 15 },
    { field: 'name', header: FIELDS.name, width: 20 },
    { field: 'status', header: FIELDS.status, width: 15 },
    { field: 'description', header: FIELDS.description, width: 20 },
    { field: 'interfaceType', header: FIELDS.interfaceType, width: 20 },
    { field: 'protocols', header: FIELDS.protocols, width: 20 },
  ];

  globalFilterFields: string[] = ['name', 'description', 'interfaceType', 'protocols'];

  selectedConnections: any[] = [];

  selectedConnection: any = null;

  CONNECTION_TYPE_LABELS = CONNECTION_TYPE_LABELS;

  STATUSES = STATUSES;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private connectionSrv: ConnectionService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openConnectionForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.handleFilterObjUpdate();
    this.getConnections();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.connectionSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getConnections();
      }
    });
    this.subscriptions.push(subscription);
  }

  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values?.search || '';
      }
    });
  }

  getConnections() {
    this.isLoading = true;
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.connectionSrv.selected = null;
        }),
      )
      .subscribe({
        next: (res) => {
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(res?.data || []), 'id')?.map((connection) => ({
            ...connection,
            interfaceType: connection.interface?.type,
          }));
          if (!!this.selectedConnection) {
            this.selectedConnection = this.values.find((conn) => conn.id === this.selectedConnection.id);
          }
          if (!!this.connectionSrv.selected) {
            const values = this.util.cloneObjectArray(this.values);
            const selectedIndex = values.findIndex((connection) => connection.id === this.connectionSrv.selected.id);
            if (selectedIndex >= 0) {
              this.firstIndex = Math.floor(selectedIndex / PER_PAGE) * PER_PAGE;
              this.selectedConnection = { ...values[selectedIndex] };
            }
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openConnectionForm(connection?: any) {
    this.openConnectionFormEvent?.emit(connection);
  }

  //handle the connection switch toggle
  updateConnection(connection: any) {
    this.isLoading = true;
    const payload = this.handlePayload(connection);
    this.connectionSrv
      .updateConnection(connection.id, payload)
      .pipe(
        finalize(() => {
          this.connectionSrv.refresh$.next(true);
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Connection Updated Successfully');
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  // modify payload for connection update to match BE endpoint
  handlePayload(connection: any): any {
    let payload = null;
    if (connection.interfaceType === CONNECTION_TYPES.PCAP) {
      // handle PCAP payload using form data
      payload = new FormData();
      payload.append('enabled', connection.enabled);
      payload.append('use_packet_processor', JSON.stringify(!!connection.interface?.use_packet_processor));
      payload.append('name', connection.name);
      payload.append('description', connection.description);
      payload.append('type', connection.interfaceType);
      payload.append('protocols', JSON.stringify(connection.protocols));
      payload.append('project_id', connection.project_id);
      payload.append('interface', { ...connection.interface });
      payload.append('status', connection.status);
      payload.append('ips_to_ignore', JSON.stringify(connection.ips_to_ignore));
      payload.append('macs_to_ignore', JSON.stringify(connection.macs_to_ignore));
      payload.append('reload_pcap', connection.reload_pcap);
    } else {
      // handle non-PCAP payload
      //remove id and interfaceType from payload to prevent BE error
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { interfaceType, id, ...rest } = connection;
      payload = {
        enabled: connection.enabled,
        ...rest,
      };
      if (connection.interfaceType === CONNECTION_TYPES.MQTT_AGENT && !!payload.interface?.hasOwnProperty('client_id')) {
        delete payload.interface?.client_id;
      }
    }
    return payload;
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((connection) => ({
          ...connection,
          enabled: connection.enabled?.toString()?.toUpperCase(),
          reload_pcap: connection.reload_pcap?.toString()?.toUpperCase(),
          interfaceType: connection.interface?.type ? CONNECTION_TYPE_LABELS[connection.interface.type] : connection.interface.type,
          protocols: connection.protocols?.map((protocol: string) => protocol?.toUpperCase()).join(', '),
        }));
        this.dt.generateReportDialog.exportReport(data, `project_${this.breadcrumbConfig?.projectId}_connections`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  openDeleteConfirmation(connections: any[] = []) {
    const connectionList = connections.map((con) => `<li>${`Connection ID ${con.id} ${!!con.name ? `: ${con.name}` : ''}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Network(s)',
      customContent: `Are you sure you want to delete ${
        !connections?.length ? 'all connections?<br/><br/>' : `the following connection(s)?<ul>${connectionList.join('')}</ul>`
      }Deleting this network connection(s) will result in the permanent removal of all associated analyzers, detected devices and threats.`,
      next: () => {
        this.isLoading = true;
        if (!connections.length) {
          connections = this.values;
        }
        const requests: Observable<any>[] = connections.map((device) => this.connectionSrv.deleteConnection(device.id));
        forkJoin(requests)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe({
            next: () => {
              this.showSuccessMessage(`Deleted connection(s) successfully`);
              const connectionIds = connections.map((connection) => connection.id) || [];
              if (!!this.selectedConnections?.length) {
                this.selectedConnections = this.selectedConnections.filter((connection) => !connectionIds?.includes(connection.id));
              }
              if (!!this.selectedConnection && !!connectionIds.includes(this.selectedConnection.id)) {
                this.selectedConnection = null;
              }
              this.getConnections();
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          });
      },
    });
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedConnection = null;
  }

  get checkConnectionQuota() {
    return this.values.length < this.getQuotaLimitation(QUOTAS_FEATURES.CONNECTIONS);
  }

  override ngOnDestroy() {
    this.cleanup();
    this.connectionSrv.refresh$.next(null);
  }
}
