import { Component, Input } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { EDITOR_DIAGRAM_CONFIG_KEYS, EDITOR_OBJECT_TYPES } from '@ids-constants';
import { NetworkMapEditor } from '@ids-models';
import { NetworkMapHelper } from '@ids-utilities';
import { Cell } from '@maxgraph/core';

@Component({
  selector: 'app-nme-diagram-config',
  templateUrl: './nme-diagram-config.component.html',
  styleUrls: ['./nme-diagram-config.component.scss'],
})
export class NmeDiagramConfigComponent extends BaseComponent {
  @Input() editor: NetworkMapEditor | null = null;

  EDITOR_DIAGRAM_CONFIG_KEYS = EDITOR_DIAGRAM_CONFIG_KEYS;

  changeDiagramConfig(cf: any) {
    if (!!this.editor) {
      switch (cf?.key) {
        case EDITOR_DIAGRAM_CONFIG_KEYS.DEVICE_NAME: {
          this.editor.showLoader(() => {
            const freeDeviceTypeCells =
              this.editor?.layers?.device?.children?.filter((p) => !!p.id?.startsWith(`${EDITOR_OBJECT_TYPES.DEVICE}-`)) || [];
            const deviceCellsInZones =
              this.editor?.layers.zone?.children.reduce((allItems: Cell[], item: Cell) => {
                allItems.push(...item.children);
                return allItems;
              }, []) || [];
            [...freeDeviceTypeCells, ...deviceCellsInZones].forEach((cell) => {
              cell.setValue({ ...cell.value, label: !!cf?.value ? NetworkMapHelper.shortenDeviceName(cell.value?.data?.label) : '' });
              this.editor?.graph?.refresh();
            });
          });
          break;
        }
        case EDITOR_DIAGRAM_CONFIG_KEYS.GRID: {
          if (!!this.editor.graph) {
            this.editor.graph.setGridEnabled(!!cf?.value);
            this.editor?.repaintGrids(true);
            this.editor?.graph?.refresh();
          }
          break;
        }
        case EDITOR_DIAGRAM_CONFIG_KEYS.PAGE_VIEW: {
          if (!!this.editor.graph) {
            this.editor.graph.pageBreaksVisible = !!cf.value;
            this.editor.graph.refresh();
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }
}
