/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { AssessmentService, ReportService } from '@ids-services';
import { CommonChart } from '@microsec/models';
import {
  ASSESSMENT_RESULTS,
  ASSESSMENT_RESULT_COLORS,
  ASSESSMENT_RESULT_LABELS,
  COMPLIANCE_STANDARD_IEC_62443_KEY,
  COMPLIANCE_STANDARDS,
  REPORT_STATUSES,
  QUOTAS_FEATURES,
  REPORTS_FEATURES,
} from '@ids-constants';
import { CHARTS, CHART_KEYS } from '../../../../overview/dashboard/dashboard-compliance/dashboard-compliance.config';

import { finalize, forkJoin } from 'rxjs';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';

const DEVICE_COMPLIANCE_CHART_OPTIONS: ChartOptions = {
  animation: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context: any) => {
          const label = context[0].label || 'No Data';
          return label;
        },
        label: (context: any): string => {
          const value: number = context.parsed || 0;
          const total: number = context.dataset.data.reduce((a: any, b: any) => a + b);
          const percentage: number = (context.parsed / total) * 100;
          return ` ${Math.round(percentage)}% ${value}/${total} `;
        },
      },
    },
  },
};

@Component({
  selector: 'app-assessment-report',
  templateUrl: './assessment-report.component.html',
  styleUrls: ['./assessment-report.component.scss'],
})
export class AssessmentReportComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  isLoadingCharts = false;

  isDashboard = false;

  selectedOverview: 'question' | 'device' = 'question';

  assessment: any = null;

  assessmentReport: any = null;

  report: any = null;

  reportTotalRecords = 0;

  sections: any[] = [];

  devices: any[] = [];

  allDevices: any[] = [];

  showScoreSL = false;

  reportTypes: any[] = [];

  hideReportMessageCache = false;

  deviceComplianceChart: CommonChart = this.util.cloneDeepObject({
    type: 'doughnut',
    key: 'DEVICE_COMPLIANCE_CHART',
    label: 'Device Distribution by Compliance',
    data: {} as ChartData,
    options: DEVICE_COMPLIANCE_CHART_OPTIONS,
    children: Object.values(ASSESSMENT_RESULTS).map((value) => ({
      LABEL: ASSESSMENT_RESULT_LABELS[value],
      COLOR: ASSESSMENT_RESULT_COLORS[value],
    })),

    legendcols: [
      { field: 'label', header: 'Status', width: 5 },
      { field: 'percent', header: '%', width: 2 },
      { field: 'counter', header: 'Devices', width: 4 },
    ],
  } as CommonChart);

  deviceRiskComplianceChart: CommonChart = this.util.cloneDeepObject(CHARTS[CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE]);

  downloadReportMenuOptions: MenuItem[] = [
    {
      label: 'PDF',
      icon: 'fas fa-file-pdf',
      command: () => this.downloadReport('pdf'),
    },
    {
      label: 'Docx',
      icon: 'fas fa-file-word',
      command: () => this.downloadReport('docx'),
    },
  ];

  gauge = {
    thresholds: {
      '0': { color: ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.NOT_COMPLIANT] },
      '1': { color: ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT] },
      '80': { color: ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.COMPLIANT] },
    },
    pinColor: '#eeeeee',
  };

  ASSESSMENT_RESULTS = ASSESSMENT_RESULTS;

  REPORT_STATUSES = REPORT_STATUSES;

  questionCount = {
    unanswered: 0,
    answered: 0,
  };

  getReportInterval: any;

  @ViewChild('reportMessageTarget') reportMessageTarget!: ElementRef;

  @ViewChild('reportMessagePanel') reportMessagePanel!: OverlayPanel;

  constructor(
    private assessmentSrv: AssessmentService,
    private reportSrv: ReportService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.hideReportMessageCache = JSON.parse(localStorage.getItem('hideMessageAssessmentReportGeneration') || 'false');
    this.assessment = this.dialogConfig?.data?.assessment;
    this.showScoreSL = this.assessment?.assessmentType?.standard === COMPLIANCE_STANDARD_IEC_62443_KEY;
    this.allDevices = this.dialogConfig?.data?.allDevices || [];
    this.isDashboard = this.dialogConfig?.data?.isDashboard;
    this.getData();
    this.getReportInterval = setInterval(() => {
      this.getReport();
    }, 60000);
  }

  getData() {
    if (!!this.assessment) {
      this.isLoading = true;
      forkJoin({
        assessment: this.assessmentSrv.getAssessment(this.assessment.id),
        assessmentReport: this.assessmentSrv.getAssessmentReport(this.assessment.id),
        reportTypes: this.reportSrv.getReportTypes('', this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
        reportTotalRecords: this.reportSrv.getReports(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, 1, 1),
      })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.getReport(true);
            this.setData();
            this.setChartData();
          }),
        )
        .subscribe({
          next: (res) => {
            this.assessment = {
              ...this.assessment,
              ...(res.assessment || {}),
            };
            this.assessmentReport = {
              ...(res.assessmentReport || {}),
              displayScoreOverall: Math.floor((res.assessmentReport?.score_overall || 0) * 100),
            };
            this.reportTypes = (res.reportTypes?.report_types || []).filter((type: any) => !!(type.data_sources || []).includes('assessments:ids'));
            this.reportTotalRecords = res.reportTotalRecords?.total_record || 0;
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  onCreateReport(event: any) {
    this.isLoading = true;
    const payload = {
      organization_id: this.breadcrumbConfig?.organizationId,
      project_id: this.breadcrumbConfig?.projectId,
      name: `Report Assessment ${this.assessment.name} (${moment().format('YYYYMMDD_HHmmss')})`,
      report_type_id: this.reportTypes?.[0]?.id,
      report_filters: [
        {
          data_source: 'assessments:ids',
          assessment_ids: [{ data: this.assessment.id }],
        },
      ],
    };
    this.reportSrv
      .createReport(payload)
      .pipe()
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.assessment.report_id = res.id;
          this.report = {
            status: REPORT_STATUSES.CREATED,
          };
          this.getReport();
          this.patchAssessment(res.id);
          if (!this.hideReportMessageCache) {
            this.reportMessagePanel.show(event, this.reportMessageTarget?.nativeElement as HTMLElement);
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.showErrorMessage(error);
        },
      });
  }

  patchAssessment(reportId: any) {
    this.assessmentSrv
      .updateAssessment(this.assessment.id, {
        report_id: reportId,
      })
      .pipe()
      .subscribe();
  }

  getReport(showLoading = false) {
    if (!!this.assessment?.report_id && (!this.report || (!!this.report && this.report.status === REPORT_STATUSES.CREATED))) {
      if (!!showLoading) {
        this.isLoading = true;
      }
      this.reportSrv
        .getReport(this.assessment.report_id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.report = res;
          },
          error: (err) => {
            if (err.status === 404) {
              this.patchAssessment(null);
            }
          },
        });
    }
  }

  downloadReport(type = 'pdf') {
    if (this.report) {
      this.isLoading = true;
      this.reportSrv
        .downloadReport(this.report.id, type)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.showSuccessMessage(`Downloaded ${this.report.name} successfully`);
            this.util.downloadClientFile(`${(this.report.name || '').split(' ').join('_')}.${type}`, res);
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  setData() {
    this.gauge.pinColor = ASSESSMENT_RESULT_COLORS[this.assessmentReport?.result_overall] || '#eeeeee';

    const sectionsQuestionIds: { [key: string]: any[] } = {};
    const questionsDetail: { [key: string]: any } = {};
    ((this.assessment?.assessmentType?.sections as any[]) || []).forEach((section) => {
      const questionIds: any[] = [];
      ((section.assigned_questions as any[]) || []).forEach((question) => {
        if (!!question.question) {
          questionIds.push(question.question.id);
          questionsDetail[question.question.id] = {
            description: question.question.description || '',
            descriptionPreview: this.util.parseMarkdown(question.question.description || ''),
            mandatory: !!question.mandatory,
          };
        }
      });
      sectionsQuestionIds[section.id] = questionIds;
    });

    let answered = 0;
    let unanswered = 0;
    ((this.assessmentReport?.questions as any[]) || []).forEach((q) => {
      if (this.getResult(q.result) !== ASSESSMENT_RESULTS.UNANSWERED) {
        answered += 1;
      } else {
        unanswered += 1;
      }
    });
    this.questionCount.answered = answered;
    this.questionCount.unanswered = unanswered;

    this.sections = this.mapSections(sectionsQuestionIds, questionsDetail);

    const devices: any[] = ((this.assessmentReport?.devices as any[]) || []).map((d) => {
      return {
        ...d,
        sections: this.mapSections(sectionsQuestionIds, questionsDetail, d),
      };
    });
    this.devices = devices;
  }

  setChartData() {
    this.isLoadingCharts = true;

    const devices = (this.assessmentReport?.devices as any[]) || [];

    const deviceComplianceChart: CommonChart = this.util.cloneDeepObject(this.deviceComplianceChart);
    deviceComplianceChart.total = devices.length;
    const deviceComplianceChartData = deviceComplianceChart.data as ChartData;
    deviceComplianceChartData.labels = Object.values(ASSESSMENT_RESULT_LABELS);
    deviceComplianceChartData.datasets = [
      {
        data: [
          devices.filter((device) => this.getResult(device.result) === ASSESSMENT_RESULTS.COMPLIANT).length,
          devices.filter((device) => this.getResult(device.result) === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT).length,
          devices.filter((device) => this.getResult(device.result) === ASSESSMENT_RESULTS.NOT_COMPLIANT).length,
          devices.filter((device) => this.getResult(device.result) === ASSESSMENT_RESULTS.UNANSWERED).length,
          devices.filter((device) => this.getResult(device.result) === ASSESSMENT_RESULTS.NOT_APPLICABLE).length,
        ],
        backgroundColor: Object.values(ASSESSMENT_RESULT_COLORS),
        borderWidth: 0,
      } as ChartDataset,
    ];
    this.deviceComplianceChart = deviceComplianceChart;

    const filteredDevices = devices.filter((device) => typeof device.compliance_score === 'number');
    const deviceRiskComplianceChart: CommonChart = this.util.cloneDeepObject(this.deviceRiskComplianceChart);
    deviceRiskComplianceChart.total = filteredDevices.length;
    const deviceRiskComplianceChartData = deviceRiskComplianceChart.data as ChartData;
    const radius = filteredDevices.reduce((obj, device) => {
      obj[`${Math.round((device.compliance_score || 0) * 100)}_${device.criticality || 0}`] =
        (obj[`${Math.round((device.compliance_score || 0) * 100)}_${device.criticality || 0}`] || 4) + 1;
      return obj;
    }, {});
    const datasets = filteredDevices.map((device: any) => {
      const color = device.compliance_score === 1 ? '#00ad74' : device.compliance_score > 0 ? '#ff9534' : '#fb4d58';
      const opacity = 0.3 / ((radius[`${Math.round((device.compliance_score || 0) * 100)}_${device.criticality || 0}`] || 5) - 4);
      return {
        label: device.device_label,
        data: [
          {
            x: Math.round(device.compliance_score * 100),
            y: device.criticality,
            r: radius[`${Math.round((device.compliance_score || 0) * 100)}_${device.criticality || 0}`] || 5,
          },
        ],
        borderColor: color,
        backgroundColor: this.util.hexToRgba(color, opacity),
      } as ChartDataset;
    });
    deviceRiskComplianceChartData.datasets = datasets;
    this.deviceRiskComplianceChart = deviceRiskComplianceChart;

    setTimeout(() => {
      this.isLoadingCharts = false;
    });
  }

  mapSections(sectionsQuestionIds: { [key: string]: any[] }, questionsDetail: { [key: string]: any }, device?: any) {
    const sections: any[] = ((this.assessmentReport?.sections as any[]) || []).map((s, is) => {
      const questions: any[] = ((this.assessmentReport?.questions as any[]) || [])
        .filter((q) => !!sectionsQuestionIds[s.section_id]?.includes(q.question_id))
        .map((q) => {
          return {
            ...q,
            question_description: questionsDetail[q.question_id]?.description || '',
            question_descriptionPreview: questionsDetail[q.question_id]?.descriptionPreview || '',
            question_mandatory: !!questionsDetail[q.question_id]?.mandatory,
            result: this.getResult(!device ? q.result : ((q.devices as any[]) || []).find((d) => d.device_id === device.device_id)?.result),
            devices: !device
              ? ((q.devices as any[]) || []).map((d) => ({
                  ...d,
                  result: this.getResult(d.result),
                }))
              : undefined,
          };
        });
      return {
        section_id: s.section_id,
        section_name: s.section_name,
        sla: !device ? undefined : device.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_achieved?.[is] || 0,
        slc: !device ? undefined : device.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_capable?.[is] || 0,
        slt: !device ? undefined : device.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_target?.[is] || 0,
        result: this.getResult(!device ? s.result : ((s.devices as any[]) || []).find((d) => d.device_id === device.device_id)?.result),
        questions: questions,
      };
    });
    return sections;
  }

  getResult(result: any) {
    return (Array.isArray(result) ? result[0] : result) || ASSESSMENT_RESULTS.UNANSWERED;
  }

  getChartLegendLabel(value: any, label: string) {
    return label;
  }

  alwaysHideReportMessage() {
    localStorage.setItem('hideMessageAssessmentReportGeneration', JSON.stringify(true));
    this.hideReportMessageCache = true;
    this.reportMessagePanel.hide();
  }

  get checkReportQuota() {
    return this.reportTotalRecords < this.getQuotaLimitation(QUOTAS_FEATURES.REPORTS);
  }

  get checkReportFeatured() {
    return !!this.checkReportFeatureEnabled(REPORTS_FEATURES.ENABLED);
  }

  override ngOnDestroy() {
    this.cleanup();
    clearInterval(this.getReportInterval);
  }
}
