import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { ILayoutItem } from '@microsec/interfaces';
import { BaseComponent } from '@ids-components';

import { DEVICES_CHILDREN } from './devices.config';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { DEVICE_FEATURES } from '@ids-constants';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkDeviceFeatureEnabled(DEVICE_FEATURES.ENABLED)) {
      if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
        const tabItems: ILayoutItem[] = this.util.cloneObjectArray(DEVICES_CHILDREN).filter((p) => !!p.label);
        this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      }
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
