import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import moment from 'moment';
import { MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

const TIME_RANGE_ITEMS = {
  CUSTOM_RANGE: {
    ID: 'CUSTOM_RANGE',
    LABEL: 'Custom Range',
  },
  ALL_TIME: {
    ID: 'ALL_TIME',
    LABEL: 'All Time',
  },
  LAST_7_DAYS: {
    ID: 'LAST_7_DAYS',
    LABEL: 'Last 7 days',
  },
  LAST_30_DAYS: {
    ID: 'LAST_30_DAYS',
    LABEL: 'Last 30 days',
  },
  LAST_90_DAYS: {
    ID: 'LAST_90_DAYS',
    LABEL: 'Last 90 days',
  },
  THIS_MONTH: {
    ID: 'THIS_MONTH',
    LABEL: 'This month',
  },
  LAST_MONTH: {
    ID: 'LAST_MONTH',
    LABEL: 'Last month',
  },
  LAST_12_MONTH: {
    ID: 'LAST_12_MONTH',
    LABEL: 'Last 12 month',
  },
  THIS_YEAR: {
    ID: 'THIS_YEAR',
    LABEL: 'This year',
  },
  LAST_YEAR: {
    ID: 'LAST_YEAR',
    LABEL: 'Last year',
  },
};

@Component({
  selector: 'app-report-form-time-range-input',
  templateUrl: './time-range-input.component.html',
  styleUrls: ['./time-range-input.component.scss'],
})
export class TimeRangeInputComponent extends BaseComponent implements AfterViewInit {
  _dateRange: Date[] | null | undefined;

  get dateRange() {
    return this._dateRange;
  }

  @Input() set dateRange(value: Date[] | null | undefined) {
    this._dateRange = value;
    this.dateRangeChange.emit(value);
  }

  @Output() dateRangeChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('timeRangePanel') timeRangePanel!: OverlayPanel;

  timeRangeItems: MenuItem[] = [];

  selectedTimeRangeItem: string | null = null;

  config = {
    minDate: moment('20200101').toDate(),
    maxDate: moment().toDate(),
  };

  TIME_RANGE_ITEMS = TIME_RANGE_ITEMS;

  constructor() {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.config.maxDate = moment().toDate();
    this.setTimeRangeItems();
  }

  setTimeRange(id: string) {
    this.selectedTimeRangeItem = id;
    this.setTimeRangeItems();
    if (this.selectedTimeRangeItem !== TIME_RANGE_ITEMS.CUSTOM_RANGE.ID) {
      this.timeRangePanel.hide();
    }
  }

  setTimeRangeItems() {
    this.timeRangeItems = [
      {
        label: TIME_RANGE_ITEMS.CUSTOM_RANGE.LABEL,
        styleClass: this.selectedTimeRangeItem === TIME_RANGE_ITEMS.CUSTOM_RANGE.ID ? 'selected' : '',
        command: () => {
          this.dateRange = null;
          this.setTimeRange(TIME_RANGE_ITEMS.CUSTOM_RANGE.ID);
        },
      },
      {
        label: TIME_RANGE_ITEMS.ALL_TIME.LABEL,
        styleClass: this.selectedTimeRangeItem === TIME_RANGE_ITEMS.ALL_TIME.ID ? 'selected' : '',
        command: () => {
          this.dateRange = null;
          this.setTimeRange(TIME_RANGE_ITEMS.ALL_TIME.ID);
        },
      },
      {
        label: 'Day',
        disabled: true,
      },
      {
        label: TIME_RANGE_ITEMS.LAST_7_DAYS.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.LAST_7_DAYS.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [moment().subtract(7, 'days').startOf('day').toDate(), moment().endOf('day').toDate()];
          this.setTimeRange(TIME_RANGE_ITEMS.LAST_7_DAYS.ID);
        },
      },
      {
        label: TIME_RANGE_ITEMS.LAST_30_DAYS.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.LAST_30_DAYS.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [moment().subtract(30, 'days').startOf('day').toDate(), moment().endOf('day').toDate()];
          this.setTimeRange(TIME_RANGE_ITEMS.LAST_30_DAYS.ID);
        },
      },
      {
        label: TIME_RANGE_ITEMS.LAST_90_DAYS.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.LAST_90_DAYS.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [moment().subtract(90, 'days').startOf('day').toDate(), moment().endOf('day').toDate()];
          this.setTimeRange(TIME_RANGE_ITEMS.LAST_90_DAYS.ID);
        },
      },
      {
        label: 'Month',
        disabled: true,
      },
      {
        label: TIME_RANGE_ITEMS.THIS_MONTH.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.THIS_MONTH.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [moment().startOf('month').startOf('day').toDate(), moment().endOf('day').toDate()];
          this.setTimeRange(TIME_RANGE_ITEMS.THIS_MONTH.ID);
        },
      },
      {
        label: TIME_RANGE_ITEMS.LAST_MONTH.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.LAST_MONTH.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [
            moment().subtract(1, 'months').startOf('month').startOf('day').toDate(),
            moment().subtract(1, 'months').endOf('month').endOf('day').toDate(),
          ];
          this.setTimeRange(TIME_RANGE_ITEMS.LAST_MONTH.ID);
        },
      },
      {
        label: TIME_RANGE_ITEMS.LAST_12_MONTH.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.LAST_12_MONTH.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [
            moment().subtract(12, 'months').startOf('month').startOf('day').toDate(),
            moment().subtract(12, 'months').endOf('month').endOf('day').toDate(),
          ];
          this.setTimeRange(TIME_RANGE_ITEMS.LAST_12_MONTH.ID);
        },
      },
      {
        label: 'Year',
        disabled: true,
      },
      {
        label: TIME_RANGE_ITEMS.THIS_YEAR.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.THIS_YEAR.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [moment().startOf('year').startOf('day').toDate(), moment().endOf('day').toDate()];
          this.setTimeRange(TIME_RANGE_ITEMS.THIS_YEAR.ID);
        },
      },
      {
        label: TIME_RANGE_ITEMS.LAST_YEAR.LABEL,
        styleClass: 'ml-3' + (this.selectedTimeRangeItem === TIME_RANGE_ITEMS.LAST_YEAR.ID ? ' selected' : ''),
        command: () => {
          this.dateRange = [
            moment().subtract(1, 'years').startOf('year').startOf('day').toDate(),
            moment().subtract(1, 'years').endOf('year').endOf('day').toDate(),
          ];
          this.setTimeRange(TIME_RANGE_ITEMS.LAST_YEAR.ID);
        },
      },
    ];
  }

  onClearTime() {
    this.selectedTimeRangeItem = null;
    this.dateRange = null;
  }

  checkDateValidity() {
    if (this.isDateRangeValid) {
      this.timeRangePanel.hide();
    }
  }

  get isDateRangeValid() {
    return this.dateRange?.length === 2 && this.dateRange?.[0] instanceof Date && this.dateRange?.[1] instanceof Date;
  }
}
