import { Cell } from '@maxgraph/core';
import { NetworkMapEditor } from './network-map-editor';

export const NMELayer = {
  /**
   * Manage layers
   * @param mode
   */
  orderLayers(this: NetworkMapEditor, mode: string) {
    const key = Object.keys(this.selection).find((key) => !!(this.selection as any)[key]) as string;
    const parent = ((this.selection as any)[key] as Cell).parent as Cell;
    switch (mode) {
      case 'front': {
        const cellsExceptSelection: Cell[] = parent.children.filter((p) => p.id !== (this.selection as any)[key]?.id);
        cellsExceptSelection?.push((this.selection as any)[key]);
        parent.children = cellsExceptSelection;
        break;
      }
      case 'back': {
        const cellsExceptSelection: Cell[] = parent.children.filter((p) => p.id !== (this.selection as any)[key]?.id);
        cellsExceptSelection?.unshift((this.selection as any)[key]);
        parent.children = cellsExceptSelection;
        break;
      }
      case 'forward': {
        const selectedCellIndex = parent.children.findIndex((p) => p.id === (this.selection as any)[key]?.id);
        if (selectedCellIndex < parent.children.length - 1) {
          const cellsExceptSelection: Cell[] = parent.children.filter((p) => p.id !== (this.selection as any)[key]?.id);
          cellsExceptSelection.splice(selectedCellIndex + 1, 0, (this.selection as any)[key]);
          parent.children = cellsExceptSelection;
        }
        break;
      }
      case 'backward': {
        const selectedCellIndex = parent.children.findIndex((p) => p.id === (this.selection as any)[key]?.id);
        if (selectedCellIndex > 0) {
          const cellsExceptSelection: Cell[] = parent.children.filter((p) => p.id !== (this.selection as any)[key]?.id);
          cellsExceptSelection.splice(selectedCellIndex - 1, 0, (this.selection as any)[key]);
          parent.children = cellsExceptSelection;
        }
        break;
      }
      default: {
        break;
      }
    }
    this.isSaved = false;
    this.refreshGraph();
  },
};
