<microsec-form-builder #fb [fields]="fields" [shouldShowActionButtons]="true" [submitButtonLabel]="'Apply'" (submitEvent)="onSubmit($event)" />

<ng-template #intervalTemplate>
  <ng-container
    *ngIf="!!form?.form?.get([FORM_PARAMS.INTERVAL, FORM_PARAMS.TIME]) && !!form?.form?.get([FORM_PARAMS.INTERVAL, FORM_PARAMS.UNIT])"
    [formGroup]="$any(form.form)">
    <ng-container [formGroupName]="FORM_PARAMS.INTERVAL">
      <div class="flex gap-3 align-self-center">
        <p-inputNumber mode="decimal" [maxlength]="NUMBER_MAX_LENGTH" [useGrouping]="false" [formControlName]="FORM_PARAMS.TIME"></p-inputNumber>
        <p-dropdown
          [options]="unitOptions"
          [formControlName]="FORM_PARAMS.UNIT"
          placeholder="Unit"
          optionLabel="label"
          optionValue="value"
          appendTo="body"></p-dropdown>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
