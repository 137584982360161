<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!analyzer) {
    <microsec-selected-panel-header
      [id]="analyzer.id"
      [label]="analyzer.name"
      (closeSelectedPanelEvent)="hidePanelEvent.emit()"
      [rightTemplate]="panelHeaderRightTemplate" />
    <microsec-card [isDetailCard]="true">
      <p-tabView styleClass="microsec-dashboard-tab" [(activeIndex)]="activeIndex">
        <p-tabPanel header="Overview">
          <app-rule-based-analyzer-overview [analyzer]="detailsAnalyzer"></app-rule-based-analyzer-overview>
        </p-tabPanel>
      </p-tabView>
    </microsec-card>
  }
</microsec-loading-overlay>
