<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="form px-5">
    @if (!!devicesMergeCandidates.node.length) {
      <div class="flex flex-column gap-1 mt-2">
        <label class="mb-1"> Devices with identical names or MAC addresses have been detected.</label>
        <label> Please select the devices you wish to merge from the list below. </label>
      </div>
      <div class="mt-4">
        <p-treeTable
          [value]="devicesMergeCandidates.node"
          [columns]="cols"
          [scrollable]="true"
          [paginator]="true"
          [rowsPerPageOptions]="PER_PAGE_OPTIONS"
          [rows]="PER_PAGE"
          paginatorDropdownAppendTo="body"
          styleClass="microsec-dashboard-tree-table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th [style.width.rem]="6">
                <p-checkbox [binary]="true" [(ngModel)]="allDeviceSelected" (onChange)="onToggleAll($event.checked)"></p-checkbox>
              </th>
              <th *ngFor="let col of columns" [style.width.rem]="col.width">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr [ngClass]="{ 'child-row': !!rowNode.parent }">
              <td [style.width.rem]="6">
                <div class="flex align-items-center gap-2">
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  <p-checkbox
                    name="selectedDevices"
                    [value]="rowData.id"
                    [(ngModel)]="selectedDevices"
                    (onChange)="onChangeTreeTableCheckbox($event.checked, rowData.id, rowNode)"></p-checkbox>
                </div>
              </td>
              <td *ngFor="let col of columns; let i = index" [style.width.rem]="col.width">
                {{ rowData[col.field] }}
              </td>
            </tr>
          </ng-template>
        </p-treeTable>
      </div>
      <div class="action-buttons flex align-items-center justify-content-end gap-2">
        <p-button severity="secondary" type="button" (onClick)="dialogRef.close(false)" label="Cancel"> </p-button>
        <p-button severity="success" type="button" label="Merge All" (onClick)="onSubmit(true)"> </p-button>
        <p-button severity="success" type="button" label="Merge Selected" (onClick)="onSubmit()" [disabled]="!selectedDevices.length"> </p-button>
      </div>
    } @else {
      <div class="mb-6">
        <label> No devices with identical names or MAC addresses have been detected.</label>
      </div>
      <div class="action-buttons flex align-items-center justify-content-end">
        <p-button severity="primary" type="button" (onClick)="dialogRef.close(false)" label="Done"> </p-button>
      </div>
    }
  </div>
</microsec-loading-overlay>
