import { Injectable } from '@angular/core';

import { asyncScheduler, Observable, scheduled } from 'rxjs';

import { IIDSService } from './IIDSService';

@Injectable({
  providedIn: 'root',
})
export class MockIdsService implements IIDSService {
  getHostsInfo(): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
