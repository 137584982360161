import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../api';

import { Observable, catchError, throwError } from 'rxjs';

import { IAccessTokenService } from './IAccessTokenService';

const ACCESS_POLICY_API = API.ACCESS_POLICY;

@Injectable({
  providedIn: 'root',
})
export class AccessTokensService implements IAccessTokenService {
  constructor(private httpClient: HttpClient) {}

  getTokens(user_id: number): Observable<any> {
    return this.httpClient
      .get<any>(`${ACCESS_POLICY_API}/user/${user_id}/tokens`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createToken(user_id: number, payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${ACCESS_POLICY_API}/user/${user_id}/tokens`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteToken(user_id: number, id: number): Observable<any> {
    return this.httpClient
      .delete<any>(`${ACCESS_POLICY_API}/user/${user_id}/tokens/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
