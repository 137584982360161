import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EventMonitorEffects } from './+state/event-monitor.effects';
import * as fromEvents from './+state/event-monitor.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromEvents.EVENT_MONITOR_FEATURE_KEY, fromEvents.eventsReducer),
    EffectsModule.forFeature([EventMonitorEffects]),
  ],
})
export class NgrxEventMonitorModule {}
