<div class="grid">
  <div class="col-12">
    <p-dropdown
      [options]="COMPLIANCE_STANDARD_OPTIONS"
      [(ngModel)]="complianceStandard"
      styleClass="filter-dropdown"
      optionLabel="label"
      optionValue="value"
      appendTo="body">
    </p-dropdown>
  </div>

  @if (!!complianceResults.length) {
    @for (result of complianceResults; track $index) {
      <div class="col-12">
        <microsec-card [isNestedCard]="true" [isBordered]="true">
          <div class="flex flex-wrap align-items-start gap-3">
            <div class="flex align-items-start gap-3">
              <div>
                <i class="fas fa-rectangle-list"></i>
              </div>

              <div class="flex flex-column gap-3">
                <label class="font-bold">{{ result.label }}</label>
                <label> Target Security Level: {{ result.target }} </label>
                <label> Capability Security Level: {{ result.capability }} </label>
              </div>
            </div>

            @if (result.achieved !== null && result.achieved !== undefined) {
              <div
                class="custom-chip square ml-auto"
                [ngClass]="{
                  green: result.achieved >= result.target,
                  red: result.achieved < result.target
                }">
                Achieved Security Level: {{ result.achieved }}
              </div>
            }
          </div>
        </microsec-card>
      </div>
    }
  } @else {
    <div class="col-12">
      <div class="text-grey text-center my-5">No zone compliance details available</div>
    </div>
  }
</div>
