<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="grid">
    <div class="col-12">
      <div class="flex flex-wrap justify-content-between gap-2">
        <div class="flex flex-wrap gap-2">
          <p-multiSelect
            styleClass="filter-multiselect"
            [options]="THREAT_TYPE_OPTIONS"
            [(ngModel)]="filterThreatType"
            optionLabel="label"
            optionValue="value"
            (ngModelChange)="getData()"
            defaultLabel="Filter by Threat Type...">
          </p-multiSelect>
          <p-multiSelect
            styleClass="filter-multiselect"
            [options]="ATTACK_TYPE_OPTIONS"
            [(ngModel)]="filterAttackType"
            optionLabel="label"
            optionValue="value"
            (ngModelChange)="getData()"
            defaultLabel="Filter by Attack Type...">
          </p-multiSelect>
          <p-multiSelect
            styleClass="filter-multiselect"
            [options]="THREAT_SCORE_OPTIONS"
            [(ngModel)]="filterThreatScore"
            optionLabel="label"
            optionValue="value"
            (ngModelChange)="getData()"
            defaultLabel="Filter by Threat Score...">
          </p-multiSelect>
        </div>
        <div>
          <div class="flex flex-wrap align-items-center gap-2">
            <p-dropdown
              styleClass="filter-dropdown"
              [options]="timeRangeOptions"
              [(ngModel)]="selectedTimeRange"
              optionValue="value"
              (onChange)="setTimeRange(true)">
              <ng-template let-timeRange pTemplate="item">
                <div class="-mx-3 -my-2 px-3 py-2" (click)="timeRange.value === 'custom' && openCustomTimeRangeFormComponent()">
                  <label class="text-data">
                    {{ timeRange.label }}
                  </label>
                </div>
              </ng-template>
            </p-dropdown>
            <p-button
              severity="danger"
              icon="fa fa-filter-circle-xmark"
              pTooltip="Clear filter"
              tooltipPosition="bottom"
              appendTo="body"
              (onClick)="clearFilter()"></p-button>
            <microsec-operation-button
              [isThreeEvent]="true"
              operation="Refresh"
              buttonClass="p-button-primary"
              icon="fa fa-refresh"
              [allOperationLabel]="(!!isRefreshIntervalEnabled ? 'Disable' : 'Enable') + ' Auto Refresh'"
              selectedOperationLabel="Edit Refresh Interval"
              (clickEvent)="getData()"
              (allOperationEvent)="setRefreshInterval(true, !isRefreshIntervalEnabled, false)"
              (selectedOperationEvent)="openRefreshIntervalForm()" />
          </div>
          <div class="mt-2 flex justify-content-end" *ngIf="!!isRefreshIntervalEnabled && !!refreshIntervalTimer">
            <label class="text-white">Next Refresh in {{ refreshIntervalTimer | date: 'HH:mm:ss' }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 my-2">
      <div class="flex align-items-center gap-3">
        <label class="text-white font-light text-2xl">{{ !!isActiveThreats ? 'Active' : 'All' }} Threats</label>
      </div>
    </div>
    <div class="col-12">
      <div class="grid">
        <div class="col-12 lg:col-6 xl:col-3">
          <microsec-card [isChartCard]="true" [isFull]="true">
            <label class="font-bold">Overall Threat Score</label>
            <div class="grid align-items-center h-full">
              <div class="col-12 text-center mt-2">
                <microsec-common-gauge
                  [color]="
                    (summary?.project_threat_score | threatScorePipe) < 4
                      ? '#00ad74'
                      : (summary?.project_threat_score | threatScorePipe) < 8
                        ? '#ff9534'
                        : '#fb4d58'
                  "
                  [value]="summary?.project_threat_score | threatScorePipe"
                  append="/10"
                  [label]="
                    (summary?.project_threat_score | threatScorePipe) < 4
                      ? 'Low'
                      : (summary?.project_threat_score | threatScorePipe) < 8
                        ? 'Medium'
                        : 'High'
                  "
                  [labelClass]="
                    (summary?.project_threat_score | threatScorePipe) < 4
                      ? 'text-green'
                      : (summary?.project_threat_score | threatScorePipe) < 8
                        ? 'text-orange'
                        : 'text-red'
                  " />
              </div>
            </div>
          </microsec-card>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
          <microsec-card [isChartCard]="true" [isFull]="true">
            <div class="grid">
              <label class="col-12 font-bold">Threat Overview</label>
              <div class="col-12">
                <label class="text-6xl font-bold">
                  {{ summary?.total || 0 }}
                  <span class="text-sm">{{ !!isActiveThreats ? 'Active' : 'All' }} Threats</span>
                </label>
              </div>
              <div class="col-12 flex justify-content-center">
                <div class="grid grid-nogutter max-w-full" style="width: 40rem">
                  <ng-container *ngFor="let scoreChart of scoreCharts; let lastScoreChart = last">
                    <div class="col-4 p-1">
                      <div class="flex flex-column gap-1 mb-6" [ngClass]="{ 'divider-right': !lastScoreChart }">
                        <label
                          class="text-lg font-bold"
                          [ngClass]="{
                            'text-red': scoreChart.label === 'High',
                            'text-orange': scoreChart.label === 'Medium',
                            'text-green': scoreChart.label === 'Low'
                          }">
                          {{ scoreChart.label }}
                        </label>
                        <label class="text-xs"> {{ summary?.totalByScore?.[$any(scoreChart.label?.toLowerCase())] || 0 }} Threats</label>
                      </div>
                      <div style="height: 5rem" [ngClass]="{ 'pr-2': !lastScoreChart }">
                        <canvas #scoreDiagrams></canvas>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </microsec-card>
        </div>
        <div class="col-12 lg:col-12 xl:col-6">
          <microsec-card [isChartCard]="true" [isFull]="true">
            <div class="grid">
              <label class="col-12 font-bold">Threat Timeline</label>
              <div class="col-12">
                <div style="height: 20rem" [ngClass]="{ hidden: !summary?.total }">
                  <canvas #timelineDiagram></canvas>
                </div>
                <div class="flex" style="height: '20rem'" *ngIf="!summary?.total">
                  <label class="m-auto">No data found</label>
                </div>
                <div class="flex flex-row flex-wrap justify-content-center ml-2">
                  <div class="py-2" *ngFor="let option of timelineChartOptions">
                    <p-radioButton
                      labelStyleClass="mr-6"
                      [(ngModel)]="selectedTimelineChart"
                      [value]="option.value"
                      [label]="option.label"></p-radioButton>
                  </div>
                </div>
              </div>
            </div>
          </microsec-card>
        </div>
      </div>
    </div>
    <div class="col-12">
      <microsec-common-overview #dashboardThreatsComponent [charts]="charts" [getLegendLabel]="getLegendLabel" />
    </div>
    <div class="col-12">
      <microsec-card [isChartCard]="true">
        <div class="grid">
          <label class="col-12 font-bold">Critical Devices</label>
          <div class="col-12">
            <microsec-common-table
              #dtDevice
              [lazy]="true"
              (lazyLoadEvent)="getDevices(true, $event)"
              [totalRecords]="deviceTotalRecords"
              [cols]="deviceCols"
              [values]="devices"
              [(selection)]="selectedDevice"
              [loading]="isLoadingDevice"
              [cellTemplate]="deviceCellTemplate"
              [dataKey]="'id'"
              [scrollHeight]="'40rem'"
              [noSortingCols]="['id', 'label', 'type', 'interfaces', 'threat_score']"
              [showContainerHeader]="false" />
          </div>
        </div>
      </microsec-card>
    </div>
    <div class="col-12">
      <microsec-card [isChartCard]="true">
        <div class="grid">
          <label class="col-12 font-bold">Recent Threats</label>
          <div class="col-12">
            <microsec-common-table
              #dtThreat
              [lazy]="true"
              (lazyLoadEvent)="getThreats(true, $event)"
              [totalRecords]="threatTotalRecords"
              [cols]="threatCols"
              [values]="threats"
              [(selection)]="selectedThreat"
              [loading]="isLoadingThreat"
              [cellTemplate]="threatCellTemplate"
              [dataKey]="'threat_id'"
              [scrollHeight]="'40rem'"
              [noSortingCols]="['created', 'threat_id', 'threat_type', 'attack_type', 'threat_score', 'status']"
              [showContainerHeader]="false" />
          </div>
        </div>
      </microsec-card>
    </div>
  </div>
</microsec-loading-overlay>

<ng-template let-col="col" let-rowData="rowData" #deviceCellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Interfaces -->
    <ng-container *ngSwitchCase="'interfaces'">
      {{ rowData | detectedDeviceInterfacesPipe }}
    </ng-container>
    <!-- Threat Score -->
    <ng-container *ngSwitchCase="'threat_score'">
      <span [ngClass]="{ 'text-red': !!rowData[col.field] }">
        {{ (rowData[col.field] | number: '1.0-0') || '-' }}
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #threatCellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Timestamp -->
    <ng-container *ngSwitchCase="'created'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <!-- Threat Score -->
    <ng-container *ngSwitchCase="'threat_score'">
      <microsec-score-scale [inputNumber]="rowData.threat_score" [tooltipText]="'Threat Score'" />
    </ng-container>
    <!-- Status -->
    <ng-container *ngSwitchCase="'status'">
      <div
        class="uppercase max-w-max bordered-status"
        [ngClass]="{
          red: rowData.status.toLowerCase() === 'open',
          green: rowData.status.toLowerCase() === 'closed',
          orange: rowData.status.toLowerCase() === 'fixing',
          grey: rowData.status.toLowerCase() === 'archived'
        }">
        {{ rowData['status'] || '-' }}
      </div>
    </ng-container>
    <!-- Tags -->
    <ng-container *ngSwitchCase="'tags'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let tag of rowData[col.field] || []; let i = index; let firstTag = first; let lastTag = last">
          <div *ngIf="!!firstTag" class="custom-chip" [style]="{ background: util.hexToRgba(tag.color), color: tag.color }">
            <!-- (click)="onClickSelectedTag($event, tag)"> -->
            {{ tag.label }}
          </div>
          <div *ngIf="!firstTag && !!lastTag" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Attack Type -->
    <ng-container *ngSwitchCase="'attack_type'">
      {{ rowData.attack_type ? (rowData.attack_type | constant: 'threat-attack-type') : '-' }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<p-sidebar
  [(visible)]="displayDeviceDetails"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="selectedDevice = null">
  @if (!!selectedDevice && !!displayDeviceDetails) {
    <app-detected-device-details [deviceId]="selectedDevice.id" (hidePanelEvent)="selectedDevice = null"></app-detected-device-details>
  }
</p-sidebar>

<p-sidebar
  [(visible)]="displayThreatDetails"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="selectedThreat = null">
  @if (!!selectedThreat && !!displayThreatDetails) {
    <app-threat-details [threatId]="selectedThreat.threat_id" (hidePanelEvent)="selectedThreat = null"></app-threat-details>
  }
</p-sidebar>
