import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-firmware-details',
  templateUrl: './firmware-details.component.html',
  styleUrls: ['./firmware-details.component.scss'],
})
export class FirmwareDetailsComponent extends BaseComponent {
  @Input() isLoading = false;

  _firmware: any = null;

  get firmware() {
    return this._firmware;
  }

  @Input() set firmware(value: any) {
    this._firmware = value;
  }

  @Output() firmwareChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() actionEvent: EventEmitter<any> = new EventEmitter<any>();

  refreshFirmware() {
    this.actionEvent.emit('refresh');
  }
}
