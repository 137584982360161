@if (!excludeLayoutItemRoutes.includes(currentRoute)) {
  <microsec-common-layout [preContentTemplate]="projectPreContentTemplate">
    <router-outlet />
  </microsec-common-layout>
} @else {
  <router-outlet />
}

<ng-template #projectPreContentTemplate>
  <app-shared-license-warning />
  <app-project-information></app-project-information>
</ng-template>
