<div class="detected-devices">
  <div class="grid">
    <div class="col-12" id="tb">
      <microsec-card [isTableCard]="true">
        <microsec-common-table
          #dt
          [lazy]="true"
          (lazyLoadEvent)="getDevices($any($event))"
          [totalRecords]="totalRecords"
          [cols]="selectedCols"
          [values]="values"
          [selectable]="false"
          [loading]="isLoading"
          [cellTemplate]="cellTemplate"
          [dataKey]="'id'"
          itemName="Device"
          [containerHeaderRightTemplate]="containerHeaderRightTemplate">
        </microsec-common-table>
      </microsec-card>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-table-column-filter [key]="constructor.name" [cols]="COLS" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Status -->
    <ng-container *ngSwitchCase="'status'">
      <span
        class="capitalize"
        [ngClass]="{
          'text-green': rowData.status === 'normal',
          'text-red': rowData.status !== 'normal'
        }"
        >{{ rowData['status'] }}</span
      >
    </ng-container>
    <!-- Interfaces -->
    <ng-container *ngSwitchCase="'interfaces'">
      {{ rowData | detectedDeviceInterfacesPipe }}
    </ng-container>
    <!-- IP Address -->
    <ng-container *ngSwitchCase="'src_ip_addr'">
      <span [pTooltip]="!!rowData[col.field] ? rowData[col.field].tooltip : ''" tooltipPosition="top" appendTo="body">
        {{ !!rowData[col.field] ? rowData[col.field].label : '-' }}
      </span>
    </ng-container>
    <!-- Tags -->
    <ng-container *ngSwitchCase="'tags'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let tag of rowData[col.field] || []; let i = index; let firstTag = first; let lastTag = last">
          <div *ngIf="!!firstTag" class="custom-chip" [style]="{ background: util.hexToRgba(tag.color), color: tag.color }">
            <!-- (click)="onClickSelectedTag($event, tag)"> -->
            {{ tag.label }}
          </div>
          <div *ngIf="!firstTag && !!lastTag" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Zones -->
    <ng-container *ngSwitchCase="'zones'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let zone of rowData[col.field] || []; let i = index; let firstZone = first; let lastZone = last">
          <div *ngIf="!!firstZone" class="custom-chip" [style]="{ background: util.hexToRgba(zone.color), color: zone.color }">
            <!-- (click)="onClickSelectedTag($event, tag)"> -->
            {{ zone.label }}
          </div>
          <div *ngIf="!firstZone && !!lastZone" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Threat Score -->
    <ng-container *ngSwitchCase="'threat_score'">
      <span [ngClass]="{ 'text-red': !!rowData[col.field] }">
        {{ (rowData[col.field] | number: '1.0-0') || '-' }}
      </span>
    </ng-container>
    <!-- date -->
    <ng-container *ngSwitchCase="col.field === 'created' || col.field === 'last_seen' ? col.field : ''">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <!-- boolean -->
    <ng-container *ngSwitchCase="col.field === 'is_imported' || col.field === 'uses_agent' ? col.field : ''">
      <span class="capitalize">{{ rowData[col.field] }}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
