import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  DELETE_LABEL,
  FORCE_DELETE_LABEL,
  FORCE_REMOVE_MESSAGE_TEMPLATE,
  MODULE_CONSTANTS,
  ORGANIZATION_ID_PARAM_ROUTE,
  PROJECT_ID_PARAM_ROUTE,
  USER_ROLE,
} from '@microsec/constants';
import { PROJECT_STATUS_TAGS, QUOTAS_FEATURES } from '@ids-constants';
import { SharedProjectFormComponent } from '../../shared-components/shared-project-form/shared-project-form.component';
import { ProjectService } from '@microsec/services';
import { BaseComponent } from '@ids-components';
import { ArrayToPlaceholderStringPipe, MomentPipe } from '@ids-pipes';
import { ReportSpecification, CommonToolbarResult, CommonToolbarConfiguration, ActionMenuItem, ConfirmationDialogConfig } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';

import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

const FIELDS = {
  name: 'Name',
  description: 'Description',
  country_name: 'Country',
  organization: 'Organization',
  is_active: 'Status',
  created: 'Created On',
  modified: 'Last Modified',
  est_enrolment_type: 'EST Enrollment Type',
  scep_enrolment_type: 'SCEP Enrollment Type',
  device_re_enrol_strategy: 'Device Re-Enroll Strategy',
};

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [MomentPipe, ArrayToPlaceholderStringPipe],
})
export class ProjectsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = true;

  _selectedProject: any = null;

  get selectedProject() {
    return this._selectedProject;
  }

  set selectedProject(value) {
    this._selectedProject = value;
  }

  cols: any[] = [
    { field: 'name', header: FIELDS.name, width: 18 },
    { field: 'is_active', header: FIELDS.is_active, width: 10 },
    { field: 'created', header: FIELDS.created, width: 10 },
  ];

  globalFilterFields: string[] = ['name'];

  actionsMenuItems: ActionMenuItem[] = [];

  values: any[] = [];

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  constructor(
    private momentPipe: MomentPipe,
    private projectSrv: ProjectService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: ({ rowData }) => !!rowData.hasPermission,
        command: ({ rowData }) => this.openProjectForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: ({ rowData }) => !!rowData.hasPermission,
        command: ({ rowData }) => this.openDeleteConfirmation(rowData),
      },
    ];
    this.handleFilterObjUpdate();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.getProjects();
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result.search || '';
      }
    });
  }

  /**
   * Get the list of all projects
   */
  getProjects() {
    this.isLoading = true;
    this.values = [];
    this.projectSrv
      .getProjects(this.breadcrumbConfig?.organizationId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res: any[]) => {
          this.values = res.map((p) => ({ ...p, hasPermission: this.checkPermission(p.user_role, USER_ROLE.ADMIN) }));
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((project) => ({
          ...project,
          is_active: !!project.is_active ? 'Active' : 'Inactive',
          created: this.momentPipe?.transform(project.created),
          modified: this.momentPipe?.transform(project.modified),
          organization: !!project.org_name ? `${project.org_name}(${project.org_id})` : `ID: ${project.org_id}`,
        }));
        this.dt.generateReportDialog.exportReport(data, `organization_${this.breadcrumbConfig?.organizationId}_projects`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  /**
   * Open a form to create or edit project
   * @param project
   */
  openProjectForm(project = null) {
    const dialogRef = this.dialogSrv.open(SharedProjectFormComponent, {
      data: {
        project: !project ? null : project,
      },
      header: `${!project ? 'Create' : 'Edit'} Project`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getProjects();
      }
    });
  }

  openDeleteConfirmation(project: any) {
    // Normal confirmation
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Project',
      object: project,
      objectFieldNameFunction: (p) => `Project ID ${p.id} ${!!p.name ? ` : ${p.name}` : ''}`,
      acceptRequest: this.projectSrv.deleteProject(project.id),
      next: () => {
        this.showSuccessMessage(`Deleted project ${project.name} successfully`);
        this.getProjects();
      },
      error: () => {
        // Force confirmation
        this.confirm({
          isDependenciesDetected: true,
          action: FORCE_DELETE_LABEL,
          customContent: FORCE_REMOVE_MESSAGE_TEMPLATE.replace('{0}', 'project'),
          shouldShowDefaultContent: false,
          acceptRequest: this.projectSrv.deleteProject(project.id, true),
          next: () => {
            this.showSuccessMessage(`Deleted project ${project.name} successfully`);
            this.getProjects();
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        } as ConfirmationDialogConfig);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Get the config for the status label
   * @param status
   * @returns
   */
  getLabelConfig(status: boolean) {
    const config = PROJECT_STATUS_TAGS.find((p) => p.value === status);
    return !!config
      ? config
      : {
          value: '',
          label: '',
          backgroundColor: 'transparent',
          color: 'transparent',
        };
  }

  /**
   * Route to project
   */
  goToProject(projectId: any) {
    this.changeRoute(
      MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(
        ORGANIZATION_ID_PARAM_ROUTE,
        this.breadcrumbConfig?.organizationId?.toString() as string,
      ).replace(PROJECT_ID_PARAM_ROUTE, projectId),
    );
  }

  get checkProjectQuota() {
    return this.values.length < this.getQuotaLimitation(QUOTAS_FEATURES.PROJECTS);
  }
}
