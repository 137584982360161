/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { LDAPSetting } from '@microsec/models';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { ILDAPSettingService } from './ILDAPSettingService';
import groupsData from './mock-data/ldap-groups.json';
import linksData from './mock-data/ldap-links.json';
import settingData from './mock-data/ldap-setting.json';

@Injectable({
  providedIn: 'root',
})
export class MockLdapSettingService implements ILDAPSettingService {
  getLDAPSetting(): Observable<LDAPSetting> {
    return scheduled([settingData as any as LDAPSetting], asyncScheduler);
  }

  save(data: any): Observable<any> {
    return scheduled([{ message: 'Updated LDAP setting successfully' }], asyncScheduler);
  }

  testConnection(data: any): Observable<any> {
    return scheduled([{ message: 'Test connection successfully' }], asyncScheduler);
  }

  testAuthentication(data: any): Observable<any> {
    return scheduled([{ message: 'Test authentication successfully' }], asyncScheduler);
  }

  reset(): Observable<LDAPSetting> {
    return scheduled([{} as LDAPSetting], asyncScheduler);
  }

  getLDAPGroups(): Observable<any> {
    return scheduled([groupsData], asyncScheduler);
  }

  getLDAPGroupLinks(organizationId: number): Observable<any[]> {
    return scheduled([linksData], asyncScheduler);
  }

  createLDAGroupLink(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateLDAGroupLink(ldapGroupLinkId: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteLDAGroupLink(ldapGroupLinkId: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
