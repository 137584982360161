@if (!!visible) {
  <div class="mx-5 my-2">
    <microsec-loading-overlay [isLoading]="!!isLoading">
      <microsec-card [isBordered]="true">
        <div class="grid">
          <div class="col-12">
            <div class="flex align-items-center gap-3">
              <i class="fa fa-suitcase text-5xl"></i>
              <div class="flex flex-column gap-1">
                <label class="text-lg font-bold">Project {{ project?.name || '' }}</label>
                <label class="text-grey">Project ID: {{ project?.id || '' }}</label>
              </div>
            </div>
          </div>
          @if (!!project?.description) {
            <div class="col-12">
              <label>{{ project.description }}</label>
            </div>
          }
          @if (permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
            <div class="col-12">
              <label class="link-blue font-bold" (click)="openProjectForm()">Edit Project Details</label>
            </div>
          }
        </div>
      </microsec-card>
    </microsec-loading-overlay>
  </div>
}
