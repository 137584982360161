import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { COMPLIANCE_STANDARDS, DEFAULT_SECURITY_LEVELS, TAGS_PRESET_COLORS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { TargetDeviceService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';
import { MenuItem } from 'primeng/api';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  LABEL: 'label',
  DESCRIPTION: 'description',
  COLOR: 'color',
  IS_COMPLIANCE: 'is_compliance',
  COMPLIANCE_DETAILS: 'compliance_details',
  COMPLIANCE: 'compliance',
};
const TAB_VALUES = {
  OVERVIEW: 'overview',
  COMPLIANCE: 'compliance',
};

@Component({
  selector: 'app-zone-form',
  templateUrl: './zone-form.component.html',
  styleUrls: ['./zone-form.component.scss'],
})
export class ZoneFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  color = '#0277FF';

  @ViewChild('fb') form!: FormBuilderComponent;

  zone: any = null;

  tabItems: MenuItem[] = [];

  activeTabItem: MenuItem | undefined = undefined;

  securityLevel: any = {};

  complianceDetailsValid = false;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  TAGS_PRESET_COLORS = TAGS_PRESET_COLORS;

  @ViewChild('colorField') colorField!: TemplateRef<any>;

  @ViewChild('complianceDetailsField') complianceDetailsField!: TemplateRef<any>;

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.zone = this.dialogConfig?.data?.zone;
    this.complianceDetailsValid = !!this.zone;
    this.tabItems = [
      { id: TAB_VALUES.OVERVIEW, label: 'Overview', disabled: false },
      { id: TAB_VALUES.COMPLIANCE, label: 'Compliance', disabled: false },
    ];
    this.securityLevel = {
      target:
        this.zone?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_target?.length === 7
          ? this.zone.compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_target
          : DEFAULT_SECURITY_LEVELS,
      capable:
        this.zone?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_capable?.length === 7
          ? this.zone.compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_capable
          : DEFAULT_SECURITY_LEVELS,
    };
    this.activeTabItem = this.tabItems[0];
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const fields: FormItem[] = [
      // -------------------------------- OVERVIEW --------------------------------
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_ID,
        defaultValue: this.breadcrumbConfig?.organizationId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        defaultValue: this.breadcrumbConfig?.projectId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Name',
        required: true,
        fieldInfo: 'Name of the zone',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the zone',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COLOR,
        label: 'Color',
        field: 'custom',
        customField: this.colorField,
        fieldInfo: 'Color of the zone',
        defaultValue: '',
      } as FormItem),
      // -------------------------------- COMPLIANCE --------------------------------
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_COMPLIANCE,
        label: 'Enter Compliance Details',
        field: 'checkbox',
        defaultValue: !!this.zone?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443],
        hidden: this.activeTabItem?.id !== TAB_VALUES.COMPLIANCE || !this.zone?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMPLIANCE_DETAILS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.complianceDetailsField,
        defaultValue: {},
        hidden: this.activeTabItem?.id !== TAB_VALUES.COMPLIANCE || !this.zone?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443],
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.IS_COMPLIANCE, (value: boolean) => {
        this.form.setControlVisibility(FORM_PARAMS.COMPLIANCE_DETAILS, !!value);
      });
    });
    if (!!this.zone) {
      this.form.patchValue(this.zone);
      this.color = this.zone[FORM_PARAMS.COLOR] || '#0277FF';
    }
  }

  changeTab(tabItem: MenuItem = this.tabItems[0]) {
    const tabValue = tabItem.id;
    this.form.isLoading = true;
    this.form.setControlVisibility('form_title', tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.LABEL, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.DESCRIPTION, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.COLOR, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.IS_COMPLIANCE, tabValue === TAB_VALUES.COMPLIANCE);
    this.form.setControlVisibility(
      FORM_PARAMS.COMPLIANCE_DETAILS,
      tabValue === TAB_VALUES.COMPLIANCE && !!this.form.getControlValue(FORM_PARAMS.IS_COMPLIANCE),
    );
    setTimeout(() => {
      this.form.isLoading = false;
    }, 10);
  }

  submit(closeDialog: () => void) {
    this.form.isLoading = true;
    // Overview
    const payload = {
      ...this.form.getRawValue(),
      [FORM_PARAMS.COLOR]: this.color,
    };
    // Compliance
    if (!!payload[FORM_PARAMS.IS_COMPLIANCE]) {
      payload[FORM_PARAMS.COMPLIANCE] = {
        [COMPLIANCE_STANDARDS.IEC_62443]: {
          security_level_target: this.securityLevel.target,
          security_level_capable: this.securityLevel.capable,
        },
      };
    }
    delete payload[FORM_PARAMS.IS_COMPLIANCE];
    delete payload[FORM_PARAMS.COMPLIANCE_DETAILS];
    const request: Observable<any> = !this.zone ? this.targetDeviceSrv.createZone(payload) : this.targetDeviceSrv.updateZone(this.zone.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.zone ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'zone'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
