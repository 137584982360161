<div class="grid form">
  <div class="col-12">
    <app-fl-analyzer-progress [analyzer]="analyzer"></app-fl-analyzer-progress>
  </div>
  <div class="col-12">
    <microsec-card [isNestedCard]="true">
      <div class="mb-3">
        <label class="font-bold">Analyzer Details</label>
      </div>
      <div class="list-fields mx-3">
        <div class="grid align-items-center">
          <label class="col-4">Enabled</label>
          <div class="col-8">
            <p-inputSwitch [(ngModel)]="analyzer.enabled" [disabled]="true"></p-inputSwitch>
          </div>
        </div>
        @if (!!analyzer?.mode) {
          <div class="grid align-items-center">
            <label class="col-4">Mode</label>
            <div class="col-8">
              <p-dropdown
                styleClass="w-10rem"
                appendTo="body"
                [options]="MODE_OPTIONS"
                optionLabel="label"
                optionValue="value"
                [(ngModel)]="analyzer.mode"
                [disabled]="true">
              </p-dropdown>
            </div>
          </div>
        }
        @if (!!analyzer?.name) {
          <div class="grid align-items-center">
            <label class="col-4">Name</label>
            <label class="col-8 text-data overflow-hidden white-space-nowrap text-overflow-ellipsis">{{ analyzer?.name }}</label>
          </div>
        }
        @if (!!analyzer?.description) {
          <div class="grid align-items-center">
            <label class="col-4">Description</label>
            <label class="col-8 text-data">{{ analyzer.description }}</label>
          </div>
        }
        @if (!!analyzer?.connectionNames?.length) {
          <div class="grid align-items-center">
            <label class="col-4">Connection Name(s)</label>
            <label class="col-8 text-data">{{ analyzer.connectionNames.join(', ') }}</label>
          </div>
        }
        @if (!!analyzer?.protocolNames) {
          <div class="grid align-items-center">
            <label class="col-4">Protocol(s)</label>
            <label class="col-8 text-data">{{ analyzer.protocolNames | constant: 'anomaly-analyzer-protocol' : true }}</label>
          </div>
        }
        @if (!!analyzer?.attackTypeNames) {
          <div class="grid align-items-center">
            <label class="col-4">Attack Type(s)</label>
            <label class="col-8 text-data">{{ analyzer.attackTypeNames | constant: 'anomaly-analyzer-attack' : true }}</label>
          </div>
        }
        @if (!!analyzer?.time_started) {
          <div class="grid align-items-center">
            <label class="col-4">Time Started</label>
            <label class="col-8 text-data">{{ analyzer.time_started | momentPipe }}</label>
          </div>
        }
      </div>
    </microsec-card>
  </div>
</div>
