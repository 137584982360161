import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AssessmentService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

export const FORM_PARAMS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
};

@Component({
  selector: 'app-assessment-mitigation-form',
  templateUrl: './assessment-mitigation-form.component.html',
  styleUrls: ['./assessment-mitigation-form.component.scss'],
})
export class AssessmentMitigationFormComponent extends BaseComponent implements AfterViewInit {
  mitigation: any = null;

  mitigations: any[] = [];

  questionId: any = null;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;
  rule: any;

  constructor(
    private assessmentSrv: AssessmentService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.prepareConfigs();
    this.mitigation = this.dialogConfig?.data?.mitigation;
    this.mitigations = this.dialogConfig?.data?.mitigations || [];
    this.questionId = this.dialogConfig?.data?.questionId;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TITLE,
        label: 'Title',
        required: true,
        focused: true,
        fieldInfo: 'Title of the mitigation',
        defaultValue: this.mitigation?.[FORM_PARAMS.TITLE] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        maxInputLimit: 1200,
        fieldInfo: 'Description of the mitigation',
        defaultValue: this.mitigation?.[FORM_PARAMS.DESCRIPTION] || '',
      } as FormItem),
    ];
    this.fields = fields;
  }

  onSubmit(closeDialog: (rs: any) => void) {
    this.form.isLoading = true;
    const mitigation = this.form.getRawValue();

    if (this.questionId) {
      const payload = {
        mitigations: [...this.mitigations, mitigation],
      };
      this.assessmentSrv
        .updateAssessmentQuestion(this.questionId, payload)
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage('Add Mitigation Succeed');
            closeDialog(true);
          },
          error: (err: any) => {
            this.form.showServerErrorMessage(err);
            this.showErrorMessage(err);
          },
        });
    } else {
      closeDialog(mitigation);
    }
  }
}
