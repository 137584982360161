import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ObjectPickerHelper } from '@ids-utilities';
import { RoleService, UserService } from '@microsec/services';
import { SCOPE } from '@microsec/constants';
import { ASSIGN_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { FormBuilderComponent } from '@microsec/components';
import { ProjectService } from '@microsec/services';

import { catchError, finalize, switchMap } from 'rxjs/operators';
import { Observable, asyncScheduler, forkJoin, scheduled } from 'rxjs';

const FORM_PARAMS = {
  USERS: 'users',
  ADD_TO_PROJECTS: 'addToProjects',
  PROJECTS: 'projects',
  ROLE: 'role',
};

@Component({
  selector: 'app-user-access-form',
  templateUrl: './user-access-form.component.html',
  styleUrls: ['./user-access-form.component.scss'],
})
export class UserAccessFormComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  ASSIGN_LABEL = ASSIGN_LABEL;

  searchScope = '';

  projects: any[] = [];

  constructor(
    private projectSrv: ProjectService,
    private userSrv: UserService,
    private roleSrv: RoleService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
    this.getRoles();
    if (this.currentScope === SCOPE.ORGANIZATION) {
      this.getProjects();
    }
  }

  /**
   * Init the form controls
   */
  initForm() {
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        this.searchScope = SCOPE.GLOBAL;
        break;
      }
      case SCOPE.PROJECT: {
        this.searchScope = SCOPE.ORGANIZATION;
        break;
      }
      default: {
        break;
      }
    }
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USERS,
        label: 'Users',
        field: 'objectpicker',
        objectPickerSearchScope: this.searchScope,
        objectPickerTypes: ['user'],
        includeAssignedUsers: false,
        excludeAssignedUsersOnProject: this.breadcrumbConfig?.projectId,
        objectPickerRequestsFunction: ObjectPickerHelper.getObjectPickerRequestsFunction,
        placeholder: 'Search users ...',
        showRequiredMark: true,
        fieldInfo: 'Type at least 3 characters to search users',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ROLE,
        label: 'Role',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a role',
        required: this.currentScope === SCOPE.ORGANIZATION,
        fieldInfo: 'User role',
        hidden: this.currentScope !== SCOPE.ORGANIZATION,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ADD_TO_PROJECTS,
        hasNoLabel: true,
        checkboxLabel: 'Add user(s) to Project(s)?',
        field: 'checkbox',
        defaultValue: false,
        hidden: this.currentScope !== SCOPE.ORGANIZATION,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECTS,
        label: 'Projects',
        field: 'multiselect',
        options: [] as any[],
        placeholder: 'Select projects',
        fieldInfo: 'Projects',
        defaultValue: [],
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;

    this.form.setChangeEvent(FORM_PARAMS.ADD_TO_PROJECTS, (valueChanged: any) => {
      this.form.setControlVisibility(FORM_PARAMS.PROJECTS, !!valueChanged);
    });
  }

  /**
   * Get the list of projects
   */
  getProjects() {
    this.projectSrv.getProjects(this.breadcrumbConfig?.organizationId as number).subscribe({
      next: (res: any[]) => {
        const projectField = this.fields.find((p) => p.name === FORM_PARAMS.PROJECTS);
        if (!!projectField) {
          projectField.options = this.util.sortObjectArray(
            res.map((p) => ({
              value: p.id,
              label: p.name,
            })),
            'name',
          );
        }
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Get the list of roles
   */
  getRoles() {
    let request: Observable<any[]>;
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        request = this.roleSrv.getOrganizationRoles(this.breadcrumbConfig?.organizationId);
        break;
      }
      case SCOPE.PROJECT: {
        request = this.roleSrv.getProjectRoles(this.breadcrumbConfig?.projectId);
        break;
      }
      default: {
        request = scheduled([[]], asyncScheduler);
        break;
      }
    }
    request.subscribe({
      next: (rs: any[]) => {
        const roleField = this.fields.find((p) => p.name === FORM_PARAMS.ROLE);
        if (!!roleField) {
          const roles = rs.map((role) => ({
            value: role.id,
            label: role.name,
            level: role.level,
          }));
          roleField.options = this.util.sortObjectArray(roles, 'level', false);
        }
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Submit the form data
   * @param closeDialog
   */
  onSubmit(closeDialog: (result: any) => void) {
    const formData = { ...this.form.getRawValue() };
    const requests: Observable<any>[] = [];
    let parentId: any = null;
    const payload: any = { roleId: formData[FORM_PARAMS.ROLE] };
    switch (this.currentScope) {
      case SCOPE.ORGANIZATION: {
        parentId = this.breadcrumbConfig?.organizationId;
        break;
      }
      case SCOPE.PROJECT: {
        parentId = this.breadcrumbConfig?.projectId;
        payload.is_assigned = true;
        break;
      }
      default: {
        break;
      }
    }
    formData[FORM_PARAMS.USERS].forEach((user: any) => {
      requests.push(
        this.userSrv
          .assignUserToScope(this.currentScope, parentId, user.id, payload)
          .pipe(catchError((err) => scheduled([{ error: err }], asyncScheduler))),
      );
    });
    this.assignUserToScope(requests, formData, closeDialog);
  }

  /**
   * Assign users to scope (organization / project)
   * @param requests
   * @param formData
   * @param closeDialog
   */
  private assignUserToScope(requests: Observable<any>[], formData: any, closeDialog: (result: any) => void) {
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = forkJoin(requests)
      .pipe(
        switchMap((results: any[]) => {
          results.forEach((rs: any, index: number) => {
            if (!!rs?.error) {
              this.showErrorMessage(rs?.error);
            } else {
              this.showSuccessMessage(
                `Assigned user ${formData[FORM_PARAMS.USERS][index].username}` + ` to current ${this.currentScope} successfully.`,
              );
            }
          });
          // Assign to projects
          if (!!this.form.getControlValue(FORM_PARAMS.ADD_TO_PROJECTS)) {
            const requests2: Observable<any>[] = [];
            formData[FORM_PARAMS.USERS].forEach((user: any) => {
              requests2.push(
                this.userSrv
                  .assignUserToScope(SCOPE.PROJECT, null, user.id, {
                    [FORM_PARAMS.PROJECTS]: formData[FORM_PARAMS.PROJECTS],
                  })
                  .pipe(catchError((err) => scheduled([{ error: err }], asyncScheduler))),
              );
            });
            return forkJoin(requests2);
          }
          return scheduled([true], asyncScheduler);
        }),
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (results: any[] | boolean) => {
          if (typeof results !== 'boolean') {
            let hasError = false;
            results.forEach((rs: any, index: number) => {
              if (!!rs?.errors) {
                hasError = true;
                this.showErrorMessage(rs?.errors);
              } else {
                this.showSuccessMessage(`Assigned user ${formData[FORM_PARAMS.USERS][index].username} to the selected projects successfully.`);
              }
            });
            if (!hasError) {
              closeDialog(true);
            }
          } else {
            closeDialog(true);
          }
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Additional condition: check object picker value separately
   */
  get checkValidFormValue() {
    return !!this.form?.getControlValue(FORM_PARAMS.USERS)?.length;
  }
}
