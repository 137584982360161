import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@ids-constants';
import { SharedProjectFormComponent } from '@ids-products';
import { MODULE_CONSTANTS, ORGANIZATION_ID_PARAM_ROUTE, PROJECT_ID_PARAM_ROUTE, PROJECT_ONBOARDING_UNFINISHED, USER_ROLE } from '@microsec/constants';
import { layoutSelectors } from '@microsec/ngrx-layout';
import { ProjectService } from '@microsec/services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-project-information',
  templateUrl: './project-information.component.html',
  styleUrls: ['./project-information.component.scss'],
})
export class ProjectInformationComponent extends BaseComponent implements OnInit {
  visible = false;

  isLoading = true;

  project: any = null;

  constructor(private projectSrv: ProjectService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true);
    const isOnboardingUnfinished = JSON.parse(
      localStorage.getItem(`${PROJECT_ONBOARDING_UNFINISHED}_${this.breadcrumbConfig?.organizationId}_${this.breadcrumbConfig?.projectId}`) ||
        'false',
    );
    if (!isOnboardingUnfinished) {
      const projectManagementRoutePrefix = `${MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(
        ORGANIZATION_ID_PARAM_ROUTE,
        this.breadcrumbConfig?.organizationId?.toString(),
      ).replace(PROJECT_ID_PARAM_ROUTE, this.breadcrumbConfig?.projectId?.toString())}/`;
      this.store.select(layoutSelectors.selectUrl).subscribe((selectUrl) => {
        const currentRoute = selectUrl.split(projectManagementRoutePrefix)[1];
        const parentRoute = currentRoute?.split('/')?.[0] || '';
        this.visible = parentRoute === PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE;
        if (!!this.visible) {
          this.getProject();
        }
      });
    }
  }

  getProject() {
    this.isLoading = true;
    const projectId = this.breadcrumbConfig?.projectId as number;
    this.projectSrv
      .getProject(projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.project = rs;
        },
      });
  }

  openProjectForm() {
    const dialogRef = this.dialogSrv.open(SharedProjectFormComponent, {
      data: {
        project: this.project,
      },
      header: `Edit Project`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getProject();
      }
    });
  }
}
