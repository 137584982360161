import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-qr-code-scan',
  templateUrl: './qr-code-scan.component.html',
  styleUrls: ['./qr-code-scan.component.scss'],
})
export class QrCodeScanComponent {
  @Input() qrData: any;

  @Output() changeStepEvent: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Change step
   * @param step
   */
  changeStep(step = 1) {
    this.changeStepEvent.emit(step);
  }
}
