/* eslint-disable @typescript-eslint/no-unused-vars */
import { ASSESSMENT_RESULTS, ASSESSMENT_RESULT_COLORS } from '@ids-constants';
import { Util } from '@microsec/utilities';
import { CommonChart } from '@microsec/models';

import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { COMMON_CHART_CONFIG } from '@microsec/constants';

function chunkString(str: string, maxWidth: number) {
  const sections = [];
  const words = str.split(' ');
  let builder = '';

  for (const word of words) {
    if (word.length > maxWidth) {
      sections.push(builder.trim());
      builder = '';
      sections.push(word.trim());
      continue;
    }

    const temp = `${builder} ${word}`;
    if (temp.length > maxWidth) {
      sections.push(builder.trim());
      builder = word;
      continue;
    }

    builder = temp;
  }
  sections.push(builder.trim());

  return sections;
}

const titleFont = {
  size: 14,
};

const JITTER_TICK_PLUGIN: Plugin = {
  id: 'JITTER_TICK_PLUGIN',
  beforeDatasetDraw: (chart, args, options) => {
    const { index: dataIndex, meta } = args;
    const points = meta.data.map((el) => ({ x: el.x, y: el.y }));
    const { length: dsLength } = chart.data.datasets;
    const adjustedMap: any[] = [];
    for (let datasetIndex = 0; datasetIndex < dsLength; datasetIndex += 1) {
      if (dataIndex !== datasetIndex) {
        const datasetMeta = chart.getDatasetMeta(datasetIndex);
        datasetMeta.data.forEach((el: any) => {
          const overlap = points.find((point) => point.x === el.x && point.y === el.y);
          if (overlap) {
            const adjusted = adjustedMap.find((item) => item.datasetIndex === datasetIndex && item.dataIndex === dataIndex);
            if (!adjusted && datasetIndex % 2) {
              el.x += 5;
            } else {
              el.x -= 5;
            }
            adjustedMap.push({ datasetIndex, dataIndex });
          }
        });
      }
    }
  },
};

const DEVICES_BY_CRITICALITY_COMPLIANCE_CHART_OPTIONS: ChartOptions = {
  animation: false,
  layout: {
    padding: {
      bottom: 30,
      right: 30,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (chart) => {
          const text: string[] = [];
          const data: any = chart?.[0]?.raw;
          if (chart?.[0]?.raw) {
            text.push(`Criticality: ${data.y || 0}`);
            text.push(`Compliance Score: ${data.x || 0}%`);
          }
          text.push(`Number of Devices: ${chart?.length || 0}`);
          return text;
        },
        label: (): string => '',
      },
    },
  },
  scales: {
    x: {
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
      reverse: true,
      beginAtZero: true,
      afterDataLimits: (scale) => {
        scale.max = 100;
        scale.min = 0;
      },
      ticks: {
        display: false,
        stepSize: 10,
      },
    },
    y: {
      title: {
        display: true,
        font: titleFont,
        text: 'Criticality',
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
      beginAtZero: true,
      afterDataLimits: (scale) => {
        scale.max = 10;
        scale.min = 0;
      },
      ticks: {
        stepSize: 1,
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
    },
  },
};

const THREATS_BY_SCORE_CHART_OPTIONS: ChartOptions = {
  responsive: false,
  maintainAspectRatio: true,
  animation: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (chart) => {
          const text: string[] = [];
          const data: any = chart?.[0]?.raw;
          if (chart?.[0]?.raw) {
            text.push(`Likelihood: ${data.y || 0}/10`);
            text.push(`Severity: ${data.x || 0}/10`);
          }
          text.push(`Number of Threats: ${chart?.length || 0}`);
          return text;
        },
        label: (): string => '',
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        font: titleFont,
        text: 'Severity',
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        // borderWidth: 0,
        tickLength: 0,
      },
      beginAtZero: true,
      afterDataLimits: (scale) => {
        scale.max = 10;
        scale.min = 0;
      },
      ticks: {
        display: false,
        stepSize: 1,
      },
    },
    y: {
      title: {
        display: true,
        font: titleFont,
        text: 'Likelihood',
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        // borderWidth: 0,
        tickLength: 0,
      },
      beginAtZero: true,
      afterDataLimits: (scale) => {
        scale.max = 10;
        scale.min = 0;
      },
      ticks: {
        display: false,
        stepSize: 1,
      },
    },
  },
};

const THREATS_BY_ATTACK_TYPE_CHART_OPTIONS: ChartOptions = {
  responsive: false,
  maintainAspectRatio: false,
  animation: false,
  layout: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: () => '',
        label: (context) => `${context.label}: ${context.parsed.r}`,
      },
    },
  },
  scales: {
    r: {
      ticks: {
        display: false,
      },
      pointLabels: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        font: {
          size: 10,
        },
        callback(label): string[] {
          return chunkString(label, 25);
        },
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
      angleLines: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};

const MITIGATIONS_CHART_OPTIONS: ChartOptions = {
  animation: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: () => '',
        label: (context) => chunkString(`${context.label}: ${context.parsed.y}`, 30),
      },
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        font: {
          size: 10,
        },
        callback(value, index): string[] {
          let text: string[] = [];
          if (typeof value === 'number') {
            const maxWidth = 15;
            const label = this.getLabelForValue(value);
            const words = label
              .substring(0, maxWidth - 3)
              .trim()
              .split(' ');
            if (label.length > maxWidth - 3) {
              words[words.length - 1] = words[words.length - 1] + '...';
            }
            text = words;
          }
          return text;
        },
      },
    },
    y: {
      title: {
        display: true,
        font: titleFont,
        text: 'No. of Devices',
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
      ticks: {
        stepSize: 1,
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      beginAtZero: true,
    },
  },
};

const TIMELINE_CHART_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      afterBuildTicks: (axis) => {
        if (!!axis) {
          const max = axis.max;
          const steps = axis.max <= 5 ? 1 : Math.ceil(max / (max <= 50 ? 5 : 10) / 5) * 5;
          const ticks = [];
          for (let i = 0; i <= max; i += steps) {
            ticks.push({ value: i });
          }
          axis.ticks = ticks;
        }
        return;
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};

const getTimelineDevicesChartOptions = (): ChartOptions => {
  const options = Util.cloneDeepObject(TIMELINE_CHART_OPTIONS);
  options.scales.y.title = {
    display: true,
    font: titleFont,
    text: 'No. of Assessed Devices',
    color: COMMON_CHART_CONFIG.FONT_COLOR,
  };
  return options;
};

const getTimelineScoreChartOptions = (): ChartOptions => {
  const options = Util.cloneDeepObject(TIMELINE_CHART_OPTIONS);
  options.scales.y.title = {
    display: true,
    font: titleFont,
    text: 'Compliance Scores',
    color: COMMON_CHART_CONFIG.FONT_COLOR,
  };
  options.scales.y.afterDataLimits = (scale: any) => {
    scale.max = 100;
    scale.min = 0;
  };
  return options;
};

export function getGradientBackgroundColor(ctx: any, color: string) {
  const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
  const rgb = Util.hexToRgb(color);
  gradient.addColorStop(0, color);
  gradient.addColorStop(0.5, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.4)`);
  gradient.addColorStop(1, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0)`);
  return gradient;
}

export const CHART_KEYS = {
  DEVICES_CRITICALITY_COMPLIANCE: 'DEVICES_CRITICALITY_COMPLIANCE',
  THREATS_BY_SCORE: 'THREATS_BY_SCORE',
  THREATS_BY_NON_COMPLIANCE_CATEGORY: 'THREATS_BY_NON_COMPLIANCE_CATEGORY',
  MITIGATIONS: 'MITIGATIONS',
  TIMELINE_DEVICES: 'TIMELINE_DEVICES',
  TIMELINE_SCORES: 'TIMELINE_SCORES',
};

export const CHARTS: { [key: string]: CommonChart } = {
  [CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE]: {
    group: 'devices',
    plugins: [
      {
        id: 'subLabels',
        afterDatasetsDraw: (chart, args, pluginOptions) => {
          const {
            ctx,
            chartArea: { left, right, top, bottom, width, height },
          } = chart;

          ctx.save();

          subLabelText('Compliant', ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.COMPLIANT], 'center', 0, 0);
          subLabelText('Partially Compliant', ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT], 'center', width / 2, 0);
          subLabelText('Not', ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.NOT_COMPLIANT], 'center', width, 0);
          subLabelText('Compliant', ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.NOT_COMPLIANT], 'center', width, 10);

          function subLabelText(text: any, color: any, textAlign: any, x: any, y: any) {
            ctx.fillStyle = color;
            ctx.textAlign = textAlign;
            ctx.fillText(text, x + left, bottom + 25 + y);
          }
        },
      },
    ],
    type: 'bubble',
    key: CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE,
    label: 'Device Distribution by Criticality/Compliance Score',
    data: {} as ChartData,
    options: DEVICES_BY_CRITICALITY_COMPLIANCE_CHART_OPTIONS,
  },
  [CHART_KEYS.THREATS_BY_SCORE]: {
    group: 'threats',
    plugins: [
      {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, args, options) => {
          const {
            ctx,
            chartArea: { left, right, top, bottom, width, height },
          } = chart;
          ctx.save();
          const compliantColor = Util.hexToRgba(ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.COMPLIANT]);
          const partiallyCompliantColor = Util.hexToRgba(ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT]);
          const notCompliantColor = Util.hexToRgba(ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.NOT_COMPLIANT]);

          const gradientColor = ctx.createLinearGradient(right, top, left, bottom * 3.3);
          gradientColor.addColorStop(0, notCompliantColor);
          gradientColor.addColorStop(0.15, notCompliantColor);
          gradientColor.addColorStop(0.15, partiallyCompliantColor);
          gradientColor.addColorStop(0.35, partiallyCompliantColor);
          gradientColor.addColorStop(0.35, compliantColor);
          gradientColor.addColorStop(1, compliantColor);

          ctx.fillStyle = gradientColor;
          ctx.fillRect(left, top, width, height);
          ctx.restore();
        },
      },
    ],
    type: 'bubble',
    key: CHART_KEYS.THREATS_BY_SCORE,
    label: 'Threat Assessment',
    data: {} as ChartData,
    options: THREATS_BY_SCORE_CHART_OPTIONS,
  },
  [CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY]: {
    group: 'threats',
    type: 'radar',
    key: CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY,
    label: 'Top Non Compliant Category',
    data: {} as ChartData,
    options: THREATS_BY_ATTACK_TYPE_CHART_OPTIONS,
  },
  [CHART_KEYS.MITIGATIONS]: {
    group: 'devices',
    type: 'bar',
    key: CHART_KEYS.MITIGATIONS,
    label: 'Top Open Mitigation(s)',
    data: {} as ChartData,
    options: MITIGATIONS_CHART_OPTIONS,
  },
  [CHART_KEYS.TIMELINE_SCORES]: {
    group: 'devices',
    type: 'line',
    key: CHART_KEYS.TIMELINE_SCORES,
    label: 'Compliance Timeline',
    data: {} as ChartData,
    options: getTimelineScoreChartOptions(),
  },
  [CHART_KEYS.TIMELINE_DEVICES]: {
    group: 'devices',
    type: 'line',
    key: CHART_KEYS.TIMELINE_DEVICES,
    label: 'No. of Assessed Devices',
    data: {} as ChartData,
    options: getTimelineDevicesChartOptions(),
  },
};
