<div class="grid">
  <!-- Table Toolbar: Button? Search, Filter, Clear -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="leftTemplate"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button [label]="'Add Token'" [rounded]="true" icon="pi pi-plus" (onClick)="openAccessTokenFormDialog()"> </p-button>
    </ng-template>
  </div>

  <!-- Table -->
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="Token"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getTokens()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <ng-container *ngSwitchCase="'expiry_date'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <ng-container *ngSwitchCase="'created'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <ng-container *ngSwitchCase="'last_used'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
