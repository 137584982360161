import { Component, Input } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { MODE_OPTIONS } from '@ids-constants';

@Component({
  selector: 'app-fl-analyzer-overview',
  templateUrl: './fl-analyzer-overview.component.html',
  styleUrls: ['./fl-analyzer-overview.component.scss'],
})
export class FlAnalyzerOverviewComponent extends BaseComponent {
  @Input() analyzer: any = {};

  MODE_OPTIONS = MODE_OPTIONS;

  constructor() {
    super();
  }
}
