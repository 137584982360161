import { Component, OnInit } from '@angular/core';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@ids-components';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  constructor(private confirmSrv: ConfirmationService) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(false));
    this.cleanupSession();
  }

  /**
   * Clean up all dialogs, confirmations, ... from the previous sessions
   */
  cleanupSession() {
    // Dialogs
    const dialogs = document.getElementsByTagName('p-dynamicdialog');
    if (!!dialogs && !!dialogs.length) {
      for (let index = 0; index < dialogs.length; index++) {
        const dialog = dialogs[index];
        dialog.remove();
      }
    }
    // Confirmation dialogs
    this.confirmSrv.close();
    // Remove stopping scroll overlay class
    document.body.className = '';
  }
}
