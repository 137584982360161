<div class="grid">
  <label class="col-12 text-data text-center mt-5">
    With 2FA enabled, you will need these backup verification codes in case you lose your device.
    <br />
    We recommend you store them in a safe place.
  </label>

  <div class="col-12 text-center mb-5">
    <div class="grid">
      <div class="col-6 code-block" [ngClass]="{ 'text-right': i % 2 === 0, 'text-left': i % 2 !== 0 }" *ngFor="let code of codes; let i = index">
        <span class="line-height-3">{{ code }}</span>
      </div>
      <div class="col-12">
        <p-button [autofocus]="true" styleClass="mt-3" (click)="downloadPdf()" label="Download (PDF)" />
      </div>
    </div>
  </div>

  <div class="col-4 text-left">
    <p-button severity="secondary" (onClick)="changeStep(-1)" label="Back" />
  </div>
  <label class="col-4 text-data text-center" style="line-height: 2.5rem"> Step 3/3 </label>
  <div class="col-4 text-right">
    <p-button severity="success" (onClick)="changeStep(1)" label="Next" />
  </div>
</div>
