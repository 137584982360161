<div class="container-border">
  <microsec-loading-overlay [isLoading]="!!isLoading">
    <microsec-selected-panel-header
      [id]="zone.id"
      [label]="zone.label"
      (closeSelectedPanelEvent)="(hideDetailsEvent.emit)"
      [rightTemplate]="rightTemplate"></microsec-selected-panel-header>
    <ng-template #rightTemplate>
      <p-button
        severity="primary"
        icon="fas fa-edit"
        pTooltip="Edit"
        tooltipPosition="bottom"
        appendTo="body"
        (onClick)="openFormEvent.emit(zone)"
        *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
      </p-button>
    </ng-template>
    <microsec-card [isDetailCard]="true">
      <p-tabView styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <app-zone-overview [zone]="zone" />
        </p-tabPanel>
        <p-tabPanel header="Compliance">
          <app-zone-compliance [zone]="zone" />
        </p-tabPanel>
        <p-tabPanel header="Devices">
          <app-zone-devices [zone]="zone" />
        </p-tabPanel>
        <p-tabPanel header="Attachments">
          <app-zone-attachments [zone]="zone" (refreshListEvent)="refreshListEvent.emit()" />
        </p-tabPanel>
      </p-tabView>
    </microsec-card>
  </microsec-loading-overlay>
</div>
