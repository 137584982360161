<div class="flex flex-column p-4">
  <div class="flex text-data mb-4">Select devices to import into network map editor.</div>
  <div class="flex">
    <div class="grid">
      <div class="col-12">
        <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" />
      </div>

      <div class="col-12 overflow-x-auto" id="tb" style="max-width: calc(800px - 2rem)">
        <microsec-card [isTableCard]="true">
          <microsec-common-table
            #dt
            [lazy]="true"
            (lazyLoadEvent)="getDevices(false, $any($event))"
            [totalRecords]="totalRecords"
            [cols]="selectedCols"
            [values]="values"
            selectionMode="multiple"
            [selectable]="true"
            selectableRowDisabledKey="disabled"
            [(selection)]="selectedDevices"
            [loading]="isLoading"
            [cellTemplate]="cellTemplate"
            [dataKey]="'id'"
            itemName="Device"
            [containerHeaderRightTemplate]="containerHeaderRightTemplate"
            [scrollHeight]="'35vh'">
          </microsec-common-table>
        </microsec-card>
      </div>
    </div>
  </div>
  <div class="flex w-full justify-content-end mt-3">
    <p-button label="Cancel" severity="secondary" (onClick)="submit(false)" />
    <p-button label="Import" severity="primary" styleClass="ml-2" (onClick)="submit(true)" [disabled]="!selectedDevices.length" />
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getDevices(false)"></microsec-refresh-button>
    <microsec-table-column-filter [key]="constructor.name" [cols]="COLS" [(selectedColFields)]="selectedColFields"> </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- IP Address -->
    <ng-container *ngSwitchCase="'src_ip_addr'">
      <span [pTooltip]="!!rowData[col.field] ? rowData[col.field].tooltip : ''" tooltipPosition="top" appendTo="body">
        {{ !!rowData[col.field] ? rowData[col.field].label : '-' }}
      </span>
    </ng-container>
    <!-- Tags -->
    <ng-container *ngSwitchCase="'tags'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let tag of rowData[col.field] || []; let i = index; let firstTag = first; let lastTag = last">
          <div *ngIf="!!firstTag" class="custom-chip" [style]="{ background: util.hexToRgba(tag.color), color: tag.color }">
            {{ tag.label }}
          </div>
          <div *ngIf="!firstTag && !!lastTag" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Zones -->
    <ng-container *ngSwitchCase="'zones'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let zone of rowData[col.field] || []; let i = index; let firstZone = first; let lastZone = last">
          <div *ngIf="!!firstZone" class="custom-chip" [style]="{ background: util.hexToRgba(zone.color), color: zone.color }">
            {{ zone.label }}
          </div>
          <div *ngIf="!firstZone && !!lastZone" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
