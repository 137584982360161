import { Graph } from '@maxgraph/core';

export class CommonMaxgraph {
  /**
   * Loading indicator's status
   */
  isLoading = false;

  /**
   * Loading message
   */
  loadingMessage = '';

  /**
   * Children loading statuses
   */
  subLoadings: any[] = [];

  /**
   * ================================= GRAPH =================================
   */
  graphElement!: HTMLElement;

  graphCanvas!: HTMLCanvasElement;

  _graph: Graph | null = null;

  get graph() {
    return this._graph;
  }

  set graph(value: Graph | null) {
    this._graph = value;
  }

  graphCallback: (() => void) | null = null;

  /**
   * ================================= HELPER =================================
   */

  /**
   * Show loader
   * @param callback
   */
  showLoader(callback: () => void) {
    this.isLoading = true;
    setTimeout(() => {
      callback();
      this.isLoading = false;
    }, 200);
  }

  /**
   * Focus graph
   */
  focusGraph() {
    // Fix Key handler focus issue: https://jgraph.github.io/mxgraph/docs/known-issues.html#Focus
    this.graph?.container?.setAttribute('tabindex', '-1');
    this.graph?.container?.focus();
  }

  /**
   * Clear all objects on the graph
   */
  clearGraph() {
    this.graph?.removeCells();
  }

  /**
   * Refresh graph
   */
  refreshGraph() {
    this.graph?.sizeDidChange();
    this.graph?.view.refresh();
    this.graph?.refresh();
    this.focusGraph();
  }
}
