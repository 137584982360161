import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { NUMBER_MAX_LENGTH } from '@microsec/constants';
import { FormItem } from '@microsec/models';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export const FORM_PARAMS = {
  INTERVAL: 'interval',
  TIME: 'time',
  UNIT: 'unit',
};

@Component({
  selector: 'app-refresh-interval-form',
  templateUrl: './refresh-interval-form.component.html',
  styleUrls: ['./refresh-interval-form.component.scss'],
})
export class RefreshIntervalFormComponent extends BaseComponent implements AfterViewInit {
  interval: any = {};

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  unitOptions: any[] = [
    { label: 'Seconds', value: 's' },
    { label: 'Minutes', value: 'm' },
    { label: 'Hours', value: 'h' },
  ];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('intervalTemplate') intervalTemplate!: TemplateRef<any>;

  NUMBER_MAX_LENGTH = NUMBER_MAX_LENGTH;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private fb: FormBuilder,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.interval = this.dialogConfig?.data?.interval;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.INTERVAL,
        label: 'Refresh Interval',
        field: 'custom',
        customField: this.intervalTemplate,
        fieldInfo: 'Refresh Interval',
        defaultValue: {},
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.form?.setControl(
        FORM_PARAMS.INTERVAL,
        this.fb.group({
          [FORM_PARAMS.TIME]: [this.interval?.time, Validators.required],
          [FORM_PARAMS.UNIT]: [this.interval?.unit, Validators.required],
        }),
      );
    });
  }

  onSubmit(closeDialog: (result: any) => void) {
    closeDialog(this.form.getRawValue());
  }
}
