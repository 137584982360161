import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tag-overview',
  templateUrl: './tag-overview.component.html',
  styleUrls: ['./tag-overview.component.scss'],
})
export class TagOverviewComponent {
  @Input() tag: any = null;
}
