import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MODULE_CONSTANTS,
  ORGANIZATION_ID_PARAM_ROUTE,
  PROJECT_ID_PARAM_ROUTE,
  PROJECT_ONBOARDING_UNFINISHED,
  SYSTEM_ONBOARDING_UNFINISHED,
  LATEST_BREADCRUMB_CONFIG,
  SCOPE,
} from '@microsec/constants';
import { FeatureService, OrganizationService, ProjectService } from '@microsec/services';
import { authenticationSelectors, fromAuthenticationActions } from '@microsec/ngrx-authentication';

import { firstValueFrom } from 'rxjs';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent as SharedBaseComponent } from '@microsec/components';
import { SYSTEM_SETTINGS_CONSTANTS } from '@ids-constants';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
})
export class RedirectionComponent extends SharedBaseComponent implements OnInit, OnDestroy {
  constructor(
    private featureSrv: FeatureService,
    private organizationSrv: OrganizationService,
    private projectSrv: ProjectService,
  ) {
    super();
  }

  async ngOnInit() {
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(false));
    const currentUser = await firstValueFrom(this.store.select(authenticationSelectors.currentUser));
    if (!!currentUser) {
      this.featureSrv.getFeatures().subscribe({
        next: (features: any[]) => {
          this.organizationSrv.getOrganizations().subscribe({
            next: (resOrg: any[]) => {
              const organizations = this.util.sortObjectArray(this.util.cloneObjectArray(resOrg), 'created', true, true);
              this.projectSrv.getProjects().subscribe({
                next: (resPrj: any[]) => {
                  const projects = this.util.sortObjectArray(this.util.cloneObjectArray(resPrj), 'created', true, true);
                  const isSuperAdmin = !!currentUser.superuser;

                  if (!this.hasLicense(features) && !!isSuperAdmin) {
                    this.router.navigate([`/${MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE}/${SYSTEM_SETTINGS_CONSTANTS.LICENSES.ROUTE}`]);
                  } else {
                    const latestBreadcrumbConfig = JSON.parse(localStorage.getItem(LATEST_BREADCRUMB_CONFIG) || 'null');
                    localStorage.removeItem(LATEST_BREADCRUMB_CONFIG);
                    const scope = !!latestBreadcrumbConfig?.organizationId
                      ? !!latestBreadcrumbConfig?.projectId
                        ? SCOPE.PROJECT
                        : SCOPE.ORGANIZATION
                      : SCOPE.GLOBAL;

                    const foundProject = !!(projects || []).find((p) => p.id === latestBreadcrumbConfig?.projectId)
                      ? projects.find((p) => p.id === latestBreadcrumbConfig?.projectId)
                      : !!(projects || []).find((p) => !!p.user_role)
                        ? projects.find((p) => !!p.user_role)
                        : null;
                    if (!!foundProject && scope !== SCOPE.ORGANIZATION) {
                      const isOnboardingUnfinished = JSON.parse(
                        localStorage.getItem(`${PROJECT_ONBOARDING_UNFINISHED}_${foundProject.org_id}_${foundProject.id}`) || 'false',
                      );
                      if (!isOnboardingUnfinished) {
                        // Find first project, for sure exist organization
                        this.changeRoute(
                          MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, foundProject.org_id).replace(
                            PROJECT_ID_PARAM_ROUTE,
                            foundProject.id,
                          ),
                        );
                      } else {
                        this.changeRoute(
                          MODULE_CONSTANTS.PROJECT_GETTING_STARTED.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, foundProject.org_id).replace(
                            PROJECT_ID_PARAM_ROUTE,
                            foundProject.id,
                          ),
                        );
                      }
                    } else {
                      // Cannot find any project
                      const foundOrganization = !!(organizations || []).find((o) => o.id === latestBreadcrumbConfig?.organizationId)
                        ? projects.find((o) => o.id === latestBreadcrumbConfig?.organizationId)
                        : !!(projects || []).find((o) => !!o.user_role)
                          ? projects.find((o) => !!o.user_role)
                          : null;
                      if (!!foundOrganization) {
                        this.changeRoute(
                          `/${MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, foundOrganization.id)}`,
                        );
                      } else {
                        localStorage.setItem(SYSTEM_ONBOARDING_UNFINISHED, 'true');
                        this.changeRoute(
                          isSuperAdmin ? `/${MODULE_CONSTANTS.SYSTEM_GETTING_STARTED.ROUTE}` : `/${MODULE_CONSTANTS.USER_SETTINGS.ROUTE}`,
                        );
                      }
                    }
                  }
                },
                error: () => {
                  this.redirectToLoginPage();
                },
              });
            },
            error: () => {
              this.redirectToLoginPage();
            },
          });
        },
        error: () => {
          this.redirectToLoginPage();
        },
      });
    } else {
      this.redirectToLoginPage();
    }
  }

  redirectToLoginPage() {
    this.store.dispatch(new fromAuthenticationActions.ClearUser());
    this.store.dispatch(new fromLayoutActions.InitLayout());
    setTimeout(() => {
      this.changeRoute(`/${MODULE_CONSTANTS.LOGIN.ROUTE}`);
    });
  }

  /**
   * Check if the application has license
   */
  hasLicense(features: any) {
    let result = false;
    if (!!features) {
      Object.values(features).forEach((featureTypeValue: any) => {
        result ||= !!Object.values(featureTypeValue).find((p) => !!p);
      });
    } else {
      result = false;
    }
    return result;
  }
}
