import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../api';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IIDSService } from './IIDSService';

const IDS_API = API.IDS;

@Injectable()
export class IdsService implements IIDSService {
  constructor(private httpClient: HttpClient) {}

  getHostsInfo(): Observable<any> {
    return this.httpClient.get(`${IDS_API}/NetworkInfo`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
