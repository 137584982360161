import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-zone-overview',
  templateUrl: './zone-overview.component.html',
  styleUrls: ['./zone-overview.component.scss'],
})
export class ZoneOverviewComponent {
  @Input() zone: any = null;
}
