<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="!device && !config ? CREATE_LABEL : SAVE_CHANGES_LABEL"
  (submitEvent)="onSubmit($event)"
  [leftActionArea]="leftActionArea">
</microsec-form-builder>

<ng-template #leftActionArea>
  <p-button styleClass="mr-2" type="button" label="Test Connection" [disabled]="true" *ngIf="false"> </p-button>
  <!-- (onClick)="testConnection()" -->
</ng-template>
