import { RoleService, UserService } from '@microsec/services';
import { SCOPE } from '@microsec/constants';
import { AppInjector } from '@microsec/utilities';
import { asyncScheduler, catchError, Observable, scheduled } from 'rxjs';

export class ObjectPickerHelper {
  static getObjectPickerRequestsFunction = (config: {
    types: string[];
    searchUserScope: string;
    searchRoleScope: string;
    searchTerm: string;
    organizationId: any;
    projectId: any;
    includeAssignedUsers: boolean;
    excludeAssignedUsersOnProject: boolean;
  }) => {
    const { types, searchUserScope, searchRoleScope, searchTerm, organizationId, projectId, includeAssignedUsers, excludeAssignedUsersOnProject } =
      config;
    const requests: Observable<any>[] = [];
    const injector = AppInjector.getInjector();
    types.forEach((type: string) => {
      switch (type) {
        case 'user': {
          const userSrv = injector.get(UserService);
          switch (searchUserScope) {
            case SCOPE.GLOBAL: {
              requests.push(userSrv.getUsers(searchTerm, includeAssignedUsers).pipe(catchError(() => scheduled([[]], asyncScheduler))));
              break;
            }
            case SCOPE.ORGANIZATION: {
              requests.push(
                userSrv
                  .getUsersByOrganizationId(organizationId, searchTerm, excludeAssignedUsersOnProject)
                  .pipe(catchError(() => scheduled([[]], asyncScheduler))),
              );
              break;
            }
            case SCOPE.PROJECT: {
              requests.push(userSrv.getUsersByProjectId(projectId, searchTerm).pipe(catchError(() => scheduled([[]], asyncScheduler))));
              break;
            }
            default:
              requests.push(
                new Observable((observe) => {
                  observe.next([]);
                }),
              );
              break;
          }
          break;
        }
        case 'role': {
          const roleSrv = injector.get(RoleService);
          switch (searchRoleScope) {
            case SCOPE.ORGANIZATION: {
              requests.push(roleSrv.getOrganizationRoles(organizationId, searchTerm).pipe(catchError(() => scheduled([[]], asyncScheduler))));
              break;
            }
            case SCOPE.PROJECT: {
              requests.push(roleSrv.getProjectRoles(projectId, searchTerm).pipe(catchError(() => scheduled([[]], asyncScheduler))));
              break;
            }
            default:
              requests.push(
                new Observable((observe) => {
                  observe.next([]);
                }),
              );
              break;
          }
          break;
        }
        default: {
          break;
        }
      }
    });
    return requests;
  };
}
