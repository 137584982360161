import { AfterViewInit, Component, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { ASSIGN_LABEL } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { FirmwareService, TargetDeviceService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  ASSIGNED_DEVICES: 'assigned_devices',
  ORG_ID: 'org_id',
};

@Component({
  selector: 'app-firmware-assigned-devices-form',
  templateUrl: './firmware-assigned-devices-form.component.html',
  styleUrls: ['./firmware-assigned-devices-form.component.scss'],
})
export class FirmwareAssignedDevicesFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  firmware: any = null;

  FORM_PARAMS = FORM_PARAMS;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('devicesDropdown') devicesDropdown!: TemplateRef<any>;

  ASSIGN_LABEL = ASSIGN_LABEL;

  devices: any[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private targetDeviceSrv: TargetDeviceService,
    private firmwareSrv: FirmwareService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.firmware = this.dialogConfig.data?.firmware;
    this.initForm();
  }

  initForm() {
    const devicesField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.ASSIGNED_DEVICES,
      label: 'Device(s)',
      field: 'multiselect',
      options: [] as any[],
      placeholder: 'Select device(s)',
      required: true,
      fieldInfo: 'Assign firmware to the selected device(s)',
      defaultValue: [],
      focused: true,
      onShowEvent: new EventEmitter<any>(),
    } as FormItem);
    const fields = [
      Object.assign(new FormItem(), {
        label: 'Assign firmware to the selected device(s) (if supported).',
        field: 'text',
      } as FormItem),
      devicesField,
    ];
    this.fields = fields;
    this.getDevices();
  }

  getDevices() {
    this.form.isLoading = true;
    this.targetDeviceSrv
      .getDevices({ organizationId: this.breadcrumbConfig?.organizationId, projectId: this.breadcrumbConfig?.projectId, page: 1, perPage: 500 })
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.devices = ((rs.devices as any[]) || []).map((device) => ({
            ...device,
            value: device.id,
            label: device.label,
          }));
          const assignedDevicesField = this.fields.find((p) => p.name === FORM_PARAMS.ASSIGNED_DEVICES);
          if (!!assignedDevicesField) {
            assignedDevicesField.options = this.devices;
          }
          if (!!this.firmware) {
            this.form?.patchValue({
              [FORM_PARAMS.ASSIGNED_DEVICES]: ((this.firmware[FORM_PARAMS.ASSIGNED_DEVICES] as any[]) || []).map(
                (assignedDevice) => assignedDevice.device_id,
              ),
            });
          }
        },
      });
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const selectedDevices = this.form.getRawValue()?.[FORM_PARAMS.ASSIGNED_DEVICES] || [];
    let deviceIds = [...(this.firmware[FORM_PARAMS.ASSIGNED_DEVICES] as any[]).map((d) => d.device_id), ...selectedDevices];
    deviceIds = deviceIds.filter((val, index, self) => self.indexOf(val) === index);
    this.firmwareSrv
      .updateFirmware(this.firmware.id, {
        [FORM_PARAMS.ASSIGNED_DEVICES]: deviceIds,
        [FORM_PARAMS.ORG_ID]: this.breadcrumbConfig?.organizationId,
      })
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Assigned firmware to device(s) successfully');
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  getString(obj: any) {
    return JSON.stringify(obj);
  }
}
