import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SHORT_TEXT_MAX_LENGTH } from '@microsec/constants';

const FORM_PARAMS = {
  CODE: 'code',
};

@Component({
  selector: 'app-authenticator-verification',
  templateUrl: './authenticator-verification.component.html',
  styleUrls: ['./authenticator-verification.component.scss'],
})
export class AuthenticatorVerificationComponent implements OnInit {
  @Output() changeStepEvent: EventEmitter<any> = new EventEmitter<any>();

  FORM_PARAMS = FORM_PARAMS;

  form!: FormGroup;

  SHORT_TEXT_MAX_LENGTH = SHORT_TEXT_MAX_LENGTH;

  ngOnInit() {
    this.form = new FormGroup({
      [FORM_PARAMS.CODE]: new FormControl(''),
    });
  }

  /**
   * Change step
   * @param step
   */
  changeStep(step = 1) {
    this.changeStepEvent.emit({
      number: step,
      [FORM_PARAMS.CODE]: this.form.value[FORM_PARAMS.CODE],
    });
  }
}
