<div class="topics-field">
  @if (!!form && !!topicsFormArray) {
    <ng-container [formGroup]="form">
      <ng-container [formArrayName]="FORM_PARAMS.INTERFACE_TOPICS">
        <div class="mt-1" *ngIf="!!topicsFormArray?.controls?.length">
          <p-accordion [multiple]="true" [(activeIndex)]="activeIndex">
            <ng-container *ngFor="let item of topicsFormArray?.controls; let i = index">
              <ng-container [formGroupName]="i">
                <div class="flex">
                  <div
                    [ngStyle]="{
                      width: !isReadOnly && !topicsFormArray.get([i, 'expanded'])?.value ? 'calc(100% - 32px)' : '100%'
                    }">
                    <p-accordionTab [(selected)]="$any(topicsFormArray.get([i, 'expanded'])).value">
                      <ng-template pTemplate="header">
                        <div class="flex align-items-center gap-2">
                          <span class="vertical-align-middle">{{
                            topicsFormArray.get([i, 'saved'])?.value ? topicsFormArray.get([i, FORM_PARAMS.TOPIC])?.value : 'New Topic'
                          }}</span>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="content">
                        <div class="grid field">
                          <label class="col-4 text-data">Topic Name <microsec-asterisk /></label>
                          <div class="col-8">
                            <input
                              inputTrimRef
                              type="text"
                              defaultTemplate
                              [maxlength]="50"
                              pInputText
                              [formControlName]="FORM_PARAMS.TOPIC"
                              [ngClass]="{
                                'ng-invalid ng-dirty':
                                  !!topicsFormArray.get([i, FORM_PARAMS.TOPIC])?.touched &&
                                  !!topicsFormArray.get([i, FORM_PARAMS.TOPIC])?.dirty &&
                                  !!topicsFormArray.get([i, FORM_PARAMS.TOPIC])?.errors
                              }" />
                          </div>
                          <label
                            class="col-8 col-offset-4 text-error"
                            *ngIf="
                              !!topicsFormArray?.get([i, FORM_PARAMS.TOPIC])?.touched &&
                              !!topicsFormArray?.get([i, FORM_PARAMS.TOPIC])?.dirty &&
                              !!topicsFormArray?.get([i, FORM_PARAMS.TOPIC])?.errors
                            ">
                            Topic name is required
                          </label>
                        </div>
                        <div class="grid field">
                          <label
                            class="col-12 link"
                            (click)="toggleShowAdvanceSettings(i, !topicsFormArray.get([i, 'showedAdvancedSettings'])?.value)"
                            >Show Advanced Settings
                            <i
                              [class]="
                                !!topicsFormArray.get([i, 'showedAdvancedSettings'])?.value ? 'pi pi-chevron-down ml-2' : 'pi pi-chevron-right ml-2'
                              "
                              style="font-size: 0.75rem">
                            </i>
                          </label>
                        </div>
                        @if (!!topicsFormArray.get([i, 'showedAdvancedSettings'])?.value) {
                          <!-- TShark Compatibility Coming Soon Feature -->
                          <!-- <div class="grid field">
                          <label class="col-4 text-data">TShark Compatibility</label>
                          <div class="col-8">
                            <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.TSHARK_COMPATIBILITY"> </p-checkbox>
                          </div>
                        </div> -->
                          <div class="grid field">
                            <div class="col-12 console-header">
                              <div class="card">
                                <div class="flex justify-content-between align-items-center">
                                  <label>Input Validation Lambda</label>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 microsec-codemirror-container console-content">
                              <div class="card" style="height: 200px">
                                <ngx-codemirror
                                  #codeMirrorComponent
                                  [formControlName]="FORM_PARAMS.PREPROCESSING_LAMBDA"
                                  [options]="$any(codeMirrorOptions)">
                                </ngx-codemirror>
                              </div>
                            </div>
                          </div>
                          <div class="grid field">
                            <div class="col-12 console-header">
                              <div class="card">
                                <div class="flex justify-content-between align-items-center">
                                  <label>Processing Lambda</label>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 microsec-codemirror-container console-content">
                              <div class="card" style="height: 200px">
                                <ngx-codemirror
                                  #codeMirrorComponent
                                  [formControlName]="FORM_PARAMS.PROCESSING_LAMBDA"
                                  [options]="$any(codeMirrorOptions)">
                                </ngx-codemirror>
                              </div>
                            </div>
                          </div>
                        }
                        <div class="flex align-items-center justify-content-end gap-2" *ngIf="!isReadOnly">
                          <p-button
                            severity="secondary"
                            size="small"
                            type="button"
                            label="Cancel"
                            (onClick)="i + 1 > topics.length ? updateTopics(i, true) : cancelChangesTopic(i)"></p-button>
                          <p-button
                            *ngIf="i + 1 <= topics.length"
                            severity="danger"
                            size="small"
                            type="button"
                            label="Delete"
                            (onClick)="updateTopics(i, true)"></p-button>
                          <p-button
                            severity="success"
                            size="small"
                            type="button"
                            label="Save Topic"
                            (onClick)="updateTopics(i)"
                            [disabled]="!topicsFormArray.at(i).valid || !topicsFormArray.get([i, 'updated'])?.value"></p-button>
                        </div>
                      </ng-template>
                    </p-accordionTab>
                  </div>
                  <div
                    class="flex justify-content-end mt-3"
                    style="width: 32px"
                    *ngIf="!isReadOnly && !topicsFormArray?.get([i, 'expanded'])?.value"
                    (click)="updateTopics(i, true)">
                    <i class="fa fa-trash cursor-pointer"></i>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </p-accordion>
        </div>
        <div class="mt-2" *ngIf="!isReadOnly && topics.length === topicsFormArray?.length">
          <p-button severity="secondary" size="small" type="button" label="Add New Topic" (onClick)="addTopic()"></p-button>
        </div>
      </ng-container>
    </ng-container>
  }
</div>
