import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';

import moment from 'moment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export const FORM_PARAMS = {
  FROM: 'from',
  TO: 'to',
};

@Component({
  selector: 'app-custom-time-range-form',
  templateUrl: './custom-time-range-form.component.html',
  styleUrls: ['./custom-time-range-form.component.scss'],
})
export class CustomTimeRangeFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(private dialogConfig: DynamicDialogConfig) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    const data = this.dialogConfig?.data;
    this.initForm();
    if (data) {
      this.form.patchValue(data);
    }
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FROM,
        label: 'From',
        field: 'calendar',
        calendarMinDate: moment('2018-01-01').toDate(),
        calendarMaxDate: moment().toDate(),
        calendarShowTime: true,
        required: true,
        fieldInfo: 'From',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TO,
        label: 'To',
        field: 'calendar',
        calendarMaxDate: moment().toDate(),
        calendarShowTime: true,
        required: true,
        fieldInfo: 'To',
        defaultValue: null,
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.FROM, (value: any) => {
        if (this.form.getControlValue(FORM_PARAMS.TO)?.getTime() <= value?.getTime()) {
          this.form.setControlValue(FORM_PARAMS.TO, null);
        }
        const toField = this.fields.find((p) => p.name === FORM_PARAMS.TO);
        if (!!toField) {
          toField.calendarMinDate = value;
        }
      });
    });
  }

  onSubmit(closeDialog: (result: any) => void) {
    closeDialog(this.form.getRawValue());
  }
}
