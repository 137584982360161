import { Component } from '@angular/core';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
})
export class AssessmentsComponent extends BaseComponent {
  form = {
    visible: false,
    assessment: null,
  };

  openAssessmentForm(assessment?: any) {
    this.form = {
      visible: true,
      assessment: assessment || null,
    };
  }

  closeAssessmentForm() {
    this.form = {
      visible: false,
      assessment: null,
    };
  }
}
