import { AfterViewInit, Component, HostListener, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@ids-constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { NetworkMapEditor } from '@ids-models';
import { NmeTopNavigationComponent } from './nme-top-navigation/nme-top-navigation.component';
import { NmeDiagramComponent } from './nme-diagram/nme-diagram.component';
// import { Subject } from 'rxjs';

@Component({
  selector: 'app-network-map-editor',
  templateUrl: './network-map-editor.component.html',
  styleUrls: ['./network-map-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NetworkMapEditorComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  leftNavInterval: any = null;

  editor: NetworkMapEditor | null = null;

  // leaveConfirmationSubject = new Subject<boolean>();

  @ViewChild('topNav') topNavComponent!: NmeTopNavigationComponent;

  @ViewChild('diagram') diagramComponent!: NmeDiagramComponent;

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.store.dispatch(new fromLayoutActions.ToggleLeftNavbar(false));
    this.leftNavInterval = setInterval(() => {
      this.setLeftNavSelection(true);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    if (this.editor?.isSaved === false) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  // canLeavePage(): Observable<boolean> | Promise<boolean> | boolean {
  //   if (!this.diagramComponent?.isFirstLoaded && this.editor?.isSaved === false) {
  //     this.confirm({
  //       action: 'Save Changes',
  //       acceptLabel: 'Save',
  //       rejectLabel: "Don't Save",
  //       customContent: "<p>Do you want to save the changes made to network map editor?</p><p>Your changes will be lost if you don't save them.</p>",
  //       next: () => {
  //         this.topNavComponent?.save(() => {
  //           this.leaveConfirmationSubject.next(true);
  //           this.leaveConfirmationSubject.complete();
  //         });
  //       },
  //       rejectCallback: () => {
  //         this.leaveConfirmationSubject.next(true);
  //         this.leaveConfirmationSubject.complete();
  //       },
  //       additionalButtons: [
  //         {
  //           label: 'Cancel',
  //           styleClass: 'mr-4',
  //           command: () => {
  //             this.leaveConfirmationSubject.next(false);
  //             this.leaveConfirmationSubject.complete();
  //           },
  //         },
  //       ],
  //     } as ConfirmationDialogConfig);
  //     return this.leaveConfirmationSubject.asObservable();
  //   } else {
  //     return scheduled([true], asyncScheduler);
  //   }
  // }

  setLeftNavSelection(isSelected: boolean) {
    const leftNav = document.getElementsByClassName('microsec-left-navbar')?.[0];
    if (!!leftNav) {
      const menuItems = leftNav.getElementsByClassName('p-menuitem');
      const menuLinkItems = leftNav.getElementsByClassName('p-menuitem-link');
      for (let index = 0; index < menuItems.length; index++) {
        const menuItem = menuItems[index];
        const menuLinkItem = menuLinkItems[index];
        if (((menuLinkItem.getAttribute('href') as string) || '').indexOf(PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP.ROUTE) > -1) {
          if (!!isSelected) {
            menuItem.classList.add('selected');
          } else {
            menuItem.classList.remove('selected');
          }
        }
      }
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    // if (!!this.leaveConfirmationSubject) {
    //   this.leaveConfirmationSubject.unsubscribe();
    // }
    // Remove context menu
    const popupContextMenus = document.getElementsByClassName('mxPopupMenu');
    while (!!popupContextMenus.length) {
      popupContextMenus[0].parentNode?.removeChild(popupContextMenus[0]);
    }
    // Remove tooltips
    const tooltips = document.getElementsByClassName('mxTooltip');
    while (!!tooltips.length) {
      tooltips[0].parentNode?.removeChild(tooltips[0]);
    }
    // Others
    if (!!this.leftNavInterval) {
      this.setLeftNavSelection(false);
      clearInterval(this.leftNavInterval);
    }
  }
}
