import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent, CommonToolbarComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem } from '@microsec/models';
import { AssessmentService } from '@ids-services';

import { BehaviorSubject, finalize } from 'rxjs';

import { AssessmentMitigationFormComponent } from './assessment-mitigation-form/assessment-mitigation-form.component';
import { DELETE_LABEL } from '@microsec/constants';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

@Component({
  selector: 'app-assessment-mitigations',
  templateUrl: './assessment-mitigations.component.html',
  styleUrls: ['./assessment-mitigations.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class AssessmentMitigationsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  values: any[] = [];

  @Input() isReport = false;

  @Input() questionId = null;

  _mitigations: any[] = [];

  get mitigations() {
    return this._mitigations;
  }

  @Input() set mitigations(values: any[]) {
    this._mitigations = values || [];
    this.setValues();
    this.mitigationsChange.emit(this._mitigations);
  }

  @Output() mitigationsChange: EventEmitter<any> = new EventEmitter<any>();

  cols: any[] = [
    { field: 'title', header: 'Title', width: 12 },
    { field: 'description', header: 'Description', width: 15 },
  ];

  globalFilterFields: string[] = [...this.cols.map((col) => col.field)];

  selectedMitigations: any[] = [];

  actionsMenuItems: ActionMenuItem[] = [];

  @ViewChild('ct') toolbar!: CommonToolbarComponent;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
  };

  filterSearch = '';

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  @ViewChild('dt') dt!: CommonTableComponent;

  constructor(
    private assessmentSrv: AssessmentService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fa fa-edit',
        visible: () => this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openMitigationForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values.search || '';
      }
    });
  }

  setValues() {
    const values = this.mitigations.map((mitigation, mitigationIndex: number) => ({
      ...mitigation,
      tempId: mitigationIndex,
    }));
    this.values = values;
    this.toolbar?.clearFilters();
  }

  getMitigations(values: any[]) {
    const mitigations = (values || []).map((mitigation) => ({
      ...mitigation,
      tempId: undefined,
    }));
    return mitigations;
  }

  openMitigationForm(mitigation?: any) {
    const dialog = this.dialogSrv.open(AssessmentMitigationFormComponent, {
      header: `${!mitigation ? 'Add' : 'Edit'} Mitigation`,
      data: {
        questionId: this.questionId || null,
        mitigations: this.getMitigations(this.values?.filter((m) => (!!mitigation ? m.tempId !== mitigation.tempId : true))),
        mitigation: mitigation || null,
      },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        if (typeof rs === 'boolean') {
          this.assessmentSrv.refresh$.next(true);
        } else {
          if (!mitigation) {
            const tempId = this.values?.length || 0;
            this.values.push({ ...rs, tempId });
          } else {
            this.values[mitigation.tempId] = rs;
          }
          this.mitigations = this.getMitigations(this.values);
        }
      }
    });
  }

  openDeleteConfirmation(mitigations: any[] = []) {
    const mitigationList = mitigations.map((mitigation) => `<li>${`Mitigation ${mitigation.title}`}</li>`);
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Mitigation(s)',
      customContent: `<ul>${mitigationList.join('')}</ul>`,
      next: () => {
        const deletedTempIds = mitigations?.map((mitigation) => mitigation.tempId) || [];
        const newMitigations = !!mitigations.length ? this.values.filter((mitigation) => !deletedTempIds.includes(mitigation.tempId)) : [];
        const selectedMitigations = !!mitigations.length
          ? this.selectedMitigations.filter((mitigation) => !deletedTempIds.includes(mitigation.tempId))
          : [];
        if (!this.questionId) {
          this.selectedMitigations = selectedMitigations;
          this.values = newMitigations;
          this.mitigations = this.getMitigations(this.values);
        } else {
          this.isLoading = true;
          const payload = { mitigations: this.getMitigations(newMitigations) };
          this.assessmentSrv
            .updateAssessmentQuestion(this.questionId, payload)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              }),
            )
            .subscribe({
              next: () => {
                this.selectedMitigations = selectedMitigations;
                this.assessmentSrv.refresh$.next(true);
              },
              error: (err: any) => {
                this.showErrorMessage(err);
              },
            });
        }
      },
    });
  }
}
