<div class="grid">
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="leftTemplate"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button
        [label]="'Upload Firmware'"
        [rounded]="true"
        icon="pi pi-plus"
        (onClick)="openFirmwareForm()"
        *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
      </p-button>
    </ng-template>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedFirmware }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [(selection)]="selectedFirmware"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [scrollHeight]="'55vh'"
        itemName="Firmware"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-6" *ngIf="!!selectedFirmware">
    <div class="container-border">
      <microsec-loading-overlay [isLoading]="!!isLoading">
        <microsec-selected-panel-header
          [id]="selectedFirmware.id"
          [label]="selectedFirmware.name"
          (closeSelectedPanelEvent)="hideDetailsPanel()"></microsec-selected-panel-header>
        <app-firmware-details [(firmware)]="selectedFirmware" (actionEvent)="handleAction($event)" [isLoading]="isLoading"></app-firmware-details>
      </microsec-loading-overlay>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getFirmwares()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Target Device -->
    <ng-container *ngSwitchCase="'target_device'">
      <label>
        {{ (rowData[col.field] | constant: 'firmware-target-device') || '-' }}
      </label>
    </ng-container>
    <!-- Uploaded -->
    <ng-container *ngSwitchCase="'created_date'">
      <microsec-truncate-text>
        {{ rowData[col.field] | momentPipe }}
      </microsec-truncate-text>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <microsec-truncate-text>
        {{ rowData[col.field] }}
      </microsec-truncate-text>
    </ng-container>
  </ng-container>
</ng-template>
