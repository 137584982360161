<p-messages [(value)]="messageValue" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [enableService]="false"></p-messages>

<div class="grid">
  <!-- Create + Search -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button label="Create Analyzer" severity="primary" [rounded]="true" icon="pi pi-plus" (onClick)="openAnalyzerForm()"> </p-button>
    </ng-template>
  </div>

  <!-- Data table -->
  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedAnalyzer }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        selectionMode="multiple"
        [(selection)]="selectedAnalyzers"
        [(rowSingleSelection)]="selectedAnalyzer"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [dataKey]="'tableId'"
        itemName="Rule-Based Analyzer"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate"></microsec-common-table>
    </microsec-card>
  </div>
  @if (!!selectedAnalyzer) {
    <div class="col-12 lg:col-6">
      <div class="container-border">
        <app-rule-based-analyzer-details
          [analyzer]="selectedAnalyzer"
          [panelHeaderRightTemplate]="panelHeaderRightTemplate"
          (analyzerChangeEvent)="onAnalyzerChangeEvent($event)"
          (hidePanelEvent)="hideDetailsPanel()"></app-rule-based-analyzer-details>
        <ng-template #panelHeaderRightTemplate>
          @if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
            <p-button
              severity="primary"
              styleClass="mr-2"
              icon="fas fa-edit"
              pTooltip="Edit"
              tooltipPosition="bottom"
              appendTo="body"
              (onClick)="openAnalyzerForm(selectedAnalyzer)">
            </p-button>
          }
          <p-menu #ruleBasedPayloadEditMenu styleClass="microsec-button-context-menu" [model]="editOptions" [popup]="true" appendTo="body"></p-menu>
          @if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
            <p-button
              type="button"
              icon="fa fa-trash"
              severity="danger"
              (onClick)="openDeleteConfirmation([selectedAnalyzer])"
              pTooltip="Delete"
              tooltipPosition="bottom"
              appendTo="body"></p-button>
          }
        </ng-template>
      </div>
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    @if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
      <microsec-operation-button
        operation="Delete"
        buttonClass="p-button-danger"
        icon="fa fa-trash"
        (selectedOperationEvent)="openDeleteConfirmation(selectedAnalyzers)"
        (allOperationEvent)="openDeleteConfirmation()"
        [disabled]="!selectedAnalyzers.length"></microsec-operation-button>
    }
    <microsec-refresh-button (refreshEvent)="anomalyAnalyzerSrv.refresh$.next(true)"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Protocol -->
    <ng-container *ngSwitchCase="'protocol'">
      {{ rowData['protocol'] | constant: 'anomaly-analyzer-protocol' : true }}
    </ng-container>
    <!-- Training Progress -->
    <ng-container *ngSwitchCase="'training_progress'">
      {{ rowData['training_progress'] ? (rowData['training_progress'] | number: '1.0-2') + '%' : '0%' }}
    </ng-container>
    <!-- Mode -->
    <ng-container *ngSwitchCase="'mode'">
      <p-dropdown
        (click)="$event.stopPropagation()"
        appendTo="body"
        [options]="MODE_OPTIONS"
        optionLabel="label"
        optionValue="value"
        placeholder="Select Mode"
        [(ngModel)]="rowData.mode"
        (onChange)="updateAnalyzer(rowData)"
        [disabled]="!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] || rowData.mode === 'prediction'">
      </p-dropdown>
    </ng-container>
    <!-- Rule Type -->
    <ng-container *ngSwitchCase="'rule_types'">
      {{ rowData[col.field] | analyzerRuleTypesPipe }}
    </ng-container>
    <!-- Enabled -->
    <ng-container *ngSwitchCase="'enabled'">
      <p-inputSwitch (click)="$event.stopPropagation()" [(ngModel)]="rowData[col.field]" (onChange)="updateAnalyzer(rowData)"></p-inputSwitch>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
