import { Component, Input } from '@angular/core';
import { ANALYZER_DATA_TYPES, ANALYZER_TYPES, NONIP_RULE_TYPES } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService, ConnectionService } from '@ids-services';

import moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-rule-based-analyzer-overview',
  templateUrl: './rule-based-analyzer-overview.component.html',
  styleUrls: ['./rule-based-analyzer-overview.component.scss'],
})
export class RuleBasedAnalyzerOverviewComponent extends BaseComponent {
  isLoading = false;

  _analyzer: any = null;

  get analyzer() {
    return this._analyzer;
  }

  @Input() set analyzer(value: any) {
    this._analyzer = value;
    if (!!this.analyzer) {
      if (!!this.analyzer.rule_download_last_timestamp) {
        this.analyzer.ruleDownloadLastTime = moment
          .utc(this.analyzer?.rule_download_last_timestamp)
          .local()
          .fromNow();
      }
      if (!!this.analyzer.connection_ids?.[0] && (!this.connection || this.connection.id !== this.analyzer.connection_ids?.[0])) {
        this.getConnection();
      }
      this.timer = !!value.timer ? [{ timeout: value.timer.timeout, grace: value.timer.grace }] : [];

      // For non-ip rule-based analyzer
      // Set custom rule table
      if (value.analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP) {
        this.customRuleValues = this.getCustomRuleValues(value);
      }
      // Set Enabled Built-in rule sets
      this.nonipBuiltinRules = this.getEnabledBuiltinRules(value);
    }
  }

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_DATA_TYPES = ANALYZER_DATA_TYPES;

  connection: { id?: number; name?: string } | null = null;

  timer: any[] = [];

  customRuleValues: any[] = [];

  nonipBuiltinRules = ''; // Display the enabled Built-in rules for nonip analyzer

  timerCols: any[] = [
    { field: 'timeout', header: 'Period', width: 10 },
    { field: 'grace', header: 'Grace Time', width: 10 },
  ];

  rulesCols: any[] = [{ field: 'formula', header: 'Formula', width: 20 }];

  nonIpCustomRules: any[] = [
    { field: 'enabled', header: 'Status', width: 10 },
    { field: 'name', header: 'Name', width: 15 },
    { field: 'rule', header: 'Rule', width: 20 },
  ];

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  getConnection() {
    this.isLoading = true;
    this.connectionSrv
      .getConnection(this.analyzer.connection_ids[0])
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.connection = {
            id: rs.data.id,
            name: rs.data.name,
          };
        },
        error: (err: any) => {
          this.connection = null;
          this.showErrorMessage(err);
        },
      });
  }

  downloadRule() {
    this.isLoading = true;
    this.anomalyAnalyzerSrv
      .updateAnalyzer(this.analyzer.id, { is_sync_scheduled: true })
      .pipe(
        finalize(() => {
          this.anomalyAnalyzerSrv.refresh$.next(true);
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Downloading');
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  updateNonipAnalyzerRuleStatus(rule: any) {
    this.isLoading = true;
    const payload = {
      enabled: rule.enabled,
    };
    this.anomalyAnalyzerSrv
      .updateNonipAnalyzerRuleStatus(this.analyzer.id, rule.id, payload)
      .pipe(
        finalize(() => {
          this.anomalyAnalyzerSrv.refresh$.next(true);
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Analyzer Rule Status Updated Successfully');
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  //-------------------------- NON-IP Helper Function -----------------------
  getCustomRuleValues(analyzer: any): any[] {
    const customRuleSet = analyzer.rule_types?.find((ruleType: any) => ruleType.name === NONIP_RULE_TYPES.CUSTOM);
    if (!customRuleSet) {
      return [];
    }
    const specificRuleSet = customRuleSet.rule_sets?.find((ruleSet: any) => ruleSet.id === 5);
    if (!specificRuleSet) {
      return [];
    }
    return specificRuleSet.rules;
  }

  getEnabledBuiltinRules(analyzer: any): string {
    const ruleSet = analyzer.rule_types?.find((ruleType: any) => ruleType.name === NONIP_RULE_TYPES.BUILT_IN);

    if (!ruleSet) {
      return '';
    }
    const enabledRuleSetNames = ruleSet.rule_sets.filter((ruleSet: any) => ruleSet.enabled).map((ruleSet: any) => ruleSet.name);
    return enabledRuleSetNames.join(', ');
  }
}
