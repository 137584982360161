import { Component, Input } from '@angular/core';
import { FEATURES, QUOTAS_FEATURES } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-quotas',
  templateUrl: './quotas.component.html',
  styleUrls: ['./quotas.component.scss'],
})
export class QuotasComponent extends BaseComponent {
  allFeatures: any[] = [];

  featureQuotas: { [key: string]: any } = [];

  _license: any = null;

  get license() {
    return this._license;
  }

  @Input() set license(value: any) {
    this._license = value;
    if (!!this.license) {
      const features: any[] = [];
      Object.keys(this.license.features || {}).forEach((key1) => {
        if (key1 !== FEATURES.QUOTAS) {
          const featureTypes = this.license.features[key1];
          const subFeaturesTypes = featureTypes['sub-features'];
          const subFeatures = Object.entries(subFeaturesTypes)
            .filter(([k]) => k !== `${key1}.enabled`)
            .map(([k, v]: [k: string, v: any]) => ({ ...v, key: k }));
          features.push({
            ...featureTypes,
            subFeatures,
            key: key1,
            enabled: subFeaturesTypes[`${key1}.enabled`].enabled,
            hidden: subFeaturesTypes[`${key1}.enabled`].hidden,
          });
        }
      });
      this.allFeatures = features;

      const featureQuotas = this.util.cloneDeepObject(this.license?.features?.[FEATURES.QUOTAS]?.['sub-features'] || {});
      Object.keys(featureQuotas).forEach((key) => {
        const a = key.split('quotas.')[1];
        featureQuotas[a] = featureQuotas[key];
        delete featureQuotas[key];
      });
      const quotas: { [key: string]: any } = {};
      Object.entries(featureQuotas).forEach(([k, v]) => {
        const split = k.split('.');
        return this.unflattenObject(quotas, [split.shift() || '', split.join('.')], v);
      });
      Object.keys(quotas).forEach((key) => {
        if (quotas[key].child)
          quotas[key].child = Object.keys(quotas[key].child || {}).map((childKey) => ({ ...quotas[key].child[childKey], key: childKey }));
      });
      this.featureQuotas = quotas;
    }
  }

  FEATURES = FEATURES;

  QUOTAS_FEATURES = QUOTAS_FEATURES;

  faCheck = faCheck;

  faX = faX;

  constructor() {
    super();
  }

  unflattenObject(object: { [key: string]: any }, path: string[], value: any) {
    const last = path.pop();
    if (last) {
      path.reduce((o, k) => (o[k] = o[k] || { child: {} }).child, object)[last] = value;
    } else {
      object[path[0]] = { ...value, child: {} };
    }
  }

  get allQuotas() {
    return this.license?.features?.[FEATURES.QUOTAS]?.['sub-features'] || null;
  }
}
