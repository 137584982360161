import { NetworkMapEditor } from './network-map-editor';
import {
  InternalEvent,
  InternalMouseEvent,
  PanningHandler,
  RubberBandHandler,
  registerAllCodecs,
  registerCoreCodecs,
  registerEditorCodecs,
} from '@maxgraph/core';

export const NMEConfig = {
  /**
   * Config graph
   * @param this
   */
  configGraph(this: NetworkMapEditor) {
    if (!!this.graph) {
      // Edge color
      const edgeStyle = this.graph.getStylesheet().getDefaultEdgeStyle();
      edgeStyle.strokeColor = '#aaaaaa';
      edgeStyle.edgeStyle = 'manhattanEdgeStyle';
      delete edgeStyle.endArrow;
      // Page break
      this.graph.view.setScale(0.75);
      this.graph.pageBreakDashed = true;
      this.graph.preferPageSize = true;
      this.graph.centerZoom = true;
      // Enables rubberband selection
      new RubberBandHandler(this.graph);
      // Panning
      const panningHandler = this.graph.getPlugin('PanningHandler') as PanningHandler;
      panningHandler.isForcePanningEvent = (mouseEvent: InternalMouseEvent) => {
        return !mouseEvent.evt.altKey && !mouseEvent.sourceState;
      };
      this.graph.setPanning(true);
      // Hack to rerender edge on any node move
      this.graph.model.addListener(InternalEvent.CHANGE, (_sender: any, evt: any) => {
        const changesList = evt.getProperty('changes');
        const hasMoveEdits = changesList && changesList.some((c: any) => c.constructor.name == 'GeometryChange');
        // If detected GeometryChange event, call graph.view.refresh(), which will reroute edge
        if (!!hasMoveEdits) {
          this.refreshGraph();
        }
      });
      // Add changed detection for save status
      const events: string[] = [InternalEvent.CHANGE, InternalEvent.ADD, InternalEvent.REMOVE];
      events.forEach((event) => {
        this.graph?.model.addListener(event, () => {
          this.isSaved = false;
        });
      });
      // Create grid
      this.createResizableGrids();
      // Others
      registerAllCodecs();
      registerCoreCodecs();
      registerEditorCodecs();
      InternalEvent.disableContextMenu(this.graphElement);
      this.graph.setEnabled(true);
      this.graph.setAllowDanglingEdges(false);
      this.graph.setTooltips(true);
      this.graph.setConnectable(true);
      this.graph.setHtmlLabels(true);
      const graphHandler: any = this.graph.getPlugin('SelectionHandler');
      graphHandler.setRemoveCellsFromParent(false);
      // Refresh
      this.refreshGraph();
    }
  },
};
