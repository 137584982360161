/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, BehaviorSubject, Observable, of, scheduled } from 'rxjs';

import { IThreatService } from './IThreatService';
import threatMitigationData from './mock-data/threat-mitigations.json';
import * as threatData from './mock-data/threat.json';
import * as threatsData from './mock-data/threats2.json';
import * as threatSummaryData from './mock-data/threat-summary.json';

@Injectable({
  providedIn: 'root',
})
export class MockThreatService implements IThreatService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedObs: Observable<any> = this.selected$.asObservable();
  createThreat(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreats(
    project_id?: any,
    device_ids?: any[],
    page?: number,
    per_page?: number,
    sort = 'created',
    reverse = true,
    detailed?: boolean,
    status?: string[],
    threat_type?: string[],
    search?: string,
    connection_id?: number[],
    is_imported?: boolean,
    attack_type?: string[],
    threat_score?: string[],
    created_from?: string,
    created_to?: string,
    updated_from?: string,
    updated_to?: string,
  ): Observable<any> {
    return scheduled([threatsData as any], asyncScheduler);
  }
  getThreat(id: number): Observable<any> {
    const threat = (threatsData?.threats as any[]).find((p) => p.threat_id === id);
    return scheduled([threat], asyncScheduler);
  }
  updateThreat(id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteThreat(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAggregateThreatScore(): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatSummary(
    organization_id?: any,
    project_id?: any,
    status?: string[],
    threat_type?: string[],
    attack_type?: string[],
    threat_score?: string[],
  ): Observable<any> {
    return scheduled([threatSummaryData as any], asyncScheduler);
  }
  createThreatMitigation(threat_id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatMitigation(threat_id: number): Observable<any[]> {
    return of([...(threatMitigationData as any[])]);
  }
  updateMitigation(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteMitigation(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getThreatSetting(project_id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateThreatSetting(project_id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
