import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LDAPSetting } from '@microsec/models';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from '../api';
import { ILDAPSettingService } from './ILDAPSettingService';

const API_LDAP = `${API.ACCESS_POLICY}/ldap`;
const API_LDAP_SETTING = `${API_LDAP}/setting`;
const API_LDAP_TEST = `${API_LDAP}/test`;
const API_LDAP_LINKED_GROUPS = `${API_LDAP}/linked-groups`;

@Injectable({
  providedIn: 'root',
})
export class LDAPSettingService implements ILDAPSettingService {
  constructor(private http: HttpClient) {}

  getLDAPSetting(): Observable<LDAPSetting> {
    return this.http.get<LDAPSetting>(`${API_LDAP_SETTING}/`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  save(data: any): Observable<any> {
    return this.http.post<any>(`${API_LDAP_SETTING}/`, data).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  reset(): Observable<LDAPSetting> {
    return this.http.delete<LDAPSetting>(`${API_LDAP_SETTING}/reset/`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  testConnection(data: any): Observable<any> {
    return this.http.post<any>(`${API_LDAP_TEST}/connection/`, data).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  testAuthentication(data: any): Observable<any> {
    return this.http.post<any>(`${API_LDAP_TEST}/auth/`, data).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getLDAPGroups(): Observable<any> {
    return this.http.get<any>(`${API_LDAP}/groups/`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getLDAPGroupLinks(organizationId: number): Observable<any[]> {
    let url = `${API_LDAP_LINKED_GROUPS}/`;
    if (!!organizationId) {
      url += `?organization=${organizationId}`;
    }
    return this.http.get<any>(url).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createLDAGroupLink(payload: any): Observable<any> {
    return this.http.post<any>(`${API_LDAP_LINKED_GROUPS}/`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateLDAGroupLink(ldapGroupLinkId: number, payload: any): Observable<any> {
    return this.http
      .put<any>(`${API_LDAP_LINKED_GROUPS}/${ldapGroupLinkId}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteLDAGroupLink(ldapGroupLinkId: number): Observable<any> {
    return this.http
      .delete<any>(`${API_LDAP_LINKED_GROUPS}/${ldapGroupLinkId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
