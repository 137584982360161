<microsec-form-builder
  #fb
  [fields]="fields"
  [submitButtonLabel]="
    !analyzer && (this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.MQTT ? activeStep >= 2 : activeStep >= 1)
      ? CREATE_LABEL
      : !!analyzer && (this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.MQTT ? activeStep >= 2 : activeStep >= 1)
        ? SAVE_CHANGES_LABEL
        : ''
  "
  cancelButtonLabel=""
  (submitEvent)="onSubmit($event)"
  [shouldShowActionButtons]="true"
  [leftActionArea]="leftActionArea">
</microsec-form-builder>

<ng-template #stepsTemplate>
  <div class="my-3 mx-8">
    <microsec-custom-steps [steps]="steps" [activeStep]="activeStep"></microsec-custom-steps>
  </div>
</ng-template>

<ng-template #leftActionArea>
  <div class="flex gap-2" [ngClass]="{ 'mr-2': !!analyzer || activeStep >= 1 }">
    <p-button severity="secondary" type="button" (onClick)="fb.dialogRef.close(false)" [label]="!!analyzer && activeStep === 2 ? 'Done' : 'Cancel'">
    </p-button>
    <p-button
      *ngIf="activeStep > 0"
      type="button"
      icon="fa fa-angle-left"
      label="Back"
      severity="secondary"
      (onClick)="onActiveStepChange(-1)"></p-button>
    <p-button
      *ngIf="this.typeObj[CONSTANTS.PROTOCOL_TYPE] === PROTOCOL_TYPE.MQTT ? activeStep <= 1 : activeStep === 0"
      type="button"
      icon="fa fa-angle-right"
      iconPos="right"
      label="Next"
      severity="primary"
      [disabled]="!isCurrentStepValid"
      (onClick)="
        !analyzer && !hasOinkcodes && !!form && form.getControlValue(FORM_PARAMS.PROTOCOL) === ANALYZER_TYPES.RULE_BASED && activeStep === 0
          ? openOinkcodeRequiredConfirmation()
          : onActiveStepChange(1)
      "></p-button>
  </div>
</ng-template>

<ng-template #ruleTypesField>
  @if (!!form && !!form.form) {
    <div class="grid" [formGroup]="form.form">
      <ng-container *ngFor="let ruleType of ANALYZER_RULE_TYPE_OPTIONS">
        <div class="col-6 my-2">
          <p-checkbox
            [value]="ruleType.value"
            [label]="ruleType.label"
            [name]="FORM_PARAMS.RULE_TYPES"
            [formControl]="$any(form.form.controls[FORM_PARAMS.RULE_TYPES])">
          </p-checkbox>
        </div>
      </ng-container>
    </div>
  }
</ng-template>

<ng-template #timerField>
  <ng-container
    *ngIf="
      !!form &&
      !!form.form &&
      !!form.form.get([FORM_PARAMS.TIMER_ENABLED]) &&
      !!form.form.get([FORM_PARAMS.TIMER]) &&
      !!form.form.get([FORM_PARAMS.TIMER, FORM_PARAMS.TIMER_TIMEOUT]) &&
      !!form.form.get([FORM_PARAMS.TIMER, FORM_PARAMS.TIMER_GRACE])
    "
    [formGroup]="form.form">
    <div class="grid">
      <div class="col-12 my-3">
        <label> Set up interruption monitoring to receive alerts when payload transmission remains inactive for a user-defined duration. </label>
      </div>
      <div class="col-12 mb-3 flex align-items-center gap-3">
        <label>Interruption Monitoring</label>
        <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.TIMER_ENABLED"> </p-checkbox>
      </div>
      @if (!!form.getControlValue(FORM_PARAMS.TIMER_ENABLED)) {
        <div class="col-1"></div>
        <div class="col-10">
          <ng-container [formGroupName]="FORM_PARAMS.TIMER">
            <div class="grid field">
              <label class="col-4">
                Period
                <microsec-asterisk></microsec-asterisk>
              </label>
              <div class="col">
                <div class="flex gap-2 align-self-center">
                  <p-inputNumber
                    mode="decimal"
                    [useGrouping]="false"
                    [formControlName]="FORM_PARAMS.TIMER_TIMEOUT"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="0"></p-inputNumber>
                  <p-dropdown
                    [options]="UNIT_OPTIONS"
                    [formControlName]="FORM_PARAMS.TIMER_TIMEOUT_UNIT"
                    placeholder="Unit"
                    optionLabel="label"
                    optionValue="value"
                    appendTo="body"></p-dropdown>
                </div>
              </div>
              <div class="col-fixed flex align-items-center" style="width: 35px">
                <span pTooltip="The expected time for usual application payload data flow" tooltipPosition="right" appendTo="body">
                  <i class="pi pi-info-circle pr-1"></i>
                </span>
              </div>
            </div>
            <div class="grid field">
              <label class="col-4">
                Grace Time
                <microsec-asterisk></microsec-asterisk>
              </label>
              <div class="col">
                <div class="flex gap-2 align-self-center">
                  <p-inputNumber
                    mode="decimal"
                    [useGrouping]="false"
                    [formControlName]="FORM_PARAMS.TIMER_GRACE"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="0"></p-inputNumber>
                  <p-dropdown
                    [options]="UNIT_OPTIONS"
                    [formControlName]="FORM_PARAMS.TIMER_GRACE_UNIT"
                    placeholder="Unit"
                    optionLabel="label"
                    optionValue="value"
                    appendTo="body"></p-dropdown>
                </div>
              </div>
              <div class="col-fixed flex align-items-center" style="width: 35px">
                <span
                  pTooltip="Time taken before an alert is sent if application payload data flow is potentially disrupted"
                  tooltipPosition="right"
                  appendTo="body">
                  <i class="pi pi-info-circle pr-1"></i>
                </span>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-1"></div>
      }
    </div>
  </ng-container>
</ng-template>

<ng-template #rulesField>
  @if (!!form && !!form.form && !!mqttRulesFormArray) {
    <div class="grid">
      <div class="col-12 my-3">
        <label>
          Set up conditional rules to receive alerts based on user defined conditions on application payload received.
          <span class="link-blue" (click)="goToDocumentation()">Read Documentation</span>
        </label>
      </div>
      <div class="col-12">
        <microsec-card [isTableCard]="true">
          <microsec-common-table
            #dt
            [cols]="mqttRulesCols"
            [values]="mqttRulesFormArray.controls"
            [cellTemplate]="cellTemplate"
            [actionsMenuItems]="mqttRuleActionItems"
            [selectable]="false"
            [paginator]="false"
            [scrollHeight]="'25rem'"
            itemName="MQTT Rule"
            [containerHeaderRightTemplate]="containerHeaderRightTemplate"
            [noSortingCols]="['formula']"></microsec-common-table>
        </microsec-card>
      </div>
    </div>
  }

  <ng-template #containerHeaderRightTemplate>
    <div class="flex flex-wrap align-items-center gap-2" *ngIf="!!form && !!form.form">
      <p-button
        type="button"
        icon="fa fa-plus"
        severity="primary"
        pTooltip="Add Rule"
        tooltipPosition="bottom"
        appendTo="body"
        severity="primary"
        [disabled]="!form.getControl(FORM_PARAMS.RULES).valid && !!mqttRulesFormArray && !!mqttRulesFormArray.controls.length"
        (onClick)="addRules()">
      </p-button>
    </div>
  </ng-template>

  <ng-template let-index="index" let-col="col" let-rowData="rowData" #cellTemplate>
    <ng-container [ngSwitch]="col.field">
      <!-- Formula -->
      <ng-container *ngSwitchCase="FORM_PARAMS.MQTT_RULE_FORMULA">
        @if (!!form && !!form.form && mqttRulesFormArray) {
          <ng-container [formGroup]="form.form">
            <ng-container [formArrayName]="FORM_PARAMS.RULES">
              <ng-container [formGroupName]="index">
                <div class="microsec-codemirror-container console-content">
                  <div
                    class="card"
                    [ngClass]="{
                      'ng-invalid ng-dirty':
                        !!mqttRulesFormArray.get([index, FORM_PARAMS.MQTT_RULE_FORMULA])?.errors &&
                        !!mqttRulesFormArray.get([index, FORM_PARAMS.MQTT_RULE_FORMULA])?.errors?.['incorrect']
                    }"
                    style="width: 37rem; height: 2.2rem">
                    <ngx-codemirror #codeMirrorComponent [formControlName]="FORM_PARAMS.MQTT_RULE_FORMULA" [options]="codeMirrorOptions" />
                  </div>
                  @if (
                    !!mqttRulesFormArray.get([index, FORM_PARAMS.MQTT_RULE_FORMULA])?.errors &&
                    !!mqttRulesFormArray.get([index, FORM_PARAMS.MQTT_RULE_FORMULA])?.errors?.['incorrect']
                  ) {
                    <div class="mt-1">
                      <label
                        class="text-error"
                        [innerHTML]="mqttRulesFormArray.get([index, FORM_PARAMS.MQTT_RULE_FORMULA])?.errors?.['message']"></label>
                    </div>
                  }
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        }
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ rowData[col.field] }}
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>

<!-- NON IP Analyzer Step 1 Edit Rule Form field -->
<ng-template #nonipRulesField>
  @if (!!form && !!form.form) {
    <app-analyzer-nonip-ruleset-field #nonipRulesetFieldComponent [analyzer]="analyzer" />
  }
</ng-template>
