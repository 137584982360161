<div class="license-card flex flex-column w-full my-2 text-white border-1 border-gray-800 border-round-sm">
  <!-- HEADER -->
  <div class="p-4">
    <label class="text-lg font-bold">
      {{ license.name }}
    </label>
  </div>
  <!-- INFO -->
  <div class="flex flex-wrap row-gap-1 column-gap-8 bg-gray-900 border-y-1 border-gray-800 p-4">
    <div class="flex gap-1">
      <div class="font-bold">Status:</div>
      <fa-icon class="text-green" [icon]="faCheckCircle" />
    </div>
    <div><span class="font-bold">Start Date:</span> {{ (license?.valids_at | date: PIPE_DATETIME) || '-' }}</div>
    <div><span class="font-bold">End Date:</span> {{ (license?.expires_at | date: PIPE_DATETIME) || '-' }}</div>
    <div><span class="font-bold">Uploaded On:</span> {{ (license?.modified | date: PIPE_DATETIME) || '-' }}</div>
  </div>
  <!-- MENU -->
  <div class="p-4">
    <div class="grid">
      <div class="col-fixed w-10rem border-right-1 border-gray-800">
        <div class="flex flex-column gap-4">
          @for (menuItem of menuItems; track $index) {
            <div
              class="text-grey hover:underline cursor-pointer"
              [ngClass]="{ 'font-bold text-highlight': menuItem.value === selectedMenuItem }"
              (click)="onMenuSelected(menuItem)">
              {{ menuItem.label }}
            </div>
          }
        </div>
      </div>
      <div class="col px-4">
        <ng-container [ngSwitch]="selectedMenuItem">
          <ng-container *ngSwitchCase="'registration'">
            <app-registration [license]="license" (uploadLicenseEvent)="onUploadLicense()" (deleteLicenseEvent)="onDeleteLicense()" />
          </ng-container>
          <ng-container *ngSwitchCase="'quotas'">
            <app-quotas [license]="license" />
          </ng-container>
          <ng-container *ngSwitchDefault> </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
