import {
  faClipboardList,
  faDesktop,
  faGear,
  faGlobe,
  faSearch,
  faSitemap,
  faSkullCrossbones,
  faThLarge,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';
import { PROJECT_MANAGEMENT_CHILDREN as COMMON_PROJECT_MANAGEMENT_CHILDREN, ProjectSettingsComponent } from '@ids-products';
import {
  ANALYZER_FEATURES,
  ASSESSMENT_FEATURES,
  CONNECTION_FEATURES,
  DEVICE_FEATURES,
  FEATURES,
  PROJECT_MANAGEMENT_CONSTANTS,
  PROJECT_MANAGEMENT_GROUPS,
  REPORTS_FEATURES,
  THREAT_FEATURES,
} from '@ids-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { DashboardComponent } from './overview/dashboard/dashboard.component';
import { DASHBOARD_CHILDREN } from './overview/dashboard/dashboard.config';
import { NetworkMapComponent } from './overview/network-map/network-map.component';
import { NetworkMapEditorComponent } from './overview/network-map-editor/network-map-editor.component';
import { DevicesComponent } from './identify-manage/devices/devices.component';
import { DEVICES_CHILDREN } from './identify-manage/devices/devices.config';
import { ThreatsComponent } from './detect/threats/threats.component';
import { THREATS_CHILDREN } from './detect/threats/threats.config';
import { AssessmentToolComponent } from './evaluate/assessment-tool/assessment-tool.component';
import { ASSESSMENT_TOOL_CHILDREN } from './evaluate/assessment-tool/assessment-tool.config';
import { NetworkSettingsComponent } from './configuration/network-settings/network-settings.component';
import { NETWORK_SETTINGS_CHILDREN } from './configuration/network-settings/network-settings.config';
import { PROJECT_SETTINGS_CHILDREN } from './configuration/project-settings/project-settings.config';
import { ReportComponent } from './evaluate/report/report.component';
import { REPORT_CHILDREN } from './evaluate/report/report.config';
import { AnomalyAnalyzerComponent } from './configuration/anomaly-analyzer/anomaly-analyzer.component';
import { ANOMALY_ANALYZER_CHILDREN } from './configuration/anomaly-analyzer/anomaly-analyzer.config';
// import { LeavePageGuard } from '@microsec/guards';

export const PROJECT_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.DASHBOARD.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.DASHBOARD.LABEL,
    icon: faThLarge,
    component: DashboardComponent,
    children: DASHBOARD_CHILDREN,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP.LABEL,
    icon: faSitemap,
    component: NetworkMapComponent,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP_EDITOR.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP_EDITOR.LABEL,
    icon: faSitemap,
    component: NetworkMapEditorComponent,
    // canDeactivate: [LeavePageGuard],
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.IDENTIFY_MANAGE,
    route: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.DEVICES.LABEL,
    icon: faDesktop,
    component: DevicesComponent,
    children: DEVICES_CHILDREN,
    visible: false,
    featureName: FEATURES.DEVICES,
    featureCheckField: DEVICE_FEATURES.ENABLED,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.DETECT,
    route: PROJECT_MANAGEMENT_CONSTANTS.THREATS.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.THREATS.LABEL,
    icon: faSkullCrossbones,
    component: ThreatsComponent,
    children: THREATS_CHILDREN,
    visible: false,
    featureName: FEATURES.THREATS,
    featureCheckField: THREAT_FEATURES.ENABLED,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.EVALUATE,
    route: PROJECT_MANAGEMENT_CONSTANTS.ASSESSMENT_TOOL.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.ASSESSMENT_TOOL.LABEL,
    icon: faClipboardList,
    component: AssessmentToolComponent,
    children: ASSESSMENT_TOOL_CHILDREN,
    visible: false,
    featureName: FEATURES.ASSESSMENTS,
    featureCheckField: ASSESSMENT_FEATURES.ENABLED,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.EVALUATE,
    route: PROJECT_MANAGEMENT_CONSTANTS.REPORTS.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.REPORTS.LABEL,
    icon: faFileLines,
    component: ReportComponent,
    children: REPORT_CHILDREN,
    visible: false,
    featureName: FEATURES.REPORTS,
    featureCheckField: REPORTS_FEATURES.ENABLED,
  },
  ...COMMON_PROJECT_MANAGEMENT_CHILDREN,
  {
    isSeparator: true,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.CONFIGURATION,
    route: PROJECT_MANAGEMENT_CONSTANTS.NETWORK_SETTINGS.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.NETWORK_SETTINGS.LABEL,
    icon: faGlobe,
    styleClass: 'tour-network-settings-step',
    component: NetworkSettingsComponent,
    children: NETWORK_SETTINGS_CHILDREN,
    visible: false,
    featureName: FEATURES.CONNECTIONS,
    featureCheckField: CONNECTION_FEATURES.ENABLED,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.CONFIGURATION,
    route: PROJECT_MANAGEMENT_CONSTANTS.ANOMALY_ANALYZER.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.ANOMALY_ANALYZER.LABEL,
    icon: faSearch,
    component: AnomalyAnalyzerComponent,
    children: ANOMALY_ANALYZER_CHILDREN,
    visible: false,
    featureName: FEATURES.ANALYZERS,
    featureCheckField: ANALYZER_FEATURES.ENABLED,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.CONFIGURATION,
    route: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.LABEL,
    icon: faGear,
    component: ProjectSettingsComponent,
    children: PROJECT_SETTINGS_CHILDREN,
    visible: false,
  },
];
