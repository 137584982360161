import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SAVE_CHANGES_LABEL } from '@microsec/constants';
import { CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { TAGS_PRESET_COLORS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { FORM_PARAMS as TAG_FORM_PARAMS } from '../../../tags/tag-form/tag-form.component';
import { TargetDeviceService } from '@ids-services';
import { validatorTrimRequired } from '@microsec/validators';

import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, finalize } from 'rxjs';

@Component({
  selector: 'app-tags-field',
  templateUrl: './tags-field.component.html',
  styleUrls: ['./tags-field.component.scss'],
})
export class TagsFieldComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  form: FormGroup | null = null;

  editedTag: any = null;

  _color = '#ffffff';

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  tags: any[] = [];

  tagOptions: any[] = [];

  _selectedTags: any[] = [];

  get selectedTags() {
    return this._selectedTags;
  }

  @Input() fieldName = 'tag';

  @Input() set selectedTags(value: any[]) {
    this._selectedTags = value;
    this.selectedTagsChange.emit(this._selectedTags);
  }

  @Output() selectedTagsChange: EventEmitter<any> = new EventEmitter<any>();

  TAG_FORM_PARAMS = TAG_FORM_PARAMS;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  TAGS_PRESET_COLORS = TAGS_PRESET_COLORS;

  @ViewChild('addTagPanel') addTagPanel!: OverlayPanel;

  @ViewChild('editTagPanel') editTagPanel!: OverlayPanel;

  @ViewChild('colorField') colorField!: TemplateRef<any>;

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private fb: FormBuilder,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.getTags();
  }

  getTags() {
    this.isLoading = true;
    let getRequest: Observable<any> = new Observable();
    switch (this.fieldName) {
      case 'tag': {
        getRequest = this.targetDeviceSrv.getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
        break;
      }
      case 'zone': {
        getRequest = this.targetDeviceSrv.getZones(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
        break;
      }
      default: {
        break;
      }
    }
    getRequest
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.tags = rs?.[`${this.fieldName}s`] || [];
          this.tagOptions = rs?.[`${this.fieldName}s`] || [];
          if (!!this.selectedTags?.length) {
            const selectedTags: any = [];
            this.selectedTags.forEach((selectedTag) => {
              const tag = this.tags?.find((tag) => tag.id === selectedTag.id);
              if (!!tag) {
                selectedTags.push(tag);
              }
            });
            this.selectedTags = [...selectedTags];
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  initForm() {
    this.form = this.fb.group({
      [TAG_FORM_PARAMS.LABEL]: [this.editedTag?.[TAG_FORM_PARAMS.LABEL] || '', validatorTrimRequired],
    });
    this.form.get(TAG_FORM_PARAMS.LABEL)?.valueChanges.subscribe((value) => {
      if (!this.editedTag) {
        this.searchTags(value || '');
      }
    });
    this.color = this.editedTag?.[TAG_FORM_PARAMS.COLOR] || '#0277FF';
  }

  onSubmit() {
    this.isLoading = true;
    const payload: any = {
      [TAG_FORM_PARAMS.ORGANIZATION_ID]: this.breadcrumbConfig?.organizationId,
      [TAG_FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
      ...this.form?.getRawValue(),
      [TAG_FORM_PARAMS.COLOR]: this.color,
    };
    let request: Observable<any> = new Observable();
    switch (this.fieldName) {
      case 'tag': {
        request = !this.editedTag ? this.targetDeviceSrv.createTag(payload) : this.targetDeviceSrv.updateTag(this.editedTag.id, payload);
        break;
      }
      case 'zone': {
        request = !this.editedTag ? this.targetDeviceSrv.createZone(payload) : this.targetDeviceSrv.updateZone(this.editedTag.id, payload);
        break;
      }
      default: {
        break;
      }
    }

    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const message = !this.editedTag ? CREATE_SUCCESS : UPDATE_SUCCESS;
          if (!this.editedTag) {
            this.selectedTags.push(rs);
          }
          this.getTags();
          this.showSuccessMessage(message.replace('{0}', this.fieldName));
          this.addTagPanel.hide();
          this.editTagPanel.hide();
          this.targetDeviceSrv.refresh$.next(false);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  onClickAddTag(event: any) {
    this.addTagPanel?.hide();
    this.editTagPanel?.hide();
    this.color = '#0277FF';
    setTimeout(() => {
      this.searchTags('');
      this.editedTag = null;
      this.initForm();
      this.addTagPanel?.show(event);
    });
  }

  onClickSelectedTag(event: any, tag: any) {
    this.addTagPanel?.hide();
    this.editTagPanel?.hide();
    this.color = '#0277FF';
    setTimeout(() => {
      this.editedTag = this.util.cloneDeepObject(tag);
      this.initForm();
      this.editTagPanel?.show(event);
    });
  }

  searchTags(str: string) {
    this.tagOptions = this.tags.filter(
      (tag) =>
        !this.selectedTags?.find((selectedTag) => selectedTag.id === tag.id) &&
        (tag.label?.toLowerCase()?.includes(str?.toLowerCase()) || tag.description?.toLowerCase()?.includes(str?.toLowerCase())),
    );
  }

  onSelectTag(value: any) {
    this.selectedTags?.push(value);
    this.addTagPanel?.hide();
  }

  onRemoveTag(id: any) {
    this.selectedTags = this.selectedTags?.filter((tag) => tag.id !== id);
    this.editTagPanel?.hide();
  }
}
