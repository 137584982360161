@if (!!isPage) {
  <microsec-card [isBordered]="true">
    <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
  </microsec-card>
} @else {
  <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
}

<ng-template #formTemplate>
  <microsec-form-builder
    #fb
    [fields]="fields"
    [isPage]="isPage"
    [submitButtonLabel]="!assessmentType ? CREATE_LABEL : SAVE_CHANGES_LABEL"
    (submitEvent)="onSubmit(!isPage ? $event : null)"
    [shouldShowActionButtons]="true"
    [hasInfoFields]="false"
    [additionalConditions]="!!form && !!form.form && !!form.form.touched && !!form.form.dirty">
  </microsec-form-builder>
</ng-template>

<ng-template #descriptionField>
  @if (!!form && !!form.form) {
    <microsec-custom-markdown-editor
      [textareaFormGroup]="form.form"
      [textareaFormControlName]="FORM_PARAMS.DESCRIPTION"></microsec-custom-markdown-editor>
  }
</ng-template>
