import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { closeMessage, setMessage } from '../anomaly-analyzer.component';
import { RuleBasedAnalyzerFormComponent } from './rule-based-analyzer-form/rule-based-analyzer-form.component';
import { AnalyzerIpRuleSettingFormComponent } from './rule-based-analyzer-form/analyzer-ip-rule-setting-form/analyzer-ip-rule-setting-form.component';
import { AnalyzerRuleTypesPipe, ArrayToPlaceholderStringPipe, ConstantPipe } from '@ids-pipes';
import { DecimalPipe } from '@angular/common';
import { verticalSlideAnimation } from '@microsec/animations';
import { BaseComponent } from '@ids-components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ReportSpecification } from '@microsec/models';
import { ANALYZER_DATA_TYPES, ANALYZER_FEATURES, ANALYZER_TYPES, MODE_OPTIONS } from '@ids-constants';
import { CommonTableComponent } from '@microsec/components';
import { AnomalyAnalyzerService, ConnectionService } from '@ids-services';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';

const FIELDS = {
  id: 'ID',
  name: 'Name',
  connection_ids: 'Connection ID',
  protocol: 'Protocol',
  mode: 'Mode',
  training_progress: 'Training Progress',
  rule_types: 'Rule Type',
  enabled: 'Status',
  connection_name: 'Connection',
  type: 'Type',
};

const COLS = [
  { field: 'enabled', header: FIELDS.enabled, width: 10 },
  { field: 'name', header: FIELDS.name, width: 10 },
  { field: 'type', header: FIELDS.type, width: 10 },
  { field: 'connection_name', header: FIELDS.connection_name, width: 15 },
];

@Component({
  selector: 'app-rule-based-analyzers',
  templateUrl: './rule-based-analyzers.component.html',
  styleUrls: ['./rule-based-analyzers.component.scss'],
  providers: [ConstantPipe, DecimalPipe, AnalyzerRuleTypesPipe, ArrayToPlaceholderStringPipe],
  animations: [verticalSlideAnimation],
})
export class RuleBasedAnalyzersComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  cols: any[] = [];

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  globalFilterFields: string[] = ['name', 'type', 'connection_name'];

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
  };

  activeTabIndex = 0;

  type: string = ANALYZER_TYPES.RULE_BASED;

  @Input() dataType = '';

  values: any[] = [];

  _messageValue: any[] = [];

  get messageValue() {
    return this._messageValue;
  }

  set messageValue(value: any[]) {
    this._messageValue = value;
    if (!!value && !value.length) {
      closeMessage(this.type);
    }
  }

  selectedAnalyzers: any[] = [];

  selectedAnalyzer: any = null;

  MODE_OPTIONS = MODE_OPTIONS;

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_DATA_TYPES = ANALYZER_DATA_TYPES;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  @ViewChild('dt') dt!: CommonTableComponent;

  editOptions: MenuItem[] = [];

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private constantPipe: ConstantPipe,
    private decimalPipe: DecimalPipe,
    private analyzerRuleTypesPipe: AnalyzerRuleTypesPipe,
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private connectionSrv: ConnectionService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ANOMALY_ANALYZER_RULE_BASED)) {
      this.messageValue = setMessage(this.type);
      this.cols = this.util.cloneObjectArray(COLS);
      this.selectedColFields = (this.cols || []).filter((col) => col.field !== 'connection_ids').map((col) => col.field);
      this.actionsMenuItems = [
        {
          label: 'Edit',
          icon: 'fas fa-edit',
          visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
          command: ({ rowData }) => this.openAnalyzerForm(rowData),
        },
        {
          label: 'Delete',
          icon: 'fas fa-trash',
          visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
          command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
        },
      ];
      this.getAnalyzers();
      this.filterObjectObs.subscribe((result) => {
        if (result) {
          if (result?.isSortReset && this.dt?.datatable) {
            this.dt.datatable.sortField = null;
            this.dt.datatable.sortOrder = 1;
            this.dt.datatable.multiSortMeta = null;
            this.dt?.datatable.tableService.onSort(null);
            this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
          }
          if (this.filterSearch !== result.search) {
            this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
          }
          this.filterSearch = result.search || '';
        }
      });
      this.subscriptions.forEach((s) => s.unsubscribe());
      const subscription = this.anomalyAnalyzerSrv.refreshObs.subscribe((rs) => {
        if (!!rs && !!this.type) {
          this.getAnalyzers();
        }
      });
      this.subscriptions.push(subscription);
    } else {
      this.navigateToNotFoundPage();
    }
  }

  getAnalyzers(resetSelected = true) {
    this.isLoading = true;
    forkJoin({
      analyzers: this.anomalyAnalyzerSrv.getAnalyzers(this.breadcrumbConfig?.projectId, null, '', this.type),
      ruleBasedNonip: this.anomalyAnalyzerSrv.getAnalyzers(this.breadcrumbConfig?.projectId, null, '', ANALYZER_TYPES.RULE_BASED_NONIP),
      connections: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.anomalyAnalyzerSrv.selected = { ...this.anomalyAnalyzerSrv.selected, id: null };
        }),
      )
      .subscribe({
        next: (res) => {
          // clear the table values when calling getAnalyzers
          this.values = [];
          const ruleBasedNonipValues = res.ruleBasedNonip;
          this.values =
            this.values.concat(res.analyzers.analyzers as any[], !!ruleBasedNonipValues ? (ruleBasedNonipValues.analyzers as any[]) : []) || [];

          const allConnections = res.connections.data || [];
          //Combine the connections and modify table values accordingly
          let modifiedValues: any[] = [];
          modifiedValues = this.values.map((item) => {
            const type = this.anomalyAnalyzerSrv.getDisplayType(this.type, item);
            return {
              ...item,
              tableId: type + String(item.id),
              connection_name: allConnections.find((con: any) => con.id === item.connection_ids[0])?.name || '',
              type: type,
            };
          });

          this.values = [...modifiedValues];

          // Set back the selected Analyzer
          if (!!this.selectedAnalyzer) {
            this.selectedAnalyzer = this.values.find((item) => item.id === this.selectedAnalyzer.id && item.type === this.selectedAnalyzer.type);
          }

          if (!!resetSelected) {
            this.selectedAnalyzers = [];
          }

          // Threat Page link to Analyzer page
          if (!!this.anomalyAnalyzerSrv.selected?.id) {
            const values = this.util.cloneObjectArray(this.values);
            const selectedIndex = values.findIndex((anomaly) => anomaly.id === this.anomalyAnalyzerSrv.selected.id);
            if (selectedIndex >= 0) {
              this.selectedAnalyzer = values[selectedIndex];
              if (selectedIndex + 1 > PER_PAGE) {
                values.splice(selectedIndex, 1);
                values.unshift(this.selectedAnalyzer);
                this.values = values;
              }
            }
            if (!!this.anomalyAnalyzerSrv.selected?.isSnort) {
              this.activeTabIndex = 1;
            }
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  updateAnalyzer(analyzer: any) {
    this.isLoading = true;
    const payload = {
      enabled: analyzer.enabled,
    };
    const updateObservable =
      analyzer.analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP
        ? this.anomalyAnalyzerSrv.updateNonipAnalyzerStatus(analyzer.id, payload)
        : this.anomalyAnalyzerSrv.updateAnalyzer(analyzer.id, payload);

    updateObservable
      .pipe(
        finalize(() => {
          this.anomalyAnalyzerSrv.refresh$.next(true);
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const successMessage =
            analyzer.analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP
              ? 'Non-ip Analyzer Status Updated Successfully'
              : 'Analyzer Status Updated Successfully';
          this.showSuccessMessage(successMessage);
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  updateNonipAnalyzerStatus(analyzer: any) {
    this.isLoading = true;
    const payload = {
      enabled: analyzer.enabled,
    };
    this.anomalyAnalyzerSrv
      .updateNonipAnalyzerStatus(analyzer.id, payload)
      .pipe(
        finalize(() => {
          this.anomalyAnalyzerSrv.refresh$.next(true);
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Analyzer Rule Status Updated Successfully');
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onAnalyzerChangeEvent(analyzer: any) {
    if (!!analyzer) {
      const values = this.values.map((value) => ({
        ...(value.id === analyzer.id && value.type === analyzer.type ? analyzer : value),
      }));
      this.values = values;
    }
  }

  openAnalyzerForm(analyzer: any = null, step?: number) {
    const dialog = this.dialogSrv.open(RuleBasedAnalyzerFormComponent, {
      header: `${!analyzer ? 'Create' : 'Update'} ${this.constantPipe?.transform(this.type, 'anomaly-analyzer-type')} Analyzer`,
      data: { type: this.type, analyzer: analyzer, step: step },
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.selectedAnalyzer = null;
        this.selectedAnalyzers = [];
        this.anomalyAnalyzerSrv.refresh$.next(true);
        if (!!rs.next) {
          delete rs.next;
          if (this.type === ANALYZER_TYPES.RULE_BASED) {
            this.confirm({
              action: `Rule-Based Analyzer ${!analyzer ? 'Created' : 'Updated'}`,
              acceptLabel: 'Proceed',
              rejectLabel: 'Skip',
              customContent: `Rule-Based Analyzer has been succesfully ${
                !analyzer ? 'created' : 'updated'
              }.<br><br>Would you like to proceed with updating the ruleset selection, custom rules, suppressed rules, and IP whitelist for this analyzer?`,
              next: () => {
                this.openIPRuleSettingsForm(rs);
                this.showInfoMessage(`Analyzer ${!analyzer ? 'Created' : 'Updated'}!`);
              },
            });
          }
        }
      }
    });
  }

  openIPRuleSettingsForm(analyzer?: any) {
    const dialog = this.dialogSrv.open(AnalyzerIpRuleSettingFormComponent, {
      header: `Configure Analyzer Rules`,
      data: { analyzer: analyzer },
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    dialog.onClose.subscribe((rs?: any) => {
      if (!!rs) {
        this.anomalyAnalyzerSrv.refresh$.next(true);
      }
    });
  }

  openDeleteConfirmation(analyzers: any[] = []) {
    const analyzerList = analyzers.map((analyzer) => `<li>${`Analyzer ID ${analyzer.id} ${analyzer.name ? `: ${analyzer.name}` : ''}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Analyzer(s)',
      customContent: `Are you sure you want to delete ${
        !analyzers?.length ? 'all analyzers?<br/><br/>' : `the following analyzer(s)?<ul>${analyzerList.join('')}</ul>`
      }`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: forkJoin((!!analyzers.length ? analyzers : this.values).map((analyzer) => this.anomalyAnalyzerSrv.deleteAnalyzer(analyzer.id))),
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`Deleted analyzer(s) successfully`);
        const analyzerIds = analyzers.map((analyzer) => analyzer.id) || [];
        if (!!this.selectedAnalyzers?.length) {
          this.selectedAnalyzers = this.selectedAnalyzers.filter((analyzer) => !analyzerIds?.includes(analyzer.id));
        }
        if (!!this.selectedAnalyzer && !!analyzerIds.includes(this.selectedAnalyzer.id)) {
          this.selectedAnalyzer = null;
        }
        this.getAnalyzers();
      },
      error: (err) => {
        this.isLoading = false;
        this.showErrorMessage(err);
      },
    });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((analyzer) => ({
          ...analyzer,
          enabled: analyzer.enabled?.toString()?.toUpperCase(),
          analyzer_type: this.constantPipe?.transform(analyzer.analyzer_type, 'anomaly-analyzer-type'),
          protocol: this.constantPipe?.transform(analyzer.protocol, 'anomaly-analyzer-protocol', true),
          mode: this.constantPipe?.transform(analyzer.mode, 'anomaly-analyzer-mode'),
          rule_types: this.analyzerRuleTypesPipe?.transform(analyzer.rule_types),
          training_progress: !!analyzer.training_progress ? this.decimalPipe?.transform(analyzer.training_progress, '1.0-2') + '%' : '0%',
        }));
        this.dt.generateReportDialog.exportReport(data, `project_${this.breadcrumbConfig?.projectId}_anomaly_analyzers`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedAnalyzer = null;
  }

  override ngOnDestroy() {
    this.cleanup();
    this.anomalyAnalyzerSrv.refresh$.next(null);
  }
}
