export const PROTOCOL_OPTIONS = [
  { label: 'ETH', value: 'eth' },
  { label: 'IP', value: 'ip' },
  { label: 'TCP', value: 'tcp' },
  { label: 'UDP', value: 'udp' },
  { label: 'LoRaWAN', value: 'lorawan' },
  { label: 'BLE', value: 'ble' },
  { label: 'CAN', value: 'can' },
  { label: 'RTSP', value: 'rtsp' },
  { label: 'RTCP', value: 'rtcp' },
  { label: 'Modbus', value: 'modbus' },
  { label: 'HTTP', value: 'http' },
  { label: 'MQTT', value: 'mqtt' },
  { label: 'ICMP', value: 'icmp' },
  { label: 'SSH', value: 'ssh' },
];

export const NON_IP_PROTOCOLS = ['ble', 'lorawan', 'can', 'goose', 'substation'];

export const IPPROTOCOLS = ['eth', 'ip', 'tcp', 'udp', 'rtsp', 'rtcp', 'rtsp', 'modbus', 'http', 'mqtt'];

export const IP_PROTOCOLS_PAYLOAD = ['eth', 'ip', 'tcp', 'udp', 'lorawan', 'ble', 'can', 'rtsp', 'rtcp', 'modbus', 'http', 'mqtt'];

export const ATTACK_OPTIONS = [
  { label: 'DOS', value: 'dos' },
  { label: 'Jamming', value: 'jamming' },
  { label: 'Tampering', value: 'tampering' },
  { label: 'Replay', value: 'replay' },
];

export const MODES = {
  TRAINING: 'training',
  PREDICTION: 'prediction',
};

export const MODE_OPTIONS = [
  { label: 'Training', value: MODES.TRAINING },
  { label: 'Prediction', value: MODES.PREDICTION },
];

export const SYNC_FREQUENCY_OPTIONS = [
  { label: 'Minutely', value: 60 },
  { label: 'Hourly', value: 3600 },
  { label: 'Daily', value: 86400 },
];

export const EXPORT_TYPE_OPTIONS = [
  // { label: 'multipart', value: 'multipart' },
  { label: 'octet-stream', value: 'octet-stream' },
];

export const ANALYZER_TYPES = {
  ML: 'ml',
  RULE_BASED: 'rule-based',
  RULE_BASED_NONIP: 'rule-based-nonip',
};

export const ANALYZER_TYPE_OPTIONS = [
  { label: 'ML', value: ANALYZER_TYPES.ML },
  { label: 'Rule-Based', value: ANALYZER_TYPES.RULE_BASED },
];

export const ANALYZER_RULE_TYPE_OPTIONS = [
  { label: 'Snort Built-In Rules', value: 'snort_builtin' },
  { label: 'Snort Community Rules', value: 'snort_community' },
  { label: 'Snort Custom Rules', value: 'snort_custom' },
  { label: 'Snort Talos Rules', value: 'snort_talos' },
];

export const ANALYZER_RULE_DOWNLOAD_FREQUENCY_OPTIONS = [
  { label: 'Manual', value: 'manual' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

export const ANALYZER_DATA_TYPES = {
  PACKET: 'packet',
  PAYLOAD: 'payload',
};

export const PARAM_NAMES = {
  DATA_TYPE: 'data_type',
  ANALYZER_TYPE: 'analyzer_type',
};

export const DISPLAY = {
  MQTT: 'Payload',
  IP: 'Packet',
  NONIP: 'Non-IP',
  ML_MQTT: 'Payload',
  ML_OTHERS: 'Packet',
};

export const PROTOCOL_TYPE = {
  IP: 'ip',
  NONIP: 'nonip',
  MQTT: 'mqtt',
};

export const CONN_TYPE = {
  MQTT: 'mqtt',
  IP: 'ip',
  NONIP: 'nonip',
  MIX: 'mix',
};

export const STEP_TEMPLATE_LABEL = {
  DEFAULT: [{ label: 'General<br/>Configuration' }],
  NONIP: [{ label: 'General<br/>Configuration' }, { label: 'Rule<br/>Configuration' }],
  IP: [{ label: 'General<br/>Configuration' }, { label: 'Snort<br/>Configuration' }],
  MQTT: [{ label: 'General<br/>Configuration' }, { label: 'Interruption<br/>Monitoring' }, { label: 'Conditional<br/>Rules' }],
};

export const NONIP_RULE_TYPES = {
  BUILT_IN: 'built-in',
  CUSTOM: 'custom',
};

export const FL_MODEL_TYPES = {
  GMM: 'gmm',
  SVM: 'svm',
};

export const FL_MODEL_TYPE_OPTIONS = [
  { label: 'GMM', value: 'gmm' },
  { label: 'SVM', value: 'svm' },
];

export const FL_AGGREGATION_METHOD_OPTIONS = [
  { label: 'Average', value: 'average' },
  { label: 'Median', value: 'median' },
];

export const NONIP_BUILT_IN_RULES = [
  { id: 1, name: 'ble-rules', description: 'Built-In BLE rules', type: NONIP_RULE_TYPES.BUILT_IN },
  { id: 2, name: 'lora-rules', description: 'Built-In LoRaWAN rules', type: NONIP_RULE_TYPES.BUILT_IN },
  { id: 3, name: 'goose-rules', description: 'Built-In GOOSE rules', type: NONIP_RULE_TYPES.BUILT_IN },
  {
    id: 4,
    name: 'substation-rules',
    description: 'Built-In substation rules',
    type: NONIP_RULE_TYPES.BUILT_IN,
  },
];
