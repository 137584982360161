import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NETWORK_MAP_SETTING_KEYS, NETWORK_MAP_SETTING_VALUES } from '@ids-constants';
import { INTERFACE_TYPE_OPTIONS, LEVEL_OPTIONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';

import { BehaviorSubject, Observable } from 'rxjs';
import * as d3 from 'd3';

import { NetworkMapCommon } from '../network-map-common';
import { NetworkMapHelper } from '@ids-utilities';

@Component({
  selector: 'app-purdue-model',
  templateUrl: './purdue-model.component.html',
  styleUrls: ['./purdue-model.component.scss'],
})
export class PurdueModelComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Input() itemDetails$ = new BehaviorSubject<any>(null);

  @Input() diagramData$ = new BehaviorSubject<any>(null);

  @Input() diagramDataObs = new Observable<any>();

  @Input() settingOptionsObs = new Observable<any>();

  @Input() zoom$ = new BehaviorSubject<any>(null);

  @Input() refresh$ = new BehaviorSubject<any>(null);

  @Input() selectedDevice: any = null;

  @Output() selectedDeviceChange = new EventEmitter<number | null>();

  windowHeight = window.innerHeight;

  windowWidth = window.innerWidth;

  @ViewChild('svgContainer') private svgElement!: ElementRef;
  svgContainer: any = {};
  svg: any = {};
  rect: any = {};

  zoom: any = {};

  maxScaleLimit = 3.0;
  minScaleLimit = 0.1;
  currentScale = 1;
  currentZoomShiftX = 0;
  currentZoomShiftY = 0;
  zoomScaleDiff = 0.75;
  zoomDeviceLimit = 15;

  rawData: any = {};

  threatDeviceIds: any[] = [];
  threatIntervals: any[] = [];

  levelAreaHeight = 120;
  levelAreaGap = 40;
  levelAreaItems: any[] = [];

  root: any = {};
  filteredDevices: any[] = [];

  @Input() settings: any[] = [];

  ngAfterViewInit(): void {
    this.subscriptions.push(
      ...[
        // Update selected device/threat
        this.itemDetails$.asObservable().subscribe((itemDetails) => {
          // Selected Device is updated by Network-Map parent component
          switch (itemDetails?.type) {
            case 'device': {
              if (!this.selectedDevice) {
                this.clearDeviceSelections();
                this.zoomToTarget();
              } else {
                d3.selectAll('g.device-node').each((data: any) => {
                  const groupId = `${this.selectedDevice?.status}-node-group-${this.selectedDevice?.id}`;
                  if (data.groupId === groupId) {
                    data.isClicked = true;
                    this.toggleDeviceNode(data);
                    this.zoomToTarget({ target: data.circle.node() } as MouseEvent);
                  }
                });
              }
              this.threatDeviceIds = [];
              this.clearThreatIntervals();
              break;
            }
            case 'threat': {
              this.clearDeviceSelections();
              this.clearThreatIntervals();
              this.threatDeviceIds = ((this.selectedDevice?.device_ids as any[]) || [])
                .map((p) => p?.device_id)
                .filter((p) => !!this.filteredDevices.map((d) => d.id).includes(p));
              d3.selectAll('g.device-node').each((deviceNode: any) => {
                const data = deviceNode?.data?.data;
                if (!!this.threatDeviceIds.includes(data?.id)) {
                  this.toggleDeviceNode(deviceNode, true);
                  this.setupThreatInterval(deviceNode);
                }
              });
              this.zoomToTarget(!!this.threatDeviceIds?.length ? ({ target: null } as MouseEvent) : undefined, this.threatDeviceIds);
              break;
            }
            default: {
              this.threatDeviceIds = [];
              this.clearDeviceSelections();
              this.clearThreatIntervals();
              if (!itemDetails?.isEmptySpaceClicked) {
                this.zoomToTarget();
              }
              break;
            }
          }
        }),
        // Update zoom
        this.zoom$.asObservable().subscribe((option) => {
          this.updateZoom(option);
        }),
        // Update setting, handle setting change
        this.settingOptionsObs.subscribe((settings: any) => {
          let shouldRefreshDiagram = true;
          if (!!settings && !Array.isArray(settings)) {
            const settingObj: any = settings;
            const setting: any = this.settings.find((p) => p.key === settingObj.key);
            const settingItem = ((setting?.items as any[]) || [])?.find((p) => p.value === settingObj.value);
            if (!!settingItem) {
              settingItem.checked = settingObj.checked;
              if (settingItem.value === NETWORK_MAP_SETTING_VALUES.PROTOCOL.LEGEND) {
                shouldRefreshDiagram = false;
              }
            }
          }
          if (!!shouldRefreshDiagram) {
            this.getFilteredDevices(this.rawData);
            this.drawDiagram();
          }
        }),
        // Update diagram's nodes and links
        this.diagramDataObs.subscribe((data) => {
          // Selected Device is updated by Network-Map parent component
          if (!!data && !!data?.shouldResetDiagram) {
            this.rawData = data;
            this.getFilteredDevices(this.rawData);
            this.drawDiagram();
          }
          // Apply search if any
          if (!!data) {
            const searchedDeviceIds = ((data?.searchedDevices as any[]) || []).map((p) => p?.id);
            this.svg?.selectAll('g.device-node')?.each((deviceNode: any) => {
              const deviceData = deviceNode?.data?.data;
              const isSearchDevice = !!((searchedDeviceIds as any[]) || []).find((p) => p === deviceData.id);
              deviceNode?.searchedCircle?.attr('stroke', isSearchDevice ? NetworkMapCommon.searchedStrokeColor : 'transparent');
              deviceNode?.circle?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
              deviceNode?.selectedCircle?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
              deviceNode?.icon?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
            });
            // Search related links
            if (!!searchedDeviceIds.length) {
              // Zoom
              this.zoomToTarget({ target: null } as MouseEvent, searchedDeviceIds);
              // Search related links
              const links = this.svg.selectAll('path.device-link');
              links.each((node: any, index: any, nodeArray: any) => {
                const data = node?.data?.data;
                const parentData = node?.parent?.data?.data;
                const deviceIdsFromLink = [data?.id, parentData?.id];
                d3
                  .select(nodeArray[index])
                  ?.style('opacity', searchedDeviceIds.filter((p) => !!deviceIdsFromLink.includes(p)).length === 2 ? 0.9 : 0.5);
              });
            } else {
              this.svg.selectAll('path.device-link').each((node: any, index: any, nodeArray: any) => {
                d3.select(nodeArray[index])?.style('opacity', 0.9);
              });
            }
          }
        }),
      ],
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.clearTooltip();
  }

  getFilteredDevices(data: any) {
    const filteredDevices: any[] = this.getDevicesFilteredByConnections(data);
    const filteredLinks: any[] = this.getLinksFilteredByDevices(data, filteredDevices);
    const devicesWithParent = this.getDevicesWithParent(filteredDevices, filteredLinks);
    const cycledDevices = this.resolveCycleDevices(this.util.sortObjectArray(devicesWithParent, 'id', false));
    const sortedDevices = this.sortRelatedDevices(cycledDevices);
    sortedDevices.unshift({
      parent: null,
      id: 0,
      label: 'root',
      network_map_level: -2,
      invisible_node: true,
    });
    this.filteredDevices = sortedDevices;
    this.diagramData$.next({ shouldResetDiagram: false, filteredDevices: this.filteredDevices });
  }

  private sortRelatedDevices(devices: any[]) {
    if (!!devices?.length) {
      const devicesCopy: any[] = [...devices]; // Create a copy of the devices array
      const parentChildDict: any = {};

      // Populate parent-child dictionary
      devicesCopy.forEach((device) => {
        const parentId = device.parent;
        if (!parentChildDict[parentId]) {
          parentChildDict[parentId] = [];
        }
        parentChildDict[parentId].push(device);
      });

      // Sort devices array based on parent-child relationships
      const sortedDevices: any[] = [];

      const parentChildDictIdsArray: any[] = [];
      const traverse = (parentId: any) => {
        if (!!parentChildDict[parentId] && !parentChildDictIdsArray.includes(parentId)) {
          parentChildDictIdsArray.push(parentId);
          ((parentChildDict[parentId] as any[]) || []).forEach((device) => {
            if (!sortedDevices.find((p) => p.id === device.id)) {
              sortedDevices.push(device);
            }
            traverse(device.id);
          });
        } else {
          const firstNextParentId = Object.keys(parentChildDict).filter((p) => !parentChildDictIdsArray.includes(p))?.[0];
          if (!!firstNextParentId) {
            traverse(firstNextParentId);
          }
        }
      };

      traverse(devicesCopy[0].parent); // Start traversing from the parent of the first device

      return sortedDevices;
    }
    return [];
  }

  private getDevicesFilteredByConnections(data: any) {
    const devices: any[] = data?.devices || [];
    const connections: any[] = data?.connections || [];
    // Filter devices by connections
    let filteredDevices: any[] = !!connections
      ? devices.filter((device) => {
          return ((device.connection_ids as any[]) || []).every((connectionId) => {
            return connections?.map((c) => c?.id)?.includes(connectionId);
          });
        })
      : devices;
    // Init some property for devices
    filteredDevices = filteredDevices.map((device) => ({
      ...device,
      invisible_node: device?.hybrid_monitor_passive_device_id === -1,
      imaginary_device: device?.hybrid_monitor_passive_device_id === -1,
    }));
    return filteredDevices;
  }

  private getLinksFilteredByDevices(data: any, filteredDevices: any[]) {
    const links: any[] = data?.links || [];
    // Make first priority for type IP
    const sortedLinks: any[] = [...links.filter((p) => p.communication_protocol === 'ip'), ...links.filter((p) => p.communication_protocol !== 'ip')];
    let filteredLinks: any[] = [];
    sortedLinks.forEach((link: any) => {
      const srcDeviceNode = filteredDevices.find((p) => p.id === link.src_device_id);
      const destDeviceNode = filteredDevices.find((p) => p.id === link.dest_device_id);
      if (!!srcDeviceNode && !!destDeviceNode && srcDeviceNode?.id !== destDeviceNode?.id) {
        filteredLinks.push(link);
      }
    });
    const pairs: any = {};
    filteredLinks = filteredLinks.filter((link) => {
      if (pairs[link.src_device_id] === link.dest_device_id || pairs[link.dest_device_id] === link.src_device_id) {
        return false;
      }
      pairs[link.src_device_id] = link.dest_device_id;
      return true;
    });
    // Get node links by making sure that no duplicated pair of src and dest found
    filteredLinks = filteredLinks.filter((v1, i, a) => a.findIndex((v2) => ['src_device_id', 'dest_device_id'].every((k) => v1[k] === v2[k])) === i);
    return filteredLinks;
  }

  private resolveCycleDevices(devices: any[]) {
    // Check cycles
    const sortedDevices = [...devices.map((p) => ({ ...p }))];
    sortedDevices.forEach((node) => {
      if (node.parent > 0) {
        sortedDevices.push(sortedDevices.splice(sortedDevices.indexOf(node), 1)[0]);
      }
    });
    devices = sortedDevices;

    const cycle = this.adjacencyList(devices);
    const sourceVertex: any[] = [];
    cycle.forEach((cycleVertex) => {
      devices.forEach((item) => {
        if (item.parent === cycleVertex) {
          sourceVertex.push(item.id);
        }
      });
    });
    sourceVertex.forEach((source) => {
      devices.forEach((item) => {
        if (item.id === source) {
          item.parent = 0;
        }
      });
    });
    return sortedDevices;
  }

  private getDevicesWithParent(devices: any[], links: any[]) {
    const devicesWithParent: any[] = [];

    const filteredLinks: any[] = [];
    const pairs: any = {};
    links.forEach((link) => {
      const src = link.src_device_id < link.dest_device_id ? link.src_device_id : link.dest_device_id;
      const dst = link.dest_device_id > link.src_device_id ? link.dest_device_id : link.src_device_id;
      if (!pairs[`${src}_${dst}`]) {
        pairs[`${src}_${dst}`] = true;
        filteredLinks.push({ ...link, src_device_id: src, dest_device_id: dst });
      }
    });

    filteredLinks.forEach((link) => {
      const destDevice: any = devices.find((p) => p.id === link.dest_device_id);
      if (!!destDevice) {
        destDevice.parent = destDevice.id === link.src_device_id ? 0 : link.src_device_id;
        destDevice.communication_protocol = link.communication_protocol;
        if (!devicesWithParent.find((p) => p.id === destDevice.id)) {
          devicesWithParent.push(destDevice);
        }
      }
    });
    const deviceWithParentIds: any[] = devicesWithParent.map((p) => p.id);
    const devicesWithoutParent: any[] = [];
    devices.forEach((device) => {
      if (!deviceWithParentIds.includes(device.id)) {
        device.parent = 0;
        devicesWithoutParent.push(device);
      }
    });
    return [...devicesWithParent, ...devicesWithoutParent];
  }

  /**
   * ========================================================================================================================================================
   * DRAW DIAGRAM
   * ========================================================================================================================================================
   */

  /**
   * Draw diagram
   */
  drawDiagram() {
    d3.select(this.svgElement.nativeElement).selectAll('*').remove();
    this.setupZoomOptions();
    this.svgContainer = d3
      .select(this.svgElement.nativeElement)
      .attr('width', this.maxWidth)
      .attr('height', this.maxHeight)
      .append('g')
      .attr('class', 'svg-container')
      .attr('transform', `translate(${-this.viewX},${-this.viewY})`)
      .call(this.zoom);

    this.svg = this.svgContainer
      .append('g')
      .attr('class', 'svg')
      .attr('width', this.maxWidth)
      .attr('height', this.maxHeight)
      .attr('transform', 'translate(0, 0)');

    this.rect = this.svg.append('rect').attr('x', 0).attr('width', this.maxWidth).attr('height', this.maxHeight).attr('fill', 'transparent');

    this.drawDevicesHierarchy();
    this.setupBackground();
  }

  /**
   * Setup level areas and the background events
   */
  setupBackground() {
    const levelAreas: any[] = this.drawLevelAreas();

    [this.rect, ...levelAreas].forEach((rect) => {
      rect.on('click', (event: MouseEvent) => {
        if (event.target === event.currentTarget) {
          if (!!this.selectedDevice) {
            this.showDeviceDetails(null, true);

            // Click on background (-): Remove Selected Device from Network-map parent component
            this.selectedDevice = null;
            this.selectedDeviceChange.emit(null);
          }
          if (!!this.threatDeviceIds.length) {
            this.threatDeviceIds = [];
            this.showDeviceDetails(null, true);
          }

          setTimeout(() => {
            this.clearDeviceSelections();
            this.clearThreatIntervals();
          });
        }
      });
    });
  }

  /**
   * Draw level areas
   */
  drawLevelAreas() {
    d3.select('.level-area-group').remove();
    const levelAreaSelection = this.svg
      .insert('g', ':first-child')
      .attr('class', 'level-area-group')
      .attr('transform', `translate(${this.viewX},${this.viewY})`);

    const levelAreas: any[] = [];
    let lastY = 0;
    const nodesGroupElement = d3.select('g.node-area-group').node() as SVGGElement;
    const nodesGroup = nodesGroupElement?.getBBox();
    this.levelAreaItems.forEach((levelAreaItem) => {
      // Areas
      const rectX = 200;
      const rectY = lastY;
      const areaHeight = this.levelAreaHeight + (levelAreaItem.dYList.length > 0 ? levelAreaItem.dYList.length - 1 : 0) * this.levelAreaHeight;
      const levelArea = levelAreaSelection
        .append('rect')
        .datum(levelAreaItem)
        .attr('class', `level-area-${levelAreaItem.value}`)
        .attr('x', rectX)
        .attr('y', rectY)
        .attr('width', nodesGroup.width + rectX * 2)
        .attr('height', areaHeight)
        .attr('stroke', 'none')
        .attr('fill', '#1c1c1c')
        .attr('rx', 10)
        .attr('ry', 10)
        .attr('opacity', 1);
      levelArea.style('filter', `drop-shadow(0px ${this.levelAreaGap / 4}px ${this.levelAreaGap / 4}px rgba(0, 0, 0, 0.25))`);
      levelAreas.push(levelArea);

      // Labels
      const labelLeftMargin = 25;
      const labelTopMargin = this.levelAreaHeight / 2;
      levelAreaSelection
        .append('text')
        .attr('x', rectX + labelLeftMargin)
        .attr('y', rectY + labelTopMargin - (levelAreaItem.value === -1 ? 0 : 10))
        .style('fill', NetworkMapCommon.textColor)
        .style('font-weight', 'bold')
        .text(levelAreaItem.value === -1 ? 'Not Defined' : `Level ${levelAreaItem.label}`);
      // Sub-labels
      levelAreaSelection
        .append('text')
        .attr('x', rectX + labelLeftMargin)
        .attr('y', rectY + labelTopMargin + 15)
        .style('fill', NetworkMapCommon.textColor)
        .text(levelAreaItem.subLabel);

      // Update lastY
      lastY = rectY + areaHeight + this.levelAreaGap;
    });
    // Move the level area to the devices
    const nodesGroupX = (d3.select('g.node-area-group').node() as SVGGElement).getBoundingClientRect()?.left;
    const levelAreaGroupX = (d3.select('g.level-area-group').node() as SVGGElement).getBoundingClientRect()?.left;
    const levelAreaGroupPos = this.parseTransformString(d3.select('g.level-area-group').attr('transform'));
    d3.select('g.level-area-group').attr(
      'transform',
      `translate(${levelAreaGroupPos.x - (Math.abs(levelAreaGroupX - nodesGroupX) + 250)},${levelAreaGroupPos.y})`,
    );
    return levelAreas;
  }

  // Function to parse transform string and extract translation values
  private parseTransformString(transformString: string): { x: number; y: number } {
    const translateValues = { x: 0, y: 0 };

    if (transformString) {
      const match = transformString.match(/translate\(([^,]+),([^,]+)\)/);
      if (match) {
        translateValues.x = parseFloat(match[1]);
        translateValues.y = parseFloat(match[2]);
      }
    }

    return translateValues;
  }

  /**
   * Draw devices hierarchy
   */
  drawDevicesHierarchy() {
    // Get tree data
    const treeData = d3
      .stratify()
      .id((d: any) => d.id)
      .parentId((d: any) => d.parent)(this.filteredDevices);
    // Define styles
    NetworkMapCommon.defineStyles(this.svg);

    this.root = d3.hierarchy(treeData, (d) => d.children);

    this.root.x0 = 0;
    this.root.y0 = 0;

    this.updateDevicesTree(this.root);
  }

  /**
   * Update devices tree
   * @param source
   */
  updateDevicesTree(source: any) {
    // Setup the area for device nodes and links
    const nodeAreaGroup = !this.svg?.selectAll('.node-area-group')?.size()
      ? this.svg.append('g').attr('class', 'node-area-group').attr('transform', `translate(${this.viewX},${this.viewY})`)
      : this.svg.select('.node-area-group');
    // Get device nodes and links from tree
    const treeMap = d3
      .tree()
      .nodeSize([200, 200])
      .separation((a) => (a.depth === 1 ? 1 : 0.5));
    const treeData = treeMap(this.root);
    const deviceNodes = treeData.descendants();
    const links = treeData.descendants().slice(1);
    // Get device node group positions
    this.setupDeviceNodePositions(deviceNodes);
    // Get device node groups
    const { allDeviceNodes, deviceNodeGroups } = this.getDeviceNodeGroups(source, nodeAreaGroup, deviceNodes);

    // Decorate for normal device nodes
    this.setupNormalNodeSelection(deviceNodeGroups);
    // Decorate for anomalous device nodes
    this.setupAnomalousNodeSelection(deviceNodeGroups);
    // Setup device node events
    this.setupDeviceNodeMouseEvents(deviceNodeGroups);
    // Setup Expand/collapse buttons
    // this.setupDeviceNodeToggleButton(deviceNodeGroups, source);

    const nodeUpdate = deviceNodeGroups.merge(allDeviceNodes);

    nodeUpdate.attr('transform', (d: any) => 'translate(' + (d.x || 0) + ',' + (d.y || 0) + ')');

    nodeUpdate
      .attr('r', 10)
      .attr('cursor', 'pointer')
      .attr('display', (d: any) => {
        if (d.data.data.invisible_node) return 'none';
        return 'block';
      });

    const nodeExit = allDeviceNodes
      .exit()
      .attr('transform', () => 'translate(' + source.x + ',' + source.y + ')')
      .remove();

    nodeExit.select('circle').attr('r', 1e-6);

    nodeExit.select('text').style('fill-opacity', 1e-6);

    this.setupLinksSelection(nodeAreaGroup, links);

    this.setupNodeTextsSelection(deviceNodeGroups);

    deviceNodes.forEach((d: any) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    if (!!this.selectedDevice) {
      this.itemDetails$.next({ type: this.itemDetails$.value?.type, data: this.selectedDevice });
    } else {
      setTimeout(() => {
        this.updateZoom(0);
      }, 1000);
    }
  }

  getRootNode(node: any): any {
    if (!!node.parent && node.depth > 1) {
      return this.getRootNode(node.parent);
    }
    return node?.data?.data?.id || 0;
  }

  setupDeviceNodePositions(deviceNodes: any[]) {
    // Define the height of the nodes tree
    const levelAreaItems = this.util.sortObjectArray(this.util.cloneObjectArray(LEVEL_OPTIONS), 'value', false);
    this.levelAreaItems = levelAreaItems.map((p) => ({ ...p, rowNumbers: [], dYList: [] }));

    // Calculate device area's Y
    this.calculateNodeYPositions(deviceNodes);

    // Calculate device area's X
    // Will be done in step: draw level areas

    const treeLastXPosition: any = {};
    const treeRoots: number[] = [];

    // set gap between node
    const nodeXPosition: any = {};
    this.util.sortObjectArray(deviceNodes, 'x').forEach((d: any) => {
      if (!!d.parent) {
        const sameLevelNodeGap = this.levelAreaHeight / 4;
        for (let y = d.y - sameLevelNodeGap; y <= d.y + sameLevelNodeGap; y++) {
          if (!nodeXPosition[y]) {
            nodeXPosition[y] = [];
          }

          const xPositions = nodeXPosition[y];
          if (xPositions.length === 0) {
            xPositions.push(d.x);
          } else {
            const lastXPosition = xPositions[xPositions.length - 1];
            if (d.x - lastXPosition < 100) {
              d.x = lastXPosition + 100;
            }
            xPositions.push(d.x);
          }
        }

        treeLastXPosition[d.data.data.root] = d.x;
        if (d.depth === 1) {
          treeRoots.push(d.data.data.id);
        }
      }
    });

    // set gap between tree
    let latestXPosition: any = null;
    treeRoots.forEach((root, index) => {
      if (index === 0) {
        latestXPosition = treeLastXPosition[root];
      } else {
        let currentXPosition = latestXPosition;
        let gap = 0;
        this.util
          .sortObjectArray(deviceNodes, 'x')
          .filter((d: any) => d.data.data.root === root)
          .forEach((d: any, i: any) => {
            if (i === 0) {
              gap = latestXPosition - d.x + 200;
            }
            d.x += gap;
            currentXPosition = d.x;
          });
        latestXPosition = currentXPosition;
      }
    });
  }

  private calculateNodeYPositions(deviceNodes: any[]) {
    this.getRowNumbers(deviceNodes);
    let lastStartDY = this.levelAreaHeight / 2;
    this.levelAreaItems.forEach((levelAreaItem) => {
      const rowNumbers = levelAreaItem.rowNumbers.length;
      levelAreaItem.startDY = lastStartDY;
      lastStartDY += this.levelAreaHeight + (rowNumbers > 0 ? rowNumbers - 1 : 0) * this.levelAreaHeight + this.levelAreaGap;
    });
    // Get the node's y and update the height of the area
    let startDY = 0;
    deviceNodes.forEach((d: any) => {
      const data = d.data.data;
      data.root = this.getRootNode(d);
      const nodeLevel = data.network_map_level;
      const levelAreaItem = this.levelAreaItems.find((p) => p.value === nodeLevel);
      startDY = levelAreaItem?.startDY;

      // Check if it has a parent with the same level
      if (!!d.parent && nodeLevel === d.parent.data.data.network_map_level) {
        // Check if it's a child or parent node
        if (d.parent.children.length < 3) {
          // Parent node with 1 or 2 children
          d.y = d.parent.y;
        } else {
          // Parent node with 3 or more children
          d.y = d.parent.y + this.levelAreaHeight;
        }
      } else {
        d.y = startDY;
      }
      // Get the list of existing d.y
      if (!!levelAreaItem && !levelAreaItem.dYList.includes(d.y)) {
        levelAreaItem.dYList.push(d.y);
      }
    });
  }

  private getRowNumbers(deviceNodes: any[]) {
    deviceNodes.forEach((d: any) => {
      const data = d.data.data;
      data.root = this.getRootNode(d);
      const nodeLevel = data.network_map_level;
      const levelAreaItem = this.levelAreaItems.find((p) => p.value === nodeLevel);

      // Check if it has a parent with the same level
      if (!!d.parent && nodeLevel === d.parent.data.data.network_map_level) {
        // Check if it's a child or parent node
        const parentData = d.parent.data.data;
        data.rowNumber = parentData.rowNumber + (d.parent.children.length < 3 ? 0 : 1);
      } else {
        data.rowNumber = 0;
      }
      // Get the list of existing d.y
      if (!!levelAreaItem && !levelAreaItem.rowNumbers.includes(data.rowNumber)) {
        levelAreaItem.rowNumbers.push(data.rowNumber);
      }
    });
  }

  getDeviceNodeGroups(source: any, nodeAreaGroup: any, deviceNodes: any[]) {
    this.svg.selectAll('g.device-node').remove().exit();
    let i = 0;
    const allDeviceNodes = nodeAreaGroup.selectAll('g.device-node').data(deviceNodes, (d: any) => d.id || (d.id = ++i));

    return {
      allDeviceNodes,
      deviceNodeGroups: allDeviceNodes
        .enter()
        .append('g')
        .attr('class', 'device-node')
        .attr('transform', () => 'translate(' + source.x0 + ',' + source.y0 + ')'),
    };
  }

  setupDeviceNodeMouseEvents(deviceNodes: any) {
    deviceNodes.on('mouseover', (event: any, deviceNode: any) => {
      const data = deviceNode?.data?.data;
      // circle
      const circle = deviceNode.circle;
      switch (data?.status) {
        case 'normal': {
          circle.attr('stroke', !deviceNode.isClicked ? NetworkMapCommon.normalHoverStrokeColor : NetworkMapCommon.normalClickedStrokeColor);
          circle.attr('fill', !deviceNode.isClicked ? NetworkMapCommon.circleHoverFillColor : NetworkMapCommon.circleNormalClickedFillColor);
          break;
        }
        case 'anomalous': {
          circle.attr('stroke', !deviceNode.isClicked ? NetworkMapCommon.anomalousHoverStrokeColor : NetworkMapCommon.anomalousClickedStrokeColor);
          circle.attr('fill', !deviceNode.isClicked ? NetworkMapCommon.circleHoverFillColor : NetworkMapCommon.circleAnomalousClickedFillColor);
          break;
        }
        default: {
          break;
        }
      }
      // lines
      this.highlightRelatedLinks(data, true);
      // Show tooltip
      this.clearTooltip();
      this.drawTooltip(deviceNode.group, event);
    });
    deviceNodes.on('mouseout', (event: any, deviceNode: any) => {
      const data = deviceNode?.data?.data;
      if (!deviceNode?.isClicked && !this.threatDeviceIds.includes(data?.id)) {
        // circle
        const circle = deviceNode.circle;
        switch (data?.status) {
          case 'normal': {
            circle.attr('stroke', !deviceNode.isClicked ? NetworkMapCommon.normalStrokeColor : NetworkMapCommon.normalClickedStrokeColor);
            circle.attr('fill', !deviceNode.isClicked ? NetworkMapCommon.circleFillColor : NetworkMapCommon.circleNormalClickedFillColor);
            break;
          }
          case 'anomalous': {
            circle.attr('stroke', !deviceNode.isClicked ? NetworkMapCommon.anomalousStrokeColor : NetworkMapCommon.anomalousClickedStrokeColor);
            circle.attr('fill', !deviceNode.isClicked ? NetworkMapCommon.circleFillColor : NetworkMapCommon.circleAnomalousClickedFillColor);
            break;
          }
          default: {
            break;
          }
        }
        // lines
        this.highlightRelatedLinks(data, false);
      }
      // Clear tooltip
      this.clearTooltip();
    });
    deviceNodes.on('click', (event: any, deviceNode: any) => {
      if (event?.target?.tagName !== 'image') {
        const data = deviceNode?.data?.data;
        deviceNode.isClicked = !deviceNode.isClicked;
        if (!!deviceNode.isClicked) {
          this.clearDeviceSelections(deviceNode.groupId);
          this.zoomToTarget(event);
          // Click on Node (+): Add Selected Device to Network-map parent component
          this.selectedDevice = data;
          this.selectedDeviceChange.emit(this.selectedDevice);
        } else {
          // Double Click on Node (-): Remove Selected Device from Network-map parent component
          this.selectedDevice = null;
          this.selectedDeviceChange.emit(null);
        }

        setTimeout(() => {
          this.showDeviceDetails(!!deviceNode.isClicked ? data : null);
          this.toggleDeviceNode(deviceNode);
        });
      }
      // Clear tooltip
      this.clearTooltip();
    });
  }

  /**
   * Draw device node
   * @param deviceNodesSelection
   */
  setupNormalNodeSelection(deviceNodesSelection: any) {
    const nodesSelection = deviceNodesSelection.filter((p: any) => {
      const data = p?.data?.data;
      return data.status === 'normal';
    });
    // Draw selected circle
    nodesSelection
      .append('circle')
      .attr('class', 'selected')
      .attr('r', NetworkMapCommon.circleRadius + 6)
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');
    // Draw circle
    nodesSelection
      .append('circle')
      .attr('class', 'main')
      .attr('r', NetworkMapCommon.circleRadius)
      .attr('cursor', 'pointer')
      .attr('fill', NetworkMapCommon.circleFillColor)
      .attr('stroke', NetworkMapCommon.normalStrokeColor)
      .attr('stroke-width', '3px');

    // Draw search circle
    nodesSelection
      .append('circle')
      .attr('class', 'searched')
      .attr('r', NetworkMapCommon.circleRadius)
      .attr('cursor', 'pointer')
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');

    // Add the icon
    nodesSelection
      .append('foreignObject')
      .attr('class', 'text-white')
      .attr('x', () => {
        return nodesSelection.attr('x') - NetworkMapCommon.circleRadius;
      })
      .attr('y', () => {
        return nodesSelection.attr('y') - NetworkMapCommon.circleRadius;
      })
      .attr('cursor', 'pointer')
      .attr('width', 2 * NetworkMapCommon.circleRadius)
      .attr('height', 2 * NetworkMapCommon.circleRadius);

    const nodeElements = nodesSelection.nodes();

    for (let i = 0; i < nodeElements.length; i++) {
      const nodeElement = nodeElements[i];
      const nodeData: any = d3.select(nodeElement).datum();
      const groupId = `normal-node-group-${nodeData?.data.data?.id}`;
      nodeData.groupId = groupId;
      nodeData.group = d3.select(nodeElement);
      nodeData.isClicked = false;
      const selectedCircleObject = d3.select(nodeElement).select('circle.selected');
      nodeData.selectedCircle = selectedCircleObject;
      const searchedCircleObject = d3.select(nodeElement).select('circle.searched');
      nodeData.searchedCircle = searchedCircleObject;
      const circleObject = d3.select(nodeElement).select('circle.main');
      nodeData.circle = circleObject;
      const iconObject = d3.select(nodeElement).select('foreignObject');
      const iconSize = NetworkMapCommon.circleRadius;
      const iconPadding = NetworkMapCommon.circleRadius / 2;
      const iconObj: any = NetworkMapHelper.getNetworkMapIcon(nodeData?.data.data?.type, iconSize, iconPadding);
      this.setNetworkMapIcon(iconObject, iconObj, iconSize, iconPadding);
      nodeData.icon = iconObject;
    }
  }

  /**
   * Draw anomalous device node
   * @param deviceNodesSelection
   */
  setupAnomalousNodeSelection(deviceNodesSelection: any) {
    const nodesSelection = deviceNodesSelection.filter((p: any) => {
      const data = p?.data?.data;
      return data.status === 'anomalous';
    });
    // Draw selected circle
    nodesSelection
      .append('circle')
      .attr('class', 'selected')
      .attr('r', NetworkMapCommon.circleRadius + 6)
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');
    // Draw 1
    nodesSelection
      .append('circle')
      .attr('class', 'layer-1-circle')
      .attr('r', NetworkMapCommon.circleRadius + (NetworkMapCommon.circleRadius / 3) * 2)
      .attr('fill', '#FB4D58')
      .attr('opacity', '0.1');

    // Draw 2
    nodesSelection
      .append('circle')
      .attr('class', 'layer-2-circle')
      .attr('r', NetworkMapCommon.circleRadius + NetworkMapCommon.circleRadius / 3)
      .attr('fill', '#FB4D58')
      .attr('opacity', '0.1');

    // Draw circle
    nodesSelection
      .append('circle')
      .attr('class', 'main')
      .attr('r', NetworkMapCommon.circleRadius)
      .attr('cursor', 'pointer')
      .attr('fill', NetworkMapCommon.circleFillColor)
      .attr('stroke', NetworkMapCommon.anomalousStrokeColor)
      .attr('stroke-width', '3px');

    // Draw search circle
    nodesSelection
      .append('circle')
      .attr('class', 'searched')
      .attr('r', NetworkMapCommon.circleRadius)
      .attr('cursor', 'pointer')
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');

    // Add the icon
    nodesSelection
      .append('foreignObject')
      .attr('class', 'network-map-anomalous-icon')
      .attr('x', () => {
        return nodesSelection.attr('x') - NetworkMapCommon.circleRadius;
      })
      .attr('y', () => {
        return nodesSelection.attr('y') - NetworkMapCommon.circleRadius;
      })
      .attr('cursor', 'pointer')
      .attr('width', 2 * NetworkMapCommon.circleRadius)
      .attr('height', 2 * NetworkMapCommon.circleRadius);

    const nodeElements = nodesSelection.nodes();

    for (let i = 0; i < nodeElements.length; i++) {
      const nodeElement = nodeElements[i];
      const nodeData: any = d3.select(nodeElement).datum();
      const groupId = `anomalous-node-group-${nodeData?.data.data?.id}`;
      nodeData.groupId = groupId;
      nodeData.group = d3.select(nodeElement);
      nodeData.isClicked = false;
      const selectedCircleObject = d3.select(nodeElement).select('circle.selected');
      nodeData.selectedCircle = selectedCircleObject;
      const searchedCircleObject = d3.select(nodeElement).select('circle.searched');
      nodeData.searchedCircle = searchedCircleObject;
      const circleObject = d3.select(nodeElement).select('circle.main');
      nodeData.circle = circleObject;
      nodeData.circle1 = d3.select(nodeElement).select('circle.layer-1-circle');
      nodeData.circle2 = d3.select(nodeElement).select('circle.layer-2-circle');
      const iconObject = d3.select(nodeElement).select('foreignObject');
      const iconSize = 20;
      const iconPadding = NetworkMapCommon.circleRadius / 2;
      const iconObj: any = NetworkMapHelper.getNetworkMapIcon(nodeData?.data.data?.type, iconSize, iconPadding);
      this.setNetworkMapIcon(iconObject, iconObj, iconSize, iconPadding);
      nodeData.icon = iconObject;
    }
  }

  /**
   * Draw links
   */
  setupLinksSelection(nodeAreaGroup: any, links: any[]) {
    this.svg.selectAll('path.device-link').remove().exit();

    const link = nodeAreaGroup.selectAll('path.device-link').data(links, (d: any) => d.id);

    const linkEnter = link
      .enter()
      .insert('path', 'g')
      .attr('id', (d: any) => {
        const data = d.data.data;
        return `device-link-s-${data.id}-e-${data.parent}-device-link`;
      })
      .attr('class', 'device-link')
      .attr('display', (d: any) => {
        if (d.depth === 1) return 'none';
        return 'block';
      })
      .style('stroke', (d: any) => {
        const data = d.data.data;
        let linkColor = NetworkMapCommon.linkColor;
        // SETTING: DEVICE COMMUNICATION PROTOCOL
        if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
          linkColor = INTERFACE_TYPE_OPTIONS.find((p) => p.value === data.communication_protocol)?.color || NetworkMapCommon.linkColor;
        }
        return linkColor;
      })
      .style('stroke-width', 3)
      .style('fill', 'none')
      .style('opacity', '0.9')
      .on('mouseover', (event: any, node: any) => {
        const data = node.data.data;
        let linkColor = NetworkMapCommon.linkColor;
        // SETTING: DEVICE COMMUNICATION PROTOCOL
        if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
          linkColor = INTERFACE_TYPE_OPTIONS.find((p) => p.value === data.communication_protocol)?.color || NetworkMapCommon.linkColor;
          linkColor = this.getNewShadeColor(linkColor, 90);
        } else {
          linkColor = !!node?.isClicked || !!node?.parent?.isClicked ? NetworkMapCommon.linkClickedColor : NetworkMapCommon.linkHoverColor;
        }
        // If node is clicked, keep the default color
        if (!!node.isClicked || !!node?.parent?.isClicked) {
          const status = !!node.isClicked ? data.status : node?.parent?.data?.data?.status;
          switch (status) {
            case 'normal': {
              linkColor = NetworkMapCommon.normalClickedStrokeColor;
              break;
            }
            case 'anomalous': {
              linkColor = NetworkMapCommon.anomalousClickedStrokeColor;
              break;
            }
            case '': {
              break;
            }
          }
        }
        d3.select(event.target).style('stroke', linkColor);
      })
      .on('mouseout', (event: any, node: any) => {
        const data = node.data.data;
        let linkColor = NetworkMapCommon.linkColor;
        // SETTING: DEVICE COMMUNICATION PROTOCOL
        if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
          linkColor = INTERFACE_TYPE_OPTIONS.find((p) => p.value === data.communication_protocol)?.color || NetworkMapCommon.linkColor;
          linkColor = !!node?.isClicked || !!node?.parent?.isClicked ? this.getNewShadeColor(linkColor, 90) : linkColor;
        } else {
          linkColor = !!node?.isClicked || !!node?.parent?.isClicked ? NetworkMapCommon.linkClickedColor : NetworkMapCommon.linkColor;
        }
        // If node is clicked, keep the default color
        if (!!node.isClicked || !!node?.parent?.isClicked) {
          const status = !!node.isClicked ? data.status : node?.parent?.data?.data?.status;
          switch (status) {
            case 'normal': {
              linkColor = NetworkMapCommon.normalClickedStrokeColor;
              break;
            }
            case 'anomalous': {
              linkColor = NetworkMapCommon.anomalousClickedStrokeColor;
              break;
            }
            case '': {
              break;
            }
          }
        }
        d3.select(event.target).style('stroke', linkColor);
      });

    linkEnter.merge(link).attr('d', (d: any) => {
      if (d.parent.data.data.invisible_node === true) {
        return this.straightLink(d, d.parent);
      } else {
        return this.diagonalLink(d, d.parent);
      }
    });
  }

  /**
   * Draw texts
   */
  setupNodeTextsSelection(deviceNodeGroups: any) {
    let textPosition = 20;
    // SETTING: DEVICE NAME
    if (!!this.getDeviceSetting(NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_NAME)) {
      textPosition += 20;
      deviceNodeGroups
        .append('text')
        .attr('font-size', 12)
        .attr('text-anchor', 'middle')
        .attr('dy', textPosition)
        .style('fill', 'white')
        .text((node: any) => this.truncateLabel(node.data.data.label || '', 12));
    }
    // SETTING: DEVICE MAC ADDRESS
    if (!!this.getDeviceSetting(NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MAC_ADDRESS)) {
      textPosition += 20;
      deviceNodeGroups
        .append('text')
        .attr('font-size', 12)
        .attr('text-anchor', 'middle')
        .attr('dy', textPosition)
        .style('fill', 'white')
        .text((node: any) => node.data.data.mac_address, 12);
    }

    deviceNodeGroups.append('button');
  }

  /**
   * Clear all selection except the checking selection
   * @param exceptionNode
   */
  clearDeviceSelections(exceptionNode = '') {
    if (!this.svg?.selectAll) {
      return;
    }
    // Clear all highlighted links
    // SETTING: DEVICE COMMUNICATION PROTOCOL
    if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
      this.svg?.selectAll('path.device-link').each((linkData: any, i: any, nodesArray: any) => {
        const linkPureData = linkData?.data?.data;
        const linkColor = INTERFACE_TYPE_OPTIONS.find((p) => p.value === linkPureData.communication_protocol)?.color || NetworkMapCommon.linkColor;
        d3.select(nodesArray[i]).style('stroke', linkColor);
      });
    } else {
      this.svg?.selectAll('path.device-link').style('stroke', NetworkMapCommon.linkColor);
    }
    // Clear all nodes
    this.svg?.selectAll('g.device-node').each((nodeData: any) => {
      if (nodeData.groupId !== exceptionNode) {
        nodeData.isClicked = false;
        nodeData.selectedCircle?.style('fill', 'transparent');
        nodeData.selectedCircle?.style('stroke', 'transparent');
        switch (nodeData?.data?.data?.status) {
          case 'normal': {
            nodeData.circle.attr('stroke', NetworkMapCommon.normalStrokeColor);
            nodeData.circle.attr('fill', NetworkMapCommon.circleFillColor);
            nodeData.icon.attr('class', 'text-white');
            break;
          }
          case 'anomalous': {
            nodeData.circle.attr('stroke', NetworkMapCommon.anomalousStrokeColor);
            nodeData.circle.attr('fill', NetworkMapCommon.circleFillColor);
            nodeData.icon.attr('class', 'network-map-anomalous-icon');
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  /**
   * Draw tooltip
   * @param node
   * @param event
   */
  drawTooltip(node: any, event: any) {
    const deviceNode = node.datum();
    const data = deviceNode?.data?.data || deviceNode;
    // create tooltip element
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'fixed')
      .style('z-index', 3)
      .style('pointer-events', 'none')
      .style('opacity', 0);

    // set tooltip content
    const status = data?.status || '';
    let statusColorClass = '';
    switch (status) {
      case 'normal': {
        statusColorClass = 'text-teal-500';
        break;
      }
      case 'anomalous': {
        statusColorClass = 'text-red-500';
        break;
      }

      default: {
        break;
      }
    }
    const hiddenMACClass = !!data?.mac_address ? '' : 'hidden';
    const hiddenIPClass = !!data?.ip_address ? '' : 'hidden';
    tooltip.html(`
    <div class="flex w-25rem relative">
      <div class="flex w-25rem absolute justify-content-center z-1" style="top: -1rem;">
        <div class="w-4rem h-4rem align-self-center border-round-xl rotate-45" style="background: #222222;"></div>
      </div>
      <div class="flex w-25rem absolute flex-column text-white master-font p-4 border-round-xl z-2" style="background: #222222;">
        <div class="grid field">
          <div class="col-12 font-bold">${data?.label}</div>
        </div>
        <div class="grid field">
          <div class="col-6">Status</div>
          <div class="col-6">
            <span class="${statusColorClass}">
              ${!!status ? status[0].toUpperCase() + status.slice(1) : ''}
            </span>
          </div>
        </div>
        <div class="grid field ${hiddenMACClass}">
          <div class="col-6">MAC Address</div>
          <div class="col-6">${data?.mac_address}</div>
        </div>
        <div class="grid field ${hiddenIPClass}">
          <div class="col-6">IP Address</div>
          <div class="col-6">${data?.ip_address}</div>
        </div>
      </div>
    </div>
    `);
    const tooltipWidth = tooltip.node()?.clientWidth || 0;

    // set tooltip position
    tooltip
      .style('left', event.pageX - tooltipWidth / 2 + 'px')
      .style('top', `calc(${event.pageY}px + 4rem)`)
      .style('opacity', 1);
  }

  /**
   * Clear tooltip
   */
  clearTooltip() {
    const tooltip = d3.select('.tooltip');
    tooltip.remove();
  }

  /**
   * ========================================================================================================================================================
   * TOGGLE / SHOW DETAILS
   * ========================================================================================================================================================
   */

  /**
   * Enable/Disable device's node
   * @param node
   * @param isThreatSelected
   */
  toggleDeviceNode(node: any, isThreatSelected = false) {
    const data = node?.data?.data || node;
    node.selectedCircle.style('fill', !!node.isClicked ? NetworkMapCommon.circleSelectedColor : 'transparent');
    switch (data.status) {
      case 'normal': {
        node.circle.attr('stroke', !!node.isClicked ? NetworkMapCommon.normalClickedStrokeColor : NetworkMapCommon.normalStrokeColor);
        node.circle.attr('fill', !!node.isClicked ? NetworkMapCommon.circleNormalClickedFillColor : NetworkMapCommon.circleFillColor);
        node.selectedCircle.style('stroke', !!node.isClicked ? NetworkMapCommon.normalClickedStrokeColor : 'transparent');
        break;
      }
      case 'anomalous': {
        node.circle.attr('stroke', !!node.isClicked ? NetworkMapCommon.anomalousClickedStrokeColor : NetworkMapCommon.anomalousStrokeColor);
        node.circle.attr('fill', !!node.isClicked ? NetworkMapCommon.circleAnomalousClickedFillColor : NetworkMapCommon.circleFillColor);
        node.selectedCircle.style('stroke', !!node.isClicked ? NetworkMapCommon.anomalousClickedStrokeColor : 'transparent');
        node.icon.attr('class', !!node.isClicked ? 'text-white' : 'network-map-anomalous-icon');
        break;
      }
      default: {
        break;
      }
    }
    if (!isThreatSelected) {
      this.highlightRelatedLinks(node, false, !!node.isClicked || !!isThreatSelected);
    }
  }

  /**
   * Show device details
   * @param data
   */
  showDeviceDetails(data: any, isEmptySpaceClicked = false) {
    this.itemDetails$.next(
      !!data
        ? {
            type: 'device',
            data,
          }
        : { data: null, isEmptySpaceClicked },
    );
  }

  /**
   * Highlight related links
   */
  private highlightRelatedLinks(data: any, isHovered = true, isClicked = false) {
    if (!!this.svg.selectAll) {
      const deviceData = data?.data?.data;
      const selectedDevice = this.selectedDevice;
      const links = this.svg.selectAll(`path[id^='device-link-s-${deviceData?.id}-'],path[id$='-e-${deviceData?.id}-device-link']`);
      links.each((linkData: any, i: any, nodesArray: any) => {
        const linkItem = d3.select(nodesArray[i]);
        const linkElementId: string = linkItem?.attr('id') || '';
        // SETTING: DEVICE COMMUNICATION PROTOCOL
        let linkColor = NetworkMapCommon.linkColor;
        let linkHoverColor = NetworkMapCommon.linkHoverColor;
        let linkClickedColor = NetworkMapCommon.linkClickedColor;
        if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
          const linkPureData = linkData?.data?.data;
          linkColor = INTERFACE_TYPE_OPTIONS.find((p) => p.value === linkPureData.communication_protocol)?.color || NetworkMapCommon.linkColor;
          linkHoverColor = this.getNewShadeColor(linkColor, 90);
          linkClickedColor = linkHoverColor;
        }
        // If node is clicked, keep the default color
        if (!!data.isClicked) {
          switch (deviceData?.status) {
            case 'normal': {
              linkClickedColor = NetworkMapCommon.normalClickedStrokeColor;
              break;
            }
            case 'anomalous': {
              linkClickedColor = NetworkMapCommon.anomalousClickedStrokeColor;
              break;
            }
            default: {
              linkClickedColor = linkHoverColor;
              break;
            }
          }
        }

        if (linkItem.style('stroke') !== linkClickedColor) {
          linkItem.style('stroke', !!isClicked ? linkClickedColor : !!isHovered ? linkHoverColor : linkColor);
        }
        if (
          linkElementId.indexOf(`device-link-s-${selectedDevice?.id}-`) > -1 ||
          linkElementId.indexOf(`-e-${selectedDevice?.id}-device-link`) > -1
        ) {
          linkItem.style('stroke', linkClickedColor);
        }
      });
    }
  }

  /**
   * ========================================================================================================================================================
   * THREAT INTERVALS
   * ========================================================================================================================================================
   */

  /**
   * Setup threat interval
   * @param data
   */
  setupThreatInterval(data: any) {
    let intervalStep = 0;
    let direction = 1;
    const interval = setInterval(() => {
      if (direction === 1) {
        // increase
        if (intervalStep + 0.05 <= 0.3) {
          intervalStep = intervalStep + 0.05;
        } else {
          direction = -1;
        }
      } else {
        // decrease
        if (intervalStep - 0.05 >= 0) {
          intervalStep = intervalStep - 0.05;
        } else {
          direction = 1;
        }
      }
      data?.circle1?.attr('opacity', intervalStep);
      data?.circle2?.attr('opacity', intervalStep);
    }, 100);
    const threatInterval = {
      interval,
      circle1: data?.circle1,
      circle2: data?.circle2,
    };
    this.threatIntervals.push(threatInterval);
  }

  /**
   * Clear threat intervals
   */
  clearThreatIntervals() {
    this.threatIntervals.forEach((threatInterval) => {
      clearInterval(threatInterval.interval);
      threatInterval?.circle1?.attr('opacity', 0.1);
      threatInterval?.circle2?.attr('opacity', 0.1);
    });
    this.threatIntervals = [];
  }

  //-------------------------------- CYCLE DETECTION FUNCTIONS START -------------------------------
  adjacencyList(devices: any[]) {
    const adjList: any = {};
    devices.forEach((item) => {
      if (adjList[item.id]) {
        adjList[item.id].push.apply(item.parent);
      } else {
        adjList[item.id] = [item.parent];
      }
    });
    return this.hasCycle(adjList);
  }

  hasCycle(adjList: any) {
    const white = new Set();
    const gray = new Set();
    const black = new Set();
    const cycleVertex: any[] = [];
    Object.keys(adjList).forEach((vertex) => white.add(vertex));
    do {
      Object.keys(adjList).forEach((node) => {
        if (this.dfs(node, white, gray, black, adjList, cycleVertex)) {
          return true;
        }
        return false;
      });
    } while (white.size > 0);
    return cycleVertex;
  }

  dfs(current: string, white: any, gray: any, black: any, adjList: any, cycleVertex: any) {
    this.moveVertex(current, white, gray);
    adjList[current].forEach((neighbor: any) => {
      if (black.has(neighbor) || !neighbor) {
        return;
      }
      if (gray.has(neighbor)) {
        cycleVertex.push(neighbor);
        return true;
      }
      if (this.dfs(neighbor, white, gray, black, adjList, cycleVertex)) {
        cycleVertex.push(neighbor);
        return true;
      }
      return false;
    });

    this.moveVertex(current, gray, black);
    return false;
  }

  moveVertex(vertex: any, source: any, dst: any) {
    source.delete(vertex);
    dst.add(vertex);
  }
  //-------------------------------- CYCLE DETECTION FUNCTIONS END -------------------------------

  diagonalLink(s: any, d: any) {
    return `M ${s.x || 0} ${s.y || 0}C ${s.x || 0} ${(s.y + d.y) / 2 || 0}` + `, ${d.x} ${(s.y + d.y) / 2 || 0}` + `, ${d.x || 0} ${d.y || 0}`;
  }

  straightLink(s: any, d: any) {
    return `M ${d.x || 0} ${d.y || 0} H ${s.x || 0} V${s.y || 0}`;
  }

  truncateLabel(label: string, maxCharacter: number) {
    return label.length > maxCharacter
      ? label
          .trim()
          .slice(0, maxCharacter - 1)
          .trim() + '...'
      : label;
  }

  /**
   * ========================================================================================================================================================
   * ZOOM
   * ========================================================================================================================================================
   */

  /**
   * Setup zoom options
   */
  setupZoomOptions() {
    this.zoom = d3
      .zoom()
      .on('zoom', (event) => {
        this.currentZoomShiftX = event.transform.x;
        this.currentZoomShiftY = event.transform.y;
        this.currentScale = event.transform.k;
        this.svg.attr('transform', event.transform);
      })
      .scaleExtent([0.1, 5]);
  }

  /**
   * Update zoom in/out
   * @param option
   */
  updateZoom(option: number | null = null) {
    const transform = () => {
      const transform = d3.zoomIdentity.translate(this.currentZoomShiftX, this.currentZoomShiftY).scale(this.currentScale);
      if (!!this.svgContainer?.call) {
        this.svgContainer.call(this.zoom.transform, transform);
      }
    };
    if (option !== null) {
      if (option !== 0) {
        // Update zoom in/out
        const updatedZoomLevel = this.currentScale + 0.1 * option;
        if (updatedZoomLevel >= 0.1 && updatedZoomLevel <= 5) {
          this.currentScale = updatedZoomLevel;
          this.currentZoomShiftX += this.viewX * 0.1 * option * -1;
          this.currentZoomShiftY += this.viewY * 0.1 * option * -1;
          transform();
        }
      } else {
        if (!!this.selectedDevice) {
          // Zoom to target device
          d3.selectAll('g.device-node').each((data: any) => {
            const groupId = `${this.selectedDevice?.status}-node-group-${this.selectedDevice?.id}`;
            if (data.groupId === groupId) {
              this.zoomToTarget({ target: data.circle.node() } as MouseEvent);
            }
          });
        } else {
          // Zoom to full diagram
          this.zoomToTarget();
        }
      }
    } else {
      transform();
    }
  }

  /**
   * Zoom to device's node(s)
   * @param event
   */
  zoomToTarget(event?: MouseEvent, zoomedDeviceIds: any[] = []) {
    let elementX = 0;
    let elementY = 0;
    if (!!event?.target) {
      // Clicked element
      const item = event.target as SVGCircleElement | SVGForeignObjectElement;
      const element = item.closest('g') as SVGGElement;
      const elementData: any = d3.select(element).datum();
      elementX = +elementData?.x;
      elementY = +elementData?.y;
      // New scale
      this.currentScale = 2;
    } else {
      // Zoom all devices
      if (event?.target === undefined) {
        // Normal zoom
        const nodesGroupElement = d3.select('g.node-area-group').node() as SVGGElement;
        const nodesGroup = nodesGroupElement?.getBBox();
        if (!!nodesGroup) {
          // New scale
          const currentScale = Math.min(this.windowWidth / nodesGroup.width, this.windowHeight / nodesGroup.height) * this.zoomScaleDiff;
          this.currentScale = currentScale >= 0.75 ? 0.75 : currentScale;
          elementX = nodesGroup.x * this.zoomScaleDiff + nodesGroup.width / 3;
          elementY = nodesGroup.y * this.zoomScaleDiff + nodesGroup.height / 3 + 300;
        } else {
          return;
        }
      }
      // Zoom to a group of devices
      else if (event?.target === null) {
        const boundaryRect = {
          x: Infinity,
          y: Infinity,
          maxX: -Infinity,
          maxY: -Infinity,
          width: -Infinity,
          height: -Infinity,
        };
        d3.selectAll('g.device-node').each((nodeData: any) => {
          const data = nodeData?.data?.data;
          if (!!zoomedDeviceIds.includes(data?.id)) {
            const x = +nodeData?.x;
            const y = +nodeData?.y;
            boundaryRect.x = Math.min(boundaryRect.x, x);
            boundaryRect.y = Math.min(boundaryRect.y, y);
            boundaryRect.maxX = Math.max(boundaryRect.maxX, x);
            boundaryRect.maxY = Math.max(boundaryRect.maxY, y);
            boundaryRect.width = Math.abs(boundaryRect.maxX - boundaryRect.x);
            boundaryRect.height = Math.abs(boundaryRect.maxY - boundaryRect.y);
          }
        });
        if (zoomedDeviceIds.length > 1) {
          const widthRadius = !!boundaryRect.width ? this.windowWidth / boundaryRect.width : this.windowWidth;
          const heightRadius = !!boundaryRect.height ? this.windowHeight / boundaryRect.height : this.windowHeight;
          const scale = Math.min(widthRadius, heightRadius) * (this.zoomScaleDiff - 0.4);
          this.currentScale = Math.max(Math.min(scale, this.maxScaleLimit), this.minScaleLimit);
          elementX = boundaryRect.x + boundaryRect.width / 2;
          elementY = boundaryRect.y + boundaryRect.height / 2;
        } else {
          elementX = boundaryRect.x;
          elementY = boundaryRect.y;
          this.currentScale = 2;
        }
      }
    }
    /** CALCULATION X
     * -elementX * this.currentScale            => The correct position of the node, this will make sure the node will be always at left edge of the screen
     * + this.windowWidth * 0.25                => 0.25 to make sure the x will be located in position 1/4 of screen width
     * - this.viewX * (this.currentScale - 1)   => Additional value to make it locate to the position (0,y) of the svg
     */
    this.currentZoomShiftX = -elementX * this.currentScale + this.windowWidth * 0.25 - this.viewX * (this.currentScale - 1);
    /** CALCULATION Y
     * -elementY * this.currentScale            => The correct position of the node, this will make sure the node will be always at top edge corner of the screen
     * + this.windowHeight * 0.5                => 0.5 to make sure the x will be located in middle of screen height
     * - this.viewY * (this.currentScale - 1)   => Additional value to make it locate to the position (x,0) of the svg
     */
    this.currentZoomShiftY = -elementY * this.currentScale + this.windowHeight * 0.5 - this.viewY * (this.currentScale - 1);

    const transform = d3.zoomIdentity.translate(this.currentZoomShiftX, this.currentZoomShiftY).scale(this.currentScale);
    if (!!this.svgContainer.transition) {
      this.svgContainer?.transition()?.duration(500)?.call(this.zoom.transform, transform);
    }
  }

  /**
   * ========================================================================================================================================================
   * HELPER FUNCTIONS
   * ========================================================================================================================================================
   */

  getDeviceSetting(settingName: any) {
    const deviceSetting: any = this.settings?.find((p) => p.key === NETWORK_MAP_SETTING_KEYS.DEVICE);
    const setting: any = ((deviceSetting?.items as any[]) || [])?.find((p) => p.value === settingName);
    return setting?.checked;
  }

  getProtocolSetting(settingName: any) {
    const protocolSetting: any = this.settings?.find((p) => p.key === NETWORK_MAP_SETTING_KEYS.PROTOCOL);
    const setting: any = ((protocolSetting?.items as any[]) || [])?.find((p) => p.value === settingName);
    return setting?.checked;
  }

  /**
   * Refresh the device data
   */
  refresh() {
    this.refresh$.next(null);
  }

  setNetworkMapIcon(iconObject: any, iconObj: any, iconSize: any, iconPadding: any) {
    const svgIconObject = iconObject
      .append('svg')
      .attr('width', NetworkMapCommon.circleRadius * 2)
      .attr('height', NetworkMapCommon.circleRadius * 2);
    if (iconObj?.type === 'svg') {
      d3.text(iconObj?.url).then((svgString: string) => {
        // Append the SVG content to the container element
        svgIconObject.html(
          svgString.replace(`width="48" height="48"`, `width="${iconSize}" height="${iconSize}"` + ` x="${iconPadding}" y="${iconPadding}"`),
        );
      });
    } else {
      svgIconObject.html(iconObj?.template);
    }
  }

  /**
   * Get new color
   * @param hexColor
   * @param magnitude
   * @returns
   */
  private getNewShadeColor(hexColor: any, magnitude: any) {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  }

  get maxWidth() {
    return this.windowWidth * 100;
  }

  get maxHeight() {
    return this.windowHeight * 100;
  }

  get viewX() {
    return this.maxWidth / 2;
  }

  get viewY() {
    return this.maxHeight / 2;
  }
}
