export const NETWORK_MAP_MODE_VALUES = {
  FORCE_DIRECTED: 'force_directed',
  PURDUE_MODEL: 'purdue_model',
  EDITOR: 'editor',
};

export const NETWORK_MAP_MODES = [
  { value: NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED, label: 'Force Directed', icon: 'fas fa-circle-nodes' },
  { value: NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, label: 'Purdue Model', icon: 'fas fa-sitemap' },
  { value: NETWORK_MAP_MODE_VALUES.EDITOR, label: 'Editor', icon: 'fas fa-pencil' },
];

export const NETWORK_MAP_SETTING_KEYS = {
  DEVICE: 'device',
  PROTOCOL: 'protocol',
};

export const NETWORK_MAP_SETTING_VALUES = {
  DEVICE: {
    DEVICE_NAME: 'device_name',
    DEVICE_MAC_ADDRESS: 'device_mac_address',
    DEVICE_MULTICAST: 'device_multicast',
  },
  PROTOCOL: {
    COMMUNICATION_PROTOCOL: 'communication_protocol',
    LEGEND: 'legend',
  },
};

//Order is important
export const NETWORK_MAP_SETTINGS = [
  {
    //Device Settings are always first for the Multicast property on settingObs.subscribe
    mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED],
    key: NETWORK_MAP_SETTING_KEYS.DEVICE,
    label: 'Device Settings',
    items: [
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
        label: 'Show Device Name',
        value: NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_NAME,
      },
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
        label: 'Show Device MAC Address',
        value: NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MAC_ADDRESS,
      },
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED],
        label: 'Show Multicast Devices',
        value: NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST,
      },
    ],
  },
  {
    mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
    key: NETWORK_MAP_SETTING_KEYS.PROTOCOL,
    label: 'Protocol Settings',
    items: [
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
        label: 'Show Communication Protocol on Links',
        value: NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL,
      },
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
        label: 'Show Legend',
        value: NETWORK_MAP_SETTING_VALUES.PROTOCOL.LEGEND,
      },
    ],
  },
];

export const DEVICE_TYPE_BASE_URL = 'assets/images/network-map-icons';

export const EDITOR_DIAGRAM_BASE_URL = 'assets/images/network-map-editor';

export const EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL = `${EDITOR_DIAGRAM_BASE_URL}/device-types`;

export const EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN = '###BASE_URL###';

export const EDITOR_DEFAULT_LOADING_MESSAGE = 'Please wait while we populate the network map with selected devices...';

export const EDITOR_DEVICE_TYPE_SIZE = 40;

export const EDITOR_DEVICE_TYPE_DEFAULT_GAP = 150;

export const EDITOR_ZONE_DARK_LEVEL = 0.75;

export const EDITOR_CREATION_TYPE_VALUES = {
  SCRATCH: 'scratch',
  DISCOVERED_NETWORK_ASSETS: 'dna',
};

export const EDITOR_CREATION_TYPES = [
  { value: EDITOR_CREATION_TYPE_VALUES.SCRATCH, label: 'Create from Scratch' },
  { value: EDITOR_CREATION_TYPE_VALUES.DISCOVERED_NETWORK_ASSETS, label: 'Create from Discovered Network Assets' },
];

export const EDITOR_OBJECT_TYPES = {
  DEVICE: 'device',
  ZONE: 'zone',
  LINE: 'line',
};

export const EDITOR_DEVICE_NAME_MAX_LENGTH = 10;

export const EDITOR_DIAGRAM_CONFIG_KEYS = {
  DEVICE_NAME: 'device_name',
  GRID: 'grid',
  PAGE_VIEW: 'page_view',
  BACKGROUND: 'background',
  BACKGROUND_COLOR: 'background_color',
};

export const EDITOR_DIAGRAM_CONFIGS = [
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.DEVICE_NAME, label: 'Device Name' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.GRID, label: 'Grid' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.PAGE_VIEW, label: 'Page View' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND, label: 'Background' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND_COLOR, label: 'Background Color' },
];

export const EDITOR_LAYER_BUTTONS = {
  TO_FRONT: { LABEL: 'To Front', VALUE: 'front' },
  TO_BACK: { LABEL: 'To Back', VALUE: 'back' },
  BRING_TOWARD: { LABEL: 'Bring Forward', VALUE: 'forward' },
  SEND_BACKWARD: { LABEL: 'Send Backward', VALUE: 'backward' },
};
