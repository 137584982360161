import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { EXPORT_TYPE_OPTIONS } from '@ids-constants';
import { VALIDATOR_TYPE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AnomalyAnalyzerService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

export const FORM_PARAMS = {
  LABEL: 'label',
  FILE_PATH: 'file_path',
  EXPORT: 'export',
};
@Component({
  selector: 'app-ml-model-export-import-form',
  templateUrl: './ml-model-export-import-form.component.html',
  styleUrls: ['./ml-model-export-import-form.component.scss'],
})
export class MlModelExportImportFormComponent extends BaseComponent implements AfterViewInit {
  model: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CONFIRM_LABEL = 'Confirm';

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.model = this.dialogConfig?.data?.model;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EXPORT,
        label: 'Export as',
        field: 'dropdown',
        options: this.util.cloneObjectArray(EXPORT_TYPE_OPTIONS),
        fieldInfo: 'File type of model',
        placeholder: 'Select an export type',
        defaultValue: null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        fieldInfo: 'Label of the model',
        defaultValue: '',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE_PATH,
        label: 'Model',
        field: 'file',
        fieldInfo: 'Path of model file',
        acceptedFileTypes: ['.pkl'],
        defaultValue: null,
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    this.initUploadFile(FORM_PARAMS.FILE_PATH);
    setTimeout(() => {
      this.setFormField();
    });
  }

  private setFormField() {
    this.form.setControlValidatorsAndVisibility(FORM_PARAMS.EXPORT, this.model.form_type === 'export' ? [VALIDATOR_TYPE.REQUIRED] : []);
    this.form.setControlValidatorsAndVisibility(FORM_PARAMS.LABEL, this.model.form_type === 'import' ? [VALIDATOR_TYPE.REQUIRED] : []);
    this.form.setControlValidatorsAndVisibility(FORM_PARAMS.FILE_PATH, this.model.form_type === 'import' ? [VALIDATOR_TYPE.REQUIRED] : []);
  }

  private initUploadFile(formNameFile: string) {
    const uploadFileField = this.fields.find((p) => p.name === formNameFile);
    if (!!uploadFileField) {
      uploadFileField.uploadEvent?.subscribe((event: any) => {
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          this.form.isLoading = true;
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.FILE_PATH, file);
          this.form.isLoading = false;
        }
      });
    }
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    if (this.model.form_type === 'import') {
      const formValues = { ...this.form.getRawValue() };
      const payload = this.getPayload(formValues);
      this.anomalyAnalyzerSrv
        .createModel(payload)
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage(`Model file is successfully imported.`);
            closeDialog();
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
    if (this.model.form_type === 'export') {
      const formValues = { ...this.form.getRawValue() };
      this.anomalyAnalyzerSrv
        .exportModel(this.model.model_id, formValues.export === 'octet-stream')
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.util.downloadFileFromBlob(res);
            this.showSuccessMessage(`Model is successfully exported.`);
            closeDialog();
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  private getPayload(formValues: any) {
    const formData = new FormData();
    formData.append('anomaly_analyzer_id', this.model.analyzerId);
    formData.append(FORM_PARAMS.LABEL, formValues[FORM_PARAMS.LABEL]);
    formData.append(FORM_PARAMS.FILE_PATH, formValues[FORM_PARAMS.FILE_PATH]);
    return formData;
  }
}
