import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { NetworkDeviceService } from '@ids-services';
import { MODEL_OPTIONS, QUOTAS_FEATURES, TYPE_OPTIONS } from '@ids-constants';
import { verticalSlideAnimation } from '@microsec/animations';
import { SharedNetworkDeviceFormComponent } from '../../../../shared-components/shared-network-device-form/shared-network-device-form.component';
import { SharedTestConfigurationDialogComponent } from '../../../../shared-components/shared-test-configuration-dialog/shared-test-configuration-dialog.component';
import { ConstantPipe } from '@ids-pipes';
import { CommonTableComponent } from '@microsec/components';
import { ReportSpecification } from '@microsec/models';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ActionMenuItem } from '@microsec/models';

import { BehaviorSubject, finalize, forkJoin, Observable } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';

const FIELDS = {
  id: 'ID',
  label: 'Device Name',
  device_type: 'Type',
  model: 'Model',
  firmware: 'Firmware',
  supported_features: 'Supported Features',
  configIPAddress: 'IP Address',
  configPort: 'Port',
  configStatus: 'Status',
};

@Component({
  selector: 'app-network-devices',
  templateUrl: './network-devices.component.html',
  styleUrls: ['./network-devices.component.scss'],
  providers: [ConstantPipe, TitleCasePipe],
  animations: [verticalSlideAnimation],
})
export class NetworkDevicesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  currentPage = 1;

  totalRecords = 0;

  lazyLoadEvent: LazyLoadEvent | null = null;

  values: any[] = [];

  cols = [
    { field: 'id', header: FIELDS.id, width: 8 },
    { field: 'label', header: FIELDS.label, width: 12 },
    { field: 'device_type', header: FIELDS.device_type, width: 8 },
    { field: 'model', header: FIELDS.model, width: 10 },
    { field: 'firmware', header: FIELDS.firmware, width: 10 },
    { field: 'configIPAddress', header: FIELDS.configIPAddress, width: 10 },
    { field: 'configPort', header: FIELDS.configPort, width: 10 },
    { field: 'configStatus', header: FIELDS.configStatus, width: 10 },
  ];

  noSortCols = this.cols.map((x) => x.field);

  selectedDevices: any[] = [];

  selectedDevice: any = null;

  filterAgent: boolean | null = null;

  PER_PAGE = PER_PAGE;

  TYPE_OPTIONS = TYPE_OPTIONS;

  MODEL_OPTIONS = MODEL_OPTIONS;

  @ViewChild('dt') dt!: CommonTableComponent;

  @ViewChild('testConfigurationDialog') testConfigurationDialog!: SharedTestConfigurationDialogComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search Device Name...',
    hideClearFilters: false,
    hideResetSortOption: true,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private constantPipe: ConstantPipe,
    private titleCasePipe: TitleCasePipe,
    private networkDeviceSrv: NetworkDeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeviceForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.handleFilterObjUpdate();
    this.getDevices();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.networkDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getDevices(this.filterSearch);
      }
    });
    this.subscriptions.push(subscription);
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (this.filterSearch !== result.search) {
          result.search ? this.getDevices(result.search) : this.getDevices();
        }
        this.filterSearch = result?.search || '';
      }
    });
  }

  getDevices(search?: string, resetSelectedDevices = true, event?: LazyLoadEvent) {
    this.isLoading = true;
    if (!!event) {
      this.lazyLoadEvent = event;
    }
    const page = !event ? 1 : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = this.lazyLoadEvent?.rows || PER_PAGE;
    this.networkDeviceSrv
      .getDevices(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, page, perPage, (!!search ? search : null) as any)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          const devices = (
            !!this.lazyLoadEvent?.sortField
              ? this.util.sortObjectArray(
                  (res?.devices as any[]) || [],
                  this.lazyLoadEvent?.sortField,
                  !!this.lazyLoadEvent?.sortOrder ? this.lazyLoadEvent.sortOrder === 1 : true,
                )
              : (res?.devices as any[]) || []
          ).map((device) => ({
            ...device,
            config: device?.configs?.[0] || null,
          }));
          this.values = devices;
          if (!!this.selectedDevice) {
            this.selectedDevice = this.values.find((device) => device.id === this.selectedDevice.id) || null;
          }
          if (!!resetSelectedDevices) {
            this.selectedDevices = [];
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openDeviceForm(device?: any) {
    const dialog = this.dialogSrv.open(SharedNetworkDeviceFormComponent, {
      header: `${!device ? 'Connect to' : 'Edit'} Network Device`,
      data: {
        device: device || null,
      },
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.testConfigurationDialog.open(!device ? rs : rs[0], false);
      }
    });
  }

  deleteDevices(devices: any[]) {
    this.isLoading = true;
    const requests: Observable<any>[] = devices.map((device) => this.networkDeviceSrv.deleteDevice(device.id));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Deleted device(s) successfully`);
          if (!!this.selectedDevices?.length) {
            this.selectedDevices = [];
          }
          if (!!this.selectedDevice) {
            const deviceIds = devices.map((device) => device.id) || [];
            this.selectedDevice = !!deviceIds.includes(this.selectedDevice.id) ? null : this.selectedDevice;
          }
          this.getDevices(this.filterSearch);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  openDeleteConfirmation(devices: any[] = []) {
    const deviceList = devices.map((device) => `<li>${`Device ID ${device.id} ${device.label ? `: ${device.label}` : ''}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Device(s)',
      customContent: `Are you sure you want to delete ${
        !devices?.length ? 'all devices?<br/><br/>' : `the following device(s)?<ul>${deviceList.join('')}</ul>`
      }`,
      next: () => {
        if (!devices?.length) {
          this.networkDeviceSrv
            .getDevices(
              this.breadcrumbConfig?.organizationId,
              this.breadcrumbConfig?.projectId,
              1,
              this.totalRecords,
              (!!this.filterSearch ? this.filterSearch : null) as any,
            )
            .pipe()
            .subscribe({
              next: (res) => {
                const allDevices = (res?.devices as any[]) || [];
                this.deleteDevices(allDevices);
              },
              error: () => {
                this.showErrorMessage('Error while getting all devices data');
                this.isLoading = false;
              },
            });
        } else {
          this.deleteDevices(devices);
        }
      },
    });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const filename = `project_${this.breadcrumbConfig?.projectId}_network_devices`;
        if (specification.pageOption === 'current') {
          this.dt.generateReportDialog.exportReport(this.getMappedReportData(this.values), filename);
        } else {
          const requests: Observable<any>[] =
            specification.pageOption === 'custom'
              ? this.util
                  .getSequenceInteger(specification.customPages.from as any, specification.customPages.to as any)
                  .map((page) =>
                    this.networkDeviceSrv.getDevices(
                      this.breadcrumbConfig?.organizationId,
                      this.breadcrumbConfig?.projectId,
                      page,
                      this.lazyLoadEvent?.rows || PER_PAGE,
                      (!!this.filterSearch ? this.filterSearch : null) as any,
                    ),
                  )
              : [
                  this.networkDeviceSrv.getDevices(
                    this.breadcrumbConfig?.organizationId,
                    this.breadcrumbConfig?.projectId,
                    1,
                    this.totalRecords,
                    (!!this.filterSearch ? this.filterSearch : null) as any,
                  ),
                ];
          forkJoin(requests).subscribe({
            next: (res) => {
              const data: any[] = [];
              res.forEach((devices) => {
                data.push(...(devices?.devices || []));
              });
              this.dt.generateReportDialog.exportReport(this.getMappedReportData(data), filename);
            },
            error: () => {
              this.showErrorMessage('Error while getting all devices data');
              this.dt.generateReportDialog.onClose();
            },
          });
        }
      }
    };
    this.dt?.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], []);
  }

  getMappedReportData(data: any[]) {
    return (data || []).map((device) => ({
      ...device,
      device_type: this.titleCasePipe?.transform(device.device_type),
      model: this.constantPipe?.transform(device.model, 'network-device-model'),
      supported_features: device?.supported_features?.join(','),
      configIPAddress: device?.configs?.[0]?.ip_address,
      configPort: device?.configs?.[0]?.port,
      configStatus: this.titleCasePipe?.transform(device?.configs?.[0]?.connection_status),
    }));
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedDevice = null;
  }

  get checkNetworkDeviceQuota() {
    return this.totalRecords < this.getQuotaLimitation(QUOTAS_FEATURES.DEVICES); //this.getQuotaLimitation(QUOTAS_FEATURES.NETWORK_DEVICES);
  }

  override ngOnDestroy() {
    this.cleanup();
    this.networkDeviceSrv.refresh$.next(null);
  }
}
