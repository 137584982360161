import { ILayoutItem } from '@microsec/interfaces';

import { VulnerabilityComponent } from './vulnerability/vulnerability.component';
import { MlAnalyzersComponent } from './ml-analyzers/ml-analyzers.component';
import { RuleBasedAnalyzersComponent } from './rule-based-analyzers/rule-based-analyzers.component';
import { FlAnalyzersComponent } from './fl-analyzers/fl-analyzers.component';
import { ANALYZER_FEATURES, FEATURES } from '@ids-constants';

export const ANOMALY_ANALYZER_CONSTANTS = {
  MACHINE_LEARNING: {
    ROUTE: 'machine-learning',
    LABEL: 'Machine Learning',
  },
  RULE_BASED: {
    ROUTE: 'rule-based',
    LABEL: 'Rule-Based',
  },
  VULNERABILITY: {
    ROUTE: 'vulnerability',
    LABEL: 'Vulnerability',
  },
  PACKET: {
    ROUTE: 'packet',
    LABEL: 'Packet Analysis',
  },
  PAYLOAD: {
    ROUTE: 'payload',
    LABEL: 'Payload Analysis',
  },
  FEDERATED_LEARNING: {
    ROUTE: 'federated-learning',
    LABEL: 'Federated Learning',
  },
};

export const ANOMALY_ANALYZER_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: ANOMALY_ANALYZER_CONSTANTS.MACHINE_LEARNING.ROUTE,
  },
  {
    route: ANOMALY_ANALYZER_CONSTANTS.MACHINE_LEARNING.ROUTE,
    label: ANOMALY_ANALYZER_CONSTANTS.MACHINE_LEARNING.LABEL,
    component: MlAnalyzersComponent,
    visible: false,
    featureName: FEATURES.ANALYZERS,
    featureCheckField: ANALYZER_FEATURES.ANOMALY_ANALYZER_ML,
  },
  {
    route: ANOMALY_ANALYZER_CONSTANTS.RULE_BASED.ROUTE,
    label: ANOMALY_ANALYZER_CONSTANTS.RULE_BASED.LABEL,
    component: RuleBasedAnalyzersComponent,
    visible: false,
    featureName: FEATURES.ANALYZERS,
    featureCheckField: ANALYZER_FEATURES.ANOMALY_ANALYZER_RULE_BASED,
  },
  {
    route: ANOMALY_ANALYZER_CONSTANTS.VULNERABILITY.ROUTE,
    label: ANOMALY_ANALYZER_CONSTANTS.VULNERABILITY.LABEL,
    component: VulnerabilityComponent,
    visible: false,
    featureName: FEATURES.ANALYZERS,
    featureCheckField: ANALYZER_FEATURES.VULNERABILITY_ANALYZER,
  },
  {
    route: ANOMALY_ANALYZER_CONSTANTS.FEDERATED_LEARNING.ROUTE,
    label: ANOMALY_ANALYZER_CONSTANTS.FEDERATED_LEARNING.LABEL,
    component: FlAnalyzersComponent,
  },
];
