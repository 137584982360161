<microsec-card>
  <div class="p-2">
    <microsec-form-builder
      [isPage]="true"
      #fb
      [fields]="fields"
      [shouldShowActionButtons]="true"
      [submitButtonLabel]="SAVE_CHANGES_LABEL"
      [cancelButtonLabel]="''"
      (submitEvent)="onSubmit()"
      [leftActionArea]="leftActionArea" />
  </div>
</microsec-card>

<ng-template #threatTypeFiltersTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="mb-3" *ngFor="let threatType of THREAT_TYPE_OPTIONS">
        <p-checkbox
          [value]="threatType.value.toLowerCase()"
          [label]="threatType.label"
          [name]="FORM_PARAMS.THREAT_TYPE_FILTERS"
          [formControl]="$any(form.form.controls[FORM_PARAMS.THREAT_TYPE_FILTERS])">
        </p-checkbox>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #overallScoreTemplate>
  <ng-container
    [ngTemplateOutlet]="sliderTemplate"
    [ngTemplateOutletContext]="{
      formName: FORM_PARAMS.MIN_THREAT_SCORE,
      formLabel: 'Overall Score'
    }">
  </ng-container>
</ng-template>

<ng-template #impactScoreTemplate>
  <ng-container
    [ngTemplateOutlet]="sliderTemplate"
    [ngTemplateOutletContext]="{
      formName: FORM_PARAMS.MIN_THREAT_IMPACT,
      formLabel: 'Impact Score'
    }">
  </ng-container>
</ng-template>

<ng-template #likelihoodScoreTemplate>
  <ng-container
    [ngTemplateOutlet]="sliderTemplate"
    [ngTemplateOutletContext]="{
      formName: FORM_PARAMS.MIN_THREAT_LIKELIHOOD,
      formLabel: 'Likelihood Score'
    }">
  </ng-container>
</ng-template>

<ng-template #accuracyScoreTemplate>
  <ng-container
    [ngTemplateOutlet]="sliderTemplate"
    [ngTemplateOutletContext]="{
      formName: FORM_PARAMS.MIN_THREAT_ACCURACY,
      formLabel: 'Accuracy Score'
    }">
  </ng-container>
</ng-template>

<ng-template #leftActionArea>
  <p-button [label]="CANCEL_LABEL" severity="secondary" styleClass="mr-2" (onClick)="getThreatSetting()"> </p-button>
</ng-template>

<ng-template let-formName="formName" let-formLabel="formLabel" #sliderTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="grid align-items-center mb-3" style="max-width: 45rem">
        <div class="col-12 lg:col-4">{{ formLabel }}</div>
        <div class="col">
          <p-slider [formControlName]="formName" [step]="1" [min]="0" [max]="10"></p-slider>
        </div>
        <div class="col-fixed text-center" style="width: 50px">
          <label class="font-bold">
            {{ form.getControlValue(formName) }}
          </label>
        </div>
      </div>
    </ng-container>
  }
</ng-template>
