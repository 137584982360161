import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, CommonTableComponent } from '@microsec/components';
import { ArrayToPlaceholderStringPipe, MomentPipe } from '@ids-pipes';
import { AccessTokensService } from '@ids-services';
import { CommonToolbarConfiguration, CommonToolbarResult, ReportSpecification, ActionMenuItem } from '@microsec/models';

import { finalize } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { AccessTokenFormComponent } from './access-token-form/access-token-form.component';
import { authenticationSelectors } from '@microsec/ngrx-authentication';

const FIELDS = {
  id: 'ID',
  name: 'Token Name',
  expiry_date: 'Expiry Date',
  created: 'Created',
  last_used: 'Last Used',
};

@Component({
  selector: 'app-access-tokens',
  templateUrl: './access-tokens.component.html',
  styleUrls: ['./access-tokens.component.scss'],
  providers: [MomentPipe, ArrayToPlaceholderStringPipe],
})
export class AccessTokensComponent extends BaseComponent implements OnInit {
  currentUser: any;
  cols: any[] = [
    { field: 'name', header: 'Token Name', width: 30 },
    { field: 'expiry_date', header: 'Expiry Date', width: 20 },
    { field: 'created', header: 'Created', width: 20 },
    { field: 'last_used', header: 'Last Used', width: 20 },
  ];
  globalFilterFields: string[] = ['name'];
  values: any[] = [];
  isLoading = true;

  @ViewChild('dt') dt!: CommonTableComponent;

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private momentPipe: MomentPipe,
    private accessTokenSrv: AccessTokensService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.currentUser = await firstValueFrom(this.store.select(authenticationSelectors.currentUser));
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        command: ({ rowData }) => this.deleteToken(rowData.id),
      },
    ];
    this.handleFilterObjUpdate();
    this.getTokens();
  }

  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values?.search || '';
      }
    });
  }

  getTokens() {
    this.isLoading = true;
    this.accessTokenSrv
      .getTokens(this.currentUser.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.values = res ? res : [];
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((token) => ({
          ...token,
          expiry_date: this.momentPipe?.transform(token.expiry_date),
          created: this.momentPipe?.transform(token.created),
          last_used: this.momentPipe?.transform(token.last_used),
        }));
        this.dt.generateReportDialog.exportReport(data, 'tokens');
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.cols, [], this.values);
  }

  openAccessTokenFormDialog() {
    const dialog = this.dialogSrv.open(AccessTokenFormComponent, {
      header: `Add Token`,
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getTokens();
      }
    });
  }

  deleteToken(token_id: number) {
    this.accessTokenSrv.deleteToken(this.currentUser.id, token_id).subscribe({
      next: () => {
        this.showSuccessMessage('Deleted token successfully.');
        this.getTokens();
      },
      error: (error) => {
        this.showErrorMessage(error);
      },
    });
  }
}
