<div class="grid">
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null" />
    <ng-template #leftTemplate>
      <span [pTooltip]="!!checkProjectQuota ? '' : 'Maximum number of projects reached'" tooltipPosition="bottom" appendTo="body">
        <p-button
          [label]="'Create Project'"
          [rounded]="true"
          icon="pi pi-plus"
          [disabled]="!!isLoading || !checkProjectQuota"
          (onClick)="openProjectForm()" />
      </span>
    </ng-template>
  </div>
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [(selection)]="selectedProject"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [loading]="isLoading"
        [selectable]="false"
        itemName="Project"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getProjects()" />
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Name -->
    <ng-container *ngSwitchCase="'name'">
      <label class="link overflow-hidden white-space-nowrap text-overflow-ellipsis" [title]="rowData[col.field]" (click)="goToProject(rowData.id)">
        {{ rowData[col.field] }}
      </label>
    </ng-container>
    <!-- Status -->
    <ng-container *ngSwitchCase="'is_active'">
      <p-tag
        [value]="getLabelConfig(rowData[col.field]).label"
        [rounded]="true"
        [style]="{
          'color': getLabelConfig(rowData[col.field]).color,
          'background-color': getLabelConfig(rowData[col.field]).backgroundColor
        }"
        styleClass="status-tag select-none uppercase"></p-tag>
    </ng-container>
    <!-- Created On -->
    <ng-container *ngSwitchCase="'created'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ rowData[col.field] }}
    </ng-container>
  </ng-container>
</ng-template>
