import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonToolbarComponent } from '@microsec/components';
import { PER_PAGE } from '@microsec/constants';
import { NETWORK_MAP_MODE_VALUES, NETWORK_MAP_SETTING_VALUES } from '@ids-constants';
import { INTERFACE_TYPE_LABELS, INTERFACE_TYPE_OPTIONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ConnectionService, TargetDeviceService } from '@ids-services';

import moment from 'moment';
import { DataView } from 'primeng/dataview';
import { BehaviorSubject, Observable, finalize, forkJoin } from 'rxjs';

const FILTER_CONFIG: CommonToolbarConfiguration = {
  types: ['search', 'sort', 'filter'],
  showTotalRecords: true,
  totalRecordsIcon: 'fa fa-laptop',
  searchPlaceholder: 'Search Device Name, Source IP Address (CIDR supported) or MAC Address...',
  sorts: [
    { key: 'label', label: 'Name' },
    { key: 'type', label: 'Device Type' },
    { key: 'interfaces', label: 'Interfaces' },
    { key: 'src_mac_addr', label: 'MAC Address' },
    { key: 'src_ip_addr', label: 'IP Address' },
    { key: 'status', label: 'Status' },
    { key: 'connection', label: 'Connection ID' },
    { key: 'created', label: 'Created Date' },
    { key: 'criticality', label: 'Criticality' },
    { key: 'id', label: 'Device ID' },
    { key: 'is_imported', label: 'Imported' },
    { key: 'last_seen', label: 'Last Seen' },
    { key: 'threat_score', label: 'Threat Score' },
    { key: 'uses_agent', label: 'Uses Agent' },
  ],
  filters: {
    1: {
      key: 'usesAgent',
      label: 'Agent/Agentless',
      type: 'dropdown',
      options: [
        { label: 'Agent', value: true },
        { label: 'Agentless', value: false },
      ],
    },
    2: {
      key: 'connections',
      label: 'Connections',
      type: 'multiselect',
      options: [],
    },
    3: {
      key: 'createdDates',
      label: 'Created Date',
      type: 'date-range',
    },
    4: {
      key: 'criticality',
      label: 'Criticality',
      type: 'slider',
    },
    5: {
      key: 'isImported',
      label: 'Import Status',
      type: 'dropdown',
      options: [
        { label: 'Imported', value: true },
        { label: 'Not Imported', value: false },
      ],
    },
    6: {
      key: 'interfaces',
      label: 'Interface Types',
      type: 'multiselect',
      options: INTERFACE_TYPE_OPTIONS,
    },
    7: {
      key: 'lastSeenDates',
      label: 'Last Seen',
      type: 'date-range',
    },
    8: {
      key: 'tags',
      label: 'Tags',
      type: 'multiselect',
      options: [],
    },
    9: {
      key: 'zones',
      label: 'Zones',
      type: 'multiselect',
      options: [],
    },
  },
};

@Component({
  selector: 'app-network-map-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent extends BaseComponent implements AfterViewInit, OnInit {
  isLoading = false;

  filterConfiguration: CommonToolbarConfiguration = FILTER_CONFIG;

  values: any[] = [];

  diagramDevices: any[] = [];

  links: any = {};

  connections: any[] = [];

  PER_PAGE = PER_PAGE;

  filterObject: CommonToolbarResult = {
    search: '',
    sort: null,
    filter: {},
    isFiltered: false,
    isSortReset: false,
  };

  isMulticast: 'false' | 'true' | 'any' = 'false';

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  @Input() mode = NETWORK_MAP_MODE_VALUES.PURDUE_MODEL;

  @Input() settings: any[] = [];

  @Input() itemDetails$ = new BehaviorSubject<any>(null);

  @Input() diagramData$ = new BehaviorSubject<any>(null);

  @Input() changedItems$ = new BehaviorSubject<any>(null);

  @Input() changedItemsObs = new Observable<any>();

  @Input() settingOptionsObs = new Observable<any>();

  @Input() refreshObs = new Observable<any>();

  @Input() selectedDevice: any = null;

  @Output() changeSelectedDevice = new EventEmitter<number | null>();

  @Input() isItemDetailsDisplayed = false;

  @ViewChild('dv') dataView!: DataView;

  @ViewChild('ct') toolbar!: CommonToolbarComponent;

  constructor(
    private deviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    if (!!Array.isArray(this.settings)) {
      let multicastItem: any = null;
      this.settings.some((setting: any) => {
        multicastItem = ((setting?.items as any[]) || []).find((item) => item.value === NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST);
        return !!multicastItem;
      });
      this.isMulticast = !!multicastItem?.checked ? 'any' : 'false';
    }
    this.settingOptionsObs.subscribe((settings: any) => {
      this.handleMulticastChange(settings);
    });
    this.refreshObs.subscribe(() => {
      this.getDevices();
    });
    this.changedItemsObs.subscribe((changedItems: any) => {
      if (!!changedItems?.length) {
        this.filterDevicesWithoutFetching(changedItems);
      }
    });
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        this.filterObject = this.util.cloneDeepObject(result);
        this.filterDevices();
      }
    });
  }

  ngOnInit() {
    // Check for localStorage multicast option, when first initialised
    const multicastOption = localStorage.getItem(NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST);
    if (!!multicastOption) {
      try {
        this.isMulticast = (JSON.parse(multicastOption) as boolean) ? 'any' : 'false';
      } finally {
        this.isMulticast = 'false';
      }
    } else {
      this.isMulticast = 'false';
    }
  }

  getDevices() {
    this.isLoading = true;
    forkJoin({
      connectionData: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      tagData: this.deviceSrv.getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      zoneData: this.deviceSrv.getZones(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      deviceData: this.deviceSrv.getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        detailed: false,
        isMulticast: this.isMulticast,
      }),
      linkData: this.deviceSrv.getLinks(),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          if (!!this.deviceSrv.selected) {
            this.deviceSrv.selected = null;
          }
        }),
      )
      .subscribe((result) => {
        this.filterConfiguration.totalRecords = result.deviceData?.total_record || 0;
        this.values = (result.deviceData?.devices as any[]) || [];
        this.diagramDevices = ((result.deviceData?.devices as any[]) || []).map((p) => ({
          ...p,
          mac_address: Object.keys(p?.eth || {})?.[0] || '',
          ip_address: p?.ip ? Object.keys(p.ip)?.[0] + (Object.keys(p.ip).length > 1 ? '...' : '') : '',
        }));
        const links: any = this.util.cloneDeepObject(result.linkData || {});
        Object.entries(links).forEach(([key, linkItems]: [string, any]) => {
          if (key !== 'default') {
            links[key] = (linkItems as any[]).map((p) => ({ ...p, communication_protocol: key }));
          }
        });
        this.links = links;
        this.connections = result.connectionData?.data || [];

        const connectionOptions = (result.connectionData?.data as any[] | []).map((con: { name: any; id: any }) => ({
          label: con.name || con.id,
          value: con.id,
        }));
        if (!!this.filterConfiguration.filters?.[2]) {
          this.filterConfiguration.filters[2].options = connectionOptions;
        }
        const tagOptions = (result.tagData?.tags as any[] | []).map((tag) => ({
          label: tag.label,
          value: tag.id,
        }));
        const zoneOptions = (result.zoneData?.zones as any[] | []).map((zone) => ({
          label: zone.label,
          value: zone.id,
        }));
        if (this.filterConfiguration.filters?.[8]) {
          this.filterConfiguration.filters[8].options = tagOptions;
        }
        if (this.filterConfiguration.filters?.[9]) {
          this.filterConfiguration.filters[9].options = zoneOptions;
        }

        const selectedDevice = !!this.deviceSrv.selected
          ? this.diagramDevices.find((device) => device.id === this.deviceSrv.selected.id)
          : this.selectedDevice;

        // Click on Network Level from Device Page (+): Update Selected Device to Network-map parent component
        if (!this.selectedDevice || selectedDevice.id !== this.selectedDevice?.id) {
          this.selectedDevice = selectedDevice;
          this.changeSelectedDevice.emit(selectedDevice);
        }

        setTimeout(() => {
          this.diagramData$.next(
            this.util.cloneDeepObject({
              shouldResetDiagram: true,
              connections: this.connections,
              devices: this.diagramDevices,
              legends: this.getLegendData(this.links),
              links: this.getLinks(),
            }),
          );
        });
      });
  }

  filterDevices() {
    this.isLoading = true;
    this.deviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        sort: this.filterObject.sort?.key,
        reverse: !!this.filterObject.sort?.direction ? this.filterObject.sort.direction === 'desc' : true,
        detailed: false,
        ...(this.filterObject.filter || {}),
        search: this.filterObject.search,
        connectionIds: this.filterObject.filter?.connections,
        interfaceTypes: this.filterObject.filter?.interfaces,
        tagIds: this.filterObject.filter?.tags,
        zoneIds: this.filterObject.filter?.zones,
        createdFrom: this.filterObject.filter?.createdDates?.from
          ? moment(this.filterObject.filter?.createdDates?.from).toISOString() || ''
          : undefined,
        createdTo: this.filterObject.filter?.createdDates?.to ? moment(this.filterObject.filter?.createdDates?.to).toISOString() || '' : undefined,
        lastSeenFrom: this.filterObject.filter?.lastSeenDates?.from
          ? moment(this.filterObject.filter?.lastSeenDates?.from).toISOString() || ''
          : undefined,
        lastSeenTo: this.filterObject.filter?.lastSeenDates?.to ? moment(this.filterObject.filter?.lastSeenDates?.to).toISOString() || '' : undefined,
        isMulticast: this.isMulticast,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.filterConfiguration.totalRecords = res?.total_record || 0;
          this.values = (res?.devices as any[]) || [];
          // Make search node check
          this.diagramData$.next(
            this.filterObject.search !== '' || !!this.filterObject.isFiltered
              ? this.util.cloneDeepObject({
                  searchedDevices: this.diagramDevices?.filter((d) => !!this.values.find((p) => p.id === d.id)) || [],
                })
              : { searchedDevices: [] },
          );
        },
        error: () => {
          this.filterConfiguration.totalRecords = 0;
        },
      });
  }

  /**
   * Change item by client
   * @param changedItems
   */
  filterDevicesWithoutFetching(changedItems: any[]) {
    changedItems.forEach((item) => {
      const listDevice = this.values.find((p) => p.id === item.id);
      const listDeviceIndex = this.values.findIndex((p) => p.id === item.id);
      const diagramDevice = this.diagramDevices.find((p) => p.id === item.id);
      if (!!listDevice && !!diagramDevice) {
        listDevice.network_map_level = item.network_map_level;
        diagramDevice.network_map_level = item.network_map_level;
        this.values[listDeviceIndex] = this.util.cloneDeepObject(listDevice);
        this.dataView?.cd?.detectChanges();
      }
    });
    this.diagramData$.next(
      this.util.cloneDeepObject({
        shouldResetDiagram: true,
        connections: this.connections,
        devices: this.diagramDevices,
        legends: this.getLegendData(this.links),
        links: this.getLinks(),
      }),
    );
  }

  getLinks() {
    const filteredLinks: any[] = [];
    Object.values(this.links || {}).forEach((links: any) => {
      if (!!Array.isArray(links)) {
        filteredLinks.push(...((links as any[]) || []));
      }
    });
    return filteredLinks;
  }

  /**
   * Get legends
   * @param linksObj
   * @returns
   */
  getLegendData(linksObj: any) {
    const legends: any[] = [];
    Object.keys(linksObj).forEach((key) => {
      if (key !== 'default') {
        const legend = {
          value: key,
          label: INTERFACE_TYPE_LABELS?.[key] || key,
          color: INTERFACE_TYPE_OPTIONS.find((p) => p.value === key)?.color || this.getDynamicColor(),
        };
        legends.push(legend);
      }
    });
    return legends;
  }

  changeSelectedItemDevice(value: any) {
    this.selectedDevice = value;
    this.changeSelectedDevice.emit(this.selectedDevice);
  }
  // Multicast Setting Checkbox Change
  handleMulticastChange(settings: any) {
    //single object update send by setting options when checkbox is toggled
    if (!Array.isArray(settings)) {
      if (settings.value === NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST) {
        this.isMulticast = settings.checked ? 'any' : 'false';
        //clear the filters in threat toolbar if any filters are applied
        this.toolbar.clearFilters();
        //close the details panel if it is open
        if (!!this.isItemDetailsDisplayed) {
          // Toggle Multicast Option (-): Remove Selected Device from Network-map parent component
          this.selectedDevice = null;
          this.changeSelectedDevice.emit(null);
          setTimeout(() => {
            this.itemDetails$.next(null);
          });
        }
        this.getDevices();
      }
    }
  }

  /**
   * Generate dynamic colors
   * @returns
   */
  private getDynamicColor() {
    return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
  }
}
