import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { PIPE_DATETIME } from '@microsec/constants';
import { NETWORK_MAP_MODE_VALUES } from '@ids-constants';
import { LEVEL_OPTIONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { TargetDeviceService } from '@ids-services';

import { BehaviorSubject, Observable, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-network-map-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  @Input() mode = NETWORK_MAP_MODE_VALUES.PURDUE_MODEL;

  @Input() type: any = null;

  _item: any = {};

  @Input() set item(value: any) {
    value.levelLabel = this.getLevelLabel(value.network_map_level);
    value.levelOptions = this.util.cloneObjectArray(LEVEL_OPTIONS).map((option: any) => ({
      ...option,
      command: () => this.changeLevel(value, option?.value),
    }));
    this._item = value;
  }
  get item() {
    return this._item;
  }

  @Input() itemDetails$ = new BehaviorSubject<any>(null);

  @Input() diagramData$ = new BehaviorSubject<any>(null);

  @Input() changedItems$ = new BehaviorSubject<any>(null);

  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() changeSelectedDevice = new EventEmitter<any>();

  LEVEL_OPTIONS = LEVEL_OPTIONS;

  PIPE_DATETIME = PIPE_DATETIME;

  NETWORK_MAP_MODE_VALUES = NETWORK_MAP_MODE_VALUES;

  filteredDevices: any[] = [];

  /* properties for dragging event */
  dragging = false;
  startX = 0;
  startY = 0;
  threshold = 5;

  constructor(private deviceSrv: TargetDeviceService) {
    super();
  }

  ngAfterViewInit(): void {
    this.diagramData$.asObservable().subscribe((data) => {
      if (!!data && !!data.filteredDevices) {
        this.filteredDevices = data.filteredDevices;
      }
    });
  }

  /* -- Handle dragging event for copying text and prevent onClick event from happening -- */
  onMouseDown(event: MouseEvent) {
    this.dragging = false;
    this.startX = event.clientX;
    this.startY = event.clientY;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.dragging) {
      const deltaX = Math.abs(event.clientX - this.startX);
      const deltaY = Math.abs(event.clientY - this.startY);
      if (deltaX > this.threshold || deltaY > this.threshold) {
        this.dragging = true;
      }
    }
  }

  onMouseUp(event: MouseEvent, type: any, data: any) {
    if (!this.dragging) {
      this.openDetailsPanel(event, type, data);
    }
  }
  /*------------------------------------------------------------------- */

  openDetailsPanel(event: MouseEvent, type: any, data: any) {
    if (!this.dragging) {
      // Click on Device Item Card (+): Update Selected Device to Network-map parent component
      this.changeSelectedDevice.emit(data);
      setTimeout(() => {
        const clickedElement = event.target as HTMLElement;
        const levelSelectionElement = document.querySelector(`#device-${data?.id}-network-map-level-selection`);
        if (!levelSelectionElement?.contains(clickedElement)) {
          this.itemDetails$.next({ type, data });
        }
      });
    }
  }

  getMacAddress(ethValue: any) {
    return !!ethValue ? Object.keys(ethValue)?.[0] || '-' : '-';
  }

  getIpAddress(ipValue: any) {
    const ips = !!ipValue ? Object.keys(ipValue) : [];
    return {
      label: !!ips.length ? Object.keys(ipValue)?.[0] + (ips.length > 1 ? '...' : '') : '-',
      tooltip: !!ips.length ? ips.join(', ') : '',
    };
  }

  getDeviceNames(devices: any[]) {
    return devices?.map((p) => p.label).join(', ') || '-';
  }

  openLevelOptions(event: MouseEvent) {
    const splittedButton = (event.target as HTMLElement).closest('.network-map-level-selection');
    const children = splittedButton?.querySelectorAll('.p-splitbutton-menubutton');
    children?.forEach((child) => {
      const element = child as HTMLElement;
      element.click();
    });
  }

  changeLevel(device: any, newLevel: any) {
    this.isLoading = true;
    const changedItems = [{ id: device.id, network_map_level: newLevel }];
    const requests: Observable<any>[] = [this.deviceSrv.updateDevice(device.id, { network_map_level: newLevel })];
    // Update imaginary device level
    const currentDevice = this.filteredDevices.find((p) => p.id === device.id);
    const deviceParent = this.filteredDevices.find((p) => p.id === currentDevice?.parent);
    if (!!deviceParent && !!deviceParent.imaginary_device) {
      const highestLevelSibling =
        this.util
          .cloneObjectArray(this.filteredDevices)
          .filter((node) => node.parent === deviceParent?.id && node.id !== device.id)
          .sort((a, b) => a.network_map_level - b.network_map_level)
          .pop()?.network_map_level || -1;
      const newParentLevel = highestLevelSibling > newLevel ? highestLevelSibling : newLevel;
      if (deviceParent?.network_map_level !== newParentLevel) {
        changedItems.push({ id: deviceParent.id, network_map_level: newParentLevel });
        requests.push(this.deviceSrv.updateDevice(deviceParent.id, { network_map_level: newParentLevel }));
      }
    }

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.changedItems$.next(changedItems);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  getLevelLabel(networkMapLevel: any) {
    if (networkMapLevel !== -1 && networkMapLevel !== undefined) {
      return `Level ${networkMapLevel}`;
    }
    return 'Not Defined';
  }
}
