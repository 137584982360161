export const ONBOARDING_PROGRESS = 'onboardingProgress';

export const INDUSTRIES = {
  BUILDING_AUTOMATION_SYSTEMS: 'Building Automation Systems',
  MANUFACTURING: 'Manufacturing',
  AUTOMOTIVE: 'Automotive',
  CHEMICAL: 'Chemical',
  ELECTRIC_UTILITIES: 'Electric Utilities',
  FOOD_BEVERAGE: 'Food & Beverage',
  OIL_GAS: 'Oil & Gas',
  WATER_SYSTEMS: 'Water Systems',
  MINING: 'Mining',
  PHARMACEUTICAL_BIOTECHNOLOGY: 'Pharmaceutical & Biotechnology',
  TRANSPORTATION: 'Transportation',
  HEALTHCARE: 'Healthcare',
  PUBLIC_SECTOR: 'Public Sector',
  MARITIME: 'Maritime',
};

export const OBJECTIVES = {
  DETECTION: 'Detecting and alerting network-based threats and anomalies',
  PROTECTION: 'Protecting specific OT and IoT devices from targeted attacks',
  EVALUATION: 'System Evaluation (Assessments)',
};

export const OBJECTIVE_OPTIONS = [
  { value: OBJECTIVES.DETECTION, label: OBJECTIVES.DETECTION },
  { value: OBJECTIVES.PROTECTION, label: OBJECTIVES.PROTECTION },
  { value: OBJECTIVES.EVALUATION, label: OBJECTIVES.EVALUATION },
];
