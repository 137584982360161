import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './lib/base-component/base.component';
import { BaseAppComponent } from './lib/base-app-component/base-app-component.component';
import { RedirectionComponent } from './lib/redirection-component/redirection.component';

@NgModule({
  declarations: [BaseAppComponent, BaseComponent, RedirectionComponent],
  imports: [CommonModule],
  exports: [BaseAppComponent, BaseComponent, RedirectionComponent],
})
export class ComponentsModule {}
