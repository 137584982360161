import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AnalyzerRuleTypesPipe,
  ArrayMapPipe,
  ArrayToPlaceholderStringPipe,
  ConstantPipe,
  DetectedDeviceInterfacesPipe,
  MiddleEllipsisPipe,
  MomentPipe,
  SortPipe,
  ThreatScorePipe,
  ThreatTitlePipe,
  UnixTimestampTransformPipe,
} from './lib/custom-pipe.pipe';

@NgModule({
  declarations: [
    ThreatScorePipe,
    AnalyzerRuleTypesPipe,
    DetectedDeviceInterfacesPipe,
    UnixTimestampTransformPipe,
    SortPipe,
    ArrayMapPipe,
    ArrayToPlaceholderStringPipe,
    MiddleEllipsisPipe,
    MomentPipe,
    ThreatTitlePipe,
    ConstantPipe,
  ],
  imports: [CommonModule],
  exports: [
    ThreatScorePipe,
    AnalyzerRuleTypesPipe,
    DetectedDeviceInterfacesPipe,
    UnixTimestampTransformPipe,
    SortPipe,
    ArrayMapPipe,
    ArrayToPlaceholderStringPipe,
    MiddleEllipsisPipe,
    MomentPipe,
    ThreatTitlePipe,
    ConstantPipe,
  ],
})
export class PipesModule {}
