import { Component, Input } from '@angular/core';
import { ANALYZER_DATA_TYPES, ANALYZER_TYPES } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService } from '@ids-services';
import { ConnectionService } from '@ids-services';

import moment from 'moment';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-ml-analyzer-overview',
  templateUrl: './ml-analyzer-overview.component.html',
  styleUrls: ['./ml-analyzer-overview.component.scss'],
})
export class MlAnalyzerOverviewComponent extends BaseComponent {
  isLoading = false;

  _analyzer: any = null;

  get analyzer() {
    return this._analyzer;
  }

  @Input() set analyzer(value: any) {
    this._analyzer = value;
    if (!!this.analyzer) {
      if (!!this.analyzer.rule_download_last_timestamp) {
        this.analyzer.ruleDownloadLastTime = moment
          .utc(this.analyzer?.rule_download_last_timestamp)
          .local()
          .fromNow();
      }
      if (!!this.analyzer.connection_ids?.[0] && (!this.connection || this.connection.id !== this.analyzer.connection_ids?.[0])) {
        this.getConnection();
      }
      this.timer = !!value.timer ? [{ timeout: value.timer.timeout, grace: value.timer.grace }] : [];
    }
  }

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_DATA_TYPES = ANALYZER_DATA_TYPES;

  connection: { id?: number; name?: string } | null = null;

  timer: any[] = [];

  timerCols: any[] = [
    { field: 'timeout', header: 'Period', width: 10 },
    { field: 'grace', header: 'Grace Time', width: 10 },
  ];

  rulesCols: any[] = [{ field: 'formula', header: 'Formula', width: 20 }];

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  getConnection() {
    this.isLoading = true;
    this.connectionSrv
      .getConnection(this.analyzer.connection_ids[0])
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.connection = {
            id: rs.data.id,
            name: rs.data.name,
          };
        },
        error: (err: any) => {
          this.connection = null;
          this.showErrorMessage(err);
        },
      });
  }

  downloadRule() {
    this.isLoading = true;
    this.anomalyAnalyzerSrv
      .updateAnalyzer(this.analyzer.id, { is_sync_scheduled: true })
      .pipe(
        finalize(() => {
          this.anomalyAnalyzerSrv.refresh$.next(true);
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Downloading');
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }
}
