import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@ids-shared';

import { SharedOrganizationFormComponent } from './shared-organization-form.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedOrganizationFormComponent],
  exports: [SharedOrganizationFormComponent],
})
export class SharedOrganizationFormModule {
  static forRoot(): ModuleWithProviders<SharedOrganizationFormModule> {
    return {
      ngModule: SharedOrganizationFormModule,
      providers: [],
    };
  }
}
