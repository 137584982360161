import { Component, OnInit, ViewChild } from '@angular/core';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { ReportSpecification } from '@microsec/models';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent } from '@microsec/components';
import { TargetDeviceService } from '@ids-services';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ActionMenuItem } from '@microsec/models';

import { BehaviorSubject, finalize, forkJoin } from 'rxjs';

import { TagFormComponent } from './tag-form/tag-form.component';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

const FIELDS = {
  id: 'ID',
  label: 'Name',
  description: 'Description',
};

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class TagsComponent extends BaseComponent implements OnInit {
  isLoading = true;

  firstIndex = 0;

  @ViewChild('dt') dt!: CommonTableComponent;

  values: any[] = [];

  cols = [
    { field: 'label', header: FIELDS.label, width: 10 },
    { field: 'description', header: FIELDS.description, width: 15 },
  ];

  globalFilterFields: string[] = ['label', 'description'];

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  selectedTags: any[] = [];

  selectedTag: any = null;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openTagForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.getTags();
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result?.search || '';
      }
    });
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.targetDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getTags();
      }
    });
    this.subscriptions.push(subscription);
  }

  getTags() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.targetDeviceSrv.selected = null;
        }),
      )
      .subscribe({
        next: (res) => {
          this.values = res.tags || [];
          if (!!this.selectedTag) {
            this.selectedTag = this.values.find((tag) => tag.id === this.selectedTag.id);
          }
          if (!!this.targetDeviceSrv.selected) {
            const values = this.util.cloneObjectArray(this.values);
            const selectedIndex = values.findIndex((tag) => tag.id === this.targetDeviceSrv.selected.id);
            if (selectedIndex >= 0) {
              this.firstIndex = Math.floor(selectedIndex / PER_PAGE) * PER_PAGE;
              this.selectedTag = { ...values[selectedIndex] };
            }
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = ((specification.data as any[]) || []).map((tag) => ({
          ...tag,
        }));
        this.dt?.generateReportDialog.exportReport(data, `project_${this.breadcrumbConfig?.projectId}_tags`);
      }
    };
    this.dt?.generateReportDialog.open(onGenerateReport, FIELDS, this.cols, [], this.values);
  }

  openTagForm(tag?: any) {
    const dialog = this.dialogSrv.open(TagFormComponent, {
      data: { tag: !tag ? null : tag },
      header: `${!tag ? 'Create' : 'Edit'} Tag`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.targetDeviceSrv.refresh$.next(rs);
      }
    });
  }

  openDeleteConfirmation(tags: any[] = []) {
    const tagList = tags.map((tag) => `<li>${`Tag ID ${tag.id}: ${tag.label}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Tag(s)',
      customContent: `Are you sure you want to delete ${!tags?.length ? 'all tags?<br/><br/>' : `the following tag(s)?<ul>${tagList.join('')}</ul>`}`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: forkJoin((!!tags.length ? tags : this.values).map((tag) => this.targetDeviceSrv.deleteTag(tag.id))),
      next: () => {
        this.showSuccessMessage(`Deleted tag(s) successfully`);
        const tagIds = tags.map((tag) => tag.id) || [];
        if (!!this.selectedTags?.length) {
          this.selectedTags = this.selectedTags.filter((tag) => !tagIds?.includes(tag.id));
        }
        if (!!this.selectedTag && !!tagIds.includes(this.selectedTag.id)) {
          this.selectedTag = null;
        }
        this.getTags();
        this.isLoading = false;
      },
      error: (err) => {
        this.showErrorMessage(err);
        this.isLoading = false;
      },
    });
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedTag = null;
  }
}
