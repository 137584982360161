<microsec-loading-overlay [isLoading]="!!isLoading">
  <microsec-card [isDetailCard]="true">
    <p-tabView [scrollable]="true" styleClass="microsec-dashboard-tab" *ngIf="!!device">
      <p-tabPanel header="Overview">
        <app-network-device-overview [device]="device"></app-network-device-overview>
      </p-tabPanel>
      <p-tabPanel header="VLANs">
        <app-shared-vlans [device]="device"></app-shared-vlans>
      </p-tabPanel>
      <p-tabPanel header="Firewalls">
        <app-shared-firewalls [device]="device"></app-shared-firewalls>
      </p-tabPanel>
    </p-tabView>
  </microsec-card>
</microsec-loading-overlay>
