<div class="flex flex-column gap-3">
  @if (!isReport) {
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="toolbarLeftTemplate"></microsec-common-toolbar>
  }

  <microsec-card [isNestedCard]="true" [isTableCard]="true">
    <microsec-common-table
      #dt
      dataKey="tempId"
      [globalFilterFields]="globalFilterFields"
      [cols]="cols"
      [values]="values"
      [loading]="isLoading"
      [cellTemplate]="cellTemplate"
      [selectable]="!isReport"
      [selectionMode]="!!isReport ? 'single' : 'multiple'"
      [(selection)]="selectedMitigations"
      itemName="Mitigation"
      [actionsMenuItems]="!isReport ? actionsMenuItems : undefined"
      [containerHeaderRightTemplate]="!isReport ? $any(containerHeaderRightTemplate) : undefined">
    </microsec-common-table>
  </microsec-card>
</div>

<ng-template #toolbarLeftTemplate>
  <p-button type="button" icon="fa fa-plus" label="Add Mitigation" severity="primary" [rounded]="true" (onClick)="openMitigationForm()"> </p-button>
</ng-template>

<ng-template #containerHeaderRightTemplate>
  <div *ngIf="!isReport" class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedMitigations)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedMitigations.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
  </div>
</ng-template>

<ng-template let-index="index" let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Description -->
    <ng-container *ngSwitchCase="'description'">
      <div class="flex flex-wrap">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
