<div class="force-directed-item-details flex flex-column w-full px-2 pt-2" *ngIf="!isLoading">
  <div class="grid w-full pt-0 pr-0">
    <div class="col-12 pt-0 pr-0">
      <ng-container [ngSwitch]="type">
        <!-- Device -->
        <ng-container *ngSwitchCase="'device'">
          <app-detected-device-details
            [deviceId]="item?.id"
            (hidePanelEvent)="hideItemDetails()"
            (saveCallbackEvent)="refreshNetworkPanelAndMap()"></app-detected-device-details>
        </ng-container>
        <!-- Threat -->
        <ng-container *ngSwitchCase="'threat'">
          <div style="margin-right: -7.5px">
            <app-threat-details [threatId]="item.threat_id" (hidePanelEvent)="hideItemDetails()"></app-threat-details>
          </div>
        </ng-container>
        <!-- Others -->
        <ng-container *ngSwitchDefault> </ng-container>
      </ng-container>
    </div>
  </div>
</div>
