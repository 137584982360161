<p-messages [(value)]="messageValue" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [enableService]="false" />

<microsec-card>
  <div style="max-width: 50rem">
    <microsec-form-builder
      [isPage]="true"
      #fb
      [fields]="fields"
      [shouldShowActionButtons]="true"
      [submitButtonLabel]="SAVE_CHANGES_LABEL"
      [cancelButtonLabel]="''"
      (submitEvent)="onSubmit()"
      [leftActionArea]="leftActionArea" />
  </div>
</microsec-card>

<ng-template #enabledTemplate>
  <ng-container
    *ngIf="
      !!form &&
      !!form?.form &&
      !!form?.form?.get([FORM_PARAMS.ENABLED, FORM_PARAMS.PACKAGES]) &&
      !!form?.form?.get([FORM_PARAMS.ENABLED, FORM_PARAMS.CONFIGURATIONS]) &&
      !!form?.form?.get([FORM_PARAMS.ENABLED, FORM_PARAMS.FIRMWARES]) &&
      !!form?.form?.get([FORM_PARAMS.ENABLED, FORM_PARAMS.PROTOCOLS])
    "
    [formGroup]="$any(form.form)">
    <ng-container [formGroupName]="FORM_PARAMS.ENABLED">
      <div class="grid">
        <div class="col-6 flex flex-column gap-3">
          <label class="font-bold">Enabled Vulnerability Types</label>
          <label class="text-grey">Select the vulnerability types to be enabled on MicroIDS</label>
        </div>
        <div class="col-6 flex flex-column gap-3">
          <p-checkbox [binary]="true" label="Configuration" [formControlName]="FORM_PARAMS.CONFIGURATIONS"></p-checkbox>
          <p-checkbox [binary]="true" label="Firmware" [formControlName]="FORM_PARAMS.FIRMWARES"></p-checkbox>
          <p-checkbox [binary]="true" label="Package" [formControlName]="FORM_PARAMS.PACKAGES"></p-checkbox>
          <p-checkbox [binary]="true" label="Protocol" [formControlName]="FORM_PARAMS.PROTOCOLS"></p-checkbox>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #frequencyTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="grid">
        <label class="col-12 font-bold mb-3">Vulnerability Scan Frequency</label>
        <div class="col-6">
          <label class="text-grey">Set a schedule to initiate a vulnerability scan automatically</label>
        </div>
        <div class="col-6 flex flex-column gap-3">
          <p-dropdown
            [options]="SYNC_FREQUENCY_OPTIONS"
            [formControlName]="FORM_PARAMS.SCAN_FREQUENCY"
            placeholder="Select frequency"
            optionLabel="label"
            optionValue="value"
            appendTo="body"></p-dropdown>
          <!-- (onChange)="updateConfiguration()" -->
        </div>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #connectionsTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="grid">
        <label class="col-12 font-bold mb-3">Network Connection</label>
        <div class="col-6">
          <label class="text-grey">Select network connection(s) for vulnerability analyzer to scan</label>
        </div>
        <div class="col-6 flex flex-column gap-3">
          <p-multiSelect
            appendTo="body"
            [options]="connectionOptions"
            [formControlName]="FORM_PARAMS.CONNECTION_IDS"
            optionLabel="label"
            optionValue="value"
            defaultLabel="Select connections...">
            <!-- (onChange)="updateConfiguration()" -->
          </p-multiSelect>
        </div>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #leftActionArea>
  <p-button [label]="CANCEL_LABEL" severity="secondary" styleClass="mr-2" (onClick)="getConfigurationData()"> </p-button>
</ng-template>
