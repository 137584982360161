<div class="grid">
  <div class="col-12" *ngIf="!isDevice">
    <span class="text-2xl">Question Overview</span>
  </div>
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [rightTemplate]="$any(rightTemplate)"></microsec-common-toolbar>
  </div>
  <div class="col-12">
    <p-accordion styleClass="report-accordion">
      <ng-container *ngFor="let section of filteredSections || []">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="ml-2 w-full flex align-items-center justify-content-between gap-2">
              <div class="flex align-items-center gap-2">
                <i class="fas fa-clipboard-list"></i>
                <span class="vertical-align-middle">{{ section.section_name }}</span>
              </div>
              <div class="flex align-items-center gap-2">
                <div *ngIf="!!section.result" class="flex align-items-center gap-2">
                  <label class="font-bold">Compliance:</label>
                  <div [class]="'w-10rem custom-chip square' + getResultClass(section.result)">
                    {{ section.result | constant: 'assessment-result' }}
                  </div>
                </div>
                <div *ngIf="!!isDevice && !!showScoreSL" class="flex align-items-center gap-2">
                  <label class="font-bold">•</label>
                  <label class="font-bold">SL-A/SL-T:</label>
                  <div [class]="'custom-chip square' + getResultClass(section.result)">{{ section.sla }}/{{ section.slt }}</div>
                  <span
                    [pTooltip]="'SL-A Score: ' + section.sla + '<br>SL-T Score: ' + section.slt + '<br>SL-C Score: ' + section.slc"
                    [escape]="false"
                    tooltipPosition="right"
                    appendTo="body">
                    <i class="pi pi-info-circle"></i>
                  </span>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-container *ngFor="let question of section.questions || []">
            <div class="report-question-list">
              <div class="flex flex-column gap-3 ml-4">
                <div class="cursor-pointer" (click)="onToggleQuestionDetails(question)">
                  <div class="flex align-items-center justify-content-between gap-2">
                    <div class="flex align-items-center gap-2">
                      <i [class]="!question.showDetail ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"> </i>
                      <span class="font-bold">
                        {{ question.question_title }}
                      </span>
                    </div>
                    <div class="flex align-items-center gap-2">
                      <div *ngIf="!!question.result" class="flex align-items-center gap-2">
                        <label class="font-bold">Compliance:</label>
                        <div [class]="'w-10rem custom-chip square' + getResultClass(question.result)">
                          {{ question.result | constant: 'assessment-result' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                @if (!!question.showDetail) {
                  <div class="-mt-4 -ml-4">
                    <p-divider></p-divider>
                  </div>
                  <div class="-mt-2">
                    <span class="font-bold text-lg">
                      {{ question.question_title }} <microsec-asterisk *ngIf="!!question.question_mandatory"></microsec-asterisk>
                    </span>
                  </div>
                  <div class="-mt-2" *ngIf="!!question.question_descriptionPreview">
                    <div
                      [ngClass]="{
                        'max-h-2rem overflow-hidden': !question.showDetailDescription,
                        'overflow-auto': !!question.showDetailDescription
                      }">
                      <div class="markdown-preview" [innerHTML]="question.question_descriptionPreview"></div>
                    </div>
                    <span class="mt-2 link" (click)="question.showDetailDescription = !question.showDetailDescription">
                      {{ !!question.showDetailDescription ? 'Show less details' : 'Show more details' }}
                      <i [class]="!!question.showDetailDescription ? 'pi pi-chevron-up ml-2' : 'pi pi-chevron-down ml-2'"> </i>
                    </span>
                  </div>
                  <div class="-mt-2">
                    <p-divider></p-divider>
                    <p-tabView styleClass="-mt-2 microsec-dashboard-tab">
                      <p-tabPanel header="Results">
                        @if (!!question.devices?.length) {
                          <div>
                            <app-assessment-devices
                              mode="report_question"
                              [isAllDevicesInit]="true"
                              [allDevices]="allDevices || []"
                              [devices]="question.devices || []"
                              [confidenceScore]="question.final_confidence_score"></app-assessment-devices>
                          </div>
                        } @else {
                          <div class="grid align-items-center mb-2">
                            <label class="col-2 font-bold text-data">Compliance</label>
                            <div class="col-10">
                              <div [class]="'w-10rem custom-chip square' + getResultClass(question.result)">
                                {{ question.result | constant: 'assessment-result' }}
                              </div>
                            </div>
                          </div>
                          <div class="grid align-items-center">
                            <label class="col-2 font-bold text-data">Confidence Score</label>
                            <div class="col-10">
                              @if (question.final_confidence_score === 1) {
                                <div class="w-max custom-chip square green flex align-items-center gap-2">
                                  <i class="fas fa-check"></i>
                                  100%
                                </div>
                              } @else {
                                <div class="w-max custom-chip square grey custom-border flex align-items-center gap-2">
                                  <img src="/assets/images/prefilling-icon.svg" alt="prefilling-icon" />
                                  <label class="text-data">
                                    {{
                                      question.final_confidence_score === 0 || question.final_confidence_score > 0
                                        ? (question.final_confidence_score * 100 | number: '1.0-0') + '%'
                                        : '-'
                                    }}
                                  </label>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </p-tabPanel>
                      <p-tabPanel
                        header="Recommended Mitigations"
                        *ngIf="
                          !hideMitigations && question.result !== ASSESSMENT_RESULTS.COMPLIANT && question.result !== ASSESSMENT_RESULTS.UNANSWERED
                        ">
                        <app-assessment-mitigations [isReport]="true" [mitigations]="question.mitigations"></app-assessment-mitigations>
                      </p-tabPanel>
                      <p-tabPanel header="Comments" *ngIf="!!question.comment">
                        <div class="grid form no-wrapper">
                          <div class="col-12">
                            <label class="font-bold">Comments</label>
                          </div>
                          <div class="col-12">
                            <textarea class="w-full h-8rem" pInputTextarea [autoResize]="false" [ngModel]="question.comment" [disabled]="true">
                            </textarea>
                          </div>
                        </div>
                      </p-tabPanel>
                      <p-tabPanel header="Atatchments" *ngIf="!!question.attachments?.length">
                        <div class="grid form no-wrapper">
                          <div class="col-12">
                            <label class="font-bold">Attachments ({{ question.attachments.length }})</label>
                          </div>
                          <div class="col-12">
                            <div class="flex flex-wrap align-items-center gap-3">
                              <ng-container *ngFor="let attachment of question.attachments || []">
                                <div class="attachment-item">
                                  <div class="h-3rem w-11rem flex align-items-center gap-3">
                                    <div>
                                      <i [class]="'text-5xl ' + (ATTACHMENT_FILE_ICONS[attachment.file_type] || 'fa fa-file')"> </i>
                                    </div>
                                    <div class="flex flex-column">
                                      <label
                                        class="w-min max-w-8rem text-sm overflow-hidden white-space-nowrap text-overflow-ellipsis"
                                        [pTooltip]="attachment.file_name"
                                        tooltipPosition="bottom"
                                        appendTo="body">
                                        {{ attachment.file_name }}
                                      </label>
                                      <label class="text-xs text-grey">
                                        {{ !!attachment.file_size ? util.formatBytes(attachment.file_size) : '-' }}
                                      </label>
                                      <div class="flex align-content-center">
                                        <label class="text-sm link-blue" (click)="downloadAttachment(attachment)"> Download </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
                    </p-tabView>
                  </div>
                }
              </div>
            </div>
          </ng-container>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  </div>
</div>
