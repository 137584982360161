import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { AssessmentService } from '@ids-services';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

import { FILTER_PARAMS } from '../dashboard-compliance.component';

@Component({
  selector: 'app-dashboard-compliance-filters',
  templateUrl: './dashboard-compliance-filters.component.html',
  styleUrls: ['./dashboard-compliance-filters.component.scss'],
})
export class DashboardComplianceFiltersComponent extends BaseComponent implements OnInit {
  isLoading = false;

  filters: any = null;

  filterByOptions = [
    { label: 'All', value: 'all' },
    { label: 'Selected Tag(s)', value: 'tag' },
    { label: 'Selected Device(s)', value: 'device' },
  ];

  filterBy: 'all' | 'tag' | 'device' = 'all';

  assessmentTypes: any[] = [];

  tags: any[] = [];

  FILTER_PARAMS = FILTER_PARAMS;

  constructor(
    private assessmentSrv: AssessmentService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.prepareConfigs();
    this.getAssessmentTypes();
    this.filters = this.util.cloneDeepObject(
      this.dialogConfig?.data?.filters || {
        [FILTER_PARAMS.ASSESSMENT_TYPE_ID]: [],
        [FILTER_PARAMS.TAG_ID]: [],
        [FILTER_PARAMS.DEVICE_ID]: [],
      },
    );
    this.filterBy = this.filters[FILTER_PARAMS.TAG_ID]?.length ? 'tag' : this.filters[FILTER_PARAMS.DEVICE_ID]?.length ? 'device' : 'all';
  }

  getAssessmentTypes() {
    this.isLoading = true;
    this.assessmentSrv
      .getAssessmentTypes(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.assessmentTypes = ((res.assessment_types as any[]) || []).map((type) => ({
            label: type.name,
            value: type.id,
          }));
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onApply() {
    const filter = {
      ...this.filters,
      [FILTER_PARAMS.TAG_ID]: this.filterBy === 'tag' ? this.filters[FILTER_PARAMS.TAG_ID] : [],
      [FILTER_PARAMS.DEVICE_ID]: this.filterBy === 'device' ? this.filters[FILTER_PARAMS.DEVICE_ID] : [],
    };
    this.dialogRef.close(filter);
  }
}
