import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, FeatureGuard, LoginGuard, OrganizationGuard, ProjectGuard, SuperAdminFeatureGuard } from '@microsec/guards';
import { NotAuthorizedComponent, NotFoundComponent } from '@microsec/components';
import { NOT_AUTHORIZED_URL, NOT_FOUND_URL } from '@microsec/constants';
import { MODULES } from './products/modules';
import { RedirectionComponent } from 'libs/components/src/lib/redirection-component/redirection.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: MODULES.REDIRECTION.ROUTE, pathMatch: 'full' },
      {
        path: MODULES.REDIRECTION.ROUTE,
        component: RedirectionComponent,
        data: { Animation: 'rootRouteChangeAnimation' },
      },
      {
        path: MODULES.SYSTEM_SETTINGS.ROUTE,
        canLoad: [SuperAdminFeatureGuard],
        loadChildren: () => import('./products/system-settings/system-settings.module').then((m) => m.SystemSettingsModule),
        data: { Animation: 'rootRouteChangeAnimation' },
      },
      {
        path: MODULES.ORGANIZATION_MANAGEMENT.ROUTE,
        canLoad: [FeatureGuard],
        canActivate: [OrganizationGuard],
        loadChildren: () => import('./products/organization-management/organization-management.module').then((m) => m.OrganizationManagementModule),
        data: { Animation: 'rootRouteChangeAnimation' },
      },
      {
        path: MODULES.PROJECT_MANAGEMENT.ROUTE,
        canLoad: [FeatureGuard],
        canActivate: [OrganizationGuard, ProjectGuard],
        loadChildren: () => import('./products/project-management/project-management.module').then((m) => m.ProjectManagementModule),
        data: { Animation: 'rootRouteChangeAnimation' },
      },
      {
        path: MODULES.USER_SETTINGS.ROUTE,
        canLoad: [FeatureGuard],
        loadChildren: () => import('./products/user-settings/user-settings.module').then((m) => m.UserSettingsModule),
        data: { Animation: 'rootRouteChangeAnimation' },
      },
      {
        path: MODULES.SYSTEM_GETTING_STARTED.ROUTE,
        loadChildren: () => import('./products/system-getting-started/system-getting-started.module').then((m) => m.SystemGettingStartedModule),
        data: { Animation: 'rootRouteChangeAnimation' },
      },
      {
        path: MODULES.PROJECT_GETTING_STARTED.ROUTE,
        canActivate: [OrganizationGuard, ProjectGuard],
        loadChildren: () => import('./products/project-getting-started/project-getting-started.module').then((m) => m.ProjectGettingStartedModule),
        data: { Animation: 'rootRouteChangeAnimation' },
      },
    ],
  },
  {
    path: MODULES.LOGIN.ROUTE,
    canLoad: [LoginGuard],
    loadChildren: () => import('./products/login/login.module').then((m) => m.LoginModule),
    data: { Animation: 'rootRouteChangeAnimation' },
  },
  {
    path: NOT_AUTHORIZED_URL.replace('/', ''),
    component: NotAuthorizedComponent,
    data: { Animation: 'rootRouteChangeAnimation' },
  },
  {
    path: '**',
    redirectTo: NOT_FOUND_URL.replace('/', ''),
    pathMatch: 'full',
  },
  {
    path: NOT_FOUND_URL.replace('/', ''),
    component: NotFoundComponent,
    data: { Animation: 'rootRouteChangeAnimation' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
