import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { ReportSpecification } from '@microsec/models';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent } from '@microsec/components';
import { ActionMenuItem } from '@microsec/models';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { AssessmentService } from '@ids-services';

import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable, finalize, forkJoin } from 'rxjs';

import { AssessmentTypeFormComponent } from './assessment-type-form/assessment-type-form.component';
import { AssessmentTypeCreatorComponent } from './assessment-type-creator/assessment-type-creator.component';

const FIELDS = {
  id: 'ID',
  name: 'Name',
  description: 'Description',
};

@Component({
  selector: 'app-assessment-types',
  templateUrl: './assessment-types.component.html',
  styleUrls: ['./assessment-types.component.scss'],
})
export class AssessmentTypesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  @ViewChild('dt') dt!: CommonTableComponent;

  values: any[] = [];

  cols = [
    { field: 'id', header: FIELDS.id, width: 8 },
    { field: 'name', header: FIELDS.name, width: 10 },
    { field: 'description', header: FIELDS.description, width: 15 },
  ];

  currentPage = 1;

  totalRecords = 0;

  selectedAssessmentTypes: any[] = [];

  selectedAssessmentType: any = null;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search Name, or Description...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(private assessmentSrv: AssessmentService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fa fa-edit',
        visible: ({ rowData }) => !!((this.permissions as any)[rowData.scope] || this.permissions[this.SCOPE.PROJECT])[this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openAssessmentTypeCreator(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: ({ rowData }) => !!((this.permissions as any)[rowData.scope] || this.permissions[this.SCOPE.PROJECT])[this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.handleFilterObjUpdate();
    this.getAssessmentTypes();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.assessmentSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getAssessmentTypes();
      }
    });
    this.subscriptions.push(subscription);
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.filterSearch = values?.search || '';
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getAssessmentTypes();
      }
    });
  }

  getAssessmentTypes(resetSelectedTypes = true, event?: LazyLoadEvent) {
    this.isLoading = true;
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    const requests: Observable<any>[] = [
      this.assessmentSrv.getAssessmentTypes(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        page,
        perPage,
        this.dt?.datatable?.sortField as any,
        this.dt?.datatable?.sortOrder ? this.dt?.datatable?.sortOrder === 1 : true,
        this.filterSearch,
      ),
    ];
    if (!!this.assessmentSrv.selected?.id) {
      requests.push(this.assessmentSrv.getAssessmentType(this.assessmentSrv.selected.id).pipe(this.handleRequest().bind(this)));
    }
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          if (!!this.assessmentSrv.selected) {
            this.assessmentSrv.selected = null;
          }
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res[0]?.page;
          this.totalRecords = res[0]?.total_record;
          this.values = res[0].assessment_types || [];
          if (!!this.selectedAssessmentType) {
            this.selectedAssessmentType = this.values.find((at) => at.id === this.selectedAssessmentType.id) || null;
          }
          if (!!resetSelectedTypes) {
            this.selectedAssessmentTypes = [];
          }
          if (!!res[1]) {
            const selected = this.values.find((at) => at.id === res[1].id);
            if (!!selected) {
              this.selectedAssessmentType = selected;
            } else {
              this.values.unshift(res[1]);
              this.selectedAssessmentType = res[1];
            }
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        this.dt.generateReportDialog.exportReport(specification.data || [], `project_${this.breadcrumbConfig?.projectId}_types`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  openAssessmentTypeForm() {
    const dialog = this.dialogSrv.open(AssessmentTypeFormComponent, {
      header: 'Create Assessment Type',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs && !!rs.id) {
        this.openAssessmentTypeCreator(rs);
      }
    });
  }

  openAssessmentTypeCreator(assessmentType: any) {
    const dialog = this.dialogSrv.open(AssessmentTypeCreatorComponent, {
      data: { assessmentType: assessmentType },
      closeOnEscape: false,
      showHeader: false,
      modal: false,
      draggable: false,
      resizable: false,
      closable: false,
      styleClass: 'dialog-full-screen',
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.assessmentSrv.refresh$.next(rs);
      }
    });
  }

  openDeleteConfirmation(types: any[] = [], isForce = false) {
    const typeIds = types.map((q) => q.id) || [];
    const typeList = types.map((q) => `<li>${q.name}</li>`) || [];
    const message = !isForce
      ? `Are you sure you want to delete ${
          !types?.length ? 'all types?<br/><br/>' : `the following assessment type(s)?<ul>${typeList.join('')}</ul>`
        }`
      : `Deleting ${types.length > 1 ? 'these' : 'this'} assessment ${
          types.length > 1 ? 'types' : 'type'
        } will also remove all assessments associated with it.<br/><br/>Are you sure you want to proceed?`;

    this.confirm({
      action: !isForce ? DELETE_LABEL : 'Linked Assessment Detected',
      objectName: !isForce ? 'Type(s)' : '',
      customContent: message,
      acceptLabel: !isForce ? 'delete' : 'delete with assessment',
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.assessmentSrv.deleteAssessmentTypes(typeIds, isForce),
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`Deleted assessment type(s) successfully`);
        const typeIds = types.map((type) => type.id) || [];
        if (!!this.selectedAssessmentTypes?.length) {
          this.selectedAssessmentTypes = this.selectedAssessmentTypes.filter((type) => !typeIds?.includes(type.id));
        }
        if (!!this.selectedAssessmentType && !!typeIds.includes(this.selectedAssessmentType.id)) {
          this.selectedAssessmentType = null;
        }
        this.getAssessmentTypes();
      },
      error: (err) => {
        this.isLoading = false;
        if (!isForce && err.status === 400) {
          setTimeout(() => {
            this.openDeleteConfirmation(types, true);
          }, 500);
        } else {
          this.showErrorMessage(err);
        }
      },
    });
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedAssessmentType = null;
  }

  override ngOnDestroy() {
    this.cleanup();
    this.assessmentSrv.refresh$.next(null);
  }
}
