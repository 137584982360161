import { Injectable } from '@angular/core';
import { API } from '@ids-services';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';

import { IAssessmentService } from './IAssessmentService';

const API_ASSESSMENTS = `${API.ASSESSMENT_MANAGER}/assessments`;
const API_ASSESSMENT_TYPES = `${API.ASSESSMENT_MANAGER}/assessment-types`;
const API_QUESTIONS = `${API.ASSESSMENT_MANAGER}/assessment-questions`;
const API_ATTACHMENTS = `${API.ASSESSMENT_MANAGER}/attachments`;

@Injectable({
  providedIn: 'root',
})
export class AssessmentService implements IAssessmentService {
  constructor(private httpClient: HttpClient) {}

  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected: any = null;

  getAssessments(
    organization_id?: any,
    proj_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    search?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (proj_id) {
      params.push(`project_id=${proj_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (!!sort) {
      params.push(`sort=${sort}`);
    }
    if (typeof reverse === 'boolean') {
      params.push(`reverse=${reverse}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }
    return this.httpClient
      .get(`${API_ASSESSMENTS}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessment(id: any): Observable<any> {
    return this.httpClient.get<any>(`${API_ASSESSMENTS}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAssessment(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_ASSESSMENTS}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateAssessment(id: any, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_ASSESSMENTS}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAssessment(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${API_ASSESSMENTS}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentReport(id: any): Observable<any> {
    return this.httpClient.get<any>(`${API_ASSESSMENTS}/${id}/report`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadAssessmentReport(id: any, type: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/octet-stream');
    return this.httpClient
      .get(`${API_ASSESSMENTS}/${id}/report?export=${type}`, {
        headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentSummary(organization_id: any, project_id: any, assessment_type_id?: any[], tag_id?: any[], device_id?: any[]): Observable<any> {
    const params: string[] = [];
    if (assessment_type_id?.length) {
      params.push(`assessment_type_ids=${assessment_type_id.join(',')}`);
    }
    if (tag_id?.length) {
      params.push(`tag_ids=${tag_id.join(',')}`);
    }
    if (device_id?.length) {
      params.push(`device_ids=${device_id.join(',')}`);
    }
    return this.httpClient.get<any>(
      `${API_ASSESSMENTS}/summary?organization_id=${organization_id}&project_ids=${project_id}${!!params.length ? `&${params.join('&')}` : ''}`,
    );
  }

  prefillAssessment(id: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API_ASSESSMENTS}/${id}/prefill`, undefined)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentPrefillingInfo(assessmentTypeId: any, deviceIds: any[]): Observable<any> {
    return this.httpClient
      .get<any>(`${API.ASSESSMENT_MANAGER}/assessment-prefilling-info?assessment_type_id=${assessmentTypeId}&device_ids=${deviceIds.join(',')}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentTypes(
    organization_id?: any,
    proj_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    search?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (proj_id) {
      params.push(`project_id=${proj_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (!!sort) {
      params.push(`sort=${sort}`);
    }
    if (typeof reverse === 'boolean') {
      params.push(`reverse=${reverse}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }
    return this.httpClient
      .get(`${API_ASSESSMENT_TYPES}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentType(id: any): Observable<any> {
    return this.httpClient.get<any>(`${API_ASSESSMENT_TYPES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAssessmentType(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_ASSESSMENT_TYPES}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateAssessmentType(id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${API_ASSESSMENT_TYPES}/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAssessmentType(id: any, force = false): Observable<any> {
    return this.httpClient
      .delete<any>(`${API_ASSESSMENT_TYPES}/${id}?force=${force}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAssessmentTypes(ids: any[], force = false): Observable<any> {
    return this.httpClient
      .delete<any>(`${API_ASSESSMENT_TYPES}?force=${force}`, {
        body: { assessment_type_ids: ids },
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAssessmentTypeSection(assessmentTypeId: any, payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API_ASSESSMENT_TYPES}/${assessmentTypeId}/sections`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateAssessmentTypeSection(assessmentTypeId: any, id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${API_ASSESSMENT_TYPES}/${assessmentTypeId}/sections/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAssessmentTypeSection(assessmentTypeId: any, id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${API_ASSESSMENT_TYPES}/${assessmentTypeId}/sections/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentQuestions(
    organization_id?: any,
    proj_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    search?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (proj_id) {
      params.push(`project_id=${proj_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (!!sort) {
      params.push(`sort=${sort}`);
    }
    if (typeof reverse === 'boolean') {
      params.push(`reverse=${reverse}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }
    return this.httpClient
      .get(`${API_QUESTIONS}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentQuestion(id: any): Observable<any> {
    return this.httpClient.get<any>(`${API_QUESTIONS}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAssessmentQuestion(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_QUESTIONS}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateAssessmentQuestion(id: any, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_QUESTIONS}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAssessmentQuestion(id: any, force = false): Observable<any> {
    return this.httpClient
      .delete<any>(`${API_QUESTIONS}/${id}?force=${force}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAssessmentQuestions(ids: any[], force = false): Observable<any> {
    return this.httpClient
      .delete<any>(`${API_QUESTIONS}?force=${force}`, {
        body: { question_ids: ids },
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentAttachments(): Observable<any> {
    return this.httpClient.get<any>(`${API_ATTACHMENTS}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAssessmentAttachment(id: any): Observable<any> {
    return this.httpClient.get<any>(`${API_ATTACHMENTS}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadAssessmentAttachment(id: any): Observable<any> {
    const options: any = {
      responseType: 'blob',
      observe: 'response',
    };
    return this.httpClient
      .get<any>(`${API_ATTACHMENTS}/download/${id}`, options)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  uploadAssessmentAttachment(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_ATTACHMENTS}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAssessmentAttachment(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${API_ATTACHMENTS}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
