import { Component, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { CANCEL_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { THREAT_TYPE_OPTIONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { ThreatService } from '@ids-services';

import { finalize } from 'rxjs';

export const FORM_PARAMS = {
  PROJECT_ID: 'project_id',
  MIN_THREAT_ACCURACY: 'min_threat_accuracy',
  MIN_THREAT_LIKELIHOOD: 'min_threat_likelihood',
  MIN_THREAT_SCORE: 'min_threat_score',
  MIN_THREAT_IMPACT: 'min_threat_impact',
  THREAT_TYPE_FILTERS: 'threat_type_filters',
};
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('threatTypeFiltersTemplate') threatTypeFiltersTemplate!: TemplateRef<any>;

  @ViewChild('overallScoreTemplate') overallScoreTemplate!: TemplateRef<any>;

  @ViewChild('impactScoreTemplate') impactScoreTemplate!: TemplateRef<any>;

  @ViewChild('likelihoodScoreTemplate') likelihoodScoreTemplate!: TemplateRef<any>;

  @ViewChild('accuracyScoreTemplate') accuracyScoreTemplate!: TemplateRef<any>;

  setting: any = {};

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  CANCEL_LABEL = CANCEL_LABEL;

  THREAT_TYPE_OPTIONS = THREAT_TYPE_OPTIONS;

  constructor(private threatSrv: ThreatService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.getThreatSetting();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Enabled Threat Type',
        labelStyleClass: 'font-bold',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Select the type of threats to be enabled on MicroIDS.',
        labelStyleClass: 'font-normal',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.THREAT_TYPE_FILTERS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.threatTypeFiltersTemplate,
        defaultValue: this.setting?.[FORM_PARAMS.THREAT_TYPE_FILTERS] || [],
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Minimum Threat Score',
        labelStyleClass: 'font-bold mt-3',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Set the minimum threshold score that a threat must meet to be published and displayed as an alert in MicroIDS.',
        labelStyleClass: 'font-normal mb-3',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MIN_THREAT_SCORE,
        hasNoLabel: true,
        field: 'custom',
        customField: this.overallScoreTemplate,
        defaultValue: this.setting?.[FORM_PARAMS.MIN_THREAT_SCORE] || 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MIN_THREAT_IMPACT,
        hasNoLabel: true,
        field: 'custom',
        customField: this.impactScoreTemplate,
        defaultValue: this.setting?.[FORM_PARAMS.MIN_THREAT_IMPACT] || 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MIN_THREAT_LIKELIHOOD,
        hasNoLabel: true,
        field: 'custom',
        customField: this.likelihoodScoreTemplate,
        defaultValue: this.setting?.[FORM_PARAMS.MIN_THREAT_LIKELIHOOD] || 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MIN_THREAT_ACCURACY,
        hasNoLabel: true,
        field: 'custom',
        customField: this.accuracyScoreTemplate,
        defaultValue: this.setting?.[FORM_PARAMS.MIN_THREAT_ACCURACY] || 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
    ];
    this.fields = fields;
  }

  getThreatSetting() {
    this.form.isLoading = true;
    this.threatSrv
      .getThreatSetting(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.initForm();
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.setting = res || {};
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onSubmit() {
    this.form.isLoading = true;
    const payload = {
      ...this.form.getRawValue(),
      [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
    };
    this.threatSrv
      .updateThreatSetting(this.breadcrumbConfig?.projectId, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Updated Threat Setting Successfully');
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }
}
