@if (!isFirstLoaded) {
  <div id="diagram-wrapper" class="relative">
    <div id="diagram" class="h-full">
      <div id="network-map-editor" class="w-full h-full"></div>
    </div>

    <div id="navigator-buttons" class="flex flex-column gap-2 absolute">
      <p-selectButton [options]="modes" [(ngModel)]="mode" optionValue="value" (onOptionClick)="changeMode($event.option?.value)" option>
        <ng-template let-item pTemplate>
          <div [pTooltip]="item.label" tooltipPosition="bottom" appendTo="body">
            <i [class]="item.icon"></i>
          </div>
        </ng-template>
      </p-selectButton>
    </div>

    <div id="zoom-container" class="flex flex-column gap-2 absolute">
      <div
        class="tool-button cursor-pointer border-circle border-2 border-gray-900 p-2"
        pTooltip="Reset Network Layout"
        tooltipPosition="left"
        appendTo="body"
        (click)="resetLayout()">
        <i class="fa fa-clock-rotate-left"></i>
      </div>
      <div
        class="tool-button cursor-pointer border-circle border-2 border-gray-900 p-2"
        pTooltip="Zoom In"
        tooltipPosition="left"
        appendTo="body"
        (click)="zoom('in')">
        <i class="fa fa-magnifying-glass-plus"></i>
      </div>
      <div
        class="tool-button cursor-pointer border-circle border-2 border-gray-900 p-2"
        pTooltip="Zoom Out"
        tooltipPosition="left"
        appendTo="body"
        (click)="zoom('out')">
        <i class="fa fa-magnifying-glass-minus"></i>
      </div>
      <div
        class="tool-button cursor-pointer border-circle border-2 border-gray-900 p-2"
        pTooltip="Zoom to fit"
        tooltipPosition="left"
        appendTo="body"
        (click)="zoom('fit')">
        <i class="fa fa-magnifying-glass-location"></i>
      </div>
    </div>
  </div>
}
