import { Cell, GraphDataModel, ModelXmlSerializer } from '@maxgraph/core';
import { NetworkMapEditor } from './network-map-editor';
import { EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL, EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN, EDITOR_OBJECT_TYPES } from '@ids-constants';
import { NetworkMapHelper } from '@ids-utilities';
import { Util } from '@microsec/utilities';

export const NMEXML = {
  /**
   * Import data by xml
   * @param importedXml
   */
  importXML(this: NetworkMapEditor, importedXml: string, isFirstLoad: boolean = false) {
    const tempGraphModel = new GraphDataModel();
    const xmlSerializer = new ModelXmlSerializer(tempGraphModel);
    let replacedUrlXml = importedXml;
    const imageArr = replacedUrlXml.split(`${EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN}/`).filter((_value, index) => !!index);
    imageArr.forEach((imageItem) => {
      const imageName = imageItem.split('.svg"')?.[0];
      let imageUrl = NetworkMapHelper.getNetworkMapEditorIconUrl(imageName);
      imageUrl = imageUrl.replace(new RegExp(EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL, 'g'), EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN);
      replacedUrlXml = replacedUrlXml.replace(`${EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN}/${imageName}.svg`, imageUrl);
    });
    replacedUrlXml = replacedUrlXml.replace(new RegExp(EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN, 'g'), EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL);
    xmlSerializer.import(replacedUrlXml);

    this.clearGraph();

    const zoneItems = [...((tempGraphModel?.cells?.[`${EDITOR_OBJECT_TYPES.ZONE}s`]?.children as Cell[]) || [])];
    zoneItems.forEach((zoneCell) => {
      const zoneGeo = { width: zoneCell.geometry?.width || 0, height: zoneCell.geometry?.height || 0 };
      const cell = this.graph?.addCell(zoneCell, this.layers.zone);
      if (!!cell?.geometry) {
        cell.geometry.width = zoneGeo.width;
        cell.geometry.height = zoneGeo.height;
      }
      if (!![EDITOR_OBJECT_TYPES.ZONE, EDITOR_OBJECT_TYPES.DEVICE].includes(cell?.value?.type)) {
        NMEXML.importEntitiesIntoParentZone.call(this, zoneCell);
      }
    });
    const deviceItems = [...((tempGraphModel?.cells?.[`${EDITOR_OBJECT_TYPES.DEVICE}s`]?.children as Cell[]) || [])];
    deviceItems.forEach((deviceCell) => {
      this.graph?.addCell(deviceCell, this.layers.device);
      if (!![EDITOR_OBJECT_TYPES.ZONE, EDITOR_OBJECT_TYPES.DEVICE].includes(deviceCell?.value?.type)) {
        this.addImportedDeviceId(deviceCell.value?.data?.device_id);
      }
    });
    this.undoManager?.clear();
    this.zoom('fit');
    if (!!isFirstLoad) {
      this.isSaved = null;
    }
    this.refreshGraph();
  },

  /**
   * Import devices to parent zone
   * @param parentZoneCell
   */
  importEntitiesIntoParentZone(this: NetworkMapEditor, parentZoneCell: Cell) {
    parentZoneCell.children.forEach((cell) => {
      switch (cell?.value?.type) {
        case EDITOR_OBJECT_TYPES.ZONE: {
          NMEXML.importEntitiesIntoParentZone.call(this, cell);
          break;
        }
        case EDITOR_OBJECT_TYPES.DEVICE: {
          this.addImportedDeviceId(cell.value?.data?.device_id);
          break;
        }
        default: {
          break;
        }
      }
    });
  },

  /**
   * Export current diagram
   */
  exportXML(this: NetworkMapEditor) {
    const model = this.graph?.getDataModel() as GraphDataModel;
    const xmlSerializer = new ModelXmlSerializer(model);
    const exportedXml = xmlSerializer.export();
    const replacedUrlXml = exportedXml.replace(new RegExp(EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL, 'g'), EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN);
    Util.downloadFileFromText(replacedUrlXml, `Network_map_diagram_${Util.generateRandomNumberString()}.xml`);
  },
};
