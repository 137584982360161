import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@ids-shared';

import { SharedProjectFormComponent } from './shared-project-form.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedProjectFormComponent],
  exports: [SharedProjectFormComponent],
})
export class SharedProjectFormModule {
  static forRoot(): ModuleWithProviders<SharedProjectFormModule> {
    return {
      ngModule: SharedProjectFormModule,
      providers: [],
    };
  }
}
