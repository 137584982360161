<div class="grid">
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!this.device && !!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button [label]="'Create Firewall'" [rounded]="true" icon="pi pi-plus" [disabled]="!!isLoading" (onClick)="openFirewallForm()"> </p-button>
      <p-button
        [label]="'Sync'"
        severity="secondary"
        [rounded]="true"
        icon="fa-solid fa-spinner"
        [disabled]="!!isLoading"
        (onClick)="syncFirewalls()">
      </p-button>
    </ng-template>
  </div>

  <div class="col-12">
    <microsec-card [isNestedCard]="!!device" [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [(selection)]="selectedFirewalls"
        selectionMode="multiple"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        itemName="Firewall"
        [dataKey]="'id'"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedFirewalls)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedFirewalls.length || !!isLoading"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getDeviceFirewalls()" [isDisabled]="!!isLoading"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Interface -->
    <ng-container *ngSwitchCase="'interface'">
      {{ (rowData[col.field] | constant: 'firewall-interface') || '-' }}
    </ng-container>
    <!-- Interface -->
    <ng-container *ngSwitchCase="'ip_protocol'">
      {{ (rowData[col.field] | constant: 'firewall-ip-protocol') || '-' }}
    </ng-container>
    <!-- Interface -->
    <ng-container *ngSwitchCase="'ip_version'">
      {{ (rowData[col.field] | constant: 'firewall-ip-version') || '-' }}
    </ng-container>
    <!-- Interface -->
    <ng-container *ngSwitchCase="'chain'">
      {{ (rowData[col.field] | constant: 'firewall-chain') || '-' }}
    </ng-container>
    <!-- Interface -->
    <ng-container *ngSwitchCase="'target'">
      {{ (rowData[col.field] | constant: 'firewall-target') || '-' }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<app-shared-test-configuration-dialog #testConfigurationDialog> </app-shared-test-configuration-dialog>
