import { Component, OnInit } from '@angular/core';
import { MODULE_CONSTANTS, ORGANIZATION_ID_PARAM_ROUTE } from '@microsec/constants';
import { OrganizationService } from '@microsec/services';
import { BaseComponent } from '@ids-components';
import { finalize } from 'rxjs/operators';

const FIELDS = {
  ID: 'ID',
  NAME: 'Organizations',
  TOTAL_PROJECTS: 'No. of Projects',
  ADDRESS: 'Address',
  TELEPHONE_NUMBER: 'Phone Number',
};

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  cols: any[] = [
    { field: 'name', header: FIELDS.NAME, width: 15, frozen: true },
    { field: 'total_projects', header: FIELDS.TOTAL_PROJECTS, width: 15 },
  ];

  values: any[] = [];

  constructor(private organizationSrv: OrganizationService) {
    super();
  }

  ngOnInit() {
    this.getOrganizations();
    this.organizationSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getOrganizations();
      }
    });
  }

  /**
   * Get organizations
   */
  getOrganizations() {
    this.isLoading = true;
    this.organizationSrv
      .getOrganizations()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this.values = rs;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Go to organization
   * @param e
   * @param org_id
   */
  goToOrganization(e: MouseEvent, org_id: any) {
    e.preventDefault();
    this.changeRoute(MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, org_id));
  }
}
