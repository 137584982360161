<div class="grid">
  <!-- Create + Search -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button severity="primary" label="Create Zone" [rounded]="true" icon="pi pi-plus" (onClick)="openZoneForm()"> </p-button>
    </ng-template>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-5': !!selectedZone }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [(firstIndex)]="firstIndex"
        [cols]="selectedCols"
        [values]="values"
        [loading]="isLoading"
        selectionMode="multiple"
        [(selection)]="selectedZones"
        [(rowSingleSelection)]="selectedZone"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [dataKey]="'id'"
        itemName="Zone"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-7" *ngIf="!!selectedZone">
    <app-zone-details
      [isLoading]="isLoading"
      [(zone)]="selectedZone"
      (refreshListEvent)="getZones()"
      (openFormEvent)="openZoneForm($event)"
      (hideDetailsEvent)="hideDetailsPanel()" />
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedZones)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedZones.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getZones()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Name -->
    <ng-container *ngSwitchCase="'label'">
      <div class="flex align-items-center w-max" [pTooltip]="rowData[col.field]" tooltipPosition="top" appendTo="body">
        <div class="custom-chip" [style]="{ background: util.hexToRgba(rowData.color), color: rowData.color }">
          {{ rowData[col.field] }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
