<microsec-card>
  <div class="grid">
    <div class="col-12 xl:col-5 lg:col-6 md:col-8 sm:col-12">
      <microsec-form-builder
        #fb
        [isPage]="true"
        [fields]="fields"
        [shouldShowActionButtons]="true"
        [submitButtonLabel]="'Change Password'"
        [submitButtonStyleClass]="'p-button-primary'"
        [cancelButtonLabel]="''"
        (submitEvent)="onSubmit()"
        [rightActionArea]="rightActionArea" />
    </div>
  </div>
</microsec-card>

<ng-template #rightActionArea>
  <label class="link ml-2" (click)="showForgotPasswordInfo()">Forgot Password?</label>
</ng-template>
