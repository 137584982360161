<microsec-card [isDetailCard]="true">
  <p-tabView styleClass="microsec-dashboard-tab">
    <p-tabPanel header="Overview">
      <app-firmware-overview [firmware]="firmware"></app-firmware-overview>
    </p-tabPanel>
    <p-tabPanel header="Assigned Devices">
      <app-firmware-assigned-devices [firmware]="firmware" (refreshFirmwareEvent)="refreshFirmware()"> </app-firmware-assigned-devices>
    </p-tabPanel>
  </p-tabView>
</microsec-card>
