import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API } from '@ids-services';
import { BehaviorSubject, Observable, throwError, scheduled, asyncScheduler } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ITargetDeviceService } from './ITargetDeviceService';

const API_DEVICES = `${API.TARGET_DEVICE_MANAGER}/devices`;

const API_DATA = `${API.TARGET_DEVICE_MANAGER}/data`;

const API_MERGE = `${API.TARGET_DEVICE_MANAGER}/merge`;

const API_TAGS = `${API.TARGET_DEVICE_MANAGER}/tags`;

const API_ZONES = `${API.TARGET_DEVICE_MANAGER}/zones`;

const API_NETWORK_MAP = `${API.TARGET_DEVICE_MANAGER}/network-map`;

const API_ATTACHMENTS = `${API.TARGET_DEVICE_MANAGER}/attachments`;

@Injectable({
  providedIn: 'root',
})
export class TargetDeviceService implements ITargetDeviceService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  selected: any = null;

  constructor(private httpClient: HttpClient) {}

  createDevice(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_DEVICES}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDevices(config: {
    organizationId?: any;
    projectId?: any;
    page?: number | null;
    perPage?: number | null;
    sort?: string;
    reverse?: boolean;
    detailed?: boolean;
    search?: string;
    usesAgent?: boolean;
    isImported?: boolean;
    connectionIds?: number[];
    interfaceTypes?: string[];
    criticality?: number[];
    createdFrom?: string;
    createdTo?: string;
    lastSeenFrom?: string;
    lastSeenTo?: string;
    tagIds?: number[];
    zoneIds?: number[];
    isMulticast?: string;
    activeScan?: string;
  }): Observable<any> {
    const params: string[] = [];
    if (config.organizationId) {
      params.push(`organization_id=${config.organizationId}`);
    }
    if (config.projectId) {
      params.push(`proj_id=${config.projectId}`);
    }
    if (config.page) {
      params.push(`page=${config.page}`);
    }
    if (config.perPage) {
      params.push(`per_page=${config.perPage}`);
    }
    params.push(`sort=${config.sort || 'id'}`);
    params.push(`reverse=${typeof config.reverse === 'boolean' ? config.reverse : 'true'}`);
    if (typeof config.detailed === 'boolean') {
      params.push(`detailed=${config.detailed}`);
    }
    if (config.search) {
      params.push(`search=${config.search}`);
    }
    if (typeof config.usesAgent === 'boolean') {
      params.push(`uses_agent=${config.usesAgent}`);
    }
    if (typeof config.isImported === 'boolean') {
      params.push(`is_imported=${config.isImported}`);
    }
    if (!!config.connectionIds?.length) {
      params.push(`connection_ids=[${config.connectionIds.join(',')}]`);
    }
    if (!!config.interfaceTypes?.length) {
      params.push(`interface_types=${JSON.stringify(config.interfaceTypes)}`);
    }
    if (!!config.criticality?.length) {
      params.push(`criticality=[${config.criticality.join(',')}]`);
    }
    if (config.createdFrom) {
      params.push(`created_from=${config.createdFrom}`);
    }
    if (config.createdTo) {
      params.push(`created_to=${config.createdTo}`);
    }
    if (config.lastSeenFrom) {
      params.push(`last_seen_from=${config.lastSeenFrom}`);
    }
    if (config.lastSeenTo) {
      params.push(`last_seen_to=${config.lastSeenTo}`);
    }
    if (!!config.tagIds?.length) {
      params.push(`tag_ids=[${config.tagIds.join(',')}]`);
    }
    if (!!config.zoneIds?.length) {
      params.push(`zone_ids=[${config.zoneIds.join(',')}]`);
    }
    params.push(`is_multicast=${config.isMulticast || 'any'}`);
    if (!!config.activeScan) {
      params.push(`active-scan=${config.activeScan}`);
    }
    return this.httpClient
      .get(`${API_DEVICES}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDevice(id: number): Observable<any> {
    return this.httpClient.get<any>(`${API_DEVICES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateDevice(id: number, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_DEVICES}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteDevice(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${API_DEVICES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDeviceMetrics(id: any, granularity: string, from?: string, to?: string): Observable<any> {
    let params = `?granularity=${granularity}`;
    if (!!from) {
      params += `&from=${from}`;
    }
    if (!!to) {
      params += `&to=${to}`;
    }
    return this.httpClient.get<any>(`${API_DEVICES}/${id}/metrics${params}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDeviceSummary(organization_id: any, proj_id: any): Observable<any> {
    return this.httpClient
      .get(`${API_DEVICES}/summary?organization_id=${organization_id}&proj_id=${proj_id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getLinks(protocol?: string): Observable<any> {
    return this.httpClient
      .get(`${API.TARGET_DEVICE_MANAGER}/links${!!protocol ? `?protocol=${protocol}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDeviceConfiguration(id: any): Observable<any> {
    return this.httpClient.get(`${API_DEVICES}/${id}/configuration`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateDeviceConfiguration(id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch(`${API_DEVICES}/${id}/configuration`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDevicesMergeCandidates(project_id: any): Observable<any> {
    return this.httpClient.get(`${API_MERGE}/candidates/${project_id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  mergeDevices(project_id: any, payload: any): Observable<any> {
    return this.httpClient.post(`${API_MERGE}/${project_id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getManufacturers(): Observable<any> {
    return this.httpClient.get(`${API_DATA}/vendors`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getModels(manufacturerName: any, productName: any): Observable<any> {
    return this.httpClient
      .get(`${API_DATA}/products?vendor=${manufacturerName}&product=${productName}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getDeviceInfo(manufacturerName: any, productName: any): Observable<any> {
    return this.httpClient.get(`${API_DATA}/device_info?vendor=${manufacturerName}&product=${productName}`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return scheduled([null], asyncScheduler);
        }
        return throwError(() => error);
      }),
    );
  }

  createTag(payload: any): Observable<any> {
    return this.httpClient.post(`${API_TAGS}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getTags(organization_id?: any, project_id?: any): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (project_id) {
      params.push(`project_id=${project_id}`);
    }
    return this.httpClient
      .get(`${API_TAGS}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateTag(id: number, payload: any): Observable<any> {
    return this.httpClient.patch(`${API_TAGS}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteTag(id: number): Observable<any> {
    return this.httpClient.delete(`${API_TAGS}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createZone(payload: any): Observable<any> {
    return this.httpClient.post(`${API_ZONES}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getZones(organization_id?: any, project_id?: any): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (project_id) {
      params.push(`project_id=${project_id}`);
    }
    return this.httpClient
      .get(`${API_ZONES}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getZone(id: number): Observable<any> {
    return this.httpClient.get(`${API_ZONES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateZone(id: number, payload: any): Observable<any> {
    return this.httpClient.patch(`${API_ZONES}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteZone(id: number): Observable<any> {
    return this.httpClient.delete(`${API_ZONES}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAttachment(payload: any): Observable<any> {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]: [string, any]) => {
      formData.append(key, value);
    });
    return this.httpClient.post(API_ATTACHMENTS, formData).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAttachment(attachmentId?: any): Observable<any> {
    const options: any = {
      responseType: 'blob',
      observe: 'response',
    };
    return this.httpClient.get(`${API_ATTACHMENTS}/${attachmentId}`, options).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAttachment(attachmentId: any): Observable<any> {
    return this.httpClient.delete(`${API_ATTACHMENTS}/${attachmentId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getNetworkMap(organizationId: any, projectId: any): Observable<any> {
    return this.httpClient
      .get(`${API_NETWORK_MAP}/manual/state?organization_id=${organizationId}&project_id=${projectId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateNetworkMap(payload: any): Observable<any> {
    return this.httpClient.put(`${API_NETWORK_MAP}/manual/state`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  sendDeviceCommand(id: number, payload: any): Observable<any> {
    return this.httpClient.post(`${API_DEVICES}/${id}/command`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
