/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import { Store } from '@ngrx/store';
import { Util } from '@microsec/utilities';
import { asyncScheduler, first, Observable, scheduled, switchMap } from 'rxjs';
import { ISetting2FAService } from './ISetting2FAService';
import globalSettingData from './mock-data/global-setting.json';
import rsaSettingData from './mock-data/rsa-setting.json';
import rsaTestData from './mock-data/rsa-test.json';
import userSettingData from './mock-data/user-setting.json';

@Injectable({
  providedIn: 'root',
})
export class MockSetting2FAService implements ISetting2FAService {
  constructor(private store: Store) {}
  getRSASetting(): Observable<any> {
    return scheduled([rsaSettingData], asyncScheduler);
  }

  updateRSASetting(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  testRSAConnection(payload: any): Observable<any> {
    return scheduled([rsaTestData], asyncScheduler);
  }

  getOpenGlobalSetting(): Observable<any> {
    return this.store.select(authenticationSelectors.temporaryCredential).pipe(
      first(),
      switchMap((credential: { username: string; password: string }) => {
        const clonedSetting = Util.cloneDeepObject(globalSettingData);
        if (credential.username === '2fa') {
          clonedSetting.auth_provider = 'google_auth';
        }
        return scheduled([clonedSetting], asyncScheduler);
      }),
    );
  }

  getGlobalSetting(): Observable<any> {
    return scheduled([globalSettingData], asyncScheduler);
  }

  updateGlobalSetting(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getOpenUserSetting(username: any, password: any): Observable<any> {
    return this.store.select(authenticationSelectors.temporaryCredential).pipe(
      first(),
      switchMap((credential: { username: string; password: string }) => {
        const clonedSetting = Util.cloneDeepObject(userSettingData);
        if (credential.username === '2fa') {
          clonedSetting.status = true;
        }
        return scheduled([clonedSetting], asyncScheduler);
      }),
    );
  }

  getUserSetting(): Observable<any> {
    return scheduled([userSettingData], asyncScheduler);
  }

  updateUserSetting(payload: any): Observable<any> {
    return scheduled([userSettingData], asyncScheduler);
  }
}
