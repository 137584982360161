import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NETWORK_MAP_MODES, NETWORK_MAP_MODE_VALUES, PROJECT_MANAGEMENT_CONSTANTS } from '@ids-constants';
import { BaseComponent } from '@ids-components';

import { BehaviorSubject, Observable } from 'rxjs';
import { ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';

@Component({
  selector: 'app-network-map-buttons',
  templateUrl: './network-map-buttons.component.html',
  styleUrls: ['./network-map-buttons.component.scss'],
})
export class NetworkMapButtonsComponent extends BaseComponent implements OnInit {
  @Input() settingOptions$ = new BehaviorSubject<any>(null);

  @Input() diagramDataObs = new Observable<any>();

  @Input() isItemListDisplayed = false;

  @Input() isItemDetailsDisplayed = false;

  @Input() mode = NETWORK_MAP_MODE_VALUES.PURDUE_MODEL;

  @Input() purdueSettingOptions = null;

  @Output() purdueSettingOptionsChange = new EventEmitter<any>();

  modes = this.util.cloneObjectArray(NETWORK_MAP_MODES);

  @Output() modeChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() settings: any[] = [];

  @Output() settingsChange = new EventEmitter<any>();

  legends: any[] = [];

  isSettingsDisplayed = false;

  NETWORK_MAP_MODE_VALUES = NETWORK_MAP_MODE_VALUES;

  ngOnInit(): void {
    // Update legend chart
    this.diagramDataObs.subscribe((data) => {
      if (!!data?.legends) {
        this.legends = data?.legends || [];
      }
    });
    // Add event listeners
    document.addEventListener('click', (event: any) => {
      const settingButtonElement = document.getElementById('network-map-settings-button');
      const menuElement = document.getElementById('network-map-settings-menu');
      if (!menuElement?.contains(event?.target) && !settingButtonElement?.contains(event?.target)) {
        this.isSettingsDisplayed = false;
      }
    });
  }

  async changeMode(mode: any) {
    await this.prepareConfigs();
    if (mode !== NETWORK_MAP_MODE_VALUES.EDITOR) {
      this.modeChange.emit(mode);
    } else {
      this.changeRoute(
        `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
          `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
          `${PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP_EDITOR.ROUTE}`,
      );
    }
  }

  changeSetting(mode: string, settingKey: any, item: any, isChecked: boolean) {
    item.checked = isChecked;
    localStorage.setItem(`network_map_${item?.value}`, item?.checked);

    // Modify the settings and update parent component settings
    const settingObj: any = item;
    const setting: any = ((this.settings as any[]) || [])?.find((p) => p.key === settingKey);
    const settingItem = ((setting?.items as any[]) || [])?.find((p) => p.value === settingObj.value);
    if (!!settingItem) {
      settingItem.checked = settingObj.checked;
    }
    this.settingsChange.emit(this.settings);

    setTimeout(() => {
      this.settingOptions$.next({ key: settingKey, ...item });
    });
  }

  getSettingChecked(settingName: string) {
    return !!this.settings.find((setting) => !!((setting?.items as any[]) || []).find((item) => item.value === settingName && !!item.checked));
  }
}
