<div id="network-map-editor-wrapper" class="flex flex-column overflow-hidden relative select-none">
  @if (!!editor?.isLoading) {
    <!-- LOADING -->
    <app-nme-loader [editor]="editor" />
  }

  <!-- TOP NAVIGATION -->
  <div class="w-full flex-grow-0 z-2">
    <app-nme-top-navigation #topNav [editor]="editor" />
  </div>
  <div class="flex w-full flex-grow-1 z-1">
    <div class="flex flex-grow-1">
      <!-- LEFT NAVIGATION -->
      <div id="left-navigation" class="flex-grow-0 border-right-1 border-800">
        <app-nme-left-navigation [editor]="editor" />
      </div>
      <!-- DIAGRAM -->
      <div id="main-diagram">
        <app-nme-diagram #diagram [(editor)]="editor" />
      </div>
      <!-- RIGHT NAVIGATION -->
      <div id="right-navigation" class="flex-grow-0 border-left-1 border-800" *ngIf="!!editor?.graph">
        <app-nme-right-navigation [editor]="editor" />
      </div>
    </div>
  </div>
</div>
