import { GraphView } from '@maxgraph/core';
import { NetworkMapEditor } from './network-map-editor';
export const NMEGrid = {
  /**
   * Create resizable grids
   * @param this
   */
  createResizableGrids(this: NetworkMapEditor) {
    if (!!this.graph) {
      this.graph.gridSize = 50;
      GraphView.prototype.validateBackground = () => {
        try {
          NMEGrid.repaintGrids.call(this);
        } catch (e) {
          this.graphElement.style.backgroundImage = "url('assets/images/network-map-editor/nme-grid.png')";
        }
      };
    }
  },
  /**
   * Repaint grid
   * @param this
   * @param isForced
   */
  repaintGrids(this: NetworkMapEditor, isForced = false) {
    const graph = this.graph;
    const ctx = this.graphCanvas.getContext('2d');
    if (!!graph && ctx !== null) {
      const bounds = graph.getGraphBounds();
      const width = Math.max(bounds.x + bounds.width, graph.container.clientWidth);
      const height = Math.max(bounds.y + bounds.height, graph.container.clientHeight);
      const sizeChanged = width != this.gridConfig.width || height != this.gridConfig.height;
      if (
        graph.view.scale != this.gridConfig.scale ||
        graph.view.translate.x != this.gridConfig.translate.x ||
        graph.view.translate.y != this.gridConfig.translate.y ||
        graph.gridSize != this.gridConfig.gridSize ||
        !!sizeChanged ||
        !!isForced
      ) {
        this.gridConfig = {
          translate: graph.view.translate.clone(),
          scale: graph.view.scale,
          gridSize: graph.gridSize,
          width: width,
          height: height,
        };

        // Clears the background if required
        if (!sizeChanged) {
          ctx.clearRect(0, 0, this.gridConfig.width, this.gridConfig.height);
        } else {
          this.graphCanvas.setAttribute('width', `${this.gridConfig.width}`);
          this.graphCanvas.setAttribute('height', `${this.gridConfig.height}`);
        }
        // Draw sub-grid
        NMEGrid.drawGrids.call(this, ctx, graph.gridSize / 5, !!graph.gridEnabled ? '#222222' : 'transparent');
        // Draw container grid
        NMEGrid.drawGrids.call(this, ctx, graph.gridSize, !!graph.gridEnabled ? '#444444' : 'transparent');
      }
    }
  },
  /**
   * Draw Grid
   * @param this
   * @param ctx
   * @param gridSize
   * @param strokeColor
   */
  drawGrids(this: NetworkMapEditor, ctx: CanvasRenderingContext2D, gridSize: number, strokeColor: string) {
    const tx = this.gridConfig.translate.x * this.gridConfig.scale;
    const ty = this.gridConfig.translate.y * this.gridConfig.scale;

    // Sets the distance of the grid lines in pixels
    const minStepping = gridSize;
    let step = minStepping * this.gridConfig.scale;
    if (step < minStepping) {
      const count = Math.round(Math.ceil(minStepping / step) / 2) * 2;
      step = count * step;
    }
    const xs = Math.floor((0 - tx) / step) * step + tx;
    let xe = Math.ceil(this.gridConfig.width / step) * step;
    const ys = Math.floor((0 - ty) / step) * step + ty;
    let ye = Math.ceil(this.gridConfig.height / step) * step;
    xe += Math.ceil(step);
    ye += Math.ceil(step);
    const ixs = Math.round(xs);
    const ixe = Math.round(xe);
    const iys = Math.round(ys);
    const iye = Math.round(ye);

    ctx.strokeStyle = strokeColor;
    // Draws the box grid horizontally
    ctx.beginPath();
    for (let x = xs; x <= xe; x += step) {
      x = Math.round((x - tx) / step) * step + tx;
      const ix = Math.round(x);
      ctx.moveTo(ix + 0.5, iys + 0.5);
      ctx.lineTo(ix + 0.5, iye + 0.5);
      ctx.moveTo(ix + 0.5, iys + iye / 2 + 0.5);
      ctx.lineTo(ix + 0.5, iye + iye / 2 + 0.5);
    }
    // Draws the box grid vertically
    for (let y = ys; y <= ye; y += step) {
      y = Math.round((y - ty) / step) * step + ty;
      const iy = Math.round(y);
      ctx.moveTo(ixs + 0.5, iy + 0.5);
      ctx.lineTo(ixe + 0.5, iy + 0.5);
    }
    ctx.closePath();
    ctx.stroke();
  },
};
