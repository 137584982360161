<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!analyzer) {
    <microsec-selected-panel-header
      [id]="analyzer.id"
      [label]="analyzer.name"
      (closeSelectedPanelEvent)="hidePanelEvent.emit()"
      [rightTemplate]="panelHeaderRightTemplate"></microsec-selected-panel-header>
    <microsec-card [isDetailCard]="true">
      <p-tabView styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <app-ml-analyzer-overview [analyzer]="analyzer"></app-ml-analyzer-overview>
        </p-tabPanel>
        <p-tabPanel header="Models">
          <app-ml-analyzer-models [analyzer]="analyzer"></app-ml-analyzer-models>
        </p-tabPanel>
      </p-tabView>
    </microsec-card>
  }
</microsec-loading-overlay>
