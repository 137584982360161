import { Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { ANALYZER_RULE_DOWNLOAD_FREQUENCY_OPTIONS, ANALYZER_RULE_TYPE_OPTIONS, ANALYZER_TYPES } from '@ids-constants';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService } from '@ids-services';
import { ActionMenuItem } from '@microsec/models';

import { Observable, finalize } from 'rxjs';

import { IpCustomRuleFormComponent } from './ip-custom-rule-form/ip-custom-rule-form.component';
import { IpSuppressedRuleFormComponent } from './ip-suppressed-rule-form/ip-suppressed-rule-form.component';
import { IpWhitelistFormComponent } from './ip-whitelist-form/ip-whitelist-form.component';

@Component({
  selector: 'app-rule-based-configuration-field',
  templateUrl: './rule-based-configuration-field.component.html',
  styleUrls: ['./rule-based-configuration-field.component.scss'],
})
export class RuleBasedConfigurationFieldComponent extends BaseComponent implements OnInit {
  _analyzer = null;

  get analyzer() {
    return this._analyzer;
  }

  @Input() set analyzer(value: any) {
    this._analyzer = value;
    if (!!this._analyzer) {
      this.onChangeSelectedRulesetType();
      this.getAnalyzerCustomRules();
      this.getAnalyzerSuppressedRules();
      this.getAnalyzerWhitelists();
    }
  }

  @Output() setLoadingEvent: EventEmitter<any> = new EventEmitter<any>();

  customRuleRows = PER_PAGE;

  customRuleFirstIndex = 0;

  customRules: any[] = [];

  suppressedRules: any[] = [];

  whitelists: any[] = [];

  rulesets: any[] = [];

  selectedCustomRule: any = null;

  rulesetTypeOptions = ANALYZER_RULE_TYPE_OPTIONS.filter((ruleType) => ruleType.value === 'snort_talos');

  selectedRulesetType = 'snort_talos';

  selectedRulesetTypeEnabled = false;

  rulesetCols = [
    { field: 'enabled', header: 'Status', width: 10 },
    { field: 'name', header: 'Ruleset', width: 15 },
    { field: 'description', header: 'Description', width: 15 },
  ];

  customRuleCols = [
    { field: 'enabled', header: 'Status', width: 10 },
    { field: 'name', header: 'Name', width: 15 },
    { field: 'description', header: 'Description', width: 15 },
  ];

  suppressedRuleCols = [
    { field: 'enabled', header: 'Status', width: 10 },
    { field: 'rule_gid', header: 'Rule GID', width: 15 },
    { field: 'rule_sid', header: 'Rule SID', width: 15 },
    { field: 'comment', header: 'Comment', width: 15 },
  ];

  whitelistCols = [
    { field: 'enabled', header: 'Status', width: 10 },
    { field: 'ip', header: 'IP', width: 15 },
    { field: 'comment', header: 'Comment', width: 15 },
  ];

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_RULE_DOWNLOAD_FREQUENCY_OPTIONS = ANALYZER_RULE_DOWNLOAD_FREQUENCY_OPTIONS;

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(public anomalyAnalyzerSrv: AnomalyAnalyzerService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openForm(rowData['type'], rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation(rowData['type'], rowData),
      },
    ];
  }

  getAnalyzerCustomRules() {
    this.setLoadingEvent.emit(true);
    this.anomalyAnalyzerSrv
      .getAnalyzerCustomRules(this.analyzer?.id)
      .pipe(
        finalize(() => {
          this.setLoadingEvent.emit(false);
          this.anomalyAnalyzerSrv.selected = { ...this.anomalyAnalyzerSrv.selected, customRuleId: null };
        }),
      )
      .subscribe({
        next: (rs) => {
          this.customRules = ((rs as any[]) || [])?.map((v) => ({ ...v, type: 'custom-rule' }));
          if (!!this.anomalyAnalyzerSrv.selected?.isSnort) {
            const customRuleIndex = this.customRules.findIndex((rule) => rule.id === this.anomalyAnalyzerSrv.selected?.customRuleId);
            this.selectedCustomRule = customRuleIndex >= 0 ? this.customRules[customRuleIndex] : null;
            this.customRuleFirstIndex = customRuleIndex >= 0 ? (Math.ceil((customRuleIndex + 1) / this.customRuleRows) - 1) * 10 : 0;
            setTimeout(() => {
              this.scrollIntoCustomRule(this.customRuleFirstIndex);
              this.anomalyAnalyzerSrv.selected = { ...this.anomalyAnalyzerSrv.selected, isSnort: false };
            });
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  getAnalyzerSuppressedRules() {
    this.setLoadingEvent.emit(true);
    this.anomalyAnalyzerSrv
      .getAnalyzerSuppressedRules(this.analyzer?.id)
      .pipe(
        finalize(() => {
          this.setLoadingEvent.emit(false);
        }),
      )
      .subscribe({
        next: (rs) => {
          this.suppressedRules = ((rs as any[]) || [])?.map((v) => ({ ...v, type: 'suppressed-rule' }));
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  getAnalyzerWhitelists() {
    this.setLoadingEvent.emit(true);
    this.anomalyAnalyzerSrv
      .getAnalyzerWhitelists(this.analyzer?.id)
      .pipe(
        finalize(() => {
          this.setLoadingEvent.emit(false);
        }),
      )
      .subscribe({
        next: (rs) => {
          this.whitelists = ((rs as any[]) || [])?.map((v) => ({ ...v, type: 'whitelist' }));
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  scrollIntoCustomRule(id: any) {
    const selector = 'custom-rule_' + id;
    if (document.getElementById(selector)) {
      document.getElementById('custom-rule')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      return;
    }
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(function (mutation) {
        const nodes = Array.from(mutation.addedNodes);
        for (const node of nodes) {
          if (node.contains(document.getElementById(selector))) {
            document.getElementById('custom-rule')?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
            observer.disconnect();
            return;
          }
        }
      });
    });
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  }

  updateAnalyzer(key: string, newValue: any = null) {
    this.setLoadingEvent.emit(true);
    const payload = {
      [key]: newValue || this.analyzer?.[key] || undefined,
    };
    this.anomalyAnalyzerSrv
      .updateAnalyzer(this.analyzer?.id, payload)
      .pipe(
        finalize(() => {
          this.anomalyAnalyzerSrv.refresh$.next(true);
          this.setLoadingEvent.emit(false);
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Analyzer Updated Successfully');
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  updateEnabled(data: any, type?: 'custom-rule' | 'suppressed-rule' | 'whitelist') {
    this.setLoadingEvent.emit(true);
    const payload = {
      enabled: data.enabled,
    };
    let request: Observable<any>;
    let title: string;
    let onRefetch: () => void;
    if (!!type) {
      switch (type) {
        case 'custom-rule': {
          request = this.anomalyAnalyzerSrv.updateAnalyzerCustomRule(this.analyzer?.id, data.id, payload);
          title = 'Custom Rule';
          onRefetch = () => this.getAnalyzerCustomRules();
          break;
        }
        case 'suppressed-rule': {
          request = this.anomalyAnalyzerSrv.updateAnalyzerSuppressedRule(this.analyzer?.id, data.id, payload);
          title = 'Suppressed Rule';
          onRefetch = () => this.getAnalyzerSuppressedRules();
          break;
        }
        default: {
          request = this.anomalyAnalyzerSrv.updateAnalyzerWhitelist(this.analyzer?.id, data.id, payload);
          title = 'IP Whitelist';
          onRefetch = () => this.getAnalyzerWhitelists();
          break;
        }
      }
      request
        .pipe(
          finalize(() => {
            onRefetch();
            this.setLoadingEvent.emit(false);
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage(`${title} Updated Successfully`);
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    } else {
      const rulesets: number[] = (this.rulesets || []).filter((ruleset) => !!ruleset.enabled).map((ruleset) => +ruleset.id);
      this.updateAnalyzer('rule_types', [
        {
          type: this.selectedRulesetType,
          enabled: this.selectedRulesetTypeEnabled,
          rulesets: rulesets,
        },
      ]);
    }
  }

  openForm(type: 'custom-rule' | 'suppressed-rule' | 'whitelist', data?: any) {
    let onClose: () => void;
    let form: Type<any>;
    let title: string;
    switch (type) {
      case 'custom-rule': {
        onClose = () => this.getAnalyzerCustomRules();
        form = IpCustomRuleFormComponent;
        title = 'Custom Rule';
        break;
      }
      case 'suppressed-rule': {
        onClose = () => this.getAnalyzerSuppressedRules();
        form = IpSuppressedRuleFormComponent;
        title = 'Suppressed Rule';
        break;
      }
      default: {
        onClose = () => this.getAnalyzerWhitelists();
        form = IpWhitelistFormComponent;
        title = 'IP Whitelist';
        break;
      }
    }
    const dialog = this.dialogSrv.open(form, {
      data: !data ? { analyzerId: this.analyzer?.id } : { analyzerId: this.analyzer?.id, data: data },
      header: `${!data ? 'Create' : 'Edit'} ${title}`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        onClose();
      }
    });
  }

  openDeleteConfirmation(type: 'custom-rule' | 'suppressed-rule' | 'whitelist', data?: any) {
    let request: Observable<any>;
    let title: string;
    let onRefetch: () => void;
    switch (type) {
      case 'custom-rule': {
        request = this.anomalyAnalyzerSrv.deleteAnalyzerCustomRule(this.analyzer?.id, [data.id]);
        title = 'Custom Rule';
        onRefetch = () => this.getAnalyzerCustomRules();
        break;
      }
      case 'suppressed-rule': {
        request = this.anomalyAnalyzerSrv.deleteAnalyzerSuppressedRule(this.analyzer?.id, [data.id]);
        title = 'Suppressed Rule';
        onRefetch = () => this.getAnalyzerSuppressedRules();
        break;
      }
      default: {
        request = this.anomalyAnalyzerSrv.deleteAnalyzerWhitelist(this.analyzer?.id, [data.id]);
        title = 'IP Whitelist';
        onRefetch = () => this.getAnalyzerWhitelists();
        break;
      }
    }
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Analyzer',
      object: data,
      objectFieldNameFunction: (object) => `${title} ID ${object.id} ${!!object.name ? ` : ${object.name}` : ''}`,
      acceptRequest: request,
      next: () => {
        this.showSuccessMessage(`Deleted ${title} ${data.id} successfully`);
        onRefetch();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  onChangeSelectedRulesetType() {
    const ruleType = ((this.analyzer?.rule_types as any[]) || [])?.find((ruleType) => ruleType.type === this.selectedRulesetType);
    this.selectedRulesetTypeEnabled = !!ruleType?.enabled;
    this.rulesets = ruleType?.rulesets || [];
  }
}
