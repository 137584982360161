import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export const FORM_PARAMS = {
  NAME: 'name',
  ENABLED: 'enabled',
  DESCRIPTION: 'description',
  RULE: 'rule',
  RULE_SYNTAX: 'rule_syntax',
  RULE_PROTOCOLS: 'rule_protocols',
  ID: 'id',
};

@Component({
  selector: 'app-nonip-custom-rule-form',
  templateUrl: './nonip-custom-rule-form.component.html',
  styleUrls: ['./nonip-custom-rule-form.component.scss'],
})
export class NonipCustomRuleFormComponent extends BaseComponent implements AfterViewInit {
  analyzerId: any = null;

  rule: any = null;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('criticalityField') criticalityField!: TemplateRef<any>;

  constructor(
    public dialogConfig: DynamicDialogConfig,
    public cd: ChangeDetectorRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.analyzerId = this.dialogConfig?.data?.analyzerId;
    this.rule = this.dialogConfig?.data?.data;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: true,
        focused: true,
        fieldInfo: 'Name of the rule',
        defaultValue: this.rule?.[FORM_PARAMS.NAME] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the rule',
        defaultValue: this.rule?.[FORM_PARAMS.DESCRIPTION] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        defaultValue: this.rule?.[FORM_PARAMS.ENABLED] ?? true,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Define Custom Rule',
        labelStyleClass: 'font-bold',
        field: 'label',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE,
        label: 'Excel',
        required: true,
        field: 'codemirror',
        codeMirrorShowLanguage: false,
        // eslint-disable-next-line no-useless-escape
        pattern: /^=[A-Za-z]+\([^\)]*[A-Za-z0-9]\.[^\)]*\)$/, // pattern i.e. xxx.xxx and excel formula
        patternErrorText: 'Please correct the syntax to be excel formula and xxx.xxx example. =IF(goose.appid=0)',
        defaultValue: this.rule?.[FORM_PARAMS.RULE] || '',
      } as FormItem),
      // ------------- Hidden value -------------
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ID,
        defaultValue: this.rule?.[FORM_PARAMS.ID] || null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_SYNTAX,
        field: 'input',
        defaultValue: 'excel',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE_PROTOCOLS,
        defaultValue: [],
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    this.cd.detectChanges();
  }

  onSubmit(closeDialog: (rs: any) => void) {
    const payload = this.form.getRawValue();
    closeDialog(payload);
  }
}
