export const FIRMWARE_DEVICE_STATUS_VALUES = {
  UP_TO_DATE: 'uptodate',
  IN_PROGRESS: 'inprogress',
  SCHEDULED: 'scheduled',
  NORMAL: 'normal',
  FAILED: 'failed',
};

export const FIRMWARE_DEVICE_STATUSES = [
  { value: FIRMWARE_DEVICE_STATUS_VALUES.UP_TO_DATE, label: 'Up-to-date' },
  {
    value: FIRMWARE_DEVICE_STATUS_VALUES.IN_PROGRESS,
    label: 'Update In Progress',
  },
  { value: FIRMWARE_DEVICE_STATUS_VALUES.SCHEDULED, label: 'Update Scheduled' },
  { value: FIRMWARE_DEVICE_STATUS_VALUES.NORMAL, label: 'Normal' },
  { value: FIRMWARE_DEVICE_STATUS_VALUES.FAILED, label: 'Failed' },
];

export const FIRMWARE_TARGET_DEVICE_VALUES = {
  ESP32: 'esp32',
  ESP8266: 'esp8266',
  RPI: 'rpi',
  HIKVISION_CCTV: 'hikvision_cctv',
};

export const FIRMWARE_TARGET_DEVICES = [
  { value: FIRMWARE_TARGET_DEVICE_VALUES.ESP32, label: 'ESP32' },
  { value: FIRMWARE_TARGET_DEVICE_VALUES.ESP8266, label: 'ESP8266' },
  { value: FIRMWARE_TARGET_DEVICE_VALUES.RPI, label: 'Raspberry Pi (via rauc)' },
  { value: FIRMWARE_TARGET_DEVICE_VALUES.HIKVISION_CCTV, label: 'Hikvision CCTV' },
];
