import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { INTERFACE_TYPE_OPTIONS, QUOTAS_FEATURES } from '@ids-constants';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { ConnectionService, HybridMonitorService, TargetDeviceService } from '@ids-services';
import { ConstantPipe, DetectedDeviceInterfacesPipe, MomentPipe } from '@ids-pipes';
import { TitleCasePipe } from '@angular/common';
import { CommonTableComponent } from '@microsec/components';
import { ReportSpecification } from '@microsec/models';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { MULTICAST_STORAGE_NAME } from '@ids-constants';
import { ActionMenuItem } from '@microsec/models';

import { BehaviorSubject, Observable, finalize, forkJoin } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import moment from 'moment';

import { MergeDevicesFormComponent } from './merge-devices-form/merge-devices-form.component';
import { DeviceFormComponent } from './device-form/device-form.component';
import { SharedActiveScanFormComponent } from '../../../../shared-components/shared-active-scan-form/shared-active-scan-form.component';
import { OverlayPanel } from 'primeng/overlaypanel';

const FIELDS = {
  id: 'ID',
  label: 'Name',
  type: 'Type',
  interfaces: 'Interfaces',
  src_mac_addr: 'MAC Address',
  src_ip_addr: 'IP Addresses',
  src_ble_addr: 'BLE Address',
  application_protocols: 'Application Protocols',
  connection_ids: 'Connection IDs',
  created: 'Created Date',
  criticality: 'Criticality',
  eth: 'ETH (Source/Dest MAC)',
  ip: 'IP (Source/Dest IP)',
  udp: 'UDP (Source/Dest Port)',
  first_seen: 'First Seen',
  is_imported: 'Imported',
  last_seen: 'Last Seen',
  manufacturer: 'Manufacturer',
  model: 'Model',
  network_map_level: 'Network Map Level',
  organization_id: 'Organization ID',
  os: 'OS',
  project_id: 'Project ID',
  status: 'Status',
  threat_score: 'Threat Score',
  uses_agent: 'Uses Agent',
  osgen: 'OS Gen',
  osname: 'OS Name',
  cpe: 'CPE',
  lastboot: 'Last Boot',
  vendor: 'Vendor',
  tags: 'Tags',
  zones: 'Zones',
  multicast: 'Multicast Devices',
};

const COLS = [
  { field: 'label', header: FIELDS.label, width: 20 },
  { field: 'type', header: FIELDS.type, width: 15 },
  { field: 'interfaces', header: FIELDS.interfaces, width: 15 },
  { field: 'src_mac_addr', header: FIELDS.src_mac_addr, width: 15 },
  { field: 'src_ip_addr', header: FIELDS.src_ip_addr, width: 15 },
  { field: 'src_ble_addr', header: FIELDS.src_ble_addr, width: 15 },
  { field: 'tags', header: FIELDS.tags, width: 15 },
  { field: 'zones', header: FIELDS.zones, width: 15 },
  { field: 'status', header: FIELDS.status, width: 15 },
  { field: 'connection_ids', header: FIELDS.connection_ids, width: 12 },
  { field: 'created', header: FIELDS.created, width: 15 },
  { field: 'criticality', header: FIELDS.criticality, width: 10 },
  { field: 'id', header: FIELDS.id, width: 10 },
  { field: 'is_imported', header: FIELDS.is_imported, width: 10 },
  { field: 'last_seen', header: FIELDS.last_seen, width: 15 },
  { field: 'threat_score', header: FIELDS.threat_score, width: 13 },
  { field: 'uses_agent', header: FIELDS.uses_agent, width: 10 },
];

// Filter Table Column for Device Visibility Checkbox field
// where id is set to match localStorage item name
const DEVICE_OPTIONS = [{ id: MULTICAST_STORAGE_NAME, header: FIELDS.multicast, value: false }];

@Component({
  selector: 'app-detected-devices',
  templateUrl: './detected-devices.component.html',
  styleUrls: ['./detected-devices.component.scss'],
  providers: [DetectedDeviceInterfacesPipe, ConstantPipe, TitleCasePipe, MomentPipe],
})
export class DetectedDevicesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  COLS = COLS;

  deviceVisibilityOptions = DEVICE_OPTIONS;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'filter'],
    searchPlaceholder: 'Search Device Name, Source IP Address (CIDR supported) or MAC Address...',
    filters: {
      1: {
        key: 'usesAgent',
        label: 'Agent/Agentless',
        type: 'dropdown',
        options: [
          { label: 'Agent', value: true },
          { label: 'Agentless', value: false },
        ],
      },
      2: {
        key: 'connections',
        label: 'Connections',
        type: 'multiselect',
        options: [],
      },
      3: {
        key: 'createdDates',
        label: 'Created Date',
        type: 'date-range',
      },
      4: {
        key: 'criticality',
        label: 'Criticality',
        type: 'slider',
      },
      5: {
        key: 'isImported',
        label: 'Import Status',
        type: 'dropdown',
        options: [
          { label: 'Imported', value: true },
          { label: 'Not Imported', value: false },
        ],
      },
      6: {
        key: 'interfaces',
        label: 'Interface Types',
        type: 'multiselect',
        options: INTERFACE_TYPE_OPTIONS,
      },
      7: {
        key: 'lastSeenDates',
        label: 'Last Seen',
        type: 'date-range',
      },
      8: {
        key: 'tags',
        label: 'Tags',
        type: 'multiselect',
        options: [],
      },
      9: {
        key: 'zones',
        label: 'Zones',
        type: 'multiselect',
        options: [],
      },
    },
  };

  currentPage = 1;

  totalRecords = 0;

  values: any[] = [];

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = COLS.filter((col) => value?.includes(col.field));
  }

  selectedDevices: any[] = [];

  _selectedDevice: any = null;

  get selectedDevice(): any {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = value;
  }

  filters: {
    [key: string]: any;
  } = {};

  PER_PAGE = PER_PAGE;

  @ViewChild('dt') dt!: CommonTableComponent;

  @ViewChild('activeScanMessagePanel') activeScanMessagePanel!: OverlayPanel;

  @ViewChild('activeScanMessageTarget') activeScanMessageTarget!: ElementRef;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  _isMulticast = false;

  get isMulticast() {
    return this._isMulticast;
  }

  set isMulticast(value: boolean) {
    this._isMulticast = value;
    const deviceVisibility = this.deviceVisibilityOptions?.find((option) => option.id === MULTICAST_STORAGE_NAME);
    if (!!deviceVisibility) {
      deviceVisibility.value = value;
    }
  }

  actionsMenuItems: ActionMenuItem[] = [];

  runningIdsActiveScan: any = null;

  runningEdgeActiveScan: any = null;

  checkActiveScanInterval: any;

  isPostActiveScan = false;

  constructor(
    private detectedDeviceInterfacesPipe: DetectedDeviceInterfacesPipe,
    private constantPipe: ConstantPipe,
    private momentPipe: MomentPipe,
    private titleCasePipe: TitleCasePipe,
    private connectionSrv: ConnectionService,
    private targetDeviceSrv: TargetDeviceService,
    private hybridMonitorSrv: HybridMonitorService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = ['label', 'type', 'interfaces', 'src_mac_addr', 'src_ip_addr', 'src_ble_addr', 'tags', 'zones', 'status'];
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeviceForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    //get multicast setting when initialized
    this.isMulticast = (JSON.parse(localStorage.getItem(MULTICAST_STORAGE_NAME) || 'false') as boolean) || false;
    this.getDevices();
    this.getConnections();
    this.getTags();
    this.getZones();
    this.getIdsActiveScan();
    this.getEdgeActiveScan();
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.assignFilters(!!values?.isFiltered ? values : {});
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getDevices();
      }
    });
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.targetDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getDevices();
      }
      this.getTags();
      this.getZones();
    });
    this.subscriptions.push(subscription);
    this.checkActiveScanInterval = setInterval(() => {
      if (!!this.runningIdsActiveScan) {
        this.getIdsActiveScan();
      }
      if (!!this.runningEdgeActiveScan) {
        this.getEdgeActiveScan();
      }
    }, 15000);
  }

  onMulticastChange(event: any) {
    this.isMulticast = event.checked;
    localStorage.setItem(MULTICAST_STORAGE_NAME, JSON.stringify(event.checked));
    this.getDevices();
  }

  onDeviceChangeEvent(device: any) {
    if (!!device) {
      const deviceIndex = this.values.findIndex((value) => value.id === device.id);
      this.values[deviceIndex] = {
        ...device,
        src_mac_addr: this.getAddress(device.eth),
        src_ip_addr: this.getAddress(device.ip),
      };
    }
  }

  assignFilters(value: any) {
    this.filters = {
      ...(value?.filter || {}),
      search: value?.search || null,
      createdFrom: value?.filter?.createdDates?.from || null,
      createdTo: value?.filter?.createdDates?.to || null,
      lastSeenFrom: value?.filter?.lastSeenDates?.from || null,
      lastSeenTo: value?.filter?.lastSeenDates?.to || null,
    };
  }

  getDevicesRequest(page?: number, perPage?: number, sortField?: string, sortOrder?: number) {
    const request: Observable<any> = this.targetDeviceSrv.getDevices({
      organizationId: this.breadcrumbConfig?.organizationId,
      projectId: this.breadcrumbConfig?.projectId,
      page,
      perPage,
      sort: sortField === 'connection_id' ? 'connection' : sortField,
      reverse: sortOrder ? sortOrder === 1 : true,
      detailed: true,
      ...(this.filters || {}),
      connectionIds: this.filters?.['connections'],
      interfaceTypes: this.filters?.['interfaces'],
      tagIds: this.filters?.['tags'],
      zoneIds: this.filters?.['zones'],
      createdFrom: this.filters?.['createdFrom'] ? (moment(this.filters?.['createdFrom']).toISOString() as any) : null,
      createdTo: this.filters?.['createdTo'] ? (moment(this.filters?.['createdTo']).toISOString() as any) : null,
      lastSeenFrom: this.filters?.['lastSeenFrom'] ? (moment(this.filters?.['lastSeenFrom']).toISOString() as any) : null,
      lastSeenTo: this.filters?.['lastSeenTo'] ? (moment(this.filters?.['lastSeenTo']).toISOString() as any) : null,
      isMulticast: this.isMulticast ? 'any' : 'false',
    });
    return request;
  }

  getDevices(resetSelectedDevices = true, event?: LazyLoadEvent) {
    this.isLoading = true;
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.getDevicesRequest(page, perPage, this.dt?.datatable?.sortField as any, this.dt?.datatable?.sortOrder)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          if (!!this.targetDeviceSrv.selected) {
            this.targetDeviceSrv.selected = null;
          }
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          const devices = ((res?.devices as any[]) || []).map((device) => ({
            ...device,
            src_mac_addr: this.getAddress(device.eth),
            src_ip_addr: this.getAddress(device.ip, true),
            src_ble_addr: this.getAddress(device.ble),
          }));
          this.values = devices;
          if (!!this.selectedDevice) {
            this.selectedDevice = this.values.find((device) => device.id === this.selectedDevice.id) || null;
          }
          if (!!this.targetDeviceSrv.selected) {
            const selected = this.values.find((device) => device.id === this.targetDeviceSrv.selected.id);
            if (!!selected) {
              this.selectedDevice = selected;
            } else {
              this.getDevice(this.targetDeviceSrv.selected);
            }
          }
          if (!!resetSelectedDevices) {
            this.selectedDevices = [];
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.selectedDevice = null;
          this.selectedDevices = [];
        },
      });
  }

  getDevice(device: any) {
    if (device?.id) {
      this.isLoading = true;
      this.targetDeviceSrv
        .getDevice(device?.id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.values.unshift(res);
            this.selectedDevice = res;
          },
          error: (error) => {
            this.showErrorMessage(error.status === 404 ? `Device${!!device?.label ? ` ${device?.label}` : ''} not found` : error);
          },
        });
    }
  }

  getIdsActiveScan(callback?: () => void) {
    this.hybridMonitorSrv
      .getActiveScan('running')
      .pipe(
        finalize(() => {
          callback?.();
        }),
      )
      .subscribe({
        next: (res) => {
          const runningActiveScan = res?.[0] || null;
          if (
            this.runningIdsActiveScan?.project_id === this.breadcrumbConfig?.projectId &&
            (!runningActiveScan || runningActiveScan?.project_id !== this.breadcrumbConfig?.projectId)
          ) {
            this.showSuccessMessage('Active Scan Completed. View scan outcome under Project Settings > Events.');
          }
          this.runningIdsActiveScan = runningActiveScan;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getEdgeActiveScan(callback?: () => void) {
    this.targetDeviceSrv
      .getDevices({ activeScan: 'running', detailed: true })
      .pipe(
        finalize(() => {
          callback?.();
        }),
      )
      .subscribe({
        next: (res) => {
          const runningActiveScan = res?.devices?.[0] || null;
          if (
            this.runningEdgeActiveScan?.project_id === this.breadcrumbConfig?.projectId &&
            (!runningActiveScan || runningActiveScan?.project_id !== this.breadcrumbConfig?.projectId)
          ) {
            this.showSuccessMessage('Active Scan Completed. View scan outcome under Project Settings > Events.');
          }
          this.runningEdgeActiveScan = runningActiveScan;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getConnections() {
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe()
      .subscribe({
        next: (res) => {
          const connectionOptions = (res.data as any[] | []).map((con: { name: any; id: any }) => ({
            label: con.name || con.id,
            value: con.id,
          }));
          if (!!this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].options = connectionOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getTags() {
    this.targetDeviceSrv
      .getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe()
      .subscribe({
        next: (res) => {
          const tagOptions = (res.tags as any[] | []).map((tag) => ({
            label: tag.label,
            value: tag.id,
          }));
          if (!!this.filterConfiguration?.filters?.[8]) {
            this.filterConfiguration.filters[8].options = tagOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getZones() {
    this.targetDeviceSrv
      .getZones(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe()
      .subscribe({
        next: (res) => {
          const zoneOptions = (res.zones as any[] | []).map((zone) => ({
            label: zone.label,
            value: zone.id,
          }));
          if (!!this.filterConfiguration?.filters?.[9]) {
            this.filterConfiguration.filters[9].options = zoneOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openDeviceForm(device?: any) {
    const dialog = this.dialogSrv.open(DeviceFormComponent, {
      data: { device: this.util.cloneDeepObject(device) },
      header: `${!device ? 'Add New' : 'Edit'} Device`,
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.targetDeviceSrv.refresh$.next(true);
      }
    });
  }

  deleteDevices(devices: any[]) {
    this.isLoading = true;
    const requests: Observable<any>[] = devices.map((device) => this.targetDeviceSrv.deleteDevice(device.id));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Deleted device(s) successfully`);
          if (!!this.selectedDevices?.length) {
            this.selectedDevices = [];
          }
          if (!!this.selectedDevice) {
            const deviceIds = devices.map((device) => device.id) || [];
            this.selectedDevice = !!deviceIds.includes(this.selectedDevice.id) ? null : this.selectedDevice;
          }
          this.getDevices();
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  openDeleteConfirmation(devices: any[] = []) {
    const deviceList = devices.map((device) => `<li>${`Device ID ${device.id} ${device.label ? `: ${device.label}` : ''}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Device(s)',
      customContent: `Are you sure you want to delete ${
        !devices?.length ? 'all devices?<br/><br/>' : `the following device(s)?<ul>${deviceList.join('')}</ul>`
      }`,
      next: () => {
        if (!devices?.length) {
          this.getDevicesRequest(1, this.totalRecords)
            .pipe()
            .subscribe({
              next: (res) => {
                const allDevices = (res?.devices as any[]) || [];
                this.deleteDevices(allDevices);
              },
              error: () => {
                this.showErrorMessage('Error while getting all devices data');
                this.isLoading = false;
              },
            });
        } else {
          this.deleteDevices(devices);
        }
      },
    });
  }

  openMergeDevicesForm() {
    const dialog = this.dialogSrv.open(MergeDevicesFormComponent, {
      header: `Merge Devices`,
      width: '1000px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getDevices();
      }
    });
  }

  openActiveScanForm(event: any) {
    const dialog = this.dialogSrv.open(SharedActiveScanFormComponent, {
      header: `Active Scan`,
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        const hideMessageActiveScanCache = JSON.parse(localStorage.getItem('hideMessageActiveScan') || 'false');
        if (rs.scanType === 'ids') {
          this.getIdsActiveScan(() => {
            if (!hideMessageActiveScanCache) {
              this.isPostActiveScan = true;
              this.activeScanMessagePanel?.show(event, this.activeScanMessageTarget?.nativeElement as HTMLElement);
            }
          });
        } else {
          this.runningEdgeActiveScan = { project_id: this.breadcrumbConfig?.projectId };
          this.isPostActiveScan = true;
          this.activeScanMessagePanel?.show(event, this.activeScanMessageTarget?.nativeElement as HTMLElement);
        }
      }
    });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const filename = `project_${this.breadcrumbConfig?.projectId}_detected_devices`;
        if (specification.pageOption === 'current') {
          this.dt?.generateReportDialog.exportReport(this.getMappedReportData(this.values), filename);
        } else {
          const requests: Observable<any>[] =
            specification.pageOption === 'custom'
              ? this.util
                  .getSequenceInteger(specification.customPages.from as any, specification.customPages.to as any)
                  .map((page) => this.getDevicesRequest(page, this.dt?.datatable?.rows || PER_PAGE))
              : [this.getDevicesRequest(1, this.totalRecords)];
          forkJoin(requests).subscribe({
            next: (res) => {
              const data: any[] = [];
              res.forEach((devices) => {
                data.push(...(devices?.devices || []));
              });
              this.dt?.generateReportDialog.exportReport(this.getMappedReportData(data), filename);
            },
            error: () => {
              this.showErrorMessage('Error while getting all devices data');
              this.dt?.generateReportDialog.onClose();
            },
          });
        }
      }
    };
    this.dt?.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], []);
  }

  getAddress(value: any, isMultiple = false): any {
    if (!isMultiple) {
      return !!value ? Object.keys(value)?.[0] || '-' : '-';
    }
    const addresses = !!value ? Object.keys(value) : [];
    return {
      label: !!addresses.length ? Object.keys(value)?.[0] + (addresses.length > 1 ? '...' : '') : '-',
      tooltip: !!addresses.length ? addresses.join(', ') : '',
    };
  }

  getMappedReportData(data: any[]) {
    return (data || []).map((device) => ({
      ...device,
      application_protocols: this.constantPipe?.transform(device.application_protocols, 'detected-device-application-protocols', true),
      src_mac_addr: this.getAddress(device.eth),
      src_ip_addr: this.getAddress(device.ip)?.tooltip || null,
      src_ble_addr: this.getAddress(device.ble),
      created: !!device?.created ? this.momentPipe?.transform(device.created) : null,
      last_seen: !!device?.last_seen ? this.momentPipe?.transform(device.last_seen) : null,
      interfaces: this.detectedDeviceInterfacesPipe?.transform(device),
      status: this.titleCasePipe?.transform(device.status),
      osgen: device?.nmap?.os?.osmatch?.[0]?.osclass?.['@osgen'],
      osname: device?.nmap?.os?.osmatch?.[0]?.['@name'],
      cpe: device?.nmap?.os?.osmatch?.[0]?.osclass?.cpe,
      lastboot: !!device?.nmap?.uptime?.['@lastboot'] ? this.momentPipe?.transform(device.nmap.uptime['@lastboot']) : null,
      vendor: device?.nmap?.os?.osmatch?.[0]?.osclass?.['@vendor'],
    }));
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedDevice = null;
  }

  alwaysHideActiveScanMessage() {
    this.activeScanMessagePanel?.hide();
    localStorage.setItem('hideMessageActiveScan', 'true');
  }

  get checkPhysicalDeviceQuota() {
    return this.totalRecords < this.getQuotaLimitation(QUOTAS_FEATURES.DEVICES); // this.getQuotaLimitation(QUOTAS_FEATURES.PHYSICAL_DEVICES);
  }

  override ngOnDestroy() {
    this.cleanup();
    this.targetDeviceSrv.refresh$.next(null);
    clearInterval(this.checkActiveScanInterval);
  }
}
