import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { ANALYZER_DATA_TYPES, ANALYZER_TYPES, MODES, MODE_OPTIONS } from '@ids-constants';
import { AnalyzerRuleTypesPipe, ArrayToPlaceholderStringPipe, ConstantPipe } from '@ids-pipes';
import { DecimalPipe } from '@angular/common';
import { BaseComponent } from '@ids-components';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult, ReportSpecification } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';
import { AnomalyAnalyzerService, ConnectionService } from '@ids-services';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { FORM_PARAMS, FlAnalyzerFormComponent } from './fl-analyzer-form/fl-analyzer-form.component';
import { verticalSlideAnimation } from '@microsec/animations';

const FIELDS = {
  id: 'ID',
  name: 'Name',
  protocols: 'Protocol(s)',
  mode: 'Mode',
  trainingProgress: 'Training Progress',
  enabled: 'Status',
};

const COLS = [
  { field: 'enabled', header: FIELDS.enabled, width: 10 },
  { field: 'id', header: FIELDS.id, width: 10 },
  { field: 'name', header: FIELDS.name, width: 15 },
  { field: 'protocolNames', header: FIELDS.protocols, width: 10 },
  { field: 'mode', header: FIELDS.mode, width: 15 },
  { field: 'trainingProgress', header: FIELDS.trainingProgress, width: 15 },
];

@Component({
  selector: 'app-fl-analyzers',
  templateUrl: './fl-analyzers.component.html',
  styleUrls: ['./fl-analyzers.component.scss'],
  providers: [ConstantPipe, DecimalPipe, AnalyzerRuleTypesPipe, ArrayToPlaceholderStringPipe],
  animations: [verticalSlideAnimation],
})
export class FlAnalyzersComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  cols: any[] = [];

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  globalFilterFields: string[] = ['id', 'name', 'protocols', 'mode'];

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
  };

  activeTabIndex = 0;

  @Input() dataType = '';

  values: any[] = [];

  connections: any[] = [];

  selectedAnalyzers: any[] = [];

  selectedAnalyzer: any = null;

  MODE_OPTIONS = MODE_OPTIONS;

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_DATA_TYPES = ANALYZER_DATA_TYPES;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  @ViewChild('dt') dt!: CommonTableComponent;

  editOptions: MenuItem[] = [];

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private constantPipe: ConstantPipe,
    private decimalPipe: DecimalPipe,
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private connectionSrv: ConnectionService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.cols = this.util.cloneObjectArray(COLS);
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openAnalyzerForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.getData(true);
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result.search || '';
      }
    });
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.anomalyAnalyzerSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getData();
      }
    });
    this.subscriptions.push(subscription);
  }

  getData(getConnection = false) {
    this.isLoading = true;
    forkJoin({
      analyzers: this.anomalyAnalyzerSrv.getFlAnalyzers(this.breadcrumbConfig?.projectId),
      ...(getConnection && {
        connections: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      }),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.anomalyAnalyzerSrv.selected = { ...this.anomalyAnalyzerSrv.selected, id: null };
        }),
      )
      .subscribe({
        next: (res) => {
          if (!!getConnection) {
            this.connections = (res.connections?.data as any[]) || [];
          }
          const analyzers = ((res.analyzers.fl_analyzers as any[]) || []).map((analyzer) => {
            const flModels = ((analyzer.fl_models as any[]) || []).filter(
              (model) => !!model.enabled || (model.rounds_completed || 0) >= (model.rounds_total || 0),
            );
            const roundsCompleted = flModels.reduce((sum, model) => sum + (model.rounds_completed || 0), 0);
            const roundsTotal = flModels.reduce((sum, model) => sum + (model.rounds_total || 0), 0);
            const trainingProgress = (roundsCompleted / roundsTotal) * 100 || 0;
            return {
              ...(analyzer || {}),
              protocolNames: ((analyzer.protocols as any[]) || []).map((p) => p.protocol),
              trainingProgress,
              mode: trainingProgress < 100 ? MODES.TRAINING : MODES.PREDICTION,
            };
          });
          this.values = analyzers;

          if (!!this.selectedAnalyzer) {
            this.selectedAnalyzer = this.values.find((item) => item.id === this.selectedAnalyzer.id);
          }
          if (!!this.anomalyAnalyzerSrv.selected?.id) {
            const values = this.util.cloneObjectArray(this.values);
            const selectedIndex = values.findIndex((anomaly) => anomaly.id === this.anomalyAnalyzerSrv.selected.id);
            if (selectedIndex >= 0) {
              this.selectedAnalyzer = values[selectedIndex];
              if (selectedIndex + 1 > PER_PAGE) {
                values.splice(selectedIndex, 1);
                values.unshift(this.selectedAnalyzer);
                this.values = values;
              }
            }
            if (!!this.anomalyAnalyzerSrv.selected?.isSnort) {
              this.activeTabIndex = 1;
            }
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  updateAnalyzer(analyzer: any) {
    if (!!analyzer) {
      this.isLoading = true;
      const payload = {
        [FORM_PARAMS.ORGANIZATION_ID]: analyzer[FORM_PARAMS.ORGANIZATION_ID],
        [FORM_PARAMS.PROJECT_ID]: analyzer[FORM_PARAMS.PROJECT_ID],
        [FORM_PARAMS.NAME]: analyzer[FORM_PARAMS.NAME],
        [FORM_PARAMS.DESCRIPTION]: analyzer[FORM_PARAMS.DESCRIPTION],
        [FORM_PARAMS.ENABLED]: analyzer[FORM_PARAMS.ENABLED],
        [FORM_PARAMS.CONNECTION_IDS]: analyzer[FORM_PARAMS.CONNECTION_IDS],
        [FORM_PARAMS.ANALYZER_TYPE]: analyzer[FORM_PARAMS.ANALYZER_TYPE],
        [FORM_PARAMS.DATA_TYPE]: analyzer[FORM_PARAMS.DATA_TYPE],
        [FORM_PARAMS.PROTOCOLS]: analyzer[FORM_PARAMS.PROTOCOLS],
        [FORM_PARAMS.DEVICE_IDS]: analyzer[FORM_PARAMS.DEVICE_IDS],
        [FORM_PARAMS.DEVICE_COUNT]: analyzer[FORM_PARAMS.DEVICE_COUNT],
      };
      this.anomalyAnalyzerSrv
        .updateFlAnalyzer(analyzer.id, payload)
        .pipe(
          finalize(() => {
            this.anomalyAnalyzerSrv.refresh$.next(true);
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage('Analyzer Updated Successfully');
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  onAnalyzerChangeEvent(analyzer: any) {
    if (!!analyzer) {
      const analyzerIndex = this.values.findIndex((value) => value.id === analyzer.id);
      this.values[analyzerIndex] = {
        ...analyzer,
      };
    }
  }

  openAnalyzerForm(analyzer?: any) {
    const dialog = this.dialogSrv.open(FlAnalyzerFormComponent, {
      header: `${!analyzer ? 'Create' : 'Update'} FL Analyzer`,
      data: { analyzer: analyzer || null, connections: this.connections },
      width: '1000px',
      height: 'min-content',
      closable: false,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.anomalyAnalyzerSrv.refresh$.next(true);
        this.selectedAnalyzer = null;
        this.selectedAnalyzers = [];
      }
    });
  }

  openDeleteConfirmation(analyzers: any[] = []) {
    const analyzerList = analyzers.map((analyzer) => `<li>${`Analyzer ID ${analyzer.id} ${analyzer.name ? `: ${analyzer.name}` : ''}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Analyzer(s)',
      customContent: `Are you sure you want to delete ${
        !analyzers?.length ? 'all analyzers?<br/><br/>' : `the following analyzer(s)?<ul>${analyzerList.join('')}</ul>`
      }`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: forkJoin(
        (!!analyzers.length ? analyzers : this.values).map((analyzer) => this.anomalyAnalyzerSrv.deleteFlAnalyzer(analyzer.id)),
      ),
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`Deleted analyzer(s) successfully`);
        const analyzerIds = analyzers.map((analyzer) => analyzer.id) || [];
        if (!!this.selectedAnalyzers?.length) {
          this.selectedAnalyzers = this.selectedAnalyzers.filter((analyzer) => !analyzerIds?.includes(analyzer.id));
        }
        if (!!this.selectedAnalyzer && !!analyzerIds.includes(this.selectedAnalyzer.id)) {
          this.selectedAnalyzer = null;
        }
        this.getData();
      },
      error: (err) => {
        this.isLoading = false;
        this.showErrorMessage(err);
      },
    });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((analyzer) => ({
          ...analyzer,
          enabled: analyzer.enabled?.toString()?.toUpperCase(),
          analyzer_type: this.constantPipe?.transform(analyzer.analyzer_type, 'anomaly-analyzer-type'),
          protocolNames: this.constantPipe?.transform(analyzer.protocolNames, 'anomaly-analyzer-protocol', true),
          trainingProgress: !!analyzer.trainingProgress ? this.decimalPipe?.transform(analyzer.trainingProgress, '1.0-2') + '%' : '0%',
        }));
        this.dt.generateReportDialog.exportReport(data, `project_${this.breadcrumbConfig?.projectId}_anomaly_analyzers`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedAnalyzer = null;
  }

  override ngOnDestroy() {
    this.cleanup();
    this.anomalyAnalyzerSrv.refresh$.next(null);
  }
}
