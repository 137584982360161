<div class="grid">
  <!-- Search, Filter, Clear -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <p-button
          [label]="'Create Assessment Type'"
          severity="primary"
          [rounded]="true"
          icon="pi pi-plus"
          (onClick)="openAssessmentTypeForm()"
          *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
        </p-button>
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedAssessmentType }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [lazy]="true"
        (lazyLoadEvent)="getAssessmentTypes(false, $any($event))"
        [totalRecords]="totalRecords"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        selectionMode="multiple"
        [(selection)]="selectedAssessmentTypes"
        [(rowSingleSelection)]="selectedAssessmentType"
        [actionsMenuItems]="actionsMenuItems"
        [dataKey]="'id'"
        itemName="Assessment Type"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <div class="col-12 lg:col-6" *ngIf="!!selectedAssessmentType">
    <div class="container-border">
      <microsec-loading-overlay [isLoading]="!!isLoading">
        <microsec-selected-panel-header
          [id]="selectedAssessmentType.id"
          [label]="selectedAssessmentType.name"
          (closeSelectedPanelEvent)="hideDetailsPanel()"
          [rightTemplate]="rightTemplate"></microsec-selected-panel-header>
        <ng-template #rightTemplate>
          <div class="flex align-items-center gap-2">
            <p-button
              severity="primary"
              icon="fa fa-edit"
              pTooltip="Edit"
              tooltipPosition="bottom"
              appendTo="body"
              (onClick)="openAssessmentTypeCreator(selectedAssessmentType)"
              *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
            </p-button>
            <p-button
              severity="danger"
              icon="fas fa-trash"
              pTooltip="Delete"
              tooltipPosition="bottom"
              appendTo="body"
              (onClick)="openDeleteConfirmation([selectedAssessmentType])"
              *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
            </p-button>
          </div>
        </ng-template>
        <microsec-card [isDetailCard]="true">
          <p-tabView styleClass="microsec-dashboard-tab">
            <p-tabPanel header="Overview">
              <app-assessment-type-overview [assessmentType]="selectedAssessmentType"> </app-assessment-type-overview>
            </p-tabPanel>
          </p-tabView>
        </microsec-card>
      </microsec-loading-overlay>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedAssessmentTypes)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedAssessmentTypes.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getAssessmentTypes()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>
