import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-analyzer-ip-rule-setting-form',
  templateUrl: './analyzer-ip-rule-setting-form.component.html',
  styleUrls: ['./analyzer-ip-rule-setting-form.component.scss'],
})
export class AnalyzerIpRuleSettingFormComponent extends BaseComponent implements OnInit {
  fields: any[] = [];
  analyzer: any = null;

  constructor(
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.analyzer = this.dialogConfig?.data?.analyzer;
  }
}
