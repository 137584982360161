import { Component, ViewChild } from '@angular/core';
import { BaseAppComponent } from '@microsec/components';
import { BaseAppComponent as WrapperBaseAppComponent } from '@ids-components';
import { Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { MODULES } from './products/modules';
import { MICROSEC_MICRO_IDS } from '@microsec/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends WrapperBaseAppComponent {
  @ViewChild('baseApp') baseApp!: BaseAppComponent;

  constructor(
    private store: Store,
    titleSrv: Title,
  ) {
    super(titleSrv);
    // Others
    this.titleSrv.setTitle(MICROSEC_MICRO_IDS);
    this.store.dispatch(new fromLayoutActions.SetModules(MODULES));
  }
}
