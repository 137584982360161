import { faTv } from '@fortawesome/free-solid-svg-icons';
import { FEATURES, PROJECT_MANAGEMENT_GROUPS, WORKFLOW_FEATURES } from '@ids-constants';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@ids-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { WorkflowsComponent } from './respond/workflows/workflows.component';
import { WORKFLOWS_CHILDREN } from '@microsec/module-workflows';

export const PROJECT_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_GROUPS.RESPOND,
    route: PROJECT_MANAGEMENT_CONSTANTS.WORKFLOWS.ROUTE,
    label: PROJECT_MANAGEMENT_CONSTANTS.WORKFLOWS.LABEL,
    icon: faTv,
    component: WorkflowsComponent,
    children: WORKFLOWS_CHILDREN,
    visible: false,
    featureName: FEATURES.WORKFLOWS,
    featureCheckField: WORKFLOW_FEATURES.ENABLED,
  },
];
