import { ILayoutItem } from '@microsec/interfaces';
import { ReportsComponent } from './reports/reports.component';
// import { ReportTypesComponent } from './report-types/report-types.component';

export const REPORT_CONSTANTS = {
  REPORTS: {
    ROUTE: 'reports',
    LABEL: 'Reports',
  },
  REPORT_TYPES: {
    ROUTE: 'report-types',
    LABEL: 'Report Types',
  },
};

export const REPORT_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: REPORT_CONSTANTS.REPORTS.ROUTE,
  },
  {
    route: REPORT_CONSTANTS.REPORTS.ROUTE,
    label: REPORT_CONSTANTS.REPORTS.LABEL,
    component: ReportsComponent,
  },
  // {
  //   route: REPORT_CONSTANTS.REPORT_TYPES.ROUTE,
  //   label: REPORT_CONSTANTS.REPORT_TYPES.LABEL,
  //   component: ReportTypesComponent,
  // },
];
