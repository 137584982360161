export const PROJECT_USAGES = [
  { value: 'acms', label: 'X.509' },
  { value: 'micropki', label: 'MicroPKI' },
];

export const PROJECT_STATUS_VALUES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const PROJECT_STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const PROJECT_STATUSES = [
  { value: PROJECT_STATUS_VALUES.ACTIVE, label: PROJECT_STATUS_LABELS.ACTIVE },
  { value: PROJECT_STATUS_VALUES.INACTIVE, label: PROJECT_STATUS_LABELS.INACTIVE },
];

export const PROJECT_STATUS_TAGS = [
  {
    value: true,
    label: PROJECT_STATUS_LABELS.ACTIVE,
    backgroundColor: '#19b976',
    color: '#ffffff',
  },
  {
    value: false,
    label: PROJECT_STATUS_LABELS.INACTIVE,
    backgroundColor: '#fb4d58',
    color: '#ffffff',
  },
];

export const INTERNAL_SCHEME = 'internal://';
