import { Component, OnDestroy } from '@angular/core';
import { FEATURES } from '@ids-constants';
import { BaseComponent as SharedBaseComponent } from '@microsec/components';

@Component({
  template: '',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent extends SharedBaseComponent implements OnDestroy {
  constructor() {
    super();
  }

  checkConnectionFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.CONNECTIONS]?.[featureName] || false;
  }

  checkAnalyzerFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.ANALYZERS]?.[featureName] || false;
  }

  checkDeviceFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.DEVICES]?.[featureName] || false;
  }

  checkThreatFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.THREATS]?.[featureName] || false;
  }

  checkWorkflowFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.WORKFLOWS]?.[featureName] || false;
  }

  checkAssessmentFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.ASSESSMENTS]?.[featureName] || false;
  }

  checkReportFeatureEnabled(featureName: string) {
    return this.features?.[FEATURES.REPORTS]?.[featureName] || false;
  }

  getQuotaLimitation(featureName: string) {
    return this.features?.[FEATURES.QUOTAS]?.[featureName] || 0;
  }
}
