import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ATTACHMENT_ALLOWED_EXTENSIONS, EDITOR_OBJECT_TYPES } from '@ids-constants';
import { TargetDeviceService } from '@ids-services';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { Observable, finalize, switchMap } from 'rxjs';

const FORM_PARAMS = {
  ATTACHMENTS: 'attachments',
};
@Component({
  selector: 'app-nme-attachments',
  templateUrl: './nme-attachments.component.html',
  styleUrls: ['./nme-attachments.component.scss'],
})
export class NmeAttachmentsComponent extends BaseComponent implements AfterViewInit {
  @Input() entityType: any = null;

  _entity: any = null;

  get entity() {
    return this._entity;
  }

  @Input() set entity(value: any) {
    this._entity = value;
    setTimeout(() => {
      const files = ((value?.attachments as any[]) || []).map((p) => this.convertToDisplayAttachment(p));
      this.form.setControlValue(FORM_PARAMS.ATTACHMENTS, files);
    });
  }

  fields: FormItem[] = [];

  @Output() refreshSelectionEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(private targetDeviceSrv: TargetDeviceService) {
    super();
  }

  ngAfterViewInit() {
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const textField = Object.assign(new FormItem(), {
      label: 'Attachments',
      field: 'text',
      labelStyleClass: 'font-bold',
    } as FormItem);
    textField.setFullSize();
    const attachmentsField = this.initAttachmentField();
    const fields: FormItem[] = [textField, attachmentsField];
    this.fields = fields;
  }

  /**
   * Init attachment field
   * @returns
   */
  initAttachmentField() {
    const attachmentsField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.ATTACHMENTS,
      hasNoLabel: true,
      label: 'Attachment',
      field: 'file',
      isMultiple: true,
      acceptedFileTypes: [...ATTACHMENT_ALLOWED_EXTENSIONS],
      placeholder: 'Upload attachments',
      defaultValue: [],
      uploadEvent: new EventEmitter(),
      downloadEvent: new EventEmitter(),
      deleteEvent: new EventEmitter(),
    } as FormItem);
    // UPLOAD
    attachmentsField.uploadEvent?.subscribe((event) => {
      const file = event?.target?.files?.[0];
      if (!!file) {
        this.form.isLoading = true;
        this.targetDeviceSrv
          .createAttachment({ file_path: file })
          .pipe(
            switchMap((attachment) => {
              const files: any[] = this.form.getControlValue(FORM_PARAMS.ATTACHMENTS);
              files.push(this.convertToDisplayAttachment(attachment));
              this.form.setControlValue(FORM_PARAMS.ATTACHMENTS, files);
              let request = new Observable<any>();
              switch (this.entityType) {
                case EDITOR_OBJECT_TYPES.DEVICE: {
                  request = this.targetDeviceSrv.updateDevice(this.entity.id, {
                    attachments: files.map((p) => this.convertToUploadAttachment(p)),
                  });
                  break;
                }
                case EDITOR_OBJECT_TYPES.ZONE: {
                  request = this.targetDeviceSrv.updateZone(this.entity.id, {
                    attachments: files.map((p) => this.convertToUploadAttachment(p)),
                  });
                  break;
                }
                default: {
                  break;
                }
              }
              return request;
            }),
            finalize(() => {
              this.form.isLoading = false;
            }),
          )
          .subscribe({
            next: () => {
              this.showSuccessMessage('Uploaded attachment successfully');
              this.refreshSelectionEvent.emit();
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          });
      }
    });
    // DOWNLOAD
    attachmentsField.downloadEvent?.subscribe((file) => {
      this.form.isLoading = true;
      this.targetDeviceSrv
        .getAttachment(file.id)
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: (result) => {
            this.util.downloadFileFromBlob(result);
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    });
    // DELETE
    attachmentsField.deleteEvent?.subscribe((file) => {
      this.form.isLoading = true;
      this.targetDeviceSrv
        .deleteAttachment(file.id)
        .pipe(
          switchMap(() => {
            const allFiles: any[] = this.form.getControlValue(FORM_PARAMS.ATTACHMENTS);
            const files = allFiles.filter((p) => p?.id !== file?.id);
            this.form.setControlValue(FORM_PARAMS.ATTACHMENTS, files);
            let request = new Observable<any>();
            switch (this.entityType) {
              case EDITOR_OBJECT_TYPES.DEVICE: {
                request = this.targetDeviceSrv.updateDevice(this.entity.id, {
                  attachments: files.map((p) => this.convertToUploadAttachment(p)),
                });
                break;
              }
              case EDITOR_OBJECT_TYPES.ZONE: {
                request = this.targetDeviceSrv.updateZone(this.entity.id, {
                  attachments: files.map((p) => this.convertToUploadAttachment(p)),
                });
                break;
              }
              default: {
                break;
              }
            }
            return request;
          }),
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage('Removed attachment successfully');
            this.refreshSelectionEvent.emit();
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    });
    return attachmentsField;
  }

  /**
   * Convert attachment object to display
   * @param attachment
   * @returns
   */
  convertToDisplayAttachment(attachment: any) {
    return {
      id: attachment.id,
      name: attachment.file_name,
      size: attachment.file_size,
      type: attachment.file_type,
    };
  }

  /**
   * Convert attachment object to upload
   * @param attachment
   * @returns
   */
  convertToUploadAttachment(attachment: any) {
    return {
      id: attachment.id,
      file_name: attachment.name,
      file_size: attachment.size,
      file_type: attachment.type,
    };
  }
}
