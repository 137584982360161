import { Component, Input } from '@angular/core';
import { EDITOR_DIAGRAM_BASE_URL, EDITOR_DEFAULT_LOADING_MESSAGE } from '@ids-constants';
import { NetworkMapEditor } from '@ids-models';

@Component({
  selector: 'app-nme-loader',
  templateUrl: './nme-loader.component.html',
  styleUrls: ['./nme-loader.component.scss'],
})
export class NmeLoaderComponent {
  _editor: NetworkMapEditor | null = null;

  get editor() {
    return this._editor;
  }

  @Input() set editor(value: NetworkMapEditor | null) {
    this._editor = value;
  }

  EDITOR_DIAGRAM_BASE_URL = EDITOR_DIAGRAM_BASE_URL;

  EDITOR_DEFAULT_LOADING_MESSAGE = EDITOR_DEFAULT_LOADING_MESSAGE;

  close() {
    if (!!this.editor) {
      this.editor.subLoadings = [];
      this.editor.isLoading = false;
    }
  }

  get shouldShowCloseButton() {
    return this.editor?.subLoadings.find((p) => p.error === true) || false;
  }
}
