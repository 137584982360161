import { Component, Input } from '@angular/core';
import { CONNECTION_TYPES, STATUSES } from '@ids-constants';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-network-overview',
  templateUrl: './network-overview.component.html',
  styleUrls: ['./network-overview.component.scss'],
})
export class NetworkOverviewComponent extends BaseComponent {
  @Input() connection: any = {};

  STATUSES = STATUSES;

  CONNECTION_TYPES = CONNECTION_TYPES;

  constructor() {
    super();
  }
}
