import { Component, OnInit } from '@angular/core';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { ConnectionService, TargetDeviceService } from '@ids-services';

import { TreeNode } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-merge-devices-form',
  templateUrl: './merge-devices-form.component.html',
  styleUrls: ['./merge-devices-form.component.scss'],
})
export class MergeDevicesFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  allDeviceSelected = false;

  selectedDevices: any[] = [];

  cols: any[] = [
    { field: 'id', header: 'ID', width: 5 },
    { field: 'name', header: 'Name', width: 8 },
    { field: 'macAddress', header: 'MAC Address', width: 15 },
    { field: 'connectionName', header: 'Connection', width: 10 },
  ];

  devicesMergeCandidates: { node: TreeNode[]; arr: any[] } = {
    node: [],
    arr: [],
  };

  PER_PAGE = PER_PAGE;

  PER_PAGE_OPTIONS = PER_PAGE_OPTIONS;

  constructor(
    public dialogRef: DynamicDialogRef,
    private targetDeviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.getData();
  }

  getData() {
    this.isLoading = true;
    forkJoin({
      devices: this.targetDeviceSrv.getDevicesMergeCandidates(this.breadcrumbConfig?.projectId),
      connections: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const connectionNames = Object.assign({}, ...((res.connections?.data as any[]) || [])?.map((p) => ({ [p.id]: p.name || '' })));

          const node: TreeNode[] = [];
          const arr: any[] = [];
          Object.entries(res.devices || {}).forEach(([key, value]: [string, any]) => {
            const children: any[] = [];
            arr.push(key);
            Object.entries(value.source_device || {}).forEach(([keyChildren, valueChildren]: [string, any]) => {
              arr.push(keyChildren);
              children.push({
                data: {
                  id: keyChildren,
                  name: valueChildren.source_device_name,
                  macAddress: valueChildren.source_device_mac,
                  connectionName: !!valueChildren.source_device_conn_id
                    ? JSON.parse(valueChildren.source_device_conn_id)
                        ?.map((connectionId: any) => connectionNames[connectionId] || `ID: ${connectionId}`)
                        .join(', ')
                    : '-',
                },
              });
            }),
              node.push({
                data: {
                  id: key,
                  name: value.dest_device_name,
                  macAddress: value.dest_device_mac,
                  connectionName: !!value.dest_device_conn_id
                    ? JSON.parse(value.dest_device_conn_id)
                        ?.map((connectionId: any) => connectionNames[connectionId] || `ID: ${connectionId}`)
                        .join(', ')
                    : '-',
                },
                children: children,
              });
          });
          this.devicesMergeCandidates = {
            node: node,
            arr: arr,
          };
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onSubmit(isAll = false) {
    this.isLoading = true;
    const selected = this.util.cloneObjectArray(!!isAll ? this.devicesMergeCandidates.arr : this.selectedDevices);
    const payload: any = {};
    this.devicesMergeCandidates.node.forEach((node) => {
      if (selected.includes(node.data.id)) {
        const childrenArr: number[] = [];
        node.children?.forEach((children) => {
          if (selected.includes(children.data.id)) {
            childrenArr.push(+children.data.id);
          }
        });
        payload[node.data.id] = childrenArr;
      }
    });
    this.targetDeviceSrv
      .mergeDevices(this.breadcrumbConfig?.projectId, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Merged devices successfully`);
          this.dialogRef.close(true);
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onToggleAll(isChecked: any) {
    if (!!isChecked) {
      this.selectedDevices = this.util.cloneObjectArray(this.devicesMergeCandidates.arr);
    } else {
      this.selectedDevices = [];
    }
  }

  onChangeTreeTableCheckbox(checkedList: any[], deviceId: any, rowNode: any) {
    let selectedDevices = this.util.cloneObjectArray(checkedList || []);
    const isChecked = !!selectedDevices.includes(deviceId);
    const isChild = !!rowNode.parent;
    if (!!isChecked) {
      const newChecked: any[] = [];
      if (!!isChild) {
        if (!selectedDevices.includes(rowNode.parent.data.id)) {
          newChecked.push(rowNode.parent.data.id);
        }
      } else {
        rowNode.node.children?.forEach((children: any) => {
          if (!selectedDevices.includes(children.data.id)) {
            newChecked.push(children.data.id);
          }
        });
      }
      selectedDevices.push(...newChecked);
    }
    if (!isChecked) {
      if (!!isChild) {
        const selectedChildren: any[] = [];
        rowNode.parent.children?.forEach((children: any) => {
          if (!!selectedDevices.includes(children.data.id)) {
            selectedChildren.push(children.data.id);
          }
        });
        if (!selectedChildren.length) {
          selectedDevices = selectedDevices.filter((device) => device !== rowNode.parent.data.id);
        }
      } else {
        const children: any[] = rowNode.node.children?.map((children: any) => children.data.id);
        selectedDevices = selectedDevices.filter((device) => !children.includes(device));
      }
    }
    this.selectedDevices = selectedDevices;
    if (this.selectedDevices.length === this.devicesMergeCandidates.arr.length) {
      this.allDeviceSelected = true;
    } else {
      this.allDeviceSelected = false;
    }
  }
}
