<div class="grid">
  <!-- Create + Search -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button label="Create Analyzer" severity="primary" [rounded]="true" icon="pi pi-plus" (onClick)="openAnalyzerForm()"> </p-button>
    </ng-template>
  </div>

  <!-- Data table -->
  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedAnalyzer }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        selectionMode="multiple"
        [(selection)]="selectedAnalyzers"
        [(rowSingleSelection)]="selectedAnalyzer"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [actionButtonsTemplate]="actionButtonsTemplate"
        [dataKey]="'id'"
        itemName="Federated Learning Analyzer"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate"></microsec-common-table>
    </microsec-card>
  </div>
  @if (!!selectedAnalyzer) {
    <div class="col-12 lg:col-6">
      <div class="container-border">
        <app-fl-analyzer-details
          [analyzerId]="selectedAnalyzer.id"
          [connections]="connections"
          [panelHeaderRightTemplate]="panelHeaderRightTemplate"
          (analyzerChangeEvent)="onAnalyzerChangeEvent($event)"
          (hidePanelEvent)="hideDetailsPanel()"></app-fl-analyzer-details>
        <ng-template #panelHeaderRightTemplate>
          @if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
            <p-button
              severity="primary"
              styleClass="mr-2"
              icon="fas fa-edit"
              pTooltip="Edit"
              tooltipPosition="bottom"
              appendTo="body"
              (onClick)="openAnalyzerForm(selectedAnalyzer)">
            </p-button>
          }
          <p-menu styleClass="microsec-button-context-menu" [model]="editOptions" [popup]="true" appendTo="body"></p-menu>

          @if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
            <p-button
              type="button"
              icon="fa fa-trash"
              severity="danger"
              (onClick)="openDeleteConfirmation([selectedAnalyzer])"
              pTooltip="Delete"
              tooltipPosition="bottom"
              appendTo="body"></p-button>
          }
        </ng-template>
      </div>
    </div>
  }
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    @if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
      <microsec-operation-button
        operation="Delete"
        buttonClass="p-button-danger"
        icon="fa fa-trash"
        (selectedOperationEvent)="openDeleteConfirmation(selectedAnalyzers)"
        (allOperationEvent)="openDeleteConfirmation()"
        [disabled]="!selectedAnalyzers.length"></microsec-operation-button>
    }
    <microsec-refresh-button (refreshEvent)="anomalyAnalyzerSrv.refresh$.next(true)"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Protocol(s) -->
    <ng-container *ngSwitchCase="'protocolNames'">
      {{ rowData[col.field] | constant: 'anomaly-analyzer-protocol' : true }}
    </ng-container>
    <!-- Training Progress -->
    <ng-container *ngSwitchCase="'trainingProgress'">
      {{ rowData[col.field] ? (rowData[col.field] | number: '1.0-2') + '%' : '0%' }}
    </ng-container>
    <!-- Mode -->
    <ng-container *ngSwitchCase="'mode'">
      <p-dropdown appendTo="body" [options]="MODE_OPTIONS" optionLabel="label" optionValue="value" [(ngModel)]="rowData.mode" [disabled]="true">
      </p-dropdown>
    </ng-container>
    <!-- Enabled -->
    <ng-container *ngSwitchCase="'enabled'">
      <p-inputSwitch (click)="$event.stopPropagation()" [(ngModel)]="rowData[col.field]" (onChange)="updateAnalyzer(rowData)"></p-inputSwitch>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template let-rowData="rowData" #actionButtonsTemplate>
  @if (!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]) {
    <div class="flex align-items-center gap-3">
      <span class="icon-button" pTooltip="Edit" tooltipPosition="bottom" appendTo="body" (click)="openAnalyzerForm(rowData)">
        <i class="fas fa-edit"></i>
      </span>
      <span class="icon-button" pTooltip="Delete" tooltipPosition="bottom" appendTo="body" (click)="openDeleteConfirmation([rowData])">
        <i class="fa fa-trash"></i>
      </span>
    </div>
  }
</ng-template>
