<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!threat) {
    <microsec-selected-panel-header
      [id]="threat.threat_id"
      [label]="threat | threatTitlePipe"
      (closeSelectedPanelEvent)="hidePanelEvent.emit()"></microsec-selected-panel-header>
    <microsec-card [isDetailCard]="true">
      <p-tabView styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Threat Details">
          <app-threat-overview [threat]="threat" />
        </p-tabPanel>
        <p-tabPanel header="Mitigations">
          <app-threat-mitigations [threat]="threat" />
        </p-tabPanel>
        <p-tabPanel header="Comment">
          <app-threat-comment [threat]="threat" />
        </p-tabPanel>
      </p-tabView>
    </microsec-card>
  }
</microsec-loading-overlay>
