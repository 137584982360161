/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';

import { OrganizationService } from '@microsec/services';
import { UserService } from '@microsec/services';
import { EventMonitorService } from '@microsec/services';
import { Store } from '@ngrx/store';
import { Util } from '@microsec/utilities';
import { asyncScheduler, catchError, map, mergeMap, Observable, scheduled, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { EventMonitorAction, EventMonitorActionTypes, fromEventMonitorActions } from './event-monitor.actions';
import { eventMonitorSelectors } from './event-monitor.selectors';

@Injectable()
export class EventMonitorEffects {
  constructor(
    private readonly actions$: Actions<EventMonitorAction>,
    private readonly toastSrv: MessageService,
    private readonly eventSrv: EventMonitorService,
    private readonly organizationSrv: OrganizationService,
    // private readonly deviceSrv: DeviceService,
    private readonly userSrv: UserService,
    private readonly store: Store,
  ) {}

  // Get event categories
  getEventCategoriesEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_CATEGORIES),
      switchMap(() => {
        return this.eventSrv.getEventCategories().pipe(
          takeUntil(this.actions$.pipe(ofType(EventMonitorActionTypes.CANCEL_GET_EVENT_CATEGORIES_REQUEST))),
          map((eventCategories) => new fromEventMonitorActions.OnGetEventCategoriesSuccess(eventCategories)),
          catchError((error) => scheduled([new fromEventMonitorActions.OnGetEventCategoriesFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get event categories failed
  onGetEventCategoriesFailureEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_CATEGORIES_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get event organizations
  getEventOrganizationsEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS),
      switchMap(() => {
        return this.organizationSrv.getOrganizations().pipe(
          takeUntil(this.actions$.pipe(ofType(EventMonitorActionTypes.CANCEL_GET_EVENT_ORGANIZATIONS_REQUEST))),
          map((eventOrganizations) => new fromEventMonitorActions.OnGetEventOrganizationsSuccess(eventOrganizations)),
          catchError((error) => scheduled([new fromEventMonitorActions.OnGetEventOrganizationsFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get event organizations failed
  onGetEventOrganizationsFailureEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get event users
  getEventUsersEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_USERS),
      switchMap(() => {
        return this.userSrv.getUsers('', true).pipe(
          takeUntil(this.actions$.pipe(ofType(EventMonitorActionTypes.CANCEL_GET_EVENT_USERS_REQUEST))),
          map((eventUsers) => new fromEventMonitorActions.OnGetEventUsersSuccess(eventUsers)),
          catchError((error) => scheduled([new fromEventMonitorActions.OnGetEventUsersFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get event users failed
  onGetEventUsersFailureEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_CATEGORIES_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get event devices
  getEventDevicesEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_DEVICES),
      switchMap(async () => new fromEventMonitorActions.OnGetEventDevicesSuccess([])),
    ),
  );

  // Get event users failed
  onGetEventDevicesFailureEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENT_DEVICES_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Get events
  getEventsEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENTS),
      switchMap((action) => {
        const initAction = action;
        return this.eventSrv.getEvents({ search: action.searchText, page: action.page, perPage: action.perPage }).pipe(
          takeUntil(this.actions$.pipe(ofType(EventMonitorActionTypes.CANCEL_GET_EVENTS_REQUEST))),
          map((result: any) => new fromEventMonitorActions.OnGetEventsSuccess(result, initAction)),
          catchError((error) => scheduled([new fromEventMonitorActions.OnGetEventsFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get events failed
  onGetEventsFailureEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.GET_EVENTS_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );

  // Refresh the list of events
  refreshEventsEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.REFRESH_EVENTS),
      withLatestFrom(this.store.select(eventMonitorSelectors.refreshConfig)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([action, refreshConfig]) =>
        scheduled([new fromEventMonitorActions.GetEvents(refreshConfig.searchText, refreshConfig.page, refreshConfig.perPage)], asyncScheduler),
      ),
    ),
  );

  // export events
  exportEventsEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.EXPORT_EVENTS),
      switchMap((action) => {
        return this.eventSrv.getEvents({ search: action.searchText }).pipe(
          takeUntil(this.actions$.pipe(ofType(EventMonitorActionTypes.CANCEL_EXPORT_EVENTS_REQUEST))),
          map((results: any[]) => {
            Util.showSuccessMessage(this.toastSrv, 'Export events successfully');
            return new fromEventMonitorActions.OnExportEventsSuccess(results);
          }),
          catchError((error) => scheduled([new fromEventMonitorActions.OnExportEventsFailed({ error })], asyncScheduler)),
        );
      }),
    ),
  );

  // Get export failed
  onExportEventsFailureEffect$: Observable<EventMonitorAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(EventMonitorActionTypes.EXPORT_EVENTS_FAILURE),
      mergeMap((action) => {
        Util.showErrorMessage(this.toastSrv, action.error);
        return scheduled([{ type: 'ANONYMOUS' } as any], asyncScheduler);
      }),
    ),
  );
}
