import { faBuilding, faCubes, faFileContract, faFileLines, faUsers } from '@fortawesome/free-solid-svg-icons';
import { UsersComponent } from './users/users.component';
import { LicensesComponent } from './licenses/licenses.component';
import { EventsComponent } from './events/events.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { SYSTEM_SETTINGS_CONSTANTS, SYSTEM_SETTINGS_GROUPS } from '@ids-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { SystemSnortSettingsComponent } from './system-snort-settings/system-snort-settings.component';

export const SYSTEM_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    group: SYSTEM_SETTINGS_GROUPS.GLOBAL_SETTINGS,
    route: SYSTEM_SETTINGS_CONSTANTS.ORGANIZATIONS.ROUTE,
    icon: faBuilding,
    label: SYSTEM_SETTINGS_CONSTANTS.ORGANIZATIONS.LABEL,
    component: OrganizationsComponent,
  },
  {
    group: SYSTEM_SETTINGS_GROUPS.GLOBAL_SETTINGS,
    route: SYSTEM_SETTINGS_CONSTANTS.USERS.ROUTE,
    icon: faUsers,
    label: SYSTEM_SETTINGS_CONSTANTS.USERS.LABEL,
    component: UsersComponent,
  },
  {
    group: SYSTEM_SETTINGS_GROUPS.GLOBAL_SETTINGS,
    route: SYSTEM_SETTINGS_CONSTANTS.EVENTS.ROUTE,
    icon: faFileLines,
    label: SYSTEM_SETTINGS_CONSTANTS.EVENTS.LABEL,
    component: EventsComponent,
  },
  {
    group: SYSTEM_SETTINGS_GROUPS.GLOBAL_SETTINGS,
    route: SYSTEM_SETTINGS_CONSTANTS.LICENSES.ROUTE,
    icon: faFileContract,
    label: SYSTEM_SETTINGS_CONSTANTS.LICENSES.LABEL,
    component: LicensesComponent,
  },
  {
    group: SYSTEM_SETTINGS_GROUPS.GLOBAL_SETTINGS,
    route: SYSTEM_SETTINGS_CONSTANTS.SNORT_SETTINGS.ROUTE,
    icon: faCubes,
    label: SYSTEM_SETTINGS_CONSTANTS.SNORT_SETTINGS.LABEL,
    component: SystemSnortSettingsComponent,
  },
];
