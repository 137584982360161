import { ILayoutItem } from '@microsec/interfaces';
import { PROJECT_SETTINGS_CONSTANTS, PROJECT_SETTINGS_CHILDREN as COMMON_PROJECT_SETTINGS_CHILDREN } from '@ids-products';
import { EventsComponent } from './events/events.component';

export const PROJECT_SETTINGS_CHILDREN: ILayoutItem[] = [
  ...COMMON_PROJECT_SETTINGS_CHILDREN,
  {
    route: PROJECT_SETTINGS_CONSTANTS.EVENTS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.EVENTS.LABEL,
    component: EventsComponent,
  },
];
