import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { GETTING_STARTED_ROUTE } from '@microsec/constants';
import { ILayoutItem } from '@microsec/interfaces';

export const PROJECT_GETTING_STARTED_CHILDREN: ILayoutItem[] = [
  {
    route: GETTING_STARTED_ROUTE,
    label: 'Getting Started',
    icon: faFlagCheckered,
    visible: true,
  },
];
