<div class="grid">
  <div class="col-12">
    <div class="grid field">
      <label class="col-12 mb-2 md:col-2 md:mb-0 text-gray-200">Active Model</label>
      <div class="col-8 md:col-5">
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="activeModelOptions"
          placeholder="Select Active Model"
          optionLabel="label"
          optionValue="value"
          appendTo="body"
          [(ngModel)]="activeModel"
          [filter]="true"
          filterPlaceholder="Seach Model..."
          (onChange)="setActiveModel($event)"></p-dropdown>
      </div>
      <div class="col-2 md:col-5 flex align-items-center" style="width: 35px">
        <span [pTooltip]="'Select active model from the list of models available'" tooltipPosition="bottom" appendTo="body">
          <i class="pi pi-info-circle pr-1 text-gray-200 text-xl"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="leftTemplate"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button
        [label]="'Import'"
        severity="primary"
        [rounded]="true"
        icon="pi pi-plus"
        [disabled]="!!isLoading"
        (onClick)="openExportImportForm('import')">
      </p-button>
    </ng-template>
  </div>

  <!-- Data table -->
  <div class="col-12">
    <microsec-card [isNestedCard]="true" [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <ng-template #containerHeaderRightTemplate>
    <div class="flex flex-wrap align-items-center gap-2">
      <microsec-refresh-button (refreshEvent)="anomalyAnalyzerSrv.refresh$.next(true)" [isDisabled]="!!isLoading"></microsec-refresh-button>
      <microsec-table-column-filter
        [key]="constructor.name"
        [cols]="cols || []"
        [(selectedColFields)]="selectedColFields"
        (exportReportEvent)="openGenerateReportDialog()">
      </microsec-table-column-filter>
    </div>
  </ng-template>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Created -->
    <ng-container *ngSwitchCase="'created'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <!-- Updated -->
    <ng-container *ngSwitchCase="'updated'">
      {{ rowData[col.field] | momentPipe }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
