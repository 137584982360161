<microsec-form-builder
  #fb
  [isPage]="true"
  [shouldShowActionButtons]="false"
  [fields]="fields"
  [hasInfoFields]="false"
  [shouldSubmitOnEnter]="false" />

<ng-template #statusField>
  @if (!!form) {
    <div class="flex h-full align-items-center">
      <div class="flex text-value">{{ statusLabel() }}</div>
      @if (!!statusLabel()) {
        <div class="flex text-value ml-2" [pTooltip]="tooltipContent" tooltipPosition="bottom" appendTo="body">
          <ng-template #tooltipContent>
            <div [innerHTML]="statusDescription()"></div>
          </ng-template>
          <i class="pi pi-info-circle"></i>
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #complianceDetailsField>
  @if (!!securityLevel?.target?.length && !!securityLevel?.capable?.length) {
    <app-compliance-details-field
      [readOnly]="false"
      [shouldTableWrapped]="true"
      [shouldShowPlaceholder]="false"
      [(securityLevel)]="securityLevel"
      (securityLevelChange)="changeSecurityLevel()"
      [(isValid)]="complianceDetailsValid" />
  }
</ng-template>
