import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { TargetDeviceService } from '@ids-services';
import { BaseComponent, CommonTableComponent } from '@microsec/components';
import { PER_PAGE } from '@microsec/constants';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, finalize } from 'rxjs';

const FIELDS = {
  id: 'ID',
  label: 'Name',
  type: 'Type',
  interfaces: 'Interfaces',
  src_mac_addr: 'MAC Address',
  src_ip_addr: 'IP Addresses',
};

const COLS = [
  { field: 'label', header: FIELDS.label, width: 10 },
  { field: 'type', header: FIELDS.type, width: 5 },
  { field: 'interfaces', header: FIELDS.interfaces, width: 5 },
  { field: 'src_mac_addr', header: FIELDS.src_mac_addr, width: 5 },
  { field: 'src_ip_addr', header: FIELDS.src_ip_addr, width: 5 },
];

@Component({
  selector: 'app-zone-devices',
  templateUrl: './zone-devices.component.html',
  styleUrls: ['./zone-devices.component.scss'],
})
export class ZoneDevicesComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  _zone: any = null;

  get zone() {
    return this._zone;
  }

  @Input() set zone(value: any) {
    this._zone = value;
    this.getDevices();
  }

  currentPage = 1;

  totalRecords = 0;

  values: any[] = [];

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = COLS.filter((col) => value?.includes(col.field));
  }

  @ViewChild('dt') dt!: CommonTableComponent;

  PER_PAGE = PER_PAGE;

  COLS = COLS;

  constructor(private targetDeviceSrv: TargetDeviceService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.selectedColFields = ['label', 'type', 'interfaces', 'src_mac_addr', 'src_ip_addr'];
  }

  getDevices(event?: LazyLoadEvent) {
    this.isLoading = true;
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.getDevicesRequest(page, perPage, this.dt?.datatable?.sortField as any, this.dt?.datatable?.sortOrder)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          if (!!this.targetDeviceSrv.selected) {
            this.targetDeviceSrv.selected = null;
          }
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          const devices = ((res?.devices as any[]) || []).map((device) => ({
            ...device,
            src_mac_addr: this.getAddress(device.eth),
            src_ip_addr: this.getAddress(device.ip, true),
            src_ble_addr: this.getAddress(device.ble),
          }));
          this.values = devices;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }
  getDevicesRequest(page?: number, perPage?: number, sortField?: string, sortOrder?: number) {
    const request: Observable<any> = this.targetDeviceSrv.getDevices({
      organizationId: this.breadcrumbConfig?.organizationId,
      projectId: this.breadcrumbConfig?.projectId,
      page,
      perPage,
      sort: sortField === 'connection_id' ? 'connection' : sortField,
      reverse: sortOrder ? sortOrder === 1 : true,
      detailed: true,
      zoneIds: [this.zone.id],
    });
    return request;
  }

  getAddress(value: any, isMultiple = false): any {
    if (!isMultiple) {
      return !!value ? Object.keys(value)?.[0] || '-' : '-';
    }
    const addresses = !!value ? Object.keys(value) : [];
    return {
      label: !!addresses.length ? Object.keys(value)?.[0] + (addresses.length > 1 ? '...' : '') : '-',
      tooltip: !!addresses.length ? addresses.join(', ') : '',
    };
  }
}
