import { ILayoutItem } from '@microsec/interfaces';
import { FormComponent } from './form/form.component';
import { Login2faComponent } from './login2fa/login2fa.component';

export const LOGIN_CONSTANTS = {
  LOGIN_2FA: {
    ROUTE: '2fa',
    LABEL: '2FA',
  },
};

export const LOGIN_CHILDREN: ILayoutItem[] = [
  {
    route: '',
    label: 'Login',
    component: FormComponent,
  },
  {
    route: LOGIN_CONSTANTS.LOGIN_2FA.ROUTE,
    label: LOGIN_CONSTANTS.LOGIN_2FA.LABEL,
    component: Login2faComponent,
  },
];
