import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { NetworkDeviceService } from '@ids-services';

import { Observable, finalize } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export const FORM_PARAMS = {
  DEVICE_ID: 'device_id',
  CONFIG_ID: 'config_id',
  NAME: 'name',
  VLAN_ID: 'vlan_id',
  PRIORITY: 'priority',
  TAG: 'tag',
  DESCRIPTION: 'description',
  INTERFACE: 'interface',
  PORT_MEMBERS: 'port_members',
  PORT: 'port',
  TAGGED: 'tagged',
};

@Component({
  selector: 'app-vlan-form',
  templateUrl: './vlan-form.component.html',
  styleUrls: ['./vlan-form.component.scss'],
})
export class VlanFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  devices: any[] = [];

  deviceId: any = null;

  configId: any = null;

  vlan: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('portMembersField') portMembersField!: TemplateRef<any>;

  portMembersTaggedOptions = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  FORM_PARAMS = FORM_PARAMS;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private networkDeviceSrv: NetworkDeviceService,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.devices = this.dialogConfig?.data?.devices;
    this.deviceId = this.dialogConfig?.data?.deviceId;
    this.configId = this.dialogConfig?.data?.configId;
    this.vlan = this.dialogConfig?.data?.vlan;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DEVICE_ID,
        label: 'Device',
        field: 'dropdown',
        options: ((this.devices as any[]) || [])?.map((device) => ({
          value: device.id,
          label: device.label,
        })),
        placeholder: 'Select a device',
        required: true,
        fieldInfo: 'Device of the VLAN',
        defaultValue: this.deviceId || null,
        focused: !this.deviceId,
        hidden: !!this.deviceId || !!this.vlan,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONFIG_ID,
        defaultValue: this.configId || null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VLAN_ID,
        label: 'VLAN ID',
        field: !!this.vlan ? 'label' : 'number',
        required: true,
        fieldInfo: 'Custom VLAN ID',
        defaultValue: null,
        focused: !!this.vlan,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: true,
        fieldInfo: 'Name of the VLAN',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        inputBlockSpace: true,
        pattern: /^\S*$/,
        patternErrorText: 'Invalid Description. Ensure that there are no whitespaces entered.',
        fieldInfo: 'Description of the VLAN',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.INTERFACE,
        label: 'Interface',
        required: true,
        fieldInfo: 'Interface of the VLAN',
        defaultValue: '',
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.DEVICE_ID, (value) => {
        const configId = this.devices.find((device) => device.id === value)?.configs?.[0]?.id || null;
        this.form.setControlValue(FORM_PARAMS.CONFIG_ID, configId);
      });
      if (!!this.vlan) {
        this.form.patchValue(this.vlan);
      }
    });
  }

  /**
   * Create/Edit the project and its profile
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const payload = { ...this.form.getRawValue() };
    if (!!this.vlan) {
      delete payload[FORM_PARAMS.DEVICE_ID];
      delete payload[FORM_PARAMS.CONFIG_ID];
      delete payload[FORM_PARAMS.VLAN_ID];
    }
    Object.keys(payload).forEach((key) => {
      payload[key] = payload[key] || undefined;
    });
    const request: Observable<any> = !this.vlan
      ? this.networkDeviceSrv.createDeviceVlan(payload)
      : this.networkDeviceSrv.updateDeviceVlan(this.vlan.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.vlan ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'VLAN'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  addPortMembers() {
    this.portMembersFormArray?.push(
      this.fb.group({
        [FORM_PARAMS.PORT]: [''],
        [FORM_PARAMS.TAGGED]: false,
      }),
    );
  }

  removePortMembers(index: number) {
    this.portMembersFormArray?.removeAt(index);
  }

  get portMembersFormArray() {
    return this.form?.form?.get(FORM_PARAMS.PORT_MEMBERS) as FormArray;
  }
}
