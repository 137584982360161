import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { AssessmentService } from '@ids-services';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, concat, finalize, forkJoin } from 'rxjs';

import { QuestionsFormComponent } from './questions-form/questions-form.component';
import { AssessmentQuestionFormComponent } from '../../assessment-questions/assessment-question-form/assessment-question-form.component';
import { DELETE_LABEL } from '@microsec/constants';

export const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  POSITION: 'position',
  ASSIGNED_QUESTIONS: 'assigned_questions',
  QUESTION_ID: 'question_id',
  MANDATORY: 'mandatory',
};

@Component({
  selector: 'app-assessment-type-creator',
  templateUrl: './assessment-type-creator.component.html',
  styleUrls: ['./assessment-type-creator.component.scss'],
})
export class AssessmentTypeCreatorComponent extends BaseComponent implements OnInit {
  isLoading = false;

  isPreview = false;

  activeIndexSidebarAccordion: number[] = [];

  assessmentType: any = null;

  sortedSections: any[] = [];

  selectedSectionIndex: any = null;

  selectedQuestionIndex: any = null;

  excludedQuestionIds: any[] = [];

  deleteMenuOptions: MenuItem[] = [
    {
      label: 'Delete Section',
      command: () => this.openDeleteSectionConfirmation(),
    },
    {
      label: 'Delete Question',
      command: () => this.openDeleteQuestionConfirmation(),
    },
  ];

  questionMenuOptions: MenuItem[] = [
    {
      label: 'New Question',
      command: () => this.openAssessmentQuestionForm(),
    },
    {
      label: 'Import from Question Bank',
      command: () => this.openAddQuestionsForm(),
    },
  ];

  headerTabMenu: MenuItem[] = [
    { id: 'sections', label: 'Edit Layout' },
    { id: 'general', label: 'General' },
  ];

  activeHeaderTabMenu: MenuItem = this.headerTabMenu[0];

  FORM_PARAMS = FORM_PARAMS;

  draggedSectionId = null;

  draggedQuestionId = null;

  connectedList: string[] = [];

  constructor(
    private assessmentSrv: AssessmentService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.prepareConfigs();
    this.assessmentType = this.dialogConfig?.data?.assessmentType;
    this.setData();
  }

  getAssessmentType() {
    this.isLoading = true;
    this.assessmentSrv
      .getAssessmentType(this.assessmentType.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.setData();
        }),
      )
      .subscribe({
        next: (res) => {
          this.assessmentType = res;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  setData() {
    const excludedQuestionIds: any[] = [];
    const sortedSections: any[] = this.util.sortObjectArray(this.assessmentType?.sections || [], 'position').map((section) => ({
      ...section,
      [FORM_PARAMS.ASSIGNED_QUESTIONS]: this.util.sortObjectArray(section[FORM_PARAMS.ASSIGNED_QUESTIONS] || [], 'position').map((question) => {
        excludedQuestionIds.push(question.question.id);
        return {
          ...question,
          descriptionPreview: this.util.parseMarkdown(question.question.description),
          [FORM_PARAMS.QUESTION_ID]: question.question.id,
        };
      }),
    }));
    this.excludedQuestionIds = excludedQuestionIds;
    this.sortedSections = sortedSections;
    // this.setConnectedList();
    if (!this.selectedSection) {
      this.onSelectSection(0);
    } else if (!this.selectedQuestion) {
      this.selectedQuestionIndex = !!this.selectedSection?.[FORM_PARAMS.ASSIGNED_QUESTIONS]?.length ? 0 : null;
    }
  }

  addSection() {
    if (!!Array.isArray(this.sortedSections)) {
      this.sortedSections = [
        ...this.sortedSections,
        {
          [FORM_PARAMS.NAME]: '',
          [FORM_PARAMS.DESCRIPTION]: '',
          [FORM_PARAMS.POSITION]: this.sortedSections.length + 1,
          [FORM_PARAMS.ASSIGNED_QUESTIONS]: [],
        },
      ];
      this.onSelectSection(this.sortedSections.length - 1);
    }
  }

  onSelectSection(index: number) {
    if (!this.activeIndexSidebarAccordion.filter((v) => v === index)?.length) {
      this.activeIndexSidebarAccordion = [index];
    } else {
      this.activeIndexSidebarAccordion = [];
    }
    if (this.sortedSections[index]) {
      this.selectedSectionIndex = index;
      this.selectedQuestionIndex = !!this.selectedSection?.[FORM_PARAMS.ASSIGNED_QUESTIONS]?.length ? 0 : null;
    } else {
      this.selectedSectionIndex = null;
      this.selectedQuestionIndex = null;
    }
  }

  onSelectQuestion(sectionIndex: number, questionIndex: number) {
    this.selectedSectionIndex = sectionIndex;
    this.selectedQuestionIndex = questionIndex;
  }

  openAssessmentQuestionForm(question?: any) {
    if (this.selectedSection) {
      const dialog = this.dialogSrv.open(AssessmentQuestionFormComponent, {
        data: { question: question || null },
        header: `${!question ? 'Create' : 'Edit'} Assessment Question`,
        width: '900px',
        height: 'min-content',
        closeOnEscape: true,
      });
      dialog.onClose.subscribe((rs) => {
        if (!!rs) {
          if (!!question) {
            this.getAssessmentType();
          } else {
            const newQuestion = {
              question: rs,
              descriptionPreview: this.util.parseMarkdown(rs.description),
              [FORM_PARAMS.QUESTION_ID]: rs.id,
              [FORM_PARAMS.MANDATORY]: false,
            };
            this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS] = [...(this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS] || []), newQuestion];
            this.updateSection(FORM_PARAMS.ASSIGNED_QUESTIONS, true);
          }
        }
      });
    }
  }

  openAddQuestionsForm() {
    if (this.selectedSection) {
      const dialog = this.dialogSrv.open(QuestionsFormComponent, {
        data: {
          excludedQuestionIds: this.excludedQuestionIds || [],
        },
        header: 'Question Bank',
        width: '900px',
        height: 'min-content',
        closeOnEscape: true,
      });
      dialog.onClose.subscribe((rs) => {
        if (!!rs) {
          const selectedQuestions = ((rs as any[]) || []).map((question) => ({
            question: question,
            descriptionPreview: this.util.parseMarkdown(question.description),
            [FORM_PARAMS.QUESTION_ID]: question.id,
            [FORM_PARAMS.MANDATORY]: false,
          }));
          this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS] = [
            ...(this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS] || []),
            ...selectedQuestions,
          ];
          this.updateSection(FORM_PARAMS.ASSIGNED_QUESTIONS);
        }
      });
    }
  }

  onUpdateSectionName() {
    if (this.selectedSection) {
      this.selectedSection.nameError = !this.selectedSection[FORM_PARAMS.NAME];
      if (!!this.selectedSection[FORM_PARAMS.NAME]) {
        this.updateSection(FORM_PARAMS.NAME, true);
      }
    }
  }

  updateSection(formName: string, showToast = false, isDeleteQuestion = false) {
    if (this.selectedSection) {
      this.isLoading = true;
      const payload = !this.selectedSection.id
        ? {
            [FORM_PARAMS.NAME]: this.selectedSection[FORM_PARAMS.NAME],
            [FORM_PARAMS.DESCRIPTION]: this.selectedSection[FORM_PARAMS.DESCRIPTION],
            [FORM_PARAMS.POSITION]: this.selectedSection[FORM_PARAMS.POSITION],
            [FORM_PARAMS.ASSIGNED_QUESTIONS]: this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS],
          }
        : {
            [formName]:
              formName === FORM_PARAMS.ASSIGNED_QUESTIONS
                ? ((this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS] as any[]) || []).map((question, questionIndex) => ({
                    [FORM_PARAMS.QUESTION_ID]: question[FORM_PARAMS.QUESTION_ID],
                    [FORM_PARAMS.POSITION]: questionIndex + 1,
                    [FORM_PARAMS.MANDATORY]: question[FORM_PARAMS.MANDATORY],
                  }))
                : this.selectedSection[formName],
          };
      const request: Observable<any> = !this.selectedSection.id
        ? this.assessmentSrv.createAssessmentTypeSection(this.assessmentType.id, payload)
        : this.assessmentSrv.updateAssessmentTypeSection(this.assessmentType.id, this.selectedSection.id, payload);
      request
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.getAssessmentType();
            if (!!showToast) {
              this.showSuccessMessage(!!isDeleteQuestion ? `Deleted Section's Question Successfully` : 'Updated Section Successfully');
            }
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  updateSectionPosition() {
    const requests: Observable<any>[] = [];
    (this.sortedSections || []).forEach((section, sectionIndex) => {
      if (!!section.id) {
        const payload = {
          [FORM_PARAMS.POSITION]: sectionIndex + 1,
        };
        requests.push(this.assessmentSrv.updateAssessmentTypeSection(this.assessmentType.id, section.id, payload));
      }
    });
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.getAssessmentType();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  updateQuestionPosition(currentSectionId: any, targetSectionIds: any = null) {
    const requests: { [key: string]: Observable<any> } = {};
    const sections = (this.sortedSections || []).filter((section) => currentSectionId === section.id || targetSectionIds === section.id);
    sections.forEach((section) => {
      if (!!section.id) {
        const payload = {
          [FORM_PARAMS.ASSIGNED_QUESTIONS]:
            section[FORM_PARAMS.ASSIGNED_QUESTIONS]?.map((assignedQuestion: { [key: string]: any }, assignedQuestionIndex: number) => ({
              [FORM_PARAMS.QUESTION_ID]: assignedQuestion[FORM_PARAMS.QUESTION_ID],
              [FORM_PARAMS.POSITION]: assignedQuestionIndex + 1,
              [FORM_PARAMS.MANDATORY]: assignedQuestion[FORM_PARAMS.MANDATORY],
            })) || [],
        };
        requests[section.id] = this.assessmentSrv.updateAssessmentTypeSection(this.assessmentType.id, section.id, payload);
      }
    });
    const request = targetSectionIds !== null ? concat(requests[currentSectionId], requests[targetSectionIds]) : requests[currentSectionId];
    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.getAssessmentType();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  openChangePositionInfo() {
    this.confirm({
      action: 'Change',
      object: 'Position',
      customContent: `To change the position of sections or questions, drag and drop the sections or questions to the position you prefer on the Assessment Layout Panel (Left Panel).`,
      next: () => {
        /** Nothing */
      },
    });
  }

  openDeleteSectionConfirmation() {
    if (this.selectedSection) {
      this.confirm({
        action: DELETE_LABEL,
        objectName: 'Section',
        object: this.selectedSection,
        objectFieldName: FORM_PARAMS.NAME,
        next: () => {
          if (!!this.selectedSection.id) {
            this.assessmentSrv
              .deleteAssessmentTypeSection(this.assessmentType.id, this.selectedSection.id)
              .pipe(
                finalize(() => {
                  this.isLoading = false;
                }),
              )
              .subscribe({
                next: () => {
                  this.getAssessmentType();
                  this.showSuccessMessage(`Deleted Assessment Type Section Successfully`);
                },
                error: (error) => {
                  this.showErrorMessage(error);
                },
              });
          } else {
            this.sortedSections.pop();
          }
        },
      });
    }
  }

  openDeleteQuestionConfirmation() {
    if (this.selectedSection) {
      this.confirm({
        action: DELETE_LABEL,
        objectName: 'Question',
        object: this.selectedQuestion,
        objectFieldNameFunction: (selectedQuestion) =>
          `${selectedQuestion.question?.title || selectedQuestion.question?.id || selectedQuestion[FORM_PARAMS.QUESTION_ID]} from section ${
            this.selectedSection[FORM_PARAMS.NAME]
          }`,
        next: () => {
          const assignedQuestions = this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS].filter(
            (question: any) => question[FORM_PARAMS.QUESTION_ID] !== this.selectedQuestion[FORM_PARAMS.QUESTION_ID],
          );
          this.selectedSection[FORM_PARAMS.ASSIGNED_QUESTIONS] = assignedQuestions;
          this.updateSection(FORM_PARAMS.ASSIGNED_QUESTIONS, true, true);
        },
      });
    }
  }

  onDragSectionStarted(index: number) {
    this.activeIndexSidebarAccordion = this.activeIndexSidebarAccordion.filter((v) => v !== index);
  }

  onDragQuestionStarted(sectionId: any) {
    this.draggedSectionId = sectionId;
  }

  getConnectedList() {
    const connectedList: string[] = [];
    this.sortedSections?.forEach((section) => {
      connectedList.push(`section_${section.id}`);
    });
    return connectedList;
  }

  dropQuestion(event: CdkDragDrop<string[]>, sectionId: any) {
    const draggedSectionIndex = this.sortedSections.findIndex((section) => section.id === this.draggedSectionId);
    const sectionIndex = this.sortedSections.findIndex((section) => section.id === sectionId);
    const activeIndex = [draggedSectionIndex];
    if (this.draggedSectionId === sectionId) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.updateQuestionPosition(this.draggedSectionId);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.updateQuestionPosition(this.draggedSectionId, sectionId);
      activeIndex.push(sectionIndex);
    }
    this.activeIndexSidebarAccordion = activeIndex;
    this.onSelectQuestion(sectionIndex, event.currentIndex);
  }

  dropSection(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.sortedSections, event.previousIndex, event.currentIndex);
    if (this.sortedSections[event.currentIndex]) {
      this.activeIndexSidebarAccordion = [event.currentIndex];
      this.selectedSectionIndex = event.currentIndex;
      this.selectedQuestionIndex = !!this.selectedSection?.[FORM_PARAMS.ASSIGNED_QUESTIONS]?.length ? 0 : null;
    } else {
      this.activeIndexSidebarAccordion = [];
      this.selectedSectionIndex = null;
      this.selectedQuestionIndex = null;
    }
    this.updateSectionPosition();
  }

  setPosition(arr: any[]) {
    const sortedArr = (arr || [])?.map((item, index) => ({
      ...item,
      [FORM_PARAMS.POSITION]: index + 1,
    }));
    arr = sortedArr;
  }

  get selectedSection() {
    return this.sortedSections?.[this.selectedSectionIndex];
  }

  get selectedQuestion() {
    return this.selectedSection?.[FORM_PARAMS.ASSIGNED_QUESTIONS]?.[this.selectedQuestionIndex];
  }

  get isValid() {
    return (
      !!this.sortedSections?.length &&
      !!this.assessmentType?.sections?.length &&
      this.sortedSections.length === this.assessmentType.sections.length &&
      this.sortedSections.every((section) => !!section[FORM_PARAMS.ASSIGNED_QUESTIONS]?.length) &&
      this.assessmentType.sections.every((section: any) => !!section[FORM_PARAMS.ASSIGNED_QUESTIONS]?.length)
    );
  }
}
