import { ILayoutItem } from '@microsec/interfaces';

import { DashboardDevicesComponent } from './dashboard-devices/dashboard-devices.component';
import { DashboardThreatsComponent } from './dashboard-threats/dashboard-threats.component';
import { DashboardComplianceComponent } from './dashboard-compliance/dashboard-compliance.component';
import { DEVICE_FEATURES, FEATURES, THREAT_FEATURES } from '@ids-constants';

export const DASHBOARD_CONSTANTS = {
  DEVICES: {
    ROUTE: 'devices',
    LABEL: 'Devices',
  },
  THREATS: {
    ROUTE: 'threats',
    LABEL: 'Threats',
  },
  COMPLIANCE: {
    ROUTE: 'compliance',
    LABEL: 'Compliance',
  },
};

export const DASHBOARD_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: DASHBOARD_CONSTANTS.DEVICES.ROUTE,
  },
  {
    route: DASHBOARD_CONSTANTS.DEVICES.ROUTE,
    label: DASHBOARD_CONSTANTS.DEVICES.LABEL,
    component: DashboardDevicesComponent,
    visible: false,
    featureName: FEATURES.DEVICES,
    featureCheckField: DEVICE_FEATURES.ENABLED,
  },
  {
    route: DASHBOARD_CONSTANTS.THREATS.ROUTE,
    label: DASHBOARD_CONSTANTS.THREATS.LABEL,
    component: DashboardThreatsComponent,
    visible: false,
    featureName: FEATURES.THREATS,
    featureCheckField: THREAT_FEATURES.ENABLED,
  },
  {
    route: DASHBOARD_CONSTANTS.COMPLIANCE.ROUTE,
    label: DASHBOARD_CONSTANTS.COMPLIANCE.LABEL,
    component: DashboardComplianceComponent,
  },
];
