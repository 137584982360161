/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, Observable, scheduled } from 'rxjs';

import { IHybridMonitorService } from './IHybridMonitorService';

@Injectable({
  providedIn: 'root',
})
export class MockHybridMonitorService implements IHybridMonitorService {
  startActiveScan(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getActiveScan(status?: any, projectId?: any, id?: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getCapabilities(): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
