import { Component } from '@angular/core';

@Component({
  selector: 'app-project-snort-settings',
  templateUrl: './project-snort-settings.component.html',
  styleUrls: ['./project-snort-settings.component.scss'],
})
export class ProjectSnortSettingsComponent {
  constructor() {}
}
