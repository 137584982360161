import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { TAGS_PRESET_COLORS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { TargetDeviceService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  LABEL: 'label',
  DESCRIPTION: 'description',
  COLOR: 'color',
};

@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss'],
})
export class TagFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  color = '#0277FF';

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  tag: any = null;

  FORM_PARAMS = FORM_PARAMS;

  TAGS_PRESET_COLORS = TAGS_PRESET_COLORS;

  @ViewChild('colorField') colorField!: TemplateRef<any>;

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.tag = this.dialogConfig?.data?.tag;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_ID,
        defaultValue: this.breadcrumbConfig?.organizationId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        defaultValue: this.breadcrumbConfig?.projectId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Name',
        required: true,
        fieldInfo: 'Name of the tag',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the tag',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COLOR,
        label: 'Color',
        field: 'custom',
        customField: this.colorField,
        fieldInfo: 'Color of the tag',
        defaultValue: '',
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    if (!!this.tag) {
      this.form.patchValue(this.tag);
      this.color = this.tag[FORM_PARAMS.COLOR] || '#0277FF';
    }
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const payload = { ...this.form.getRawValue(), [FORM_PARAMS.COLOR]: this.color };
    const request: Observable<any> = !this.tag ? this.targetDeviceSrv.createTag(payload) : this.targetDeviceSrv.updateTag(this.tag.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.tag ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'tag'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
