<div class="grid">
  <!-- Table Toolbar: Button? Search, Filter, Clear -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!hasPermission ? leftTemplate : null"></microsec-common-toolbar>
    <ng-template #leftTemplate>
      <p-button severity="primary" [label]="'Assign User to ' + moduleName" [rounded]="true" icon="pi pi-plus" (onClick)="openUserAccessForm()" />
    </ng-template>
  </div>

  <!-- Data table -->
  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="User"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getUsers()"></microsec-refresh-button>
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()">
    </microsec-table-column-filter>
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Role -->
    <ng-container *ngSwitchCase="'role'">
      <div class="form dark-form no-wrapper">
        @if (!rowData.is_superuser) {
          <p-dropdown
            [options]="roles"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
            [(ngModel)]="rowData.roleId"
            [disabled]="currentScope === 'project' && !!rowData.roles?.organization?.id"></p-dropdown>
        } @else {
          <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">System Admin</div>
        }
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
