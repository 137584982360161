import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ORGANIZATION_MANAGEMENT_CONSTANTS, SYSTEM_SETTINGS_CONSTANTS } from '@ids-constants';
import { AnomalyAnalyzerService } from '@ids-services';
import { FormBuilderComponent } from '@microsec/components';
import { CANCEL_LABEL, MODULE_CONSTANTS, ORGANIZATION_ID_PARAM_ROUTE, SAVE_CHANGES_LABEL, SCOPE } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { Observable, finalize } from 'rxjs';

export const FORM_PARAMS = {
  SCOPE: 'scope',
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  OINKCODE: 'oinkcode',
  IS_VALIDATED: 'is_validated',
  OINKCODE_TYPE: 'oinkcodeType',
};

export const OINKCODE_TYPES = {
  CUSTOM: 'custom',
  INHERITED: 'inherited',
};

export const OINKCODE_TYPE_OPTIONS = [
  { label: 'Use Custom Settings', value: OINKCODE_TYPES.CUSTOM },
  { label: 'Use Inherited Settings', value: OINKCODE_TYPES.INHERITED },
];

export const SNORT_OINKCODE_DOCUMENTATION_URL = 'https://snort.org/documents/how-to-find-and-use-your-oinkcode';

@Component({
  selector: 'app-shared-snort-settings',
  templateUrl: './shared-snort-settings.component.html',
  styleUrls: ['./shared-snort-settings.component.scss'],
})
export class SharedSnortSettingsComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('headerTemplate') headerTemplate!: TemplateRef<any>;

  @ViewChild('oinkcodeTypeTemplate') oinkcodeTypeTemplate!: TemplateRef<any>;

  @ViewChild('oinkcodeTemplate') oinkcodeTemplate!: TemplateRef<any>;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  CANCEL_LABEL = CANCEL_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  OINKCODE_TYPES = OINKCODE_TYPES;

  OINKCODE_TYPE_OPTIONS = OINKCODE_TYPE_OPTIONS;

  oinkcodes: { [key: string]: any } = {
    [SCOPE.GLOBAL]: null,
    [SCOPE.ORGANIZATION]: null,
    [SCOPE.PROJECT]: null,
  };

  scope: string = '';

  existedParentScope: string = '';

  constructor(private anomalyAnalyzerSrv: AnomalyAnalyzerService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.scope = !!this.breadcrumbConfig?.organizationId ? (!!this.breadcrumbConfig?.projectId ? SCOPE.PROJECT : SCOPE.ORGANIZATION) : SCOPE.GLOBAL;
    this.getOinkcodes(true);
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SCOPE,
        defaultValue: this.scope,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_ID,
        defaultValue: this.breadcrumbConfig?.organizationId || undefined,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        defaultValue: this.breadcrumbConfig?.projectId || undefined,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        hasNoLabel: true,
        field: 'custom',
        customField: this.headerTemplate,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.OINKCODE_TYPE,
        hasNoLabel: true,
        field: 'custom',
        customField: this.oinkcodeTypeTemplate,
        defaultValue: this.oinkcodes[this.scope]?.[FORM_PARAMS.OINKCODE] ? OINKCODE_TYPES.CUSTOM : OINKCODE_TYPES.INHERITED,
        hidden: this.scope === SCOPE.GLOBAL,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
        hidden: this.scope === SCOPE.GLOBAL,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.OINKCODE,
        hasNoLabel: true,
        field: 'custom',
        customField: this.oinkcodeTemplate,
        defaultValue: this.oinkcodes[this.scope]?.[FORM_PARAMS.OINKCODE] || this.oinkcodes[this.existedParentScope]?.[FORM_PARAMS.OINKCODE] || '',
        disabled: this.scope !== SCOPE.GLOBAL && !this.oinkcodes[this.scope]?.[FORM_PARAMS.OINKCODE],
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_VALIDATED,
        defaultValue: !!this.oinkcodes[this.scope]?.[FORM_PARAMS.IS_VALIDATED],
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.OINKCODE_TYPE, (value: string) => {
        if (value === OINKCODE_TYPES.CUSTOM) {
          this.form.getControl(FORM_PARAMS.OINKCODE)?.enable();
        } else {
          this.form.setControlValue(FORM_PARAMS.OINKCODE, this.oinkcodes[this.existedParentScope]?.[FORM_PARAMS.OINKCODE] || '');
          this.form.getControl(FORM_PARAMS.OINKCODE)?.disable();
        }
      });
    });
  }

  getOinkcodes(isInit = false) {
    this.form.isLoading = true;
    this.anomalyAnalyzerSrv
      .getOinkcodes()
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
          if (isInit) {
            this.initForm();
          } else {
            this.setFormValues();
          }
        }),
      )
      .subscribe({
        next: (rs) => {
          const oinkcodes = (rs.oinkcodes as any[]) || [];
          const globalOinkcode = oinkcodes.find((o) => o.scope === SCOPE.GLOBAL) || null;
          const orgOinkcodes =
            oinkcodes.find(
              (o) =>
                !!this.breadcrumbConfig?.organizationId &&
                o.scope === SCOPE.ORGANIZATION &&
                o.organization_id === this.breadcrumbConfig.organizationId,
            ) || null;
          const projOinkcodes =
            oinkcodes.find(
              (o) =>
                !!this.breadcrumbConfig?.organizationId &&
                !!this.breadcrumbConfig?.projectId &&
                o.scope === SCOPE.PROJECT &&
                o.organization_id === this.breadcrumbConfig.organizationId &&
                o.project_id === this.breadcrumbConfig.projectId,
            ) || null;

          this.oinkcodes = {
            [SCOPE.GLOBAL]: globalOinkcode,
            [SCOPE.ORGANIZATION]: orgOinkcodes,
            [SCOPE.PROJECT]: projOinkcodes,
          };

          this.existedParentScope =
            this.scope === SCOPE.GLOBAL
              ? ''
              : this.scope === SCOPE.ORGANIZATION
                ? !!this.oinkcodes[SCOPE.GLOBAL]
                  ? SCOPE.GLOBAL
                  : ''
                : !!this.oinkcodes[SCOPE.ORGANIZATION]
                  ? SCOPE.ORGANIZATION
                  : !!this.oinkcodes[SCOPE.GLOBAL]
                    ? SCOPE.GLOBAL
                    : '';
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  setFormValues() {
    this.form.setControlValue(
      FORM_PARAMS.OINKCODE_TYPE,
      this.oinkcodes[this.scope]?.[FORM_PARAMS.OINKCODE] ? OINKCODE_TYPES.CUSTOM : OINKCODE_TYPES.INHERITED,
    );
    this.form.setControlValue(
      FORM_PARAMS.OINKCODE,
      this.oinkcodes[this.scope]?.[FORM_PARAMS.OINKCODE] || this.oinkcodes[this.existedParentScope]?.[FORM_PARAMS.OINKCODE] || '',
    );
    this.form.setControlValue(FORM_PARAMS.IS_VALIDATED, !!this.oinkcodes[this.scope]?.[FORM_PARAMS.IS_VALIDATED]);
  }

  validateOinkcode() {
    this.form.isLoading = true;
    this.anomalyAnalyzerSrv
      .validateOinkcode(this.form.getControlValue(FORM_PARAMS.OINKCODE) || '')
      .pipe()
      .subscribe({
        next: (rs) => {
          this.onSubmit(!!rs.is_validated);
        },
        error: () => {
          this.onSubmit(false);
        },
      });
  }

  onSubmit(isValidated: boolean) {
    this.form.isLoading = true;
    const formValues = this.form.getRawValue();
    const payload = { ...formValues, [FORM_PARAMS.IS_VALIDATED]: !!isValidated };
    delete payload[FORM_PARAMS.OINKCODE_TYPE];
    const request: Observable<any> = !this.oinkcodes[this.scope]
      ? this.anomalyAnalyzerSrv.createOinkcode(payload)
      : this.anomalyAnalyzerSrv.updateOinkcode(this.oinkcodes[this.scope].id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Snort Settings Saved');
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  openSnortOinkcodeDocumentation() {
    window.open(SNORT_OINKCODE_DOCUMENTATION_URL, '_blank');
  }

  goToSnortSetting() {
    const route =
      this.scope === SCOPE.PROJECT
        ? `${MODULE_CONSTANTS.ORGANIZATION_MANAGEMENT.ROUTE.replace(
            ORGANIZATION_ID_PARAM_ROUTE,
            this.breadcrumbConfig?.organizationId?.toString(),
          )}/${ORGANIZATION_MANAGEMENT_CONSTANTS.SNORT_SETTINGS.ROUTE}`
        : `${MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE}/${SYSTEM_SETTINGS_CONSTANTS.SNORT_SETTINGS.ROUTE}`;
    this.changeRoute(route);
  }

  get oinkcodeFieldInfo() {
    return this.scope === SCOPE.GLOBAL
      ? 'This oinkcode will be accessible by all organizations and projects.'
      : this.form.getControlValue(FORM_PARAMS.OINKCODE_TYPE) === OINKCODE_TYPES.CUSTOM
        ? `Specify custom oinkcode for this ${this.scope}`
        : `${this.util.getUppercaseFirstLetter(
            this.existedParentScope || (this.scope === SCOPE.PROJECT ? SCOPE.ORGANIZATION : SCOPE.GLOBAL),
          )} level oinkcode used for this ${this.scope}`;
  }
}
