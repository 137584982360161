import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { LicenseService } from '@ids-services';
import { FormBuilderComponent } from '@microsec/components';
import { DOCUMENTATION_URL, DOMAIN_TOKEN, UPLOAD_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { VALIDATOR_TYPE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  OPTION: 'option',
  FILE: 'file',
  KEY: 'key',
  IS_AGREE: 'is_agree',
};

@Component({
  selector: 'app-license-form',
  templateUrl: './license-form.component.html',
  styleUrls: ['./license-form.component.scss'],
})
export class LicenseFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  FORM_PARAMS = FORM_PARAMS;

  UPLOAD_LABEL = UPLOAD_LABEL;

  @ViewChild('aggreementTemplate') aggreementTemplate!: TemplateRef<any>;

  constructor(
    private licenseSrv: LicenseService,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.OPTION,
        label: 'License Type',
        field: 'radio',
        required: true,
        defaultValue: 'file',
        fieldInfo: 'Type of the license',
        options: [
          { label: 'Upload License File', value: 'file' },
          { label: 'Enter License Key', value: 'key' },
        ],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE,
        label: 'License File',
        field: 'file',
        acceptedFileTypes: ['.license'],
        required: true,
        fieldInfo: 'File of the license',
        defaultValue: null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY,
        label: 'License Key',
        field: 'textarea',
        fieldInfo: 'Key of the license',
        defaultValue: '',
        maxInputLimit: null,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_AGREE,
        hasNoLabel: true,
        field: 'custom',
        customField: this.aggreementTemplate,
        defaultValue: false,
      } as FormItem),
    ];
    this.fields = fields;
    this.initUploadFile(FORM_PARAMS.FILE);
    setTimeout(() => {
      this.form.getControlValue(FORM_PARAMS.IS_AGREE);
      this.form?.setChangeEvent(FORM_PARAMS.OPTION, (value: string) => {
        this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.FILE, value === 'file' ? [VALIDATOR_TYPE.REQUIRED] : []);
        this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.KEY, value === 'key' ? [VALIDATOR_TYPE.REQUIRED] : []);
      });
    });
  }

  private initUploadFile(formNameFile: string) {
    const uploadFileField = this.fields.find((p) => p.name === formNameFile);
    if (!!uploadFileField) {
      uploadFileField.uploadEvent?.subscribe((event: any) => {
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          this.form.isLoading = true;
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.FILE, file);
          this.form.isLoading = false;
        }
      });
    }
  }

  /**
   * Create/Edit the project and its profile
   * @param closeDialog
   */
  onSubmit(closeDialog: (succeed?: any) => void) {
    this.form.isLoading = true;
    const formValue = { ...this.form.getRawValue() };
    let file;
    const fd = new FormData();
    if (formValue[FORM_PARAMS.OPTION] === 'file') {
      file = formValue[FORM_PARAMS.FILE];
    } else {
      const blob: any = new Blob([formValue[FORM_PARAMS.KEY]], {
        type: '',
      });
      blob.lastModifiedDate = new Date();
      blob.name = 'file.license';
      file = <File>blob;
    }
    fd.append('file', file);
    this.licenseSrv
      .uploadLicense(fd)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Uploaded License successfully');
          closeDialog(true);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  goToDocumentation() {
    const customDomain: any = localStorage.getItem('domain');
    window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
  }
}
