import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@ids-services';

import { catchError, Observable, throwError } from 'rxjs';

import { IHybridMonitorService } from './IHybridMonitorService';

@Injectable({
  providedIn: 'root',
})
export class HybridMonitorService implements IHybridMonitorService {
  constructor(private httpClient: HttpClient) {}
  startActiveScan(payload: any): Observable<any> {
    return this.httpClient.post(`${API.HYBRID_MONITOR}/active-scan`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getActiveScan(status?: any, projectId?: any, id?: any): Observable<any> {
    const params: string[] = [];
    if (status) {
      params.push(`status=${status}`);
    }
    if (projectId) {
      params.push(`project_id=${projectId}`);
    }
    if (id) {
      params.push(`id=${id}`);
    }
    return this.httpClient
      .get(`${API.HYBRID_MONITOR}/active-scan${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCapabilities(): Observable<any> {
    return this.httpClient.get(`${API.HYBRID_MONITOR}/capabilities`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
