import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { MODULE_CONSTANTS } from '@microsec/constants';
import { fromAuthenticationActions } from '@microsec/ngrx-authentication';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { LDAPSettingService, Setting2FAService } from '@ids-services';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { DOMAIN, SUCCESS_DOMAIN, VALIDATOR_TYPE } from '@microsec/constants';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SYSTEM_SETTINGS_CONSTANTS } from '@ids-constants';
import { FeatureService } from '@microsec/services';

const FORM_PARAMS = {
  UNSUBMITTED_LDAP_FIELD: 'unsubmitted_ldap_field',
  LDAP_DOMAIN: 'ldap_domain',
  UNSUBMITTED_RSA_FIELD: 'unsubmitted_rsa_field',
  USERNAME: 'username',
  PASSWORD: 'password',
  RSA_TOKEN: 'token_code',
  DOMAIN: 'domain',
  IS_LDAP: 'isLDAP',
};

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  isLoginLoading = false;

  isRSA = false;

  isLDAPSettingEnabled = false;

  FORM_PARAMS = FORM_PARAMS;

  @ViewChild('forgotPasswordTemplate') forgotPasswordTemplate!: TemplateRef<any>;

  @ViewChild('loginActionsTemplate') loginActionsTemplate!: TemplateRef<any>;

  constructor(
    private setting2FASrv: Setting2FAService,
    private ldapSettingSrv: LDAPSettingService,
    private featureSrv: FeatureService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.store.dispatch(new fromLayoutActions.SetUserInboxLoaded(false));
    this.initForm();
    this.checkLDAPSetting();
  }

  /**
   * Initialize form
   */
  initForm() {
    const usernameField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.USERNAME,
      label: 'Username',
      labelStyleClass: 'mt-1',
      field: 'input',
      required: true,
      focused: true,
    } as FormItem);
    const passwordField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.PASSWORD,
      label: 'Password',
      labelStyleClass: 'mt-1',
      field: 'password',
      feedback: false,
      afterFieldTemplate: this.forgotPasswordTemplate,
      required: true,
    } as FormItem);
    const domainField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.DOMAIN,
      label: 'Domain',
      labelStyleClass: 'mt-1',
      field: 'autocomplete',
      required: true,
    } as FormItem);
    const ldapDomainField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.LDAP_DOMAIN,
      label: 'Sign in from: ',
      field: 'text',
      hidden: true,
    } as FormItem);

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Login',
        field: 'text',
        labelStyleClass: 'title',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.UNSUBMITTED_LDAP_FIELD,
        label: 'For LDAP Login, please enter credentials as follows: username@subdomain or subdomain\\username',
        field: 'text',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.UNSUBMITTED_LDAP_FIELD,
        label: 'Example: john@example.usec.io or example.usec.io\\john',
        field: 'text',
        hidden: true,
      } as FormItem),
      usernameField,
      passwordField,
      ldapDomainField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.UNSUBMITTED_RSA_FIELD,
        label: 'For RSA SecurID Login, please select the respective option and enter the RSA SecurID Token which is shown in your registered device.',
        field: 'text',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RSA_TOKEN,
        label: 'RSA SecurID Token',
        labelStyleClass: 'mt-1',
        field: 'password',
        feedback: false,
        hidden: true,
      } as FormItem),
      domainField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_LDAP,
        field: 'custom',
        customField: this.loginActionsTemplate,
        defaultValue: false,
      } as FormItem),
    ];
    fields.forEach((field) => field.setFullSize());
    this.fields = fields;

    // Set the Domain from the local storage value to the login form
    let customDomain = localStorage.getItem(DOMAIN);
    customDomain = !!customDomain ? customDomain : window.location.host;
    this.form.patchValue({ [FORM_PARAMS.DOMAIN]: customDomain });
    localStorage.setItem(DOMAIN, customDomain);

    // Get the list of success domain
    const successDomainList = localStorage.getItem(SUCCESS_DOMAIN);
    domainField.suggestions = !!successDomainList ? [...JSON.parse(successDomainList)] : [];

    // Change event of username
    this.form.setChangeEvent(FORM_PARAMS.USERNAME, (value) => {
      this.getLDAPDomain(value, ldapDomainField);
    });

    // If the domain is changed, set the new value in local storage
    this.form.setChangeEvent(FORM_PARAMS.DOMAIN, (value) => {
      localStorage.setItem(DOMAIN, value);
    });

    // check RSA if LDAP enabled
    this.form.setChangeEvent(FORM_PARAMS.IS_LDAP, (value) => {
      this.form.setControlVisibility(FORM_PARAMS.UNSUBMITTED_LDAP_FIELD, !!value);
      this.form.setControlVisibility(FORM_PARAMS.LDAP_DOMAIN, !!value);
      this.getLDAPDomain(this.form.getControlValue(FORM_PARAMS.USERNAME), ldapDomainField);
      usernameField.label = `${!!value ? 'LDAP' : ''} Username`;
      passwordField.label = `${!!value ? 'LDAP' : ''} Password`;
      passwordField.afterFieldTemplate = !!value ? null : this.forgotPasswordTemplate;
      if (!!value) {
        this.checkRSA();
      } else {
        this.isRSA = false;
        this.form.setControlVisibility(FORM_PARAMS.UNSUBMITTED_RSA_FIELD, false);
        this.form.setControlValidatorsAndVisibility(FORM_PARAMS.RSA_TOKEN, []);
      }
    });
  }

  /**
   * Get LDAP domain
   * @param value
   * @param ldapDomainField
   */
  getLDAPDomain(value: any, ldapDomainField: FormItem) {
    let ldapDomain = '';
    const trimedValue = ((value || '') as string).trim();
    let windowUsername = trimedValue.split('\\');
    if (windowUsername.length > 1) {
      ldapDomain = windowUsername[0];
    } else {
      windowUsername = trimedValue.split('@');
      if (windowUsername.length > 1) {
        ldapDomain = windowUsername[windowUsername.length - 1];
      }
    }
    ldapDomainField.label = `Sign in from: ${ldapDomain}`;
    this.form.setControlVisibility(FORM_PARAMS.LDAP_DOMAIN, !!this.form.getControlValue(FORM_PARAMS.IS_LDAP) && !!ldapDomain);
  }

  /**
   * Login
   */
  login() {
    this.store.dispatch(
      new fromAuthenticationActions.SetTemporaryCredential(
        this.form.getControlValue(FORM_PARAMS.USERNAME),
        this.form.getControlValue(FORM_PARAMS.PASSWORD),
      ),
    );
    if (!!this.form.getControlValue(FORM_PARAMS.IS_LDAP)) {
      this.loginWithout2FA(null, true, !!this.isRSA ? this.form.getControlValue(FORM_PARAMS.RSA_TOKEN) : null);
    } else {
      // Dashboard login
      this.isLoginLoading = true;
      forkJoin([
        this.setting2FASrv.getOpenGlobalSetting(),
        this.setting2FASrv.getOpenUserSetting(this.form.getControlValue(FORM_PARAMS.USERNAME), this.form.getControlValue(FORM_PARAMS.PASSWORD)),
      ])
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
            this.isLoginLoading = false;
          }),
        )
        .subscribe({
          next: (rs: any[]) => {
            // 0: globalSetting, 1: userSetting
            if (rs[0].auth_provider === 'google_auth' && rs[0].user_login && rs[1].status) {
              this.router.navigate(['/login/2fa']);
            } else {
              this.loginWithout2FA();
            }
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        });
    }
  }

  /**
   * Login without 2FA
   * @param totpCode
   * @param isLDAPLogin
   * @param token_code
   */
  loginWithout2FA(totpCode: string | null = null, isLDAPLogin?: boolean, token_code?: string) {
    this.isLoginLoading = true;
    forkJoin({ features: this.featureSrv.getFeatures(), login: this.authSrv.login(totpCode as string, isLDAPLogin, token_code) })
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
          this.isLoginLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.form.disable();
          this.clearMessages();
          this.showSuccessMessage('Logged in successfully!');

          const currentDomain = this.form.getControlValue(FORM_PARAMS.DOMAIN);
          const domainField = this.fields.find((p) => p.name === this.FORM_PARAMS.DOMAIN);
          if (!!domainField && !domainField.suggestions.includes(currentDomain)) {
            domainField.suggestions.push(currentDomain);
            localStorage.setItem(SUCCESS_DOMAIN, JSON.stringify(domainField.suggestions));
          }
          if (!this.hasLicense(res.features) && !!res.login?.superuser) {
            this.router.navigate([`/${MODULE_CONSTANTS.GLOBAL_SETTINGS.ROUTE}/${SYSTEM_SETTINGS_CONSTANTS.LICENSES.ROUTE}`]);
          } else {
            this.router.navigate([`/${MODULE_CONSTANTS.REDIRECTION.ROUTE}`]);
          }
        },
        error: (err: any) => {
          this.clearMessages();
          if (err?.status === 0) {
            this.showErrorMessage('Invalid Domain Name');
          } else {
            this.showErrorMessage(err);
          }
        },
      });
  }

  /**
   * Check if RSA enabled?
   */
  checkRSA() {
    // call check RSA
    this.form.isLoading = true;
    this.authSrv
      .checkRSALogin()
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (isRSANeeded: any) => {
          this.isRSA = isRSANeeded;
          this.form.setControlVisibility(FORM_PARAMS.UNSUBMITTED_RSA_FIELD, !!this.isRSA);
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.RSA_TOKEN, !!this.isRSA ? [VALIDATOR_TYPE.REQUIRED] : []);
        },
        error: (err: any) => {
          this.isRSA = false;
          this.form.setControlVisibility(FORM_PARAMS.UNSUBMITTED_RSA_FIELD, false);
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.RSA_TOKEN, []);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Show the message to the current user if forgot password
   */
  showForgotPasswordInfo() {
    this.showInfoMessage('Please get in contact with your system administrator to reset your password.');
  }

  /**
   * Check LDAP setting
   */
  checkLDAPSetting() {
    if (!!this.isLDAPFeatured) {
      this.ldapSettingSrv.getLDAPSetting().subscribe({
        next: (data) => {
          this.isLDAPSettingEnabled = data.is_enabled;
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    }
  }

  /**
   * Check if the application has license
   */
  hasLicense(features: any) {
    let result = false;
    if (!!features) {
      Object.values(features).forEach((featureTypeValue: any) => {
        result ||= !!Object.values(featureTypeValue).find((p) => !!p);
      });
    } else {
      result = false;
    }
    return result;
  }

  /**
   * Check LDAP is featured
   */
  get isLDAPFeatured() {
    return false;
  }
}
