@if (form.visible) {
  <div class="h-full overflow-y-auto overflow-x-hidden">
    <app-shared-network-form
      [pageTitle]="form.connection ? 'Edit Network' : 'Create Network'"
      [connection]="form.connection"
      (closeEvent)="closeConnectionForm()" />
  </div>
} @else {
  <microsec-common-layout [preContentTemplate]="licenseWarningTemplate">
    <app-network-list (openConnectionFormEvent)="openConnectionForm($event)" />
  </microsec-common-layout>
  <ng-template #licenseWarningTemplate>
    <app-shared-license-warning />
  </ng-template>
}
