import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@ids-shared';

import { UserAccessFormComponent } from './user-access-form/user-access-form.component';
import { SharedUserAccessComponent } from './shared-user-access.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedUserAccessComponent, UserAccessFormComponent],
  exports: [SharedUserAccessComponent, UserAccessFormComponent],
})
export class SharedUserAccessModule {
  static forRoot(): ModuleWithProviders<SharedUserAccessModule> {
    return {
      ngModule: SharedUserAccessModule,
      providers: [],
    };
  }
}
