import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, CommonTableComponent } from '@microsec/components';
import { NetworkDeviceService } from '@ids-services';
import { verticalSlideAnimation } from '@microsec/animations';
import { ArrayToPlaceholderStringPipe, ConstantPipe } from '@ids-pipes';
import { ReportSpecification, CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem } from '@microsec/models';

import { BehaviorSubject, finalize, forkJoin, Observable } from 'rxjs';

import { FirewallFormComponent } from './firewall-form/firewall-form.component';
import { SharedTestConfigurationDialogComponent } from '../shared-test-configuration-dialog/shared-test-configuration-dialog.component';
import { DELETE_LABEL } from '@microsec/constants';

const FIELDS = {
  id: 'ID',
  description: 'Description',
  interface: 'Interface',
  ip_protocol: 'Protocol',
  ip_version: 'Version',
  chain: 'Chain',
  target: 'Target',
  source_address: 'Source',
  source_port: 'Source Port',
  destination_address: 'Destination',
  destination_port: 'Dest Port',
};

@Component({
  selector: 'app-shared-firewalls',
  templateUrl: './shared-firewalls.component.html',
  styleUrls: ['./shared-firewalls.component.scss'],
  providers: [ConstantPipe, ArrayToPlaceholderStringPipe],
  animations: [verticalSlideAnimation],
})
export class SharedFirewallsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  values: any[] = [];

  cols: any[] = [
    { field: 'id', header: 'ID', width: 6 },
    { field: 'description', header: 'Description', width: 10 },
    { field: 'interface', header: 'Interface', width: 10 },
    { field: 'ip_protocol', header: 'Protocol', width: 10 },
    { field: 'ip_version', header: 'Version', width: 10 },
    { field: 'chain', header: 'Chain', width: 10 },
    { field: 'target', header: 'Target', width: 10 },
    { field: 'source_address', header: 'Source', width: 10 },
    { field: 'source_port', header: 'Source Port', width: 10 },
    { field: 'destination_address', header: 'Destination', width: 10 },
    { field: 'destination_port', header: 'Dest Port', width: 10 },
  ];

  globalFilterFields: string[] = [...this.cols.map((col) => col.field)];

  selectedFirewalls: any[] = [];

  _device: any = null;

  get device() {
    return this._device;
  }

  @Input() set device(value: any) {
    this._device = value;
    if (!!this._device) {
      this.isLoading = false;
      this.setData([this._device]);
    }
  }

  devices: any[] = [];

  @ViewChild('dt') dt!: CommonTableComponent;

  @ViewChild('testConfigurationDialog') testConfigurationDialog!: SharedTestConfigurationDialogComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform([...this.globalFilterFields.map((field) => field.replace(/ip_/g, 'IP_'))]),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private constantPipe: ConstantPipe,
    private networkDeviceSrv: NetworkDeviceService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openFirewallForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.handleFilterObjUpdate();
    if (!this.device) {
      this.getDeviceFirewalls();
    }
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.networkDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !this.device) {
        this.getDeviceFirewalls();
      }
    });
    this.subscriptions.push(subscription);
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result?.search || '';
      }
    });
  }

  getDeviceFirewalls() {
    this.isLoading = true;
    const request: Observable<any> = !!this.device
      ? this.networkDeviceSrv.getDevice(this.device.id)
      : this.networkDeviceSrv.getDevices(
          this.breadcrumbConfig?.organizationId,
          this.breadcrumbConfig?.projectId,
          undefined,
          undefined,
          undefined,
          'firewall',
        );
    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.setData(!!this.device ? [res] : (res.devices as any[]) || []);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  setData(devices: any[]) {
    this.devices = devices;
    const values: any[] = [];
    this.devices.forEach((device) => {
      //populate device_id and config_id into the table row values
      //important for edit VLAN and firewall record to identify the associated device
      const firewalls = ((device.firewall_rules as any[]) || []).map((firewall) => ({
        device_id: device.id || null,
        config_id: device.configs[0]?.id || null,
        ...firewall,
      }));
      values.push(...firewalls);
    });
    this.values = values;
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((firewall) => ({
          ...firewall,
          interface: this.constantPipe?.transform(firewall.interface, 'firewall-interface'),
          ip_protocol: this.constantPipe?.transform(firewall.ip_protocol, 'firewall-ip-protocol'),
          ip_version: this.constantPipe?.transform(firewall.ip_version, 'firewall-ip-version'),
          chain: this.constantPipe?.transform(firewall.chain, 'firewall-chain'),
          target: this.constantPipe?.transform(firewall.target, 'firewall-target'),
        }));
        this.dt.generateReportDialog.exportReport(
          data,
          `${!!this.device ? `device_${this.device.id}` : `project_${this.breadcrumbConfig?.projectId}`}_firewalls`,
        );
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  syncFirewalls() {
    if (this.device?.configs?.[0]?.connection_status === 'connected') {
      this.isLoading = true;
      const request: Observable<any> = !!this.device
        ? this.networkDeviceSrv.syncDevice(this.device.id)
        : this.networkDeviceSrv.syncDevices(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
      request
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage('Syncronization completed successfully');
            this.getDeviceFirewalls();
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    } else {
      this.testConfigurationDialog.open(this.device);
    }
  }

  openFirewallForm(firewall?: any) {
    if (!!firewall || this.device?.configs?.[0]?.connection_status === 'connected') {
      const dialog = this.dialogSrv.open(FirewallFormComponent, {
        header: `${!firewall ? 'Create' : 'Update'} Firewall`,
        data: {
          deviceId: this.device?.id || firewall.device_id || null,
          configId: this.device?.configs?.[0]?.id || firewall.config_id || null,
          devices: this.device ? [this.device] : this.devices,
          firewall: firewall,
        },
        width: '800px',
        height: 'min-content',
        closable: false,
      });
      dialog.onClose.subscribe((rs) => {
        if (!!rs) {
          this.getDeviceFirewalls();
        }
      });
    } else {
      this.testConfigurationDialog.open(this.device);
    }
  }

  openDeleteConfirmation(firewalls: any[] = []) {
    const firewallList = firewalls.map((firewall) => `<li>Firewall ID ${firewall.id}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Firewall(s)',
      customContent: `Are you sure you want to delete ${
        !firewalls?.length ? 'all firewalls?<br/><br/>' : `the following firewall(s)?<ul>${firewallList.join('')}</ul>`
      }`,
      next: () => {
        this.isLoading = true;
        const deletedVlans: any[] = firewalls.length ? firewalls : this.values;
        const requests: Observable<any>[] = (deletedVlans || []).map((firewall) => this.networkDeviceSrv.deleteDeviceFirewall(firewall.id));
        forkJoin(requests)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe({
            next: () => {
              this.showSuccessMessage(`Deleted firewall(s) successfully`);
              if (!!this.selectedFirewalls?.length) {
                this.selectedFirewalls = [];
              }
              this.getDeviceFirewalls();
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          });
      },
    });
  }

  override ngOnDestroy() {
    this.cleanup();
    this.networkDeviceSrv.refresh$.next(null);
  }
}
