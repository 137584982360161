<div class="grid">
  <label class="col-12 text-data text-center mt-5"> Enter the code from the Authenticator App to make sure everything works </label>

  <div class="col-12 text-center mb-5">
    @if (!!form) {
      <form [formGroup]="form" (ngSubmit)="changeStep(1)">
        <input
          [autofocus]="true"
          inputTrimRef
          [maxlength]="SHORT_TEXT_MAX_LENGTH"
          type="text"
          pInputText
          [formControlName]="FORM_PARAMS.CODE"
          class="text-center"
          style="width: 50%" />
      </form>
    }
  </div>

  <div class="col-4 text-left">
    <p-button severity="secondary" (onClick)="changeStep(-1)" label="Back" />
  </div>
  <label class="col-4 text-data text-center" style="line-height: 2.5rem"> Step 2/3 </label>
  <div class="col-4 text-right">
    <p-button severity="success" (onClick)="changeStep(1)" label="Next" />
  </div>
</div>
