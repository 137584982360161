import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { UPLOAD_LABEL } from '@microsec/constants';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { FirmwareService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

export const FORM_PARAMS = {
  NAME: 'name',
  VERSION: 'version',
  TARGET_DEVICE: 'target_device',
  FILE: 'file',
};

@Component({
  selector: 'app-firmware-upload-form',
  templateUrl: './firmware-upload-form.component.html',
  styleUrls: ['./firmware-upload-form.component.scss'],
})
export class FirmwareUploadFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  UPLOAD_LABEL = UPLOAD_LABEL;

  supportedDevices: any[] = [];

  constructor(
    private firmwareService: FirmwareService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.supportedDevices = this.dialogConfig.data?.supportedDevices;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Firmware name',
        defaultValue: '',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VERSION,
        label: 'Version',
        field: 'input',
        required: true,
        pattern: /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(-[a-zA-Z\d][-a-zA-Z.\d]*)?(\+[a-zA-Z\d][-a-zA-Z.\d]*)?$/,
        fieldInfo: 'Version information must be a semantic version string, e.g 2.5.1',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TARGET_DEVICE,
        label: 'Target Device',
        field: 'dropdown',
        options: this.supportedDevices,
        required: true,
        placeholder: 'Select a target device',
        fieldInfo: 'Target device',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FILE,
        label: 'Upload Firmware',
        field: 'file',
        acceptedFileTypes: [],
        uploadEvent: new EventEmitter<any>(),
        required: true,
        fieldInfo: 'Upload firmware',
        defaultValue: null,
      } as FormItem),
    ];
    this.fields = fields;
    this.initFileUploadCallback();
  }

  initFileUploadCallback() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.FILE);
    if (!!fileField) {
      fileField.uploadEvent?.subscribe((event: any) => {
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.FILE, file);
        } else {
          this.form.setControlValue(FORM_PARAMS.FILE, null);
        }
        this.form.getControl(FORM_PARAMS.FILE)?.updateValueAndValidity();
      });
    }
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const formData = this.form.getRawValue();
    const payload = {
      ...formData,
      signed: false,
      project_id: this.breadcrumbConfig?.projectId,
      org_id: this.breadcrumbConfig?.organizationId,
    };
    this.firmwareService
      .uploadFirmware(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Uploaded firmware successfully');
          closeDialog();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }
}
