import { ILayoutItem } from '@microsec/interfaces';
import { UserAccessComponent } from './user-access/user-access.component';
import { ProjectSnortSettingsComponent } from '../project-snort-settings/project-snort-settings.component';

export const PROJECT_SETTINGS_CONSTANTS = {
  USER_ACCESS: {
    ROUTE: 'user-access',
    LABEL: 'User Access',
  },
  EVENTS: {
    ROUTE: 'events',
    LABEL: 'Events',
  },
  SNORT_SETTINGS: {
    ROUTE: 'snort-settings',
    LABEL: 'Snort Settings',
  },
};

export const PROJECT_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: PROJECT_SETTINGS_CONSTANTS.USER_ACCESS.ROUTE,
  },
  {
    route: PROJECT_SETTINGS_CONSTANTS.USER_ACCESS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.USER_ACCESS.LABEL,
    component: UserAccessComponent,
  },
  {
    route: PROJECT_SETTINGS_CONSTANTS.SNORT_SETTINGS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.SNORT_SETTINGS.LABEL,
    component: ProjectSnortSettingsComponent,
  },
];
