<div class="grid">
  <div class="col-12"><label class="font-bold text-xl">Registration</label></div>
  <div class="col-12 -my-2">
    <p-divider></p-divider>
  </div>
  <div class="col-12">
    <div class="flex flex-column gap-2 text-grey">
      <label><span class="text-white font-bold">Email Address:</span> {{ license.contact_email || '-' }}</label>
      <label><span class="text-white font-bold">Company:</span> {{ license.company_name || '-' }}</label>
      <label><span class="text-white font-bold">Machine ID:</span> {{ license.machine_id || '-' }}</label>
    </div>
  </div>
  <div class="col-12">
    <div class="flex align-items-center gap-2">
      <p-button
        type="button"
        [rounded]="true"
        icon="fa fa-plus"
        severity="primary"
        styleClass="text-transform-none"
        label="Upload New License"
        (onClick)="onUploadLicense()">
      </p-button>
      <p-button
        type="button"
        [rounded]="true"
        icon="fa fa-trash"
        severity="danger"
        styleClass="text-transform-none"
        label="Delete License"
        (onClick)="onDeleteLicense()">
      </p-button>
    </div>
  </div>
</div>
