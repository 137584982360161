import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { PUSH_LABEL, USERNAME_PATTERN } from '@microsec/constants';
import { SERVER_PATTERN, PORT_PATTERN } from '@microsec/constants';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { FirmwareService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  DEVICE_ID: 'device_id',
  IP_ADDRESS: 'ip_address',
  PORT: 'port',
  USERNAME: 'username',
  PASSWORD: 'password',
};

@Component({
  selector: 'app-firmware-push-form',
  templateUrl: './firmware-push-form.component.html',
  styleUrls: ['./firmware-push-form.component.scss'],
})
export class FirmwarePushFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  firmware: any = null;

  devices: any[] = [];

  PUSH_LABEL = PUSH_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private firmwareService: FirmwareService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.firmware = this.dialogConfig?.data?.firmware;
    this.devices = this.dialogConfig?.data?.devices;
    this.initForm();
  }

  initForm() {
    const deviceList = this.devices.map((device) => `Device ID ${device.device_id}`) || [];
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: `Push firmware to ${deviceList.join(', ')}`,
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP_ADDRESS,
        label: 'IP Address',
        field: 'input',
        pattern: SERVER_PATTERN,
        required: this.firmware?.target_device === 'hikvision_cctv',
        fieldInfo: 'IP address',
        defaultValue: '',
        focused: this.firmware?.target_device === 'hikvision_cctv',
        hidden: this.firmware?.target_device !== 'hikvision_cctv',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PORT,
        label: 'Port',
        field: 'number',
        pattern: PORT_PATTERN,
        required: this.firmware?.target_device === 'hikvision_cctv',
        fieldInfo: 'Port',
        defaultValue: null,
        hidden: this.firmware?.target_device !== 'hikvision_cctv',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USERNAME,
        label: 'Username',
        field: 'input',
        required: this.firmware?.target_device === 'hikvision_cctv',
        fieldInfo: 'Username',
        pattern: USERNAME_PATTERN,
        defaultValue: '',
        hidden: this.firmware?.target_device !== 'hikvision_cctv',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSWORD,
        label: 'Password',
        field: 'password',
        required: this.firmware?.target_device === 'hikvision_cctv',
        feedback: false,
        fieldInfo: 'Password',
        defaultValue: '',
        hidden: this.firmware?.target_device !== 'hikvision_cctv',
      } as FormItem),
    ];
    this.fields = fields;
  }

  /**
   * Push firmware
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const formData = this.form.getValue();
    const payloads = this.devices.map((device: any) => ({
      ...formData,
      [FORM_PARAMS.DEVICE_ID]: device?.device_id,
    }));
    this.firmwareService
      .pushHikVisionCCTVDevice(this.firmware?.id, payloads)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Pushed firmware to device(s) successfully`);
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
