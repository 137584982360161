import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-assessment-preview-info',
  templateUrl: './assessment-preview-info.component.html',
  styleUrls: ['./assessment-preview-info.component.scss'],
})
export class AssessmentPreviewInfoComponent extends BaseComponent implements OnInit {
  assessment: any = null;

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const assessment = this.dialogConfig?.data?.assessment;
    this.assessment = {
      ...(assessment || {}),
      descriptionPreview: this.util.parseMarkdown(assessment?.description || ''),
      assessmentType: {
        ...(assessment?.assessmentType || {}),
        descriptionPreview: this.util.parseMarkdown(assessment?.assessmentType?.description || ''),
      },
    };
  }
}
