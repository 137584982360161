import { Component, Input, ViewChildren, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';

import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

import { CODEMIRROR_OPTIONS } from '../assessment-question-form/assessment-question-form.component';

@Component({
  selector: 'app-assessment-question-overview',
  templateUrl: './assessment-question-overview.component.html',
  styleUrls: ['./assessment-question-overview.component.scss'],
})
export class AssessmentQuestionOverviewComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _question: any = {};

  get question() {
    return this._question;
  }

  @Input() set question(value: any) {
    this._question = value || {};
  }

  @ViewChildren('codeMirrorComponent')
  codeMirrorComponents!: CodemirrorComponent[];

  codemirrorOptions: CodeMirror.EditorConfiguration = { ...CODEMIRROR_OPTIONS, readOnly: 'nocursor' };

  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    // Code mirror refresh
    setTimeout(() => {
      this.codeMirrorComponents.forEach((codeMirrorComponent) => {
        codeMirrorComponent?.codeMirror?.refresh();
      });
    }, 500);
  }
}
