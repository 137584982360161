/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint no-console: "off" */
import { environment } from './environments/environment';

// MicroSec global helper functions
declare global {
  interface Window {
    microsec: {
      log: (message?: any, ...optionalParams: any[]) => void;
      error: (message?: any, ...optionalParams: any[]) => void;
      warn: (message?: any, ...optionalParams: any[]) => void;
    };
  }
}

const _global = (window /* browsers */ || globalThis) /* node */ as any;
const microsec: any = (_global.microsec = {});

/**
 * Show log message
 * @param message
 * @param optionalParams
 */
microsec.log = (message?: any, ...optionalParams: any[]): void => {
  if (!environment.production) {
    console.log(message, optionalParams);
  }
};

/**
 * Show error message
 * @param message
 * @param optionalParams
 */
microsec.error = (message?: any, ...optionalParams: any[]): void => {
  if (!environment.production) {
    console.error(message, optionalParams);
  }
};

/**
 * Show warn message
 * @param message
 * @param optionalParams
 */
microsec.warn = (message?: any, ...optionalParams: any[]): void => {
  if (!environment.production) {
    console.warn(message, optionalParams);
  }
};
