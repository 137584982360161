import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  COMPLIANCE_STANDARDS,
  COMPLIANCE_STANDARD_OPTIONS,
  DEFAULT_SECURITY_LEVELS,
  FOUNDATIONAL_REQUIREMENT_LABELS,
  SECURITY_LEVEL_OPTIONS,
} from '@ids-constants';
import { BaseComponent } from '@ids-components';

const CAPABILITY_INFO_MESSAGE = {
  first: 'Capability Security Level has been re-adjusted to match the new Target Security Level.',
  second: 'Note: Capability Security Level must be more than or equal to Target Security Level.',
};

const TARGET_INFO_MESSAGE = {
  first: 'Target Security Level has been re-adjusted to match the new Capability Security Level.',
  second: 'Note: Target Security Level must be less than or equal to Capability Security Level.',
};

@Component({
  selector: 'app-compliance-details-field',
  templateUrl: './compliance-details-field.component.html',
  styleUrls: ['./compliance-details-field.component.scss'],
})
export class ComplianceDetailsFieldComponent extends BaseComponent {
  _readOnly = false;

  get readOnly() {
    return this._readOnly;
  }

  @Input() set readOnly(value: boolean) {
    this._readOnly = value;
    this.setCols();
  }

  _isValid = false;

  get isValid() {
    return this._isValid;
  }

  @Input() set isValid(value: boolean) {
    this._isValid = value;
    this.isValidChange.emit(value);
  }

  @Output() isValidChange: EventEmitter<any> = new EventEmitter<any>();

  _securityLevel: any;

  get securityLevel() {
    return this._securityLevel;
  }

  @Input() set securityLevel(value: any) {
    this._securityLevel = Object.values(this.securityLevel || {}).every((value: any) => value?.length === 7)
      ? value
      : {
          target: DEFAULT_SECURITY_LEVELS,
          capable: DEFAULT_SECURITY_LEVELS,
          achieved: DEFAULT_SECURITY_LEVELS,
        };
    this.values = Object.values(FOUNDATIONAL_REQUIREMENT_LABELS).map((label, index) => ({
      label: label,
      targetLevel: this.securityLevel?.target?.[index] !== null ? this.securityLevel?.target?.[index] : null,
      capableLevel: this.securityLevel?.capable?.[index] !== null ? this.securityLevel?.capable?.[index] : null,
      achievedLevel: this.securityLevel?.achieved?.[index] !== null ? this.securityLevel?.achieved?.[index] : null,
    }));
    this.checkValidity();
    this.securityLevelChange.emit(this.securityLevel);
  }

  @Output() securityLevelChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() shouldTableWrapped = false;

  @Input() shouldShowPlaceholder = true;

  infoMessage: any = null;

  activeTabIndex = 0;

  cols: any[] = [];

  values = Object.values(FOUNDATIONAL_REQUIREMENT_LABELS).map((value) => ({
    label: value,
    targetLevel: null,
    capableLevel: null,
    achievedLevel: null,
  }));

  SECURITY_LEVEL_OPTIONS = SECURITY_LEVEL_OPTIONS;

  COMPLIANCE_STANDARD_OPTIONS = COMPLIANCE_STANDARD_OPTIONS;

  complianceStandard = COMPLIANCE_STANDARDS.IEC_62443;

  constructor() {
    super();
  }

  onChangeLevel(type: string, index: number, rowData: any) {
    if (
      !!this.values?.[index] &&
      ((rowData.targetLevel !== null && rowData.capableLevel !== null && rowData.capableLevel < rowData.targetLevel) ||
        rowData.targetLevel === null ||
        rowData.capableLevel === null)
    ) {
      this.values[index][type === 'target' ? 'capableLevel' : 'targetLevel'] = type === 'target' ? rowData.targetLevel : rowData.capableLevel;
      this.infoMessage = type === 'target' ? CAPABILITY_INFO_MESSAGE : TARGET_INFO_MESSAGE;
    } else {
      this.infoMessage = null;
    }
    this.updateSecurityLevel();
    this.checkValidity();
  }

  updateSecurityLevel() {
    const target = (this.values || []).map((v) => (v.targetLevel !== null ? v.targetLevel : null));
    const capable = (this.values || []).map((v) => (v.capableLevel !== null ? v.capableLevel : null));
    const securityLevel = {
      target: target.length === 7 ? target : DEFAULT_SECURITY_LEVELS,
      capable: capable.length === 7 ? capable : DEFAULT_SECURITY_LEVELS,
    };
    this.securityLevel = securityLevel;
  }

  checkValidity() {
    const target = ((this.securityLevel.target as any[]) || [])?.filter((level) => level !== null)?.length || 0;
    const capable = ((this.securityLevel.capable as any[]) || [])?.filter((level) => level !== null)?.length || 0;
    this.isValid = target === 7 && capable === 7;
  }

  setCols() {
    this.cols = [
      { field: 'label', header: 'Foundation Requirement', width: 2 },
      { field: 'targetLevel', header: 'Target Security Level', width: 1 },
      { field: 'capableLevel', header: 'Capability Security Level', width: 1 },
      ...(!!this.readOnly ? [{ field: 'achievedLevel', header: 'Achieved Security Level', width: 1 }] : []),
    ];
  }
}
