import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@ids-shared';
import { SharedSnortSettingsComponent } from './shared-snort-settings.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SharedSnortSettingsComponent],
  exports: [SharedSnortSettingsComponent],
})
export class SharedSnortSettingsModule {
  static forRoot(): ModuleWithProviders<SharedSnortSettingsModule> {
    return {
      ngModule: SharedSnortSettingsModule,
      providers: [],
    };
  }
}
