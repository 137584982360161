<microsec-card [isNestedCard]="true">
  <div class="grid">
    <div class="col-12">
      <div class="flex align-items-start justify-content-between">
        <div class="font-bold">Attachments</div>
        <div class="flex border-round-lg bg-gray-800 w-2rem h-2rem align-items-center justify-content-center">
          <i class="fa fa-paperclip"></i>
        </div>
      </div>
    </div>
    <div class="col-12">
      <microsec-form-builder
        #fb
        [isPage]="true"
        [shouldShowActionButtons]="false"
        [fields]="fields"
        [hasInfoFields]="false"
        [shouldSubmitOnEnter]="false" />
    </div>
  </div>
</microsec-card>
