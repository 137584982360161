import { NetworkMapEditor } from './network-map-editor';
export const NMEUndo = {
  /**
   * Undo
   * @param this
   */
  undo(this: NetworkMapEditor) {
    this.undoManager?.undo();
    this.updateImportedDevices();
    this.refreshGraph();
  },
  /**
   * Redo
   * @param this
   */
  redo(this: NetworkMapEditor) {
    this.undoManager?.redo();
    this.updateImportedDevices();
    this.refreshGraph();
  },
};
