import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService } from '@ids-services';
import { CommonToolbarResult, CommonToolbarConfiguration } from '@microsec/models';
import { CommonTableComponent } from '@microsec/components';
import { ReportSpecification } from '@microsec/models';
import { ActionMenuItem } from '@microsec/models';
import { MODES } from '@ids-constants';
import { BehaviorSubject, finalize } from 'rxjs';

import { MlModelFormComponent } from './ml-model-form/ml-model-form.component';
import { MlModelExportImportFormComponent } from './ml-model-export-import-form/ml-model-export-import-form.component';
import { DELETE_LABEL } from '@microsec/constants';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

const FIELDS = {
  id: 'Model ID',
  label: 'Label',
  created: 'Created',
  updated: 'Updated',
};
@Component({
  selector: 'app-ml-analyzer-models',
  templateUrl: './ml-analyzer-models.component.html',
  styleUrls: ['./ml-analyzer-models.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class MlAnalyzerModelsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  activeModelOptions = [];

  _analyzer: any = null;

  get analyzer() {
    return this._analyzer;
  }

  @Input() set analyzer(value: any) {
    this._analyzer = value;
    if (!!value) {
      this.values = this.analyzer.models?.sort((a: any, b: any) => {
        return a.id - b.id;
      });
      this.activeModel = this.analyzer.selected_model_id;
      this.activeModelOptions = (this.analyzer.models || []).map((model: any) => ({ label: model.label, value: model.id }));
    }
  }

  values: any[] = [];

  cols = [
    { field: 'id', header: 'Model ID', width: 10 },
    { field: 'label', header: 'Label', width: 20 },
    { field: 'created', header: 'Created Date', width: 20 },
    { field: 'updated', header: 'Updated Date', width: 20 },
  ];

  globalFilterFields: string[] = ['id', 'label'];

  activeModel: any;

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  MODES = MODES;

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.actionsMenuItems = [
      {
        label: 'Export',
        icon: 'fas fa-download',
        disabled: () => this.analyzer.mode === MODES.TRAINING,
        command: ({ rowData }) => this.openExportImportForm('export', rowData),
      },
      {
        label: 'Edit',
        icon: 'fa fa-edit',
        visible: () => this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        disabled: () => this.analyzer.mode === MODES.TRAINING,
        command: ({ rowData }) => this.openModelForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        disabled: ({ rowData }) => this.activeModel === rowData.id,
        command: ({ rowData }) => this.openDeleteConfirmation(rowData),
      },
    ];
    this.handleFilterObjUpdate();
    //Not showing Id and Updated fields on column filter at first
    this.selectedColFields = (this.cols || []).filter((col) => !(col.field === 'updated' || col.field === 'id')).map((col) => col.field);
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result.search || '';
      }
    });
  }

  openModelForm(model: any) {
    const dialog = this.dialogSrv.open(MlModelFormComponent, {
      header: 'Update Model',
      data: { model: model },
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.anomalyAnalyzerSrv.refresh$.next(true);
      }
    });
  }

  openDeleteConfirmation(model: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Model Analyzer',
      object: model,
      objectFieldName: 'label',
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.anomalyAnalyzerSrv.deleteModel(model.id).pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      ),
      next: () => {
        this.showSuccessMessage(`Deleted model analyzer ${model.label} successfully`);
        this.anomalyAnalyzerSrv.refresh$.next(true);
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  openExportImportForm(form_type: string, model?: any) {
    const dialog = this.dialogSrv.open(MlModelExportImportFormComponent, {
      header: `${form_type === 'import' ? 'Import' : 'Export'} Model`,
      data: { model: { form_type: form_type, analyzerId: this.analyzer.id, model_id: model ? model.id : null } },
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.anomalyAnalyzerSrv.refresh$.next(true);
      }
    });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        this.dt.generateReportDialog.exportReport(
          specification.data || [],
          `${!!this.analyzer ? `Analyzer_model_${this.analyzer.id}` : `project_${this.breadcrumbConfig?.projectId}`}_analyzer_model`,
        );
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  setActiveModel(evt: any) {
    const modelLabel = this.analyzer.models.find((model: any) => model.id === evt.value).label;
    this.isLoading = true;
    this.confirm({
      action: 'Change',
      objectName: 'Active Model',
      customContent: `Are you sure you want to change the Active Model to ${modelLabel}?
        <br><br>Threat analysis and packet prediction will be based on ${modelLabel} after this change.`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.anomalyAnalyzerSrv
        .updateAnalyzer(this.analyzer.id, {
          selected_model_id: this.activeModel,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        ),
      next: () => {
        this.showSuccessMessage('Analyzer Updated Successfully');
        this.anomalyAnalyzerSrv.refresh$.next(true);
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }
}
