import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ANALYZER_DATA_TYPES, ANALYZER_TYPES } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService } from '@ids-services';

import { finalize } from 'rxjs';

@Component({
  selector: 'app-rule-based-analyzer-details',
  templateUrl: './rule-based-analyzer-details.component.html',
  styleUrls: ['./rule-based-analyzer-details.component.scss'],
})
export class RuleBasedAnalyzerDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  activeIndex = 0;

  _analyzer: any = null;

  get analyzer() {
    return this._analyzer;
  }

  @Input() set analyzer(value: any) {
    this._analyzer = value;
    if (!!this._analyzer) {
      this.getAnalyzer();
    }
  }

  detailsAnalyzer: any = {};

  @Output() analyzerChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() hidePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() panelHeaderRightTemplate: TemplateRef<any> | null = null;

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_DATA_TYPES = ANALYZER_DATA_TYPES;

  checkInterval: any;

  constructor(public anomalyAnalyzerSrv: AnomalyAnalyzerService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkInterval = setInterval(() => {
      if (!!this.analyzer.id && this.analyzer?.analyzer_type === ANALYZER_TYPES.ML) {
        this.getAnalyzer(false);
      }
    }, 5000);
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.anomalyAnalyzerSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !!this.analyzer.id) {
        this.getAnalyzer();
      }
    });
    this.subscriptions.push(subscription);
  }

  getAnalyzer(showLoading = true) {
    if (!!showLoading) {
      this.isLoading = true;
    }
    this.anomalyAnalyzerSrv
      .getAnalyzer(this.analyzer.id, this.analyzer.analyzer_type)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.detailsAnalyzer =
            {
              ...rs,
              type: this.analyzer.type,
              connection_name: this.analyzer.connection_name || '',
            } || {};
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  override ngOnDestroy(): void {
    this.cleanup();
    clearInterval(this.checkInterval);
  }
}
