<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="report-preview" [autofocus]="true" tabindex="-1">
    <microsec-common-preview-layout [headerTemplate]="headerTemplate">
      <div class="body">
        <ng-container *ngIf="!!pdfBuffer">
          <pdf-viewer
            [src]="pdfBuffer"
            [rotation]="0"
            [original-size]="true"
            [show-all]="true"
            [fit-to-page]="true"
            [zoom]="1"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            style="width: 100%; height: calc(100vh - 3.125rem)"></pdf-viewer>
        </ng-container>
      </div>
    </microsec-common-preview-layout>
  </div>
</microsec-loading-overlay>

<ng-template #headerTemplate>
  <div class="w-full flex flex align-items-center justify-content-between gap-1">
    <div class="cursor-pointer hover:underline" (click)="dialogRef.close(false)"><i class="fas fa-angle-left mr-2"></i>Back to Reports</div>
    <div class="text-highlight font-bold">{{ report?.name || '' }}</div>
    <div>
      <p-button
        icon="fas fa-download"
        label="Download Report"
        severity="success"
        size="small"
        [rounded]="true"
        styleClass="text-transform-none"
        (onClick)="downloadReportMenu.toggle($event)"></p-button>
      <p-menu
        #downloadReportMenu
        styleClass="microsec-button-context-menu"
        [model]="downloadReportMenuOptions"
        [popup]="true"
        appendTo="body"></p-menu>
    </div>
  </div>
</ng-template>
