import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent } from '@ids-components';
import { validatorTrimRequired } from '@microsec/validators';

import CodeMirror from 'codemirror';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { FORM_PARAMS } from '../form.component';

const CODEMIRROR_OPTIONS: CodeMirror.EditorConfiguration = {
  theme: 'base16-dark',
  mode: 'python',
  showCursorWhenSelecting: false,
};

const DEFAULT_PREPROCESSING_LAMBDA = `# Parse the input data as JSON
parsed_data = json.loads(input_data)
`;

const DEFAULT_PROCESSING_LAMBDA = `# Extract the 'data' field from the parsed data
payload_data = parsed_data["data"]

# Extract the device information from the payload data
device_id =  parsed_data["device_id"]
device_key = f"mqtt:{device_id}"
hostname = device_id
enumeration = add_device(device_key, hostname)

# Specify the data structure ID that will describe unique JSON structure
data_structure_id = "data_structure_1"
`;

@Component({
  selector: 'app-topics-field',
  templateUrl: './topics-field.component.html',
  styleUrls: ['./topics-field.component.scss'],
})
export class TopicsFieldComponent extends BaseComponent implements OnInit, AfterViewInit {
  codeMirrorOptions: CodeMirror.EditorConfiguration | null = null;

  form: FormGroup | null = null;

  activeIndex: any = [];

  currentId = 0;

  _isReadOnly = false;

  get isReadOnly() {
    return this._isReadOnly;
  }

  @Input() set isReadOnly(values: boolean) {
    this._isReadOnly = values;
    this.codeMirrorOptions = {
      ...CODEMIRROR_OPTIONS,
      readOnly: values,
    };
  }

  _topics: any[] = [];

  get topics() {
    return this._topics;
  }

  @Input() set topics(values: any[]) {
    this._topics = values;
    this.topicsChange.emit(values);
  }

  @Output() topicsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChildren('codeMirrorComponent')
  codeMirrorComponents!: CodemirrorComponent[];

  FORM_PARAMS = FORM_PARAMS;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      [FORM_PARAMS.INTERFACE_TOPICS]: this.fb.array([]),
    });
    this.patchTopics(this.topics);
    this.updateTopics();
  }

  async ngAfterViewInit() {
    // Code mirror refresh
    setTimeout(() => {
      this.codeMirrorComponents.forEach((codeMirrorComponent) => {
        codeMirrorComponent?.codeMirror?.refresh();
      });
    }, 500);
  }

  patchTopics(topics: any[]) {
    this.topicsFormArray?.clear();
    if (topics?.length) {
      topics.forEach((topic) => {
        this.addTopic(topic, true);
      });
    }
  }

  addTopic(topic?: any, isPatch = false) {
    this.currentId += 1;
    const newTopicForm = this.fb.group({
      id: this.currentId,
      [FORM_PARAMS.TOPIC]: [topic?.[FORM_PARAMS.TOPIC] || '', validatorTrimRequired],
      [FORM_PARAMS.TSHARK_COMPATIBILITY]: [topic?.[FORM_PARAMS.TSHARK_COMPATIBILITY] || false],
      [FORM_PARAMS.PREPROCESSING_LAMBDA]: [!!isPatch ? topic?.[FORM_PARAMS.PREPROCESSING_LAMBDA] || '' : DEFAULT_PREPROCESSING_LAMBDA],
      [FORM_PARAMS.PROCESSING_LAMBDA]: [!!isPatch ? topic?.[FORM_PARAMS.PROCESSING_LAMBDA] || '' : DEFAULT_PROCESSING_LAMBDA],
      showedAdvancedSettings: [false],
      expanded: [!isPatch],
      updated: [false],
      saved: [isPatch],
    });
    if (!!this.isReadOnly) {
      newTopicForm?.disable();
    }
    newTopicForm?.valueChanges?.subscribe((values) => {
      const currentValues = this.util.cloneDeepObject(values);
      delete currentValues.showedAdvancedSettings;
      delete currentValues.expanded;
      delete currentValues.updated;
      delete currentValues.saved;
      const savedTopic = this.topics.find((topic) => topic.id === newTopicForm.value.id);
      const isUpdated = !!savedTopic ? !this.util.isObjectsDeepEqual(savedTopic, currentValues) : true;
      newTopicForm?.get('updated')?.setValue(isUpdated, { emitEvent: false });
    });
    this.topicsFormArray?.push(newTopicForm);
    if (!topic) {
      this.activeIndex.push(this.topicsFormArray?.length - 1);
    }
  }

  removeTopic(index: number) {
    this.topicsFormArray?.removeAt(index);
  }

  get topicsFormArray() {
    return this.form?.get(FORM_PARAMS.INTERFACE_TOPICS) as FormArray;
  }

  cancelChangesTopic(index: number) {
    const savedTopic = this.topics.find((topic) => topic.id === this.topicsFormArray?.get([index, 'id'])?.value);
    this.topicsFormArray?.at(index).patchValue({
      ...(savedTopic || {}),
      showedAdvancedSettings: false,
      expanded: false,
      updated: false,
    });
  }

  updateTopics(index?: any, isDelete = false) {
    if (!!isDelete) {
      this.removeTopic(index);
    } else {
      this.topicsFormArray?.get([index, 'saved'])?.setValue(true, { emitEvent: false });
      this.topicsFormArray?.get([index, 'expanded'])?.setValue(null, { emitEvent: false });
      this.topicsFormArray?.get([index, 'updated'])?.setValue(false, { emitEvent: false });
    }
    this.topics =
      this.form
        ?.get(FORM_PARAMS.INTERFACE_TOPICS)
        ?.value?.filter((topic: any) => !!topic.saved)
        ?.map((topic: any) => ({
          id: topic.id,
          [FORM_PARAMS.TOPIC]: topic?.[FORM_PARAMS.TOPIC] || '',
          [FORM_PARAMS.TSHARK_COMPATIBILITY]: topic?.[FORM_PARAMS.TSHARK_COMPATIBILITY] || false,
          [FORM_PARAMS.PREPROCESSING_LAMBDA]: topic?.[FORM_PARAMS.PREPROCESSING_LAMBDA] || '',
          [FORM_PARAMS.PROCESSING_LAMBDA]: topic?.[FORM_PARAMS.PROCESSING_LAMBDA] || '',
        })) || [];
    this.topicsChange?.emit(this.topics);
  }

  toggleShowAdvanceSettings(index: number, value: boolean) {
    this.topicsFormArray?.get([index, 'showedAdvancedSettings'])?.setValue(value, { emitEvent: false });
  }
}
