import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { PIPE_DATETIME } from '@microsec/constants';

@Component({
  selector: 'app-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss'],
})
export class LicenseDetailsComponent {
  @Input() license: any = null;

  @Output() uploadLicenseEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() deleteLicenseEvent: EventEmitter<any> = new EventEmitter<any>();

  menuItems: any[] = [
    { value: 'registration', label: 'Registration' },
    { value: 'quotas', label: 'Quotas' },
  ];

  selectedMenuItem = 'registration';

  faCheckCircle = faCheckCircle;

  PIPE_DATETIME = PIPE_DATETIME;

  constructor() {}

  onMenuSelected(menuItem: any) {
    this.selectedMenuItem = menuItem.value;
  }

  onUploadLicense() {
    this.uploadLicenseEvent.emit();
  }

  onDeleteLicense() {
    this.deleteLicenseEvent.emit();
  }
}
