import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent extends BaseComponent {
  @Input() license: any = null;

  @Output() uploadLicenseEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() deleteLicenseEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  onUploadLicense() {
    this.uploadLicenseEvent.emit();
  }

  onDeleteLicense() {
    this.deleteLicenseEvent.emit();
  }
}
