import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { TreeSelect } from 'primeng/treeselect';
import { finalize } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { BaseComponent } from '@ids-components';
import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { ANALYZER_DATA_TYPES, ANALYZER_RULE_TYPE_OPTIONS, ANALYZER_TYPES, ATTACK_OPTIONS, CONNECTION_TYPES } from '@ids-constants';
import { FormItem } from '@microsec/models';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, SAVE_CHANGES_LABEL, VALIDATOR_TYPE } from '@microsec/constants';
import { AnomalyAnalyzerService, ConnectionService } from '@ids-services';
import moment from 'moment';

export const FORM_PARAMS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  PROJECT_ID: 'project_id',
  CONNECTION_IDS: 'connection_ids',
  ANALYZER_TYPE: 'analyzer_type',
  RULE_TYPES: 'rule_types',
  SNORT_OINKMASTER_CODE: 'snort_oinkmaster_code',
  RULE_DOWNLOAD_FREQUENCY: 'rule_download_frequency',
  PROTOCOL: 'protocol',
  ENABLED: 'enabled',
  DATA_TYPE: 'data_type',
  DATA_STRUCTURE_ID: 'data_structure_id',
  PREDICTION_PACKET_ACCUMULATION_THRESHOLD: 'prediction_packet_accumulation_threshold',
  PREDICTION_THRESHOLD: 'prediction_threshold',
  ANOMALY_SENSITIVITY_THRESHOLD: 'anomaly_sensitivity_threshold',
  ATTACKS: 'attacks',
  DIVIDER: 'divider',
  TRAINING_TIME: 'trainingTime',
};

const C1_TRAINING = 1000;

const C1_PREDICTION = 500;

const C2_PREDICTION = 800;

const GLOBAL_TRAINING_RATIO = 0.7;

const GLOBAL_PREDICTION_RATIO = 0.3;

@Component({
  selector: 'app-ml-analyzer-form',
  templateUrl: './ml-analyzer-form.component.html',
  styleUrls: ['./ml-analyzer-form.component.scss'],
})
export class MlAnalyzerFormComponent extends BaseComponent implements AfterViewInit {
  analyzer: any = null;

  steps: MenuItem[] = [];

  activeStep = 0;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('stepsTemplate') stepsTemplate!: TemplateRef<any>;

  @ViewChild('protocolsTreeSelect') protocolsTreeSelect!: TreeSelect;

  @ViewChild('protocolsField')
  protocolsField!: TemplateRef<any>;

  @ViewChild('anomalySensitivityThresholdField') anomalySensitivityThresholdField!: TemplateRef<any>;

  @ViewChild('trainingTimeTemplate') trainingTimeTemplate!: TemplateRef<any>;

  protocols: any = null;

  protocolOptions: any = null;

  connections: any[] = [];

  connectionOptions: any[] = [];

  selectedConnectionType: string = '';

  packetRate: any = 0;

  protocolUpdated = false;

  connectionProtocols: any = {};

  FORM_PARAMS = FORM_PARAMS;

  CREATE_BUTTON_LABEL = CREATE_LABEL;

  SAVE_CHANGES_BUTTON_LABEL = SAVE_CHANGES_LABEL;

  ANALYZER_RULE_TYPE_OPTIONS = ANALYZER_RULE_TYPE_OPTIONS;

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    private connectionSrv: ConnectionService,
    public dialogConfig: DynamicDialogConfig,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.analyzer = this.dialogConfig?.data?.analyzer;
    this.activeStep = this.dialogConfig?.data?.step || 0;
    this.steps = [
      {
        label: 'General<br/>Configuration',
      },
      {
        label: 'ML<br/>Parameters',
      },
    ];
    this.getData();
  }

  initForm() {
    if (!!this.analyzer) {
      const connection = this.connections.find((c) => c.id === this.analyzer[FORM_PARAMS.CONNECTION_IDS]?.[0]);
      this.setProtocolOptions(connection);
      this.selectedConnectionType = connection?.interface?.type || '';
    }
    const nodes = this.util.flattenObjectArray(this.protocolsTreeSelect?.options || this.protocolOptions || [], 'children');

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        hasNoLabel: true,
        field: 'custom',
        customField: this.stepsTemplate,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: !this.analyzer || this.activeStep === 0,
        fieldInfo: 'Name of the analyzer',
        defaultValue: this.analyzer?.[FORM_PARAMS.NAME] || '',
        focused: true,
        hidden: this.activeStep !== 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the analyzer',
        defaultValue: this.analyzer?.[FORM_PARAMS.DESCRIPTION] || '',
        hidden: this.activeStep !== 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_ID,
        required: true,
        defaultValue: this.breadcrumbConfig?.projectId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONNECTION_IDS,
        label: 'Connection',
        required: !this.analyzer || this.activeStep === 0,
        field: 'dropdown',
        options: this.connectionOptions || [],
        placeholder: 'Select connection',
        fieldInfo: `Analyzer's connection ID`,
        defaultValue: this.analyzer?.[FORM_PARAMS.CONNECTION_IDS]?.[0] || null,
        disabled: !!this.analyzer,
        hidden: this.activeStep !== 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROTOCOL,
        label: 'Protocol',
        field: 'custom',
        required: this.analyzer?.[FORM_PARAMS.DATA_TYPE] === ANALYZER_DATA_TYPES.PACKET,
        customField: this.protocolsField,
        fieldInfo: 'Protocol of the connection',
        defaultValue:
          this.analyzer?.[FORM_PARAMS.DATA_TYPE] === ANALYZER_DATA_TYPES.PACKET
            ? nodes.filter((node) => (this.analyzer[FORM_PARAMS.PROTOCOL] || []).includes(node.key))?.[0] || this.analyzer?.[FORM_PARAMS.PROTOCOL]
            : null,
        disabled: !this.analyzer && this.analyzer?.[FORM_PARAMS.DATA_TYPE] === ANALYZER_DATA_TYPES.PACKET,
        hidden: !this.analyzer && (this.activeStep !== 0 || this.analyzer?.[FORM_PARAMS.DATA_TYPE] !== ANALYZER_DATA_TYPES.PACKET),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DATA_STRUCTURE_ID,
        label: 'Data Structure ID',
        required: this.analyzer?.[FORM_PARAMS.DATA_TYPE] === ANALYZER_DATA_TYPES.PAYLOAD,
        fieldInfo: 'Data structure ID of the analyzer',
        defaultValue: this.analyzer?.[FORM_PARAMS.DATA_STRUCTURE_ID] || '',
        hidden: this.activeStep !== 0 || this.analyzer?.[FORM_PARAMS.DATA_TYPE] !== ANALYZER_DATA_TYPES.PAYLOAD,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        defaultValue: this.analyzer?.[FORM_PARAMS.ENABLED] || true,
        fieldInfo: 'Enable analyzer',
        hidden: this.activeStep !== 0,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DATA_TYPE,
        defaultValue: this.analyzer?.[FORM_PARAMS.DATA_TYPE],
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ANALYZER_TYPE,
        defaultValue: ANALYZER_TYPES.ML,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ATTACKS,
        label: 'Attack(s)',
        field: 'multiselect',
        options: this.util.cloneObjectArray(ATTACK_OPTIONS),
        placeholder: 'Select Attack(s)',
        fieldInfo: 'Attack(s) of the analyzer',
        defaultValue: this.analyzer?.[FORM_PARAMS.ATTACKS] || [],
        minLength: 1,
        required: true,
        hidden: this.activeStep !== 1,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ANOMALY_SENSITIVITY_THRESHOLD,
        label: 'Threshold Sensitivity',
        field: 'custom',
        customField: this.anomalySensitivityThresholdField,
        fieldInfo: 'Threshold sensitivity of the analyzer',
        defaultValue: this.analyzer?.[FORM_PARAMS.ANOMALY_SENSITIVITY_THRESHOLD] || 0.5,
        maxValue: 0.9,
        minValue: 0.1,
        required: true,
        hidden: this.activeStep !== 1,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DIVIDER,
        rowStyleClass: '-mt-2',
        field: 'divider',
        hidden: this.activeStep !== 1,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD,
        label: 'Number of Training Packets',
        field: 'number',
        fieldInfo:
          "The number of packets used to train the ML analyzer model.<br><br>A higher number of training packets increases the training time, but also enhances the model's accuracy.",
        fieldInfoEscape: false,
        minValue: 1,
        required: true,
        defaultValue: this.analyzer?.[FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD] || null,
        hidden: this.activeStep !== 1,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PREDICTION_THRESHOLD,
        label: 'Prediction Packets Per Batch',
        field: 'number',
        fieldInfo: 'Prediction packets per batch of the analyzer',
        minValue: 1,
        required: true,
        defaultValue: this.analyzer?.[FORM_PARAMS.PREDICTION_THRESHOLD] || null,
        hidden: this.activeStep !== 1,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TRAINING_TIME,
        rowStyleClass: '-mt-2',
        hasNoLabel: true,
        field: 'custom',
        customField: this.trainingTimeTemplate,
        defaultValue: 0,
        hidden: this.activeStep !== 1,
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.DATA_TYPE, (value: any) => {
        this.onDataTypeChange(value);
      });
      this.form?.setChangeEvent(FORM_PARAMS.ANALYZER_TYPE, (value: string) => {
        this.onAnalyzerTypeChange(value);
      });
      this.form?.setChangeEvent(FORM_PARAMS.CONNECTION_IDS, (value: string) => {
        this.onConnectionChange(value);
      });
      this.form?.setChangeEvent(FORM_PARAMS.PROTOCOL, () => {
        this.protocolUpdated = true;
      });
      this.form?.setChangeEvent(FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD, (value: any) => {
        this.setTrainingTime(value);
      });
    });

    this.cd.detectChanges();
  }

  onDataTypeChange(value: any) {
    if (this.form.getControlValue(FORM_PARAMS.ANALYZER_TYPE) === ANALYZER_TYPES.ML && this.activeStep === 0) {
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.PROTOCOL,
        value !== ANALYZER_DATA_TYPES.PAYLOAD ? [VALIDATOR_TYPE.MIN_VALUE, VALIDATOR_TYPE.REQUIRED] : [],
        [0],
      );
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.DATA_STRUCTURE_ID,
        value === ANALYZER_DATA_TYPES.PAYLOAD ? [VALIDATOR_TYPE.REQUIRED] : [],
      );
    }
  }

  onAnalyzerTypeChange(value: string) {
    this.form.setControlValidatorsAndVisibility(
      FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD,
      value === ANALYZER_TYPES.ML && this.activeStep === 1 ? [VALIDATOR_TYPE.MIN_VALUE, VALIDATOR_TYPE.REQUIRED] : [],
      [1],
    );
    this.form.setControlValidatorsAndVisibility(
      FORM_PARAMS.PREDICTION_THRESHOLD,
      value === ANALYZER_TYPES.ML && this.activeStep === 1 ? [VALIDATOR_TYPE.MIN_VALUE, VALIDATOR_TYPE.REQUIRED] : [],
      [1],
    );
    this.form.setControlValidatorsAndVisibility(
      FORM_PARAMS.ANOMALY_SENSITIVITY_THRESHOLD,
      value === ANALYZER_TYPES.ML && this.activeStep === 1 ? [VALIDATOR_TYPE.MIN_VALUE, VALIDATOR_TYPE.MAX_VALUE, VALIDATOR_TYPE.REQUIRED] : [],
      [0.1, 0.9],
    );
    this.form.setControlValidatorsAndVisibility(
      FORM_PARAMS.ATTACKS,
      value === ANALYZER_TYPES.ML && this.activeStep === 1 ? [VALIDATOR_TYPE.MIN_LENGTH, VALIDATOR_TYPE.REQUIRED] : [],
      [1],
    );
    this.form.setControlVisibility(FORM_PARAMS.RULE_TYPES, value === ANALYZER_TYPES.RULE_BASED && this.activeStep === 1);
    this.form.setControlValidatorsAndVisibility(
      FORM_PARAMS.SNORT_OINKMASTER_CODE,
      value === ANALYZER_TYPES.RULE_BASED && this.activeStep === 1 ? [VALIDATOR_TYPE.REQUIRED] : [],
    );
    this.form.setControlValidatorsAndVisibility(
      FORM_PARAMS.RULE_DOWNLOAD_FREQUENCY,
      value === ANALYZER_TYPES.RULE_BASED && this.activeStep === 1 ? [VALIDATOR_TYPE.REQUIRED] : [],
    );
  }

  onConnectionChange(value: any) {
    if (this.activeStep === 0) {
      const connection = this.connections.find((conn) => conn.id === value);
      if (!!connection) {
        this.selectedConnectionType = connection.interface?.type || '';
        if (this.selectedConnectionType === CONNECTION_TYPES.MQTT) {
          this.form.setControlValue(FORM_PARAMS.DATA_TYPE, ANALYZER_DATA_TYPES.PAYLOAD);
          this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.DATA_STRUCTURE_ID, [VALIDATOR_TYPE.REQUIRED]);
          this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.PROTOCOL, []);
        }
        if (this.selectedConnectionType === CONNECTION_TYPES.PHYSICAL || this.selectedConnectionType === CONNECTION_TYPES.PCAP) {
          this.form.setControlValue(FORM_PARAMS.DATA_TYPE, ANALYZER_DATA_TYPES.PACKET);
          this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.PROTOCOL, [VALIDATOR_TYPE.REQUIRED]);
          this.setProtocolOptions(connection);
          this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.DATA_STRUCTURE_ID, []);
        }
      } else {
        this.selectedConnectionType = '';
        this.setProtocolOptions();
      }
      this.form.setControlValue(FORM_PARAMS.PROTOCOL, null);
    }
  }

  getData() {
    this.form.isLoading = true;
    forkJoin({
      connections: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      connectionProtocols: this.connectionSrv.getConnectionsProtocols(),
      protocols: this.connectionSrv.getProtocols(),
    })
      .pipe(
        finalize(() => {
          this.initForm();
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.connections = res.connections.data as any[];
          this.connectionOptions = ((res.connections.data as any[]) || [])
            .filter(
              (con) =>
                con?.interface?.type === CONNECTION_TYPES.PHYSICAL ||
                con?.interface?.type === CONNECTION_TYPES.PCAP ||
                con?.interface?.type === CONNECTION_TYPES.MQTT,
            )
            .map((con) => ({
              label: con.name || con.id,
              value: con.id,
            }));
          this.connectionProtocols = res.connectionProtocols || {};
          this.protocols = res.protocols || {};
          this.setProtocolOptions();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onSubmit(closeDialog: (analyzer: any) => void) {
    this.form.isLoading = true;
    const formValue = this.form.getRawValue();
    const protocols = formValue[FORM_PARAMS.DATA_TYPE] === ANALYZER_DATA_TYPES.PACKET ? [formValue[FORM_PARAMS.PROTOCOL]?.key] : undefined;
    const payload = {
      ...formValue,
      [FORM_PARAMS.PROTOCOL]: protocols,
      [FORM_PARAMS.CONNECTION_IDS]: [formValue[FORM_PARAMS.CONNECTION_IDS]],
    };

    delete payload[FORM_PARAMS.DIVIDER];
    delete payload[FORM_PARAMS.TRAINING_TIME];

    // Modify payload when doing patching
    if (!!this.analyzer) {
      delete payload[FORM_PARAMS.PROJECT_ID];
      delete payload[FORM_PARAMS.ANALYZER_TYPE];
      delete payload[FORM_PARAMS.CONNECTION_IDS];
    }
    if (formValue[FORM_PARAMS.DATA_TYPE] === ANALYZER_DATA_TYPES.PACKET) {
      delete payload[FORM_PARAMS.DATA_STRUCTURE_ID];
    } else {
      delete payload[FORM_PARAMS.PROTOCOL];
    }

    const request: Observable<any> = !!this.analyzer
      ? this.anomalyAnalyzerSrv.updateAnalyzer(this.analyzer.id, payload)
      : this.anomalyAnalyzerSrv.createAnalyzer(payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.showSuccessMessage(`Analyzer ${!!this.analyzer ? 'Updated' : 'Created'} Successfully`);
          closeDialog(rs);
        },
        error: (error) => {
          this.form.showServerErrorMessage(error);
          this.showErrorMessage(error);
        },
      });
  }

  getConnectionMetrics() {
    this.form.isLoading = true;
    const request =
      this.selectedConnectionType === CONNECTION_TYPES.PCAP
        ? this.connectionSrv.getConnectionMetrics('series', 'query_range', {
            projectId: this.breadcrumbConfig?.projectId,
            connectionId: this.form.getControlValue(FORM_PARAMS.CONNECTION_IDS),
            start: moment().subtract(30, 'second').unix(),
            end: moment().unix(),
            step: '5s',
          })
        : this.connectionSrv.getConnectionMetrics('summary', 'query', {
            projectId: this.breadcrumbConfig?.projectId,
            connectionId: this.form.getControlValue(FORM_PARAMS.CONNECTION_IDS),
            interval: '30s',
          });
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const selectedProtocolPacket = (rs.data || []).find(
            (i: any) => i?.metric?.protocol === this.form.getControlValue(FORM_PARAMS.PROTOCOL)?.key,
          );
          if (this.selectedConnectionType === CONNECTION_TYPES.PCAP) {
            this.packetRate = 0;
            const packets = selectedProtocolPacket?.values || [];
            const totalPackets = packets[packets.length - 1]?.[1] || 0;
            if (!this.analyzer || !!this.protocolUpdated) {
              const trainingPackets = GLOBAL_TRAINING_RATIO * parseFloat(totalPackets);
              this.form.setControlValue(FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD, trainingPackets > 0 ? trainingPackets : C1_TRAINING);
              const predictionPackets = GLOBAL_PREDICTION_RATIO * parseFloat(totalPackets);
              this.form.setControlValue(FORM_PARAMS.PREDICTION_THRESHOLD, predictionPackets > 0 ? predictionPackets : C1_PREDICTION);
              this.protocolUpdated = false;
            }
          } else {
            this.packetRate = selectedProtocolPacket?.value?.[1] || 0;
            if (!this.analyzer || !!this.protocolUpdated) {
              const constant = {
                a:
                  parseFloat(this.packetRate) > 0 && parseFloat(this.packetRate) <= 1
                    ? 2500
                    : parseFloat(this.packetRate) > 1 && parseFloat(this.packetRate) <= 10
                      ? 5000
                      : 0,
                b: parseFloat(this.packetRate) > 0 && parseFloat(this.packetRate) <= 1 ? 8000 : parseFloat(this.packetRate) > 1 ? 10000 : 0,
                p: parseFloat(this.packetRate) > 0 && parseFloat(this.packetRate) <= 1 ? 1.5 : parseFloat(this.packetRate) > 1 ? 0.5 : 0,
              };
              const trainingPackets = constant.a + constant.b * (parseFloat(this.packetRate) ^ constant.p);
              this.form.setControlValue(
                FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD,
                !this.isInfinite(trainingPackets) && trainingPackets > 0 ? trainingPackets : C1_TRAINING,
              );
              const predictionPackets = C1_PREDICTION + C2_PREDICTION * Math.log(parseFloat(this.packetRate));
              this.form.setControlValue(
                FORM_PARAMS.PREDICTION_THRESHOLD,
                !this.isInfinite(predictionPackets) && predictionPackets > 0 ? predictionPackets : C1_PREDICTION,
              );
              this.protocolUpdated = false;
            } else {
              this.setTrainingTime(this.form.getControlValue(FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD));
            }
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  setProtocolOptions(connection?: any) {
    if (!!connection) {
      const connectionProtocols = this.connectionProtocols[connection?.interface?.type] || [];
      const hierarchyProtocols = this.mappedHierarchyProtocols(this.protocols, connectionProtocols);
      const protocolOptions = this.mappedProtocolOptions(hierarchyProtocols, connection?.protocols || []);
      this.protocolOptions = protocolOptions;
    }
  }

  mappedHierarchyProtocols(protocols: any, connectionProtocols: string[]) {
    const newProtocols = this.util.cloneDeepObject(protocols);
    Object.entries(newProtocols || {}).forEach(([key, value]: [string, any]) => {
      if (!connectionProtocols.includes(key)) {
        delete newProtocols[key];
      } else if (!!value.sub && !!Object.keys(value.sub).length) {
        newProtocols[key].sub = this.mappedHierarchyProtocols(value.sub, connectionProtocols);
      }
    });
    return Object.keys(newProtocols).length === 0 ? undefined : newProtocols;
  }

  mappedProtocolOptions(hierarchyProtocols: any, connectionProtocols?: string[]): any {
    return Object.entries(hierarchyProtocols || {}).map(([key, value]: [string, any]) => ({
      key: key,
      label: value.name,
      expanded: true,
      selectable: !connectionProtocols?.length ? true : !!connectionProtocols?.includes(key),
      children: !!value.sub && !!Object.keys(value.sub).length ? this.mappedProtocolOptions(value.sub, connectionProtocols) : undefined,
    }));
  }

  onActiveStepChange(isNext = true) {
    this.activeStep = this.activeStep + (isNext ? 1 : -1);

    if (this.activeStep === 1 && !!isNext && this.form.getControlValue(FORM_PARAMS.DATA_TYPE) === ANALYZER_DATA_TYPES.PACKET) {
      this.getConnectionMetrics();
    }

    this.form.setControlVisibility(FORM_PARAMS.NAME, this.activeStep === 0);
    this.form.setControlVisibility(FORM_PARAMS.DESCRIPTION, this.activeStep === 0);
    this.form.setControlVisibility(FORM_PARAMS.CONNECTION_IDS, this.activeStep === 0);
    this.form.setControlVisibility(
      FORM_PARAMS.PROTOCOL,
      this.activeStep === 0 && this.form.getControlValue(FORM_PARAMS.DATA_TYPE) === ANALYZER_DATA_TYPES.PACKET,
    );
    this.form.setControlVisibility(
      FORM_PARAMS.DATA_STRUCTURE_ID,
      this.activeStep === 0 && this.form.getControlValue(FORM_PARAMS.DATA_TYPE) === ANALYZER_DATA_TYPES.PAYLOAD,
    );
    this.form.setControlVisibility(FORM_PARAMS.ENABLED, this.activeStep === 0);
    // STEP 2 ML
    this.form.setControlVisibility(FORM_PARAMS.ATTACKS, this.activeStep === 1);
    this.form.setControlVisibility(FORM_PARAMS.ANOMALY_SENSITIVITY_THRESHOLD, this.activeStep === 1);
    this.form.setControlVisibility(FORM_PARAMS.PREDICTION_PACKET_ACCUMULATION_THRESHOLD, this.activeStep === 1);
    this.form.setControlVisibility(FORM_PARAMS.PREDICTION_THRESHOLD, this.activeStep === 1);
    this.form.setControlVisibility(FORM_PARAMS.DIVIDER, this.activeStep === 1);
    this.form.setControlVisibility(
      FORM_PARAMS.TRAINING_TIME,
      this.activeStep === 1 &&
        this.form.getControlValue(FORM_PARAMS.DATA_TYPE) === ANALYZER_DATA_TYPES.PACKET &&
        this.selectedConnectionType !== CONNECTION_TYPES.PCAP,
    );
  }

  setTrainingTime(trainingPackets: any) {
    const trainingTime = (trainingPackets || 1) / this.packetRate;
    const displayTrainingTime =
      !this.isInfinite(trainingTime) && trainingTime < 604800 ? `~ ${this.util.formatSeconds(trainingTime)?.trim()?.split(' ')?.[0]}` : '> 7 days';
    this.form.setControlValue(FORM_PARAMS.TRAINING_TIME, displayTrainingTime);
  }

  isInfinite(value: any) {
    return value == Number.POSITIVE_INFINITY || value == Number.NEGATIVE_INFINITY;
  }

  get isCurrentStepValid() {
    switch (this.activeStep) {
      case 0: {
        return (
          this.form?.getControl(FORM_PARAMS.NAME)?.valid &&
          this.form?.getControl(FORM_PARAMS.DESCRIPTION)?.valid &&
          (!this.analyzer ? this.form?.getControl(FORM_PARAMS.CONNECTION_IDS)?.valid : true) &&
          this.form?.getControl(FORM_PARAMS.PROTOCOL)?.valid &&
          this.form?.getControl(FORM_PARAMS.DATA_STRUCTURE_ID)?.valid &&
          this.form?.getControl(FORM_PARAMS.ENABLED)?.valid
        );
      }
      default: {
        return false;
      }
    }
  }
}
