export enum ORGANIZATION_MANAGEMENT_GROUPS {
  ORGANIZATION_SETTINGS = 'Organization Settings',
}

export const ORGANIZATION_MANAGEMENT_CONSTANTS = {
  PROJECTS: {
    ROUTE: 'projects',
    LABEL: 'Projects',
  },
  NOTIFICATIONS: {
    ROUTE: 'notifications',
    LABEL: 'Notifications',
  },
  SETTINGS: {
    ROUTE: 'settings',
    LABEL: 'Settings',
  },
  USER_ACCESS: {
    ROUTE: 'user-access',
    LABEL: 'User Access',
  },
  ENVELOPES: {
    ROUTE: 'envelopes',
    LABEL: 'Envelopes',
  },
  CA_TEMPLATES: {
    ROUTE: 'ca-templates',
    LABEL: 'CA Templates',
  },
  WORKFLOWS: {
    ROUTE: 'workflows',
    LABEL: 'Workflows',
  },
  LABELS: {
    ROUTE: 'labels',
    LABEL: 'Labels',
  },
  SNORT_SETTINGS: {
    ROUTE: 'snort-settings',
    LABEL: 'Snort Settings',
  },
};
