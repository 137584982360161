import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportSpecification } from '@microsec/models';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent } from '@microsec/components';
import { ActionMenuItem } from '@microsec/models';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ArrayToPlaceholderStringPipe, ConstantPipe, MomentPipe } from '@ids-pipes';
import { FirmwareService } from '@ids-services';

import { BehaviorSubject, finalize } from 'rxjs';
import { DELETE_LABEL } from '@microsec/constants';
import { FirmwareUploadFormComponent } from '../../../../shared-components/shared-firmwares/firmware-upload-form/firmware-upload-form.component';

const FIELDS = {
  id: 'ID',
  name: 'Name',
  target_device: 'Target Device',
  version: 'Version',
  created_date: 'Uploaded',
  internal_path: 'Internal Path',
  size: 'Size',
  signed: 'Signed',
  signed_key_id: 'Signed Key ID',
  checksum: 'Checksum',
  sign_mode: 'Sign Mode',
};

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.scss'],
  providers: [MomentPipe, ConstantPipe, ArrayToPlaceholderStringPipe],
})
export class FirmwaresComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  cols: any[] = [
    { field: 'name', header: FIELDS.name, width: 10 },
    { field: 'target_device', header: FIELDS.target_device, width: 12 },
    { field: 'version', header: FIELDS.version, width: 10 },
    { field: 'created_date', header: FIELDS.created_date, width: 15 },
  ];

  globalFilterFields: string[] = ['name', 'target_device', 'version'];

  values: any[] = [];

  selectedFirmware: any = null;

  supportedDevices: any[] = [];

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
    hideClearFilters: false,
  };

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private momentPipe: MomentPipe,
    private constantPipe: ConstantPipe,
    private firmwareSrv: FirmwareService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(this.USER_ROLE.READ_ONLY, true)) {
      this.getFirmwares();
      this.getSupportedDevice();
    }
    this.handleFilterObjUpdate();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Download',
        icon: 'fa fa-download',
        command: ({ rowData }) => this.downloadFirmware(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        command: ({ rowData }) => this.openDeleteConfirmation(rowData),
      },
    ];
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result?.search || '';
      }
    });
  }

  unselectFirmware(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedFirmware = null;
  }

  /**
   * Get all firmwares from project
   */
  getFirmwares() {
    this.isLoading = true;
    this.firmwareSrv
      .getFirmwares(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.values = this.util.sortObjectArray(rs as any[], 'name');
          if (!!this.selectedFirmware) {
            this.selectedFirmware = this.values.find((firmware) => firmware.id === this.selectedFirmware.id) || null;
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  getSupportedDevice() {
    this.firmwareSrv
      .getSupportedDevices()
      .pipe()
      .subscribe({
        next: (rs) => {
          this.supportedDevices = ((rs as any[]) || []).map((device: any) => ({
            ...device,
            value: device.name,
            label: device.name,
          }));
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  openFirmwareForm() {
    const dialog = this.dialogSrv.open(FirmwareUploadFormComponent, {
      data: {
        supportedDevices: this.supportedDevices,
      },
      header: 'Upload Firmware',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getFirmwares();
      }
    });
  }

  /**
   * Handle actions: delete, download, refresh
   * @param event
   */
  handleAction(action: string) {
    switch (action) {
      case 'delete': {
        this.openDeleteConfirmation(this.selectedFirmware);
        break;
      }
      case 'download': {
        this.downloadFirmware(this.selectedFirmware);
        break;
      }
      case 'refresh': {
        this.getFirmwares();
        break;
      }
      default: {
        break;
      }
    }
  }

  openDeleteConfirmation(firmware: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Firmware',
      object: firmware,
      objectFieldNameFunction: (object) => `Firmware ID ${object.id} ${!!object.name ? ` : ${object.name}` : ''}`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.firmwareSrv.deleteFirmware(firmware.id, this.breadcrumbConfig?.organizationId),
      next: () => {
        this.showSuccessMessage(`Deleted firmware ${firmware.id} successfully`);
        this.getFirmwares();
        this.isLoading = false;
      },
      error: (err) => {
        this.showErrorMessage(err);
        this.isLoading = false;
      },
    });
  }

  /**
   * Download firmware file
   * @param firmware
   */
  downloadFirmware(firmware: any) {
    this.firmwareSrv.downloadFirmware(firmware.id).subscribe({
      next: (rs) => {
        if (!!rs) {
          this.showSuccessMessage(`Downloaded firmware ${firmware.name} successfully`);
          this.util.downloadClientFile(firmware.name, rs);
        } else {
          this.showErrorMessage(`Cannot downloaded firmware ${firmware.name}`);
        }
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const data = (specification.data || []).map((project) => ({
          ...project,
          target_device: this.constantPipe?.transform(project.target_device, 'firmware-target-device'),
          created_date: this.momentPipe?.transform(project.created_date),
        }));
        this.dt.generateReportDialog.exportReport(data, `project_${this.breadcrumbConfig?.projectId}_firmwares`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedFirmware = null;
  }
}
