import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { CommonOverviewComponent } from '@microsec/components';
import { ChartHelper } from '@microsec/utilities';
import { CommonChart } from '@microsec/models';
import { TargetDeviceService } from '@ids-services';
import { BaseComponent } from '@ids-components';
import { INTERFACE_TYPE_LABELS } from '@ids-constants';
import { ConnectionService } from '@ids-services';
import { ConstantPipe } from '@ids-pipes';

import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { finalize, forkJoin } from 'rxjs';

import { COMMON_DOUGHNUT_LAYOUT_OPTIONS, DATA_BLOCKS, DIAGRAMS } from './dashboard-devices.config';

@Component({
  selector: 'app-dashboard-devices',
  templateUrl: './dashboard-devices.component.html',
  styleUrls: ['./dashboard-devices.component.scss'],
  providers: [ConstantPipe],
})
export class DashboardDevicesComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  summary: any = null;

  connectionNames: any = {};

  /**
   * Data blocks
   */
  blocks: any[] = [];

  /**
   * Chart data + options
   */
  charts: CommonChart[] = [];

  /**
   * Diagram constants
   */
  DIAGRAMS = DIAGRAMS;

  /**
   * Diagram UIs
   */
  @ViewChild('dashboardDevicesComponent') dashboardDevicesComponent!: CommonOverviewComponent;

  constructor(
    private constantPipe: ConstantPipe,
    private targetDeviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initTemplates();
    this.initData();
  }

  /**
   * Initialize data
   */
  async initData() {
    await this.prepareConfigs();
    const subscription = forkJoin({
      connections: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      summary: this.targetDeviceSrv.getDeviceSummary(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.connectionNames = ((res?.connections?.data as any[]) || []).reduce((a, v) => ({ ...a, [v.id]: v.name }), {});
          this.summary = res.summary;
          this.initTemplates();
          this.charts.forEach((chart) => {
            this.updateChartData(chart);
          });
          this.generateDataBlocks();
          this.redrawDiagram();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Generate block data
   */
  private generateDataBlocks() {
    const blocks: any[] = [];
    Object.entries(this.util.cloneDeepObject(DATA_BLOCKS)).forEach(([key, entry]) => {
      let value = 0;
      switch (key) {
        case DATA_BLOCKS.TOTAL.KEY: {
          value = this.summary?.devices?.total || 0;
          break;
        }
        case DATA_BLOCKS.DETECTED_PAST_DAY.KEY: {
          value = ((this.summary?.devices?.detected_past_day as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.DETECTED_PAST_WEEK.KEY: {
          value = ((this.summary?.devices?.detected_past_week as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.DETECTED_PAST_MONTH.KEY: {
          value = ((this.summary?.devices?.detected_past_month as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.AGENT.KEY: {
          value = ((this.summary?.devices?.agent as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ACTIVE_PAST_HOUR.KEY: {
          value = ((this.summary?.devices?.active_past_hour as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ACTIVE_PAST_DAY.KEY: {
          value = ((this.summary?.devices?.active_past_day as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ACTIVE_PAST_WEEK.KEY: {
          value = ((this.summary?.devices?.active_past_week as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.ACTIVE_PAST_MONTH.KEY: {
          value = ((this.summary?.devices?.active_past_month as any[]) || []).length;
          break;
        }
        case DATA_BLOCKS.AGENTLESS.KEY: {
          value = ((this.summary?.devices?.agentless as any[]) || []).length;
          break;
        }
        default: {
          break;
        }
      }
      blocks.push({
        value,
        label: (entry as any).LABEL,
        color: (entry as any).COLOR,
      });
    });
    this.blocks = blocks;
  }

  /**
   * Redraw the diagram UI(s)
   * @param chart
   */
  private redrawDiagram(chart?: CommonChart) {
    if (!!this.dashboardDevicesComponent?.diagrams) {
      if (!chart) {
        this.dashboardDevicesComponent.diagrams.forEach((diagram) => {
          setTimeout(() => {
            diagram.redraw();
          });
        });
      } else {
        const diagram = this.dashboardDevicesComponent.diagrams.find((p) => p.chartConfig.key === chart.key);
        setTimeout(() => {
          diagram?.redraw();
        });
      }
    }
  }

  private initTemplates() {
    // charts
    const charts: any[] = [];
    Object.entries(DIAGRAMS).forEach(([key, value]) => {
      let chartOptions: ChartOptions | null = null;
      switch (value.TYPE) {
        case 'doughnut': {
          chartOptions = this.util.cloneDeepObject(COMMON_DOUGHNUT_LAYOUT_OPTIONS) as ChartOptions;
          break;
        }
        default: {
          break;
        }
      }
      const chart = this.util.cloneDeepObject(
        {
          type: value.TYPE,
          key,
          label: value.LABEL,
          data: {} as ChartData,
          options: chartOptions,
          children: !!value.CHILDREN.length ? this.util.cloneObjectArray(value.CHILDREN, true) : this.getDefaultChartChildren(key),
          legendCols: value.TYPE === 'doughnut' ? this.getLegendCols() : [],
          unitsetup: null,
          link: !!(value as any).LINK
            ? {
                ...(value as any).LINK,
                URL: this.getLink((value as any)?.LINK?.URL),
              }
            : null,
        },
        true,
      ) as CommonChart;
      charts.push(chart);
    });
    this.charts = charts;
  }

  private getLink(itemUrl: string) {
    const currentUrlArr = this.router.routerState?.snapshot?.url?.split('/');
    const rootUrlArr = currentUrlArr.filter((p, i) => i !== currentUrlArr.length - 1 && i !== currentUrlArr.length - 2);
    return `${rootUrlArr.join('/')}/${itemUrl}`;
  }

  private getDefaultChartChildren(chartKey: string) {
    const results: any[] = [];
    switch (chartKey) {
      case DIAGRAMS.DEVICES_BY_TYPE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.device_type_distribution || [], 'device_type'));
        break;
      }
      case DIAGRAMS.DEVICES_BY_NETWORK_MAP_LEVEL.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.network_map_level_distribution || [], 'level'));
        break;
      }
      case DIAGRAMS.DEVICES_BY_CONNECTION.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.connection_distribution || [], 'connection_id'));
        break;
      }
      case DIAGRAMS.DEVICES_BY_INTERFACE_TYPE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.interface_type_distribution || [], 'interface'));
        break;
      }
      case DIAGRAMS.DEVICES_BY_APPLICATION_PROTOCOL.KEY: {
        const sortedApplicationProtocols = this.util.sortObjectArray(
          this.summary?.application_protocol_distribution || [],
          'devices',
          false,
          false,
          true,
        );
        results.push(...ChartHelper.autoGenerateChildren(sortedApplicationProtocols || [], 'application_protocol'));
        break;
      }
      case DIAGRAMS.DEVICES_BY_MANUFACTURER.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.manufacturer_distribution || [], 'manufacturer'));
        break;
      }
      case DIAGRAMS.DEVICES_BY_OS.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.os_distribution || [], 'os_type'));
        break;
      }
      default: {
        break;
      }
    }
    return results;
  }

  /**
   * Get the legend columns
   */
  getLegendCols() {
    const results: any[] = [
      { field: 'label', header: 'Status', width: 5 },
      { field: 'percent', header: '%', width: 2 },
      { field: 'counter', header: 'Devices', width: 4 },
    ];
    return results;
  }

  /**
   * Generate the data
   * @param chart chart
   */
  updateChartData(chart: CommonChart) {
    chart.total = this.summary?.devices?.total;
    const data = chart.data as ChartData;
    data.labels = [];
    data.datasets = [
      {
        data: this.generateData(chart),
        backgroundColor: [],
        borderWidth: [],
        barPercentage: 0.5,
      } as ChartDataset,
    ];
    this.generateLabels(chart, data);
  }

  /**
   * Generate the chart data
   * @param chart
   * @returns
   */
  private generateData(chart: CommonChart): number[] {
    const values: number[] = [];
    if (!!chart.key) {
      switch (chart.key) {
        case DIAGRAMS.DEVICES_BY_TYPE.KEY: {
          values.push(...(((this.summary?.device_type_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_BY_NETWORK_MAP_LEVEL.KEY: {
          values.push(...(((this.summary?.network_map_level_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_BY_CONNECTION.KEY: {
          values.push(...(((this.summary?.connection_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_BY_INTERFACE_TYPE.KEY: {
          values.push(...(((this.summary?.interface_type_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_BY_APPLICATION_PROTOCOL.KEY: {
          const sortedApplicationProtocols = this.util.sortObjectArray(
            this.summary?.application_protocol_distribution || [],
            'devices',
            false,
            false,
            true,
          );
          values.push(...(((sortedApplicationProtocols as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_BY_MANUFACTURER.KEY: {
          values.push(...(((this.summary?.manufacturer_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        case DIAGRAMS.DEVICES_BY_OS.KEY: {
          values.push(...(((this.summary?.os_distribution as any[]) || [])?.map((p) => ((p.devices as any[]) || []).length) || []));
          break;
        }
        default: {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            switch (chart.key) {
              case DIAGRAMS.DEVICES_BY_STATUS.KEY: {
                values.push(this.summary?.devices?.[item.FIELD_TO_CHECK]?.length || 0);
                break;
              }
              default: {
                break;
              }
            }
          });
          break;
        }
      }
    }
    return values;
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: CommonChart, data: ChartData) {
    if (chart.type === 'doughnut') {
      if (!!chart.key) {
        const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
        children?.forEach((item: any) => {
          // Labels
          switch (chart.key) {
            case DIAGRAMS.DEVICES_BY_INTERFACE_TYPE.KEY: {
              data.labels?.push(INTERFACE_TYPE_LABELS[item.LABEL] || (item.LABEL === 'no_data' ? 'No Data' : item.LABEL));
              break;
            }
            case DIAGRAMS.DEVICES_BY_CONNECTION.KEY: {
              data.labels?.push(this.connectionNames[item.LABEL] || (item.LABEL === 'no_data' ? 'No Data' : item.LABEL));
              break;
            }
            case DIAGRAMS.DEVICES_BY_TYPE.KEY: {
              data.labels?.push(item.LABEL === 'no_data' ? 'No Data' : this.constantPipe.transform(item.LABEL, 'detected-device-type'));
              break;
            }
            case DIAGRAMS.DEVICES_BY_APPLICATION_PROTOCOL.KEY: {
              data.labels?.push(
                item.LABEL === 'no_data' ? 'No Data' : this.constantPipe.transform(item.LABEL, 'detected-device-application-protocols'),
              );
              break;
            }
            default: {
              data.labels?.push(item.LABEL === 'no_data' ? 'No Data' : item.LABEL);
              break;
            }
          }
          const dataset = data.datasets.find((p) => !!p) as ChartDataset;
          // Colors
          if (!!dataset && !!dataset.backgroundColor) {
            (dataset.backgroundColor as string[]).push(item.COLOR);
            (dataset.borderWidth as number[]).push(0);
          }
        });
      }
    }
  }

  /**
   * Get legend label
   * @param value
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return label;
  }
}
