<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="tag ? SAVE_CHANGES_LABEL : CREATE_LABEL"
  (submitEvent)="onSubmit($event)" />

<ng-template #colorField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <microsec-custom-color-picker [(color)]="color" [presetColors]="TAGS_PRESET_COLORS"></microsec-custom-color-picker>
    </ng-container>
  }
</ng-template>
