<div class="force-directed-item-list flex flex-column w-full px-2">
  <!-- Title -->
  <microsec-selected-panel-header [label]="listName" (closeSelectedPanelEvent)="hideItemList()" [isCard]="false"></microsec-selected-panel-header>
  <!-- Tab view -->
  <microsec-card [isDetailCard]="true">
    <p-tabView styleClass="microsec-dashboard-tab" [(activeIndex)]="activeIndex">
      <p-tabPanel header="Devices">
        <app-network-map-device-list
          [mode]="mode"
          [selectedDevice]="selectedDevice"
          (changeSelectedDevice)="changeSelectedDevice($event)"
          [settings]="settings"
          [itemDetails$]="itemDetails$"
          [diagramData$]="diagramData$"
          [changedItems$]="changedItems$"
          [changedItemsObs]="changedItemsObs"
          [settingOptionsObs]="settingOptionsObs"
          [isItemDetailsDisplayed]="isItemDetailsDisplayed"
          [refreshObs]="refreshObs"></app-network-map-device-list>
      </p-tabPanel>
      <p-tabPanel header="Threats">
        <app-network-map-threat-list
          [itemDetails$]="itemDetails$"
          [diagramData$]="diagramData$"
          (changeSelectedDevice)="changeSelectedDevice($event)"
          [settings]="settings"
          [settingOptionsObs]="settingOptionsObs"
          [isItemDetailsDisplayed]="isItemDetailsDisplayed"
          [refreshObs]="refreshObs"></app-network-map-threat-list>
      </p-tabPanel>
    </p-tabView>
  </microsec-card>
</div>
