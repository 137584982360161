<div *ngIf="!!analyzer">
  <div class="grid">
    <div class="col-12 my-3">
      <label>Set up configurations for ruleset selection, custom rules, suppresed rules and IP whitelist</label>
    </div>

    <div class="col-12">
      <p-accordion>
        <p-accordionTab header="Ruleset Selection">
          <div class="grid">
            <div class="col-12 py-2 flex align-items-center gap-3">
              <label>Rule Type:</label>
              <p-dropdown
                [options]="rulesetTypeOptions"
                [(ngModel)]="selectedRulesetType"
                placeholder="Select a rule type"
                optionLabel="label"
                optionValue="value"
                (onChange)="onChangeSelectedRulesetType()"
                appendTo="body"></p-dropdown>
            </div>
            <div class="col-12">
              <microsec-card [isNestedCard]="true" [isTableCard]="true">
                <microsec-common-table [cols]="rulesetCols" [values]="rulesets" [scrollHeight]="'35rem'" [cellTemplate]="cellTemplate">
                </microsec-common-table>
              </microsec-card>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Custom Rules">
          <div class="grid" id="custom-rule">
            <div class="col-12">
              <microsec-card [isNestedCard]="true" [isTableCard]="true">
                <microsec-common-table
                  [(rows)]="customRuleRows"
                  [firstIndex]="customRuleFirstIndex"
                  [(selection)]="selectedCustomRule"
                  [cols]="customRuleCols"
                  [values]="customRules"
                  [scrollHeight]="'35rem'"
                  [containerHeaderRightTemplate]="addCustomRuleTemplate"
                  [cellTemplate]="cellTemplate"
                  [actionsMenuItems]="actionsMenuItems">
                </microsec-common-table>
              </microsec-card>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Suppressed Rules">
          <div class="grid">
            <div class="col-12">
              <microsec-card [isNestedCard]="true" [isTableCard]="true">
                <microsec-common-table
                  [cols]="suppressedRuleCols"
                  [values]="suppressedRules"
                  [scrollHeight]="'35rem'"
                  [containerHeaderRightTemplate]="addSuppresedRuleTemplate"
                  [cellTemplate]="cellTemplate"
                  [actionsMenuItems]="actionsMenuItems">
                </microsec-common-table>
              </microsec-card>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="IP Whitelist">
          <div class="grid">
            <div class="col-12">
              <microsec-card [isNestedCard]="true" [isTableCard]="true">
                <microsec-common-table
                  [cols]="whitelistCols"
                  [values]="whitelists"
                  [scrollHeight]="'35rem'"
                  [containerHeaderRightTemplate]="addIPWhitelistTemplate"
                  [cellTemplate]="cellTemplate"
                  [actionsMenuItems]="actionsMenuItems">
                </microsec-common-table>
              </microsec-card>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>

<ng-template #addCustomRuleTemplate>
  <p-button
    type="button"
    icon="fa fa-plus"
    severity="success"
    size="small"
    pTooltip="Add Custom Rules"
    tooltipPosition="bottom"
    appendTo="body"
    (onClick)="openForm('custom-rule')"
    *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></p-button>
</ng-template>

<ng-template #addSuppresedRuleTemplate>
  <p-button
    type="button"
    icon="fa fa-plus"
    severity="success"
    size="small"
    pTooltip="Add Suppressed Rules"
    tooltipPosition="bottom"
    appendTo="body"
    (onClick)="openForm('suppressed-rule')"
    *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></p-button>
</ng-template>

<ng-template #addIPWhitelistTemplate>
  <p-button
    type="button"
    icon="fa fa-plus"
    severity="success"
    size="small"
    pTooltip="Add IP Whitelist"
    tooltipPosition="bottom"
    appendTo="body"
    (onClick)="openForm('whitelist')"
    *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></p-button>
</ng-template>

<ng-template let-index="index" let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <ng-container *ngSwitchCase="'enabled'">
      <p-inputSwitch [(ngModel)]="rowData[col.field]" (onChange)="updateEnabled(rowData, rowData['type'])"></p-inputSwitch>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ rowData[col.field] || '-' }}
    </ng-container>
  </ng-container>
</ng-template>
