import { Component } from '@angular/core';

@Component({
  selector: 'app-system-snort-settings',
  templateUrl: './system-snort-settings.component.html',
  styleUrls: ['./system-snort-settings.component.scss'],
})
export class SystemSnortSettingsComponent {
  constructor() {}
}
