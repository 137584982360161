/* eslint-disable @typescript-eslint/no-explicit-any */
import { EVENT_CRITICALITIES } from '@ids-constants';
import { Util, XlsxHelper } from '@microsec/utilities';
import { MOMENT_DATETIME, FILE_EXTENSION } from '@microsec/constants';
import moment from 'moment';
import { EventMonitorAction, EventMonitorActionTypes } from './event-monitor.actions';

export const EVENT_MONITOR_FEATURE_KEY = 'event-monitor';

export interface EventMonitorState {
  isCategoriesLoaded: boolean;
  categories?: any[];
  subCategories?: any[];
  isOrganizationsLoaded: boolean;
  organizations?: any[];
  isUsersLoaded: boolean;
  users?: any[];
  isDevicesLoading: boolean;
  devices?: any[];
  totalEventNumber: number;
  events?: any[];
  selectedEvent?: any;
  isLoading: boolean;
  error?: string | null;
  lastRefresh?: Date | null;
  filters?: any;
  searchText?: string;
  page?: any;
  perPage?: any;
}
export const initialEventMonitorState: EventMonitorState = {
  isCategoriesLoaded: false,
  categories: [],
  subCategories: [],
  isOrganizationsLoaded: false,
  organizations: [],
  isUsersLoaded: false,
  users: [],
  isDevicesLoading: false,
  devices: [],
  totalEventNumber: 0,
  events: [],
  selectedEvent: null,
  error: null,
  isLoading: false,
  lastRefresh: null,
  filters: null,
  searchText: '',
  page: 1,
  perPage: 20,
};

export function eventsReducer(state: EventMonitorState = initialEventMonitorState, action: EventMonitorAction): EventMonitorState {
  // Helper functions
  const transformData = (events: any[]) => {
    return events.map((eventData) => ({
      ...eventData,
      eventLabel: state.subCategories?.find((p) => p.value === eventData.event)?.label || eventData.event,
      categoryLabel: state.categories?.find((p) => p.value === eventData.category)?.label || eventData.category,
      details: eventData?.payload?.title || '',
      criticalityLabel: EVENT_CRITICALITIES.find((p) => p.value === eventData.criticality)?.label || eventData.criticality,
      organizationLabel: state.organizations?.find((p) => p.value === eventData.org_id)?.label || `Unknown Organization ${eventData.org_id}`,
      userLabel:
        state.users?.find((p) => p.value == eventData.user_id)?.label ||
        (eventData.user_id === 0 || eventData.user_id === 'None' ? 'System' : `Unknown User ${eventData.user_id}`),
      payload: {
        ...eventData?.payload,
        details: (eventData?.payload?.details || '').replace(/\n/g, '<br />'),
      },
    }));
  };
  // Reducers
  switch (action.type) {
    case EventMonitorActionTypes.INIT: {
      state = Util.cloneDeepObject(initialEventMonitorState);
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_CATEGORIES: {
      state = { ...state, isCategoriesLoaded: false };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_CATEGORIES_SUCCESS: {
      const events = action.result?.all_events;
      let categories: any[] = [];
      const subCategories: any[] = [];
      Object.keys(events).forEach((key1: any) => {
        const subEvents = events[key1].events;
        if (subEvents) {
          categories.push({ value: key1, label: events[key1].label });
          Object.keys(subEvents).forEach((key2: any) => {
            subCategories.push({
              value: key2,
              label: subEvents[key2],
            });
          });
        }
      });
      categories = Util.sortObjectArray(categories, 'label');
      categories.unshift({ value: null, label: '--- All Categories ---' });
      state = {
        ...state,
        categories,
        subCategories,
        isCategoriesLoaded: true,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_CATEGORIES_FAILURE: {
      state = { ...state, isCategoriesLoaded: true };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS: {
      state = { ...state, isOrganizationsLoaded: false };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS_SUCCESS: {
      const organizations = Util.sortObjectArray(action.result, 'name').map((p) => ({
        ...p,
        value: p.id,
        label: p.name,
      }));
      organizations.unshift({
        value: null,
        label: '--- All Organizations ---',
      });
      state = {
        ...state,
        organizations,
        isOrganizationsLoaded: true,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_ORGANIZATIONS_FAILURE: {
      state = { ...state, isOrganizationsLoaded: true };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_USERS: {
      state = { ...state, isUsersLoaded: false };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_USERS_SUCCESS: {
      const users = Util.sortObjectArray(action.result, 'username').map((p) => ({
        ...p,
        value: p.id,
        label: p.username,
      }));
      users.unshift({ value: 0, label: 'System' });
      users.unshift({ value: null, label: '--- All Users ---' });
      state = {
        ...state,
        users,
        isUsersLoaded: true,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_USERS_FAILURE: {
      state = { ...state, isUsersLoaded: true };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_DEVICES: {
      state = {
        ...state,
        devices: [],
        isDevicesLoading: true,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_DEVICES_SUCCESS: {
      const devices = Util.sortObjectArray(action.result, 'name').map((p) => ({
        ...p,
        value: p.id,
        label: p.name,
      }));
      devices.unshift({ value: '', label: '--- All Devices ---' });
      state = {
        ...state,
        devices,
        isDevicesLoading: false,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENT_DEVICES_FAILURE: {
      state = {
        ...state,
        devices: [],
        isDevicesLoading: false,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENTS: {
      state = {
        ...state,
        isLoading: true,
        error: null,
        lastRefresh: null,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENTS_SUCCESS: {
      const result = action.result;
      const events = transformData((result?.event_logs as any[]) || []);
      state = {
        ...state,
        events,
        totalEventNumber: result?.total_records,
        isLoading: false,
        error: null,
        lastRefresh: new Date(),
        searchText: action.payload.searchText,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
      break;
    }
    case EventMonitorActionTypes.GET_EVENTS_FAILURE: {
      const { error } = action;
      state = {
        ...state,
        isLoading: false,
        error: error,
        lastRefresh: new Date(),
      };
      break;
    }
    case EventMonitorActionTypes.SELECT_EVENT: {
      state = {
        ...state,
        selectedEvent: action.selectedEvent,
      };
      break;
    }
    case EventMonitorActionTypes.EXPORT_EVENTS: {
      state = {
        ...state,
        isLoading: true,
      };
      break;
    }
    case EventMonitorActionTypes.EXPORT_EVENTS_SUCCESS: {
      const events = transformData(action.results || []);
      XlsxHelper.exportSingleSheet(
        events.map((event: any) => ({
          ID: event.id,
          Event: event.eventLabel,
          Category: event.categoryLabel,
          Details: event.payload?.title,
          Criticality: event.criticalityLabel,
          Organization: event.organizationLabel,
          Username: event.userLabel,
          Timestamp: event.timestamp ? moment(event.timestamp).format(MOMENT_DATETIME) : '',
        })),
        `Event_Logs_Report_${moment().format(MOMENT_DATETIME).replace(/-/g, '_').replace(/ /g, '_').replace(/:/g, '_')}`,
        FILE_EXTENSION.XLSX,
      );
      state = {
        ...state,
        isLoading: false,
      };
      break;
    }
    case EventMonitorActionTypes.EXPORT_EVENTS_FAILURE: {
      state = {
        ...state,
        isLoading: false,
      };
      break;
    }
  }
  return state;
}
