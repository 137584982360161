import { Component, Input } from '@angular/core';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-fl-analyzer-progress',
  templateUrl: './fl-analyzer-progress.component.html',
  styleUrls: ['./fl-analyzer-progress.component.scss'],
})
export class FlAnalyzerProgressComponent {
  @Input() analyzer: any = null;

  items: MenuItem[] = [
    {
      label: 'Training',
    },
    {
      label: 'Prediction',
      icon: 'fas fa-infinity text-xl',
    },
  ];

  activeStep = 0;
}
