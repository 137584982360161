import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ANALYZER_DATA_TYPES, ANALYZER_TYPES, MODES } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService } from '@ids-services';

import { finalize } from 'rxjs';

@Component({
  selector: 'app-fl-analyzer-details',
  templateUrl: './fl-analyzer-details.component.html',
  styleUrls: ['./fl-analyzer-details.component.scss'],
})
export class FlAnalyzerDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  activeIndex = 0;

  _analyzerId: any = null;

  get analyzerId() {
    return this._analyzerId;
  }

  @Input() set analyzerId(value: any) {
    this._analyzerId = value;
    if (!!this._analyzerId) {
      this.getAnalyzer();
    }
  }

  analyzer: any = {};

  @Input() connections: any[] = [];

  @Output() analyzerChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() hidePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() panelHeaderRightTemplate: TemplateRef<any> | null = null;

  ANALYZER_TYPES = ANALYZER_TYPES;

  ANALYZER_DATA_TYPES = ANALYZER_DATA_TYPES;

  checkInterval: any;

  constructor(public anomalyAnalyzerSrv: AnomalyAnalyzerService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkInterval = setInterval(() => {
      if (!!this.analyzerId) {
        this.getAnalyzer(false);
      }
    }, 5000);
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.anomalyAnalyzerSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !!this.analyzerId) {
        this.getAnalyzer();
      }
    });
    this.subscriptions.push(subscription);
  }

  getAnalyzer(showLoading = true) {
    if (!!showLoading) {
      this.isLoading = true;
    }
    this.anomalyAnalyzerSrv
      .getFlAnalyzer(this.analyzerId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const protocols: string[] = [];
          const attackTypes: { [key: string]: boolean } = {};
          ((rs.protocols as any[]) || []).forEach((p) => {
            protocols.push(p.protocol);
            ((p.attacks as any[]) || [])
              .filter((a) => !!a.enabled)
              .forEach((a) => {
                attackTypes[a.attack_type] = true;
              });
          });
          const flModels = ((rs.fl_models as any[]) || []).filter(
            (model) => !!model.enabled || (model.rounds_completed || 0) >= (model.rounds_total || 0),
          );
          const roundsCompleted = flModels.reduce((sum, model) => sum + (model.rounds_completed || 0), 0);
          const roundsTotal = flModels.reduce((sum, model) => sum + (model.rounds_total || 0), 0);
          const trainingProgress = (roundsCompleted / roundsTotal) * 100 || 0;
          const analyzer = {
            ...(rs || {}),
            connectionNames: this.connections.filter((conn) => (rs.connection_ids || []).includes(conn.id)).map((conn) => conn.name),
            protocolNames: protocols,
            attackTypeNames: Object.keys(attackTypes),
            roundsCompleted,
            roundsTotal,
            trainingProgress,
            mode: trainingProgress < 100 ? MODES.TRAINING : MODES.PREDICTION,
          };
          this.analyzer = analyzer;
          this.analyzerChangeEvent.emit(this.analyzer);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  override ngOnDestroy(): void {
    this.cleanup();
    clearInterval(this.checkInterval);
  }
}
