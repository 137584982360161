<!-- BUTTONS -->
<app-network-map-buttons
  [(mode)]="mode"
  [(settings)]="settings"
  [settingOptions$]="$any(settingOptions$)"
  [diagramDataObs]="diagramDataObs"
  [isItemDetailsDisplayed]="$any(isItemDetailsDisplayed)"
  [isItemListDisplayed]="$any(isItemListDisplayed)">
</app-network-map-buttons>

<!-- MAIN CONTENT -->
<div class="main-content">
  <div class="network-map-main-container relative">
    <div class="diagram-wrapper relative">
      <div class="diagram-container overflow-hidden absolute">
        <ng-container [ngSwitch]="mode">
          <!-- Force Directed -->
          <ng-container *ngSwitchCase="NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED">
            <app-force-directed
              [(selectedDevice)]="selectedDevice"
              [itemDetails$]="itemDetails$"
              [diagramDataObs]="diagramDataObs"
              [zoom$]="zoom$"
              [refresh$]="refresh$">
            </app-force-directed>
          </ng-container>
          <!-- Purdue Model -->
          <ng-container *ngSwitchCase="NETWORK_MAP_MODE_VALUES.PURDUE_MODEL">
            <app-purdue-model
              [(selectedDevice)]="selectedDevice"
              [settings]="settings"
              [itemDetails$]="itemDetails$"
              [diagramData$]="diagramData$"
              [diagramDataObs]="diagramDataObs"
              [settingOptionsObs]="settingOptionsObs"
              [zoom$]="zoom$"
              [refresh$]="refresh$">
            </app-purdue-model>
          </ng-container>
          <!-- Default  -->
          <ng-container *ngSwitchDefault> </ng-container>
        </ng-container>
      </div>

      <div class="zoom-container flex flex-column absolute">
        <p-button
          [rounded]="true"
          styleClass="tool-button"
          icon="fa fa-refresh"
          pTooltip="Refresh"
          tooltipPosition="left"
          appendTo="body"
          (onClick)="refresh()">
        </p-button>
        <p-button
          [rounded]="true"
          styleClass="tool-button"
          icon="fa fa-magnifying-glass-plus"
          pTooltip="Zoom In"
          tooltipPosition="left"
          appendTo="body"
          (onClick)="updateZoom(1)">
        </p-button>
        <p-button
          [rounded]="true"
          styleClass="tool-button"
          icon="fa fa-magnifying-glass-minus"
          pTooltip="Zoom Out"
          tooltipPosition="left"
          appendTo="body"
          (onClick)="updateZoom(-1)">
        </p-button>
        <p-button
          [rounded]="true"
          styleClass="tool-button"
          icon="fa fa-magnifying-glass-location"
          pTooltip="Zoom to Location"
          tooltipPosition="left"
          appendTo="body"
          (onClick)="updateZoom(0)">
        </p-button>
      </div>
    </div>
  </div>

  <!-- LIST PANEL -->
  <!-- Expanded mode  -->
  <div
    [style]="{ width: isItemListDisplayed === true || isItemListDisplayed === null ? 'initial' : '0px !important' }"
    class="list border-left-1 w-5 fixed overflow-auto animation-duration-500 shadow-2"
    [ngClass]="{
      animate__slideInRight: isItemListDisplayed === true,
      animate__slideOutRight: isItemListDisplayed === null
    }">
    <app-network-map-item-list
      [mode]="mode"
      [(selectedDevice)]="selectedDevice"
      [itemList$]="itemList$"
      [settings]="settings"
      [itemDetails$]="itemDetails$"
      [diagramData$]="diagramData$"
      [changedItems$]="changedItems$"
      [changedItemsObs]="changedItemsObs"
      [settingOptionsObs]="settingOptionsObs"
      [isItemDetailsDisplayed]="$any(isItemDetailsDisplayed)"
      [refreshObs]="refreshObs"></app-network-map-item-list>
  </div>
  <!-- Collapsed mode -->
  <div
    class="list collapsed border-left-1 fixed overflow-auto animation-duration-500 px-1"
    [ngClass]="{
      animate__slideInRight: isItemListDisplayed === false || isItemListDisplayed === null
    }"
    *ngIf="isItemListDisplayed === false">
    <div class="w-4rem text-data text-2xl text-center p-3 border-bottom-2 border-800 cursor-pointer" style="margin-top: 5px" (click)="showItemList()">
      <i class="fa fa-angle-double-left"></i>
    </div>
  </div>
  <!-- DETAILS PANEL -->
  <div
    class="details border-left-1 w-5 fixed overflow-auto animation-duration-500 shadow-2"
    *ngIf="isItemDetailsDisplayed !== false"
    [ngClass]="{
      animate__slideInRight: isItemDetailsDisplayed === true,
      animate__slideOutRight: isItemDetailsDisplayed === null
    }">
    <app-network-map-item-details
      (changeSelectedDevice)="changeSelectedDevice($event)"
      [itemDetails$]="itemDetails$"
      [itemDetailsObs]="itemDetailsObs"
      [changedItems$]="changedItems$"
      [refresh$]="refresh$"></app-network-map-item-details>
  </div>
</div>
