<div class="flex flex-column gap-2">
  <div class="grid field">
    <label class="col-12 lg:col-4 md:col-4 sm:col-12 xl:col-3 text-data">Compliance Standard</label>
    <div class="col">
      <p-dropdown
        [options]="COMPLIANCE_STANDARD_OPTIONS"
        [(ngModel)]="complianceStandard"
        styleClass="filter-dropdown"
        optionLabel="label"
        optionValue="value"
        appendTo="body" />
    </div>
  </div>
  <div>
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [cols]="cols"
        [values]="values"
        [cellTemplate]="cellTemplate"
        [selectable]="false"
        [showContainerHeader]="false"
        [paginator]="false"
        [noSortingCols]="['label', 'targetLevel', 'capableLevel', 'achievedLevel']">
      </microsec-common-table>
    </microsec-card>
  </div>
  <div class="text-grey font-italic" *ngIf="infoMessage">
    <label>{{ infoMessage.first }}</label>
    <br />
    <label>{{ infoMessage.second }}</label>
  </div>
</div>

<ng-template let-col="col" let-rowData="rowData" let-index="index" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Target Security Level -->
    <ng-container *ngSwitchCase="'targetLevel'">
      @if (!!readOnly) {
        <span>{{ rowData[col.field] !== null ? rowData[col.field] : '-' }}</span>
      } @else {
        <p-dropdown
          (click)="$event.stopPropagation()"
          appendTo="body"
          [options]="SECURITY_LEVEL_OPTIONS"
          optionLabel="label"
          optionValue="value"
          [placeholder]="!!shouldShowPlaceholder ? 'Enter Level' : ''"
          [(ngModel)]="rowData.targetLevel"
          (onChange)="onChangeLevel('target', index, rowData)">
        </p-dropdown>
      }
    </ng-container>
    <!-- Capability Security Level -->
    <ng-container *ngSwitchCase="'capableLevel'">
      @if (!!readOnly) {
        <span>{{ rowData[col.field] !== null ? rowData[col.field] : '-' }}</span>
      } @else {
        <p-dropdown
          (click)="$event.stopPropagation()"
          appendTo="body"
          [options]="SECURITY_LEVEL_OPTIONS"
          optionLabel="label"
          optionValue="value"
          [placeholder]="!!shouldShowPlaceholder ? 'Enter Level' : ''"
          [(ngModel)]="rowData.capableLevel"
          (onChange)="onChangeLevel('capable', index, rowData)">
        </p-dropdown>
      }
    </ng-container>
    <!-- Achieved Security Level -->
    <ng-container *ngSwitchCase="'achievedLevel'">
      {{ rowData[col.field] !== null ? rowData[col.field] : '-' }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden text-overflow-ellipsis" [ngClass]="{ 'white-space-nowrap': !shouldTableWrapped }">
        {{ rowData[col.field] }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
