import { AfterViewInit, Component, Input, TemplateRef } from '@angular/core';
import { ASSESSMENT_RESULTS, ATTACHMENT_FILE_ICONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';

import { BehaviorSubject, finalize } from 'rxjs';
import { FilterService } from 'primeng/api';
import { AssessmentService } from '@ids-services';

@Component({
  selector: 'app-assessment-report-question',
  templateUrl: './question-overview.component.html',
  styleUrls: ['./question-overview.component.scss'],
})
export class QuestionOverviewComponent extends BaseComponent implements AfterViewInit {
  filteredSections: any[] = [];

  @Input() isDevice = false;

  @Input() hideMitigations = false;

  @Input() showScoreSL = false;

  _sections: any[] = [];

  get sections() {
    return this._sections;
  }

  @Input() set sections(value: any[]) {
    this._sections = value;
    this.filterSections();
  }

  @Input() allDevices: any[] = [];

  @Input() rightTemplate?: TemplateRef<any>;

  foundationalRequirements: any = {};

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search Title, or Description...',
    hideResetSortOption: true,
  };

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  isLoadingDownload = false;

  ASSESSMENT_RESULTS = ASSESSMENT_RESULTS;

  ATTACHMENT_FILE_ICONS = ATTACHMENT_FILE_ICONS;

  constructor(
    private filterService: FilterService,
    private assessmentSrv: AssessmentService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.filterObjectObs.subscribe((values) => {
      this.filterSearch = values?.search || '';
      this.filterSections();
    });
  }

  filterSections() {
    const filteredSections: any = (this.sections || [])
      .map((section) => {
        return {
          ...section,
          questions: this.filterService.filter(
            section.questions || [],
            ['question_title', 'question_description', 'result'],
            !(this.filterService.filters as any)?.contains(section.section_name, this.filterSearch || '') ? this.filterSearch || '' : '',
            'contains',
          ),
        };
      })
      .filter(
        (section) => (this.filterService.filters as any)?.contains(section.section_name, this.filterSearch || '') || !!section.questions?.length,
      );
    this.filteredSections = filteredSections;
  }

  getResultClass(result: string, isButton = false) {
    switch (result) {
      case ASSESSMENT_RESULTS.COMPLIANT:
        return isButton ? 'success' : ' green';
      case ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT:
        return isButton ? 'warning' : ' orange';
      case ASSESSMENT_RESULTS.NOT_COMPLIANT:
        return isButton ? 'danger' : ' red';
      default:
        return isButton ? 'secondary' : ' grey';
    }
  }

  downloadAttachment(attachment: any) {
    this.isLoadingDownload = true;
    this.assessmentSrv
      .downloadAssessmentAttachment(attachment?.id)
      .pipe(
        finalize(() => {
          this.isLoadingDownload = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.showSuccessMessage('Downloaded Attachment Successfully');
          this.util.downloadClientFile(`${attachment?.file_name}.${attachment?.file_type}`, res);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  onToggleQuestionDetails(question: any) {
    if (!!question) {
      question.showDetail = !question.showDetail;
      question.showDetailDescription = !!question.showDetail;
    }
  }
}
