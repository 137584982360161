<div class="flex flex-column gap-3">
  <div>
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" />
  </div>

  <div>
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="cols"
        [values]="values"
        [loading]="isLoading"
        selectionMode="multiple"
        [(selection)]="selectedTags"
        [cellTemplate]="cellTemplate"
        [dataKey]="'id'"
        itemName="Tag"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <p-button
      label="Show Selected"
      [severity]="!!isFilterSelected ? 'primary' : 'secondary'"
      [rounded]="true"
      styleClass="text-transform-none"
      (onClick)="filterSelected()"></p-button>
    <microsec-refresh-button (refreshEvent)="getTags()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Name -->
    <ng-container *ngSwitchCase="'label'">
      <div class="custom-chip" [style]="{ background: util.hexToRgba(rowData.color), color: rowData.color }">
        {{ rowData.label }}
      </div>
    </ng-container>
    <!-- Description -->
    <ng-container *ngSwitchCase="'description'">
      <div class="flex flex-wrap">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
