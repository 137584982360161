import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { WORKFLOWS_CHILDREN } from '@microsec/module-workflows';
import { of, switchMap } from 'rxjs';
import { ConnectionService, TargetDeviceService } from '@ids-services';

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss'],
})
export class WorkflowsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    private connectionSrv: ConnectionService,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const checkedItems = this.util.cloneObjectArray(WORKFLOWS_CHILDREN).filter((p) => !!p.label);
    checkedItems.forEach((item) => {
      item.visible = true;
    });
    this.store.dispatch(new fromLayoutActions.UpdateTabItems(checkedItems));
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }

  triggerAPIFilterCallback = (command: string) => {
    switch (command) {
      case 'get_connection_endpoint':
        return this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId).pipe(
          switchMap((res: any) => {
            return of(res.data.map((connection: any) => ({ label: connection.name, value: connection.id })));
          }),
        );
      case 'get_devices_endpoint':
        return this.targetDeviceSrv
          .getDevices({ organizationId: this.breadcrumbConfig?.organizationId, projectId: this.breadcrumbConfig?.projectId, detailed: false })
          .pipe(
            switchMap((res: any) => {
              return of(res.devices.map((device: any) => ({ label: device.label, value: device.id })));
            }),
          );
      default:
        this.showErrorMessage('Unable to find endpoint options');
        return of([]);
    }
  };
}
