import { AfterViewInit, Component, EventEmitter, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { DEFAULT_SECURITY_LEVELS, DEVICE_TYPE_OPTIONS, LEVEL_OPTIONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { TargetDeviceService } from '@ids-services';
import { COMPLIANCE_STANDARDS } from '@ids-constants';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription, asyncScheduler, finalize, scheduled } from 'rxjs';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  LABEL: 'label',
  IS_MANUALLY_ADDED: 'is_manually_added',
  ETH: 'eth',
  IP: 'ip',
  TYPE: 'type',
  CRITICALITY: 'criticality',
  MANUFACTURER: 'manufacturer',
  MODEL: 'model',
  FIRMWARE_VERSION: 'firmware_version',
  NETWORK_MAP_LEVEL: 'network_map_level',
  OS: 'os',
  TAGS: 'tags',
  ZONES: 'zones',
  IS_COMPLIANCE: 'is_compliance',
  COMPLIANCE_DETAILS: 'compliance_details',
  COMPLIANCE: 'compliance',
};

const IP_REGEX =
  '^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])((/([0-9]|[1-2][0-9]|3[0-2]))?)|((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))))((/([8-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?)$';

const TAB_VALUES = {
  OVERVIEW: 'overview',
  COMPLIANCE: 'compliance',
};

const SECURITY_LEVEL_PREFIX = 'sl_c_fr';

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss'],
})
export class DeviceFormComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  FORM_PARAMS = FORM_PARAMS;

  fields: FormItem[] = [];

  isCompliance = false;

  device: any = null;

  deviceInfo: any = null;

  securityLevel: any = {};

  selectedTags: any[] = [];

  selectedZones: any[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('typeField') typeField!: TemplateRef<any>;

  @ViewChild('criticalityField') criticalityField!: TemplateRef<any>;

  @ViewChild('tagsField') tagsField!: TemplateRef<any>;

  @ViewChild('zonesField') zonesField!: TemplateRef<any>;

  @ViewChild('complianceDetailsField') complianceDetailsField!: TemplateRef<any>;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  TAB_VALUES = TAB_VALUES;

  complianceDetailsValid = false;

  tabItems: MenuItem[] = [];

  activeTabItem: MenuItem | undefined = undefined;

  isEditFirstLoad = false;

  manufacturerSubscription: Subscription | null = null;

  modelSubscription: Subscription | null = null;

  deviceInfoSubscription: Subscription | null = null;

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.device = this.dialogConfig?.data?.device;
    this.tabItems = [
      { id: TAB_VALUES.OVERVIEW, label: 'Overview' },
      { id: TAB_VALUES.COMPLIANCE, label: 'Compliance' },
    ];
    this.activeTabItem = this.tabItems[0];
    this.complianceDetailsValid = !!this.device;
    this.isCompliance = !!this.dialogConfig?.data?.isCompliance;
    if (!!this.isCompliance) {
      this.activeTabItem = this.tabItems.find((p) => p.id === TAB_VALUES.COMPLIANCE);
    }
    this.isEditFirstLoad = !!this.device?.[FORM_PARAMS.COMPLIANCE]?.[COMPLIANCE_STANDARDS.IEC_62443];
    this.securityLevel = {
      target:
        this.device?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_target?.length === 7
          ? this.device.compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_target
          : DEFAULT_SECURITY_LEVELS,
      capable:
        this.device?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_capable?.length === 7
          ? this.device.compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_capable
          : DEFAULT_SECURITY_LEVELS,
    };
    this.initForm();
    setTimeout(() => {
      this.getManufacturers();
      if (!!this.device) {
        this.getModels();
      }
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (!!this.manufacturerSubscription) {
      this.manufacturerSubscription.unsubscribe();
    }
    if (!!this.modelSubscription) {
      this.modelSubscription.unsubscribe();
    }
    if (!!this.deviceInfoSubscription) {
      this.deviceInfoSubscription.unsubscribe();
    }
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: 'form_title',
        label:
          'Register and keep track of your devices by adding them and entering details. This allows MicroIDS to effectively manage and monitor your devices.',
        field: 'text',
        labelStyleClass: 'text-base',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Device Name',
        required: true,
        focused: true,
        fieldInfo: 'Name of the device',
        defaultValue: this.device?.[FORM_PARAMS.LABEL] || '',
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ETH,
        label: 'MAC Address',
        pattern: /^[a-fA-F0-9]{2}(:[a-fA-F0-9]{2}){5}$/,
        patternErrorText: 'Invalid MAC address. Please enter a valid MAC address, eg 00:1a:2b:3c:4d:5e',
        fieldInfo: 'MAC Addresses of the device (separated by comma)',
        defaultValue: Object.keys(this.device?.[FORM_PARAMS.ETH] || {})?.map((eth) => eth)?.[0] || '',
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP,
        label: 'IP Address(es)',
        field: 'input-chip',
        regex: IP_REGEX,
        patternErrorText: 'Invalid IP address. Please enter a valid IP address, eg 1.1.1.1 or 2001:db8:3333:4444:CCCC:DDDD:EEEE:FFFF',
        fieldInfo: 'IP Addresses of the device (separated by comma)',
        defaultValue: Object.keys(this.device?.[FORM_PARAMS.IP] || {})?.map((ip) => ip),
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MANUFACTURER,
        label: 'Manufacturer',
        field: 'autocomplete',
        autoCompleteDropdown: false,
        minLength: 2,
        suggestions: [] as any[],
        onBlurEvent: new EventEmitter(),
        fieldInfo: 'Manufacturer of the device',
        defaultValue: this.device?.[FORM_PARAMS.MANUFACTURER] || '',
        hidden: !!this.isCompliance,
      } as unknown as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MODEL,
        label: 'Model',
        field: 'autocomplete',
        autoCompleteDropdown: false,
        minLength: 2,
        suggestions: [] as any[],
        onTypeEvent: new EventEmitter(),
        onBlurEvent: new EventEmitter(),
        fieldInfo: 'Model of the device',
        defaultValue: this.device?.[FORM_PARAMS.MODEL] || '',
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Device Type',
        field: 'autocomplete',
        autoCompleteDropdown: true,
        suggestions: this.util.cloneObjectArray(DEVICE_TYPE_OPTIONS).map((type) => type.label),
        fieldInfo: 'Type of the device',
        defaultValue: this.device?.[FORM_PARAMS.TYPE] || '',
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.OS,
        label: 'Operating System',
        fieldInfo: 'Operating system of the device',
        defaultValue: this.device?.[FORM_PARAMS.OS] || '',
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FIRMWARE_VERSION,
        label: 'Firmware Version',
        fieldInfo: 'Firmware version of the device',
        defaultValue: this.device?.[FORM_PARAMS.FIRMWARE_VERSION] || '',
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NETWORK_MAP_LEVEL,
        label: 'Network Map Level',
        field: 'dropdown',
        options: this.util.cloneObjectArray(LEVEL_OPTIONS),
        fieldInfo: 'Network map level of the device',
        defaultValue: !!this.device ? this.device[FORM_PARAMS.NETWORK_MAP_LEVEL] : -1,
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CRITICALITY,
        label: 'Criticality',
        required: true,
        field: 'custom',
        customField: this.criticalityField,
        fieldInfo: 'Criticality of the device',
        defaultValue: this.device?.[FORM_PARAMS.CRITICALITY] || 5,
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TAGS,
        label: 'Tag(s)',
        field: 'custom',
        customField: this.tagsField,
        fieldInfo: 'Tags of the device',
        defaultValue: [],
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ZONES,
        label: 'Zone(s)',
        field: 'custom',
        customField: this.zonesField,
        fieldInfo: 'Zones of the device',
        defaultValue: [],
        hidden: !!this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_COMPLIANCE,
        label: 'Enter Compliance Details',
        field: 'checkbox',
        defaultValue: !!this.device?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443],
        hidden: !this.isCompliance,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMPLIANCE_DETAILS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.complianceDetailsField,
        defaultValue: {},
        hidden: !this.isCompliance || !this.device?.compliance?.[COMPLIANCE_STANDARDS.IEC_62443],
      } as FormItem),
    ];
    this.fields = fields;
    this.setupFieldEvents();
  }

  /**
   * Setup field events
   */
  setupFieldEvents() {
    const modelField = this.fields.find((p) => p.name === FORM_PARAMS.MODEL);
    if (!!modelField) {
      modelField.onTypeEvent?.subscribe(() => {
        this.getModels();
      });
      modelField.onBlurEvent?.subscribe(() => {
        if (!!this.form.getControlValue(FORM_PARAMS.IS_COMPLIANCE)) {
          this.getDeviceInfo();
        }
      });
    }
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.IS_COMPLIANCE, (value: boolean) => {
        this.form.setControlVisibility(
          FORM_PARAMS.COMPLIANCE_DETAILS,
          (!!this.isCompliance || this.activeTabItem?.id === TAB_VALUES.COMPLIANCE) && !!value,
        );
        if (!!value) {
          this.getDeviceInfo();
        }
      });
      if (!!this.device) {
        this.selectedTags = this.device[FORM_PARAMS.TAGS]?.length ? this.device[FORM_PARAMS.TAGS] : [];
        this.selectedZones = this.device[FORM_PARAMS.ZONES]?.length ? this.device[FORM_PARAMS.ZONES] : [];
      }
    });
  }

  /**
   * Get manufacturers
   */
  getManufacturers() {
    if (!!this.manufacturerSubscription) {
      this.manufacturerSubscription.unsubscribe();
      this.manufacturerSubscription = null;
    }
    this.manufacturerSubscription = this.targetDeviceSrv.getManufacturers().subscribe({
      next: (result: any[]) => {
        const manufacturers = this.util
          .sortObjectArray(
            this.util.getUniqueObjectArray(
              result.map((p) => ({ ...p, value: ((p.vendor_name as string) || '')?.toLowerCase() })).filter((p) => !!p.vendor_name),
              'vendor_name',
            ),
            'value',
          )
          .map((p) => p.vendor_name);
        const manufacturerField = this.fields.find((p) => p.name === FORM_PARAMS.MANUFACTURER);
        if (!!manufacturerField) {
          manufacturerField.suggestions = manufacturers;
        }
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Get models
   */
  getModels() {
    const manufacturer = (this.form.getControlValue(FORM_PARAMS.MANUFACTURER) as string) || '';
    const model = (this.form.getControlValue(FORM_PARAMS.MODEL) as string) || '';
    if (!!manufacturer && !!model && model.length >= (this.fields.find((p) => p.name === FORM_PARAMS.MODEL)?.minLength || 1)) {
      if (!!this.modelSubscription) {
        this.modelSubscription.unsubscribe();
        this.modelSubscription = null;
      }
      this.modelSubscription = this.targetDeviceSrv
        .getModels(manufacturer, model)
        .pipe(
          finalize(() => {
            if (!!this.form.getControlValue(FORM_PARAMS.IS_COMPLIANCE)) {
              this.getDeviceInfo();
            }
          }),
        )
        .subscribe({
          next: (result: any[]) => {
            const models = this.util
              .sortObjectArray(
                this.util.getUniqueObjectArray(
                  result.map((p) => ({ ...p, value: ((p.product_name as string) || '')?.toLowerCase() })).filter((p) => !!p.product_name),
                  'product_name',
                ),
                'value',
              )
              .map((p) => p.product_name);
            const modelField = this.fields.find((p) => p.name === FORM_PARAMS.MODEL);
            if (!!modelField) {
              modelField.suggestions = models;
            }
          },
          error: () => {
            const modelField = this.fields.find((p) => p.name === FORM_PARAMS.MODEL);
            if (!!modelField) {
              modelField.suggestions = [];
            }
          },
        });
    }
  }

  /**
   * Get device info
   * @param isAutofillSLC
   */
  getDeviceInfo(isAutofillSLC = false) {
    if (!!this.deviceInfoSubscription) {
      this.deviceInfoSubscription.unsubscribe();
      this.deviceInfoSubscription = null;
    }
    let request: Observable<any> = scheduled([null], asyncScheduler);
    if (!!isAutofillSLC) {
      request = scheduled([this.deviceInfo], asyncScheduler);
    } else {
      const manufacturer = this.form.getControlValue(FORM_PARAMS.MANUFACTURER) || '';
      const model = this.form.getControlValue(FORM_PARAMS.MODEL) || '';
      request = !!manufacturer && !!model ? this.targetDeviceSrv.getDeviceInfo(manufacturer, model) : request;
    }
    this.deviceInfoSubscription = request.subscribe({
      next: (result: any) => {
        this.deviceInfo = result;
        if (!this.isEditFirstLoad) {
          if (!!this.deviceInfo || !!isAutofillSLC) {
            const securityLevel: any = {};
            Object.entries(this.deviceInfo).forEach(([key, value]: [string, any]) => {
              if (!!key.startsWith(SECURITY_LEVEL_PREFIX)) {
                Object.keys(this.securityLevel).forEach((sl: any) => {
                  if (!securityLevel?.[sl]) {
                    securityLevel[sl] = [];
                  }
                  const index = parseInt(key.replace(SECURITY_LEVEL_PREFIX, '')) - 1;
                  securityLevel[sl][index] = value;
                });
              }
            });
            this.securityLevel = securityLevel;
          }
        } else {
          this.isEditFirstLoad = false;
        }
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  onSubmit(closeDialog: (rs: any) => void) {
    this.form.isLoading = true;
    const formValues = this.form.getRawValue();
    const payload = {
      ...(!this.device && {
        [FORM_PARAMS.ORGANIZATION_ID]: this.breadcrumbConfig?.organizationId,
        [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
        [FORM_PARAMS.IS_MANUALLY_ADDED]: true,
      }),
      ...(!this.isCompliance && {
        [FORM_PARAMS.LABEL]: formValues[FORM_PARAMS.LABEL],
        [FORM_PARAMS.TYPE]: formValues[FORM_PARAMS.TYPE] || '',
        [FORM_PARAMS.MANUFACTURER]: formValues[FORM_PARAMS.MANUFACTURER],
        [FORM_PARAMS.MODEL]: formValues[FORM_PARAMS.MODEL],
        [FORM_PARAMS.OS]: formValues[FORM_PARAMS.OS],
        [FORM_PARAMS.FIRMWARE_VERSION]: formValues[FORM_PARAMS.FIRMWARE_VERSION],
        [FORM_PARAMS.NETWORK_MAP_LEVEL]: formValues[FORM_PARAMS.NETWORK_MAP_LEVEL],
        [FORM_PARAMS.CRITICALITY]: formValues[FORM_PARAMS.CRITICALITY],
        interface_data: {
          [FORM_PARAMS.ETH]: !!formValues[FORM_PARAMS.ETH] ? { [formValues[FORM_PARAMS.ETH]]: [] } : undefined,
          [FORM_PARAMS.IP]: !!formValues[FORM_PARAMS.IP]?.length
            ? formValues[FORM_PARAMS.IP].reduce((obj: any, value: any) => ((obj[value] = []), obj), {})
            : undefined,
        },
        [FORM_PARAMS.TAGS]: !!this.selectedTags?.length
          ? this.selectedTags.map((tag) => ({
              id: tag.id,
              label: tag.label,
              color: tag.color,
            }))
          : [],
        [FORM_PARAMS.ZONES]: !!this.selectedZones?.length
          ? this.selectedZones.map((zone) => ({
              id: zone.id,
              label: zone.label,
              color: zone.color,
            }))
          : [],
      }),
      [FORM_PARAMS.COMPLIANCE]: !!formValues[FORM_PARAMS.IS_COMPLIANCE]
        ? {
            [COMPLIANCE_STANDARDS.IEC_62443]: {
              security_level_target: this.securityLevel.target,
              security_level_capable: this.securityLevel.capable,
            },
          }
        : undefined,
    };
    const request: Observable<any> = !this.device
      ? this.targetDeviceSrv.createDevice(payload)
      : this.targetDeviceSrv.updateDevice(this.device.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const message = !this.device ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'device'));
          closeDialog(rs);
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  onActiveStepChange(tabItem: MenuItem = this.tabItems[0]) {
    const tabValue = tabItem.id;
    this.form.isLoading = true;
    this.form.setControlVisibility('form_title', tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.LABEL, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.ETH, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.IP, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.TYPE, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.MANUFACTURER, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.MODEL, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.OS, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.FIRMWARE_VERSION, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.NETWORK_MAP_LEVEL, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.CRITICALITY, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.TAGS, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.ZONES, tabValue === TAB_VALUES.OVERVIEW);
    this.form.setControlVisibility(FORM_PARAMS.IS_COMPLIANCE, tabValue === TAB_VALUES.COMPLIANCE);
    this.form.setControlVisibility(
      FORM_PARAMS.COMPLIANCE_DETAILS,
      tabValue === TAB_VALUES.COMPLIANCE && !!this.form.getControlValue(FORM_PARAMS.IS_COMPLIANCE),
    );
    setTimeout(() => {
      this.form.isLoading = false;
    }, 10);
  }
}
