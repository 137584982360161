<p-dialog
  styleClass="common-dialog"
  [(visible)]="visible"
  [modal]="true"
  appendTo="body"
  [closable]="false"
  [style]="{ width: '800px' }"
  [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">
      @switch (type) {
        @case ('confirm') {
          <span>Connection Test Required</span>
        }
        @case ('loading') {
          <span>Testing Connection</span>
        }
        @case ('success') {
          <span>Connection Successful</span>
        }
        @case ('failed') {
          <span>{{ !isSyncing ? 'Connection' : 'Syncing' }} Failed</span>
        }
        @default {
          <span></span>
        }
      }
    </span>
  </ng-template>
  <div class="form">
    <div class="py-2 px-3">
      @if (type === 'confirm') {
        <div class="grid px-3">
          <label class="col-12"> A connection test is required to enabled the management of {{ device?.label }}. </label>
        </div>
        <div class="action-buttons flex align-items-center justify-content-end gap-2">
          <p-button type="button" label="Cancel" severity="secondary" (onClick)="onCancel()"> </p-button>
          <p-button type="button" label="Edit Configuration" severity="primary" (onClick)="openDeviceForm()"> </p-button>
          <p-button type="button" label="Test Connection" severity="success" (onClick)="testConfiguration()"> </p-button>
        </div>
      }
      @if (type === 'loading') {
        <div class="grid px-3">
          <label class="col-12">Network configuration for {{ device?.label }} saved.</label>
          <label class="col-12" *ngIf="!isSyncing">Establishing connection to {{ device?.label }}...</label>
          @if (!!isSyncing) {
            <label class="col-12">Established connection to {{ device?.label }}.</label>
            <label class="col-12">Syncing database with {{ device?.label }} configuration...</label>
          }

          <div class="col-12 mt-3">
            <p-progressBar class="loading-progress-bar" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
          </div>
        </div>

        <div class="action-buttons flex align-items-center justify-content-end gap-2">
          <p-button type="button" label="Cancel" severity="secondary" (onClick)="onCancel()"> </p-button>
        </div>
      }
      @if (type === 'success') {
        <div class="grid px-3">
          <label class="col-12"> Connection to {{ device?.label }} established. </label>
          <label class="col-12"> {{ device?.label }} can now be managed. </label>
        </div>
        <div class="action-buttons flex align-items-center justify-content-end gap-2">
          <p-button type="button" label="Done" severity="primary" (onClick)="onCancel()"> </p-button>
        </div>
      }
      @if (type === 'failed') {
        <div class="grid px-3">
          @if (!isSyncing) {
            <label class="col-12"> Unable to establish connection to {{ device?.label }}. </label>
            <label class="col-12"> Please check the network connection and ensure that the connection details are correct. </label>
          } @else {
            <label class="col-12"> Connection to {{ device?.label }} established and {{ device?.label }} can now be managed. </label>
            <label class="col-12">
              However, syncing was not successful. You may initiate a re-sync in the VLANs / Firewalls sections of
              {{ device?.label }}.
            </label>
          }
        </div>
        <div class="action-buttons flex align-items-center justify-content-end gap-2">
          <p-button type="button" label="Cancel" severity="secondary" (onClick)="onCancel()"> </p-button>
          <p-button type="button" label="Edit Configuration" severity="primary" (onClick)="openDeviceForm()"> </p-button>
          <p-button type="button" label="Retry" severity="success" (onClick)="testConfiguration()"> </p-button>
        </div>
      }
    </div>
  </div>
</p-dialog>
