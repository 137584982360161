import { AfterViewInit, Component, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL, VALIDATOR_TYPE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AssessmentService } from '@ids-services';
import { COMPLIANCE_STANDARDS, COMPLIANCE_STANDARD_OPTIONS, FOUNDATIONAL_REQUIREMENT_OPTIONS, SECURITY_LEVEL_OPTIONS } from '@ids-constants';
import { FormArray, FormBuilder } from '@angular/forms';
import { validatorTrimRequired } from '@microsec/validators';

import { finalize } from 'rxjs/operators';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  SCOPE: 'scope',
  TITLE: 'title',
  DESCRIPTION: 'description',
  ANSWER_TYPE: 'answer_type',
  COMPLIANCE: 'compliance',
  IS_COMPLIANCE: 'is_compliance',
  COMPLIANCE_STANDARD: 'compliance_standard',
  SECURITY_LEVEL_CAPABLE: 'security_level_capable',
  FOUNDATIONAL_REQUIREMENT: 'foundational_requirement',
  MITIGATIONS: 'mitigations',
  PREFILLING_LOGICS: 'prefilling_logics',
};

export const CODEMIRROR_OPTIONS: CodeMirror.EditorConfiguration = {
  theme: 'base16-dark',
  mode: 'spreadsheet',
  showCursorWhenSelecting: false,
};

@Component({
  selector: 'app-assessment-question-form',
  templateUrl: './assessment-question-form.component.html',
  styleUrls: ['./assessment-question-form.component.scss'],
})
export class AssessmentQuestionFormComponent extends BaseComponent implements AfterViewInit {
  question: any = null;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  CODEMIRROR_OPTIONS = CODEMIRROR_OPTIONS;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('titleField') titleField!: TemplateRef<any>;

  @ViewChild('descriptionField') descriptionField!: TemplateRef<any>;

  @ViewChild('prefillingLogicsField') prefillingLogicsField!: TemplateRef<any>;

  @ViewChild('mitigationsField') mitigationsField!: TemplateRef<any>;

  @ViewChildren('codeMirrorComponent')
  codeMirrorComponents!: CodemirrorComponent[];

  mitigations: any[] = [];

  tabsMenu: MenuItem[] = [
    { id: 'general', label: 'General' },
    { id: 'compliance', label: 'Compliance' },
    { id: 'mitigations', label: 'Mitigations' },
  ];

  activeTabMenu: MenuItem = this.tabsMenu[0];

  constructor(
    private assessmentSrv: AssessmentService,
    public dialogConfig: DynamicDialogConfig,
    private fb: FormBuilder,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.question = this.dialogConfig?.data?.question;
    this.mitigations = this.util.cloneObjectArray(this.question?.[FORM_PARAMS.MITIGATIONS] || []);
    this.initForm();
    // Code mirror refresh
    setTimeout(() => {
      this.codeMirrorComponents.forEach((codeMirrorComponent) => {
        codeMirrorComponent?.codeMirror?.refresh();
      });
    }, 500);
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TITLE,
        hasNoLabel: true,
        field: 'custom',
        customField: this.titleField,
        required: true,
        focused: true,
        defaultValue: this.question?.[FORM_PARAMS.TITLE] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        hasNoLabel: true,
        field: 'custom',
        customField: this.descriptionField,
        defaultValue: this.question?.[FORM_PARAMS.DESCRIPTION] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PREFILLING_LOGICS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.prefillingLogicsField,
        defaultValue: this.question?.[FORM_PARAMS.PREFILLING_LOGICS] || [],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IS_COMPLIANCE,
        label: 'Enter Compliance Details',
        field: 'checkbox',
        hidden: true,
        defaultValue: !this.util.isObjectEmpty(this.question?.[FORM_PARAMS.COMPLIANCE] || {}),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMPLIANCE_STANDARD,
        required: !this.util.isObjectEmpty(this.question?.[FORM_PARAMS.COMPLIANCE] || {}),
        label: 'Compliance Standard',
        field: 'dropdown',
        options: COMPLIANCE_STANDARD_OPTIONS,
        hidden: true,
        defaultValue: COMPLIANCE_STANDARDS.IEC_62443,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FOUNDATIONAL_REQUIREMENT,
        required: !this.util.isObjectEmpty(this.question?.[FORM_PARAMS.COMPLIANCE] || {}),
        label: 'Foundation Requirement',
        placeholder: 'Select Foundation Requirement',
        field: 'dropdown',
        options: FOUNDATIONAL_REQUIREMENT_OPTIONS,
        hidden: true,
        defaultValue: this.question?.[FORM_PARAMS.COMPLIANCE]?.[COMPLIANCE_STANDARDS.IEC_62443]?.[FORM_PARAMS.FOUNDATIONAL_REQUIREMENT] || null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SECURITY_LEVEL_CAPABLE,
        required: !this.util.isObjectEmpty(this.question?.[FORM_PARAMS.COMPLIANCE] || {}),
        label: 'Capability Security Level (SL-C)',
        placeholder: 'Select Level',
        field: 'multiselect',
        options: SECURITY_LEVEL_OPTIONS.filter((level) => level.value > 0),
        hidden: true,
        defaultValue: this.question?.[FORM_PARAMS.COMPLIANCE]?.[COMPLIANCE_STANDARDS.IEC_62443]?.[FORM_PARAMS.SECURITY_LEVEL_CAPABLE] || [],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MITIGATIONS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.mitigationsField,
        hidden: true,
        defaultValue: [],
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.form?.setControl(FORM_PARAMS.PREFILLING_LOGICS, this.fb.array([]));
      this.form?.setChangeEvent(FORM_PARAMS.IS_COMPLIANCE, (value: boolean) => {
        this.setComplianceFields(!!value, true);
      });
      this.question?.[FORM_PARAMS.PREFILLING_LOGICS]?.forEach((logic: any) => {
        this.prefillingLogicsFormArray?.push(
          this.fb.group({
            value: [logic, validatorTrimRequired],
          }),
        );
      });
    });
  }

  onSubmit(closeDialog: (rs?: any) => void) {
    this.form.isLoading = true;
    const formValue = this.form?.getRawValue();
    const payload = {
      [FORM_PARAMS.TITLE]: formValue[FORM_PARAMS.TITLE],
      [FORM_PARAMS.DESCRIPTION]: formValue[FORM_PARAMS.DESCRIPTION],
      [FORM_PARAMS.ANSWER_TYPE]: 'boolean',
      [FORM_PARAMS.COMPLIANCE]: !!formValue[FORM_PARAMS.IS_COMPLIANCE]
        ? {
            [COMPLIANCE_STANDARDS.IEC_62443]: {
              [FORM_PARAMS.SECURITY_LEVEL_CAPABLE]: formValue[FORM_PARAMS.SECURITY_LEVEL_CAPABLE],
              [FORM_PARAMS.FOUNDATIONAL_REQUIREMENT]: formValue[FORM_PARAMS.FOUNDATIONAL_REQUIREMENT],
            },
          }
        : {},
      [FORM_PARAMS.PREFILLING_LOGICS]: !!formValue[FORM_PARAMS.PREFILLING_LOGICS]?.length
        ? formValue[FORM_PARAMS.PREFILLING_LOGICS].map((logic: any) => logic.value)
        : [],
      [FORM_PARAMS.MITIGATIONS]: this.mitigations,
      ...(!this.question && {
        [FORM_PARAMS.ORGANIZATION_ID]: this.breadcrumbConfig?.organizationId,
        [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
        [FORM_PARAMS.SCOPE]: this.currentScope,
      }),
    };
    const request: Observable<any> = !this.question
      ? this.assessmentSrv.createAssessmentQuestion(payload)
      : this.assessmentSrv.updateAssessmentQuestion(this.question.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.showSuccessMessage(`Question ${!!this.question ? 'Updated' : 'Created'} Successfully`);
          closeDialog(rs);
        },
        error: (err: any) => {
          this.form?.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  setComplianceFields(isCompliance: boolean, setValidators = false) {
    if (!!setValidators) {
      this.form?.setControlValidators(FORM_PARAMS.COMPLIANCE_STANDARD, !!isCompliance ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form?.setControlValidators(FORM_PARAMS.SECURITY_LEVEL_CAPABLE, !!isCompliance ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form?.setControlValidators(FORM_PARAMS.FOUNDATIONAL_REQUIREMENT, !!isCompliance ? [VALIDATOR_TYPE.REQUIRED] : []);
    }
    this.form?.setControlVisibility(FORM_PARAMS.COMPLIANCE_STANDARD, this.activeTabMenu.id === 'compliance' && !!isCompliance);
    this.form?.setControlVisibility(FORM_PARAMS.FOUNDATIONAL_REQUIREMENT, this.activeTabMenu.id === 'compliance' && !!isCompliance);
    this.form?.setControlVisibility(FORM_PARAMS.SECURITY_LEVEL_CAPABLE, this.activeTabMenu.id === 'compliance' && !!isCompliance);
  }

  onTabChange(event: any) {
    this.form.isLoading = true;
    this.activeTabMenu = event;
    this.form?.setControlVisibility(FORM_PARAMS.TITLE, this.activeTabMenu.id === 'general');
    this.form?.setControlVisibility(FORM_PARAMS.DESCRIPTION, this.activeTabMenu.id === 'general');
    this.form?.setControlVisibility(FORM_PARAMS.PREFILLING_LOGICS, this.activeTabMenu.id === 'general');
    this.form?.setControlVisibility(FORM_PARAMS.IS_COMPLIANCE, this.activeTabMenu.id === 'compliance');
    this.form?.setControlVisibility(FORM_PARAMS.MITIGATIONS, this.activeTabMenu.id === 'mitigations');
    this.setComplianceFields(!!this.form?.getControlValue(FORM_PARAMS.IS_COMPLIANCE));
    setTimeout(() => {
      this.form.isLoading = false;
    }, 10);
  }

  addPrefillingLogic() {
    this.prefillingLogicsFormArray?.push(
      this.fb.group({
        value: ['', validatorTrimRequired],
      }),
    );
  }

  removePrefillingLogic(index: number) {
    this.prefillingLogicsFormArray?.removeAt(index);
  }

  get prefillingLogicsFormArray() {
    return this.form?.form?.get(FORM_PARAMS.PREFILLING_LOGICS) as FormArray;
  }
}
