@if (!!license && !!license.features && allFeatures.length && !!allQuotas) {
  <div class="grid" style="max-width: 50rem">
    <div class="col-12">
      <label class="font-bold text-xl">License Quotas</label>
    </div>
    <div class="col-12 -my-2">
      <p-divider></p-divider>
    </div>
    <div class="col-12">
      <div class="flex flex-column gap-4">
        <div class="flex flex-wrap justify-content-between align-items-center gap-2">
          <div class="flex flex-column gap-2">
            <label class="font-bold">{{ allQuotas[QUOTAS_FEATURES.ORGANIZATIONS]?.name }}</label>
            <label class="text-sm text-grey">{{ allQuotas[QUOTAS_FEATURES.ORGANIZATIONS]?.description }}</label>
          </div>
          <label class="font-bold">{{ allQuotas[QUOTAS_FEATURES.ORGANIZATIONS]?.value }}</label>
        </div>
        <div class="flex flex-wrap justify-content-between align-items-center gap-2">
          <div class="flex flex-column gap-2">
            <label class="font-bold">{{ allQuotas[QUOTAS_FEATURES.PROJECTS]?.name }}</label>
            <label class="text-sm text-grey">{{ allQuotas[QUOTAS_FEATURES.PROJECTS]?.description }}</label>
          </div>
          <label class="font-bold">{{ allQuotas[QUOTAS_FEATURES.PROJECTS]?.value }}</label>
        </div>
        <div class="flex flex-wrap justify-content-between align-items-center gap-2">
          <div class="flex flex-column gap-2">
            <label class="font-bold">{{ allQuotas[QUOTAS_FEATURES.USERS]?.name }}</label>
            <label class="text-sm text-grey">{{ allQuotas[QUOTAS_FEATURES.USERS]?.description }}</label>
          </div>
          <label class="font-bold">{{ allQuotas[QUOTAS_FEATURES.USERS]?.value }}</label>
        </div>
      </div>
    </div>

    @for (feature of allFeatures; track $index) {
      @if (true) {
        <div class="col-12 mt-5">
          <label class="font-bold text-xl">{{ feature.name }}</label>
        </div>
        <div class="col-12 -my-2">
          <p-divider></p-divider>
        </div>
        <div class="col-12">
          <div class="flex flex-column gap-4">
            <div class="flex flex-wrap justify-content-between align-items-center gap-2">
              <div class="flex flex-column gap-2">
                <label class="font-bold">{{ featureQuotas[feature.key]?.name }}</label>
                <label class="text-sm text-grey">{{ featureQuotas[feature.key]?.description }}</label>
              </div>
              <label class="font-bold">{{ featureQuotas[feature.key]?.value }}</label>
            </div>

            @if (featureQuotas[feature.key]?.child?.length) {
              @for (featureQuota of featureQuotas[feature.key]?.child; track $index) {
                <div class="ml-4 flex flex-wrap justify-content-between align-items-center gap-2">
                  <div class="flex flex-column gap-2">
                    <label class="text-sm font-bold">{{ featureQuota.name }}</label>
                  </div>
                  <label class="text-sm font-bold">{{ featureQuota.value }}</label>
                </div>
              }
            }

            @if (feature.subFeatures?.length) {
              <div class="flex flex-column gap-2">
                <label class="font-bold">Supported Features</label>
                <div class="ml-3 grid grid-nogutter">
                  @for (subFeature of feature.subFeatures; track $index) {
                    @if (true) {
                      <div class="col-12 md:col-6 flex align-items-center gap-2 p-2">
                        <fa-icon
                          [class]="!!subFeature.enabled ? 'text-green' : 'text-red'"
                          transform="shrink-5"
                          [icon]="!!subFeature.enabled ? faCheck : faX" />
                        <label class="text-sm">{{ subFeature.name }}</label>
                      </div>
                    }
                  }
                </div>
              </div>
            }
          </div>
        </div>
      }
    }
  </div>
}
