import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { TargetDeviceService } from '@ids-services';
import { COMPLIANCE_STANDARDS } from '@ids-constants';

import { finalize } from 'rxjs';

import { DeviceFormComponent } from '../device-form/device-form.component';

@Component({
  selector: 'app-detected-device-details',
  templateUrl: './detected-device-details.component.html',
  styleUrls: ['./detected-device-details.component.scss'],
})
export class DetectedDeviceDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @Input() readOnly = false;

  activeIndex = 0;

  _deviceId = null;

  get deviceId() {
    return this._deviceId;
  }

  @Input() set deviceId(value: any) {
    this._deviceId = value;
    if (!!this._deviceId) {
      this.getDevice();
    }
  }

  device: any = {};

  @Output() deviceChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() hidePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() saveCallbackEvent: EventEmitter<any> = new EventEmitter<any>();

  threatsCount = 0;

  COMPLIANCE_STANDARDS = COMPLIANCE_STANDARDS;

  constructor(private targetDeviceSrv: TargetDeviceService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.deviceId) {
      this.getDevice();
    }
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.targetDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !!this.deviceId) {
        this.getDevice();
      }
    });
    this.subscriptions.push(subscription);
  }

  getDevice() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getDevice(this.deviceId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.device = rs || {};
          this.deviceChangeEvent.emit(this.device);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  openDeviceForm(device: any) {
    const dialog = this.dialogSrv.open(DeviceFormComponent, {
      data: { device: device },
      header: 'Edit Device',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.targetDeviceSrv.refresh$.next(true);
        this.saveCallbackEvent?.emit(rs);
      }
    });
  }

  goToTab(tab: 'threat' | 'compliance') {
    switch (tab) {
      case 'threat': {
        this.activeIndex = 1;
        break;
      }
      case 'compliance': {
        this.activeIndex = !!this.device?.uses_agent ? 3 : 2;
        break;
      }
      default: {
        break;
      }
    }
  }
}
