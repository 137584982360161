import { Component, Input } from '@angular/core';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-assessment-type-overview',
  templateUrl: './assessment-type-overview.component.html',
  styleUrls: ['./assessment-type-overview.component.scss'],
})
export class AssessmentTypeOverviewComponent extends BaseComponent {
  isLoading = false;

  _assessmentType: any = {};

  get assessmentType() {
    return this._assessmentType;
  }

  @Input() set assessmentType(value: any) {
    this._assessmentType = {
      ...(value || {}),
      descriptionPreview: this.util.parseMarkdown(value?.description || ''),
      numberOfQuestions: value?.sections?.reduce((a: any, b: any) => a + (b.assigned_questions?.length || 0), 0),
    };
  }

  constructor() {
    super();
  }
}
