import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API } from '@ids-services';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IReportService } from './IReportService';

@Injectable({
  providedIn: 'root',
})
export class ReportService implements IReportService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  constructor(private httpClient: HttpClient) {}

  createReport(payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API.REPORT_MANAGER}/reports`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getReports(
    organization_id?: any,
    project_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    report_type_id?: any,
    status?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (project_id) {
      params.push(`project_id=${project_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (!!sort) {
      params.push(`sort=${sort}`);
    }
    if (typeof reverse === 'boolean') {
      params.push(`reverse=${reverse}`);
    }
    if (report_type_id) {
      params.push(`report_type_id=${report_type_id}`);
    }
    if (status) {
      params.push(`status=${status}`);
    }
    return this.httpClient
      .get(`${API.REPORT_MANAGER}/reports${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getReport(id: any): Observable<any> {
    return this.httpClient.get<any>(`${API.REPORT_MANAGER}/reports/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  downloadReport(id: any, format = 'pdf'): Observable<any> {
    const options: any = {
      responseType: 'blob',
      observe: 'response',
    };
    return this.httpClient
      .get<any>(`${API.REPORT_MANAGER}/reports/${id}/download?format=${format}`, options)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteReport(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${API.REPORT_MANAGER}/reports/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createReportType(payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API.REPORT_MANAGER}/report-types`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getReportTypes(
    scope?: string,
    organization_id?: any,
    project_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
  ): Observable<any> {
    const params: string[] = [];
    if (scope) {
      params.push(`scope=${scope}`);
    }
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (project_id) {
      params.push(`project_id=${project_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (!!sort) {
      params.push(`sort=${sort}`);
    }
    if (typeof reverse === 'boolean') {
      params.push(`reverse=${reverse}`);
    }
    return this.httpClient
      .get(`${API.REPORT_MANAGER}/report-types${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getReportFilters(): Observable<any> {
    return this.httpClient.get<any>(`${API.REPORT_MANAGER}/report-filters`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
