import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventMonitorState, EVENT_MONITOR_FEATURE_KEY } from './event-monitor.reducer';

// Lookup the 'Event Monitor' feature state managed by NgRx
const getEventMonitorState = createFeatureSelector<EventMonitorState>(EVENT_MONITOR_FEATURE_KEY);

// All filters loaded
const isFiltersLoaded = createSelector(
  getEventMonitorState,
  (state: EventMonitorState) => state.isCategoriesLoaded && state.isOrganizationsLoaded && state.isUsersLoaded,
);

// is loading
const isLoading = createSelector(getEventMonitorState, (state: EventMonitorState) => state.isLoading);

// categories
const categories = createSelector(getEventMonitorState, (state: EventMonitorState) => state.categories);

// organizations
const organizations = createSelector(getEventMonitorState, (state: EventMonitorState) => state.organizations);

// users
const users = createSelector(getEventMonitorState, (state: EventMonitorState) => state.users);

// devices
const devices = createSelector(getEventMonitorState, (state: EventMonitorState) => state.devices);

// is devices loading
const isDevicesLoading = createSelector(getEventMonitorState, (state: EventMonitorState) => state.isDevicesLoading);

// events
const events = createSelector(getEventMonitorState, (state: EventMonitorState) => state.events);

// selected event
const selectedEvent = createSelector(getEventMonitorState, (state: EventMonitorState) => state.selectedEvent);

// total event number
const totalEventNumber = createSelector(getEventMonitorState, (state: EventMonitorState) => state.totalEventNumber);

// lastRefresh
const lastRefresh = createSelector(getEventMonitorState, (state: EventMonitorState) => state.lastRefresh);

const refreshConfig = createSelector(getEventMonitorState, (state: EventMonitorState) => ({
  searchText: state.searchText,
  page: state.page,
  perPage: state.perPage,
}));

export const eventMonitorSelectors = {
  isFiltersLoaded,
  isLoading,
  categories,
  organizations,
  users,
  devices,
  isDevicesLoading,
  events,
  selectedEvent,
  totalEventNumber,
  lastRefresh,
  refreshConfig,
};
