@if (!!isPage) {
  <microsec-card>
    <div class="grid">
      <div class="col-12 xl:col-6 lg:col-8 md:col-10 sm:col-12">
        <ng-container [ngTemplateOutlet]="formContent"></ng-container>
      </div>
    </div>
  </microsec-card>
} @else {
  <ng-container [ngTemplateOutlet]="formContent"></ng-container>
}

<ng-template #formContent>
  <microsec-form-builder
    #fb
    [isPage]="!!isPage"
    [fields]="fields"
    [shouldShowActionButtons]="true"
    [submitButtonLabel]="!isPage && !project ? 'Submit' : 'Save changes'"
    [cancelButtonLabel]="!isPage ? 'Cancel' : ''"
    [leftActionArea]="!isPage ? null : leftActionArea"
    (submitEvent)="onSubmit($event)">
  </microsec-form-builder>

  <ng-template #leftActionArea>
    <p-button severity="secondary" styleClass="mr-2" type="button" (onClick)="getProjectAndInitForm()" label="Discard"> </p-button>
  </ng-template>
</ng-template>
