<div class="grid">
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <span [pTooltip]="!!checkOrganizationQuota ? '' : 'Maximum number of organizations reached'" tooltipPosition="bottom" appendTo="body">
          <p-button
            [label]="'Create New Organization'"
            [rounded]="true"
            icon="pi pi-plus"
            (onClick)="openOrganizationForm()"
            [disabled]="!!isLoading || !checkOrganizationQuota" />
        </span>
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [selectable]="false"
        itemName="Organization"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getOrganizations()" />
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <ng-container *ngSwitchCase="'name'">
      <label
        class="link overflow-hidden white-space-nowrap text-overflow-ellipsis"
        [title]="rowData[col.field]"
        (click)="goToOrganization($event, rowData.id)">
        {{ rowData[col.field] }}
      </label>
    </ng-container>
    <ng-container *ngSwitchCase="'total_projects'">
      {{ rowData[col.field] }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
