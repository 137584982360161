<microsec-form-builder
  #fb
  [fields]="fields"
  [shouldShowActionButtons]="true"
  [submitButtonLabel]="!vlan ? CREATE_LABEL : SAVE_CHANGES_LABEL"
  (submitEvent)="onSubmit($event)">
</microsec-form-builder>

<ng-template #portMembersField>
  @if (!!form && !!form.form && !!portMembersFormArray) {
    <ng-container [formGroup]="form.form">
      <ng-container [formArrayName]="FORM_PARAMS.PORT_MEMBERS">
        <div class="grid my-1">
          <div class="col-12 lg:col-5 md:col-6 sm:col-12 xl:col-4">Port Members</div>
          <div class="col">
            <ng-container *ngFor="let item of portMembersFormArray?.controls; let i = index">
              <ng-container [formGroupName]="i">
                <div class="grid field align-items-center">
                  <div class="col">
                    <input
                      inputTrimRef
                      type="text"
                      pInputText
                      [formControlName]="FORM_PARAMS.PORT"
                      [ngClass]="{
                        'ng-invalid ng-dirty':
                          !!form.form.get([FORM_PARAMS.PORT_MEMBERS, i, FORM_PARAMS.PORT])?.touched &&
                          !!form.form.get([FORM_PARAMS.PORT_MEMBERS, i, FORM_PARAMS.PORT])?.dirty &&
                          !!form.form.get([FORM_PARAMS.PORT_MEMBERS, i, FORM_PARAMS.PORT])?.errors
                      }" />
                  </div>
                  <div class="col-4">
                    <p-dropdown
                      [options]="portMembersTaggedOptions"
                      [formControlName]="FORM_PARAMS.TAGGED"
                      optionLabel="label"
                      optionValue="value"
                      appendTo="body">
                    </p-dropdown>
                  </div>
                  <div class="col-fixed">
                    <i class="pi pi-minus-circle cursor-pointer" style="font-size: 1.25rem" (click)="removePortMembers(i)"></i>
                  </div>
                  <label
                    class="col-12 text-error"
                    *ngIf="
                      !!form.form.get([FORM_PARAMS.PORT_MEMBERS, i, FORM_PARAMS.PORT])?.touched &&
                      !!form.form.get([FORM_PARAMS.PORT_MEMBERS, i, FORM_PARAMS.PORT])?.dirty &&
                      !!form.form.get([FORM_PARAMS.PORT_MEMBERS, i, FORM_PARAMS.PORT])?.errors
                    ">
                    Port is
                    {{ !!form.form.get([FORM_PARAMS.PORT_MEMBERS, i, FORM_PARAMS.PORT])?.errors?.['required'] ? 'required' : 'not valid' }}
                  </label>
                </div>
              </ng-container>
            </ng-container>
            <div>
              <i class="pi pi-plus-circle cursor-pointer" style="font-size: 1.25rem" (click)="addPortMembers()"></i>
            </div>
          </div>
          <div class="col-fixed mt-2" style="width: 35px">
            <span pTooltip="Port members of the VLAN" tooltipPosition="right" appendTo="body">
              <i class="pi pi-info-circle pr-1"></i>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  }
</ng-template>
