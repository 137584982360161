import { faCubes, faSuitcase, faUsers } from '@fortawesome/free-solid-svg-icons';
import { ILayoutItem } from '@microsec/interfaces';
import { ProjectsComponent } from './projects/projects.component';
import { ORGANIZATION_MANAGEMENT_CONSTANTS, ORGANIZATION_MANAGEMENT_GROUPS } from '@ids-constants';
import { UserAccessComponent } from './user-access/user-access.component';
import { OrganizationSnortSettingsComponent } from './organization-snort-settings/organization-snort-settings.component';

export const ORGANIZATION_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.ORGANIZATION_SETTINGS,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.PROJECTS.ROUTE,
    icon: faSuitcase,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.PROJECTS.LABEL,
    component: ProjectsComponent,
  },
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.ORGANIZATION_SETTINGS,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.USER_ACCESS.ROUTE,
    icon: faUsers,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.USER_ACCESS.LABEL,
    component: UserAccessComponent,
    visible: false,
  },
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.ORGANIZATION_SETTINGS,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.SNORT_SETTINGS.ROUTE,
    icon: faCubes,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.SNORT_SETTINGS.LABEL,
    component: OrganizationSnortSettingsComponent,
  },
];
