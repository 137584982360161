import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssessmentService, ConnectionService, ReportService, TargetDeviceService } from '@ids-services';
import { BaseComponent } from '@ids-components';
import { ORGANIZATION_ID_PARAM_ROUTE, PROJECT_ID_PARAM_ROUTE, USER_ROLE } from '@microsec/constants';
import { CommonTableComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';

import { finalize } from 'rxjs/operators';
import { PER_PAGE, DELETE_LABEL } from '@microsec/constants';

import { ReportFormComponent } from './report-form/report-form.component';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { MODULES } from '../../../../modules';
import { PROJECT_MANAGEMENT_CONSTANTS, QUOTAS_FEATURES, REPORT_STATUSES } from '@ids-constants';
import { ASSESSMENT_TOOL_CONSTANTS } from '../../assessment-tool/assessment-tool.config';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ReportPreviewComponent } from './report-preview/report-preview.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  currentPage = 1;

  totalRecords = 0;

  @ViewChild('dt') dt!: CommonTableComponent;

  values: any[] = [];

  selectedReports: any[] = [];

  selectedReport: any = null;

  reportFilters: any = {};

  reportTypes: any[] = [];

  assessments: any[] = [];

  tags: any[] = [];

  devices: any[] = [];

  connections: any[] = [];

  cols: any[] = [
    { field: 'id', header: 'ID', width: 10 },
    { field: 'name', header: 'Name', width: 15 },
    { field: 'reportTypeName', header: 'Type', width: 15 },
    { field: 'actions', header: 'Actions', width: 15, frozen: true },
  ];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  getReportsInterval: any;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['filter'],
    searchPlaceholder: 'Search...',
    filters: {
      1: {
        key: 'report_type_id',
        label: 'Report Type',
        type: 'dropdown',
        options: [],
      },
      2: {
        key: 'status',
        label: 'Status',
        type: 'dropdown',
        options: Object.values(REPORT_STATUSES).map((v) => ({ label: this.util.getUppercaseFirstLetter(v), value: v })),
      },
    },
  };

  filters: { [key: string]: any } = {};

  REPORT_STATUSES = REPORT_STATUSES;

  downloadReportMenuOptions: MenuItem[] = [];

  @ViewChild('downloadReportMenuPanel') downloadReportMenuPanel!: OverlayPanel;

  constructor(
    private reportSrv: ReportService,
    private assessmentSrv: AssessmentService,
    private targetDeviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      this.getData();
      this.getReportsInterval = setInterval(() => {
        this.getReports(undefined, false);
      }, 30000);
      this.filterObjectObs.subscribe((values) => {
        if (!!values) {
          if (!!values?.isFiltered) {
            this.currentPage = 1;
            if (this.dt?.datatable) {
              this.dt.datatable.first = 0;
            }
          }
          if (values?.isSortReset && this.dt?.datatable) {
            this.dt.datatable.sortField = null;
            this.dt.datatable.sortOrder = 1;
            this.dt.datatable.multiSortMeta = null;
            this.dt?.datatable.tableService.onSort(null);
          }
          this.filters = {
            ...(values?.filter || {}),
          };
          if (!!values?.isFiltered || !!values?.isSortReset) {
            this.getReports();
          }
        }
      });
    }
  }

  getData() {
    forkJoin({
      reportFilter: this.reportSrv.getReportFilters().pipe(this.handleRequest(true).bind(this)),
      reportType: this.reportSrv
        .getReportTypes('', this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
        .pipe(this.handleRequest(true).bind(this)),
      assessment: this.assessmentSrv
        .getAssessments(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
        .pipe(this.handleRequest(true).bind(this)),
      tag: this.targetDeviceSrv
        .getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
        .pipe(this.handleRequest(true).bind(this)),
      device: this.targetDeviceSrv
        .getDevices({ organizationId: this.breadcrumbConfig?.organizationId, projectId: this.breadcrumbConfig?.projectId, detailed: false })
        .pipe(this.handleRequest(true).bind(this)),
      connection: this.connectionSrv.getConnections(this.breadcrumbConfig?.projectId).pipe(this.handleRequest(true).bind(this)),
    })
      .pipe(
        finalize(() => {
          this.getReports();
        }),
      )
      .subscribe({
        next: (res) => {
          this.reportFilters = res.reportFilter?.all_filters || {};
          this.reportTypes = (res.reportType?.report_types as any[]) || [];
          this.assessments = (res.assessment?.assessments as any[]) || [];
          this.tags = (res.tag?.tags as any[]) || [];
          this.devices = (res.device?.devices as any[]) || [];
          this.connections = (res.connection?.data as any[]) || [];

          if (!!this.filterConfiguration?.filters?.[1]) {
            this.filterConfiguration.filters[1].options = this.reportTypes.map((v) => ({ label: v.name, value: v.id }));
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getReports(event?: LazyLoadEvent, showLoading = true) {
    if (!!showLoading) {
      this.isLoading = true;
    }
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.reportSrv
      .getReports(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        page,
        perPage,
        this.dt?.datatable?.sortField as any,
        this.dt?.datatable?.sortOrder ? this.dt?.datatable?.sortOrder === 1 : true,
        this.filters['report_type_id'],
        this.filters['status'],
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          const reports = ((res.reports as any[]) || []).map((report) => {
            return {
              ...report,
              reportTypeName: this.reportTypes.find((type) => type.id === report.report_type_id)?.name || `Report Type ID: ${report.report_type_id}`,
              reportFilters: (report.report_filters || []).reduce((obj: { [key: string]: any }, filter: any) => {
                const filters = Object.keys(filter)
                  .filter((key) => key !== 'data_source' && !!filter[key])
                  .filter((key) => !Array.isArray(filter[key]) || (!!Array.isArray(filter[key]) && !!filter[key].length))
                  .reduce(
                    (a, key) => ({
                      ...a,
                      [key]: !!Array.isArray(filter[key]) ? filter[key].map((v: any) => v.data || v) : filter[key].data || filter[key],
                    }),
                    {},
                  );
                return { ...obj, [filter.data_source]: filters };
              }, {}),
            };
          });
          this.values = reports;
          if (!!this.selectedReport) {
            this.selectedReport = this.values.find((report) => this.selectedReport.id === report.id);
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openReportForm(report?: any) {
    const dialog = this.dialogSrv.open(ReportFormComponent, {
      data: {
        report: !report ? null : report,
        assessments: this.assessments,
        tags: this.tags,
        connections: this.connections,
        devices: this.devices,
        reportTypes: this.reportTypes,
        reportFilters: this.reportFilters,
      },
      header: !report ? 'Create Report' : `Duplicate ${report.reportTypeName}`,
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getReports();
      }
    });
  }

  openDeleteConfirmation(reports: any[] = []) {
    const reportList = reports.map((q) => `<li>${q.name}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Report(s)',
      customContent: `Are you sure you want to delete ${
        !reports?.length ? 'all reports?<br/><br/>' : `the following report(s)?<ul>${reportList.join('')}</ul>`
      }`,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: forkJoin((!!reports.length ? reports : this.values).map((q) => this.reportSrv.deleteReport(q.id))),
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`Deleted report(s) successfully`);
        const reportIds = reports.map((report) => report.id) || [];
        if (!!this.selectedReports?.length) {
          this.selectedReports = this.selectedReports.filter((report) => !reportIds?.includes(report.id));
        }
        if (!!this.selectedReport && !!reportIds.includes(this.selectedReport.id)) {
          this.selectedReport = null;
        }
        this.getReports();
      },
      error: (err) => {
        this.isLoading = false;
        this.showErrorMessage(err);
      },
    });
  }

  previewReport(report: any) {
    if (!!report) {
      this.dialogSrv.open(ReportPreviewComponent, {
        data: { report },
        closeOnEscape: false,
        showHeader: false,
        modal: false,
        draggable: false,
        resizable: false,
        closable: false,
        styleClass: 'dialog-full-screen',
      });
    }
  }

  downloadReport(report: any, type = 'pdf') {
    if (report) {
      this.isLoading = true;
      this.reportSrv
        .downloadReport(report.id, type)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.showSuccessMessage(`Downloaded ${report.name} successfully`);
            this.util.downloadClientFile(`${(report.name || '').split(' ').join('_')}.${type}`, res);
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  setDownloadMenu(event: any, report: any) {
    if (!!report) {
      this.downloadReportMenuOptions = [
        {
          label: 'PDF',
          icon: 'fas fa-file-pdf',
          command: () => {
            this.downloadReportMenuPanel.hide();
            this.downloadReport(report);
          },
        },
        {
          label: 'Docx',
          icon: 'fas fa-file-word',
          command: () => {
            this.downloadReportMenuPanel.hide();
            this.downloadReport(report, 'docx');
          },
        },
      ];
      this.downloadReportMenuPanel.toggle(event);
    }
  }

  goToAssessment(assessmentId: any) {
    this.assessmentSrv.selected = { id: assessmentId };
    if (!!assessmentId) {
      this.changeRoute(
        `${MODULES.PROJECT_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, this.breadcrumbConfig?.organizationId?.toString()).replace(
          PROJECT_ID_PARAM_ROUTE,
          this.breadcrumbConfig?.projectId?.toString(),
        )}/${PROJECT_MANAGEMENT_CONSTANTS.ASSESSMENT_TOOL.ROUTE}/${ASSESSMENT_TOOL_CONSTANTS.ASSESSMENTS.ROUTE}`,
      );
    }
  }

  get checkReportQuota() {
    return this.totalRecords < this.getQuotaLimitation(QUOTAS_FEATURES.REPORTS);
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedReport = null;
  }

  override ngOnDestroy(): void {
    this.cleanup();
    clearInterval(this.getReportsInterval);
  }
}
