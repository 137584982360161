import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL, USERNAME_PATTERN } from '@microsec/constants';
import {
  MODEL_OPTIONS,
  TYPE_OPTIONS,
  CONFIG_TYPE_OPTIONS,
  SNMP_VERSION_OPTIONS,
  CONFIG_TYPES,
  SNMP_AUTH_PROTOCOL_OPTIONS,
  SNMP_PRIV_PROTOCOL_OPTIONS,
} from '@ids-constants';
import { IPV4_PATTERN, PORT_PATTERN } from '@microsec/constants';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { NetworkDeviceService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, forkJoin, finalize } from 'rxjs';

export const FORM_PARAMS = {
  PROJECT_ID: 'project_id',
  DEVICE_ID: 'device_id',
  DEVICE_TYPE: 'device_type',
  LABEL: 'label',
  DESCRIPTION: 'description',
  MODEL: 'model',
  PROTOCOL: 'protocol',
  NUM_PHYSICAL_PORTS: 'num_physical_ports',
  CONFIG: 'config',
  CONFIG_TYPE: 'config_type',
  IP_ADDRESS: 'ip_address',
  PORT: 'port',
  USERNAME: 'username',
  PASSWORD: 'password',
  SNMP_VERSION: 'snmp_version',
  SNMP_COMMUNITY_STRING: 'snmp_community_string',
  SNMP_PRIV_PROTOCOL: 'snmp_priv_protocol',
  SNMP_AUTH_PROTOCOL: 'snmp_auth_protocol',
  SNMP_ENCRYPT_KEY: 'snmp_encrypt_key',
  STATUS: 'status',
};

@Component({
  selector: 'app-shared-network-device-form',
  templateUrl: './shared-network-device-form.component.html',
  styleUrls: ['./shared-network-device-form.component.scss'],
})
export class SharedNetworkDeviceFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  device: any = null;

  config: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  FORM_PARAMS = FORM_PARAMS;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  constructor(
    private networkDeviceSrv: NetworkDeviceService,
    private dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.device = this.dialogConfig?.data?.device;
    if (!!this.device) {
      this.config = this.device['configs']?.[0] || null;
    }
    this.initForm();
  }

  initForm() {
    const selectedModelOption = !!this.device ? MODEL_OPTIONS.find((model) => model.value === this.device[FORM_PARAMS.MODEL]) : null;
    const deviceFields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DEVICE_TYPE,
        label: 'Device Type',
        field: !!this.device ? 'label' : 'dropdown',
        placeholder: 'Select a type',
        options: TYPE_OPTIONS,
        required: true,
        fieldInfo: 'Type of the network device',
        defaultValue: this.device?.[FORM_PARAMS.DEVICE_TYPE]
          ? TYPE_OPTIONS.find((type) => type.value === this.device[FORM_PARAMS.DEVICE_TYPE])?.label || this.device[FORM_PARAMS.DEVICE_TYPE]
          : null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.MODEL,
        label: 'Model',
        field: !!this.device ? 'label' : 'dropdown',
        placeholder: 'Select a model',
        options: [] as any[],
        disabled: true,
        required: true,
        fieldInfo: 'Model of the network device',
        defaultValue: selectedModelOption?.label || null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        required: true,
        fieldInfo: 'Label of the network device',
        defaultValue: this.device?.[FORM_PARAMS.LABEL] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        fieldInfo: 'Description of the network device',
        defaultValue: this.device?.[FORM_PARAMS.DESCRIPTION] || '',
      } as FormItem),
    ];
    const configFields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONFIG_TYPE,
        label: 'Connection Type',
        field: !!this.config ? 'label' : 'radio',
        options: selectedModelOption?.supportedConfigTypeOptions || [],
        required: true,
        fieldInfo: 'Connection type of the network device',
        defaultValue: !!this.config?.[FORM_PARAMS.CONFIG_TYPE]
          ? CONFIG_TYPE_OPTIONS?.find((type) => type.value === this.config[FORM_PARAMS.CONFIG_TYPE])?.label
          : null,
        hidden: !this.device?.[FORM_PARAMS.MODEL],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.IP_ADDRESS,
        label: 'IP Address',
        required: true,
        pattern: IPV4_PATTERN,
        fieldInfo: 'IP Address to use for connection using this configuration',
        defaultValue: this.config?.[FORM_PARAMS.IP_ADDRESS] || '',
        hidden: !this.device?.[FORM_PARAMS.MODEL],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PORT,
        label: 'Port',
        required: true,
        pattern: PORT_PATTERN,
        fieldInfo: 'Port to use for connection using this configuration',
        defaultValue: this.config?.[FORM_PARAMS.PORT] || '',
        hidden: !this.device?.[FORM_PARAMS.MODEL],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SNMP_VERSION,
        label: 'SNMP Version',
        field: 'dropdown',
        placeholder: 'Select an SNMP version',
        options: SNMP_VERSION_OPTIONS,
        fieldInfo: 'Version for an SNMP configuration',
        defaultValue: this.config?.[FORM_PARAMS.SNMP_VERSION] || null,
        hidden: !this.device?.[FORM_PARAMS.MODEL] || this.config?.[FORM_PARAMS.CONFIG_TYPE] !== CONFIG_TYPES.SNMP,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USERNAME,
        label: 'Username',
        fieldInfo: 'Username to use for connection using this configuration',
        defaultValue: this.config?.[FORM_PARAMS.USERNAME] || '',
        pattern: USERNAME_PATTERN,
        hidden:
          !this.device?.[FORM_PARAMS.MODEL] ||
          (this.config?.[FORM_PARAMS.CONFIG_TYPE] === CONFIG_TYPES.SNMP && this.config?.[FORM_PARAMS.SNMP_VERSION] !== '3'),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSWORD,
        label: 'Password',
        field: 'password',
        feedback: false,
        placeholder: !!this.config ? 'Leave empty to use old password' : '',
        fieldInfo: 'Password to use for connection using this configuration',
        defaultValue: '',
        hidden:
          !this.device?.[FORM_PARAMS.MODEL] ||
          (this.config?.[FORM_PARAMS.CONFIG_TYPE] === CONFIG_TYPES.SNMP && this.config?.[FORM_PARAMS.SNMP_VERSION] !== '3'),
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SNMP_COMMUNITY_STRING,
        label: 'SNMP Community String',
        fieldInfo: 'Community string for an SNMP configuration',
        defaultValue: this.config?.[FORM_PARAMS.SNMP_COMMUNITY_STRING] || '',
        hidden:
          !this.device?.[FORM_PARAMS.MODEL] ||
          this.config?.[FORM_PARAMS.CONFIG_TYPE] !== CONFIG_TYPES.SNMP ||
          this.config?.[FORM_PARAMS.SNMP_VERSION] === '3',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SNMP_AUTH_PROTOCOL,
        label: 'SNMP Authentication Protocol',
        field: 'dropdown',
        placeholder: 'Select an SNMP authentication protocol',
        options: SNMP_AUTH_PROTOCOL_OPTIONS,
        fieldInfo: 'Authentication protocol for an SNMPv3 configuration',
        defaultValue: this.config?.[FORM_PARAMS.SNMP_AUTH_PROTOCOL] || null,
        hidden:
          !this.device?.[FORM_PARAMS.MODEL] ||
          this.config?.[FORM_PARAMS.CONFIG_TYPE] !== CONFIG_TYPES.SNMP ||
          this.config?.[FORM_PARAMS.SNMP_VERSION] !== '3',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SNMP_PRIV_PROTOCOL,
        label: 'SNMP Privacy Protocol',
        field: 'dropdown',
        placeholder: 'Select an SNMP privacy protocol',
        options: SNMP_PRIV_PROTOCOL_OPTIONS,
        fieldInfo: 'Privacy protocol for an SNMPv3 configuration',
        defaultValue: this.config?.[FORM_PARAMS.SNMP_PRIV_PROTOCOL] || null,
        hidden:
          !this.device?.[FORM_PARAMS.MODEL] ||
          this.config?.[FORM_PARAMS.CONFIG_TYPE] !== CONFIG_TYPES.SNMP ||
          this.config?.[FORM_PARAMS.SNMP_VERSION] !== '3',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SNMP_ENCRYPT_KEY,
        label: 'SNMP Encryption Key',
        fieldInfo: 'Encryption key for an SNMPv3 configuration',
        defaultValue: this.config?.[FORM_PARAMS.SNMP_ENCRYPT_KEY] || '',
        hidden:
          !this.device?.[FORM_PARAMS.MODEL] ||
          this.config?.[FORM_PARAMS.CONFIG_TYPE] !== CONFIG_TYPES.SNMP ||
          this.config?.[FORM_PARAMS.SNMP_VERSION] !== '3',
      } as FormItem),
    ];
    const fields: FormItem[] = [
      ...deviceFields,
      Object.assign(new FormItem(), {
        name: 'connectionDetailsDivider',
        field: 'divider',
        hidden: !this.device,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: 'connectionDetailsTitle',
        label: 'Connection Details',
        field: 'label',
        labelStyleClass: 'py-2 font-bold',
        hidden: !this.device,
      } as FormItem),
      ...configFields,
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.DEVICE_TYPE, (value: string) => {
        const modelField = this.fields.find((p) => p.name === FORM_PARAMS.MODEL);
        if (!!modelField) {
          modelField.options = MODEL_OPTIONS.filter((model) => model.deviceType === value);
        }
        this.form.setControlValue(FORM_PARAMS.MODEL, null);
        this.form.enableControl(FORM_PARAMS.MODEL);
      });
      this.form?.setChangeEvent(FORM_PARAMS.MODEL, (value: string) => {
        if (!!value) {
          const configTypeField = this.fields.find((p) => p.name === FORM_PARAMS.CONFIG_TYPE);
          if (!!configTypeField) {
            configTypeField.options = MODEL_OPTIONS.find((model) => model.value === value)?.supportedConfigTypeOptions || [];
          }
          this.form.setControlValue(FORM_PARAMS.CONFIG_TYPE, configTypeField?.options?.[0].value);
        } else {
          this.form.setControlValue(FORM_PARAMS.CONFIG_TYPE, null);
          this.form.setControlVisibility(FORM_PARAMS.SNMP_VERSION, false);
          this.form.setControlVisibility(FORM_PARAMS.USERNAME, false);
          this.form.setControlVisibility(FORM_PARAMS.PASSWORD, false);
          this.form.setControlVisibility(FORM_PARAMS.SNMP_COMMUNITY_STRING, false);
          this.form.setControlVisibility(FORM_PARAMS.SNMP_AUTH_PROTOCOL, false);
          this.form.setControlVisibility(FORM_PARAMS.SNMP_PRIV_PROTOCOL, false);
          this.form.setControlVisibility(FORM_PARAMS.SNMP_ENCRYPT_KEY, false);
        }
        this.form.setControlVisibility('connectionDetailsDivider', !!value);
        this.form.setControlVisibility('connectionDetailsTitle', !!value);
        this.form.setControlVisibility(FORM_PARAMS.CONFIG_TYPE, !!value);
        this.form.setControlVisibility(FORM_PARAMS.IP_ADDRESS, !!value);
        this.form.setControlVisibility(FORM_PARAMS.PORT, !!value);
      });
      this.form?.setChangeEvent(FORM_PARAMS.CONFIG_TYPE, (value: string) => {
        const isSNMP = value === CONFIG_TYPES.SNMP;
        const usernameField = this.fields.find((p) => p.name === FORM_PARAMS.USERNAME);
        if (!!usernameField) {
          usernameField.label = `${!!isSNMP ? 'SNMP ' : ''} Username`;
        }
        const passwordField = this.fields.find((p) => p.name === FORM_PARAMS.PASSWORD);
        if (!!passwordField) {
          passwordField.label = `${!!isSNMP ? 'SNMP ' : ''} Password`;
        }
        this.form.setControlVisibility(FORM_PARAMS.SNMP_VERSION, !!isSNMP);
        this.form.setControlVisibility(FORM_PARAMS.USERNAME, !isSNMP);
        this.form.setControlVisibility(FORM_PARAMS.PASSWORD, !isSNMP);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_COMMUNITY_STRING, !!isSNMP);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_AUTH_PROTOCOL, !!isSNMP);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_PRIV_PROTOCOL, !!isSNMP);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_ENCRYPT_KEY, !!isSNMP);
        if (!!isSNMP) {
          this.form.setControlValue(FORM_PARAMS.SNMP_VERSION, '2c');
        }
      });
      this.form?.setChangeEvent(FORM_PARAMS.SNMP_VERSION, (value: string) => {
        const isV3 = value === '3';
        this.form.setControlVisibility(FORM_PARAMS.USERNAME, !!isV3);
        this.form.setControlVisibility(FORM_PARAMS.PASSWORD, !!isV3);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_COMMUNITY_STRING, !isV3);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_AUTH_PROTOCOL, !!isV3);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_PRIV_PROTOCOL, !!isV3);
        this.form.setControlVisibility(FORM_PARAMS.SNMP_ENCRYPT_KEY, !!isV3);
      });
    });
  }

  /**
   * Create/Edit the project and its profile
   * @param closeDialog
   */
  onSubmit(closeDialog: (res: any) => void) {
    this.form.isLoading = true;
    const formValue = { ...this.form.getRawValue() };
    const devicePayload = {
      ...(!this.device && {
        [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
        [FORM_PARAMS.MODEL]: formValue[FORM_PARAMS.MODEL],
      }),
      [FORM_PARAMS.LABEL]: formValue[FORM_PARAMS.LABEL],
      [FORM_PARAMS.DESCRIPTION]: formValue[FORM_PARAMS.DESCRIPTION],
    };
    const configPayload = {
      ...(!this.config && {
        [FORM_PARAMS.CONFIG_TYPE]: formValue[FORM_PARAMS.CONFIG_TYPE],
      }),
      [FORM_PARAMS.IP_ADDRESS]: formValue[FORM_PARAMS.IP_ADDRESS],
      [FORM_PARAMS.PORT]: formValue[FORM_PARAMS.PORT],
      [FORM_PARAMS.USERNAME]: formValue[FORM_PARAMS.USERNAME],
      [FORM_PARAMS.PASSWORD]: formValue[FORM_PARAMS.PASSWORD],
      ...(formValue[FORM_PARAMS.CONFIG_TYPE] === CONFIG_TYPES.SNMP && {
        [FORM_PARAMS.SNMP_COMMUNITY_STRING]: formValue[FORM_PARAMS.SNMP_COMMUNITY_STRING],
        [FORM_PARAMS.SNMP_PRIV_PROTOCOL]: formValue[FORM_PARAMS.SNMP_PRIV_PROTOCOL],
        [FORM_PARAMS.SNMP_AUTH_PROTOCOL]: formValue[FORM_PARAMS.SNMP_AUTH_PROTOCOL],
        [FORM_PARAMS.SNMP_ENCRYPT_KEY]: formValue[FORM_PARAMS.SNMP_ENCRYPT_KEY],
      }),
    };
    Object.keys(devicePayload).forEach((key) => {
      devicePayload[key] = devicePayload[key] || undefined;
    });
    Object.keys(configPayload).forEach((key) => {
      configPayload[key] = configPayload[key] || undefined;
    });
    const request: Observable<any> = !this.device
      ? this.networkDeviceSrv.createDevice({ ...devicePayload, [FORM_PARAMS.CONFIG]: configPayload })
      : forkJoin([
          this.networkDeviceSrv.updateDevice(this.device.id, devicePayload),
          this.networkDeviceSrv.updateDeviceConfig(this.config.id, configPayload),
        ]);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.networkDeviceSrv.refresh$.next(true);
          closeDialog(res);
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
