import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { AssessmentService } from '@ids-services';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, finalize } from 'rxjs';

@Component({
  selector: 'app-assessment-type-questions-form',
  templateUrl: './questions-form.component.html',
  styleUrls: ['./questions-form.component.scss'],
})
export class QuestionsFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  values: any[] = [];

  cols = [
    { field: 'id', header: 'ID', width: 5 },
    { field: 'title', header: 'Title', width: 15 },
  ];

  totalRecords = 0;

  allQuestions: any[] = [];

  selectedQuestions: any[] = [];

  excludedQuestionIds: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search Title, or Description...',
    hideClearFilters: false,
  };

  filterSearch = '';

  isFilterSelected = false;

  constructor(
    private assessmentSrv: AssessmentService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.excludedQuestionIds = this.dialogConfig?.data?.excludedQuestionIds || [];
    this.getAssessmentQuestions();
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.filterSearch = values.search || '';
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getAssessmentQuestions();
      }
    });
  }

  getAssessmentQuestions() {
    this.isLoading = true;
    this.assessmentSrv
      .getAssessmentQuestions(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        undefined,
        undefined,
        this.dt?.datatable?.sortField as any,
        this.dt?.datatable?.sortOrder ? this.dt?.datatable?.sortOrder === 1 : true,
        this.filterSearch,
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const allQuestions = ((res.questions as any[]) || []).filter((question) => !(this.excludedQuestionIds || []).includes(question.id));
          this.totalRecords = allQuestions.length;
          this.allQuestions = allQuestions;
          this.setValues();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onSubmit() {
    if (!!this.selectedQuestions?.length) {
      this.dialogRef.close(this.selectedQuestions);
    } else {
      this.showErrorMessage('Please select question(s).', 'warn');
    }
  }

  setValues() {
    this.values = this.util.cloneObjectArray(!!this.isFilterSelected ? this.selectedQuestions || [] : this.allQuestions || []);
  }

  filterSelected() {
    this.isFilterSelected = !this.isFilterSelected;
    this.setValues();
  }
}
