import { Component, Input } from '@angular/core';
import { EDITOR_LAYER_BUTTONS } from '@ids-constants';
import { NetworkMapEditor } from '@ids-models';

@Component({
  selector: 'app-nme-arrange',
  templateUrl: './nme-arrange.component.html',
  styleUrls: ['./nme-arrange.component.scss'],
})
export class NmeArrangeComponent {
  @Input() editor: NetworkMapEditor | null = null;

  layerButtons = Object.values(EDITOR_LAYER_BUTTONS).map((item) => ({
    label: item.LABEL,
    command: () => {
      this.editor?.orderLayers(item.VALUE);
    },
  }));
}
