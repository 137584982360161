import { Component, Input, OnInit } from '@angular/core';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { ActionMenuItem } from '@microsec/models';
import { NONIP_BUILT_IN_RULES, NONIP_RULE_TYPES } from '@ids-constants';

import { FORM_PARAMS, NonipCustomRuleFormComponent } from './nonip-custom-rule-form/nonip-custom-rule-form.component';

@Component({
  selector: 'app-analyzer-nonip-ruleset-field',
  templateUrl: './analyzer-nonip-ruleset-field.component.html',
  styleUrls: ['./analyzer-nonip-ruleset-field.component.scss'],
})
export class AnalyzerNonipRulesetFieldComponent extends BaseComponent implements OnInit {
  rulesetCols = [
    { field: 'enabled', header: 'Status', width: 10 },
    { field: 'name', header: 'Ruleset', width: 15 },
    { field: 'description', header: 'Description', width: 15 },
  ];

  customRuleCols = [
    { field: 'enabled', header: 'Status', width: 10 },
    { field: 'name', header: 'Name', width: 15 },
    { field: 'rule', header: 'Rule', width: 15 },
  ];

  customRuleRows = PER_PAGE;

  customRuleFirstIndex = 0;

  enabledRuleset = true;

  enabledCustomRuleset = true;

  rulesets: any[] = [];

  customRules: any[] = [];

  customActionsItems: ActionMenuItem[] = [];

  @Input() analyzer: any = null;

  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.customActionsItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData, index }) => this.openCustomForm(rowData, index),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData, index }) => this.openDeleteConfirmation(rowData, index),
      },
    ];
    if (!!this.analyzer) {
      this.populateForm();
    } else {
      this.rulesets = [...NONIP_BUILT_IN_RULES.map((rule) => ({ ...rule, enabled: true }))];
    }
  }

  openCustomForm(data?: any, index: any = null) {
    const dialog = this.dialogSrv.open(NonipCustomRuleFormComponent, {
      data: !data ? null : { data: data },
      header: `${!data ? 'Create' : 'Edit'} Custom Rule`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        if (rs[FORM_PARAMS.RULE][0] !== '=') {
          rs[FORM_PARAMS.RULE] = '=' + rs[FORM_PARAMS.RULE];
        }
        if (!rs.id && index === null) {
          // Add new custom rule
          this.customRules.push(rs);
        } else {
          //  Edit newly created custom rule or existing custom rule
          if (typeof index === 'number') {
            this.customRules[index] = rs;
          }
        }
      }
    });
  }

  openDeleteConfirmation(data: any, index: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Analyzer',
      customContent: `<ul><li>${`Custom Rule ID ${data.name || ''} ${!!data.name ? ` : ${data.name}` : ''}`}</li></ul>`,
      next: () => {
        this.customRules.splice(index, 1);
        this.showSuccessMessage(`Deleted Custom Rule ${data.name || ''} successfully`);
      },
    });
  }

  populateForm() {
    const data = this.analyzer.rule_types;
    if (!!data && data.length > 0) {
      const builtinRules = data.find((datum: any) => datum.name === NONIP_RULE_TYPES.BUILT_IN) || null;
      if (!!builtinRules) {
        this.enabledRuleset = builtinRules.enabled;
        this.rulesets = builtinRules.rule_sets || [];
      }
      const customRules = data.find((datum: any) => datum.name === NONIP_RULE_TYPES.CUSTOM) || null;
      if (!!customRules) {
        this.enabledCustomRuleset = customRules.enabled;
        this.customRules = (customRules.rule_sets.find((rule: any) => rule.id === 5) || null)?.rules || [];
      }
    }
  }
}
