<div class="py-3 px-5" *ngIf="!!assessment">
  <div class="overflow-y-auto" style="max-height: 60vh">
    @if (!!assessment.descriptionPreview) {
      <div class="px-3">
        <div>
          <label class="font-bold text-data">Assessment Description</label>
        </div>
        <div class="markdown-preview text-grey" [innerHTML]="assessment.descriptionPreview"></div>
      </div>
      <div>
        <p-divider></p-divider>
      </div>
    }
    <div class="px-3" *ngIf="!!assessment.assessmentType">
      <div class="flex flex-column gap-2 mb-3">
        <label class="font-bold text-data">Assessment Type</label>
        <label class="text-grey">{{ assessment.assessmentType.name }}</label>
      </div>
      @if (assessment.assessmentType.descriptionPreview) {
        <div>
          <label class="font-bold text-data">Assessment Type Description</label>
        </div>
        <div class="markdown-preview text-grey" [innerHTML]="assessment.assessmentType.descriptionPreview"></div>
      }
    </div>
  </div>
  <div>
    <p-divider></p-divider>
  </div>
  <div class="flex justify-content-end">
    <p-button severity="primary" type="button" (onClick)="dialogRef.close()" label="Close"> </p-button>
  </div>
</div>
