<div>
  <div class="grid">
    <div class="col-12">
      <p-accordion>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="flex flex-row w-full justify-content-between align-items-center">
              <span>Built-In Rules</span>
              <div class="custom-switch">
                <p-inputSwitch [(ngModel)]="enabledRuleset" (click)="$event.stopPropagation()"></p-inputSwitch>
              </div>
            </div>
          </ng-template>
          <div class="grid">
            <div class="col-12">
              <microsec-card [isNestedCard]="true" [isTableCard]="true">
                <microsec-common-table [cols]="rulesetCols" [values]="rulesets" [scrollHeight]="'35rem'" [cellTemplate]="cellTemplate">
                </microsec-common-table>
              </microsec-card>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="flex flex-row w-full justify-content-between align-items-center">
              <span>Custom Rules</span>
              <div class="custom-switch">
                <p-inputSwitch [(ngModel)]="enabledCustomRuleset" (click)="$event.stopPropagation()"></p-inputSwitch>
              </div>
            </div>
          </ng-template>
          <div class="grid" id="custom-rule">
            <div class="col-12">
              <microsec-card [isNestedCard]="true" [isTableCard]="true">
                <microsec-common-table
                  [(rows)]="customRuleRows"
                  [firstIndex]="customRuleFirstIndex"
                  [cols]="customRuleCols"
                  [values]="customRules"
                  [scrollHeight]="'35rem'"
                  [containerHeaderRightTemplate]="addCustomRuleTemplate"
                  [cellTemplate]="cellTemplate"
                  [actionsMenuItems]="customActionsItems">
                </microsec-common-table>
              </microsec-card>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>

<ng-template #addCustomRuleTemplate>
  <p-button
    type="button"
    icon="fa fa-plus"
    severity="success"
    size="small"
    pTooltip="Add Custom Rules"
    tooltipPosition="bottom"
    appendTo="body"
    (onClick)="openCustomForm()"
    *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></p-button>
</ng-template>

<ng-template let-index="index" let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <ng-container *ngSwitchCase="'enabled'">
      <p-inputSwitch [(ngModel)]="rowData[col.field]"></p-inputSwitch>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ rowData[col.field] || '-' }}
    </ng-container>
  </ng-container>
</ng-template>
