<div class="flex flex-wrap gap-3 mb-2">
  <div
    *ngFor="let tag of selectedTags"
    class="custom-chip cursor-pointer"
    [style]="{ background: util.hexToRgba(tag.color), color: tag.color }"
    (click)="onClickSelectedTag($event, tag)">
    {{ tag.label }}
  </div>
  <p-button
    icon="pi pi-plus"
    [label]="'Add ' + fieldName | titlecase"
    severity="secondary"
    size="small"
    [rounded]="true"
    styleClass="text-transform-none"
    (onClick)="onClickAddTag($event)"></p-button>
</div>

<p-overlayPanel #addTagPanel styleClass="tag-overlay" appendTo="body">
  <ng-template pTemplate="content">
    <microsec-loading-overlay [isLoading]="!!isLoading">
      <form *ngIf="!!form && !editedTag" class="no-wrapper" [formGroup]="form">
        <div class="mb-2">
          <span class="p-input-icon-right w-full">
            <i class="pi pi-search"></i>
            <input type="text" pInputText [formControlName]="TAG_FORM_PARAMS.LABEL" />
          </span>
        </div>
        <div class="grid mt-2 max-h-8rem overflow-y-auto">
          @if (!!tagOptions.length) {
            <div *ngFor="let tag of tagOptions" class="col-12">
              <div
                class="custom-chip cursor-pointer"
                [style]="{ background: util.hexToRgba(tag.color), color: tag.color }"
                (click)="onSelectTag(tag)">
                {{ tag.label }}
              </div>
            </div>
          } @else {
            <label class="col-12 text-center text-grey"> No {{ fieldName | lowercase }}s found. </label>
            <label class="col-12 text-center text-grey">
              Create a {{ fieldName | lowercase }} by entering a tag name on the search bar and selecting Create New {{ fieldName | titlecase }}.
            </label>
          }
        </div>
        <div class="add-tag-container grid">
          <div class="col flex flex-column gap-2">
            <label class="font-bold"> Create New {{ fieldName | titlecase }} </label>
            <label>
              {{ form.get(TAG_FORM_PARAMS.LABEL)?.value }}
            </label>
          </div>
          <div class="col-fixed flex align-items-center gap-2">
            <microsec-custom-color-picker [(color)]="color" [presetColors]="TAGS_PRESET_COLORS"></microsec-custom-color-picker>
            <p-button icon="pi pi-plus" [disabled]="!form.get(TAG_FORM_PARAMS.LABEL)?.value" [rounded]="true" (onClick)="onSubmit()"></p-button>
          </div>
        </div>
      </form>
    </microsec-loading-overlay>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #editTagPanel styleClass="tag-overlay" appendTo="body">
  <ng-template pTemplate="content">
    <microsec-loading-overlay [isLoading]="!!isLoading">
      <form *ngIf="!!form && !!editedTag" class="no-wrapper" [formGroup]="form">
        <div class="field grid">
          <label class="col-4">Name</label>
          <div class="col-8">
            <input
              inputTrimRef
              type="text"
              pInputText
              [formControlName]="TAG_FORM_PARAMS.LABEL"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  !!form &&
                  !!form.get(TAG_FORM_PARAMS.LABEL)?.touched &&
                  !!form.get(TAG_FORM_PARAMS.LABEL)?.dirty &&
                  !!form.get(TAG_FORM_PARAMS.LABEL)?.errors
              }" />
          </div>
          <label
            class="col-8 col-offset-4 text-error"
            *ngIf="
              !!form &&
              !!form.get(TAG_FORM_PARAMS.LABEL)?.touched &&
              !!form.get(TAG_FORM_PARAMS.LABEL)?.dirty &&
              !!form.get(TAG_FORM_PARAMS.LABEL)?.errors
            ">
            Name is required
          </label>
        </div>
        <div class="field grid">
          <label class="col-4">Color</label>
          <div class="col-8">
            <microsec-custom-color-picker [(color)]="color" [presetColors]="TAGS_PRESET_COLORS"></microsec-custom-color-picker>
          </div>
        </div>
        <div class="mt-3">
          <div class="flex action-buttons flex-row align-items-center justify-content-end gap-2">
            <p-button severity="secondary" type="button" (onClick)="editTagPanel.hide()" label="Cancel"> </p-button>
            <p-button severity="danger" type="button" label="Unassign" (onClick)="onRemoveTag(editedTag.id)"> </p-button>
            <p-button severity="success" type="button" [label]="SAVE_CHANGES_LABEL" (onClick)="onSubmit()"> </p-button>
          </div>
        </div>
      </form>
    </microsec-loading-overlay>
  </ng-template>
</p-overlayPanel>
