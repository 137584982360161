/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, BehaviorSubject, Observable, scheduled } from 'rxjs';

import { IConnectionService } from './IConnectionService';
import connectionsData from './mock-data/connections2.json';
import connectionData from './mock-data/connection.json';
import connectionProtocolsData from './mock-data/connection-protocols.json';
import protocolsData from './mock-data/protocols.json';
import availableConnectionsData from './mock-data/available-connections.json';
import connectionMetrics from './mock-data/connection-metrics.json';

@Injectable({
  providedIn: 'root',
})
export class MockConnectionService implements IConnectionService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected: any = null;
  createConnection(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getConnections(organizationId?: number, projectId?: number): Observable<any> {
    return scheduled([connectionsData as any], asyncScheduler);
  }
  getConnection(id: number): Observable<any> {
    return scheduled([connectionData as any], asyncScheduler);
  }
  updateConnection(id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteConnection(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteConnections(ids: number[]): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getStatus(): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  testConnection(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getConnectionsProtocols(): Observable<any> {
    return scheduled([connectionProtocolsData as any], asyncScheduler);
  }
  getProtocols(): Observable<any> {
    return scheduled([protocolsData as any], asyncScheduler);
  }
  getAvailableConnections(): Observable<any> {
    return scheduled([availableConnectionsData as any], asyncScheduler);
  }
  getConnectionMetrics(
    queryName: string,
    queryMethod: string,
    queries: { projectId: any; connectionId: any; interval?: any; start?: number; end?: number; step?: string },
  ): Observable<any> {
    return scheduled([connectionMetrics as any], asyncScheduler);
  }
}
