export const FEATURES = {
  CONNECTIONS: 'connections',
  ANALYZERS: 'analyzers',
  DEVICES: 'devices',
  THREATS: 'threats',
  WORKFLOWS: 'workflows',
  ASSESSMENTS: 'assessments',
  QUOTAS: 'quotas',
  REPORTS: 'reports',
};

export const CONNECTION_FEATURES = {
  ENABLED: 'connections.enabled',
  PHYSICAL: 'connections.physical',
  PCAP: 'connections.pcap',
  MQTT: 'connections.mqtt',
  MICRO_AGENT: 'connections.micro-agent',
  MICRO_IDS: 'connections.micro-ids',
};

export const ANALYZER_FEATURES = {
  ENABLED: 'analyzers.enabled',
  ANOMALY_ANALYZER: 'analyzers.anomaly-analyzer',
  ANOMALY_ANALYZER_ML: 'analyzers.anomaly-analyzer.ml',
  ANOMALY_ANALYZER_RULE_BASED: 'analyzers.anomaly-analyzer.rule-based',
  VULNERABILITY_ANALYZER: 'analyzers.vulnerability-analyzer',
  MALWARE_ANALYZER: 'analyzers.malware-analyzer',
};

export const DEVICE_FEATURES = {
  ENABLED: 'devices.enabled',
  PHYSICAL: 'devices.physical',
  NETWORK: 'devices.network',
};

export const THREAT_FEATURES = {
  ENABLED: 'threats.enabled',
};

export const WORKFLOW_FEATURES = {
  ENABLED: 'workflows.enabled',
  TIME_BASED: 'workflows.time-based',
  EVENT_BASED: 'workflows.event-based',
};

export const ASSESSMENT_FEATURES = {
  ENABLED: 'assessments.enabled',
};

export const QUOTAS_FEATURES = {
  ORGANIZATIONS: 'quotas.organizations',
  PROJECTS: 'quotas.projects',
  USERS: 'quotas.users',
  CONNECTIONS: 'quotas.connections',
  ANALYZERS: 'quotas.analyzers',
  ANOMALY_ANALYZERS: 'quotas.analyzers.anomaly-analyzer',
  ML_ANOMALY_ANALYZERS: 'quotas.analyzers.anomaly-analyzer.ml',
  RULE_BASED_ANOMALY_ANALYZERS: 'quotas.analyzers.anomaly-analyzer.rule-based',
  DEVICES: 'quotas.devices',
  PHYSICAL_DEVICES: 'quotas.devices.physical',
  NETWORK_DEVICES: 'quotas.devices.network',
  THREATS: 'quotas.threats',
  WORKFLOWS: 'quotas.workflows',
  ASSESSMENTS: 'quotas.assessments',
  REPORTS: 'quotas.reports',
};

export const REPORTS_FEATURES = {
  ENABLED: 'reports.enabled',
};
