import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { MenuItem } from 'primeng/api';
import { NmeCreationFormComponent } from './nme-creation-form/nme-creation-form.component';
import { NmeImportDevicesFormComponent } from './nme-import-devices-form/nme-import-devices-form.component';
import { Cell, GraphDataModel, ModelXmlSerializer } from '@maxgraph/core';
import {
  EDITOR_CREATION_TYPE_VALUES,
  EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL,
  EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN,
  EDITOR_OBJECT_TYPES,
} from '@ids-constants';
import { TargetDeviceService } from '@ids-services';
import { PIPE_DATETIME } from '@microsec/constants';
import { NetworkMapEditor } from '@ids-models';
import moment from 'moment';
import { Observable, asyncScheduler, catchError, finalize, firstValueFrom, forkJoin, map, scheduled } from 'rxjs';
import { ConfirmationDialogConfig } from '@microsec/models';
import { NetworkMapHelper } from '@ids-utilities';
import shortUuid from 'short-uuid';

const SUB_LOADINGS = {
  NEW_ZONES: 'new_zones',
  EXISTED_ZONES: 'existed_zones',
  NEW_DEVICES: 'new_devices',
  EXISTED_DEVICES: 'existed_devices',
};

@Component({
  selector: 'app-nme-top-navigation',
  templateUrl: './nme-top-navigation.component.html',
  styleUrls: ['./nme-top-navigation.component.scss'],
})
export class NmeTopNavigationComponent extends BaseComponent implements OnInit {
  @Input() editor: NetworkMapEditor | null = null;

  menuItems: MenuItem[] = [
    {
      label: 'Create New',
      command: () => {
        this.createNewDiagram();
      },
    },
    {
      label: 'Import Diagram',
      command: () => {
        const openFileButton = document.getElementById('open-xml');
        openFileButton?.click();
      },
    },
    {
      label: 'Export Diagram',
      command: () => {
        this.exportDiagram();
      },
    },
  ];

  toolbarButtons: any[] = [
    {
      key: 'view',
      icon: 'fa fa-table-cells-large',
      label: 'View',
      items: [
        { label: 'Shapes', command: () => {} },
        { label: 'Format', command: () => {} },
        { label: 'Ruler', command: () => {} },
        { separator: true },
        { label: 'Find/Replace', command: () => {} },
        { label: 'Layers', command: () => {} },
        { label: 'Tags', command: () => {} },
        { label: 'Outline', command: () => {} },
        { separator: true },
        { label: 'Fullscreen', command: () => {} },
      ],
      disabled: true,
    },
    { separator: true },
    {
      key: 'zoom',
      label: '100%',
      items: [
        { label: 'Fit Window / Reset View', command: () => {} },
        { separator: true },
        { label: '25%', command: () => {} },
        { label: '50%', command: () => {} },
        { label: '75%', command: () => {} },
        { label: '100%', command: () => {} },
        { label: '125%', command: () => {} },
        { label: '150%', command: () => {} },
        { label: '200%', command: () => {} },
        { label: '300%', command: () => {} },
        { label: '400%', command: () => {} },
        { separator: true },
        { label: 'Fit Window', command: () => {} },
        { label: 'Fit Page Width', command: () => {} },
        { label: 'Fit Page', command: () => {} },
        { label: 'Two Pages', command: () => {} },
        { separator: true },
        { label: 'Custom...', command: () => {} },
      ],
      disabled: true,
    },
    { separator: true },
    {
      key: 'zoom-in',
      icon: 'fa fa-magnifying-glass-plus',
      label: 'Zoom In',
      command: () => {
        this.editor?.zoom('in');
      },
    },
    {
      key: 'zoom-out',
      icon: 'fa fa-magnifying-glass-minus',
      label: 'Zoom Out',
      command: () => {
        this.editor?.zoom('out');
      },
    },
    { separator: true },
    {
      key: 'undo',
      icon: 'fa fa-arrow-rotate-left',
      label: 'Undo (Ctrl + Z)',
      command: () => {
        this.editor?.undo();
      },
    },
    {
      key: 'redo',
      icon: 'fa fa-arrow-rotate-right',
      label: 'Redo (Ctrl + Y or Ctrl + Shift + Z)',
      command: () => {
        this.editor?.redo();
      },
    },
    { separator: true },
    {
      key: 'delete',
      icon: 'fa fa-trash',
      label: 'Delete',
      command: () => {
        this.editor?.graph?.removeCells();
        this.editor?.updateImportedDevices();
        this.editor?.refreshGraph();
      },
    },
  ];

  PIPE_DATETIME = PIPE_DATETIME;

  constructor(private targetDeviceSrv: TargetDeviceService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
  }

  /**
   * Save the diagram
   * @param callback
   * @param isDeploying
   */
  async save(callback?: () => void, isDeploying = false) {
    const model = this.editor?.graph?.getDataModel() as GraphDataModel;
    const xmlSerializer = new ModelXmlSerializer(model);
    const exportedXml = xmlSerializer.export();
    const replacedUrlXml = exportedXml.replace(new RegExp(EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL, 'g'), EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN);
    const payload = {
      version: '0.0.1',
      xml_object: replacedUrlXml,
      organization_id: this.breadcrumbConfig?.organizationId,
      project_id: this.breadcrumbConfig?.projectId,
    };
    if (!!this.editor) {
      this.editor.isLoading = !!isDeploying ? true : this.editor.isLoading;
      const obs = new Observable((observer) => {
        this.targetDeviceSrv
          .updateNetworkMap(payload)
          .pipe(
            finalize(() => {
              this.editor!.isLoading = !!isDeploying ? true : this.editor!.isLoading;
              if (!!callback) {
                callback();
              }
              observer.next();
            }),
          )
          .subscribe({
            next: (rs: any) => {
              if (!!this.editor) {
                this.editor.lastSaved = moment.utc(rs?.modified).local();
                this.editor.isSaved = true;
              }
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          });
      });
      await firstValueFrom(obs);
    }
  }

  /**
   * Deploy and save the changes
   */
  deploy() {
    this.confirm({
      action: 'Deploy Changes',
      acceptLabel: 'Deploy',
      rejectLabel: 'Cancel',
      customContent:
        '<p>Deploying changes will save all modifications made on the network map and apply them to the rest of the MicroIDS system, updating associated devices, links, and zones.</p>' +
        '<p>Are you sure you want to deploy changes?</p>',
      next: () => {
        this.proceedDeployment();
      },
      rejectCallback: () => {},
    } as ConfirmationDialogConfig);
  }

  /**
   * Check if any zones/devices are removed
   */
  async getRemovedEntities() {
    const promise = new Promise<any>((resolve) => {
      const getZoneRequests: Observable<any>[] = [];
      const getDevicesRequests: Observable<any>[] = [];
      const cells: any = (this.editor?.graph?.getDataModel()?.cells as any) || {};
      Object.values(cells).forEach((cell: any) => {
        const data: any = cell?.value?.data;
        if (!!data) {
          const updateResultFunc = (request: Observable<any>) => {
            return request.pipe(
              map((entity) => {
                return entity?.project_id !== this.breadcrumbConfig?.projectId ? cell : null;
              }),
              catchError(() => scheduled([cell], asyncScheduler)),
            );
          };
          if (!!data?.zone_id) {
            getZoneRequests.push(updateResultFunc(this.targetDeviceSrv.getZone(data?.zone_id)));
          } else if (!!data?.device_id) {
            getDevicesRequests.push(updateResultFunc(this.targetDeviceSrv.getDevice(data?.device_id)));
          }
        }
      });
      if (!!getZoneRequests.length || !!getDevicesRequests.length) {
        forkJoin([
          !!getZoneRequests.length ? forkJoin(getZoneRequests) : scheduled([[]], asyncScheduler),
          !!getDevicesRequests.length ? forkJoin(getDevicesRequests) : scheduled([[]], asyncScheduler),
        ]).subscribe((results) => {
          const removedZones: Cell[] = results[0].filter((p) => !!p);
          const removedDevices: Cell[] = results[1].filter((p) => !!p);
          resolve({ zones: removedZones, devices: removedDevices });
        });
      } else {
        resolve({ zones: [], devices: [] });
      }
    });
    return promise;
  }

  /**
   * Proceed the deployment
   */
  async proceedDeployment() {
    this.editor!.isLoading = true;
    this.editor!.loadingMessage = '<p>Deploying changes to MicroIDS...</p>' + '<p>Please do not close MicroIDS while changes are being deployed.</p>';
    this.editor!.subLoadings = [
      { id: SUB_LOADINGS.NEW_ZONES, label: 'Creating new zones', isLoading: null, error: null },
      { id: SUB_LOADINGS.EXISTED_ZONES, label: 'Updating existing zones', isLoading: null, error: null },
      { id: SUB_LOADINGS.NEW_DEVICES, label: 'Creating new devices', isLoading: null, error: null },
      { id: SUB_LOADINGS.EXISTED_DEVICES, label: 'Updating existing devices', isLoading: null, error: null },
    ];
    const newZones: Cell[] = [];
    let existedZones: Cell[] = [];
    const newDevices: Cell[] = [];
    let existedDevices: Cell[] = [];
    const cells: any = (this.editor?.graph?.getDataModel()?.cells as any) || {};
    Object.values(cells).forEach((cell: any) => {
      const data: any = cell?.value?.data;
      if (!!data) {
        switch (cell?.value?.type) {
          case EDITOR_OBJECT_TYPES.ZONE: {
            if (!data?.zone_id) {
              newZones.push(cell);
            } else {
              existedZones.push(cell);
            }
            break;
          }
          case EDITOR_OBJECT_TYPES.DEVICE: {
            if (!data?.device_id) {
              newDevices.push(cell);
            } else {
              existedDevices.push(cell);
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    });
    try {
      // =============== FIRST SAVE ===============
      await this.save(undefined, true);
      // =============== UPDATE REMOVED ZONES / DEVICES ===============
      const removedEntities: { zones: Cell[]; devices: Cell[] } = await this.getRemovedEntities();
      removedEntities.zones.forEach((removedZone) => {
        newZones.push(removedZone);
        existedZones = existedZones.filter((p) => p !== removedZone);
      });
      removedEntities.devices.forEach((removedDevice) => {
        newDevices.push(removedDevice);
        existedDevices = existedDevices.filter((p) => p !== removedDevice);
      });
      // =============== ZONES ===============
      // Create new zones
      const newZonesStep = this.editor!.subLoadings.find((p) => p.id === SUB_LOADINGS.NEW_ZONES);
      newZonesStep!.isLoading = true;
      const createdNewZoneItems = await this.deployZones(newZones);
      newZonesStep!.error = false;
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      newZonesStep!.isLoading = false;

      // Update existed zones
      const existedZonesStep = this.editor!.subLoadings.find((p) => p.id === SUB_LOADINGS.EXISTED_ZONES);
      existedZonesStep!.isLoading = true;
      const updatedZoneItems = await this.deployZones(existedZones, true);
      existedZonesStep!.error = false;
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      existedZonesStep!.isLoading = false;
      // Update & save diagram
      await this.save(undefined, true);

      // =============== DEVICES ===============
      const zones: any[] = [...createdNewZoneItems, ...updatedZoneItems];
      // Create new devices
      const newDevicesStep = this.editor!.subLoadings.find((p) => p.id === SUB_LOADINGS.NEW_DEVICES);
      newDevicesStep!.isLoading = true;
      await this.deployDevices(newDevices, zones);
      newDevicesStep!.error = false;
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      newDevicesStep!.isLoading = false;

      // Update existed devices
      const existedDevicesStep = this.editor!.subLoadings.find((p) => p.id === SUB_LOADINGS.EXISTED_DEVICES);
      existedDevicesStep!.isLoading = true;
      await this.deployDevices(existedDevices, zones, true);
      existedDevicesStep!.error = false;
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      existedDevicesStep!.isLoading = false;

      // Update & save diagram
      await this.save(undefined, true);
      this.editor!.isLoading = false;
      this.editor!.subLoadings = [];
      this.editor?.updateImportedDevices();
      this.showSuccessMessage(`The deployment is successful.`);
    } catch {
      this.editor!.subLoadings.forEach((subLoading) => {
        if (subLoading.isLoading !== false) {
          subLoading.error = true;
        }
      });
      this.showErrorMessage(`The deployment is unsuccessful.`);
    }
  }

  /**
   * Create new diagram
   */
  createNewDiagram() {
    const creationDialog = this.dialogSrv.open(NmeCreationFormComponent, {
      header: 'Create Network Map',
      width: '800px',
      height: 'min-content',
      closable: false,
    });
    creationDialog.onClose.subscribe((result) => {
      if (!!result) {
        switch (result) {
          case EDITOR_CREATION_TYPE_VALUES.SCRATCH: {
            this.editor?.clearGraph();
            break;
          }
          case EDITOR_CREATION_TYPE_VALUES.DISCOVERED_NETWORK_ASSETS: {
            const importDeviceDialog = this.dialogSrv.open(NmeImportDevicesFormComponent, {
              header: 'Import Devices',
              width: '800px',
              height: 'min-content',
              closable: false,
            });
            importDeviceDialog.onClose.subscribe((result: any[]) => {
              if (!!this.editor && !!result) {
                this.editor.clearGraph();
                this.editor.showLoader(() => {
                  this.editor?.graph?.batchUpdate(() => {
                    this.editor!.importDevices(result);
                    this.editor!.zoom('fit');
                  });
                });
              }
            });
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  checkDisableButton(button: any) {
    let disabled = button?.disabled || false;
    if (!disabled) {
      if (button?.key === 'undo') {
        disabled = !this.editor?.undoManager?.canUndo();
      }
      if (button?.key === 'redo') {
        disabled = !this.editor?.undoManager?.canRedo();
      }
      if (button?.key === 'delete') {
        disabled = !this.editor?.graph?.getSelectionModel().cells.length;
      }
    }
    return disabled;
  }

  /**
   * =================================================== IMPORT / EXPORT ===================================================
   */

  /**
   * Import existing diagram
   */
  importDiagram(event: any) {
    if (!!event?.target?.files?.length) {
      const cleanInputtedFile = () => {
        const openFileButton = document.getElementById('open-xml') as HTMLInputElement;
        if (!!openFileButton) {
          openFileButton.value = '';
        }
      };
      const file = event.target.files[0] as File;
      if (file.type.toLowerCase().endsWith('xml')) {
        const reader = new FileReader();
        reader.onload = () => {
          const importedXml = (reader.result?.toString() as string) || '';
          this.editor?.importXML(importedXml);
          // Remove the file after import done
          cleanInputtedFile();
        };
        reader.readAsText(file);
      } else {
        cleanInputtedFile();
        this.showErrorMessage("The imported file's format must be XML");
      }
    }
  }

  /**
   * Export current diagram
   */
  exportDiagram() {
    this.editor?.exportXML();
  }

  /**
   * =================================================== DEPLOY ===================================================
   */

  /**
   * Deploy zones
   * @param cells
   * @param isUpdated
   */
  async deployZones(cells: Cell[], isUpdated = false) {
    const requests: Observable<any>[] = [];
    cells.forEach((cell) => {
      const data = cell?.value?.data;
      const compliance = JSON.parse(data?.compliance || 'null') || null;
      const payload = {
        ...(!isUpdated ? { organization_id: this.breadcrumbConfig?.organizationId, project_id: this.breadcrumbConfig?.projectId } : {}),
        label: data?.label || `${EDITOR_OBJECT_TYPES.ZONE}-${shortUuid.generate()}`,
        color: cell.style.fillColor,
        ...(!!compliance ? { compliance: compliance } : {}),
      };
      let request: Observable<any> = new Observable<any>();
      if (!isUpdated) {
        request = this.targetDeviceSrv.createZone(payload);
      } else {
        request = this.targetDeviceSrv.updateZone(data?.zone_id, payload);
      }
      request.pipe(
        catchError((err) => {
          window.microsec.error(err);
          return scheduled([null], asyncScheduler);
        }),
      );
      requests.push(request);
    });
    if (requests.length === 0) {
      return [];
    }
    const results = await firstValueFrom(forkJoin(requests));
    // Update cells
    results.forEach((zone, index) => {
      const zoneCell = cells[index] as Cell;
      zoneCell.setValue({ ...zoneCell.getValue(), label: zone?.label, data: { ...zoneCell.value.data, zone_id: zone?.id, label: zone?.label } });
    });
    if (!!this.editor) {
      this.editor.graph?.setSelectionCell(null);
      this.editor.graph?.refresh();
    }
    return results;
  }

  /**
   * Deploy devices
   * @param cells
   * @param zones
   * @param isUpdated
   */
  async deployDevices(cells: Cell[], zones: any[], isUpdated = false) {
    const requests: Observable<any>[] = [];
    cells.forEach((cell) => {
      const data = cell?.value?.data;
      const payload = {
        ...(!isUpdated
          ? { organization_id: this.breadcrumbConfig?.organizationId, project_id: this.breadcrumbConfig?.projectId, is_manually_added: true }
          : {}),
        label: data?.label || `${data?.device_type}-${shortUuid.generate()}`,
        type: data?.device_type,
        zones: this.getDeviceZones(cell, zones),
      };
      let request: Observable<any> = new Observable<any>();
      if (!isUpdated) {
        request = this.targetDeviceSrv.createDevice(payload);
      } else {
        request = this.targetDeviceSrv.updateDevice(data?.device_id, payload);
      }
      request.pipe(
        catchError((err) => {
          window.microsec.error(err);
          return scheduled([null], asyncScheduler);
        }),
      );
      requests.push(request);
    });
    if (requests.length === 0) {
      return [];
    }
    const results = await firstValueFrom(forkJoin(requests));
    // Update cells
    results.forEach((device, index) => {
      const deviceCell = cells[index] as Cell;
      deviceCell.setValue({
        ...deviceCell.getValue(),
        label: NetworkMapHelper.shortenDeviceName(device?.label),
        data: { ...deviceCell.value.data, device_id: device?.id, label: device?.label },
      });
    });
    if (!!this.editor) {
      this.editor.graph?.setSelectionCell(null);
      this.editor.graph?.refresh();
    }
    return results;
  }

  /**
   * Get device zones
   * @param cell
   * @param zones
   * @param previousResults
   */
  private getDeviceZones(cell: Cell, zones: any[], previousResults: any[] = []) {
    let results: any[] = !!previousResults.length ? previousResults : [];
    const parent = cell.parent;
    if (!!parent && parent.style.shape === 'swimlane') {
      const zoneId = parent.value?.data?.zone_id;
      const zone = zones.find((p) => p.id === zoneId);
      results.push({
        id: zone.id,
        label: zone.label,
        color: zone.color,
      });
      results = this.getDeviceZones(parent, zones, results);
    }
    return results;
  }
}
