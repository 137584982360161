export const EVENT_CATEGORY_OPTIONS = [
  { label: 'PKI Event', value: 'pki_event' },
  { label: 'System Event', value: 'system_event' },
  { label: 'Network Device Management', value: 'network_device_management' },
  { label: 'Firewall Management', value: 'firewall_management' },
  { label: 'VLAN Management', value: 'vlan_management' },
  { label: 'Device Management', value: 'device_management' },
  { label: 'Anomaly Detection', value: 'anomaly_detection' },
  { label: 'Alert Management', value: 'alert_management' },
  { label: 'Connection Manager', value: 'connection_management' },
  { label: 'Malware Detection', value: 'malware_detection' },
  { label: 'Vulnerability Detection', value: 'vulnerability_detection' },
  { label: 'Threat Detection', value: 'threat_detection' },
];

export const EVENT_CRITICALITIES: any[] = [
  // { value: null, label: '--- All Criticality ---' },
  { value: 10, label: 'Debug' },
  { value: 20, label: 'Info' },
  { value: 30, label: 'Warning' },
  { value: 40, label: 'Critical' },
  { value: 50, label: 'Fatal' },
];

export const EVENTS_PERIODS: any[] = [
  // {
  //   value: null,
  //   label: '--- All Timestamp ---',
  // },
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
  },
  {
    value: new Date().setDate(new Date().getDate() - 7),
    label: 'Last 7 Days',
  },
  {
    value: new Date().setDate(new Date().getDate() - 30),
    label: 'Last 30 days',
  },
  {
    value: new Date().setMonth(new Date().getMonth() - 6),
    label: 'Last 6 months',
  },
  {
    value: new Date().setMonth(new Date().getMonth() - 12),
    label: 'Last 12 months',
  },
  {
    value: 'CUSTOM',
    label: '--- Custom ---',
  },
];
