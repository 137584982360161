import { NetworkMapEditor } from './network-map-editor';

export const NMEZoom = {
  /**
   * Zoom
   * @param mode
   */
  zoom(this: NetworkMapEditor, mode: any) {
    switch (mode) {
      case 'in': {
        this.graph?.zoomIn();
        break;
      }
      case 'out': {
        this.graph?.zoomOut();
        break;
      }
      case 'fit': {
        this.graph?.fit();
        break;
      }
      default: {
        break;
      }
    }
  },
};
