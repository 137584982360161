import { Util } from '@microsec/utilities';
import { NetworkMapEditor } from './network-map-editor';
import {
  EDITOR_DEVICE_TYPE_DEFAULT_GAP,
  EDITOR_DEVICE_TYPE_SIZE,
  EDITOR_DIAGRAM_CONFIG_KEYS,
  EDITOR_OBJECT_TYPES,
  EDITOR_ZONE_DARK_LEVEL,
  SECURITY_LEVEL_OPTIONS,
} from '@ids-constants';
import { Cell } from '@maxgraph/core';
import { Guid } from 'guid-typescript';
import { NetworkMapHelper } from '@ids-utilities';
import { Observable, finalize } from 'rxjs';

export const NMEImport = {
  /**
   * Import devices into graph
   * @param result
   */
  importDevices(this: NetworkMapEditor, result: any) {
    const devices = result?.devices as any[];
    const usedLinks = NMEImport.getUsedLinks(result);
    NMEImport.createZones.call(this, devices, usedLinks);
    // Import all devices without zone
    const graphBoundary = this.graph?.getBoundingBoxFromGeometry(Object.values(this.graph.model.cells || {}), false);
    NMEImport.importDevicesWithZone.call(
      this,
      devices.filter((p) => !p.zones?.length),
      null,
      usedLinks,
      EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2,
      (graphBoundary?.y as number) + (graphBoundary?.height as number) + EDITOR_DEVICE_TYPE_DEFAULT_GAP,
    );
    NMEImport.connectDevices.call(this, usedLinks);
    // Reset view
    this.zoom('fit');
    this.refreshGraph();
  },

  /**
   * Create zone
   * @param this
   * @param devices
   * @param links
   * @returns
   */
  createZones(this: NetworkMapEditor, devices: any[], links: any[]) {
    // Get all new zones
    let rawZones = Util.getUniqueObjectArray(
      devices.reduce((allZones: any[], device: any) => {
        allZones.push(...((device?.zones as any[]) || []));
        return allZones;
      }, []),
      'zone_id',
    );
    // Combine new zones with existing zones
    const existingZones = Object.entries(this.graph?.model.cells || {})
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, _cell]) => !!key.startsWith(`${EDITOR_OBJECT_TYPES.ZONE}-`))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_key, cell]) => cell.value?.data);
    rawZones = [...rawZones, ...existingZones].filter((zone, index, self) => self.findIndex((p) => p.zone_id === zone.zone_id) === index);
    // Get devices of all zones
    rawZones = Util.sortObjectArray(
      rawZones.map((zone) => {
        zone.devices = devices.filter((p) => (p.zones as any[]).map((p) => p.zone_id).includes(zone.zone_id));
        zone.device_length = zone.devices?.length || 0;
        return zone;
      }),
      'device_length',
      false,
    );
    // Get zone tree
    const zonesTree: any[] = NMEImport.buildZonesTree(rawZones.map((zone) => ({ ...zone, children: [] })));
    // Get boundary of of graph
    const initialBoundary = this.graph?.getBoundingBoxFromGeometry(Object.values(this.graph.model.cells || {}), false);
    const initialX =
      (!!initialBoundary?.width && !!initialBoundary.height
        ? (initialBoundary?.x as number) + (initialBoundary?.width as number) + EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2
        : 0) +
      EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2;
    const initialY = EDITOR_DEVICE_TYPE_DEFAULT_GAP;
    const zoneCoordinate = {
      originX: initialX,
      originY: initialY,
      x: initialX,
      y: initialY,
      maxX: initialX,
      maxY: initialY,
    };
    // Create zones
    const zones: Cell[] = [];
    zonesTree.forEach((zoneItem) => {
      const zone = NMEImport.drawZone.call(this, null, zoneCoordinate, zoneItem, links) as Cell;
      if (!!zone) {
        zones.push(zone);
        if (!!zone.geometry) {
          zoneCoordinate.maxX = Math.max(zoneCoordinate.maxX, zone.geometry.x + zone.geometry.width);
          zoneCoordinate.maxY = Math.max(zoneCoordinate.maxY, zone.geometry.y + zone.geometry.height);
        }
        zoneCoordinate.x = zoneCoordinate.maxX + EDITOR_DEVICE_TYPE_DEFAULT_GAP;
        zoneCoordinate.y = zoneCoordinate.originY;
      }
    });
    return zones;
  },

  /**
   * Draw zone
   * @param this
   * @param parentZone
   * @param zoneCoordinate
   * @param zoneItem
   * @param links
   * @returns
   */
  drawZone(
    this: NetworkMapEditor,
    parentZone: Cell | null,
    zoneCoordinate: {
      originX: any;
      originY: any;
      x: any;
      y: any;
      maxX: any;
      maxY: any;
    },
    zoneItem: any,
    links: any[],
  ) {
    // If the there are some existing zones, exclude them from the creation
    const existingZones = Object.entries(this.graph?.model.cells || {})
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, _cell]) => !!key.startsWith(`${EDITOR_OBJECT_TYPES.ZONE}-`))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_key, cell]) => cell.value?.data.zone_id);

    let zone: Cell | null = null;
    let isZoneExisted = false;
    if (!existingZones.includes(zoneItem.zone_id)) {
      zone = NMEImport.createZone.call(this, parentZone, zoneItem, zoneCoordinate.x, zoneCoordinate.y) || null;
      // -- Minimize the zone's size
      if (!!zone?.geometry) {
        zone.geometry.width = 0;
        zone.geometry.height = 0;
      }
    } else {
      isZoneExisted = true;
      zone =
        Object.entries(this.graph?.model.cells || {})
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .find(([key, _cell]) => !!key.startsWith(`${EDITOR_OBJECT_TYPES.ZONE}-${zoneItem.zone_id}-`))?.[1] || null;
    }
    if (!!zone) {
      const initialChildX = !!isZoneExisted ? (zone.geometry?.width as number) : EDITOR_DEVICE_TYPE_DEFAULT_GAP;
      const childZoneCoordinate = {
        originX: initialChildX,
        originY: EDITOR_DEVICE_TYPE_DEFAULT_GAP,
        x: initialChildX,
        y: EDITOR_DEVICE_TYPE_DEFAULT_GAP,
        maxX: initialChildX,
        maxY: EDITOR_DEVICE_TYPE_DEFAULT_GAP,
      };
      const childrenZones: Cell[] = [];
      // -- Import children zones
      if (!!((zoneItem.children as any[]) || []).length) {
        ((zoneItem.children as any[]) || []).forEach((childZoneItem) => {
          const childZone: Cell | null = NMEImport.drawZone.call(this, zone, childZoneCoordinate, childZoneItem, links) || null;
          if (!!childZone) {
            childrenZones.push(childZone);
            if (!!childZone.geometry) {
              childZoneCoordinate.maxX = Math.max(childZoneCoordinate.maxX, childZone.geometry.x + childZone.geometry.width);
              childZoneCoordinate.maxY = Math.max(childZoneCoordinate.maxY, childZone.geometry.y + childZone.geometry.height);
            }
            childZoneCoordinate.x = childZoneCoordinate.maxX + EDITOR_DEVICE_TYPE_DEFAULT_GAP;
            childZoneCoordinate.y = childZoneCoordinate.originY;
          }
        });
        // Hack: Fix not correct y alignment, something wrong or the resize make the zone is not in correct position
        childrenZones.forEach((childZone) => {
          if (!!childZone.geometry) {
            childZone.geometry.y = childZoneCoordinate.originY;
          }
        });
        zoneCoordinate.x = zoneCoordinate.originX;
        zoneCoordinate.y = childZoneCoordinate.maxY + EDITOR_DEVICE_TYPE_DEFAULT_GAP;
      }
      // -- Import related devices
      NMEImport.importDevicesWithZone.call(
        this,
        zoneItem.devices,
        zone,
        links,
        (!!isZoneExisted ? (this.graph?.getBoundingBoxFromGeometry(zone.children, false)?.width as number) + EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2 : 0) +
          EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2,
        !!isZoneExisted
          ? childZoneCoordinate.originY
          : (zone.geometry?.y as number) +
              (zone.geometry?.height as number) +
              (!((zoneItem.children as any[]) || []).length ? 0 : EDITOR_DEVICE_TYPE_DEFAULT_GAP),
      );
      // Hack: Fix not correct x alignment, something wrong or the resize make the zone is not in correct position
      if (!childrenZones?.[0]?.geometry?.x) {
        childrenZones.forEach((childZone) => {
          if (!!childZone.geometry) {
            childZone.geometry.x += EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2;
          }
        });
      }
      // -- Calculate the coordinates of devices
      if (!!zone?.geometry) {
        zone.geometry.width =
          Math.max(...zone.children.map((p) => (p.geometry?.x as number) + (p.geometry?.width as number))) + EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2;
        zone.geometry.height =
          Math.max(...zone.children.map((p) => (p.geometry?.y as number) + (p.geometry?.height as number))) + EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2;
        zoneCoordinate.maxX = zone.geometry.x + zone.geometry.width;
        zoneCoordinate.maxY = zone.geometry.y + zone.geometry.height;
      }
      return zone;
    }
    return null;
  },

  /**
   * Build zones tree
   * @param zones
   * @returns
   */
  buildZonesTree(zones: any[]) {
    const zonesTree: any[] = [];
    zones.forEach((checkedZone) => {
      let foundZone: any = NMEImport.findZoneInTree(checkedZone, zonesTree);
      if (!foundZone) {
        zonesTree.push(checkedZone);
        foundZone = checkedZone;
      }
      // Get unchecked zones
      const uncheckedZones = zones.filter((p) => p.zone_id !== foundZone?.zone_id);
      // Get related children
      foundZone.children = uncheckedZones.filter((uncheckedZone) => {
        const zoneDeviceIds = (foundZone.devices as any[]).map((p) => p.id);
        const uncheckedZoneDeviceIds = (uncheckedZone.devices as any[]).map((p) => p.id);
        const condition = !!uncheckedZoneDeviceIds.length && !!uncheckedZoneDeviceIds.every((p) => zoneDeviceIds.includes(p));
        if (!!condition) {
          foundZone.devices = (foundZone.devices as any[]).filter((p) => !uncheckedZoneDeviceIds.includes(p.id));
        }
        return condition;
      });
    });
    return zonesTree;
  },

  /**
   * Find zone in zones tree
   * @param zone
   * @param tree
   * @returns
   */
  findZoneInTree(zone: any, tree: any[]) {
    let result: any = null;
    tree.forEach((treeItem) => {
      if (treeItem.zone_id === zone.zone_id) {
        result = treeItem;
      } else if (!!treeItem?.children?.length) {
        result = result || NMEImport.findZoneInTree(zone, treeItem?.children || []);
      }
    });
    return result;
  },

  /**
   * Connect devices by links
   * @param this
   * @param usedLinks
   */
  connectDevices(this: NetworkMapEditor, usedLinks: { src_device_id: any; dest_device_id: any }[]) {
    const cells = this.graph?.model.cells;
    usedLinks.forEach((usedLink) => {
      const sourceDeviceCell =
        cells?.[Object.keys(cells).find((k) => k.startsWith(`${EDITOR_OBJECT_TYPES.DEVICE}-${usedLink.src_device_id}-`)) || ''];
      const destDeviceCell = cells?.[Object.keys(cells).find((k) => k.startsWith(`${EDITOR_OBJECT_TYPES.DEVICE}-${usedLink.dest_device_id}-`)) || ''];
      if (
        !!sourceDeviceCell &&
        !!destDeviceCell &&
        !cells?.[
          Object.keys(cells).find((k) => k.startsWith(`${EDITOR_OBJECT_TYPES.LINE}-${usedLink.src_device_id}-${usedLink.dest_device_id}-`)) || ''
        ] &&
        !cells?.[
          Object.keys(cells).find((k) => k.startsWith(`${EDITOR_OBJECT_TYPES.LINE}-${usedLink.dest_device_id}-${usedLink.src_device_id}-`)) || ''
        ]
      ) {
        this.graph?.insertEdge({
          id: `${EDITOR_OBJECT_TYPES.LINE}-${usedLink.src_device_id}-${usedLink.dest_device_id}-${Util.generateRandomNumberString()}`,
          source: sourceDeviceCell,
          target: destDeviceCell,
        });
      }
    });
  },

  /**
   * Get used links
   * @param rawData
   */
  getUsedLinks(rawData: { connections: any[]; links: any; devices: any[] }) {
    const { connections, links, devices } = rawData;
    // Get all devices existed in connection list
    const devicesWithAllExistingConnections: any[] = !!connections?.length
      ? devices.filter((device) => {
          return ((device.connection_ids as any[]) || []).every((connectionId) => {
            return connections?.map((c) => c?.id)?.includes(connectionId);
          });
        })
      : devices;
    const checkedDeviceIds = devicesWithAllExistingConnections.map((p) => p.id);
    // Get filtered links
    let filteredLinks: any[] = [];
    const linkObj: any = Util.cloneDeepObject(links || {});
    Object.entries(linkObj).forEach(([key, linkItems]: [string, any]) => {
      if (key !== 'default') {
        filteredLinks.push(
          ...((linkItems as any[]) || []).filter(
            (p) => !!checkedDeviceIds.includes(p.src_device_id) || !!checkedDeviceIds.includes(p.dest_device_id),
          ),
        );
      }
    });
    filteredLinks = filteredLinks.filter(
      (p, index, self) =>
        self.findIndex(
          (s) =>
            (s.src_device_id === p.src_device_id && s.dest_device_id === p.dest_device_id) ||
            (s.src_device_id === p.dest_device_id && s.dest_device_id === p.src_device_id),
        ) === index,
    );
    return filteredLinks;
  },

  /**
   * Import devices with zone
   * @param this
   * @param devices
   * @param zone
   * @param links
   * @param latestX
   * @param latestY
   * @returns
   */
  importDevicesWithZone(this: NetworkMapEditor, devices: any[], zone: Cell | null = null, links: any[], latestX: any = null, latestY: any = null) {
    const { devicesWithLinks, devicesWithoutLinks } = NMEImport.classifyDeviceGroupsByLinks(devices, links);
    const deviceByLevels = NMEImport.classifyDeviceGroupsByLevels(devicesWithLinks, devicesWithoutLinks);
    let x = !latestX ? EDITOR_DEVICE_TYPE_DEFAULT_GAP : latestX;
    let y = !latestY ? EDITOR_DEVICE_TYPE_DEFAULT_GAP : latestY;
    const deviceCells: Cell[] = [];
    let maxX = x;
    const allLevels = [-1, ...SECURITY_LEVEL_OPTIONS.map((p) => p.value)].filter(
      (level) =>
        !!(deviceByLevels[level].withLinks as any[]).filter((deviceGroup: any[]) => !!deviceGroup.length).length ||
        !!(deviceByLevels[level].noLinks as any[]).length,
    );
    for (let groupIndex = 0; groupIndex < (devicesWithLinks.length || 1); groupIndex++) {
      const groupX = x;
      for (let index = allLevels.length - 1; index >= 0; index--) {
        const levelIndex = allLevels[index];
        const currentLevel = deviceByLevels[levelIndex];
        let isDeviceWithLinksAdded = false;
        (currentLevel.withLinks[groupIndex] as any[])?.forEach((device) => {
          isDeviceWithLinksAdded = true;
          deviceCells.push(NMEImport.createDeviceNode.call(this, device, x, y, zone));
          x += EDITOR_DEVICE_TYPE_DEFAULT_GAP;
          maxX = Math.max(maxX, x);
        });
        if (!!isDeviceWithLinksAdded) {
          x = groupX;
          y += EDITOR_DEVICE_TYPE_DEFAULT_GAP;
        }
        (currentLevel.noLinks as any[])?.forEach((device) => {
          deviceCells.push(NMEImport.createDeviceNode.call(this, device, x, y, zone));
          x += EDITOR_DEVICE_TYPE_DEFAULT_GAP;
        });
        // After a row is draw, go to next line
        x = groupX;
        y += EDITOR_DEVICE_TYPE_DEFAULT_GAP * 2;
      }
      x = maxX + EDITOR_DEVICE_TYPE_DEFAULT_GAP;
      y = !latestY ? EDITOR_DEVICE_TYPE_DEFAULT_GAP : latestY;
    }
    return deviceCells;
  },

  /**
   * Classify device groups by links
   * @param devices
   * @param links
   * @returns
   */
  classifyDeviceGroupsByLinks(devices: any[], links: any[]) {
    const devicesWithLinks = devices.filter(
      (d) =>
        !!links.find(
          (l) =>
            (l.src_device_id === d.id || l.dest_device_id === d.id) &&
            !!devices.find((p) => p.id === l.src_device_id) &&
            !!devices.find((p) => p.id === l.dest_device_id),
        ),
    );
    const devicesWithoutLinks = devices.filter((d) => !devicesWithLinks.find((p) => p.id === d.id));

    let sortedDevicesWithLinks: any[] = [];
    // Handle devices with links
    if (!!devicesWithLinks?.length) {
      // Split devices into related groups
      const groups: any = {};
      devicesWithLinks.forEach((device) => {
        let foundGroup: any[] | null = null;
        // Check if device existed in any groups
        Object.entries(groups).forEach(([groupName, deviceList]: [string, any]) => {
          if (!!((deviceList as any[]) || []).find((p) => p.id === device.id)) {
            foundGroup = groups[groupName];
          }
        });
        // Init group if not existed
        if (!foundGroup) {
          const groupName = Guid.create().toString();
          groups[groupName] = [];
          foundGroup = groups[groupName];
        }
        // Push current device and its related devices
        const relatedDeviceIds = [device.id, ...NMEImport.getRelatedDeviceIds(links, device.id)];
        const relatedDevices = devicesWithLinks.filter((d) => relatedDeviceIds.includes(d.id)) || [];
        foundGroup?.push(...Util.getUniqueObjectArray(relatedDevices, 'id'));
      });
      // Get unique values of each group
      Object.entries(groups).forEach(([key, devices]: [string, any]) => {
        groups[key] = (devices as any[]).filter((device, index, array) => array.findIndex((p) => p.id === device.id) === index);
      });
      // Sort groups into array
      sortedDevicesWithLinks = Object.values(groups).sort((a: any, b: any) => -((a as any[]).length - (b as any[]).length));
    }
    return { devicesWithLinks: sortedDevicesWithLinks, devicesWithoutLinks };
  },

  /**
   * Classify device groups by levels
   * @param devicesWithLinks
   * @param devicesWithoutLinks
   * @returns
   */
  classifyDeviceGroupsByLevels(devicesWithLinks: any[][], devicesWithoutLinks: any[]) {
    const allLevels = [-1, ...SECURITY_LEVEL_OPTIONS.map((p) => p.value)];
    const devicesByLevels: any = allLevels.reduce((obj: any, level: number) => {
      obj[level] = {
        withLinks: devicesWithLinks.map((deviceGroup) => deviceGroup.filter((p) => p.network_map_level === level)),
        noLinks: devicesWithoutLinks.filter((p) => p.network_map_level === level),
      };
      return obj;
    }, {});
    return devicesByLevels;
  },

  /**
   * Get related device IDs
   * @param links
   * @param deviceId
   * @param result
   * @returns
   */
  getRelatedDeviceIds(links: any[], deviceId: any, result: any[] = []) {
    const fromSrc: any[] = links
      .filter((l) => l.src_device_id === deviceId)
      .filter((l) => !result.includes(l.dest_device_id))
      .map((l) => l.dest_device_id);
    const fromDest: any[] = links
      .filter((l) => l.dest_device_id === deviceId)
      .filter((l) => !result.includes(l.src_device_id))
      .map((l) => l.src_device_id);
    result.push(...fromSrc);
    result.push(...fromDest);
    fromSrc.forEach((id) => {
      NMEImport.getRelatedDeviceIds(links, id, result);
    });
    fromDest.forEach((id) => {
      NMEImport.getRelatedDeviceIds(links, id, result);
    });
    return result;
  },

  /**
   * Create device node
   * @param this
   * @param device
   * @param x
   * @param y
   * @param zone
   * @param isNew
   * @returns
   */
  createDeviceNode(this: NetworkMapEditor, device: any, x: any, y: any, zone: Cell | null = null, isNew = false): Cell {
    if (!this.importedDevices().includes(device?.id) || !!isNew) {
      if (!!device?.id) {
        this.addImportedDeviceId(device?.id);
      }
      const id = `${EDITOR_OBJECT_TYPES.DEVICE}-${device?.id}-${Util.generateRandomNumberString()}`;
      const cell = this.graph?.insertVertex({
        id,
        parent: !zone ? this.layers.device : zone,
        value: {
          label: !!this.config?.find((p) => p.key === EDITOR_DIAGRAM_CONFIG_KEYS.DEVICE_NAME)?.value
            ? NetworkMapHelper.shortenDeviceName(device?.label)
            : '',
          type: EDITOR_OBJECT_TYPES.DEVICE,
          data: {
            device_id: device?.id,
            label: device?.label,
            device_type: device?.type || 'others',
            network_map_level: device?.network_map_level || 0,
          },
        },
        x,
        y,
        height: EDITOR_DEVICE_TYPE_SIZE * 1.5,
        width: EDITOR_DEVICE_TYPE_SIZE * 1.5,
        style: {
          shape: 'image',
          verticalAlign: 'top',
          resizable: false,
          // image
          imageAlign: 'center',
          image: `/${NetworkMapHelper.getNetworkMapEditorIconUrl(device?.type || 'others')}`,
          imageWidth: EDITOR_DEVICE_TYPE_SIZE,
          imageHeight: EDITOR_DEVICE_TYPE_SIZE,
          // label
          fontColor: '#ffffff',
          fontSize: 15,
          verticalLabelPosition: 'bottom',
        },
      }) as Cell;
      return cell;
    }
    return new Cell();
  },

  /**
   * Create zone
   * @param this
   * @param parentZone
   * @param zone
   * @param x
   * @param y
   * @returns
   */
  createZone(this: NetworkMapEditor, parentZone: Cell | null, zone: any, x: any, y: any) {
    const existingZones = Object.entries(this.graph?.model.cells || {})
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, _cell]) => !!key.startsWith(`${EDITOR_OBJECT_TYPES.ZONE}-`))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_key, cell]) => cell.value?.data);
    if (!existingZones.find((p) => p?.zone_id === zone?.zone_id)) {
      const id = `${EDITOR_OBJECT_TYPES.ZONE}-${zone?.zone_id}-${Util.generateRandomNumberString()}`;
      const cell = this.graph?.insertVertex({
        id,
        parent: !parentZone ? this.layers.zone : parentZone,
        value: {
          label: zone?.label,
          type: EDITOR_OBJECT_TYPES.ZONE,
          data: {
            zone_id: zone.zone_id,
            label: zone?.label,
            color: zone?.color,
            compliance: !!zone?.compliance ? JSON.stringify(zone?.compliance) : null,
          },
        },
        x,
        y,
        height: EDITOR_DEVICE_TYPE_SIZE * 5,
        width: EDITOR_DEVICE_TYPE_SIZE * 5,
        style: {
          shape: 'swimlane',
          whiteSpace: 'wrap',
          resizable: true,
          fontColor: '#ffffff',
          fontSize: 15,
          swimlaneFillColor: NetworkMapHelper.getDarkerColor(zone?.color, EDITOR_ZONE_DARK_LEVEL),
          swimlaneLine: true,
          strokeColor: zone?.color,
          fillColor: zone?.color,
        },
      });
      return cell;
    }
    return null;
  },
  /**
   * Reset graph layout
   * @param this
   */
  resetGraphLayout(this: NetworkMapEditor, requests: Observable<any>) {
    const allCells = this.graph?.model.cells || {};
    const newDeviceCells = Object.entries(allCells || {})
      // Need to check carefully because the id not always work as expected
      .filter(([k, v]) => k.startsWith(`${EDITOR_OBJECT_TYPES.DEVICE}-null-`) || (!v.value?.data?.device_id && !!v.value?.data?.device_type))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_k, v]) => v);
    this.isLoading = true;
    requests.pipe(finalize(() => (this.isLoading = false))).subscribe({
      next: (result) => {
        this.clearGraph();
        NMEImport.importDevices.call(this, result);
        // Add new devices
        const newGraphRect = this.graph?.getBoundingBoxFromGeometry(
          [...(this.layers.device?.children || []), ...(this.layers.zone?.children || [])],
          false,
        );
        newDeviceCells.forEach((cell, index) => {
          NMEImport.createDeviceNode.call(
            this,
            {
              id: null,
              label: cell.value.data?.label,
              type: cell.value.data?.device_type,
              network_map_level: cell.value.data?.network_map_level,
              zones: [],
            },
            EDITOR_DEVICE_TYPE_DEFAULT_GAP / 2 + EDITOR_DEVICE_TYPE_DEFAULT_GAP * index,
            (newGraphRect?.y as number) + (newGraphRect?.height as number) + EDITOR_DEVICE_TYPE_DEFAULT_GAP,
            null,
            true,
          );
        });
        // Zoom fit
        this.zoom('fit');
      },
    });
  },
};
