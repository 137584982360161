@if (!!visible) {
  <div class="mx-5 my-2">
    <microsec-loading-overlay [isLoading]="!!isLoading">
      <microsec-card [isBordered]="true">
        <div class="grid">
          <div class="col-12">
            <div class="flex align-items-center gap-3">
              <i class="fa fa-suitcase text-5xl"></i>
              <div class="flex flex-column gap-1">
                <label class="text-lg font-bold">Organization {{ organization?.name || '' }}</label>
                <div class="flex flex-wrap gap-2">
                  <label class="text-grey">Organization ID: {{ organization?.id || '' }}</label>
                  @if (!!organization?.address) {
                    <span class="text-grey">•</span>
                    <label class="text-grey">Address: {{ organization.address }}</label>
                  }
                  @if (!!organization?.telephone_number) {
                    <span class="text-grey">•</span>
                    <label class="text-grey">Phone Number: {{ organization.telephone_number }}</label>
                  }
                </div>
              </div>
            </div>
          </div>
          @if (permissions[SCOPE.ORGANIZATION][USER_ROLE.ADMIN]) {
            <div class="col-12">
              <label class="link-blue font-bold" (click)="openOrganizationForm()">Edit Organization Details</label>
            </div>
          }
        </div>
      </microsec-card>
    </microsec-loading-overlay>
  </div>
}
