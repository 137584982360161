<div class="grid form">
  <div class="col-12">
    <app-ml-analyzer-progress [analyzer]="analyzer" [connectionName]="connection?.name"></app-ml-analyzer-progress>
  </div>
  <div class="col-12">
    <microsec-card [isNestedCard]="true">
      <div class="mb-3">
        <label class="font-bold">Analyzer Details</label>
      </div>
      <div class="list-fields mx-3">
        <div class="grid align-items-center">
          <label class="col-4">Enabled</label>
          <div class="col-8">
            <p-inputSwitch [(ngModel)]="analyzer.enabled" [disabled]="true"></p-inputSwitch>
          </div>
        </div>
        @if (!!analyzer?.mode) {
          <div class="grid align-items-center">
            <label class="col-4">Mode</label>
            <label class="col-8 text-data">{{ analyzer.mode | titlecase }}</label>
          </div>
        }
        @if (!!analyzer?.name) {
          <div class="grid align-items-center">
            <label class="col-4">Name</label>
            <label class="col-8 text-data overflow-hidden white-space-nowrap text-overflow-ellipsis">{{ analyzer?.name }}</label>
          </div>
        }
        @if (!!analyzer?.description) {
          <div class="grid align-items-center">
            <label class="col-4">Description</label>
            <label class="col-8 text-data">{{ analyzer.description }}</label>
          </div>
        }
        @if (!!analyzer?.connection_ids?.length) {
          <div class="grid align-items-center">
            <label class="col-4">Connection ID</label>
            <label class="col-8 text-data">{{ analyzer.connection_ids.join(', ') }}</label>
          </div>
        }
        @if (!!connection?.name) {
          <div class="grid align-items-center">
            <label class="col-4">Connection Name</label>
            <label class="col-8 text-data">{{ connection?.name }}</label>
          </div>
        }
        @if (!!analyzer?.protocol) {
          <div class="grid align-items-center">
            <label class="col-4">Protocol(s)</label>
            <label class="col-8 text-data">{{ analyzer.protocol | constant: 'anomaly-analyzer-protocol' : true }}</label>
          </div>
        }
        @if (!!analyzer?.data_structure_id) {
          <div class="grid align-items-center">
            <label class="col-4">Data Structure ID</label>
            <label class="col-8 text-data">{{ analyzer.data_structure_id }}</label>
          </div>
        }
        <div class="grid align-items-center">
          <label class="col-4">Number of Training Packets</label>
          <label class="col-8 text-data">{{
            analyzer?.prediction_packet_accumulation_threshold ? analyzer.prediction_packet_accumulation_threshold : 0
          }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Prediction Packets Per Batch</label>
          <label class="col-8 text-data">{{ analyzer?.prediction_threshold ? analyzer.prediction_threshold : 0 }}</label>
        </div>
        <div class="grid align-items-center">
          <label class="col-4">Threshold Sensitivity</label>
          <label class="col-8 text-data">{{ analyzer?.anomaly_sensitivity_threshold ? analyzer.anomaly_sensitivity_threshold : 0 }}</label>
        </div>
      </div>
    </microsec-card>
  </div>
</div>
