import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ThreatService } from '@ids-services';

import { finalize } from 'rxjs';

@Component({
  selector: 'app-threat-details',
  templateUrl: './threat-details.component.html',
  styleUrls: ['./threat-details.component.scss'],
})
export class ThreatDetailsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _threatId = null;

  get threatId() {
    return this._threatId;
  }

  @Input() set threatId(value: any) {
    this._threatId = value;
    if (!!this.threatId) {
      this.getThreat();
    }
  }

  threat: any = {};

  @Output() hidePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private threatSrv: ThreatService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.threatSrv.refreshObs.subscribe((rs) => {
      if (!!rs && !!this.threatId) {
        this.getThreat();
      }
    });
    this.subscriptions.push(subscription);
  }

  getThreat() {
    this.isLoading = true;
    this.threatSrv
      .getThreat(this.threatId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.threat = res;
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.hidePanelEvent?.emit();
        },
      });
  }
}
