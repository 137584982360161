import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { REPORTS_FEATURES } from '@ids-constants';
import { ILayoutItem } from '@microsec/interfaces';

import { fromLayoutActions } from '@microsec/ngrx-layout';
import { REPORT_CHILDREN } from './report.config';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkReportFeatureEnabled(REPORTS_FEATURES.ENABLED)) {
      const tabItems: ILayoutItem[] = this.util.cloneObjectArray(REPORT_CHILDREN).filter((p) => !!p.label && p.visible !== false);
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
