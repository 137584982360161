<div class="flex flex-column align-items-center justify-content-center">
  <div class="w-full" style="max-width: 850px">
    <microsec-form-builder #fb [isPage]="true" [fields]="fields" [hasInfoFields]="false" [shouldShowActionButtons]="false" />
  </div>
</div>

@if (step <= 2 && !!form && !!form.form) {
  <div class="custom-layout-action-buttons">
    <div class="w-full" style="max-width: 1500px">
      <p-divider></p-divider>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2 mt-5">
        <div>
          @if ((type === CONNECTION_TYPES.MQTT_AGENT && step > 1) || type !== CONNECTION_TYPES.MQTT_AGENT) {
            <p-button
              [severity]="
                !form.getControlValue(FORM_PARAMS.STATUS)
                  ? 'secondary'
                  : form.getControlValue(FORM_PARAMS.STATUS) === STATUSES.CONNECTED
                    ? 'success'
                    : 'danger'
              "
              [styleClass]="!form.getControlValue(FORM_PARAMS.STATUS) ? '' : 'custom-button'"
              type="button"
              (onClick)="testConnection()"
              icon="fa fa-sync"
              [label]="!form.getControlValue(FORM_PARAMS.STATUS) ? 'Test Connection' : form.getControlValue(FORM_PARAMS.STATUS)"
              [disabled]="
                !!form.isLoading ||
                !form.form.valid ||
                (!!form.form.valid && type === CONNECTION_TYPES.PCAP && !form.getControlValue(FORM_PARAMS.PCAP_FILE_PATH))
              ">
            </p-button>
          }
        </div>
        <div>
          @if (type === CONNECTION_TYPES.MQTT_AGENT && step === 1) {
            <p-checkbox
              [(ngModel)]="skipSetupAgent"
              [binary]="true"
              label="I already have MicroAgents installed in my devices and do not require any additional downloads."
              (onChange)="onChangeSkipSetupAgent($event.checked)"></p-checkbox>
          }
        </div>
        <div class="flex align-items-center gap-2">
          @if ((!connection && step >= 1) || (!!connection && step >= 2)) {
            <p-button
              type="button"
              severity="primary"
              label="Back"
              icon="fa fa-angle-left"
              iconPos="left"
              [disabled]="!!form.isLoading"
              (onClick)="
                onStepChange((isContinuousScan && step === 1) || (type === CONNECTION_TYPES.MQTT_AGENT && step > 1 && step <= 2) ? -0.5 : -1)
              ">
            </p-button>
          }
          @if (step < 2) {
            <p-button
              type="button"
              severity="primary"
              label="Next"
              icon="fa fa-angle-right"
              iconPos="right"
              [disabled]="!!form.isLoading || !isCurrentStepValid"
              (onClick)="onStepChange(type === CONNECTION_TYPES.MQTT_AGENT && step < 2 ? 0.5 : 1)">
            </p-button>
          }
          @if (step === 2) {
            <p-button
              type="button"
              severity="primary"
              label="Submit"
              icon="fa fa-angle-right"
              iconPos="right"
              [disabled]="!!form.isLoading || !form.form.valid"
              (onClick)="onSubmit()">
            </p-button>
          }
        </div>
      </div>
    </div>
  </div>
}

<ng-template #setupTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-4">
        @if (!!skipSetupAgent && type === CONNECTION_TYPES.MQTT_AGENT && step === 1) {
          <div class="flex justify-content-center align-items-center h-25rem">
            <label class="font-bold">No Configuration Required. Proceed to set up MicroAgent Connection.</label>
          </div>
        } @else {
          @for (item of setupItems; track $index) {
            <microsec-card [isBordered]="true" styleClass="border-round-lg">
              <div class="flex flex-column p-3">
                @if (!item.hideLabel) {
                  <label class="font-bold text-2xl">{{ item.name }}</label>
                  @if (!!item.description) {
                    <label class="text-grey mt-3">{{ item.description }}</label>
                  }
                }
                @switch (item.name) {
                  @case (SETUP_ITEMS.PCAP_FILE) {
                    <!-- Upload PCAP -->
                    <div class="flex flex-column gap-2 mt-3">
                      <label class="text-grey">Upload a PCAP file for MicroIDS to monitor and analyze connection traffic.</label>
                      <label class="link-blue" (click)="openWiresharkDocs()">Capture Network Data Using Wireshark</label>
                    </div>
                    <div class="flex flex-column gap-2 mt-4">
                      <div class="flex align-items-center gap-2">
                        <input
                          type="text"
                          class="w-full"
                          pInputText
                          [disabled]="true"
                          [value]="form.getControlValue(FORM_PARAMS.PCAP_FILE)?.name || 'No file selected'"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.PCAP_FILE)?.touched &&
                              !!form.form.get(FORM_PARAMS.PCAP_FILE)?.dirty &&
                              !!form.form.get(FORM_PARAMS.PCAP_FILE)?.errors
                          }" />
                        <microsec-upload-file-button
                          [acceptedFileTypesArr]="['.pcap', '.pcapng']"
                          (uploadEvent)="uploadEvent($event, FORM_PARAMS.PCAP_FILE, FORM_PARAMS.PCAP_FILE_PATH)" />
                      </div>
                      <ng-container
                        [ngTemplateOutlet]="errorTemplate"
                        [ngTemplateOutletContext]="{
                          formName: FORM_PARAMS.PCAP_FILE,
                          formLabel: 'PCAP file'
                        }">
                      </ng-container>
                    </div>
                  }
                  @case (SETUP_ITEMS.DOWNLOAD_AGENT) {
                    <!-- Download MicroAgent -->
                    <div class="mt-3">
                      <p-button type="button" severity="primary" label="Download" (onClick)="downloadAgent()"> </p-button>
                    </div>
                  }
                  @case (SETUP_ITEMS.NETWORK_CONFIG) {
                    @if (type === CONNECTION_TYPES.PHYSICAL) {
                      <!-- Interface Identifier -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Interface Identifier <microsec-asterisk /></label>
                        <p-dropdown
                          [options]="availableConnection"
                          [formControlName]="FORM_PARAMS.INTERFACE_IDENTIFIER"
                          placeholder="Select an interface identifier"
                          optionLabel="label"
                          optionValue="value"
                          appendTo="body"></p-dropdown>
                      </div>
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS || type === CONNECTION_TYPES.MQTT || type === CONNECTION_TYPES.MQTT_AGENT) {
                      <!-- Hostname -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Hostname <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-2">
                          <input
                            inputTrimRef
                            [maxlength]="100"
                            type="text"
                            pInputText
                            [formControlName]="FORM_PARAMS.INTERFACE_BROKER_HOSTNAME"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME)?.touched &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME)?.dirty &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME)?.errors
                            }" />
                          <p-inputNumber
                            placeholder="Port"
                            mode="decimal"
                            [min]="1"
                            [maxlength]="5"
                            [useGrouping]="false"
                            [formControlName]="FORM_PARAMS.INTERFACE_BROKER_PORT"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="0"
                            [inputStyleClass]="
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PORT)?.touched &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PORT)?.dirty &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PORT)?.errors
                                ? 'ng-invalid ng-dirty'
                                : ''
                            "></p-inputNumber>
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_BROKER_HOSTNAME,
                            formLabel: 'Hostname'
                          }">
                        </ng-container>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_BROKER_PORT,
                            formLabel: 'Port'
                          }">
                        </ng-container>
                      </div>
                    }
                    @if (type === CONNECTION_TYPES.MQTT) {
                      <!-- MQTT Client ID -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">MQTT Client ID</label>
                        <input
                          inputTrimRef
                          [maxlength]="100"
                          type="text"
                          pInputText
                          [formControlName]="FORM_PARAMS.INTERFACE_CLIENT_ID"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.INTERFACE_CLIENT_ID)?.touched &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_CLIENT_ID)?.dirty &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_CLIENT_ID)?.errors
                          }" />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_CLIENT_ID,
                            formLabel: 'MQTT Client ID'
                          }">
                        </ng-container>
                      </div>
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS) {
                      <!-- Access Token -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Access Token <microsec-asterisk /></label>
                        <input
                          type="text"
                          [maxlength]="50"
                          pInputText
                          [formControlName]="FORM_PARAMS.INTERFACE_ACCESS_TOKEN"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.INTERFACE_ACCESS_TOKEN)?.touched &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_ACCESS_TOKEN)?.dirty &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_ACCESS_TOKEN)?.errors
                          }" />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_ACCESS_TOKEN,
                            formLabel: 'Access Token'
                          }">
                        </ng-container>
                      </div>
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS || type === CONNECTION_TYPES.MQTT || type === CONNECTION_TYPES.MQTT_AGENT) {
                      <!-- Is TLS -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Is TLS</label>
                        <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.INTERFACE_IS_TLS"></p-checkbox>
                      </div>
                    }
                    @if (
                      (type === CONNECTION_TYPES.MICROIDS || type === CONNECTION_TYPES.MQTT || type === CONNECTION_TYPES.MQTT_AGENT) &&
                      !!form.getControlValue(FORM_PARAMS.INTERFACE_IS_TLS)
                    ) {
                      <!-- CA Certificate -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">CA Certificate</label>
                        <div class="flex align-items-center gap-2">
                          <input
                            type="text"
                            class="w-full"
                            pInputText
                            [disabled]="true"
                            [value]="form.getControlValue(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.name || 'No file selected'"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                !!form.form.get(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.touched &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.dirty &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.errors
                            }" />
                          <microsec-upload-file-button
                            (uploadEvent)="uploadEvent($event, FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE, FORM_PARAMS.INTERFACE_TLS_CERTIFICATE)" />
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE,
                            formLabel: 'CA Certificate'
                          }">
                        </ng-container>
                      </div>
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS) {
                      <!-- Enable Sync -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Enable Sync</label>
                        <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.INTERFACE_IS_SYNCED"></p-checkbox>
                      </div>
                      <!-- Polling Frequency -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Polling Frequency <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-3">
                          <p-inputNumber
                            mode="decimal"
                            [maxlength]="5"
                            [useGrouping]="false"
                            [formControlName]="FORM_PARAMS.INTERFACE_POLLING_FREQUENCY"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                !!form.form.get(FORM_PARAMS.INTERFACE_POLLING_FREQUENCY)?.touched &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_POLLING_FREQUENCY)?.dirty &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_POLLING_FREQUENCY)?.errors
                            }"></p-inputNumber>
                          <p-dropdown
                            [options]="unitOptions"
                            [formControlName]="FORM_PARAMS.INTERFACE_POLLING_FREQUENCY_UNIT"
                            placeholder="Unit"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"></p-dropdown>
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_POLLING_FREQUENCY,
                            formLabel: 'Polling Frequency'
                          }">
                        </ng-container>
                      </div>
                    }
                  }
                  @case (SETUP_ITEMS.ADVANCED_SETTINGS) {
                    <div class="cursor-pointer flex align-items-center gap-2" (click)="hideAdvanceSettings = !hideAdvanceSettings">
                      <span class="hover:underline font-bold text-xl">{{ item.name }}</span>
                      <i [class]="!hideAdvanceSettings ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
                    </div>
                    @if (!hideAdvanceSettings) {
                      <label class="text-grey mt-3">{{ item.description }}</label>
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Protocols <microsec-asterisk /></label>
                        <p-treeSelect
                          #protocolsTreeSelect
                          appendTo="body"
                          [formControlName]="FORM_PARAMS.PROTOCOLS"
                          [options]="protocolOptions"
                          [metaKeySelection]="false"
                          [propagateSelectionUp]="false"
                          [propagateSelectionDown]="false"
                          selectionMode="checkbox"
                          placeholder="Select protocol(s)"
                          (onNodeSelect)="onProtocolsSelectionChange()"
                          (onNodeUnselect)="onProtocolsSelectionChange()">
                          <ng-template pTemplate="header">
                            <div class="p-treeselect-header">
                              <p-checkbox
                                class="mr-2"
                                [binary]="true"
                                [(ngModel)]="allProtocolsSelected"
                                [ngModelOptions]="{ standalone: true }"
                                (onChange)="onProtocolsToggleAll($event.checked)">
                              </p-checkbox>
                              <div class="p-treeselect-filter-container">
                                <input type="text" class="p-treeselect-filter p-inputtext" (input)="protocolsTreeSelect.onFilterInput($event)" />
                                <span class="p-treeselect-filter-icon pi pi-search"></span>
                              </div>
                              <button class="p-treeselect-close p-link" (click)="protocolsTreeSelect.hide($event)">
                                <span class="p-treeselect-filter-icon pi pi-times"></span>
                              </button>
                            </div>
                          </ng-template>
                        </p-treeSelect>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.PROTOCOLS,
                            formLabel: 'Protocols'
                          }">
                        </ng-container>
                      </div>
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">MAC Addresses to Ignore</label>
                        <p-chips
                          #chips
                          [inputId]="FORM_PARAMS.MACS_TO_IGNORE"
                          [separator]="','"
                          inputTrimRef
                          (onAdd)="onAddChip(FORM_PARAMS.MACS_TO_IGNORE, MAC_REGEX, false, $event.value)"
                          (onBlur)="onAddChip(FORM_PARAMS.MACS_TO_IGNORE, MAC_REGEX, true)"
                          [maxlength]="SHORT_TEXT_MAX_LENGTH"
                          [formControlName]="FORM_PARAMS.MACS_TO_IGNORE"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.MACS_TO_IGNORE)?.touched &&
                              !!form.form.get(FORM_PARAMS.MACS_TO_IGNORE)?.dirty &&
                              !!form.form.get(FORM_PARAMS.MACS_TO_IGNORE)?.errors
                          }" />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.MACS_TO_IGNORE,
                            formLabel: 'MAC Addresses to Ignore'
                          }">
                        </ng-container>
                      </div>
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">IP Addresses to Ignore</label>
                        <p-chips
                          #chips
                          [inputId]="FORM_PARAMS.IPS_TO_IGNORE"
                          [separator]="','"
                          inputTrimRef
                          (onAdd)="onAddChip(FORM_PARAMS.IPS_TO_IGNORE, IP_REGEX, false, $event.value)"
                          (onBlur)="onAddChip(FORM_PARAMS.IPS_TO_IGNORE, IP_REGEX, true)"
                          [maxlength]="SHORT_TEXT_MAX_LENGTH"
                          [formControlName]="FORM_PARAMS.IPS_TO_IGNORE"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.IPS_TO_IGNORE)?.touched &&
                              !!form.form.get(FORM_PARAMS.IPS_TO_IGNORE)?.dirty &&
                              !!form.form.get(FORM_PARAMS.IPS_TO_IGNORE)?.errors
                          }" />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.IPS_TO_IGNORE,
                            formLabel: 'IP Addresses to Ignore'
                          }">
                        </ng-container>
                      </div>
                    }
                  }
                  @case (SETUP_ITEMS.USER_AUTH_CONFIG) {
                    <div class="flex align-items-center justify-content-between gap-2">
                      <label class="font-bold text-xl">{{ item.name }}</label>
                      <p-inputSwitch [formControlName]="FORM_PARAMS.IS_USER_AUTH"></p-inputSwitch>
                    </div>
                    @if (!!form.getControlValue(FORM_PARAMS.IS_USER_AUTH)) {
                      <!-- Username -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Username <microsec-asterisk /></label>
                        <input
                          inputTrimRef
                          [maxlength]="50"
                          type="text"
                          pInputText
                          [formControlName]="FORM_PARAMS.INTERFACE_BROKER_USERNAME"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_USERNAME)?.touched &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_USERNAME)?.dirty &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_USERNAME)?.errors
                          }" />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_BROKER_USERNAME,
                            formLabel: 'Username'
                          }">
                        </ng-container>
                      </div>
                      <!-- Password -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Password</label>
                        <p-password
                          [formControlName]="FORM_PARAMS.INTERFACE_BROKER_PASSWORD"
                          appendTo="body"
                          [maxlength]="50"
                          [toggleMask]="true"
                          [feedback]="false"
                          styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PASSWORD)?.touched &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PASSWORD)?.dirty &&
                              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PASSWORD)?.errors
                          }"
                          [inputStyleClass]="
                            !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PASSWORD)?.touched &&
                            !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PASSWORD)?.dirty &&
                            !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PASSWORD)?.errors
                              ? 'ng-invalid ng-dirty'
                              : ''
                          " />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_BROKER_PASSWORD,
                            formLabel: 'Password'
                          }">
                        </ng-container>
                      </div>
                    }
                  }
                  @case (SETUP_ITEMS.CERT_AUTH_CONFIG) {
                    <div class="flex align-items-center justify-content-between gap-2">
                      <label class="font-bold text-xl">{{ item.name }}</label>
                      <p-inputSwitch [formControlName]="FORM_PARAMS.IS_CERT_AUTH"></p-inputSwitch>
                    </div>
                    @if (!!form.getControlValue(FORM_PARAMS.IS_CERT_AUTH)) {
                      <!-- Client Certificate -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Client Certificate <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-2">
                          <input
                            type="text"
                            class="w-full"
                            pInputText
                            [disabled]="true"
                            [value]="form.getControlValue(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.name || 'No file selected'"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.touched &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.dirty &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.errors
                            }" />
                          <microsec-upload-file-button
                            (uploadEvent)="
                              uploadEvent($event, FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE, FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT)
                            " />
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE,
                            formLabel: 'Client Certificate'
                          }">
                        </ng-container>
                      </div>
                      <!-- Client Key -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Client Key <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-2">
                          <input
                            type="text"
                            class="w-full"
                            pInputText
                            [disabled]="true"
                            [value]="form.getControlValue(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.name || 'No file selected'"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.touched &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.dirty &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.errors
                            }" />
                          <microsec-upload-file-button
                            (uploadEvent)="
                              uploadEvent($event, FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE, FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY)
                            " />
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE,
                            formLabel: 'Client Key'
                          }">
                        </ng-container>
                      </div>
                    }
                  }
                  @case (SETUP_ITEMS.TOPIC_CONFIG) {
                    <div class="mt-2" style="width: calc(900px - 7rem)">
                      <app-topics-field #topicsFieldComponent [isReadOnly]="false" [(topics)]="topics"></app-topics-field>
                    </div>
                  }
                  @case (SETUP_ITEMS.OS_ARCHITECTURE) {
                    <!-- Operating System -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Operating System <microsec-asterisk /></label>
                      <p-dropdown
                        [options]="operatingSystemsOptions"
                        [formControlName]="FORM_PARAMS.OS"
                        placeholder="Select Operating System"
                        optionLabel="label"
                        optionValue="value"
                        optionDisabled="disabled"
                        appendTo="body"></p-dropdown>
                    </div>
                    <!-- Architecture -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Architecture <microsec-asterisk /></label>
                      <p-dropdown
                        [options]="architectureOptions"
                        [formControlName]="FORM_PARAMS.ARCHITECTURE"
                        placeholder="Select Architecture"
                        optionLabel="label"
                        optionValue="value"
                        appendTo="body"></p-dropdown>
                    </div>
                  }
                  @case (SETUP_ITEMS.FEATURES_CONFIG_VERSION) {
                    <!-- Built-In Features -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Built-In Features <microsec-asterisk /></label>
                      <p-dropdown
                        [options]="featureOptions"
                        [formControlName]="FORM_PARAMS.FEATURES"
                        placeholder="Select Built-In Features"
                        optionLabel="label"
                        optionValue="value"
                        optionDisabled="disabled"
                        appendTo="body"></p-dropdown>
                    </div>
                    <!-- Cryptographic Engine -->
                    @if (
                      form.getControlValue(FORM_PARAMS.FEATURES) === MICROAGENT_FEATURES.PROTECTION_MONITORING ||
                      form.getControlValue(FORM_PARAMS.FEATURES) === MICROAGENT_FEATURES.PROTECTION
                    ) {
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Cryptographic Engine <microsec-asterisk /></label>
                        <p-dropdown
                          [options]="cryptographicOptions"
                          [formControlName]="FORM_PARAMS.CRYPTOGRAPHIC"
                          placeholder="Select Cryptographic Engine"
                          optionLabel="label"
                          optionValue="value"
                          appendTo="body"></p-dropdown>
                      </div>
                    }
                    <!-- Agent Version -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Agent Version <microsec-asterisk /></label>
                      <p-dropdown
                        [options]="versionOptions"
                        [formControlName]="FORM_PARAMS.VERSION"
                        placeholder="Select Agent Version"
                        optionLabel="label"
                        optionValue="value"
                        appendTo="body"></p-dropdown>
                    </div>
                  }
                }
              </div>
            </microsec-card>
          }
        }
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #finalizeTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <microsec-card [isBordered]="true" styleClass="border-round-lg">
        <div class="flex flex-column p-3">
          <label class="font-bold text-2xl">General Information</label>
          <label class="text-grey mt-3">Provide a name and description for this connection.</label>
          <div class="flex flex-column gap-2 mt-4">
            <label class="font-bold text-lg">Name <microsec-asterisk /></label>
            <input type="text" [maxlength]="50" pInputText [formControlName]="FORM_PARAMS.NAME" />
            <ng-container
              [ngTemplateOutlet]="errorTemplate"
              [ngTemplateOutletContext]="{
                formName: FORM_PARAMS.NAME,
                formLabel: 'Name'
              }">
            </ng-container>
          </div>
          <div class="flex flex-column gap-2 mt-4">
            <label class="font-bold text-lg">Description</label>
            <input type="text" [maxlength]="50" pInputText [formControlName]="FORM_PARAMS.DESCRIPTION" />
          </div>
          <div class="flex flex-column gap-2 mt-4">
            <label class="font-bold text-lg">Enabled</label>
            <p-inputSwitch [formControlName]="FORM_PARAMS.ENABLED"></p-inputSwitch>
          </div>
        </div>
      </microsec-card>
    </ng-container>
  }
</ng-template>

<ng-template let-formName="formName" let-formLabel="formLabel" #errorTemplate>
  @if (!!form && !!form.form) {
    <label
      class="text-error"
      *ngIf="!!form.form.get(formName)?.errors && !!form.form.get(formName)?.errors?.['incorrect']"
      [innerHTML]="form.form.get(formName)?.errors?.['message']">
    </label>
    <label
      class="text-error"
      *ngIf="!!form.form.get(formName)?.errors?.['required'] && !!form.form.get(formName)?.touched && !!form.form.get(formName)?.dirty">
      {{ formLabel }} is required
    </label>
    <label
      class="text-error"
      *ngIf="!!form.form.get(formName)?.errors?.['pattern'] && !!form.form.get(formName)?.touched && !!form.form.get(formName)?.dirty">
      Please enter a valid {{ formLabel }}
    </label>
  }
</ng-template>
