import { Component, Input } from '@angular/core';
import { MODES } from '@ids-constants';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-ml-analyzer-progress',
  templateUrl: './ml-analyzer-progress.component.html',
  styleUrls: ['./ml-analyzer-progress.component.scss'],
})
export class MlAnalyzerProgressComponent {
  @Input() analyzer: any = null;

  @Input() connectionName: any = null;

  MODES = MODES;

  items: MenuItem[] = [
    {
      label: 'Training',
    },
    {
      label: 'Prediction',
      icon: 'fas fa-infinity text-xl',
    },
  ];

  activeStep = 0;
}
