/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { asyncScheduler, Observable, scheduled } from 'rxjs';

import { IVulnerabilityAnalyzerService } from './IVulnerabilityAnalyzerService';
import * as configurationsData from './mock-data/configurations.json';

@Injectable({
  providedIn: 'root',
})
export class MockVulnerabilityAnalyzerService implements IVulnerabilityAnalyzerService {
  getConfiguration(project_id: any): Observable<any> {
    return scheduled([configurationsData as any], asyncScheduler);
  }
  updateConfiguration(project_id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
