import {
  AuthenticationService,
  MockAuthenticationService,
  OrganizationService,
  MockOrganizationService,
  ProjectService,
  MockProjectService,
  MockUserService,
  UserService,
  MockRoleService,
  RoleService,
  MockUserInboxService,
  UserInboxService,
  FeatureService,
  WorkflowService,
  MockWorkflowService,
  EventMonitorService,
  MockEventMonitorService,
} from '@microsec/services';
import { MockFeatureService } from './feature/mock-feature.service';
import { LDAPSettingService } from './ldap/ldap-setting.service';
import { MockLdapSettingService } from './ldap/mock-ldap-setting.service';
import { MockSetting2FAService } from './setting-2fa/mock-setting-2fa.service';
import { Setting2FAService } from './setting-2fa/setting-2fa.service';
import { LicenseService } from './license/license.service';
import { MockLicenseService } from './license/mock-license.service';
import { AccessTokensService } from './access-tokens/access-tokens.service';
import { MockAccessTokenService } from './access-tokens/mock-access-token.service';
import { AnomalyAnalyzerService } from './anomaly-analyzer/anomaly-analyzer.service';
import { MockAnomalyAnalyzerService } from './anomaly-analyzer/mock-anomaly-analyzer.service';
import { AssessmentService } from './assessment/assessment.service';
import { MockAssessmentService } from './assessment/mock-assessment.service';
import { ConnectionService } from './connection/connection.service';
import { MockConnectionService } from './connection/mock-connection.service';
import { FirmwareService } from './firmware/firmware.service';
import { MockFirmwareService } from './firmware/mock-firmware.service';
import { HybridMonitorService } from './hybrid-monitor/hybrid-monitor.service';
import { MockHybridMonitorService } from './hybrid-monitor/mock-hybrid-monitor.service';
import { IdsService } from './ids/ids.service';
import { MockIdsService } from './ids/mock-ids.service';
import { NetworkDeviceService } from './network-device/network-device.service';
import { MockNetworkDeviceService } from './network-device/mock-network-device.service';
import { ReportService } from './report/report.service';
import { MockReportService } from './report/mock-report.service';
import { TargetDeviceService } from './target-device/target-device.service';
import { MockTargetDeviceService } from './target-device/mock-target-device.service';
import { ThreatService } from './threat/threat.service';
import { MockThreatService } from './threat/mock-threat.service';
import { VulnerabilityAnalyzerService } from './vulnerability-analyzer/vulnerability-analyzer.service';
import { MockVulnerabilityAnalyzerService } from './vulnerability-analyzer/mock-vulnerability-analyzer.service';

export function getServiceReplacements(environment: any) {
  const createServiceReplacement = (realSrv: any, mockSrv: any, shouldUseMockData = false) => {
    return {
      provide: realSrv,
      useClass: !!environment.useMockData || !!shouldUseMockData ? mockSrv : realSrv,
    };
  };
  return [
    createServiceReplacement(AuthenticationService, MockAuthenticationService),
    createServiceReplacement(OrganizationService, MockOrganizationService),
    createServiceReplacement(ProjectService, MockProjectService),
    createServiceReplacement(RoleService, MockRoleService),
    createServiceReplacement(FeatureService, MockFeatureService),
    createServiceReplacement(LicenseService, MockLicenseService),
    createServiceReplacement(EventMonitorService, MockEventMonitorService),
    createServiceReplacement(UserService, MockUserService),
    createServiceReplacement(UserInboxService, MockUserInboxService),
    createServiceReplacement(Setting2FAService, MockSetting2FAService),
    createServiceReplacement(LDAPSettingService, MockLdapSettingService),
    createServiceReplacement(AccessTokensService, MockAccessTokenService),
    createServiceReplacement(AnomalyAnalyzerService, MockAnomalyAnalyzerService),
    createServiceReplacement(AssessmentService, MockAssessmentService),
    createServiceReplacement(ConnectionService, MockConnectionService),
    createServiceReplacement(FirmwareService, MockFirmwareService),
    createServiceReplacement(HybridMonitorService, MockHybridMonitorService),
    createServiceReplacement(IdsService, MockIdsService),
    createServiceReplacement(NetworkDeviceService, MockNetworkDeviceService),
    createServiceReplacement(ReportService, MockReportService),
    createServiceReplacement(TargetDeviceService, MockTargetDeviceService),
    createServiceReplacement(ThreatService, MockThreatService),
    createServiceReplacement(VulnerabilityAnalyzerService, MockVulnerabilityAnalyzerService),
    createServiceReplacement(WorkflowService, MockWorkflowService),
  ];
}
