import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { CREATE_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { CREATE_SUCCESS, UPDATE_SUCCESS } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AnomalyAnalyzerService } from '@ids-services';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize, Observable } from 'rxjs';

export const FORM_PARAMS = {
  NAME: 'name',
  ENABLED: 'enabled',
  DESCRIPTION: 'description',
  RULE: 'rule',
};
@Component({
  selector: 'app-ip-custom-rule-form',
  templateUrl: './ip-custom-rule-form.component.html',
  styleUrls: ['./ip-custom-rule-form.component.scss'],
})
export class IpCustomRuleFormComponent extends BaseComponent implements AfterViewInit {
  analyzerId: any = null;

  rule: any = null;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  FORM_PARAMS = FORM_PARAMS;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('criticalityField') criticalityField!: TemplateRef<any>;

  constructor(
    public anomalyAnalyzerSrv: AnomalyAnalyzerService,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.analyzerId = this.dialogConfig?.data?.analyzerId;
    this.rule = this.dialogConfig?.data?.data;
    this.initForm();
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: true,
        focused: true,
        fieldInfo: 'Name of the rule',
        defaultValue: this.rule?.[FORM_PARAMS.NAME] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the rule',
        defaultValue: this.rule?.[FORM_PARAMS.DESCRIPTION] || '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        label: 'Enabled',
        field: 'checkbox',
        defaultValue: this.rule?.[FORM_PARAMS.ENABLED] || true,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        label: 'Define Custom Rule',
        labelStyleClass: 'font-bold',
        field: 'label',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.RULE,
        label: 'Snort',
        required: true,
        field: 'codemirror',
        codeMirrorShowLanguage: false,
        defaultValue: this.rule?.[FORM_PARAMS.RULE] || '',
      } as FormItem),
    ];
    this.fields = fields;
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const payload = this.form.getRawValue();
    const request: Observable<any> = !this.rule
      ? this.anomalyAnalyzerSrv.createAnalyzerCustomRule(this.analyzerId, payload)
      : this.anomalyAnalyzerSrv.updateAnalyzerCustomRule(this.analyzerId, this.rule.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.rule ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'custom rule'));
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
