import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@ids-shared';
import { SharedEventsModule } from '../shared-components/shared-events/shared-events.module';
import { SharedOrganizationFormModule } from '../shared-components/shared-organization-form/shared-organization-form.module';
import { SharedLicenseWarningModule } from '../shared-components/shared-license-warning/shared-license-warning.module';

import { UserFormComponent } from './users/user-form/user-form.component';
import { UsersComponent } from './users/users.component';
import { LicensesComponent } from './licenses/licenses.component';
import { EventsComponent } from './events/events.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { SystemSettingsComponent } from './system-settings.component';
import { LicenseFormComponent } from './licenses/license-form/license-form.component';
import { LicenseDetailsComponent } from './licenses/license-details/license-details.component';
import { RegistrationComponent as LicenseRegistrationComponent } from './licenses/license-details/registration/registration.component';
import { QuotasComponent as LicenseQuotasComponent } from './licenses/license-details/quotas/quotas.component';
import { SystemSnortSettingsComponent } from './system-snort-settings/system-snort-settings.component';
import { SharedSnortSettingsModule } from '../shared-components/shared-snort-settings/shared-snort-settings.module';

@NgModule({
  declarations: [
    SystemSettingsComponent,
    OrganizationsComponent,
    EventsComponent,
    LicensesComponent,
    LicenseFormComponent,
    LicenseDetailsComponent,
    LicenseRegistrationComponent,
    LicenseQuotasComponent,
    UsersComponent,
    UserFormComponent,
    SystemSnortSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedLicenseWarningModule,
    SharedEventsModule,
    SharedOrganizationFormModule,
    SharedSnortSettingsModule,
  ],
})
export class SystemSettingsModule {}
