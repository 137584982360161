@if (!!analyzer) {
  <microsec-card [isNestedCard]="true">
    <div class="grid align-items-center">
      <label class="col-12 font-bold"> Analyzer Progress Timeline </label>
      <div class="col-12">
        <microsec-custom-steps
          [steps]="items"
          [activeStep]="analyzer.trainingProgress < 100 ? 0 : 1"
          colorStyle="green"
          [loading]="analyzer.trainingProgress < 100"></microsec-custom-steps>
      </div>
      <div class="col-12">
        <div class="flex align-items-center gap-5 mx-auto" style="max-width: 32rem">
          <!-- Training -->
          @if (analyzer.trainingProgress < 100) {
            <span><i class="fas fa-cubes-stacked progress-icon"></i></span>
            <div class="grid">
              <label class="col-12 font-bold"> Training in Progress </label>
              <label class="col-12"> The FL model is currently being trained across selected MicroAgents. </label>
              <div class="col-12">
                <div class="progress-detail">
                  Round:
                  <span> {{ analyzer.roundsCompleted || 0 }}/{{ analyzer.roundsTotal || 0 }} </span>
                  | Progress:
                  <span>
                    {{ analyzer.trainingProgress ? (analyzer.trainingProgress | number: '1.0-2') + '%' : '0%' }}
                  </span>
                </div>
              </div>
            </div>
          }

          <!-- Predicition -->
          @if (analyzer.trainingProgress >= 100) {
            <span><i class="fas fa-infinity progress-icon"></i></span>
            <div class="grid">
              <label class="col-12 font-bold"> Packet Queuing and Prediction </label>
              <label class="col-12">
                FL Model is now in prediction mode. Streaming packets will be queued and analyzed in real-time for threat detection.
              </label>
            </div>
          }
        </div>
      </div>
    </div>
  </microsec-card>
}
