<div class="flex relative z-1">
  <div class="buttons-wrapper w-full absolute">
    <div
      class="buttons-list network-map-option-buttons relative flex flex-row justify-content-end"
      [ngClass]="{
        expanded: !isItemListDisplayed && !isItemDetailsDisplayed,
        collapsed: !!isItemListDisplayed || !!isItemDetailsDisplayed
      }">
      <!-- Buttons -->
      <div class="flex flex-row align-items-center">
        <p-button
          id="network-map-settings-button"
          icon="fas fa-cog"
          severity="secondary"
          styleClass="p-button-outlined"
          (onClick)="isSettingsDisplayed = !isSettingsDisplayed"></p-button>
        <p-selectButton
          [options]="modes"
          [(ngModel)]="mode"
          optionLabel="''"
          optionValue="value"
          (onChange)="changeMode($event.value)"
          [unselectable]="true"
          styleClass="ml-2">
          <ng-template let-item pTemplate>
            <div [pTooltip]="item.label" tooltipPosition="bottom" appendTo="body">
              <i [class]="item.icon"></i>
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <!-- Settings Menu -->
      <div id="network-map-settings-menu" class="settings-menu absolute w-max pr-6 z-2" *ngIf="!!isSettingsDisplayed">
        <div class="flex flex-column" [ngClass]="{ 'pt-3': i > 0 && !!setting.mode.includes(mode) }" *ngFor="let setting of settings; let i = index">
          <div class="font-bold" *ngIf="!!setting.mode.includes(mode)">
            {{ setting.label }}
          </div>
          <ng-container *ngFor="let item of setting.items; let last = last">
            <div [class]="!!item.mode.includes(mode) ? 'flex pt-3' : ''">
              <p-checkbox
                *ngIf="!!item.mode.includes(mode)"
                [binary]="true"
                [(ngModel)]="item.checked"
                [label]="item.label"
                (ngModelChange)="changeSetting(mode, setting.key, item, $event)">
              </p-checkbox>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Legend Board -->
      <div
        id="network-map-legend"
        class="legend overflow-y-auto absolute w-max pr-6 z-1"
        *ngIf="!!getSettingChecked('legend') && mode === NETWORK_MAP_MODE_VALUES.PURDUE_MODEL">
        <div class="flex flex-column">
          <div class="font-bold pb-3">Protocols</div>
          <ng-container *ngFor="let legend of legends; let i = index">
            <div class="flex flex-column">
              <div class="flex flex-row" [ngClass]="{ 'pt-2': i !== 0, 'pb-2': i !== legends.length - 1 }">
                <div class="legend-item mr-2" [ngStyle]="{ background: legend.color }"></div>
                <div>{{ legend.label }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
