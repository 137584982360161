import { Pipe, PipeTransform } from '@angular/core';
import {
  ANALYZER_RULE_TYPE_OPTIONS,
  ANALYZER_TYPE_OPTIONS,
  ASSESSMENT_RESULT_LABELS,
  MODE_OPTIONS,
  PROTOCOL_OPTIONS,
  CONNECTION_TYPE_OPTIONS,
  CHAIN_OPTIONS,
  INTERFACE_OPTIONS,
  IP_PROTOCOL_OPTIONS,
  IP_VERSION_OPTIONS,
  TARGET_OPTIONS,
  FIRMWARE_DEVICE_STATUSES,
  FIRMWARE_TARGET_DEVICES,
  CONFIG_TYPE_OPTIONS,
  MODEL_OPTIONS,
  APPLICATION_PROTOCOL_OPTIONS,
  DEVICE_TYPE_OPTIONS,
  INTERFACE_TYPES,
  INTERFACE_TYPE_LABELS,
  INTERFACE_TYPE_OPTIONS,
  LEVEL_OPTIONS,
  ATTACK_TYPE_OPTIONS,
  THREAT_STATUS_OPTIONS,
  THREAT_TYPES,
  THREAT_TYPE_OPTIONS,
  MITIGATION_STATUS_OPTIONS,
  ATTACK_OPTIONS,
} from '@ids-constants';
import { Util } from '@microsec/utilities';

const deviceInterfaceOptions = [
  ...INTERFACE_TYPE_OPTIONS,
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.TCP6], value: INTERFACE_TYPES.TCP6 },
  { label: INTERFACE_TYPE_LABELS[INTERFACE_TYPES.UDP6], value: INTERFACE_TYPES.UDP6 },
];

/*
 * Transform threat score number to array of number.
 * Usage:
 *   value | threatScore
 * Example:
 *   {{ 2 | threatScore }}
 *   formats to: [1, 1]
 */
@Pipe({ name: 'threatScorePipe' })
export class ThreatScorePipe implements PipeTransform {
  transform(threatScore: number): number {
    if (threatScore < 0) {
      return 0;
    } else if (threatScore > 10) {
      return 10;
    } else {
      return Math.floor(threatScore);
    }
  }
}

/*
 * Pipe to collect rule type from analyzer rule_types object and transform to string.
 */
@Pipe({ name: 'analyzerRuleTypesPipe' })
export class AnalyzerRuleTypesPipe implements PipeTransform {
  transform(data: any[]): string {
    if (!data?.length) {
      return '-';
    }
    const strArr = data.filter((d) => !!d.enabled && !!d.type).map((d) => getLabel(ANALYZER_RULE_TYPE_OPTIONS, d.type));
    return strArr.length > 0 ? strArr.join(', ') : '-';
  }
}

/*
 * Pipe to collect interface keys from detected device object and transform to string.
 */
@Pipe({ name: 'detectedDeviceInterfacesPipe' })
export class DetectedDeviceInterfacesPipe implements PipeTransform {
  supportedProtocols = Object.values(INTERFACE_TYPES);
  transform(data: any): string {
    if (!data) {
      return '-';
    }
    const strArr: string[] = [];
    Object.keys(data).forEach((key) => {
      if (this.supportedProtocols.includes(key) && !!Object.keys(data[key] || {}).length) {
        strArr.push(INTERFACE_TYPE_LABELS[key]);
      }
    });
    return strArr.length > 0 ? strArr.join(', ') : '-';
  }
}

/*
 * Pipe to transform Unix timestamp to date time.
 */
@Pipe({ name: 'unixTimestampTransformPipe' })
export class UnixTimestampTransformPipe implements PipeTransform {
  transform(seconds: any) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + 'd ' : '';
    const hDisplay = h > 0 ? h + 'h ' : '';
    const mDisplay = m > 0 ? m + 'm ' : '';
    const sDisplay = s > 0 ? s + 's' : '';
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }
}

@Pipe({ name: 'sortPipe' })
export class SortPipe implements PipeTransform {
  transform(arr: any[], propertyToCompare: string, isAscending = true, isDatetime = false) {
    return Util.sortObjectArray((arr as any[]) || [], propertyToCompare, isAscending, isDatetime);
  }
}

@Pipe({ name: 'arrayMapPipe' })
export class ArrayMapPipe implements PipeTransform {
  transform(arr: any[], key?: string, toString = false) {
    const mappedArr = !!key ? arr.filter((value) => !!value[key]).map((value) => value[key]) : [...arr];
    if (!toString) {
      return mappedArr;
    } else {
      return mappedArr.join(', ');
    }
  }
}

/*
 * Pipe to transform string array to comma separated string
 * "snake_case" to "Snake Case" or "camelCase" to "Camel Case"
 * Used in converting string array to search placeholder string
 */
@Pipe({ name: 'arrayToPlaceholderStringPipe' })
export class ArrayToPlaceholderStringPipe implements PipeTransform {
  transform(arr: string[], leadingStr = 'Search ', trailingStr = '...'): string {
    if (!Array.isArray(arr)) return '';

    const formattedArray = arr.map((value) => {
      const isException = value.toLowerCase() === 'id';

      return isException
        ? value.toUpperCase()
        : value
            .replace(/_+/g, ' ')
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z][a-z])/g, '$1')
            .replace(/(\b\w)/g, (str) => str.toUpperCase());
    });

    if (formattedArray.length > 1) {
      const lastItem = formattedArray.pop();
      return leadingStr + formattedArray.join(', ') + ', or ' + lastItem + trailingStr;
    } else {
      return leadingStr + formattedArray.join(', ') + trailingStr;
    }
  }
}

@Pipe({ name: 'middleEllipsisPipe' })
export class MiddleEllipsisPipe implements PipeTransform {
  transform(str: string, maxLength: number) {
    if (str.length > maxLength + 3) {
      return str.substring(0, Math.floor(maxLength / 2)) + '...' + str.substring(str.length - Math.floor(maxLength / 2), str.length);
    }
    return str;
  }
}

@Pipe({ name: 'momentPipe' })
export class MomentPipe implements PipeTransform {
  transform(date: any, format?: string) {
    return Util.formatDate(date, format) || '-';
  }
}

@Pipe({ name: 'threatTitlePipe' })
export class ThreatTitlePipe implements PipeTransform {
  transform(threat: any) {
    if (!threat.threat_type && !threat.attack_type) {
      return '-';
    } else {
      const threatTypeLabel = threat.threat_type ? getLabel(THREAT_TYPE_OPTIONS, threat.threat_type) : '';
      const attackTypeLabel = threat.attack_type ? getLabel(ATTACK_TYPE_OPTIONS, threat.attack_type.toLowerCase(), true) : '';
      return threat.threat_type === THREAT_TYPES.VULNERABILITY && threat.attack_type?.toLowerCase() !== 'unknown'
        ? attackTypeLabel || '-'
        : threatTypeLabel + (!!attackTypeLabel ? ` (${attackTypeLabel})` : '');
    }
  }
}

@Pipe({
  name: 'constant',
})
export class ConstantPipe implements PipeTransform {
  transform(value: any, type: string, isArray = false): string {
    let result = '-';
    if (!!value) {
      switch (type) {
        case 'network-device-config-type': {
          result = getLabel(CONFIG_TYPE_OPTIONS, value);
          break;
        }
        case 'network-device-model': {
          result = getLabel(MODEL_OPTIONS, value);
          break;
        }
        case 'firmware-device-status': {
          result = getLabel(FIRMWARE_DEVICE_STATUSES, value);
          break;
        }
        case 'firmware-target-device': {
          result = getLabel(FIRMWARE_TARGET_DEVICES, value);
          break;
        }
        case 'detected-device-type': {
          result = getLabel(DEVICE_TYPE_OPTIONS, value);
          break;
        }
        case 'detected-device-interface': {
          result = getLabel(deviceInterfaceOptions, value);
          break;
        }
        case 'detected-device-network-map-level': {
          result = getLabel(LEVEL_OPTIONS, value);
          break;
        }
        case 'detected-device-application-protocols': {
          result = !!isArray
            ? (value as any[])?.map((v) => getLabel(APPLICATION_PROTOCOL_OPTIONS, v))?.join(', ')
            : getLabel(APPLICATION_PROTOCOL_OPTIONS, value);
          break;
        }
        case 'assessment-result': {
          result = ASSESSMENT_RESULT_LABELS[value] || Util.getUppercaseFirstLetter(value);
          break;
        }
        case 'anomaly-analyzer-type': {
          result = getLabel(ANALYZER_TYPE_OPTIONS, value);
          break;
        }
        case 'anomaly-analyzer-mode': {
          result = getLabel(MODE_OPTIONS, value);
          break;
        }
        case 'anomaly-analyzer-protocol': {
          result = !!isArray ? (value as any[])?.map((v) => getLabel(PROTOCOL_OPTIONS, v))?.join(', ') : getLabel(PROTOCOL_OPTIONS, value);
          break;
        }
        case 'anomaly-analyzer-rule-type': {
          result = !!isArray
            ? (value as any[])?.map((v) => getLabel(ANALYZER_RULE_TYPE_OPTIONS, v))?.join(', ')
            : getLabel(ANALYZER_RULE_TYPE_OPTIONS, value);
          break;
        }
        case 'anomaly-analyzer-attack': {
          result = !!isArray ? (value as any[])?.map((v) => getLabel(ATTACK_OPTIONS, v))?.join(', ') : getLabel(ATTACK_OPTIONS, value);
          break;
        }
        case 'threat-status': {
          result = getLabel(THREAT_STATUS_OPTIONS, value?.toLowerCase());
          break;
        }
        case 'threat-type': {
          result = getLabel([...THREAT_TYPE_OPTIONS, { label: 'Non-Compliance', value: 'non_compliance' }], value?.toLowerCase(), true);
          break;
        }
        case 'threat-attack-type': {
          result = getLabel(ATTACK_TYPE_OPTIONS, value?.toLowerCase(), true);
          break;
        }
        case 'connection-interface-type': {
          result = getLabel(CONNECTION_TYPE_OPTIONS, value);
          break;
        }
        case 'firewall-ip-protocol': {
          result = getLabel(IP_PROTOCOL_OPTIONS, value);
          break;
        }
        case 'firewall-interface': {
          result = getLabel(INTERFACE_OPTIONS, value);
          break;
        }
        case 'firewall-ip-version': {
          result = getLabel(IP_VERSION_OPTIONS, value);
          break;
        }
        case 'firewall-chain': {
          result = getLabel(CHAIN_OPTIONS, value);
          break;
        }
        case 'firewall-target': {
          result = getLabel(TARGET_OPTIONS, value);
          break;
        }
        case 'threat-mitigation-status': {
          result = getLabel(MITIGATION_STATUS_OPTIONS, value);
          break;
        }
        default: {
          break;
        }
      }
    }
    return result;
  }
}

function getLabel(constant: { value: any; label: string }[], value: any, isElseUppercase = false) {
  const result = constant.find((c) => c.value === value);
  if (!!result) {
    return result.label;
  } else {
    if (!!isElseUppercase) {
      const words: string[] = value.split('_') || [''];
      const capitalizedWords = words.map((item) => Util.getUppercaseFirstLetter(item));
      return capitalizedWords.join(' ');
    } else {
      return value;
    }
  }
}
