import { Component, TemplateRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ANALYZER_FEATURES, SYNC_FREQUENCY_OPTIONS } from '@ids-constants';
import { CANCEL_LABEL, SAVE_CHANGES_LABEL } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { ConnectionService, VulnerabilityAnalyzerService } from '@ids-services';

import { finalize } from 'rxjs';

import { closeMessage, setMessage } from '../anomaly-analyzer.component';

export const FORM_PARAMS = {
  SCAN_FREQUENCY: 'scan_frequency',
  CONNECTION_IDS: 'connection_ids',
  PACKAGES: 'packages',
  CONFIGURATIONS: 'configurations',
  FIRMWARES: 'firmwares',
  PROTOCOLS: 'protocols',
  ENABLED: 'enabled',
  CONNECTION_TEXT: 'connection_text',
};

@Component({
  selector: 'app-vulnerability',
  templateUrl: './vulnerability.component.html',
  styleUrls: ['./vulnerability.component.scss'],
})
export class VulnerabilityComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('enabledTemplate') enabledTemplate!: TemplateRef<any>;

  @ViewChild('frequencyTemplate') frequencyTemplate!: TemplateRef<any>;

  @ViewChild('connectionsTemplate') connectionsTemplate!: TemplateRef<any>;

  connectionOptions: { label: string; value: number }[] = [];

  configuration: any = {};

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  CANCEL_LABEL = CANCEL_LABEL;

  SYNC_FREQUENCY_OPTIONS = SYNC_FREQUENCY_OPTIONS;

  _messageValue: any[] = [];

  get messageValue() {
    return this._messageValue;
  }

  @Input() set messageValue(value: any[]) {
    this._messageValue = value;
    if (!!value && !value.length) {
      closeMessage('vulnerability');
    }
  }

  constructor(
    private fb: FormBuilder,
    private vulnerabilitySrv: VulnerabilityAnalyzerService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    if (!!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.VULNERABILITY_ANALYZER)) {
      this.messageValue = setMessage('vulnerability');
      this.getConnections();
      this.getConfigurationData();
    } else {
      this.navigateToNotFoundPage();
    }
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Configuration',
        labelStyleClass: 'text-xl font-bold mb-4',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENABLED,
        hasNoLabel: true,
        field: 'custom',
        customField: this.enabledTemplate,
        defaultValue: {},
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SCAN_FREQUENCY,
        hasNoLabel: true,
        field: 'custom',
        customField: this.frequencyTemplate,
        defaultValue: this.configuration[FORM_PARAMS.SCAN_FREQUENCY] || null,
      } as FormItem),
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONNECTION_IDS,
        hasNoLabel: true,
        field: 'custom',
        customField: this.connectionsTemplate,
        defaultValue: this.configuration[FORM_PARAMS.CONNECTION_IDS] || [],
        hidden: !this.configuration?.[FORM_PARAMS.ENABLED]?.[FORM_PARAMS.PROTOCOLS],
      } as FormItem),
      Object.assign(new FormItem(), {
        name: 'lastDivider',
        field: 'divider',
        hidden: !this.configuration?.[FORM_PARAMS.ENABLED]?.[FORM_PARAMS.PROTOCOLS],
      } as FormItem),
    ];
    this.fields = fields;
    setTimeout(() => {
      this.form?.form?.setControl(
        FORM_PARAMS.ENABLED,
        this.fb.group({
          [FORM_PARAMS.PACKAGES]: [!!this.configuration?.[FORM_PARAMS.ENABLED]?.[FORM_PARAMS.PACKAGES]],
          [FORM_PARAMS.CONFIGURATIONS]: [!!this.configuration?.[FORM_PARAMS.ENABLED]?.[FORM_PARAMS.CONFIGURATIONS]],
          [FORM_PARAMS.FIRMWARES]: [!!this.configuration?.[FORM_PARAMS.ENABLED]?.[FORM_PARAMS.FIRMWARES]],
          [FORM_PARAMS.PROTOCOLS]: [!!this.configuration?.[FORM_PARAMS.ENABLED]?.[FORM_PARAMS.PROTOCOLS]],
        }),
      );
      this.form?.setChangeEvent(FORM_PARAMS.ENABLED, (enabled: any) => {
        this.form.setControlVisibility(FORM_PARAMS.CONNECTION_IDS, !!enabled[FORM_PARAMS.PROTOCOLS]);
        this.form.setControlVisibility('lastDivider', !!enabled[FORM_PARAMS.PROTOCOLS]);
        if (!enabled[FORM_PARAMS.PROTOCOLS]) {
          this.form.setControlValue(FORM_PARAMS.CONNECTION_IDS, []);
        }
      });
    });
  }

  getConfigurationData() {
    this.form.isLoading = true;
    this.vulnerabilitySrv
      .getConfiguration(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.initForm();
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.configuration = res;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getConnections() {
    this.form.isLoading = true;
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.connectionOptions = (res.data as any[] | []).map((con) => ({
            label: con.name || con.id,
            value: con.id,
          }));
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onSubmit() {
    this.form.isLoading = true;
    const payload = {
      ...this.form.getRawValue(),
    };
    delete payload.lastDivider;
    this.vulnerabilitySrv
      .updateConfiguration(this.breadcrumbConfig?.projectId, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.showSuccessMessage(res.message);
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.getConfigurationData();
        },
      });
  }
}
