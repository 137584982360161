@if (model?.form_type === 'import') {
  <div class="text-gray-200 py-3 px-5">
    <div class="col-12">Please ensure the model to be imported meets the following requirements:</div>
    <div class="col-12">
      <ul class="m-0">
        <li class="mb-2">Model is retrieved from previous MicroIDS analyzers</li>
        <li class="mb-2">Model is of the same protocol</li>
        <li>Model is in .pkl format</li>
      </ul>
    </div>
    <div class="col-12">If the requirements are not met, the imported model will be replaced with a newly retrained model during prediction.</div>
  </div>
}

<microsec-form-builder #fb [fields]="fields" [shouldShowActionButtons]="true" [submitButtonLabel]="CONFIRM_LABEL" (submitEvent)="onSubmit($event)" />
