import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportSpecification } from '@microsec/models';
import { BaseComponent, CommonTableComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { AssessmentService } from '@ids-services';
import { DELETE_LABEL, PER_PAGE } from '@microsec/constants';
import { ActionMenuItem } from '@microsec/models';

import { BehaviorSubject, finalize } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';

import { AssessmentQuestionFormComponent } from './assessment-question-form/assessment-question-form.component';

const FIELDS = {
  id: 'ID',
  title: 'Title',
};

@Component({
  selector: 'app-assessment-questions',
  templateUrl: './assessment-questions.component.html',
  styleUrls: ['./assessment-questions.component.scss'],
})
export class AssessmentQuestionsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = true;

  @ViewChild('dt') dt!: CommonTableComponent;

  values: any[] = [];

  cols = [
    { field: 'id', header: FIELDS.id, width: 8 },
    { field: 'title', header: FIELDS.title, width: 10 },
  ];

  currentPage = 1;

  totalRecords = 0;

  selectedQuestions: any[] = [];

  selectedQuestion: any = null;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: 'Search Title, or Description...',
    hideClearFilters: false,
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(private assessmentSrv: AssessmentService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.selectedColFields = (this.cols || []).map((col) => col.field);
    this.actionsMenuItems = [
      {
        label: 'Edit',
        icon: 'fa fa-edit',
        visible: ({ rowData }) => !!((this.permissions as any)[rowData.scope] || this.permissions[this.SCOPE.PROJECT])[this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openQuestionForm(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: ({ rowData }) => !!((this.permissions as any)[rowData.scope] || this.permissions[this.SCOPE.PROJECT])[this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.handleFilterObjUpdate();
    this.getAssessmentQuestions();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.assessmentSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getAssessmentQuestions();
      }
    });
    this.subscriptions.push(subscription);
  }

  handleFilterObjUpdate() {
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.filterSearch = values?.search || '';
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getAssessmentQuestions();
      }
    });
  }

  getAssessmentQuestions(resetSelectedQuestions = true, event?: LazyLoadEvent) {
    this.isLoading = true;
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.assessmentSrv
      .getAssessmentQuestions(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        page,
        perPage,
        this.dt?.datatable?.sortField as any,
        this.dt?.datatable?.sortOrder ? this.dt?.datatable?.sortOrder === 1 : true,
        this.filterSearch,
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          this.values = ((res.questions as any[]) || []).map((question) => ({
            ...question,
            descriptionPreview: this.util.parseMarkdown(question?.description || ''),
          }));
          if (!!this.selectedQuestion) {
            this.selectedQuestion = this.values.find((q) => q.id === this.selectedQuestion.id) || null;
          }
          if (!!resetSelectedQuestions) {
            this.selectedQuestions = [];
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        this.dt.generateReportDialog.exportReport(specification.data || [], `project_${this.breadcrumbConfig?.projectId}_assessment_questions`);
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, [], this.values);
  }

  openQuestionForm(question?: any) {
    const dialog = this.dialogSrv.open(AssessmentQuestionFormComponent, {
      data: { question: !question ? null : question },
      header: `${!question ? 'Create' : 'Edit'} Assessment Question`,
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.assessmentSrv.refresh$.next(rs);
      }
    });
  }

  openDeleteConfirmation(questions: any[] = [], isForce = false) {
    const questionIds = questions.map((question) => question.id) || [];
    const questionList = questions.map((q) => `<li>Assessment Question ID: ${q.id}</li>`) || [];
    const message = !isForce
      ? `Are you sure you want to delete ${
          !questions?.length ? 'all questions?<br/><br/>' : `the following assessment question(s)?<ul>${questionList.join('')}</ul>`
        }`
      : `Deleting ${questions.length > 1 ? 'these' : 'this'} assessment ${questions.length > 1 ? 'questions' : 'question'} will also remove ${
          questions.length > 1 ? 'them' : 'it'
        } from all assessment types ${questions.length > 1 ? 'they are' : 'it is'} associated with.<br/><br/>Are you sure you want to proceed?`;
    this.confirm({
      action: DELETE_LABEL,
      isDependenciesDetected: !!isForce,
      objectName: !isForce ? 'Question(s)' : '',
      customContent: message,
      prepareRequest: () => {
        this.isLoading = true;
      },
      acceptRequest: this.assessmentSrv.deleteAssessmentQuestions(questionIds, isForce),
      next: () => {
        this.isLoading = false;
        this.showSuccessMessage(`Deleted assessment question(s) successfully`);
        const questionIds = questions.map((question) => question.id) || [];
        if (!!this.selectedQuestions?.length) {
          this.selectedQuestions = this.selectedQuestions.filter((question) => !questionIds?.includes(question.id));
        }
        if (!!this.selectedQuestion && !!questionIds.includes(this.selectedQuestion.id)) {
          this.selectedQuestion = null;
        }
        this.getAssessmentQuestions();
      },
      error: (err) => {
        this.isLoading = false;
        if (!isForce && err.status === 400) {
          setTimeout(() => {
            this.openDeleteConfirmation(questions, true);
          }, 500);
        } else {
          this.showErrorMessage(err);
        }
      },
    });
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedQuestion = null;
  }

  override ngOnDestroy() {
    this.cleanup();
    this.assessmentSrv.refresh$.next(null);
  }
}
