import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { ILayoutItem } from '@microsec/interfaces';

import { DASHBOARD_CHILDREN } from './dashboard.config';
import { fromLayoutActions } from '@microsec/ngrx-layout';

import { ShepherdService } from 'angular-shepherd';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  defaultStepOptions = {
    classes: 'shepherd-theme-arrows custom-tour-card',
    scrollTo: true,
    cancelIcon: {
      enabled: false,
    },
    when: {
      hide: () => localStorage.removeItem('showProductTour'),
    },
  };

  steps: any[] = [
    {
      attachTo: {
        element: '.tour-documentation-step',
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'p-button-secondary text-data',
          secondary: true,
          text: 'Got It',
          type: 'next',
        },
      ],
      id: 'documentation',
      classes: 'mt-2',
      title: 'Documentation Page',
      text: `Visit our Documentation page to get in-depth details on MicroIDS functionalities.`,
    },
    {
      attachTo: {
        element: '.tour-network-settings-step',
        on: 'left',
      },
      buttons: [
        {
          classes: 'p-button-secondary text-data',
          secondary: true,
          text: 'Got It',
          action: () => this.shepherdSrv.complete(),
        },
      ],
      id: 'network_analyzer',
      classes: 'ml-3',
      title: 'Network and Analyzer Settings',
      text: `You can create, edit or delete any network or analyzer here.`,
    },
  ];

  constructor(private shepherdSrv: ShepherdService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      const tabItems: ILayoutItem[] = this.util.cloneObjectArray(DASHBOARD_CHILDREN).filter((p) => !!p.label);
      tabItems.forEach((item) => {
        if (!!item.featureName && !!item.featureCheckField) {
          item.visible = this.features?.[item.featureName]?.[item.featureCheckField];
        }
      });
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
    }
  }

  ngAfterViewInit(): void {
    const showProductTour = JSON.parse(localStorage.getItem('showProductTour') || 'false');
    if (!!showProductTour) {
      this.shepherdSrv.defaultStepOptions = this.defaultStepOptions;
      this.shepherdSrv.modal = true;
      this.shepherdSrv.addSteps(this.steps);
      setTimeout(() => {
        if (document.getElementsByClassName('.tour-documentation-step') && document.getElementsByClassName('.tour-network-settings-step')) {
          this.shepherdSrv.start();
        }
      }, 1000);
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
