import { Cell } from '@maxgraph/core';
import { NetworkMapEditor } from './network-map-editor';
import { EDITOR_OBJECT_TYPES } from '@ids-constants';
export const NMECell = {
  /**
   * Remove selected cells
   */
  removeSelectedCells(this: NetworkMapEditor) {
    const selectedCells = this.graph?.getSelectionCells().filter((p) => !!p) || [];
    const selectedDeviceIds: any[] = NMECell.getSelectedDeviceIds(selectedCells);
    this.removeImportedDeviceId(selectedDeviceIds);
    this.graph?.removeCells();
  },

  /**
   * Import devices from all zones
   * @param cell
   */
  getSelectedDeviceIds(cells: Cell[]) {
    const selectedDeviceIds: any[] = [];
    cells.forEach((cell) => {
      if (cell.id?.startsWith(`${EDITOR_OBJECT_TYPES.DEVICE}-`)) {
        selectedDeviceIds.push(cell.value?.data?.device_id);
      } else if (cell.id?.startsWith(`${EDITOR_OBJECT_TYPES.ZONE}-`)) {
        selectedDeviceIds.push(...this.getSelectedDeviceIds(cell.children));
      }
    });
    return selectedDeviceIds;
  },
};
