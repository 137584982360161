import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EVENT_CRITICALITIES, EVENTS_PERIODS } from '@ids-constants';
import { DELETE_LABEL, PER_PAGE, PRIMENG_CALENDAR_DATE } from '@microsec/constants';
import { BaseComponent, CommonTableComponent } from '@microsec/components';
import { OrganizationService, UserService, EventMonitorService } from '@microsec/services';
import { verticalSlideAnimation } from '@microsec/animations';
import { MomentPipe } from '@ids-pipes';
import { ReportSpecification, CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem } from '@microsec/models';

import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable, finalize, forkJoin } from 'rxjs';

const FIELDS: { [key: string]: string } = {
  id: 'ID',
  eventLabel: 'Event',
  categoryLabel: 'Category',
  criticalityLabel: 'Criticality',
  organizationLabel: 'Organization',
  userLabel: 'Username',
  timestampLabel: 'Timestamp',
};

@Component({
  selector: 'app-shared-events',
  templateUrl: './shared-events.component.html',
  styleUrls: ['./shared-events.component.scss'],
  providers: [MomentPipe],
  animations: [verticalSlideAnimation],
})
export class SharedEventsComponent extends BaseComponent implements OnInit {
  isLoading = true;

  @Input() isCleared: any = null;

  _deviceId: any = null;

  get deviceId() {
    return this._deviceId;
  }

  @Input() set deviceId(values: any) {
    this._deviceId = values;
    if (!!values) {
      this.getEvents();
    }
  }

  currentPage = 1;

  totalRecords = 0;

  lazyLoadEvent: LazyLoadEvent | null = null;

  values: any[] = [];

  cols = [
    { field: 'id', header: 'ID', width: 5 },
    { field: 'eventLabel', header: 'Event', width: 10 },
    { field: 'categoryLabel', header: 'Category', width: 10 },
    { field: 'criticalityLabel', header: 'Criticality', width: 8 },
    { field: 'organizationLabel', header: 'Organization', width: 8 },
    { field: 'userLabel', header: 'Username', width: 8 },
    { field: 'timestampLabel', header: 'Timestamp', width: 12 },
  ];

  selectedEvents: any[] = [];

  _selectedEvent: any = null;

  get selectedEvent() {
    return this._selectedEvent;
  }

  set selectedEvent(value: any) {
    this._selectedEvent = value;
    this.displayEventDetails = !!value && !this.isMainPage;
  }

  //check if event page is called by main Event page (true) or Device page (false)
  //trigger sidebar panel if not on main page i.e. from Device
  @Input() isMainPage? = true;

  displayEventDetails = false;

  categories: any[] = [];

  subCategories: any[] = [];

  criticalities: any[] = this.util.cloneObjectArray(EVENT_CRITICALITIES);

  organizations: any[] = [];

  users: any[] = [];

  periods: any[] = this.util.cloneObjectArray(EVENTS_PERIODS);

  filters = {
    category: null,
    criticality: null,
    organization: null,
    user: null,
    period: null,
    startDate: null,
    endDate: null,
    search: '',
  };

  PER_PAGE = PER_PAGE;

  PRIMENG_CALENDAR_DATE = PRIMENG_CALENDAR_DATE;

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'filter'],
    searchPlaceholder: 'Search Event Payload...',
    hideResetSortOption: true,
    filters: {
      1: {
        key: 'category', //filter dictionary key when received obs value
        label: ' Categories ',
        type: 'dropdown',
        options: [], //populate with API call on ngOnInit
      },
      2: {
        key: 'criticality',
        label: ' Criticality ',
        type: 'multiselect',
        options: EVENT_CRITICALITIES,
      },
      3: {
        key: 'time',
        label: ' Time Range ',
        type: 'date-range',
      },
    },
  };

  actionsMenuItems: ActionMenuItem[] = [];

  constructor(
    private momentPipe: MomentPipe,
    private eventMonitorSrv: EventMonitorService,
    private organizationSrv: OrganizationService,
    private userSrv: UserService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();

    //set the columnFields in the filter settings
    this.selectedColFields = ['id', 'eventLabel', 'categoryLabel', 'criticalityLabel', 'organizationLabel', 'userLabel', 'timestampLabel'];

    //hide these filters when scope is in project
    if (this.currentScope !== this.SCOPE.PROJECT) {
      this.editConfigFilterInOrgScope();
    }

    this.actionsMenuItems = [
      {
        label: 'Archive',
        icon: 'fa fa-archive',
        visible: ({ rowData }) =>
          this.currentScope === this.SCOPE.PROJECT &&
          !this.deviceId &&
          !rowData.cleared &&
          !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openArchiveConfirmation([rowData]),
      },
      {
        label: 'Unarchive',
        icon: 'fa fa-boxes-packing',
        visible: ({ rowData }) =>
          this.currentScope === this.SCOPE.PROJECT &&
          !this.deviceId &&
          !!rowData.cleared &&
          !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openUnarchiveConfirmation([rowData]),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];

    this.getData();

    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.assignFilters(!!values?.isFiltered ? values : {});
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getEvents();
      }
    });
  }

  editConfigFilterInOrgScope() {
    this.filterConfiguration.filters = {
      ...this.filterConfiguration.filters,
      4: {
        key: 'organization',
        label: ' Organizations ',
        type: 'dropdown',
        options: [],
      },
      5: {
        key: 'user',
        label: ' Users ',
        type: 'dropdown',
        options: [],
      },
    };
  }

  getData() {
    this.isLoading = true;
    forkJoin({
      categories: this.eventMonitorSrv.getEventCategories(),
      organizations: this.organizationSrv.getOrganizations(),
      users: this.userSrv.getUsers('', true),
    })
      .pipe(
        finalize(() => {
          // get events
          this.getEvents();
        }),
      )
      .subscribe({
        next: (res) => {
          // set categories & sub categories
          const events = res?.categories?.all_events;
          const categories: any[] = [];
          const subCategories: any[] = [];
          Object.keys(events).forEach((key1: any) => {
            const subEvents = events[key1].events;
            if (subEvents) {
              categories.push({ value: key1, label: events[key1].label });
              this.filterConfiguration?.filters?.[1]?.options?.push({ value: key1, label: events[key1].label });
              Object.keys(subEvents).forEach((key2: any) => {
                subCategories.push({
                  value: key2,
                  label: subEvents[key2],
                });
              });
            }
          });
          if (this.filterConfiguration?.filters?.[1]) {
            this.filterConfiguration.filters[1].options = this.util.sortObjectArray(categories, 'label');
            this.categories = this.filterConfiguration.filters[1].options;
          }
          this.subCategories = subCategories;

          //set organizations
          const organizations = ((res.organizations as any[]) || []).map((organization) => ({
            value: organization.id,
            label: organization.name,
          }));
          this.organizations = organizations;

          //set users
          const users = ((res.users as any[]) || []).map((user) => ({ value: user.id, label: user.username }));
          this.users = users;

          //set organizations and users options in Filter
          if (this.currentScope !== this.SCOPE.PROJECT) {
            if (!!this.filterConfiguration?.filters) {
              if (!!this.filterConfiguration.filters?.[4]) {
                this.filterConfiguration.filters[4].options = [...organizations];
              }
              if (!!this.filterConfiguration.filters?.[5]) {
                this.filterConfiguration.filters[5].options = [...users];
              }
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  assignFilters(value: any) {
    this.filters = {
      ...(value?.filter || {}),
      search: value?.search || null,
      startDate: value?.filter?.time?.from || null,
      endDate: value?.filter?.time?.to || null,
    };
  }

  getEventRequest(page?: number, perPage?: number, sortField?: string, sortOrder?: number) {
    const request: Observable<any> = this.eventMonitorSrv.getEvents({
      organizationId: this.breadcrumbConfig?.organizationId || this.filters.organization,
      projectId: this.breadcrumbConfig?.projectId,
      deviceIds: !!this.deviceId ? [this.deviceId] : undefined,
      page: page,
      perPage: perPage,
      cleared: this.isCleared,
      userId: this.filters.user,
      category: this.filters.category as any,
      criticality: this.filters.criticality as any,
      search: this.filters.search,
      from: this.filters.startDate as any,
      to: this.filters.endDate as any,
      sortField: !!sortField ? FIELDS[sortField]?.toLowerCase() || sortField : undefined,
      reverse: sortOrder ? sortOrder === 1 : true,
    });
    return request;
  }

  getEvents(resetSelectedEvents = true, event?: LazyLoadEvent) {
    this.isLoading = true;
    if (!!event) {
      this.lazyLoadEvent = event;
    }
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = this.lazyLoadEvent?.rows || PER_PAGE;
    this.getEventRequest(page, perPage, this.dt?.datatable?.sortField || undefined, this.dt?.datatable?.sortOrder)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_records;
          this.values = this.getMappedEvents(res?.event_logs);

          if (!!resetSelectedEvents) {
            this.selectedEvents = [];
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getMappedEvents(events: any[]) {
    return ((events as any[]) || [])?.map((eventData) => ({
      ...eventData,
      eventLabel: this.subCategories?.find((p) => p.value === eventData.event)?.label || eventData.event,
      categoryLabel: this.categories?.find((p) => p.value === eventData.category)?.label || eventData.category,
      details: eventData?.payload?.title || '',
      criticalityLabel: EVENT_CRITICALITIES.find((p) => p.value === eventData.criticality)?.label || eventData.criticality,
      organizationLabel: this.organizations?.find((p) => p.value === eventData.org_id)?.label || `Unknown Organization ${eventData.org_id}`,
      userLabel:
        this.users?.find((p) => p.value == eventData.user_id)?.label ||
        (eventData.user_id === '0' || eventData.user_id === 0 || eventData.user_id === 'None'
          ? 'System User / Unknown'
          : `Unknown User ${eventData.user_id}`),
      timestampLabel: this.momentPipe?.transform(eventData?.timestamp),
      payload: {
        ...eventData?.payload,
        details: (eventData?.payload?.details || '').replace(/\n/g, '<br />'),
      },
    }));
  }

  updateEvents(events: any[], payload: any, showMessage = true) {
    this.isLoading = true;
    const requests: Observable<any>[] = events.map((event) => this.eventMonitorSrv.updateEvent(event.id, payload));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          if (showMessage) {
            this.showSuccessMessage('Event(s) Updated Successfully');
          }
          if (!!this.selectedEvents?.length) {
            this.selectedEvents = [];
          }
          if (!!this.selectedEvent) {
            this.selectedEvent = null;
          }
          this.getEvents();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  deleteEvents(events: any[]) {
    this.isLoading = true;
    const requests: Observable<any>[] = events.map((event) => this.eventMonitorSrv.deleteEvent(event.id));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Deleted event(s) successfully`);
          if (!!this.selectedEvents?.length) {
            this.selectedEvents = [];
          }
          if (!!this.selectedEvent) {
            const eventIds = events.map((event) => event.id) || [];
            this.selectedEvent = !!eventIds.includes(this.selectedEvent.threat_id) ? null : this.selectedEvent;
          }
          this.getEvents();
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const filename =
          this.currentScope === this.SCOPE.PROJECT
            ? !!this.deviceId
              ? `device_${this.deviceId}_events`
              : !!this.isCleared
                ? `project_${this.breadcrumbConfig?.projectId}_archieved_events`
                : `project_${this.breadcrumbConfig?.projectId}_events`
            : 'system_events';
        if (specification.pageOption === 'current') {
          this.dt.generateReportDialog.exportReport(this.values, filename);
        } else {
          const requests: Observable<any>[] =
            specification.pageOption === 'custom'
              ? this.util
                  .getSequenceInteger(specification.customPages?.from as any, specification.customPages?.to as any)
                  .map((page) => this.getEventRequest(page, this.lazyLoadEvent?.rows || PER_PAGE))
              : [this.getEventRequest(1, this.totalRecords)];
          forkJoin(requests).subscribe({
            next: (res) => {
              const data: any[] = [];
              res.forEach((events) => {
                data.push(...(events?.event_logs || []));
              });
              this.dt.generateReportDialog.exportReport(this.getMappedEvents(data), filename);
            },
            error: () => {
              this.showErrorMessage('Error while getting all events data');
              this.dt.generateReportDialog.onClose();
            },
          });
        }
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, []);
  }

  openArchiveConfirmation(events: any[] = []) {
    const eventList = events?.map((event) => `<li>${`Event ID ${event.id} : ${event.eventLabel ? event.eventLabel : '-'}`}</li>`) || [];
    this.confirm({
      action: 'Archive',
      objectName: 'Event(s)',
      customContent: `Are you sure you want to archived ${
        !events?.length ? 'all events?<br/><br/>' : `the following event(s)?<ul>${eventList.join('')}</ul>`
      }Archived events will be moved to Archived Tab.`,
      next: () => {
        const payload = { marked_as_read: true, cleared: true };
        if (!events?.length) {
          this.getEventRequest(1, this.totalRecords)
            .pipe()
            .subscribe({
              next: (res) => {
                const allEvents = (res?.event_logs as any[]) || [];
                this.updateEvents(allEvents, payload);
              },
              error: () => {
                this.showErrorMessage('Error while getting all events data');
                this.isLoading = false;
              },
            });
        } else {
          this.updateEvents(events, payload);
        }
      },
    });
  }

  openUnarchiveConfirmation(events: any[] = []) {
    const eventList = events.map((event) => `<li>${`Event ID ${event.id} : ${event.eventLabel ? event.eventLabel : '-'}`}</li>`) || [];
    this.confirm({
      action: 'Unarchive',
      objectName: 'Event(s)',
      customContent: `Are you sure you want to unarchive ${
        !events?.length ? 'all events?<br/><br/>' : `the following event(s)?<ul>${eventList.join('')}</ul>`
      }Unarchived events will be moved to Details Tab.`,
      next: () => {
        const payload = { marked_as_read: true, cleared: false };
        if (!events?.length) {
          this.getEventRequest(1, this.totalRecords)
            .pipe()
            .subscribe({
              next: (res) => {
                const allEvents = (res?.event_logs as any[]) || [];
                this.updateEvents(allEvents, payload);
              },
              error: () => {
                this.showErrorMessage('Error while getting all events data');
                this.isLoading = false;
              },
            });
        } else {
          this.updateEvents(events, payload);
        }
      },
    });
  }

  openDeleteConfirmation(events: any[] = []) {
    const eventList = events.map((event) => `<li>${`Event ID ${event.id} : ${event.eventLabel ? event.eventLabel : '-'}`}</li>`) || [];
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Event(s)',
      customContent: `Are you sure you want to delete ${
        !events?.length ? 'all events?<br/><br/>' : `the following event(s)?<ul>${eventList.join('')}</ul>`
      }`,
      next: () => {
        if (!events?.length) {
          this.getEventRequest(1, this.totalRecords)
            .pipe()
            .subscribe({
              next: (res) => {
                const allEvents = (res?.event_logs as any[]) || [];
                this.deleteEvents(allEvents);
              },
              error: () => {
                this.showErrorMessage('Error while getting all events data');
                this.isLoading = false;
              },
            });
        } else {
          this.deleteEvents(events);
        }
      },
    });
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedEvent = null;
  }
}
