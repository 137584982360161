<div class="grid">
  @if (!entity) {
    <label class="col-12 font-bold text-data"> Attachments </label>
    <div class="col-12 text-value text-base">To enable attachment uploading for this {{ entityType }}, click on "Deploy Changes".</div>
  }

  <div class="col-12" [ngStyle]="{ opacity: !!entity ? 1 : 0 }">
    <microsec-form-builder
      #fb
      [isPage]="true"
      [shouldShowActionButtons]="false"
      [fields]="fields"
      [hasInfoFields]="false"
      [shouldSubmitOnEnter]="false" />
  </div>
</div>
