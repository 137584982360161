<div class="nme-right-navigation-wrapper flex flex-column">
  @if (!isEntitySelected) {
    <!-- DIAGRAM -->
    <app-nme-diagram-config [editor]="editor" />
  } @else {
    <!-- OBJECT -->
    <div class="w-full">
      <p-tabView styleClass="microsec-dashboard-tab" [(activeIndex)]="activeTabIndex" [scrollable]="true">
        <p-tabPanel [tabIndex]="0" header="Style" [disabled]="!selection.zone">
          @if (entityType === EDITOR_OBJECT_TYPES.ZONE) {
            <app-nme-style #styleComp [editor]="editor" />
          }
        </p-tabPanel>
        <p-tabPanel [tabIndex]="1" header="Text" [disabled]="true" />
        <p-tabPanel [tabIndex]="2" header="Arrange">
          <app-nme-arrange [editor]="editor" />
        </p-tabPanel>
        <p-tabPanel [tabIndex]="3" header="Properties">
          <app-nme-properties #propertiesComp [editor]="editor" [entityType]="entityType" [entity]="entity" />
        </p-tabPanel>
        <p-tabPanel [tabIndex]="4" header="Attachments">
          <app-nme-attachments [entityType]="entityType" [entity]="entity" (refreshSelectionEvent)="getData()" />
        </p-tabPanel>
      </p-tabView>
    </div>
  }
</div>
