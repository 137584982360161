import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@ids-shared';
import { SharedOrganizationFormModule } from '../shared-components/shared-organization-form/shared-organization-form.module';
import { SharedUserAccessModule } from '../shared-components/shared-user-access/shared-user-access.module';
import { SharedProjectFormModule } from '../shared-components/shared-project-form/shared-project-form.module';
import { SharedLicenseWarningModule } from '../shared-components/shared-license-warning/shared-license-warning.module';

import { OrganizationManagementComponent } from './organization-management.component';
import { ProjectsComponent } from './projects/projects.component';
import { UserAccessComponent } from './user-access/user-access.component';
import { OrganizationInformationComponent } from './organization-information/organization-information.component';
import { OrganizationSnortSettingsComponent } from './organization-snort-settings/organization-snort-settings.component';
import { SharedSnortSettingsModule } from '../shared-components/shared-snort-settings/shared-snort-settings.module';

@NgModule({
  declarations: [
    OrganizationManagementComponent,
    ProjectsComponent,
    UserAccessComponent,
    OrganizationInformationComponent,
    OrganizationSnortSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedOrganizationFormModule,
    SharedUserAccessModule,
    SharedProjectFormModule,
    SharedLicenseWarningModule,
    SharedSnortSettingsModule,
  ],
})
export class OrganizationManagementModule {}
