import { Component, OnInit } from '@angular/core';
import { ReportService } from '@ids-services';
import { BaseComponent } from '@microsec/components';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss'],
})
export class ReportPreviewComponent extends BaseComponent implements OnInit {
  isLoading = false;

  report: any = null;

  pdfBlob: any = null;

  pdfBuffer: any = null;

  downloadReportMenuOptions: MenuItem[] = [
    {
      label: 'PDF',
      icon: 'fas fa-file-pdf',
      command: () => {
        if (this.pdfBlob) {
          this.showSuccessMessage(`Downloaded ${this.report.name} successfully`);
          this.util.downloadClientFile(`${(this.report.name || '').split(' ').join('_')}.pdf`, this.pdfBlob);
        } else {
          this.downloadReport('pdf');
        }
      },
    },
    {
      label: 'Docx',
      icon: 'fas fa-file-word',
      command: () => this.downloadReport('docx'),
    },
  ];

  constructor(
    private reportSrv: ReportService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.report = this.dialogConfig?.data?.report;
    this.downloadReport('pdf', true);
  }

  downloadReport(type = 'pdf', isPreview = false) {
    if (this.report) {
      this.isLoading = true;
      this.reportSrv
        .downloadReport(this.report.id, type)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: async (res) => {
            if (!isPreview) {
              this.showSuccessMessage(`Downloaded ${this.report.name} successfully`);
              this.util.downloadClientFile(`${(this.report.name || '').split(' ').join('_')}.${type}`, res);
            } else {
              this.setSrc(res);
            }
          },
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  async setSrc(res: any) {
    this.pdfBlob = res?.body || res;
    const arr = new Uint8Array(await res?.body.arrayBuffer());
    this.pdfBuffer = arr;
  }
}
