import { ILayoutItem } from '@microsec/interfaces';

import { NetworksComponent } from './networks/networks.component';

export const NETWORK_SETTINGS_CONSTANTS = {
  NETWORKS: {
    ROUTE: 'networks',
    LABEL: 'Networks',
  },
};

export const NETWORK_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: NETWORK_SETTINGS_CONSTANTS.NETWORKS.ROUTE,
  },
  {
    route: NETWORK_SETTINGS_CONSTANTS.NETWORKS.ROUTE,
    label: NETWORK_SETTINGS_CONSTANTS.NETWORKS.LABEL,
    component: NetworksComponent,
  },
];
