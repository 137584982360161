import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ILayoutItem } from '@microsec/interfaces';
import { USER_ROLE } from '@microsec/constants';

import { THREATS_CHILDREN } from './threats.config';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { THREAT_FEATURES } from '@ids-constants';

@Component({
  selector: 'app-threats',
  templateUrl: './threats.component.html',
  styleUrls: ['./threats.component.scss'],
})
export class ThreatsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkThreatFeatureEnabled(THREAT_FEATURES.ENABLED)) {
      if (this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
        const tabItems: ILayoutItem[] = this.util.cloneObjectArray(THREATS_CHILDREN).filter((p) => !!p.label);
        this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      }
    } else {
      this.navigateToNotFoundPage();
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
