/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import analyzersData from './mock-data/analyzers.json';
import mlAnalyzersData from './mock-data/ml-analyzers.json';
import flAnalyzersData from './mock-data/fl-analyzers.json';
import nonipAnalyzersData from './mock-data/nonip-analyzers.json';
import customRulesData from './mock-data/custom-rules.json';
import suppressedRulesData from './mock-data/suppressed-rules.json';
import whitelistsData from './mock-data/whitelists.json';
import modelData from './mock-data/model.json';

import { asyncScheduler, BehaviorSubject, Observable, scheduled } from 'rxjs';

import { ANALYZER_DATA_TYPES, ANALYZER_TYPES, DISPLAY, PARAM_NAMES } from '@ids-constants';
import { IAnomalyAnalyzerService } from './IAnomalyAnalyzerService';

@Injectable({
  providedIn: 'root',
})
export class MockAnomalyAnalyzerService implements IAnomalyAnalyzerService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected: any = {};

  // Helper function to display Analyzer type
  getDisplayType(type?: string, item?: any): string {
    //type is rule-based or ML
    const itemAnalyzerType = item[PARAM_NAMES.ANALYZER_TYPE] || null;
    const itemDataType = item[PARAM_NAMES.DATA_TYPE] || null;
    switch (type) {
      case ANALYZER_TYPES.RULE_BASED:
        return itemAnalyzerType === ANALYZER_TYPES.RULE_BASED_NONIP
          ? DISPLAY.NONIP
          : itemAnalyzerType === ANALYZER_TYPES.RULE_BASED && itemDataType === ANALYZER_DATA_TYPES.PAYLOAD
            ? DISPLAY.MQTT
            : itemAnalyzerType === ANALYZER_TYPES.RULE_BASED && itemDataType === ANALYZER_DATA_TYPES.PACKET
              ? DISPLAY.IP
              : '-';
      case ANALYZER_TYPES.ML:
        return itemDataType === ANALYZER_DATA_TYPES.PAYLOAD ? DISPLAY.ML_MQTT : itemDataType === ANALYZER_DATA_TYPES.PACKET ? DISPLAY.ML_OTHERS : '-';
      default:
        return '-';
    }
  }

  getAnalyzers(projectId?: any, enabled?: boolean | null, mode?: string, analyzer_type?: string, data_type?: string): Observable<any> {
    return analyzer_type === ANALYZER_TYPES.ML
      ? scheduled([mlAnalyzersData], asyncScheduler)
      : analyzer_type === ANALYZER_TYPES.RULE_BASED
        ? scheduled([analyzersData], asyncScheduler)
        : analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP
          ? scheduled([nonipAnalyzersData], asyncScheduler)
          : scheduled([analyzersData], asyncScheduler);
  }

  getAnalyzer(id: any, analyzer_type?: string): Observable<any> {
    let analyzer: any = null;
    if (analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP) {
      analyzer = (nonipAnalyzersData.analyzers as any[]).find((analyzer) => analyzer.id === id);
    } else {
      analyzer = (analyzersData.analyzers as any[]).find((analyzer) => analyzer.id === id);
    }
    return scheduled([analyzer], asyncScheduler);
  }

  createAnalyzer(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateAnalyzer(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateNonipAnalyzerStatus(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteAnalyzer(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getAnalyzerCustomRules(analyzerId: any): Observable<any> {
    return scheduled([customRulesData], asyncScheduler);
  }

  createAnalyzerCustomRule(analyzerId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateAnalyzerCustomRule(analyzerId: any, id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteAnalyzerCustomRule(analyzerId: any, ids: any[]): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getAnalyzerSuppressedRules(analyzerId: any): Observable<any> {
    return scheduled([suppressedRulesData], asyncScheduler);
  }

  createAnalyzerSuppressedRule(analyzerId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateAnalyzerSuppressedRule(analyzerId: any, id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteAnalyzerSuppressedRule(analyzerId: any, ids: any[]): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getAnalyzerWhitelists(analyzerId: any): Observable<any> {
    return scheduled([whitelistsData], asyncScheduler);
  }

  createAnalyzerWhitelist(analyzerId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateAnalyzerWhitelist(analyzerId: any, id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteAnalyzerWhitelist(analyzerId: any, ids: any[]): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getFlAnalyzers(projectId?: any, enabled?: boolean): Observable<any> {
    return scheduled([flAnalyzersData], asyncScheduler);
  }

  getFlAnalyzer(id: any): Observable<any> {
    const analyzer: any = (flAnalyzersData.fl_analyzers as any[]).find((analyzer) => analyzer.id === id);
    return scheduled([analyzer], asyncScheduler);
  }

  createFlAnalyzer(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateFlAnalyzer(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteFlAnalyzer(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getModel(id: any): Observable<any> {
    return scheduled([modelData], asyncScheduler);
  }

  createModel(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateModel(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  deleteModel(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getOinkcodes(scope?: string | undefined, organizationId?: any, projectId?: any, isValidated?: boolean | undefined): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getOinkcode(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  createOinkcode(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  updateOinkcode(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  validateOinkcode(oinkcode: string): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
