<div class="grid">
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)"></microsec-common-toolbar>
  </div>

  <!-- Data table -->
  <div class="col-12">
    <microsec-card [isNestedCard]="true" [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        [cellTemplate]="cellTemplate"
        itemName="Device"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <ng-template #containerHeaderRightTemplate>
    <div class="flex flex-wrap align-items-center gap-2">
      <microsec-table-column-filter
        [key]="constructor.name"
        [cols]="cols || []"
        [(selectedColFields)]="selectedColFields"
        (exportReportEvent)="openGenerateReportDialog()">
      </microsec-table-column-filter>
    </div>
  </ng-template>
</div>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- IP Addresses -->
    <ng-container *ngSwitchCase="'src_ip_addr'">
      <span [pTooltip]="!!rowData[col.field] ? rowData[col.field].tooltip : ''" tooltipPosition="top" appendTo="body">
        {{ !!rowData[col.field] ? rowData[col.field].label : '-' }}
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
