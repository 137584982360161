import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { INTERFACE_TYPE_OPTIONS } from '@ids-constants';
import { ConnectionService, TargetDeviceService } from '@ids-services';
import { BaseComponent, CommonTableComponent } from '@microsec/components';
import { PER_PAGE } from '@microsec/constants';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, finalize } from 'rxjs';

const FIELDS = {
  id: 'ID',
  label: 'Name',
  src_mac_addr: 'MAC Address',
  src_ip_addr: 'IP Addresses',
  zones: 'Zones',
};

const COLS = [
  { field: 'label', header: FIELDS.label, width: 7 },
  { field: 'src_mac_addr', header: FIELDS.src_mac_addr, width: 5 },
  { field: 'src_ip_addr', header: FIELDS.src_ip_addr, width: 5 },
  { field: 'zones', header: FIELDS.zones, width: 5 },
];

@Component({
  selector: 'app-nme-import-devices-form',
  templateUrl: './nme-import-devices-form.component.html',
  styleUrls: ['./nme-import-devices-form.component.scss'],
})
export class NmeImportDevicesFormComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  COLS = COLS;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'filter'],
    searchPlaceholder: 'Search Device Name, Source IP Address (CIDR supported) or MAC Address...',
    filters: {
      1: {
        key: 'usesAgent',
        label: 'Agent/Agentless',
        type: 'dropdown',
        options: [
          { label: 'Agent', value: true },
          { label: 'Agentless', value: false },
        ],
      },
      2: {
        key: 'connections',
        label: 'Connections',
        type: 'multiselect',
        options: [],
      },
      3: {
        key: 'createdDates',
        label: 'Created Date',
        type: 'date-range',
      },
      4: {
        key: 'criticality',
        label: 'Criticality',
        type: 'slider',
      },
      5: {
        key: 'isImported',
        label: 'Import Status',
        type: 'dropdown',
        options: [
          { label: 'Imported', value: true },
          { label: 'Not Imported', value: false },
        ],
      },
      6: {
        key: 'interfaces',
        label: 'Interface Types',
        type: 'multiselect',
        options: INTERFACE_TYPE_OPTIONS,
      },
      7: {
        key: 'lastSeenDates',
        label: 'Last Seen',
        type: 'date-range',
      },
      8: {
        key: 'tags',
        label: 'Tags',
        type: 'multiselect',
        options: [],
      },
      9: {
        key: 'zones',
        label: 'Zones',
        type: 'multiselect',
        options: [],
      },
    },
  };

  currentPage = 1;

  totalRecords = 0;

  values: any[] = [];

  connections: any[] = [];

  links: any[] = [];

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = COLS.filter((col) => value?.includes(col.field));
  }

  selectedDevices: any[] = [];

  importedDevices: any[] = [];

  filters: {
    [key: string]: any;
  } = {};

  PER_PAGE = PER_PAGE;

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private connectionSrv: ConnectionService,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.importedDevices = this.dialogConfig?.data?.importedDevices;
    this.selectedColFields = ['label', 'src_mac_addr', 'src_ip_addr', 'zones'];
    this.getData();
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.assignFilters(!!values?.isFiltered ? values : {});
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getDevices();
      }
    });
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.targetDeviceSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getDevices();
      }
      this.getTags();
      this.getZones();
    });
    this.subscriptions.push(subscription);
  }

  getData() {
    this.getDevices();
    this.getConnections();
    this.getLinks();
    this.getTags();
    this.getZones();
  }

  onDeviceChangeEvent(device: any) {
    if (!!device) {
      const deviceIndex = this.values.findIndex((value) => value.id === device.id);
      this.values[deviceIndex] = {
        ...device,
        src_mac_addr: this.getMacAddress(device.eth),
        src_ip_addr: this.getIpAddress(device.ip),
      };
    }
  }

  assignFilters(value: any) {
    this.filters = {
      ...(value?.filter || {}),
      search: value?.search || null,
      createdFrom: value?.filter?.createdDates?.from || null,
      createdTo: value?.filter?.createdDates?.to || null,
      lastSeenFrom: value?.filter?.lastSeenDates?.from || null,
      lastSeenTo: value?.filter?.lastSeenDates?.to || null,
    };
  }

  getDevicesRequest(page?: number, perPage?: number, sortField?: string, sortOrder?: number) {
    const request: Observable<any> = this.targetDeviceSrv.getDevices({
      organizationId: this.breadcrumbConfig?.organizationId,
      projectId: this.breadcrumbConfig?.projectId,
      page,
      perPage,
      sort: sortField === 'connection_id' ? 'connection' : sortField,
      reverse: sortOrder ? sortOrder === 1 : true,
      ...(this.filters || {}),
      detailed: true,
      connectionIds: this.filters?.['connections'],
      interfaceTypes: this.filters?.['interfaces'],
      tagIds: this.filters?.['tags'],
      zoneIds: this.filters?.['zones'],
      createdFrom: this.filters?.['createdFrom'] ? (moment(this.filters?.['createdFrom']).toISOString() as any) : null,
      createdTo: this.filters?.['createdTo'] ? (moment(this.filters?.['createdTo']).toISOString() as any) : null,
      lastSeenFrom: this.filters?.['lastSeenFrom'] ? (moment(this.filters?.['lastSeenFrom']).toISOString() as any) : null,
      lastSeenTo: this.filters?.['lastSeenTo'] ? (moment(this.filters?.['lastSeenTo']).toISOString() as any) : null,
      isMulticast: 'false',
    });
    return request;
  }

  getDevices(resetSelectedDevices = true, event?: LazyLoadEvent) {
    this.isLoading = true;
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.getDevicesRequest(page, perPage, this.dt?.datatable?.sortField as any, this.dt?.datatable?.sortOrder)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          if (!!this.targetDeviceSrv.selected) {
            this.targetDeviceSrv.selected = null;
          }
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          const devices = ((res?.devices as any[]) || []).map((device) => ({
            ...device,
            disabled: !!this.importedDevices?.includes(device.id),
            src_mac_addr: this.getMacAddress(device.eth),
            src_ip_addr: this.getIpAddress(device.ip),
          }));
          this.values = devices;

          if (!!resetSelectedDevices) {
            this.selectedDevices = [];
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.selectedDevices = [];
        },
      });
  }

  getConnections() {
    this.isLoading = true;
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.connections = res?.data;
          const connectionOptions = (res.data as any[] | []).map((con: { name: any; id: any }) => ({
            label: con.name || con.id,
            value: con.id,
          }));
          if (!!this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].options = connectionOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getLinks() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getLinks()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.links = res;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  /**
   * Submit
   * @param isSubmitted
   */
  submit(isSubmitted = false) {
    if (!!isSubmitted) {
      this.dialogRef.close({
        connections: this.connections,
        links: this.links,
        devices:
          this.selectedDevices.map((device) => {
            ((device.zones as any[]) || []).forEach((zone) => {
              zone.zone_id = zone.id;
              delete zone.id;
            });
            return device;
          }) || [],
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  getTags() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const tagOptions = (res.tags as any[] | []).map((tag) => ({
            label: tag.label,
            value: tag.id,
          }));
          if (!!this.filterConfiguration?.filters?.[8]) {
            this.filterConfiguration.filters[8].options = tagOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getZones() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getZones(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const zoneOptions = (res.zones as any[] | []).map((zone) => ({
            label: zone.label,
            value: zone.id,
          }));
          if (!!this.filterConfiguration?.filters?.[9]) {
            this.filterConfiguration.filters[9].options = zoneOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getMacAddress(ethValue: any) {
    return !!ethValue ? Object.keys(ethValue)?.[0] || '-' : '-';
  }

  getIpAddress(ipValue: any) {
    const ips = !!ipValue ? Object.keys(ipValue) : [];
    return {
      label: !!ips.length ? Object.keys(ipValue)?.[0] + (ips.length > 1 ? '...' : '') : '-',
      tooltip: !!ips.length ? ips.join(', ') : '',
    };
  }

  override ngOnDestroy() {
    this.cleanup();
    this.targetDeviceSrv.refresh$.next(null);
  }
}
