export class NetworkMapCommon {
  // circle base
  static readonly circleRadius = 21;
  static readonly circleGrowRadius = 0.5;

  // text
  static readonly textColor = '#eeeeee';

  // fill
  static readonly circleFillColor = 'url(#circleGradientFill)';
  static readonly circleHoverFillColor = '#343434';
  static readonly circleNormalClickedFillColor = '#659BB9';
  static readonly circleAnomalousClickedFillColor = '#D64B54';

  static readonly circleSelectedColor = '#171717';

  // stroke
  static readonly normalStrokeColor = '#222222';
  static readonly anomalousStrokeColor = 'url(#anomalousGradientStroke)';

  static readonly normalHoverStrokeColor = 'url(#normalHoverGradientStroke)';
  static readonly anomalousHoverStrokeColor = 'url(#anomalousHoverGradientStroke)';

  static readonly normalClickedStrokeColor = '#659BB9';
  static readonly anomalousClickedStrokeColor = '#D64B54';

  static readonly searchedStrokeColor = '#CAC673';

  // link
  static readonly linkColor = '#444444';
  static readonly linkHoverColor = '#666666';
  static readonly linkClickedColor = '#eeeeee';
  static readonly linkNormalClickedColor = '#659BB9';
  static readonly linkAnomalousClickedColor = '#D64B54';

  /**
   * Define styles for diagrams
   */
  static defineStyles(svg: any) {
    // gradient fill
    const circleGradientFill = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'circleGradientFill')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    circleGradientFill.append('stop').attr('offset', '0%').attr('stop-color', '#444444');
    circleGradientFill.append('stop').attr('offset', '100%').attr('stop-color', '#222222');

    /** ---------- NORMAL NODE ----------  */

    // hover gradient stroke
    const normalGradientStroke = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'normalHoverGradientStroke')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    normalGradientStroke.append('stop').attr('offset', '0%').attr('stop-color', '#659BB9');
    normalGradientStroke.append('stop').attr('offset', '100%').attr('stop-color', '#25485B');

    /** ---------- ANOMALOUS NODE ----------  */

    // gradient stroke
    const anomalousGradientStroke = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'anomalousGradientStroke')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    anomalousGradientStroke.append('stop').attr('offset', '0%').attr('stop-color', '#fb4d58').attr('stop-opacity', '0.3');
    anomalousGradientStroke.append('stop').attr('offset', '100%').attr('stop-color', '#6c2e32').attr('stop-opacity', '0.3');

    // hover gradient stroke
    const hoverAnomalousGradientStroke = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'anomalousHoverGradientStroke')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    hoverAnomalousGradientStroke.append('stop').attr('offset', '0%').attr('stop-color', '#FB4D58');
    hoverAnomalousGradientStroke.append('stop').attr('offset', '100%').attr('stop-color', '#6C2E32');
  }
}
