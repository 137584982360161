import { Component, EventEmitter, Input, Output } from '@angular/core';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-verification-codes',
  templateUrl: './verification-codes.component.html',
  styleUrls: ['./verification-codes.component.scss'],
})
export class VerificationCodesComponent {
  @Input() codes: string[] = [];

  @Output() changeStepEvent: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Download PDF
   */
  downloadPdf() {
    const doc = new jsPDF();
    const x = 10;

    doc.setFontSize(18);
    doc.text('Microsec Dashboard', x, 10);

    doc.setFontSize(13);
    doc.setTextColor(150);
    doc.text('Backup Verification Codes', x, 15);
    doc.text('With 2FA enabled, you will need these backup verification codes in case you lose your device.', x, 25);

    let y = 40;
    this.codes.forEach((code) => {
      doc.setTextColor(100);
      doc.setFontSize(13);
      doc.text(code, x, y);
      y += 10;
    });
    doc.save('Microsec_dashboard_backup_verification_codes.pdf');
  }

  /**
   * Change step
   * @param step
   */
  changeStep(step = 1) {
    this.changeStepEvent.emit(step);
  }
}
