import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { SharedTestConfigurationDialogComponent } from '../../../../../../shared-components/shared-test-configuration-dialog/shared-test-configuration-dialog.component';
import { NetworkDeviceService } from '@ids-services';

import { finalize } from 'rxjs';

@Component({
  selector: 'app-network-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.scss'],
})
export class DeviceOverviewComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _device: any = null;

  get device() {
    return this._device;
  }

  @Input() set device(value: any) {
    this._device = value;
    if (!!this._device) {
      this.config = this._device.configs?.[0] || null;
    }
  }

  config: any = null;

  @ViewChild('testConfigurationDialog') testConfigurationDialog!: SharedTestConfigurationDialogComponent;

  constructor(private networkDeviceSrv: NetworkDeviceService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
  }

  testConfiguration(config: any) {
    this.isLoading = true;
    this.networkDeviceSrv
      .testDeviceConfig(config.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Tested configuration succesfully`);
          this.networkDeviceSrv.refresh$.next(true);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }
}
