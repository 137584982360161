import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PER_PAGE } from '@microsec/constants';
import { NETWORK_MAP_SETTING_VALUES } from '@ids-constants';
import { ATTACK_TYPE_OPTIONS, THREAT_SCORE_OPTIONS, THREAT_STATUSES, THREAT_STATUS_OPTIONS, THREAT_TYPE_OPTIONS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { CommonToolbarComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ConnectionService, TargetDeviceService, ThreatService } from '@ids-services';

import { BehaviorSubject, Observable, finalize } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-network-map-threat-list',
  templateUrl: './threat-list.component.html',
  styleUrls: ['./threat-list.component.scss'],
})
export class ThreatListComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'sort', 'filter'],
    searchPlaceholder: 'Search Threat ID, Snort Rule ID, MITRE Technique ID, CVE ID, Payload Details, Comment or Description...',
    showTotalRecords: true,
    totalRecordsIcon: 'fa fa-skull-crossbones',
    sorts: [
      { key: 'threat_id', label: 'Threat ID' },
      { key: 'threat_score', label: 'Threat Score' },
      { key: 'attack_type', label: 'Attack Type' },
      { key: 'status', label: 'Status' },
      { key: 'created', label: 'Created Date' },
      { key: 'updated', label: 'Updated Date' },
    ],
    filters: {
      1: {
        key: 'attackTypes',
        label: 'Attack Type',
        type: 'multiselect',
        options: ATTACK_TYPE_OPTIONS,
      },
      2: {
        key: 'connections',
        label: 'Connection',
        type: 'multiselect',
        options: [],
      },
      3: {
        key: 'devices',
        label: 'Device',
        type: 'multiselect',
        options: [],
      },
      4: {
        key: 'createdDates',
        label: 'Created Date',
        type: 'date-range',
      },
      5: {
        key: 'isImported',
        label: 'Import Status',
        type: 'dropdown',
        options: [
          { label: 'Imported', value: true },
          { label: 'Not Imported', value: false },
        ],
      },
      6: {
        key: 'statuses',
        label: 'Status',
        defaultValue: [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING],
        type: 'multiselect',
        options: THREAT_STATUS_OPTIONS,
      },
      7: {
        key: 'threatScores',
        label: 'Threat Score',
        type: 'multiselect',
        options: THREAT_SCORE_OPTIONS,
      },
      8: {
        key: 'threatTypes',
        label: 'Threat Type',
        type: 'multiselect',
        options: THREAT_TYPE_OPTIONS,
      },
      9: {
        key: 'updatedDates',
        label: 'Updated Date',
        type: 'date-range',
      },
    },
  };

  values: any[] = [];

  PER_PAGE = PER_PAGE;

  filterObject: CommonToolbarResult = {
    search: '',
    sort: null,
    filter: {},
    isFiltered: false,
    isSortReset: false,
  };

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  isMulticast: 'false' | 'true' | 'any' = 'false';

  @Input() settings: any[] = [];

  @Input() itemDetails$ = new BehaviorSubject<any>(null);

  @Input() diagramData$ = new BehaviorSubject<any>(null);

  @Input() settingOptionsObs = new Observable<any>();

  @Input() refreshObs = new Observable<any>();

  @Input() isItemDetailsDisplayed = false;

  @Output() changeSelectedDevice = new EventEmitter<number>();

  @ViewChild('ct') toolbar!: CommonToolbarComponent;

  constructor(
    private threatSrv: ThreatService,
    private targetDeviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.handleMulticastChange(this.settings);
    this.settingOptionsObs.subscribe((settings: any) => {
      this.handleMulticastChange(settings);
    });
    this.refreshObs.subscribe(() => {
      this.getThreats();
      this.getDevices();
      this.getConnections();
    });
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        this.filterObject = { ...result };
        this.getThreats();
      }
    });
  }

  // Multicast Setting Checkbox Change
  handleMulticastChange(settings: any) {
    //When it is first initialised set the property of isMulticast
    if (!!Array.isArray(settings)) {
      let multicastItem: any = null;
      settings.some((setting) => {
        multicastItem = setting.items.find((item: any) => item.value === NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST);
        return !!multicastItem;
      });
      this.isMulticast = !!multicastItem?.checked ? 'any' : 'false';
    }

    //single object update send by setting options when checkbox is toggled
    if (!Array.isArray(settings)) {
      if (settings.value === NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST) {
        this.isMulticast = settings.checked ? 'any' : 'false';
        //clear the filters in threat toolbar if any filters are applied
        this.toolbar.clearFilters();
        //close the details panel if it is open
        if (!!this.isItemDetailsDisplayed) {
          this.itemDetails$.next(null);
        }
        this.getDevices();
      }
    }
  }

  getThreats() {
    this.isLoading = true;
    this.threatSrv
      .getThreats(
        this.breadcrumbConfig?.projectId,
        this.filterObject.filter?.devices,
        undefined,
        undefined,
        this.filterObject.sort?.key,
        !!this.filterObject.sort?.direction ? this.filterObject.sort.direction === 'desc' : true,
        false,
        this.filterObject.filter?.statuses,
        this.filterObject.filter?.threatTypes,
        this.filterObject.search,
        this.filterObject.filter?.connections,
        this.filterObject.filter?.isImported,
        this.filterObject.filter?.attackTypes,
        !!this.filterObject.filter?.threatScores?.length
          ? THREAT_SCORE_OPTIONS.filter((item) => this.filterObject.filter?.threatScores.find((filter: any) => filter === item.value)).map(
              (param) => param.value,
            )
          : this.filterObject.filter?.threatScores,
        this.filterObject.filter?.createdDates?.from ? moment(this.filterObject.filter?.createdDates?.from).toISOString() || '' : undefined,
        this.filterObject.filter?.createdDates?.to ? moment(this.filterObject.filter?.createdDates?.to).toISOString() || '' : undefined,
        this.filterObject.filter?.updatedDates?.from ? moment(this.filterObject.filter?.updatedDates?.from).toISOString() || '' : undefined,
        this.filterObject.filter?.updatedDates?.to ? moment(this.filterObject.filter?.updatedDates?.to).toISOString() || '' : undefined,
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.filterConfiguration.totalRecords = res?.total_record || 0;
          const threats = ((res?.threats as any[]) || []).map((p) => ({
            ...p,
            attackTypeLabel: ATTACK_TYPE_OPTIONS.find((a) => a.value === p?.attack_type?.toLowerCase())?.label,
          }));
          this.values = threats;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getConnections() {
    this.isLoading = true;
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const connectionOptions = (res.data as any[] | []).map((con: { name: any; id: any }) => ({
            label: con.name || con.id,
            value: con.id,
          }));
          if (this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].options = connectionOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getDevices() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        reverse: false,
        detailed: false,
        isMulticast: this.isMulticast,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const deviceOptions = ((res?.devices as any[]) || []).map((device) => ({
            label: device.label || device.id,
            value: device.id,
          }));
          if (this.filterConfiguration?.filters?.[3]) {
            this.filterConfiguration.filters[3].options = deviceOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  changeSelectedItemDevice(value: any) {
    this.changeSelectedDevice.emit(value);
  }
}
