<div class="nme-left-navigation-wrapper flex flex-column">
  <!-- SEARCH -->
  <div class="py-2 px-3">
    <span class="w-full p-input-icon-right">
      <input type="text" pInputText placeholder="Search Device Types..." class="w-full" [(ngModel)]="searchText" (blur)="editor?.focusGraph()" />
      <i class="pi pi-search"></i>
    </span>
  </div>
  <!-- TABS -->
  <p-accordion [activeIndex]="[0, 1]" [multiple]="true" styleClass="nme-accordion" expandIcon="pi pi-caret-right" collapseIcon="pi pi-caret-down">
    @for (tab of tabs; track $index) {
      <p-accordionTab [header]="tab.label">
        <div class="flex flex-wrap">
          @for (item of tab.items; track $index) {
            <div [ngStyle]="{ width: '20%', display: !!isInSearch(item) ? 'inherit' : 'none' }">
              <div [id]="item.type + '-' + item.value + '-' + $index" class="draggable-item py-1">
                @if (item?.svgContent) {
                  <div class="flex" style="cursor: grab; padding-left: 5px; padding-right: 5px">
                    <div [pTooltip]="item?.label" tooltipPosition="bottom" appendTo="body" [innerHTML]="item.svgContent"></div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </p-accordionTab>
    }
  </p-accordion>
  <!-- IMPORT DEVICES -->
  <div class="flex w-full py-3 px-2 justify-content-center">
    @if ((editor?.totalNotImportedDeviceCount() || 0) <= 0) {
      <p-button icon="fa fa-plus" label="Import Devices" [rounded]="true" severity="primary" styleClass="capitalize" (onClick)="importDevices()" />
    } @else {
      <div pBadge severity="danger" [value]="'' + editor?.totalNotImportedDeviceCount()">
        <p-button icon="fa fa-plus" label="Import Devices" [rounded]="true" severity="primary" styleClass="capitalize" (onClick)="importDevices()" />
      </div>
    }
  </div>
</div>
