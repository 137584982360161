<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!assessmentType) {
    @if (!!isPreview) {
      <app-assessment-preview
        [isAssessmentType]="true"
        [assessment]="{ assessmentType: assessmentType }"
        (closeAssessmentTypePreviewEvent)="isPreview = false"></app-assessment-preview>
    } @else {
      <div class="assessment-type">
        <microsec-common-preview-layout [headerTemplate]="headerTemplate" [sidebarTemplate]="sidebarTemplate">
          <div class="body">
            @if (!!selectedSection && activeHeaderTabMenu.id === 'sections') {
              <div class="grid">
                <div class="col-12">
                  <div pTooltip="Click to edit" tooltipPosition="left" appendTo="body">
                    <textarea
                      class="no-border w-full text-3xl"
                      type="text"
                      [spellcheck]="false"
                      pInputTextarea
                      inputTrimRef
                      [autoResize]="true"
                      [rows]="1"
                      [(ngModel)]="selectedSection[FORM_PARAMS.NAME]"
                      placeholder="Enter Section Name..."
                      (keydown.enter)="$any($event)?.preventDefault()"
                      (change)="onUpdateSectionName()"></textarea>
                  </div>
                  <label class="text-error" *ngIf="!!selectedSection.nameError"> Section name is required </label>
                </div>
                <div class="col-12">
                  <div class="flex align-items-center justify-content-between gap-2">
                    <div class="flex align-items-center gap-3">
                      <p-button
                        icon="fas fa-plus"
                        label="Add New Question"
                        [disabled]="!selectedSection.id"
                        severity="secondary"
                        [rounded]="true"
                        styleClass="text-transform-none p-button-sm"
                        (onClick)="questionMenu.toggle($event)"></p-button>
                      <p-button
                        icon="fas fa-arrows-up-down"
                        label="Change Position"
                        severity="secondary"
                        [rounded]="true"
                        styleClass="text-transform-none p-button-sm"
                        (onClick)="openChangePositionInfo()"></p-button>
                      <p-menu
                        #questionMenu
                        styleClass="microsec-button-context-menu w-max"
                        [model]="questionMenuOptions"
                        [popup]="true"
                        appendTo="body"></p-menu>
                    </div>
                    <div>
                      <p-button
                        icon="fas fa-x"
                        label="Delete"
                        severity="danger"
                        size="small"
                        [rounded]="true"
                        styleClass="text-transform-none"
                        (onClick)="!!selectedQuestion ? deleteMenu.toggle($event) : openDeleteSectionConfirmation()"></p-button>
                      <p-menu
                        #deleteMenu
                        styleClass="microsec-button-context-menu w-max"
                        [model]="deleteMenuOptions"
                        [popup]="true"
                        appendTo="body"></p-menu>
                    </div>
                  </div>
                </div>
                <div class="mt-2 col-12" *ngIf="!!selectedQuestion">
                  <microsec-card [isBordered]="true">
                    <div class="grid">
                      <div class="col-12">
                        <label class="font-bold text-xl">
                          {{ selectedQuestion.question?.title
                          }}<microsec-asterisk *ngIf="!!selectedQuestion[FORM_PARAMS.MANDATORY]"></microsec-asterisk>
                        </label>
                      </div>
                      <div class="col-12" *ngIf="!!selectedQuestion.descriptionPreview">
                        <div
                          [ngClass]="{
                            'max-h-5rem overflow-hidden': !!selectedQuestion.hideDetailDescription,
                            'overflow-auto': !selectedQuestion.hideDetailDescription
                          }">
                          <div class="markdown-preview text-grey" [innerHTML]="selectedQuestion.descriptionPreview"></div>
                        </div>
                        <span class="mt-2 link" (click)="selectedQuestion.hideDetailDescription = !selectedQuestion.hideDetailDescription">
                          {{ !selectedQuestion.hideDetailDescription ? 'Show less details' : 'Show more details' }}
                          <i [class]="!selectedQuestion.hideDetailDescription ? 'pi pi-chevron-up ml-2' : 'pi pi-chevron-down ml-2'"> </i>
                        </span>
                      </div>
                      <div class="col-12" *ngIf="!!selectedQuestion.question">
                        <div class="mb-2">
                          <p-button
                            icon="fas fa-edit"
                            label="Edit Question"
                            severity="secondary"
                            size="small"
                            [rounded]="true"
                            styleClass="text-transform-none"
                            (onClick)="openAssessmentQuestionForm(selectedQuestion.question)"></p-button>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="flex gap-6">
                          <div class="flex flex-column gap-2">
                            <label class="font-bold">Set as mandatory<microsec-asterisk></microsec-asterisk></label>
                            <label class="text-grey">Set question to be compulsory or optional.</label>
                          </div>
                          <div>
                            <p-inputSwitch
                              [(ngModel)]="selectedQuestion[FORM_PARAMS.MANDATORY]"
                              (ngModelChange)="updateSection(FORM_PARAMS.ASSIGNED_QUESTIONS)"></p-inputSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </microsec-card>
                </div>
              </div>
            }
            <div [ngClass]="{ hidden: $any(activeHeaderTabMenu)?.id !== 'general' }">
              <app-assessment-type-form
                [isPage]="true"
                [assessmentType]="assessmentType"
                (submitSucceedEvent)="assessmentType = $event"></app-assessment-type-form>
            </div>
          </div>
        </microsec-common-preview-layout>
      </div>
    }
  }
</microsec-loading-overlay>

<ng-template #headerTemplate>
  <div class="w-full flex align-items-center justify-content-between gap-1">
    <div class="flex align-items-center gap-3 ml-2">
      <div class="cursor-pointer hover:underline" (click)="dialogRef.close(true)">
        <i class="fas fa-angle-left"></i>
      </div>
      <div class="flex flex-column">
        <div class="text-highlight font-bold">
          {{ util.getUppercaseFirstLetter(assessmentType.name) }}
        </div>
      </div>
    </div>
    <div class="flex align-items-center gap-2">
      <p-tabMenu
        styleClass="microsec-dashboard-tabmenu"
        [model]="headerTabMenu"
        [activeItem]="activeHeaderTabMenu"
        (activeItemChange)="activeHeaderTabMenu = $event"></p-tabMenu>
    </div>
    <div class="flex align-items-center gap-2">
      <p-button
        icon="fas fa-eye"
        label="Preview"
        [disabled]="!isValid"
        severity="secondary"
        size="small"
        [rounded]="true"
        styleClass="text-transform-none"
        (onClick)="isPreview = true"></p-button>
      <p-button
        icon="fa fa-check"
        label="Complete Assessment Design"
        [disabled]="!isValid"
        severity="primary"
        size="small"
        [rounded]="true"
        styleClass="text-transform-none"
        (onClick)="dialogRef.close(true)"></p-button>
    </div>
  </div>
</ng-template>

<ng-template #sidebarTemplate>
  <div class="sidebar">
    <div class="p-3 flex flex-column gap-3">
      <div class="font-bold text-highlight">Assessment Layout</div>
      <div class="align-self-center">
        <p-button
          icon="fa fa-plus"
          label="Add New Section"
          [disabled]="$any(this.sortedSections)?.length !== this.assessmentType?.sections?.length"
          severity="secondary"
          size="small"
          [rounded]="true"
          styleClass="text-transform-none"
          (onClick)="addSection()"></p-button>
      </div>
    </div>

    <div class="custom-accordion" cdkDropListGroup cdkDropList [cdkDropListData]="sortedSections || []" (cdkDropListDropped)="dropSection($event)">
      <ng-container *ngFor="let section of sortedSections || []; let sectionIndex = index">
        <div
          class="accordion-tab"
          cdkDrag
          [cdkDragData]="section"
          (cdkDragStarted)="onDragSectionStarted(sectionIndex)"
          cdkDragPreviewContainer="parent">
          <div
            id="{{ 'section_' + section.id }}"
            cdkDropList
            [cdkDropListData]="section[FORM_PARAMS.ASSIGNED_QUESTIONS] || []"
            (cdkDropListDropped)="dropQuestion($event, section.id)"
            [cdkDropListConnectedTo]="getConnectedList()">
            <div *cdkDragPlaceholder class="section-item"></div>
            <div *cdkDragPreview class="section-item">
              <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
                {{ sectionIndex + 1 }}.&nbsp;&nbsp;{{ section[FORM_PARAMS.NAME] || 'New Section' }}
              </div>
            </div>
            <div class="accordion-header" (click)="onSelectSection(sectionIndex)">
              <div
                class="overflow-hidden white-space-nowrap text-overflow-ellipsis"
                [pTooltip]="section[FORM_PARAMS.NAME]"
                tooltipPosition="right"
                appendTo="body">
                <i [class]="!!activeIndexSidebarAccordion.includes(sectionIndex) ? 'pi pi-chevron-down mr-2' : 'pi pi-chevron-right mr-2'"> </i>
                {{ sectionIndex + 1 }}.&nbsp;&nbsp;{{ section[FORM_PARAMS.NAME] || 'New Section' }}
              </div>
            </div>
            <div class="accordion-content" *ngIf="!!activeIndexSidebarAccordion?.includes(sectionIndex)">
              <ng-container *ngFor="let assignedQuestion of section[FORM_PARAMS.ASSIGNED_QUESTIONS] || []; let assignedQuestionIndex = index">
                <div
                  id="{{ 'question_' + assignedQuestion.question?.id }}"
                  cdkDrag
                  [cdkDragData]="assignedQuestion"
                  (cdkDragStarted)="onDragQuestionStarted(section.id)"
                  cdkDragPreviewContainer="parent"
                  class="sidebar-question"
                  [ngClass]="{
                    selected: selectedQuestion?.question?.id === assignedQuestion?.question?.id
                  }"
                  (click)="onSelectQuestion(sectionIndex, assignedQuestionIndex)">
                  <div *cdkDragPlaceholder class="section-item"></div>
                  <div
                    class="sidebar-question-label overflow-hidden white-space-nowrap text-overflow-ellipsis"
                    [pTooltip]="assignedQuestion.question?.title || '-'"
                    tooltipPosition="right"
                    appendTo="body">
                    {{ assignedQuestion.question?.title }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
