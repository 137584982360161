<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="form" *ngIf="!!question">
    <div class="grid">
      <div class="col-12">
        <microsec-card [isNestedCard]="true">
          <div class="grid">
            <label class="col-12 font-bold"> Question Details </label>
            <div class="col-12">
              <div class="list-fields mx-3">
                <div class="grid align-items-center">
                  <label class="col-4">Title</label>
                  <label class="col-8">{{ question.title }}</label>
                </div>
                <div class="grid align-items-center" *ngIf="!!question.descriptionPreview">
                  <label class="col-4">Description</label>
                  <div class="col-8 markdown-preview max-h-30rem overflow-auto" [innerHTML]="question.descriptionPreview"></div>
                </div>
                <div class="grid align-items-center" *ngIf="!!question.prefilling_logics?.length">
                  <label class="col-4">Autofill Logic(s)</label>
                  <div class="col-8 max-h-20rem overflow-auto">
                    <div class="flex flex-column gap-2">
                      <ng-container *ngFor="let logic of question.prefilling_logics; let logicIndex = index">
                        <div class="autofill-field">
                          <div class="microsec-codemirror-container console-content">
                            <div class="card">
                              <ngx-codemirror #codeMirrorComponent [(ngModel)]="question.prefilling_logics[logicIndex]" [options]="codemirrorOptions">
                              </ngx-codemirror>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </microsec-card>
      </div>
    </div>
  </div>
</microsec-loading-overlay>
