import { AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  ATTACK_TYPE_OPTIONS,
  THREAT_SCORE_OPTIONS,
  THREAT_STATUSES,
  THREAT_STATUS_COLORS,
  THREAT_TYPE_COLORS,
  THREAT_TYPE_OPTIONS,
} from '@ids-constants';
import { BaseComponent, CommonOverviewComponent } from '@microsec/components';
import { ChartHelper } from '@microsec/utilities';
import { CommonChart } from '@microsec/models';
import { ThreatService } from '@ids-services';
import { ConstantPipe } from '@ids-pipes';
import { TitleCasePipe } from '@angular/common';
import { PER_PAGE } from '@microsec/constants';
import { TargetDeviceService } from '@ids-services';

import { finalize } from 'rxjs/operators';
import { Chart, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { LazyLoadEvent } from 'primeng/api';
import moment from 'moment';

import { RefreshIntervalFormComponent } from './refresh-interval-form/refresh-interval-form.component';
import { MAIN_CHART_OPTIONS, SCORE_CHART_OPTIONS, TIMELINE_CHART_OPTIONS, DIAGRAMS, TIMELINE_DIAGRAM_KEYS } from './dashboard-threats.config';
import { CustomTimeRangeFormComponent } from './custom-time-range-form/custom-time-range-form.component';

@Component({
  selector: 'app-dashboard-threats',
  templateUrl: './dashboard-threats.component.html',
  styleUrls: ['./dashboard-threats.component.scss'],
  providers: [ConstantPipe, TitleCasePipe],
})
export class DashboardThreatsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  isLoadingThreat = false;

  isLoadingDevice = false;

  isActiveThreats = true;

  PER_PAGE = PER_PAGE;

  THREAT_TYPE_OPTIONS = THREAT_TYPE_OPTIONS;

  THREAT_SCORE_OPTIONS = THREAT_SCORE_OPTIONS;

  ATTACK_TYPE_OPTIONS = ATTACK_TYPE_OPTIONS;

  filterThreatType: string[] = [];

  filterAttackType: string[] = [];

  filterThreatScore: string[] = [];

  customFromDate: any = null;

  customToDate: any = null;

  filterFromDate: string | null = null;

  filterToDate: string | null = null;

  filterRange: string | null = null;

  summary: any = null;

  threats: any[] = [];

  devices: any[] = [];

  lazyLoadEventThreat: LazyLoadEvent | null = null;

  lazyLoadEventDevice: LazyLoadEvent | null = null;

  displayThreatDetails = false;

  displayDeviceDetails = false;

  _selectedThreat = null;

  get selectedThreat() {
    return this._selectedThreat;
  }

  set selectedThreat(value: any) {
    this._selectedThreat = value;
    this.displayThreatDetails = !!value;
  }

  _selectedDevice = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.displayDeviceDetails = !!value;
  }

  threatCurrentPage = 1;

  threatTotalRecords = 0;

  deviceCurrentPage = 1;

  deviceTotalRecords = 0;

  threatCols: any[] = [
    { field: 'created', header: 'Timestamp', width: 12 },
    { field: 'threat_id', header: 'Threat ID', width: 10 },
    { field: 'threat_type', header: 'Threat Type', width: 12 },
    { field: 'attack_type', header: 'Attack Type', width: 12 },
    { field: 'threat_score', header: 'Threat Score', width: 15 },
    { field: 'status', header: 'Status', width: 15 },
  ];

  deviceCols: any[] = [
    { field: 'id', header: 'ID', width: 5 },
    { field: 'label', header: 'Name', width: 25 },
    { field: 'type', header: 'Type', width: 15 },
    { field: 'interfaces', header: 'Interfaces', width: 15 },
    { field: 'threat_score', header: 'Combined Threat Score', width: 15 },
  ];

  _selectedTimelineChart = 0;

  get selectedTimelineChart() {
    return this._selectedTimelineChart;
  }

  set selectedTimelineChart(value: any) {
    this._selectedTimelineChart = value;
    this.updateTimelineChart();
  }

  timelineChartOptions = [
    { label: 'General', value: 0 },
    { label: 'Severity', value: 1 },
    { label: 'Status', value: 2 },
    { label: 'Threat Type', value: 3 },
    { label: 'Attack Type', value: 4 },
  ];

  timelineCharts: CommonChart[] = [];

  scoreCharts: CommonChart[] = [];

  scoreBasicCharts: Chart[] = [];

  timelineBasicChart: Chart | null = null;

  timeRangeOptions: any[] = [
    {
      label: 'Current Day',
      value: 'current_day',
    },
    {
      label: 'Current Week',
      value: 'current_week',
    },
    {
      label: 'Current Month',
      value: 'current_month',
    },
    {
      label: 'Current Year',
      value: 'current_year',
    },
    {
      label: 'Last 15 minutes',
      value: '15_minutes',
    },
    {
      label: 'Last 60 minutes',
      value: '60_minutes',
    },
    {
      label: 'Last 4 hours',
      value: '4_hours',
    },
    {
      label: 'Last 24 hours',
      value: '24_hours',
    },
    {
      label: 'Last 7 Day',
      value: '7_days',
    },
    {
      label: 'Last 30 Day',
      value: '30_days',
    },
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Custom Range',
      value: 'custom',
    },
  ];

  selectedTimeRange: any = '24_hours';

  refreshInterval: any;

  isRefreshIntervalEnabled = true;

  intervalTime = 1;

  intervalUnit = 'm';

  refreshIntervalTimer: any = null;

  /**
   * Chart data + options
   */
  charts: CommonChart[] = [];

  /**
   * Diagram UIs
   */
  @ViewChild('dashboardThreatsComponent') dashboardThreatsComponent!: CommonOverviewComponent;

  @ViewChildren('scoreDiagrams') scoreDiagrams!: QueryList<ElementRef>;

  @ViewChild('timelineDiagram') timelineDiagram!: ElementRef;

  constructor(
    private constantPipe: ConstantPipe,
    private titleCasePipe: TitleCasePipe,
    private threatSrv: ThreatService,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initTemplates();
    this.setTimeRange();
    this.getData();
    if (!!localStorage.getItem('refreshIntervalThreatDashboard')) {
      const interval = JSON.parse(localStorage.getItem('refreshIntervalThreatDashboard') || '');
      this.isRefreshIntervalEnabled = interval.enabled;
      this.intervalTime = interval.time;
      this.intervalUnit = interval.unit;
    }
    this.setRefreshInterval(false, this.isRefreshIntervalEnabled);
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscriptions = [
      this.threatSrv.refreshObs.subscribe((rs) => {
        if (!!rs) {
          this.getThreatSummary();
          this.getThreats();
        }
      }),
      this.targetDeviceSrv.refreshObs.subscribe((rs) => {
        if (!!rs) {
          this.getDevices();
        }
      }),
    ];
    this.subscriptions.push(...subscriptions);
  }

  setRefreshInterval(showToast = true, isEnabled = true, isUpdate = true) {
    clearInterval(this.refreshInterval);
    this.isRefreshIntervalEnabled = isEnabled;
    if (!!isEnabled) {
      const interval = (this.intervalTime || 1) * (this.intervalUnit === 's' ? 1000 : this.intervalUnit === 'm' ? 60000 : 3600000);
      let timer = interval;
      this.refreshInterval = setInterval(() => {
        if (timer < 1000) {
          timer = interval;
          this.getData(false);
        } else {
          timer -= 1000;
        }
        this.refreshIntervalTimer = new Date(0, 0, 0, 0, 0, 0);
        this.refreshIntervalTimer.setSeconds(timer / 1000);
      }, 1000);
    }
    if (!!showToast) {
      if (!!isUpdate) {
        this.showSuccessMessage(
          `Refresh interval set to ${this.intervalTime} ${this.intervalUnit === 's' ? 'seconds' : this.intervalUnit === 'm' ? 'minute' : 'hour'}`,
        );
      } else {
        this.showSuccessMessage(`Auto refresh is ${!!this.isRefreshIntervalEnabled ? 'enabled' : 'disabled'}`);
      }
    }
    localStorage.setItem(
      'refreshIntervalThreatDashboard',
      JSON.stringify({
        enabled: this.isRefreshIntervalEnabled,
        time: this.intervalTime,
        unit: this.intervalUnit,
      }),
    );
  }

  getData(showLoading = true) {
    this.getThreatSummary(showLoading);
    this.getThreats(showLoading);
    this.getDevices(showLoading);
  }

  getThreatSummary(showLoading = true) {
    if (!!showLoading) {
      this.isLoading = true;
    }
    if (this.selectedTimeRange !== 'custom') {
      this.setTimeRange();
    }
    this.threatSrv
      .getThreatSummary(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        !!this.isActiveThreats ? [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING] : undefined,
        this.filterThreatType,
        this.filterAttackType,
        this.filterThreatScore.length > 1
          ? this.THREAT_SCORE_OPTIONS.filter((item) => this.filterThreatScore.find((filter) => filter === item.value)).map((param) => param.value)
          : this.filterThreatScore,
        this.filterFromDate as any,
        this.filterToDate as any,
        this.filterRange as any,
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          if (!!res) {
            const summary = {
              ...res,
              totalByScore: {
                high: res.threats_by_score?.data?.[res.threats_by_score?.labels?.indexOf('high')] || 0,
                medium: res.threats_by_score?.data?.[res.threats_by_score?.labels?.indexOf('medium')] || 0,
                low: res.threats_by_score?.data?.[res.threats_by_score?.labels?.indexOf('low')] || 0,
              },
            };
            this.summary = summary;
            this.initTemplates();

            this.charts.forEach((chart) => {
              this.updateChartData(chart);
            });

            const totalByScoreArr: number[] = Object.values(summary.totalByScore);
            const scoreChartMaxYAxis = Math.max(...totalByScoreArr);
            this.scoreCharts.forEach((chart) => {
              this.updateChartData(chart);
              (chart.options as any).scales.y.max = scoreChartMaxYAxis;
            });

            this.timelineCharts.forEach((chart) => {
              this.updateChartData(chart);
            });
            this.timelineCharts.forEach((chart) => {
              if (!!chart?.data?.datasets?.length) {
                const datasets = chart?.data?.datasets
                  .filter((dataset) => !dataset?.data?.every((item) => item === 0))
                  .map((dataset, index) => {
                    if (chart.key === TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_ATTACK_TYPE) {
                      const color = ChartHelper.getDynamicColor(index);
                      return {
                        ...dataset,
                        borderColor: color,
                        backgroundColor: color,
                      };
                    } else {
                      return dataset;
                    }
                  });
                chart.data.datasets = datasets;
              }
            });

            this.redrawDiagram();
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getThreats(showLoading = true, event?: LazyLoadEvent) {
    if (!!showLoading) {
      this.isLoadingThreat = true;
    }
    if (!!event) {
      this.lazyLoadEventThreat = event;
    }
    const page = !event ? this.threatCurrentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = this.lazyLoadEventThreat?.rows || PER_PAGE;
    this.threatSrv
      .getThreats(
        this.breadcrumbConfig?.projectId,
        undefined,
        page,
        perPage,
        'id',
        true,
        true,
        !!this.isActiveThreats ? [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING] : undefined,
        this.filterThreatType,
        undefined,
        undefined,
        undefined,
        this.filterAttackType,
        this.filterThreatScore.length > 1
          ? this.THREAT_SCORE_OPTIONS.filter((item) => this.filterThreatScore.find((filter) => filter === item.value)).map((param) => param.value)
          : this.filterThreatScore,
      )
      .pipe(
        finalize(() => {
          this.isLoadingThreat = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.threatCurrentPage = res?.page;
          this.threatTotalRecords = res?.total_record;
          this.threats = (res?.threats as any[]) || [];
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.selectedThreat = null;
        },
      });
  }

  getDevices(showLoading = true, event?: LazyLoadEvent) {
    if (!!showLoading) {
      this.isLoadingDevice = true;
    }
    if (!!event) {
      this.lazyLoadEventDevice = event;
    }
    const page = !event ? this.deviceCurrentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = this.lazyLoadEventDevice?.rows || PER_PAGE;
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        page,
        perPage,
        sort: 'threat_score',
        reverse: true,
      })
      .pipe(
        finalize(() => {
          this.isLoadingDevice = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.deviceCurrentPage = res?.page;
          this.deviceTotalRecords = res?.total_record;
          const devices = ((res?.devices as any[]) || []).map((threat) => ({
            ...threat,
            updatableStatus: threat.status,
          }));
          this.devices = devices;
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.selectedDevice = null;
        },
      });
  }

  setTimeRange(isRefresh = false) {
    if (this.selectedTimeRange === 'custom') {
      return;
    } else {
      this.customFromDate = null;
      this.customToDate = null;
      const formatStr = 'YYYY-MM-DD';
      const now = moment().utc();
      switch (this.selectedTimeRange) {
        case 'current_day': {
          this.filterFromDate = now
            .clone()
            .startOf('day')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now
            .clone()
            .add(1, 'minute')
            .format(formatStr + ' HH:mm:00');
          this.filterRange = 'hourly';
          break;
        }
        case 'current_week': {
          this.filterFromDate = now.clone().startOf('isoWeek').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case 'current_month': {
          this.filterFromDate = now.clone().startOf('month').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case 'current_year': {
          this.filterFromDate = now.clone().startOf('year').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'monthly';
          break;
        }
        case '15_minutes': {
          this.filterFromDate = now
            .clone()
            .subtract(15, 'minutes')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now
            .clone()
            .add(1, 'minute')
            .format(formatStr + ' HH:mm:00');
          this.filterRange = 'minute';
          break;
        }
        case '60_minutes': {
          this.filterFromDate = now
            .clone()
            .subtract(60, 'minutes')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now.clone().format(formatStr + ' HH:mm:00');
          this.filterRange = 'minute';
          break;
        }
        case '4_hours': {
          this.filterFromDate = now
            .clone()
            .subtract(3, 'hours')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now.clone().format(formatStr + ' HH:mm:00');
          this.filterRange = 'hourly';
          break;
        }
        case '24_hours': {
          this.filterFromDate = now
            .clone()
            .subtract(23, 'hours')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now
            .clone()
            .add(1, 'minute')
            .format(formatStr + ' HH:mm:00');
          this.filterRange = 'hourly';
          break;
        }
        case '7_days': {
          this.filterFromDate = now.clone().subtract(6, 'days').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case '30_days': {
          this.filterFromDate = now.clone().subtract(29, 'days').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case 'all': {
          this.filterFromDate = null;
          this.filterToDate = null;
          this.filterRange = 'monthly';
          break;
        }
        default: {
          break;
        }
      }
    }
    if (!!isRefresh) {
      this.getThreatSummary();
    }
  }

  clearFilter() {
    this.filterThreatType = [];
    this.filterAttackType = [];
    this.filterThreatScore = [];
    this.getThreatSummary();
    this.getThreats();
  }

  openCustomTimeRangeFormComponent() {
    const formatStr = 'YYYY-MM-DD';
    const dialog = this.dialogSrv.open(CustomTimeRangeFormComponent, {
      data: {
        from: !!this.customFromDate ? moment.utc(this.customFromDate).toDate() : null,
        to: !!this.customToDate ? moment.utc(this.customToDate).toDate() : null,
      },
      header: 'Custom Range',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs && !!rs.from && !!rs.to) {
        this.customFromDate = rs.from;
        this.customToDate = rs.to;
        this.filterFromDate = moment.utc(this.customFromDate).format(formatStr + ' HH:mm:00');
        this.filterToDate = moment.utc(this.customToDate).format(formatStr + ' HH:mm:00');
        const diffDays = Math.ceil(Math.abs(rs.to - rs.from) / (1000 * 60 * 60 * 24));
        this.filterRange = diffDays <= 1 ? 'hourly' : diffDays > 1 ? 'daily' : diffDays > 31 && diffDays <= 365 ? 'monthly' : 'yearly';
        this.getThreatSummary();
      } else {
        if (!this.customFromDate || !this.customToDate) {
          this.selectedTimeRange = '24_hours';
          this.setTimeRange(true);
        }
      }
    });
  }

  openRefreshIntervalForm() {
    const dialog = this.dialogSrv.open(RefreshIntervalFormComponent, {
      header: 'Edit Refresh Interval',
      data: {
        interval: {
          time: this.intervalTime,
          unit: this.intervalUnit,
        },
      },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs && !!rs.interval) {
        this.intervalTime = rs.interval.time;
        this.intervalUnit = rs.interval.unit;
        this.setRefreshInterval();
      }
    });
  }

  toggleIsActiveThreats() {
    this.isActiveThreats = !this.isActiveThreats;
    this.getData();
  }

  /**
   * Redraw the diagram UI(s)
   * @param chart
   */
  private redrawDiagram() {
    if (!!this.dashboardThreatsComponent?.diagrams) {
      this.dashboardThreatsComponent.diagrams.forEach((diagram) => {
        setTimeout(() => {
          diagram.redraw();
        });
      });
    }
    this.scoreBasicCharts?.forEach((chart) => {
      setTimeout(() => {
        chart.update();
      });
    });
    this.timelineBasicChart?.update();
  }

  /**
   * Build up the security objects
   */
  private initTemplates() {
    // charts
    const charts: any[] = [];
    const scoreCharts: any[] = [];
    const timelineCharts: any[] = [];
    Object.entries(DIAGRAMS).forEach(([key, value]) => {
      const chart = this.util.cloneDeepObject(
        {
          group: value.GROUP,
          type: value.TYPE,
          key,
          label: value.LABEL,
          data: {} as ChartData,
          options: {} as ChartOptions,
          children: !!value.CHILDREN.length ? this.util.cloneObjectArray(value.CHILDREN, true) : this.getDefaultChartChildren(key),
          legendCols: value.TYPE === 'doughnut' ? this.getLegendCols() : [],
        },
        true,
      ) as CommonChart;
      switch (value.GROUP) {
        //Main chart is doughnut chart shape same as the charts in Dashboard devices
        case 'MAIN': {
          chart.options = this.util.cloneDeepObject(MAIN_CHART_OPTIONS);
          charts.push(chart);
          break;
        }
        case 'SCORE': {
          chart.options = this.util.cloneDeepObject(SCORE_CHART_OPTIONS);
          scoreCharts.push(chart);
          break;
        }
        case 'TIMELINE': {
          chart.options = this.util.cloneDeepObject(TIMELINE_CHART_OPTIONS);
          timelineCharts.push(chart);
          break;
        }
        default: {
          break;
        }
      }
    });
    this.charts = charts;
    this.scoreCharts = scoreCharts;
    this.timelineCharts = timelineCharts;

    setTimeout(() => {
      this.timelineBasicChart?.destroy();
      this.timelineBasicChart = new Chart(this.timelineDiagram.nativeElement, {
        type: 'line',
        data: this.timelineCharts?.[this.selectedTimelineChart].data as any,
        options: this.timelineCharts?.[this.selectedTimelineChart].options,
      });

      this.scoreBasicCharts?.forEach((scoreBasicChart) => {
        scoreBasicChart.destroy();
      });
      this.scoreBasicCharts = this.scoreDiagrams.map((scoreDiagram, index) => {
        return new Chart(scoreDiagram.nativeElement, {
          type: 'line',
          data: this.scoreCharts?.[index].data as any,
          options: this.scoreCharts?.[index].options,
        });
      });
    }, 10);
  }

  updateTimelineChart() {
    if (!!this.timelineBasicChart) {
      this.timelineBasicChart.data = this.timelineCharts?.[this.selectedTimelineChart].data as any;
      this.timelineBasicChart.options = this.timelineCharts?.[this.selectedTimelineChart].options as any;
      this.timelineBasicChart.update();
    }
  }

  private getDefaultChartChildren(chartKey: string) {
    const results: any[] = [];
    switch (chartKey) {
      case DIAGRAMS.THREATS_BY_STATUS.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threats_by_status?.labels || []));
        break;
      }
      case DIAGRAMS.THREATS_BY_TYPE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threats_by_type?.labels || []));
        break;
      }
      case DIAGRAMS.THREATS_BY_ATTACK_TYPE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threats_by_attack_type?.labels || []));
        break;
      }
      default: {
        break;
      }
    }
    return results;
  }

  /**
   * Get the legend columns
   */
  getLegendCols() {
    const results: any[] = [
      { field: 'label', header: 'Status', width: 5 },
      { field: 'percent', header: '%', width: 2 },
      { field: 'counter', header: 'Devices', width: 4 },
    ];
    return results;
  }

  getGradientBackgroundColor(ctx: any, color: string) {
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    const rgb = this.util.hexToRgb(color);
    gradient.addColorStop(0, color);
    gradient.addColorStop(0.5, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.4)`);
    gradient.addColorStop(1, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0)`);
    return gradient;
  }

  /**
   * Generate the data
   * @param chart chart
   */
  updateChartData(chart: CommonChart) {
    const data = chart.data as ChartData;
    data.labels = [];

    switch (chart.group) {
      case 'MAIN': {
        data.datasets = [
          {
            data: this.generateData(chart),
            backgroundColor: [],
            borderWidth: [],
            barPercentage: 0.5,
          } as ChartDataset,
        ];
        break;
      }
      case 'SCORE': {
        data.datasets = ((chart.children as any[]) || [])?.map((children) => {
          return {
            label: children.label,
            data: this.generateData(chart),
            fill: true,
            lineTension: 0.2,
            pointStyle: false,
            borderWidth: 2,
            borderColor: children.color,
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              return this.getGradientBackgroundColor(ctx, children.color);
            },
            pointBackgroundColor: children.color,
          } as ChartDataset;
        });
        break;
      }
      case 'TIMELINE': {
        data.datasets = ((chart.children as any[]) || [])?.map((children) => {
          return {
            label: children.label,
            data: this.generateData(chart, children.value),
            fill: chart.key === TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_GENERAL,
            lineTension: 0.2,
            pointStyle: false,
            borderWidth: 3,
            borderColor: children.color,
            backgroundColor: (context: any) => {
              if (chart.key === TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_GENERAL) {
                const { ctx } = context.chart;
                return this.getGradientBackgroundColor(ctx, children.color);
              } else {
                return children.color;
              }
            },
            pointBackgroundColor: children.color,
          } as ChartDataset;
        });
        break;
      }
      default: {
        break;
      }
    }
    this.generateLabels(chart, data);
  }

  /**
   * Generate the chart data
   * @param chart
   * @returns
   */
  private generateData(chart: CommonChart, childrenKey?: string): number[] {
    let values: number[] = [];
    if (!!chart.key) {
      switch (chart.key) {
        case DIAGRAMS.THREAT_SCORE_HIGH.KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.score?.high || 0) || [];
          break;
        }
        case DIAGRAMS.THREAT_SCORE_MEDIUM.KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.score?.medium || 0) || [];
          break;
        }
        case DIAGRAMS.THREAT_SCORE_LOW.KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.score?.low || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_GENERAL'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.total || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_SCORE'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.score?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_STATUS'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.status?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_TYPE'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.type?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_ATTACK_TYPE'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.attack_type?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS.THREATS_BY_STATUS.KEY: {
          values = this.summary?.threats_by_status?.data || [];
          break;
        }
        case DIAGRAMS.THREATS_BY_TYPE.KEY: {
          values = this.summary?.threats_by_type?.data || [];
          break;
        }
        case DIAGRAMS.THREATS_BY_ATTACK_TYPE.KEY: {
          values = this.summary?.threats_by_attack_type?.data || [];
          break;
        }
        default: {
          break;
        }
      }
    }
    return values;
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: CommonChart, data: ChartData) {
    switch (chart.key) {
      case DIAGRAMS.THREATS_BY_STATUS.KEY: {
        chart.children?.forEach((item: any) => {
          // Labels
          data.labels?.push(this.titleCasePipe.transform(item.LABEL));
          const dataset = data.datasets.find((p) => !!p) as ChartDataset;
          // Colors
          if (!!dataset && !!dataset.backgroundColor) {
            (dataset.backgroundColor as string[]).push((THREAT_STATUS_COLORS as any)[(item.LABEL as string)?.toUpperCase()] || item.COLOR);
            (dataset.borderWidth as number[]).push(0);
          }
        });
        break;
      }
      case DIAGRAMS.THREATS_BY_TYPE.KEY: {
        if (!!chart.key) {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            // Labels
            data.labels?.push(this.titleCasePipe.transform(item.LABEL));
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push((THREAT_TYPE_COLORS as any)[(item.LABEL as string)?.toUpperCase()] || item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      case DIAGRAMS.THREATS_BY_ATTACK_TYPE.KEY: {
        if (!!chart.key) {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            // Labels
            data.labels?.push(this.constantPipe.transform(item.LABEL, 'threat-attack-type'));
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push(item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      case DIAGRAMS['THREAT_TIMELINE_BY_ATTACK_TYPE'].KEY: {
        if (!!chart.key) {
          data.labels?.push(...(this.summary?.threats_by_timeline?.data?.map((data: any) => this.getTimelineChartLabel(data.time)) || []));
          data.datasets?.forEach((item: any, index: number) => {
            const color = ChartHelper.getDynamicColor(index);
            item.borderColor = color;
            item.backgroundColor = color;
          });
        }
        break;
      }
      default: {
        data.labels?.push(...(this.summary?.threats_by_timeline?.data?.map((data: any) => this.getTimelineChartLabel(data.time)) || []));
        break;
      }
    }
  }

  getTimelineChartLabel(time: any) {
    if (!!time) {
      let format = '';
      switch (this.filterRange) {
        case 'minute': {
          format = 'HH:mm';
          break;
        }
        case 'hourly': {
          format = 'HH:00';
          break;
        }
        case 'daily': {
          format =
            this.selectedTimeRange === 'current_week' || this.selectedTimeRange === '7_days'
              ? 'ddd'
              : this.selectedTimeRange === 'current_month'
                ? 'Do'
                : 'Do MMM';
          break;
        }
        case 'monthly': {
          format = this.selectedTimeRange === 'current_year' ? 'MMM' : 'MMM YYYY';
          break;
        }
        case 'yearly': {
          format = 'YYYY';
          break;
        }
        default: {
          break;
        }
      }
      return moment.utc(time).local().format(format);
    }
    return '';
  }

  /**
   * Get legend label
   * @param value
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return label;
  }

  override ngOnDestroy() {
    this.cleanup();
    clearInterval(this.refreshInterval);
  }
}
