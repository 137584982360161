import { Component, EventEmitter, Output } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent extends BaseComponent {
  @Output() changeStepEvent: EventEmitter<number> = new EventEmitter<number>();

  faExclamationCircle = faExclamationCircle;

  /**
   * Change step
   * @param step
   */
  changeStep(step = 1) {
    this.changeStepEvent.emit(step);
  }
}
