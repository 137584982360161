import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-firewalls',
  templateUrl: './firewalls.component.html',
  styleUrls: ['./firewalls.component.scss'],
})
export class FirewallsComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true);
  }

  override ngOnDestroy(): void {
    this.cleanup();
  }
}
