export const TARGET_OPTIONS = [
  { label: 'ACCEPT', value: 'accept' },
  { label: 'DROP', value: 'drop' },
  { label: 'REJECT', value: 'reject' },
  { label: 'QUEUE', value: 'queue' },
];

export const INTERFACE_OPTIONS = [
  { label: 'tcp', value: 'tcp' },
  { label: 'LAN', value: 'lan' },
  { label: 'OPT', value: 'opt' },
];

export const IP_PROTOCOL_OPTIONS = [
  { label: 'ICMP', value: 'icmp' },
  { label: 'TCP', value: 'tcp' },
  { label: 'UDP', value: 'udp' },
  { label: 'All', value: 'all' },
];

export const IP_VERSION_OPTIONS = [
  { label: 'IPv4', value: 'ipv4' },
  { label: 'IPv6', value: 'ipv6' },
];

export const CHAIN_OPTIONS = [
  { label: 'FORWARD', value: 'forward' },
  { label: 'INPUT', value: 'input' },
  { label: 'OUTPUT', value: 'output' },
];

export const CIDR_OPTIONS = [
  { label: '0', value: '0.0.0.0' },
  { label: '1', value: '128.0.0.0' },
  { label: '2', value: '192.0.0.0' },
  { label: '3', value: '224.0.0.0' },
  { label: '4', value: '240.0.0.0' },
  { label: '5', value: '248.0.0.0' },
  { label: '6', value: '252.0.0.0' },
  { label: '7', value: '254.0.0.0' },
  { label: '8', value: '255.0.0.0' },
  { label: '9', value: '255.128.0.0' },
  { label: '10', value: '255.192.0.0' },
  { label: '11', value: '255.224.0.0' },
  { label: '12', value: '255.240.0.0' },
  { label: '13', value: '255.248.0.0' },
  { label: '14', value: '255.252.0.0' },
  { label: '15', value: '255.254.0.0' },
  { label: '16', value: '255.255.0.0' },
  { label: '17', value: '255.255.128.0' },
  { label: '18', value: '255.255.192.0' },
  { label: '19', value: '255.255.224.0' },
  { label: '20', value: '255.255.240.0' },
  { label: '21', value: '255.255.248.0' },
  { label: '22', value: '255.255.252.0' },
  { label: '23', value: '255.255.254.0' },
  { label: '24', value: '255.255.255.0' },
  { label: '25', value: '255.255.255.128' },
  { label: '26', value: '255.255.255.192' },
  { label: '27', value: '255.255.255.224' },
  { label: '28', value: '255.255.255.240' },
  { label: '29', value: '255.255.255.248' },
  { label: '30', value: '255.255.255.252' },
  { label: '31', value: '255.255.255.254' },
  { label: '32', value: '255.255.255.255' },
];
