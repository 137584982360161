<div class="grid">
  <div class="col-12">
    <!-- Table Toolbar: Button? Search, Filter, Clear -->
    <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" [leftTemplate]="leftTemplate" />
    <ng-template #leftTemplate>
      <span [pTooltip]="!!checkUserQuota ? '' : 'Maximum number of users reached'" tooltipPosition="bottom" appendTo="body">
        <p-button [label]="'Create User'" [rounded]="true" icon="pi pi-plus" (onClick)="openUserForm()" [disabled]="!!isLoading || !checkUserQuota" />
      </span>
    </ng-template>
  </div>

  <div class="col-12">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [globalFilterFields]="globalFilterFields"
        [cols]="selectedCols || []"
        [values]="values"
        [cellTemplate]="cellTemplate"
        [actionsMenuItems]="actionsMenuItems"
        [loading]="isLoading"
        [selectable]="false"
        itemName="User"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate" />
    </microsec-card>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-refresh-button (refreshEvent)="getUsers()" />
    <microsec-table-column-filter
      [key]="constructor.name"
      [cols]="cols || []"
      [(selectedColFields)]="selectedColFields"
      (exportReportEvent)="openGenerateReportDialog()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- is_enabled -->
    <ng-container *ngSwitchCase="'is_superuser'">
      <p-checkbox [(ngModel)]="rowData[col.field]" [binary]="true" [disabled]="true"></p-checkbox>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
