import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { IFeatureService } from '@microsec/services';
import featuresData from './mock-data/features.json';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { fromLayoutActions } from '@microsec/ngrx-layout';

@Injectable({
  providedIn: 'root',
})
export class MockFeatureService implements IFeatureService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  getFeatures(): Observable<any> {
    const data = featuresData as any;
    const features: any = {};
    Object.keys(data).forEach((key1) => {
      const featureTypes = data[key1];
      const subFeatures: any = {};
      Object.keys(featureTypes['sub-features']).forEach((key2) => {
        subFeatures[key2] = featureTypes['sub-features'][key2].enabled || featureTypes['sub-features'][key2].value;
      });
      features[key1] = subFeatures;
    });
    this.store.dispatch(new fromLayoutActions.SetFeatures(features));
    return scheduled([features], asyncScheduler);
  }
}
