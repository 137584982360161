import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ATTACHMENT_ALLOWED_EXTENSIONS } from '@ids-constants';
import { TargetDeviceService } from '@ids-services';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { finalize, switchMap } from 'rxjs';

const FORM_PARAMS = {
  ATTACHMENTS: 'attachments',
};

@Component({
  selector: 'app-zone-attachments',
  templateUrl: './zone-attachments.component.html',
  styleUrls: ['./zone-attachments.component.scss'],
})
export class ZoneAttachmentsComponent extends BaseComponent implements AfterViewInit {
  _zone: any = null;

  get zone() {
    return this._zone;
  }

  @Input() set zone(value: any) {
    this._zone = this.util.cloneDeepObject(value);
    this.initForm();
  }

  @Output() refreshListEvent: EventEmitter<any> = new EventEmitter<any>();

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(private targetDeviceSrv: TargetDeviceService) {
    super();
  }

  ngAfterViewInit() {
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const attachmentsField = this.initAttachmentField();
    const fields: FormItem[] = [attachmentsField];
    this.fields = fields;
  }

  /**
   * Init attachment field
   * @returns
   */
  initAttachmentField() {
    const attachmentsField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.ATTACHMENTS,
      hasNoLabel: true,
      label: 'Attachment',
      field: 'file',
      isMultiple: true,
      acceptedFileTypes: [...ATTACHMENT_ALLOWED_EXTENSIONS],
      columnNumber: 3,
      placeholder: 'Upload attachments',
      defaultValue: ((this.zone?.attachments as any[]) || []).map((attachment) => this.convertToDisplayAttachment(attachment)),
      uploadEvent: new EventEmitter(),
      downloadEvent: new EventEmitter(),
      deleteEvent: new EventEmitter(),
    } as FormItem);
    // UPLOAD
    attachmentsField.uploadEvent?.subscribe((event) => {
      const file = event?.target?.files?.[0];
      if (!!file) {
        this.form.isLoading = true;
        this.targetDeviceSrv
          .createAttachment({ file_path: file })
          .pipe(
            switchMap((attachment) => {
              const files: any[] = this.form.getControlValue(FORM_PARAMS.ATTACHMENTS);
              files.push(this.convertToDisplayAttachment(attachment));
              this.form.setControlValue(FORM_PARAMS.ATTACHMENTS, files);
              return this.targetDeviceSrv.updateZone(this.zone.id, {
                attachments: files.map((p) => this.convertToUploadAttachment(p)),
              });
            }),
            finalize(() => {
              this.form.isLoading = false;
            }),
          )
          .subscribe({
            next: () => {
              this.showSuccessMessage('Uploaded attachment successfully');
              this.refreshListEvent.emit();
            },
            error: (err) => {
              this.showErrorMessage(err);
            },
          });
      }
    });
    // DOWNLOAD
    attachmentsField.downloadEvent?.subscribe((file) => {
      this.targetDeviceSrv
        .getAttachment(file.id)
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: (result) => {
            this.util.downloadFileFromBlob(result);
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    });
    // DELETE
    attachmentsField.deleteEvent?.subscribe((file) => {
      this.form.isLoading = true;
      this.targetDeviceSrv
        .deleteAttachment(file.id)
        .pipe(
          switchMap(() => {
            const allFiles: any[] = this.form.getControlValue(FORM_PARAMS.ATTACHMENTS);
            const files = allFiles.filter((p) => p?.id !== file?.id);
            this.form.setControlValue(FORM_PARAMS.ATTACHMENTS, files);
            return this.targetDeviceSrv.updateZone(this.zone.id, {
              attachments: files.map((attachment) => this.convertToUploadAttachment(attachment)),
            });
          }),
          finalize(() => {
            this.form.isLoading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage('Removed attachment successfully');
            this.refreshListEvent.emit();
          },
          error: (err) => {
            this.showErrorMessage(err);
          },
        });
    });
    return attachmentsField;
  }

  /**
   * Convert attachment object to display
   * @param attachment
   * @returns
   */
  convertToDisplayAttachment(attachment: any) {
    return {
      id: attachment.id,
      name: attachment.file_name,
      size: attachment.file_size,
      type: attachment.file_type,
    };
  }

  /**
   * Convert attachment object to upload
   * @param attachment
   * @returns
   */
  convertToUploadAttachment(attachment: any) {
    return {
      id: attachment.id,
      file_name: attachment.name,
      file_size: attachment.size,
      file_type: attachment.type,
    };
  }
}
