import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { OrganizationService } from '@microsec/services';
import { USER_ROLE } from '@microsec/constants';
import { layoutSelectors } from '@microsec/ngrx-layout';
import { finalize } from 'rxjs';
import { ORGANIZATION_MANAGEMENT_CONSTANTS } from '@ids-constants';
import { SharedOrganizationFormComponent } from '../../shared-components/shared-organization-form/shared-organization-form.component';

@Component({
  selector: 'app-organization-information',
  templateUrl: './organization-information.component.html',
  styleUrls: ['./organization-information.component.scss'],
})
export class OrganizationInformationComponent extends BaseComponent implements OnInit {
  visible = false;

  isLoading = true;

  organization: any = null;

  constructor(private organizationSrv: OrganizationService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true);
    this.store.select(layoutSelectors.pageName).subscribe((value) => {
      this.visible = value === ORGANIZATION_MANAGEMENT_CONSTANTS.USER_ACCESS.LABEL;
      if (!!this.visible) {
        this.getOrganization();
      }
    });
  }

  getOrganization() {
    this.isLoading = true;
    const organizationId = this.breadcrumbConfig?.organizationId as number;
    this.organizationSrv
      .getOrganization(organizationId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.organization = rs;
        },
      });
  }

  openOrganizationForm() {
    const dialogRef = this.dialogSrv.open(SharedOrganizationFormComponent, {
      data: {
        organization: this.organization,
      },
      header: `Edit Organization`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getOrganization();
      }
    });
  }
}
