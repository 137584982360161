import { Component } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { NetworkDeviceService } from '@ids-services';

import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { SharedNetworkDeviceFormComponent } from '../shared-network-device-form/shared-network-device-form.component';

@Component({
  selector: 'app-shared-test-configuration-dialog',
  templateUrl: './shared-test-configuration-dialog.component.html',
  styleUrls: ['./shared-test-configuration-dialog.component.scss'],
})
export class SharedTestConfigurationDialogComponent extends BaseComponent {
  visible = false;

  type: 'confirm' | 'loading' | 'success' | 'failed' = 'confirm';

  isSyncing = false;

  device: any = null;

  constructor(
    public dialogRef: DynamicDialogRef,
    private networkDeviceSrv: NetworkDeviceService,
  ) {
    super();
  }

  open(device: any, isConfirm = true) {
    if (!!device) {
      this.isSyncing = false;
      this.type = !!isConfirm ? 'confirm' : 'loading';
      this.device = this.util.cloneDeepObject(device);
      this.visible = true;
      if (!isConfirm) {
        this.testConfiguration();
      }
    }
  }

  testConfiguration() {
    this.visible = false;
    const configId = this.device?.configs?.[0]?.id;
    if (!!configId) {
      this.isSyncing = false;
      this.type = 'loading';
      this.visible = true;
      this.networkDeviceSrv
        .testDeviceConfig(configId)
        .pipe()
        .subscribe({
          next: () => {
            setTimeout(() => {
              this.networkDeviceSrv.refresh$.next(true);
              this.syncDevice();
            }, 500);
          },
          error: () => {
            setTimeout(() => {
              this.type = 'failed';
            }, 500);
          },
        });
    }
  }

  syncDevice() {
    this.visible = false;
    const deviceId = this.device?.id;
    if (!!deviceId) {
      this.isSyncing = true;
      this.type = 'loading';
      this.visible = true;
      this.networkDeviceSrv
        .syncDevice(deviceId)
        .pipe()
        .subscribe({
          next: () => {
            setTimeout(() => {
              this.networkDeviceSrv.refresh$.next(true);
              this.type = 'success';
            }, 500);
          },
          error: () => {
            setTimeout(() => {
              this.type = 'failed';
            }, 500);
          },
        });
    }
  }

  openDeviceForm() {
    const dialog = this.dialogSrv.open(SharedNetworkDeviceFormComponent, {
      header: 'Edit Network Device',
      data: {
        device: this.device,
      },
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
      styleClass: 'common-dialog',
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.device = rs[0];
        this.open(this.device, false);
      }
    });
  }

  closeTestConfigurationDialog() {
    this.visible = false;
  }

  onCancel() {
    this.dialogRef.close();
    this.closeTestConfigurationDialog();
  }
}
